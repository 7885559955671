// Core
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';

import {Styles, Btns, Colors, LayoutDims} from './UIConst.js';
import {ReportDisplay, Separator, Icon, ShowOnly} from './Components';

import {DataFetcher, Data, VaultsDataFetcher, AuditLog} from '../Models/Model';
import {Spinner, GlobalUI, FetchBusy} from '../Global';

class VaultSharePane extends Component
{
  constructor(props)
  {
    super(props);

    // shared is an array of user names
    this.state = {sharedWith: props.sharedWith, all: props.sharedWith.indexOf('*') >= 0};

    // Team member list
    this.data = null;
  }

  // If list of shared users changes, update UI
  componentWillReceiveProps(props)
  {
    this.setState({sharedWith: props.sharedWith, all: props.sharedWith.indexOf('*') >= 0});
  }

  setUser = (user, v) =>
  {
    const setShared = new Set(this.state.sharedWith);

    // Remove or add the entry
    if(v)
    {
      setShared.add(user);
    }
    else
    {
      setShared.delete(user);
    }

    this.setState({sharedWith: [...setShared]});
  }

  // Share with all users
  onCheckAll = (evt, v) =>
  {
    this.setUser('*', v);
    this.setState({all: v});
  }

  onCheck = (evt, v) =>
  {
    const elem = evt.target;
    this.setUser(elem.getAttribute('data-user'), v);
  }

  clear = () => this.setState({sharedWith: [], all: false});

  // Returns the list of users that were checked
  getSharedUsers = () => this.state.all ? '*' : this.state.sharedWith.join(',');

  getCheckBox = (row) =>
  {
    return (
      <Checkbox
        style={{marginLeft: LayoutDims.nMargin / 2}}
        data-user={row.user_login}

        checked={(row.isAll && this.state.all) || this.state.sharedWith.indexOf(row.user_login) >= 0}

        // All checkboxes disabled if "All Team Members" is checked (except that itself)
        disabled={row.isAll ? false : this.state.all}

        // Different click handler for the "All Team Members" box and others
        onCheck={row.isAll ? this.onCheckAll : this.onCheck}
      />
    )
  }

  render()
  {
    if(!this.data)
    {
      this.data = [];
      for(const sUser of Object.keys(Data.User.Stats))
      {
        if(sUser !== Data.User.Profile.user_login)
        {
          const isAll = sUser === '@';
          this.data.push
          (
            {
              user_login: isAll ? '- All Team Members -' : sUser,
              user_email: isAll ? '' : Data.User.Stats[sUser].user_email,
              isAll
            }
          );
        }
      }
    }

    return (
      <ReportDisplay data={this.data}
                     colText={['User Name', 'Email']}
                     colFields={['user_login', 'user_email']}
                     extraButton={this.getCheckBox}
                     getExtraStyle={(row) => (this.state.all && !row.isAll) ? {color: Colors.clrNimbixDarkGray} : null}
      />
    );
  }
}

export class SettingsVault extends Component
{
  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['name', 'type', 'size', 'zone'];
  static arrColNames = ['Vault Name', 'Type', 'Size', 'Zone'];

  constructor(props)
  {
    super(props);
    this.state =
    {
      data: [],
      fetched: 0,
      total: 0,
      selectedIndex: -1,
      sharing: false,
      sharedWith: null
    };

    // We need to maintain the data outside the state, so we can update it synchronously when needed
    this.data = [];

    // We would like to refresh on page switch, but only once, this flag maintains the first time state
    this.refreshed = false;

    // Register the fetchSizes method with VaultsDataFetcher to execute when vaults are re-fetched
    VaultsDataFetcher.whenDone(this.fetchVaultSizes);
  }

  // This is called by the parent SwitchedPaneView callback
  onShow()
  {
    // We only fetch sizes if this is the first ever time and
    // we're not in the midst of already refreshing (fetched != total means in progress)
    if(!this.refreshed && this.state.fetched === this.state.total)
    {
      this.fetchVaultSizes();
    }
  }

  // Update the row when the size is fetched
  updateSize = (id, vaultData) =>
  {
    this.data[id].size = vaultData.size != null ? vaultData.size + ' GB' : 'N/A';

    // Success or failure, update the data and fetched count
    this.setState({data: this.data, fetched: this.state.fetched + 1});
  }

  // Refetches all the vaults info
  onRefresh = () =>
  {
    FetchBusy(VaultsDataFetcher, 'Refreshing Vault List...');
  }

  // On refresh fetch vault sizes
  fetchVaultSizes = () =>
  {
    const arrVaultNames = Object.keys(Data.Vaults.VaultInfo);

    // Set the flag saying that the initial automatic refresh on pane show is done
    // After this, onShow won't refresh
    this.refreshed = true;

    // Clear existing data
    this.setState({data: [], total: arrVaultNames.length, fetched: 0});

    // Add all the rows with no size value
    this.data = [];
    for(const name of arrVaultNames)
    {
      // Need this so the value is captured by the lambdas
      let id = this.data.length;
      const elemSize =
        <div style={Styles.Inline}>
          <Spinner style={{width: 12, marginRight: 12}} size={10} status=''/>
          Calculating...
        </div>

      let zone = Data.Vaults.VaultInfo[name].zone;
      if(zone === -1)
      {
        zone = 'None';
      }
      else
      {
        zone = Data.Zones[zone];
        zone = zone ? zone.desc : null;
      }

      // Add a row
      this.data.push
      (
        {
          id,
          name,
          type: Data.Vaults.VaultInfo[name].type,
          size: elemSize,
          shared: Data.Vaults.VaultInfo[name].shared,
          zone: zone || Data.Vaults.VaultInfo[name].zone,
        }
      );

      // Launch a request that will fetch the size async'ly
      const fetcher = new DataFetcher('/portal/vault-size', {name}, 5000);

      // On success, update the size column, on fail just put null, which is shown as N/A
      fetcher.whenDone(() => this.updateSize(id, fetcher.data) );
      fetcher.ifFail(() => this.updateSize(id, {size: null}));
      fetcher.fetch();
    }

    this.setState({data: this.data});
  }

  onClickRow = (i) => this.setState({selectedIndex: i});

  onDefault = () =>
  {
    const name = this.state.data[this.state.selectedIndex].name;
    const fetcher = new DataFetcher('/portal/vault-set-default', {name});

    fetcher.whenDone
    (
      () =>
      {
        Data.Vaults.DefaultVault = name;
        AuditLog('vault', name, 'Set default');
        this.forceUpdate();
      }
    );

    fetcher.ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Error Saving Default Vault'));
    FetchBusy(fetcher, 'Saving...');
  }

  onShare = () =>
  {
    const name = this.state.data[this.state.selectedIndex].name;
    this.setState({sharing: true, sharedWith: Data.Vaults.VaultInfo[name].shared_with});
  }

  onSaveShare = () =>
  {
    let shared = this.refs.vaultSharePane.getSharedUsers();
    const name = this.state.data[this.state.selectedIndex].name;

    // Send request
    const fetcher = new DataFetcher('/portal/vault-share', {name, shared});

    // On success update the UI, do an audit log
    fetcher.whenDone
    (
      () =>
      {
        const data = [...this.state.data];
        data[this.state.selectedIndex].shared = shared;

        // Split will make an array even if string is empty, so avoid that
        Data.Vaults.VaultInfo[name].shared_with = shared ? shared.split(',') : [];
        this.setState({data: data, sharing: false, sharedWith: Data.Vaults.VaultInfo[name].shared_with});

        const sAuditMsg = (shared === '' ? 'Unshared' : `Shared with ${shared}`);
        AuditLog('vault', name, sAuditMsg);

      }
    );

    fetcher.ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Error Sharing Vaults'));
    FetchBusy(fetcher, 'Saving...');
  }

  // row may be null sometimes
  isDefaultVault = (row) => row && row.name === Data.Vaults.DefaultVault;

  // Shared vault indicator
  getSharedIcon = (row) => row && row.shared ? Icon('group') : '';


  render()
  {
    const bHasVaults = this.state.data.length > 0;
    const bIsSelected = this.state.selectedIndex >= 0;
    const dctSelected = bHasVaults && bIsSelected && this.state.data[this.state.selectedIndex];
    const bIsDefaultSelected = dctSelected && this.isDefaultVault(dctSelected)
    const bCanShare = dctSelected && dctSelected.name && dctSelected.name.indexOf('::') < 0;
    const bLoaded = this.state.fetched === this.state.total;

    const colGroup =
      <colgroup>
        <col style={{width: '4%'}}/>
        <col style={{width: '25%'}}/>
        <col style={{width: '15%'}}/>
        <col style={{width: '10%'}}/>
      </colgroup>;

    // Generate the table rows
    return (
      <div>

        <ShowOnly if={this.state.sharing}>
          <div style={Styles.FlexSpread}>
            <RaisedButton label='Clear All' {...Btns.Blue}
                          onClick={() => this.refs.vaultSharePane.clear()} />

            <div style={Styles.InlineFlexRow}>
              <RaisedButton label='Save' {...Btns.Green} onClick={this.onSaveShare} />
              &nbsp;&nbsp;
              <RaisedButton label='Cancel' {...Btns.DarkRed} onClick={()=>this.setState({sharing: false})} />
            </div>
          </div>

          <Separator units={2}/>

          <div style={{fontSize: LayoutDims.FontSize}}>
            Select the users on this team who are allowed to attach this vault.
            These users will be able to read and write files to this vault if they select it for jobs.
          </div>

          <VaultSharePane sharedWith={this.state.sharedWith || []} ref='vaultSharePane'/>

        </ShowOnly>

        <ShowOnly if={!this.state.sharing}>
          <div style={Styles.FlexSpread}>
            <RaisedButton label='Refresh' {...Btns.Blue} onClick={this.onRefresh} disabled={!bLoaded}/>

            <div style={Styles.InlineFlexRow}>
              <RaisedButton label='Sharing' onClick={this.onShare} disabled={!bCanShare}/>
              &nbsp;&nbsp;
              <RaisedButton label='Default' {...Btns.Green} onClick={this.onDefault} disabled={bIsDefaultSelected}/>
            </div>
          </div>

          <Separator units={1}/>

          <ReportDisplay ref='vaultList'
                         data={this.state.data}
                         colFields={SettingsVault.arrColFields}
                         colText={SettingsVault.arrColNames}
                         onClickRow={this.onClickRow}
                         selectedIndex={this.state.selectedIndex}
                         getExtraStyle={(row) => this.isDefaultVault(row) ? {fontWeight: 'bold'} : {}}
                         getPrefixText={(row, x, y)=> this.isDefaultVault(row) && x === 0 ? '✔ ' : '' }
                         extraButton={this.getSharedIcon}
                         colGroup={colGroup}
          />
        </ShowOnly>

      </div>
    );
  }
}
