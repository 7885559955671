// Core
import React, {Component} from 'react';

import Checkbox from 'material-ui/Checkbox';

import {LayoutDims, Colors} from './UIConst';
import {SectionViewHeader} from './Components';
import {GlobalUI, FetchBusy} from '../Global';

import {SettingsSummaryJobStats} from './SettingsSummaryJobStats';
import {SettingsSummaryTeamJobs} from './SettingsSummaryTeamJobs';
import {SettingsSummaryBillingReport} from './SettingsSummaryBillingReport';
import {IsTeamAdmin, Data, DataFetcher} from '../Models/Model';


const SwitcherLink = (props) =>
{
  return (
    <div
      style={{marginRight: LayoutDims.nMargin * 2, color: Colors.clrNimbixMed, cursor: 'pointer'}}
      onClick={
        ()=>
        {
          GlobalUI.Main.onNavigate(props.paneGroup, false);
          GlobalUI.Dashboard.switchPane(props.paneName);
        }
      }
    >
      More...
    </div>
  );
}


export class SettingsSummary extends Component
{
  onCheckTeamJobsPublic = (e, value) =>
  {
    const fetcher = new DataFetcher('/portal/team-payer-set-user-meta', {key: 'teamJobsPublic', value});
    fetcher.whenDone(()=>Data.User.Profile.team_jobs_public = value);
    FetchBusy(fetcher, 'Saving...');
  }

  render()
  {
    const elemLinkActive = IsTeamAdmin() && <SwitcherLink paneGroup='Dashboard' paneName='Team Jobs'/>;

    return (
      <div>

        {
          IsTeamAdmin() &&
          <Checkbox label='Allow team members to view active jobs and summary'
                    onCheck={this.onCheckTeamJobsPublic} defaultChecked={Data.User.Profile.team_jobs_public}/>
        }

        <SectionViewHeader headerText='Active Summary' />
        <div style={{padding: LayoutDims.nMargin * 2}}>
          <SettingsSummaryJobStats />
        </div>

        <SectionViewHeader headerText='Active Jobs' link={elemLinkActive} />
        <div style={{padding: LayoutDims.nMargin * 2}}>
          <SettingsSummaryTeamJobs />
        </div>

        {
          IsTeamAdmin() &&
          <div>
            <SectionViewHeader headerText='Team Billing Report (Past 30 days)'
                               link={<SwitcherLink paneGroup='Dashboard' paneName='Reports'/>} />
            <div style={{padding: LayoutDims.nMargin * 2}}>
              <SettingsSummaryBillingReport />
            </div>
          </div>
        }

      </div>
    );
  }
}
