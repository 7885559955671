// Core
import React, {Component} from 'react';

// Material UI Controls
import Paper from 'material-ui/Paper';
import {Spinner} from '../Global';

// Our custom components
import {ModalView, ModalTitle} from './Components';
import {LayoutDims, Styles, Colors} from './UIConst';
import {DataFetcher, Data} from '../Models/Model';

export default class PTCHistoryDlg extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {history: null, open:false};
    this.timerID = 0;
  }

  onTimer = () =>
  {
    this.setState({history: null});

    const url = '/portal/audit-query/';

    // Make the resource name
    // strip the user name hyphen at the front of
    // the app name and append .app and use that
    const sPrefix = Data.User.Profile.user_login + '-';
    let resource = (this.app.indexOf(sPrefix) === 0)
    ?
      this.app.substr(sPrefix.length) + '.app'
    :
      this.app + '.app';

    const params =
    {
      textonly: true,
      descending: true,
      reltime: true,
      limit: 12,
      payer: '',
      category: 'dockerpull',
      resource
    };

    new DataFetcher(url, params)
      .whenDone((jqXHR) => this.setState({history: jqXHR.responseJSON.result || 'No data'}))
      .fetch();
  }

  startTimer(app)
  {
    // Stop any timer that is active
    if(this.timerID)
    {
      clearInterval(this.timerID);
      this.timerID = 0;
    }

    this.app = app;
    this.setState({history: null});

    // Schedule periodically in 15 seconds, launch it immediately
    this.timerID = setInterval(this.onTimer, 15000);
    this.onTimer();
  }

  onClose = () =>
  {
    if(this.timerID)
    {
      clearInterval(this.timerID);
      this.timerID = 0;
    }

    this.setState({open: false, history: null});
  }

  static styleHistory = null;
  static styleHint = null;
  static styleOuter = null;

  render()
  {
    // Init static styles if first render
    if(!PTCHistoryDlg.styleHistory)
    {
      PTCHistoryDlg.styleHistory =
      {
        ...Styles.Bordered,
        padding: LayoutDims.nMargin/2,
        margin: 0,
        marginBottom: LayoutDims.nMargin,
        minHeight: 128,
        maxHeight: 256,
        overflow: 'auto',
        display: 'inline-flex',
      };

      PTCHistoryDlg.styleHint =
      {
        color: Colors.clrNimbixDark,
        marginBottom: LayoutDims.nMargin
      };

      PTCHistoryDlg.styleOuter =
      {
        ...Styles.FlexSpread,
        flexFlow: 'column',
        alignItems: 'stretch',
        alignContent: 'flex-start'
      };
    }


    return (
      <ModalView ref='viewModal' open={this.state.open} onClose={this.onClose}
                 width={LayoutDims.wContent}>
        <Paper zDepth={3} style={Styles.ModalPaper}>
          <ModalTitle scale={0.40} title='Pull History'/>

          <div style={PTCHistoryDlg.styleOuter}>
            <div style={PTCHistoryDlg.styleHint}>
              Output will refresh automatically every 15 seconds
            </div>

            <pre data-cy='ptcPullHistory' style={{...PTCHistoryDlg.styleHistory, alignItems: this.state.history ? 'flex-start' : 'center'}}>
            {
              // If we have history, show it, else show spinner, centered
              this.state.history ||
              <Spinner style={Styles.Full} textColor={Colors.clrNimbixDark} size={48} fontSize={16}
                       status='Fetching...'/>
            }
            </pre>
          </div>

        </Paper>
      </ModalView>
    );
  }
}
