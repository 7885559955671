import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Styles} from './UIConst';
import {GlobalUI} from '../Global'
import {Data} from '../Models/Model';
import {VaultFileBrowser} from './VaultFileBrowser';


export class TaskBuilderParamFile extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {sVault: null};
  }

  focus()
  {
    this.inputField.focus();
  }

  scrollIntoView()
  {
    this.inputField.scrollIntoView();
  }

  // Called from outside when vault selection is changed
  setVault(sVault)
  {
    this.setState({sVault});

    // Force the last existing dialog content to die
    // Ensuring that the VaultFileBrowser is recreated when the ... button is clicked
    GlobalUI.Dialog.clear();
  }

  // On select file, close the dialog slightly delayed to give visual feedback
  onSelectFile = (file) =>
  {
    this.inputField.value = file;
    window.setTimeout(GlobalUI.Dialog.onClose, 300);
  }

  onBrowse = () =>
  {
    const sFilter = this.props.filter ? this.props.filter.replace(/\|/g, ',') : '';
    GlobalUI.Dialog.show
    (
      `Select File - ${sFilter}`,
      <VaultFileBrowser vault={this.state.sVault} filter={this.props.filter}
                        onSelectFile={this.onSelectFile}/>,
      false, // pre
      null, // width
      null, // onclose
      null, // top
      true, // noescape

    );
  }

  render()
  {
    const isBrowsable = this.state.sVault && Data.Vaults.VaultInfo[this.state.sVault].listable;
    const sFilter = this.props.filter ? this.props.filter.replace(/\|/g, ',') : '';

    return (
      <div>
        <div style={{...Styles.Inline,  width: 'calc(50% + 48px)', alignItems: 'stretch'}}>
          <input placeholder={sFilter} ref={el=>this.inputField = el} defaultValue={this.props.defaultValue}
                 style={this.props.inputStyle} />

          <button disabled={!isBrowsable} style={{width: 32}} onClick={this.onBrowse}
                  title={!isBrowsable ? 'Selected Vault is not listable' : 'Browse'}>
          ...
          </button>

        </div>
      </div>
    );
  }
}

TaskBuilderParamFile.propTypes =
{
  style: PropTypes.object,
  getVault: PropTypes.func,
  defaultValue: PropTypes.string
};
