// Core
import React, {Component} from 'react';
import {ListItem} from 'material-ui/List';
import RaisedButton from 'material-ui/RaisedButton';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

import {Separator, HLine, InputRow, TableHeaderCells, TableCellsBordered, OptionDropDown,
  CheckListItems, RawDataDownloader, ShowOnly} from './Components.js';

import {Styles, LayoutDims, Btns, Colors} from './UIConst.js';
import {Data, DataFetcher, AdminMachineDataFetcher} from '../Models/Model';
import {AppsData, PTCAppsData, AppsDataFetcher, PTCAppsDataFetcher} from '../Models/AppsData';
import {FetchBusy} from '../Global'

import {BillingDateChooser} from './BillingDateChooser.js';

// Non Itemized report
class BillingDisplay extends Component
{
  static colNames = ['Username', 'Email', 'Billing Code', 'Job Count', 'Compute Time', 'CPU Time', 'Compute Cost'];
  static colKeys = ['user', 'email', 'billing_code', 'job_count', 'compute_time', 'cpu_time', 'compute_cost'];

  render()
  {
    const data = this.props.data;
    const isAdminReport = data.bAdminReport;
    const hasParams = data.intparamvals;

    // Make the rows, add app cost column if not own report
    // Add int params columns if any
    const rows = data.rows.map
    (
      (row)=>
        <tr key={row.user}>
          {TableCellsBordered(BillingDisplay.colKeys, row)}
          {isAdminReport && TableCellsBordered(['app_cost'], row)}
          {hasParams && TableCellsBordered(data.intparamvals)}
        </tr>
    );

    return (
      <table style={{...Styles.Table, ...Styles.Bordered, width: '100%'}}>
        <thead>
          <tr>
            {TableHeaderCells(BillingDisplay.colNames)}
            {isAdminReport && TableHeaderCells(['App Cost'])}
            {hasParams && TableHeaderCells(data.intparams)}
          </tr>
        </thead>

        <tbody>
          {rows}
        </tbody>

        {/* Add the Totals row, append total app cost and total int vals if needed */}
        <thead>
          <tr>
            {TableHeaderCells(['Totals', ' ', ' ', data.totaljobs, data.totaltime, data.totalcputime, data.totalcost])}
            {isAdminReport && TableHeaderCells([data.totalappcost])}
            {hasParams && TableHeaderCells(data.totalintvals)}
          </tr>
        </thead>
      </table>
    );
  }
}

class BillingItemizedDisplay extends Component
{
  render()
  {
    const arrFields = ['datestr', 'job_number', 'job_owner_username', 'job_payer',
      'billing_code', 'job_application', 'job_label', 'job_project', 'machine', 'compute_time', 'cpu_time', 'compute_cost']

    const arrCols = ['Start' ,'Job No.' ,'User' ,'Payer' ,'Billing Code' ,'App' ,'Job Label', 'Project',
      'Machine' ,'Compute Time', 'CPU Time','Cost'];

    return (
      <div>
        <table style={{...Styles.Table, ...Styles.Bordered, width: '98%'}}>
          <thead>
            <tr>
              {arrCols.map((col,i)=> <th key={'col'+i} style={Styles.TableHeader}>{col}</th>)}

              { // Add app cost column if necessary
                this.props.data.bAdminReport && <th style={Styles.TableHeader}>App Cost</th>
              }

              { // Add param column if necessary
                this.props.data.paramcols &&
                this.props.data.paramcols.map
                (
                  (col, i)=>
                    <th key={'colidr' + i} style={Styles.TableHeader}>{col}</th>
                )
              }
            </tr>
          </thead>

          <tbody>
          {
            this.props.data.rows.map
            (
              (row, i)=>
                <tr key={i}>
                  {
                    arrFields.map
                    (
                      (field, i)=>
                      {
                        // For user and payer, combine username and email in one field
                        let val;
                        if(field === '_job_owner_username')
                        {
                          val = [row[field], <br/>, row['email']];
                        }
                        else if(field === '_job_payer')
                        {
                          val = [row[field], <br/>, row['payer_email']];
                        }
                        else
                        {
                          val = row[field];
                        }

                        return <td key={i} style={Styles.TableCellBordered}>{val}</td>
                      }
                    )
                  }

                  { // Add app cost column if necessary
                    this.props.data.bAdminReport && <td style={Styles.TableCellBordered}>{row.app_cost}</td>
                  }
                  { // Add param vals column if necessary
                    this.props.data.paramvals &&
                    this.props.data.paramvals.map((val)=><td style={Styles.TableCellBordered}>{val}</td>)
                  }
                </tr>
            )
          }
          </tbody>

          <thead>
            <tr>
              <th style={Styles.TableHeader}>Totals</th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}> </th>
              <th style={Styles.TableHeader}>{this.props.data.params.compute_time}</th>
              <th style={Styles.TableHeader}>{this.props.data.params.cpu_time}</th>
              <th style={Styles.TableHeader}>{this.props.data.params.compute_cost}</th>
              {
                // Add app cost column if necessary
                this.props.data.bAdminReport &&
                <th style={Styles.TableHeader}>{this.props.data.params.app_cost}</th>
              }
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

// Gets the public app names from PTC or regular apps data
function GetPublicAppNames(data)
{
  return Object.keys(data).filter((app) => data[app].public);
}

// Sort apps by name
function SortAppByName(data, arr)
{
  arr.sort
  (
    (a, b) =>
    {
      const nameA = data[a].data.name.toUpperCase();
      const nameB = data[b].data.name.toUpperCase();
      return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
    }
  );
}

export default class AdminBilling extends Component
{
  static arrStatuses = ['COMPLETED', 'COMPLETED WITH ERROR', 'SUBMITTED', 'PROCESSING STARTING',
    'CANCELED', 'EXEMPT', 'SEQUENTIALLY QUEUED', 'TERMINATED'];

  constructor(props)
  {
    super(props);
    this.state =
      {
        appPopupOpen: false,
        machinePopupOpen: false,
        statusPopupOpen:false,
        arrMC: new Set([]),              // dict of selected machines
        arrStatus: new Set([]),          // dict of selected statuses
        appFilter: '',                   // selected app
        dataReport: null,
        itemized: false,
        appsData: [],
        ptcAppsData: [],
        machines: [],
        mode: 'mc'
      };

    // Set to true after a report is shown first time
    // Reset after the report is scrolled into view
    this.reportRefreshed = false;

    // Set a handler to update the dropdowns when the app data fetchers finish
    // AppsData, PTCAppsData, Machines
    // Never mind if its called thrice
    AppsDataFetcher.whenDone(this.initData);
    PTCAppsDataFetcher.whenDone(this.initData);
    AdminMachineDataFetcher.whenDone(this.initData);

    AdminBilling.styleZoneDropdown = {...Styles.ParamInput, marginBottom: LayoutDims.nMargin/2, height: LayoutDims.FontSize + 4};
  }

  componentDidMount()
  {
    // Data is already available at this point initially
    this.initData();
  }

  // To be called after machines and apps are fetched
  initData = () =>
  {
    this.setState({appsData: AppsData, ptcAppsData: PTCAppsData, machines: Data.MachinesAll});
  }

  generateReport = (itemized=false, download=false) =>
  {
    // Set up the request params
    const params =
    {
      machtypes: Array.from(this.state.arrMC).join(','),
      user: this.refs.userName.value,
      billingcode: this.refs.billingCode.value,
      intparams: this.refs.intParams.value,
      statuses: Array.from(this.state.arrStatus).join(','),
      jobapp: this.state.appFilter,
      itemized: false,
      fromadmin: true,
      ...this.refs.dateChooser.getParams()
    }

    // If zone is provided, pass it on, get rid of machtypes if any
    if(this.state.mode === 'zone')
    {
      delete params.machtypes;
      const zone = this.refs.selectZone.value;
      if(zone >= 0)
      {
        params.zone = zone;
      }
    }

    // Do we want a CSV download?
    if(download)
    {
      params.csv = 1;
    }

    // Extract only the non empty params
    const paramsNonEmpty = {};
    for(const k in params)
    {
      if(params[k])
      {
        paramsNonEmpty[k] = params[k];
      }
    }

    let url = `/portal/admin-billing${itemized ? '-itemized' : ''}-report`;

    const fetcher = new DataFetcher(url, paramsNonEmpty);

    // If report to be displayed
    if(!download)
    {
      this.setState({dataReport: null, itemized: false});
      fetcher.whenDone
      (
        () =>
        {
          this.reportRefreshed = true;
          this.setState({itemized, dataReport: fetcher.data});
        }
      );

      FetchBusy(fetcher, 'Generating report...');
    }
    else // else download CSV report
    {
      fetcher.whenDone(()=>this.refs.downloader.save(fetcher.data));
      FetchBusy(fetcher, 'Downloading CSV...');
    }
  }

  // Reset the filters when the drop downs are cleared
  onClearFilterMC = () => this.setState({arrMC: new Set([])});
  onClearFilterStatus = () => this.setState({arrStatus: new Set([])});
  onClearFilterApp = () => this.setState({appFilter: ''});

  render()
  {
    const showReport = this.state.dataReport;
    const itemized = this.state.itemized;

    // When report is visible for first time, scroll it into view
    if(showReport)
    {
      window.setTimeout
      (
        ()=>
        {
          if(this.refs.reportView && this.reportRefreshed)
          {
            this.reportRefreshed = false;
            this.refs.reportView.scrollIntoView();
          }
        },
        300
      );
    }

    // Sort the names by machine description
    const arrMCNames = Object.keys(this.state.machines);
    arrMCNames.sort
    (
      (a, b) =>
      {
        const nameA = Data.MachinesAll[a].mc_description.toUpperCase();
        const nameB = Data.MachinesAll[b].mc_description.toUpperCase();
        return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
      }
    );

    // Generate the machines dropdown checked options list
    let arrListItemsMC = CheckListItems
    (
      this,
      arrMCNames,
      'arrMC',
      'Machine',
      (m) => `${Data.MachinesAll[m].mc_description} [${m}]`
    );

    let arrOptsZone = [<option key={-1} value={-1}> </option>];
    arrOptsZone = arrOptsZone.concat
    (
      Object.keys(Data.Zones).map
      (
        (e, i) => <option key={i} value={e}>{Data.Zones[e].desc}</option>
      )

    );

    // Apps list includes both regular and PTC ones
    // Filter out private apps
    const dctAllApps = {...this.state.appsData, ...this.state.ptcAppsData};
    const arrApps = GetPublicAppNames(dctAllApps);
    SortAppByName(dctAllApps, arrApps);

    const arrListItemsApp = arrApps.map
    (
      (m, i) =>
        <ListItem
          onClick={()=>{this.setState({appFilter: m}); this.refs.appFilter.closePopup();}}
          primaryText={`${dctAllApps[m].data.name} [${m}]`}
          key={'App' + i} />
    );

    // Status list items
    const arrListItemsStatus = CheckListItems(this, AdminBilling.arrStatuses, 'arrStatus', 'Status');

    return (
      <div>
        <div style={{margin: LayoutDims.nMargin * 2}}>

          <p style={{color: Colors.clrNimbixDark}}>Choose Time Period</p>
          <HLine margin={4}/>
          <br/>

          <BillingDateChooser ref='dateChooser'/>

          <Separator units={1.5}/>

          <p style={{color: Colors.clrNimbixDark}}>Optional filters</p>
          <HLine margin={4}/>
          <br/>

          <Separator/>

          <RadioButtonGroup onChange={(e, mode)=>this.setState({mode})}
                            ref='radioGroupFilter'
                            name='adminBillingZoneRadio'
                            defaultSelected={this.state.mode}
                            style={{...Styles.InlineFlexRow, width: '90%'}}>

            <RadioButton style={{width:'50%'}} data-cy='radioBillingMC' value='mc' label='Filter by machines'/>
            <RadioButton style={{width:'50%'}} data-cy='radioBillingZone' value='zone' label='Filter by zone'/>

          </RadioButtonGroup>

          <ShowOnly
            if={this.state.mode === 'mc'}

            children={
              <OptionDropDown title='' items={arrListItemsMC}
                              width={LayoutDims.wContent * 0.8}
                              text={Array.from(this.state.arrMC).join(',')}
                              onClear={this.onClearFilterMC}/>
            }

            otherwise={
              <InputRow title=''>
                <select ref='selectZone' style={AdminBilling.styleZoneDropdown}>{arrOptsZone}</select>
              </InputRow>
            }
          />

          <InputRow title='User Name'>
            <input ref='userName' style={Styles.ParamInputThin} list='user-data-list'/>
          </InputRow>

          <InputRow title='Billing Code'>
            <input ref='billingCode' style={Styles.ParamInputThin}/>
          </InputRow>

          <OptionDropDown title='Filter By Status' items={arrListItemsStatus}
                          width={LayoutDims.wContent * 0.8}
                          text={Array.from(this.state.arrStatus).join(',')}
                          onClear={this.onClearFilterStatus}/>

          <OptionDropDown title='Filter By App' items={arrListItemsApp} ref='appFilter'
                          text={this.state.appFilter}
                          onClear={this.onClearFilterApp}/>

          <InputRow title='Int params'>
            <input ref='intParams' style={Styles.ParamInputThin}/>
          </InputRow>


          <Separator units={2}/>

          <div style={{...Styles.Inline, justifyContent: 'flex-end'}}>
            <RaisedButton {...Btns.Green} label='Generate Report' onClick={()=>this.generateReport()}/>
            &nbsp;&nbsp;
            <RaisedButton {...Btns.Blue} label='Generate Itemized Report' onClick={()=>this.generateReport('itemized')}/>
          </div>
          <Separator/>

          <div ref='reportView' >
          {
            showReport &&
            [
              <div key='downloadBtns'>
                <Separator units={2}/>
                <HLine margin={2}/>
                <Separator units={2}/>
                <div style={Styles.Inline}>
                  &nbsp;
                  <RaisedButton onClick={()=>this.generateReport(itemized, 'download')}
                                label='Download as CSV' {...Btns.Blue}/>
                </div>
                <Separator units={2}/>
              </div>,

              itemized ?
                <BillingItemizedDisplay data={this.state.dataReport} key='reportItemized' /> :
                <BillingDisplay         data={this.state.dataReport} key='report' />
            ]
          }
          </div>
        </div>

        <RawDataDownloader name='admin_billing'
                           filename={itemized ? 'report_itemized.csv': 'report.csv'}
                           ref='downloader'/>

      </div>
    );
  }
}

