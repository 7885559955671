// Core
import React, {Component} from 'react';

import {ReportDisplay, SwitchedPane} from './Components.js';
import {Styles, Colors} from './UIConst.js';
import {Data, DataFetcher} from '../Models/Model';
import {Spinner} from '../Global';

export class SettingsSummaryBillingReport extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {data: null};

    // Get a 30 day range
    const d = new Date();
    const enddate = d.toISOString().slice(0, 10);
    d.setDate(d.getDate() - 30);
    const startdate = d.toISOString().slice(0, 10);

    const params =
    {
      user: Data.User.Profile.user_login,
      timeperiod: 'range',
      itemized: true,
      startdate,
      enddate,
    };

    this.fetcher = new DataFetcher('/portal/team-report-billing', params);
    this.fetcher.whenDone
    (
      ()=>
      {
        this.setState({data: this.fetcher.data});
        //console.log(this.fetcher.data);
      }
    );
    this.fetcher.fetch();

    // Make the data refresh when this pane is switched to
    // TODO - will not refresh on right pane navigation
    SwitchedPane.RegisterOnSwitch('SettingsContent/Summary', this.refreshData);
  }

  refreshData = () =>
  {
    this.setState({data: null});
    this.fetcher.fetch();
  }

  // Get the rows of data
  getRows = (rows) =>
  {
    // The job_start_time is an integer timestamp, convert to string and add a new column datestr

  }

  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['start_time', 'job_number', 'job_owner_username',
    'email', 'job_application', 'job_label', 'job_project', 'machine', 'compute_time', 'compute_cost']

  static arrColText =   ['Start', 'Job No.', 'User',
    'Email', 'App', 'Job Label', 'Project', 'Machine', 'Compute Time', 'Cost'];

  // Tell ReportView to sort start_time using job_start_time
  static dctColTypes = {start_time: 'job_start_time'}

  render()
  {
    const bHasData = this.state.data && this.state.data.jobs;

    return (
      <div>
        {
          bHasData
            ?
            <ReportDisplay colText={SettingsSummaryBillingReport.arrColText}
                           colFields={SettingsSummaryBillingReport.arrColFields}
                           colTypes={SettingsSummaryBillingReport.dctColTypes}
                           data={this.state.data.jobs}/>
            :
            (
              !this.state.data &&
              <Spinner style={Styles.Full} textColor={Colors.clrNimbixDark} size={48} fontSize={16}
                       status='Fetching...'/>
            )
        }
      </div>
    );
  }
}
