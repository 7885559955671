import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Divider from 'material-ui/Divider';
import {List, ListItem} from 'material-ui/List';

import {GlobalUI} from '../Global';
import {Styles, LayoutDims, Colors} from './UIConst';
import {MUI, Toggler, Icon, Nop} from './Components';

import {StrICmp} from './Utils'

const FileManagerApp = 'jarvice-filemanager';


// The contents of the left drawer on the Compute section
// List of filter criteria - license, categories and vendors
// Categories can be a tree
// Only one item is selectable
export default class AppFilterPane extends Component
{
  static propTypes =
  {
    onSelectClass: PropTypes.func,
    onSelectVendor: PropTypes.func,
    onSelectLicense: PropTypes.func,
    onSelectCommunity: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOwned: PropTypes.func,
    onSelectTeam: PropTypes.func,

    fetcher: PropTypes.object.isRequired,
    prefixPane: PropTypes.object,
  };

  constructor(props)
  {
    super(props);

    this.state = {bLicensed: false, activeFilter: 'All Applications', rootOpen: ''};
    this.listItems = {};

    // Save a reference to this component
    GlobalUI[props.fetcher.tag === 'PTC' ? 'PTCAppFilterPane' : 'AppFilterPane'] = this;

    this.itemPrefix = props.fetcher.tag === 'PTC' ? 'menuViewPTC' : 'menuViewCompute';
  }

  // Call the filter and set the current filter and open root item
  // the list item with sHighLight as its filter is highlighted
  // matching prefixes is only valid for deep link search for vendors
  onItemClick = (sFilter, sKey, sHighLight) =>
  {
    this.props['onSelect' + sFilter](sKey);
    this.setState({activeFilter: sHighLight, rootOpen: this.rootOpen, matchPrefix: false})
  }

  onSelectClass = (s) => this.onItemClick('Class', s, s);
  onSelectCommunity = (s) => this.onItemClick('Community', s, s);
  onSelectAll = (s) => this.onItemClick('All', s, 'All Applications');
  onSelectOwned = (s) => this.onItemClick('Owned', s, 'My Applications');
  onSelectTeam = (s) => this.onItemClick('Team', s, 'Team');

  // Collapses all open branches of the tree
  closeAll()
  {
    for(const sText of Object.keys(this.listItems))
    {
      this.listItems[sText].setState({open: false});
    }
  }

  // Close all top level branches of the tree except the one that was clicked
  rootListElemClick(pElemThis)
  {
    for(const sText of Object.keys(this.listItems))
    {
      const pElem = this.listItems[sText];
      if(pElem !== pElemThis)
      {
        pElem.setState({open: false});
      }
      else
      {
        // Remember which root is open
        this.rootOpen = sText;
      }
    }
  }

  // Key for categories tree list sub items
  static iListKey = 0;

  // Creates a list item
  // Root items expand on clicking the item,
  // Child items only on clicking the down arrow
  // Root items are stored in this.listItems
  // If a root item is clicked all other root items are collapsed
  listItem = (sText, arrItemChild, fnOnClick, isRoot, elemIcon) =>
  {
    const sFilter = this.state.activeFilter && this.state.activeFilter.toLowerCase();
    const sTextLower = sText.toLowerCase();

    // If this item is a root and it matches the start of the filter, it is opened
    // Top level root that was last clicked is also opened
    const idx = sFilter && sFilter.indexOf(sTextLower);
    const initiallyOpen = idx === 0 || sText === this.rootOpen;
    const dctPropsRoot = (arrItemChild.length === 0) ? {} :
    {
      initiallyOpen,
      primaryTogglesNestedList: isRoot,
      nestedItems: arrItemChild,
      innerDivStyle: isRoot ? {color: Colors.clrNimbixDark, fontSize: LayoutDims.FontSize} : {},
    };

    // Divide the filter components, a/b/c -> [a,b,c]
    const arrPath = sText.split('/');
    const dctPropsItem =
    {
      key: AppFilterPane.iListKey,

      leftAvatar: elemIcon,

      // Set click handler for non root items
      onClick: isRoot ? Nop: fnOnClick.bind(this, sText),

      // If its a root item, set the expand handler (which closes all other top level roots)
      onNestedListToggle: isRoot ? this.rootListElemClick.bind(this) : Nop,

      // Show only the last part of the filter path
      primaryText: <div style={Styles.AppFilterText}>{arrPath[arrPath.length-1]}</div>,

      // Save the references for later use
      ref: (ref) => {if(isRoot) {this.listItems[sText] = ref;}},

      // Highlight the list item if it matches the current filter
      // Optionally use a prefix match to allow URL search params to match
      style:
      {
        backgroundColor:
          sFilter === sTextLower || (this.state.matchPrefix && sTextLower.indexOf(sFilter) === 0)
            ? Colors.clrNimbixBlueGray
            : Colors.clrLight
      },
    }

    AppFilterPane.iListKey++;
    return <ListItem {...dctPropsRoot} {...dctPropsItem}/>;
  };

  // Builds up a recursive list item from a dictionary tree
  getAppFilterList = (sClassName, dctClass, fnOnClick, isRoot, elemIcon) =>
  {
    let arrItemChild = [];
    for(const sClass of Object.keys(dctClass).sort((a, b)=>StrICmp(a,b)))
    {
      arrItemChild.push(this.getAppFilterList(sClass, dctClass[sClass], fnOnClick, false));
    }

    return this.listItem(sClassName, arrItemChild, fnOnClick, isRoot, elemIcon);
  }

  // Get a list of vendor names
  getVendorList = (bLicensed) =>
  {
    return this.listItem
    (
      'Vendors',
      this.props.fetcher.getAppVendorList(bLicensed)
      .map
      (
        (sText) => this.listItem
        (
          sText,
          [],
          this.onItemClick.bind(this, 'Vendor', sText),
          false
        )
      ),
      Nop,
      true,
      Icon('business', Styles.ListIcon)
    );
  }

  //Makes a plain list item
  simpleListItem = (sText, sIcon, fnOnClick, selectable = true) =>
  {
    const style =
    {
      backgroundColor:
        selectable && (sText === this.state.activeFilter)
        ?
          Colors.clrNimbixBlueGray
        :
          Colors.clrLight
    };

    return <ListItem leftAvatar={Icon(sIcon, Styles.ListIcon)} onClick={fnOnClick}
                     innerDivStyle={{color: Colors.clrNimbixDark, fontSize: LayoutDims.FontSize}}
                     primaryText={<div style={Styles.AppFilterText}>{sText}</div>}
                     style={style}
                     data-cy={this.itemPrefix + sText}/>;
  }

  // Set the UI based on dctFilter (called when filter is set from URL deep link)
  setFilter(dctFilter)
  {
    let sKey = '';
    let matchPrefix = false;
    if(dctFilter.category)
    {
      this.rootOpen = 'Categories';
      sKey = dctFilter.category;
    }

    if(dctFilter['community-category'])
    {
      this.rootOpen = 'Community';
      sKey = dctFilter['community-category'];
    }

    if(dctFilter['author'])
    {
      this.rootOpen = 'Vendors';
      sKey = dctFilter['author'];
      matchPrefix = true;
    }

    if(sKey)
    {
      this.setState({activeFilter: sKey, rootOpen: this.rootOpen, matchPrefix});
    }
  }

  onSelectFileMan = () => GlobalUI.PaneAppCard.openWithApp(FileManagerApp);

  render()
  {
    const fetcher = this.props.fetcher;
    fetcher.makeAppClassificationTree(this.state.bLicensed);

    const listCats =
      this.getAppFilterList('Categories', fetcher.certTree, this.onSelectClass, true,
        Icon('dashboard', Styles.ListIcon));

    const listCommunity =
      this.getAppFilterList('Community', fetcher.nonCertTree, this.onSelectCommunity, true,
        Icon('people', Styles.ListIcon));

    const listVendors = this.getVendorList(this.state.bLicensed)

    // License toggler control
    const togglerProps =
    {
      icon: 'vpn_key',
      onToggle: this.props.onSelectLicense,
      labelStyle: {color: Colors.clrNimbixDark, fontSize: LayoutDims.FontSize},
      style: {width: 'calc(100% - 72px)'},
      label: 'License Included',
    }

    const hasCommunity = (Object.keys(this.props.fetcher.nonCertTree).length) > 0;

    return MUI(
      <List style={{paddingTop: 1, overflow: 'auto', height: 'calc(100% + 8px)'}}>

        {this.props.prefixPane}

        <ListItem disabled style={Styles.MenuTitle}>Filter Applications</ListItem>

        <Divider/>
        <Toggler {...togglerProps}/>

        <Divider/>

        {
          fetcher.tag !== 'PTC' && (FileManagerApp in fetcher.data) &&
          this.simpleListItem('File Manager', 'folder', this.onSelectFileMan, false)
        }

        <Divider/>
        {this.simpleListItem('All Applications', 'clear_all', this.onSelectAll)}

        {
          fetcher.tag !== 'PTC' &&
          <div>
            <Divider/>
            {this.simpleListItem('My Applications', 'perm_identity', ()=>this.onSelectOwned(true))}

            {
              fetcher.hasTeamApps &&
              <div>
                <Divider/>
                {this.simpleListItem('Team Applications', 'recent_actors', ()=>this.onSelectTeam(true))}
              </div>
            }
          </div>
        }

        <Divider/>
        {listCats}

        {hasCommunity ? <Divider/> : null}
        {hasCommunity ? listCommunity : null}

        <Divider/>
        {listVendors}

        <Divider/>

      </List>
    );
  }
}
