import * as jquery from 'jquery';

export const assignIf = (dct, key, val) =>
{
  if(val)
  {
    dct[key] = val;
  }
};

export const StrICmp = (a, b) =>
{
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();
  return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
}

export const IntCmp = (a, b) =>
{
  const intA = parseInt(a, 10);
  const intB = parseInt(b, 10);
  return intA < intB ? -1 : (intA > intB ? 1 : 0);
}

// zero pads a number to 2 digits
function padTo2Digits(number)
{
  if(number <= 9)
  {
    return '0' + number;
  }
  return number;
}

// Format UNIX time_t as YYYY MM DD HH MM SS, browser doesn't honor OS settings
export const dateToLocalTimeStr = (time_t) =>
{
  const dt = new Date(time_t * 1000);

  const yyyy = dt.getFullYear();
  const mm = padTo2Digits(dt.getMonth() + 1);
  const dd = padTo2Digits(dt.getDate());
  const HH = padTo2Digits(dt.getHours());
  const MM = padTo2Digits(dt.getMinutes());
  const SS = padTo2Digits(dt.getSeconds());

  // use non breaking hypens
  return yyyy + '‑' + mm + '‑' + dd + ' ' + HH + ':' + MM + ':' + SS;
}

export const numToText = (num, unit) =>
{
  return num + ' ' + unit + (num > 1 ? 's' : '');
}


// Given rows in an array of dicts arrRows,
// Filter the rows whose field values have sSearch as a substring
// Case insensitive
export const filterRows = (arrRows, sSearch, iMinSearchCol = 0) =>
{
  let arrFiltered = arrRows;
  if(sSearch)
  {
    arrFiltered = arrFiltered.filter
    (
      (row, i) =>
      {
        for(const k of Object.keys(row).slice(iMinSearchCol))
        {
          if(row[k] && row[k].toUpperCase().indexOf(sSearch) >= 0)
          {
            return true;
          }
        }
        return false;
      }
    );
  }

  return arrFiltered;
}


// Given a list of users separated by comma or newlines,
// cleans it by removing leading/trailing whitespaces for each element,
// then sorting and uniq-izing the entries
// Returns back a cleaned CSV string
export const cleanUserWhiteList = (sList) =>
{
  // Split on comma or newline
  let arrList = sList.split(/[,\n]/);

  // trim the elements
  arrList.forEach(e => e.trim());

  // sort, remove duplicates
  arrList = arrList.sort().filter
  (
    (item, pos, ary) =>
      item && item.trim().length && (!pos || item !== ary[pos - 1])
  );

  // Rejoin with commas and return
  return arrList.join(',');
}

// Gets the current hostname (adjusting for development env)
export const getHost = () =>
{
  return process.env.REACT_APP_MC_DEV === 'devtest' ?
    window.location.protocol + '//' + window.location.hostname :
    window.location.origin;
}

// Ultimate deep copy for nested DS
export const deepCopy = (x) => JSON.parse(JSON.stringify(x))


export const pluralize = (unit, num) => num + ' ' + unit + (num > 1 ? 's' : '')


// Convert a value in KB to GB (2 decimals), optionally rounding down to nearest int (no decimals)
export const asGB = (val, rounded) =>
{
  const ret = val / 1048576;
  return rounded ? (ret | 0) : ret.toFixed(2);
}

// Formats a job metrics memory usage summary
export const getMemSummaryText = (metrics) =>
{
  const sMemUsed = asGB(metrics.summary.memory_used);
  const sMemTotal = asGB(metrics.summary.memory_total, true);
  return sMemUsed + ' of ' + sMemTotal + 'GB';
}

export const noWrap = (x) => x.replace(/\s/g, '\xa0')


export const getEscapedDataURI = (x) => 'data:text/plain;charset=utf-8;base64,' + btoa(x);


// Extracts a specific hint value given array of hint strings [key=value, key2=value2 ,...]
export const getAppHint = (arrHints, sKey) => {
    const sHint = arrHints && arrHints.find(s=>s.indexOf(sKey+':') === 0);
    return sHint && sHint.substr(sKey.length+1)
  }


// Helper for copying text to clipboard
export const copyToClipboard = (txt) =>
{
  const elemClipper = document.getElementById('clipper');
  elemClipper.value = txt;
  jquery.default(elemClipper).select();
  document.execCommand('copy');
  elemClipper.value = '';
}

// Tells if an item is entirely visible -
// Size should be non-zero and the entire elem should be within the window viewport
export const isElementVisible = (el, minY) =>
{
  const rcBounds = el.getBoundingClientRect();
  let isNonZero = rcBounds.width > 0 && rcBounds.height > 0;
  let isHFit = rcBounds.left > 0 && rcBounds.right < window.innerWidth;
  let isVFit = rcBounds.top > minY && rcBounds.bottom < window.innerHeight;
  return isNonZero && isHFit && isVFit;
}
