import React, {Component} from 'react';

import FloatingActionButton from 'material-ui/FloatingActionButton';
import RaisedButton from 'material-ui/RaisedButton';

import {Separator, SearchBox, Icon} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, BusyHelper, GlobalUI} from '../Global';
import {Data, DataFetcher, MachineDataFetcher, AdminMachineDataFetcher, PriceStr} from '../Models/Model';

const StyleTD = {...Styles.TableCellBordered, fontSize: 12, padding: LayoutDims.nMargin/2, height: LayoutDims.nMargin * 4};

// Gets the list of architectures
const GetArch = () =>
{
  const dct = {};
  const n = Data.MachineArchs.arch.length
  for(let i = 0; i < n; ++i)
  {
    const sArch = Data.MachineArchs.arch[i];
    const sDesc = Data.MachineArchs.archdesc[i];
    const sLabel = sArch + ' - ' + sDesc;
    dct[sLabel] = sArch;
  }

  return dct;
}

// gets the list of clusters
const GetClusters = () =>
{
  const dct = {};
  const arrNames = Object.keys(Data.Clusters)
  for(const sName of arrNames)
  {
    const sID = Data.Clusters[sName]['id'];
    const sDesc = Data.Clusters[sName]['desc'];
    const sText = sID + (sDesc ? ' - ' + sDesc : '')
    dct[sText] = sID
  }

  return dct;
}


// List of fields (with type and description) that define a machine (lifted from models.py)
const MachineFields =
{
  mc_name:             {kind: 'text', desc: 'Machine name'},
  mc_description:      {kind: 'text', desc: 'Description (required)'},
  mc_cores:            {kind: 'int',  desc: 'No. of CPU cores'},
  mc_slots:            {kind: 'int',  desc: 'No. of execution slots jobs should request'},
  mc_gpus:             {kind: 'int',  desc: 'Number of GPU cores'},
  mc_ram:              {kind: 'int',  desc: 'RAM in GB'},
  mc_swap:             {kind: 'int',  desc: 'Swap space in GB'},
  mc_scratch:          {kind: 'int',  desc: 'Local scratch disk space in GB'},
  mc_properties:       {kind: 'text', desc: 'Comma separated list of properties to request from scheduler'},
  mc_devices:          {kind: 'text', desc: 'TODO'},
  mc_slave_properties: {kind: 'text', desc: 'Comma separated list of properties to request for slave nodes from scheduler, if different from properties above'},
  mc_slave_gpus:       {kind: 'int',  desc: 'No. of GPUs to request for slaves'},
  mc_slave_ram:        {kind: 'int',  desc: 'RAM to request for slaves'},
  mc_scale_min:        {kind: 'int',  desc: 'Min No. of cores assignable to a job'},
  mc_scale_max:        {kind: 'int',  desc: 'Max No. of cores assignable to a job'},
  mc_scale_select:     {kind: 'text', desc: 'Comma separated list of numbers to customize selections between scale_min and scale_max'},
  mc_lesser:           {kind: 'int',  desc: 'non 0 if this machine can run jobs for lesser machines'},
  mc_price:            {kind: 'num',  desc: 'Machine price per hour'},
  mc_priority:         {kind: 'int',  desc: 'Machine priority'},
  mc_privs:            {kind: 'arr',  desc: 'Comma separated list of privileges required'},
  mc_arch:             {kind: 'opts', desc: 'System architecture', opts: GetArch},
  mc_sched_id:         {kind: 'opts', desc: 'Machine Cluster ID',  opts: GetClusters}
};


//Dialog for editing or adding a machine
class MachineDlg extends Component
{
  static StyleTD = {...Styles.TableCellBordered, fontSize: 16, padding: 2, height: LayoutDims.nMargin * 2};

  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  // When reopened reset state to new props
  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps});
  }

  // On update and rerender, set the values
  componentDidUpdate() { this.setInputValues(); }
  componentDidMount()  { this.setInputValues(); }

  // Sets the machine fields if editing
  setInputValues()
  {
    for(const e of Object.keys(MachineFields))
    {
      // Numbers are formatted as x.xx
      if(this.state.data && this.state.action !== 'Add')
      {
        let val = this.state.data[e];
        const kind = MachineFields[e].kind;

        // Array
        if(kind === 'arr')
        {
          val = val.join(',');
        }
        // Number
        else if(kind === 'num')
        {
          val = val.toFixed(2);
        }

        // Set the value and old value
        this.refs[e].value = val;
        this.refs[e].setAttribute('oldValue', val);
      }
      else
      {
        this.refs[e].setAttribute('oldValue', '');
        this.refs[e].value = '';
      }
    }

    // Blank out name for clone
    if(this.state.action === 'Clone')
    {
      this.refs.mc_name.value = '';
    }
  }

  // Commit add or save
  onSave = () =>
  {
    // Gather all the non-changed field values (except name)
    // If adding or cloning, get everything
    const data = {mc_name: this.refs.mc_name.value};
    for(const sField of Object.keys(MachineFields))
    {
      // Get new value
      const elem = this.refs[sField];
      const val = elem.value;

      // If it's not Edit or else if it changed, we save it
      if(this.state.action !== 'Edit' || val !== elem.getAttribute('oldValue'))
      {
        data[sField] = val;
      }
    }

    // Fire the request
    const sURL = this.state.action === 'Edit' ? '/portal/admin-machine-update' : '/portal/admin-machine-add';
    const fetcher = new DataFetcher(sURL, data)
      .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
      .whenDone
      (
        // On success, close dlg and refresh parent
        ()=>
        {
          GlobalUI.Dialog.onClose();
          this.state.parent.refetch();
        }
      );

    FetchBusy(fetcher, 'Saving machine...');
  }

  render()
  {
    return (
      <div>
        <div style={{maxHeight: 400, minHeight: 400, overflowY: 'auto'}}>
          <table style={{...Styles.Table, tableLayout: 'fixed', width: '100%'}}>
            <colgroup>
              <col style={{width: '30%'}}/>
            </colgroup>

            <thead>
              <tr>
                <th style={{...Styles.TableHeader, fontSize: 14}}>Key</th>
                <th style={{...Styles.TableHeader, fontSize: 14}}>Value</th>
              </tr>
            </thead>

            <tbody>
            {
              Object.keys(MachineFields).map
              (
                (e) =>
                {
                  let inputProps = {};
                  const kind = MachineFields[e].kind;
                  let elemOpts ;

                  // integers are min 0
                  if(kind === 'int')
                  {
                    inputProps = {type: 'number', min: 0}
                  }
                  // Numbers are constrained to 0.01 increments
                  else if(kind === 'num')
                  {
                    inputProps = {type: 'number', min: 0, step: '0.01'}
                  }
                  // Option list - map of text to value
                  else if(kind === 'opts')
                  {
                    const dctOpts = MachineFields[e].opts();
                    const dctNames = Object.keys(dctOpts)
                    dctNames.sort();
                    elemOpts = dctNames.map
                    (
                      (e, i) => <option key={i} value={dctOpts[e]}>{e}</option>
                    );
                  }

                  const styleInput =
                  {
                    border: 'none',
                    padding: 4,
                    width: 'calc(100% - 10px)'
                  };

                  // When editing, make the name field readonly bold green
                  if(e === 'mc_name' && this.props.action === 'Edit')
                  {
                    inputProps.readOnly = true;
                    styleInput.color = Colors.clrNimbixDarkGreen;
                    styleInput.fontWeight = 'bold';
                  }

                  return (
                    <tr key={e}>
                      <td style={MachineDlg.StyleTD}>{e.substr(3)}</td>
                      <td style={MachineDlg.StyleTD}>
                      {
                        kind === 'opts'
                        ?
                          <select ref={e} style={styleInput} title={MachineFields[e].desc}>{elemOpts}</select>
                        :
                          <input ref={e}
                                 style={styleInput}
                                 title={MachineFields[e].desc}
                                 placeholder={MachineFields[e].desc}
                                 {...inputProps}
                          />
                      }
                      </td>
                    </tr>
                  );
                }
              )
            }
            </tbody>
          </table>

        </div>

        <Separator units={2}/>

        <div style={Styles.FlexSpread}>
          &nbsp;
          <RaisedButton {...Btns.Blue} label='Save' onClick={this.onSave}/>
        </div>
      </div>
    );
  }
}

export default class AdminMachines extends Component
{
  // First empty column is for the delete and clone buttons
  static arrColField = ['', 'mc_name', 'mc_arch', 'mc_sched_id', 'mc_description', 'mc_price'];
  static arrFilterCols = ['mc_name', 'mc_description'];
  static arrColText = [' ', 'Name', 'Architecture', 'Cluster', 'Description', 'Price'];

  // TODO: Get rid of cluster field

  constructor(props)
  {
    // Assumes Data.MachinesAll has data initially
    super(props);
    this.state = {mcs: Data.MachinesAll, search: null, edit: false, sortcol: 'mc_name', sortdir: 1};

    AdminMachineDataFetcher.whenDone
    (
      (jqXHR) =>
      {
        Data.MachinesAll = jqXHR.responseJSON;
        this.setState({mcs: Data.MachinesAll});

        // Might be busy from our own refetch
        BusyHelper.Unbusy();
      }
    )

    this.refetch();
  }

  // Success handler, reload data (also reload the user machines)
  refetch = () =>
  {
    BusyHelper.BusyStatus('Refreshing...');
    AdminMachineDataFetcher.fetch();

    // Reload MachineDataFetcher so that Compute etc are up to date
    MachineDataFetcher.fetch();
  }

  onSearchClear = () =>
  {
    this.refs.searchBox.inputField.value = '';
    this.setState({search: null});
  }

  onSearchIncr = (s) =>
  {
    this.setState({search: s.trim().toUpperCase()});
  }

  onDelMC = (evt) =>
  {
    const mc_name = evt.target.parentElement.parentElement.getAttribute('name');
    GlobalUI.DialogConfirm.confirm
    (
      <div>
        Are you sure you wish to delete the machine <b>{mc_name}</b>?<br/><br/>
        <div className='errortext' style={{fontSize: 14}}>
          Warning:<br/>
          This may cause integrity issues with applications and nodes that refer to this machine!
        </div>
      </div>,

      'Confirm Delete',
      () =>
      {
        const fetcher = new DataFetcher('/portal/admin-machine-del', {mc_name});
        fetcher
          .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Delete Failed'))
          .whenDone(this.refetch);

        FetchBusy(fetcher, 'Deleting machine...');
      }
    );
  }

  // Called on edit or clone
  onMCAction = (evt) =>
  {
    const elemDiv = evt.target.parentElement;
    const sAction = elemDiv.getAttribute('title');
    const sMCName = elemDiv.parentElement.getAttribute('name');
    this.doMCAction(sAction, this.state.mcs[sMCName]);
  }

  // Add/Edit ot Clone
  doMCAction = (action, mc) =>
  {
    // If we editing or cloning, send the machine data
    let props = {parent: this, action, data:  (action !== 'Add') ? mc : null};
    GlobalUI.Dialog.show
    (
      action + ' Machine',
      <MachineDlg {...props}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  // Add a machine
  onAddMC = (evt) =>
  {
    this.doMCAction('Add');
  }

    // When name input field loses focus, revert the value
  onInputLeave = (evt) =>
  {
    if(this.state.edit)
    {
      evt.target.value = evt.target.defaultValue;
    }
  }

  // When input field is clicked it becomes editable
  onEdit = (evt) =>
  {
    this.setState({edit: evt.target.value});
  }

  onInputKeyPress = (evt) =>
  {
    const mc_newname = evt.target.value;
    const mc_name = evt.target.defaultValue;
    if(evt.key === 'Enter' && mc_name !== mc_newname)
    {
      // Hang on to this event for ifFail() handler below to use it later
      evt.persist();

      GlobalUI.DialogConfirm.confirm
      (
        <div>
          Are you sure you wish to rename the machine <b>{mc_name}</b> to <b>{mc_newname}</b>?<br/><br/>
          <div className='errortext' style={{fontSize: 14}}>
            Warning:<br/>
            This may cause integrity issues with applications and nodes that refer to this machine!
          </div>
        </div>,

        'Confirm Rename',
        () =>
        {
          const fetcher = new DataFetcher('/portal/admin-machine-rename', {mc_newname, mc_name});
          fetcher
            .whenDone(this.refetch)
            // If failed, restore the old name
            .ifFail
            (
              (jqXHR) =>
              {
                GlobalUI.Dialog.showErr(jqXHR, 'Error Renaming Machine')
                evt.target.value = mc_name;
              }
            );

          FetchBusy(fetcher, 'Renaming...');
          this.setState({edit:null});
        }
      );
    }
  }

  // Header click handler for sorting
  onClickHeader = (evt) =>
  {
    const sortcol = evt.target.getAttribute('name');

    // IF same field clicked, reverse the sort direction
    if(sortcol === this.state.sortcol)
    {
      this.setState({sortdir: -this.state.sortdir});
    }
    // Otherwise sort on this column ascending
    else
    {
      this.setState({sortcol, sortdir: 1});
    }
  }

  // renders a column of the table (some columns need specialization)
  renderCol(mc, col, iCol)
  {
    let elem = null;

    // First col has delete and clone buttons
    if(!col)
    {
      elem =
        <div style={{...Styles.Inline, cursor: 'pointer', justifyContent: 'space-around'}}
             name={mc.mc_name} >

          <div title='Clone' onClick={this.onMCAction}>
            {Icon('content_copy', {fontSize: 18})}
          </div>

          <div title='Edit' onClick={this.onMCAction}>
            {Icon('edit', {color: Colors.clrNimbixDarkGreen, fontSize: 18})}
          </div>

          <div title='Delete' onClick={this.onDelMC}>
            {Icon('cancel', {color: Colors.clrNimbixDarkRed, fontSize: 18})}
          </div>

        </div>
    }
    // Format prices properly
    else if(col === 'mc_price')
    {
      elem = <div>{PriceStr(mc[col])}</div>
    }
    // Name is directly editable
    else if(col === 'mc_name')
    {
      let style = {padding: 4, width: '90%'};
      style = {...style, ...(this.state.edit === mc['col'] ? Styles.Input : {border: 'none'}) };
      const props = this.state.edit ? {defaultValue: mc[col]} : {readOnly: 'readOnly', defaultValue: mc[col]};
      elem = <input {...props}
                    title={this.state.edit ? 'Press <Enter> to rename' : 'Click to edit'}
                    ref={'input-' + col}
                    style={style}
                    onClick={this.onEdit}
                    onBlur={this.onInputLeave}
                    onKeyPress={this.onInputKeyPress}/>;
    }
    else
    {
      elem = <div>{mc[col]}</div>
    }

    return <td key={'col' + iCol} style={StyleTD}>{elem}</td>;
  }

  render()
  {
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 160);
    let arrFilterMCs = Object.keys(this.state.mcs);

    // If we have a search string, filter the mcs that have the search string in
    // the fields specified in arrFilterCols
    if(this.state.search)
    {
      arrFilterMCs = arrFilterMCs.filter
      (
        (sMCName) =>
        {
          const mc = this.state.mcs[sMCName];
          for(const sField of AdminMachines.arrFilterCols)
          {
            if(mc[sField] && mc[sField].toUpperCase().indexOf(this.state.search) >= 0)
            {
              return true;
            }
          }
          return false;
        }
      );
    }

    // Sort the data according to sortdir, sortcol
    arrFilterMCs = arrFilterMCs || [];

    arrFilterMCs.sort
    (
      (a, b) =>
      {
        const valA = String(this.state.mcs[a][this.state.sortcol]).toUpperCase();
        const valB = String(this.state.mcs[b][this.state.sortcol]).toUpperCase();
        return this.state.sortdir * (valA < valB ? -1 : (valA > valB ? 1 : 0));
      }
    );

    // Render the rows
    const arrMCRows = arrFilterMCs.map
    (
      (name, i)=>
      {
        const mc = this.state.mcs[name];
        return (
          <tr key={mc.mc_name} name={mc.mc_name}>
            {
              AdminMachines.arrColField.map
              (
                (col, j) => this.renderCol(mc, col, j, i)
              )
            }
          </tr>
        );
      }
    );

    return (
      <div>

        <div style={Styles.FlexSpread}>
          <SearchBox ref='searchBox' onSearch={this.onSearchIncr} onClear={this.onSearchClear} width='50%'/>

          <FloatingActionButton mini onClick={this.onAddMC}>
            {Icon('add', {color: Colors.clrLight})}
          </FloatingActionButton>
        </div>

        <Separator units={3}/>

        <div style={{maxHeight: hAvail, minHeight: hAvail, overflowY: 'auto'}}>
          {
            (
              this.state.mcs &&
              <table style={{...Styles.Table, tableLayout: 'fixed', width: '100%'}}>

                <colgroup>
                  <col style={{width: '8%'}}/>
                  <col style={{width: '15%'}}/>
                  <col style={{width: '15%'}}/>
                  <col style={{width: '15%'}}/>
                  <col style={{width: '50%'}}/>
                  <col style={{width: '12%'}}/>
                </colgroup>

                <thead>
                  <tr>
                  {
                    AdminMachines.arrColText.map
                    (
                      (e, i) =>
                      {
                        const sFieldName = AdminMachines.arrColField[i];
                        return(
                          <th key={i} style={{...Styles.TableHeader, fontSize: 12, cursor: 'pointer'}}
                              onClick={this.onClickHeader} name={sFieldName}>
                            {e}
                            <div style={{float: 'right', minWidth: 8}}>
                              {
                                // Sort indicator
                                this.state.sortcol === sFieldName
                                ?
                                  this.state.sortdir > 0 ? '▴' : '▾'
                                :
                                  ''
                              }
                            </div>
                          </th>
                        );
                      }
                    )
                  }
                  </tr>

                </thead>

                <tbody>
                  {
                    // If we have entries show them
                    (arrMCRows.length && arrMCRows)
                    ||
                    // else show "No matches"
                    <tr>
                      <td style={{...StyleTD, textAlign: 'center'}} colSpan='5' >No matches</td>
                    </tr>
                  }
                </tbody>
              </table>
            )
          }
        </div>
      </div>
    );
  }
}
