// Core
import React, {Component} from 'react';

import {Styles, LayoutDims, Colors} from './UIConst';
import {StatsFetcher, Data, IsTeamAdmin} from '../Models/Model';
import {GlobalUI} from '../Global';
import {Icon} from '../Components/Components';

// Collapsible app view, with filtering
export default class UserStatsPane extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      stats: Data.User.Stats,
      key: Data.User.Profile.user_login,
      color: '#000000',
      showMode: 'time'
    };

    StatsFetcher.whenDone
    (
      ()=>
      {
        // Flash the color to blue, so it attracts the users attention if something changed
        if(this.state.stats && this.state.stats['@'].job_count !== StatsFetcher.data['@'].job_count)
        {
          if(this.state.color === '#000000')
          {
            window.setTimeout(()=>{this.setState({color: Colors.clrNimbixMed})}, 100);
            window.setTimeout(()=>{this.setState({color: '#000000'})}, 1000);
          }
        }
        this.setState({stats: StatsFetcher.data});
      }
    );
  }

  componentDidMount()
  {
    // Save this in the Global UI for others to use
    GlobalUI.UserStatsPane = this;
    if(!this.state.stats)
    {
      this.fetchStats();
    }
  }

  fetchStats = () =>
  {
    StatsFetcher.fetch();
  }

  onSelectStats = () =>
  {
    const key = this.refs.selectStat.value;
    this.setState({key: key});
  }

  // the "time" icon cant have a name attribute
  onSelectMode = (evt) => {
    this.setState({showMode: evt.target.parentElement.getAttribute('name')});
  }

  render()
  {
    const styleHead =
    {
      ...Styles.Bordered,
      fontSize: 16,
      backgroundColor: '#EEEEEE',
      padding: LayoutDims.nMargin,
      textAlign: 'center',
    };

    const styleVal =
    {
      ...Styles.Bordered,
      fontSize: 30,
      padding: LayoutDims.nMargin,
      textAlign: 'center',
      color: this.state.color
    };

    if(!this.state.stats)
    {
      return null;
    }

    // Stats contains "@" entry representing the team total
    // and an entry for each member of the team
    // Non team owners will therefore have 2 entries
    const arrItems = [...Object.keys(this.state.stats)].sort();
    const hasTeam = arrItems.length > 2;
    const sMe = Data.User.Profile.user_login;
    let members = [];

    const colorCurrency = this.state.showMode === 'currency' ? Colors.clrNimbixDark : Colors.clrNimbixDarkGray;
    const colorTime = this.state.showMode === 'time' ? Colors.clrNimbixDark : Colors.clrNimbixDarkGray;
    const colorCPU = this.state.showMode === 'cpu' ? Colors.clrNimbixDark : Colors.clrNimbixDarkGray;

    const styleIconCurrency = { fontFamily: 'monospace', fontSize: LayoutDims.FontSize * 1.75, cursor: 'pointer', color: colorCurrency};
    const styleIconTime = {marginTop: 2, cursor: 'pointer', color: colorTime};
    const styleIconCPU = {marginTop: 2, marginRight: 4, cursor: 'pointer', color: colorCPU};

    // Member stats only for team admins
    if(IsTeamAdmin())
    {
      // key=i is fine here because each i is a unique username
      for(const i of arrItems)
      {
        if(i !== sMe && i !== '@')
        {
          members.push(<option key={i} value={i}>{i}</option>);
        }
      }
    }

    return (
      <div style={{fontSize: 'large', marginBottom: LayoutDims.nMargin * 4}}>
        <div>
          <div style={{padding:16, color: Colors.clrLight, backgroundColor: Colors.clrNimbixDark}}>
            Stats
          </div>

          {
            hasTeam
            ?
              <div style={{...Styles.Inline}}>
                <select ref='selectStat' onChange={this.onSelectStats} style={{...Styles.ParamInput, borderColor: Colors.clrNimbixMed, fontSize: 16, flex: 1, margin: LayoutDims.nMargin}}>
                  <option value={sMe}>&#8226; My stats</option>
                  {
                    // Team total stats only for team admins
                    IsTeamAdmin() && <option value='@'>&#8226; Team total</option>
                  }
                  {members}
                </select>
              </div>
            :
              null
          }
        </div>

        <div style={styleHead}>
          JOBS RUN
        </div>

        <div style={styleVal}>
          {this.state.stats[this.state.key].job_count}
        </div>

        <div style={styleHead}>
          <span style={Styles.InlineFlexRow}>
            <span onClick={this.onSelectMode} title='CPU Time' name='cpu'>
              {Icon('computer', styleIconCPU)}
            </span>

            <span onClick={this.onSelectMode} title='Wall Time' name='time'>
              {Icon('access_time', styleIconTime)}
            </span>

            <span style={{marginLeft: LayoutDims.nMargin * 2, marginRight: LayoutDims.nMargin * 2}}>
              COMPUTE
            </span>

            <span onClick={this.onSelectMode} style={styleIconCurrency} title='Accrued Cost' name='currency'>
              <div>{Data.User.Profile.currency_symbol}&nbsp;&nbsp;&nbsp;</div>
            </span>
          </span>
        </div>
        <div style={styleVal}>
        {
          this.state.stats[this.state.key]
          [
            this.state.showMode === 'currency' ?
            'compute_cost' :
              this.state.showMode === 'time' ?
                'compute_time' :
                'cpu_time'
          ]
        }
        </div>

        {
          hasTeam
          ?
            <div style={{...styleVal, fontSize: 16}}>
              &nbsp;
              {this.state.stats[this.state.key].user_email}
            </div>
          :
            null
        }

      </div>

    );
  }
}
