// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Our UI data, styles and colors
import {Styles, LayoutDims, Colors} from './UIConst.js';

export class TaskBuilderParamSlider extends Component
{
  static propTypes =
  {
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    step: PropTypes.number,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
    paramName: PropTypes.string,
  };

  static styleDisplay = null;
  static styleSliderCtr = null;

  constructor(props)
  {
    super(props);
    this.state =
    {
      displayValue: props.min,
      tickFreq: 0
    };

    this.tickId= '#' + Math.random();

    this.calcWidth = props.extra ? `calc(80% - ${props.extra}px)` : '80%';
    this.dctSliderProps =
    {
      ref: el=>this.sliderField = el,
      type: 'range',
      onChange: this.onSliderChange,
    };
  }

  componentWillReceiveProps(nextProps)
  {
    // Did anything change?
    // Treat very first time as changed too
    this.changed = this.changed == null ||
                   nextProps.min !== this.props.min ||
                   nextProps.max !== this.props.max ||
                   nextProps.step !== this.props.step;

    if(this.changed)
    {
      const range = nextProps.max - nextProps.min;
      const nSteps = range / nextProps.step;
      this.setState({tickFreq: nSteps < 32 ? nextProps.step : 0})
    }
  }

  // After react is done rendering
  componentDidUpdate()
  {
    // If anything changed, put the slider to min/default
    if(this.changed || this.changed == null)
    {
      this.changed = false;
      let val = this.props.defaultValue;
      val = val >= this.props.min && val <= this.props.max ? val : this.props.min
      this.setValue(val);
    }
  }

  scrollIntoView()
  {
    this.sliderField.scrollIntoView();
  }

  getValue = () =>
  {
    return this.sliderField.value;
  }

  // If value is set externally set the text display and slider
  setValue = (value) =>
  {
    this.sliderField.value = value;
    this.inputField.value = value;
    this.inputField.blur();
  }

  // When slider is changed, update the text display and tell the parent
  onSliderChange = () =>
  {
    const displayValue = this.sliderField.value;
    this.inputField.value = displayValue;

    if(this.props.onChange)
    {
      this.props.onChange(displayValue);
    }
  };

  setValueFromInput = () =>
  {
    // Get the number and make it a multiple of step
    let val = parseInt(this.inputField.value, 10);

    if(isNaN(val))
    {
      val = this.props.min;
    }
    else
    {
      val /= this.props.step;
      val = Math.floor(val) * this.props.step;

      // Clip the value to the allowed range
      val = Math.min(val, this.props.max);
      val = Math.max(val, this.props.min);
    }

    // Set the value
    this.setValue(val);

    if(this.props.onChange)
    {
      this.props.onChange(val);
    }
  }

  onInputKeyPress = (evt) =>
  {
    //console.log(evt.key);
    if(evt.key === 'Enter')
    {
      this.setValueFromInput();
    }
    else
    {
      if(!(evt.key >= '0' && evt.key <= '9'))
      {
        evt.preventDefault();
      }
    }
  }

  render()
  {
    // Init static styles
    if(!TaskBuilderParamSlider.styleDisplay)
    {
      TaskBuilderParamSlider.styleDisplay =
      {
        ...Styles.ParamInput,
        minWidth: 32,
        height: 'auto',
        flex: 0,
        textAlign: 'right',
        fontSize: 15,
        marginTop: LayoutDims.nMargin,
      };

      TaskBuilderParamSlider.styleSliderCtr =
      {
        cursor: 'pointer',
        marginLeft: LayoutDims.nMargin * 2,
        marginRight: LayoutDims.nMargin * 2,
        flex: 1
      };
    }

    let elemTicks = [];
    if(this.state.tickFreq)
    {
      // key=x is fine here because all options are unique and never change
      for(let x = this.props.min; x <= this.props.max; ++x)
      {
        elemTicks.push(<option key={x}>{x}</option>);
      }
    }

    const hasRange = this.props.min !== this.props.max;
    const dctVisible = hasRange ? {} : {opacity: 0.2};

    return (
      <div style={{...Styles.Inline, width: this.calcWidth, alignItems: 'flex-start'}}>
        <input ref={el=>this.inputField = el}
               data-cy={this.props.paramName}
               defaultValue={this.state.displayValue}
               onKeyPress={this.onInputKeyPress}
               inputMode='numeric'
               onBlur={this.setValueFromInput}
               style={TaskBuilderParamSlider.styleDisplay}/>

        <div style={TaskBuilderParamSlider.styleSliderCtr}>

          <datalist id={this.tickId}>
            {elemTicks}
          </datalist>

          <input {...this.dctSliderProps}
                 list={this.tickId}
                 min={this.props.min}
                 max={hasRange ? this.props.max : this.props.min + 1}
                 disabled={!hasRange}
                 defaultValue={hasRange ? this.props.defaultValue : this.props.min}
                 step={this.props.step || 1}
                 style={{...dctVisible, width: '100%'}}/>

          <div style={{...Styles.FlexSpread, fontSize: 14, width: '100%',
              fontWeight: 'bold', color: Colors.clrNimbixDarkGreen}}>
            <div>{hasRange ? this.props.min : ' '}</div>
            <div>{hasRange ? this.props.max : ' '}</div>
          </div>

        </div>


      </div>
    );
  }
}
