import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {MUI, SwitchedPane, SectionViewHeader} from './Components';
import {LayoutDims} from './UIConst.js';
import {GlobalUI} from '../Global';

import AdminUsers from './AdminUsers';
import AdminJobs from './AdminJobs';
import AdminApps from './AdminApps';
import AdminZones from './AdminZones';
import AdminMachines from './AdminMachines';
import AdminClusters from './AdminClusters';
import AdminMCStats from './AdminMCStats';
import AdminBilling from './AdminBilling';
import AdminDiscounts from './AdminDiscounts';
import AdminZoneUsers from './AdminZoneUsers';

import AdminLogs from './AdminLogs';
import AdminLimits from './AdminLimits';
import AdminHPCQueues from './AdminHPCQueues';
import AdminMetaData from './AdminMetaData';
import AdminLicenses from './AdminLicenses';

function HeaderPane(props)
{
  return (
    <div>
      <SectionViewHeader headerText={props.headerText}/>
      <div style={{padding: LayoutDims.nMargin * 2}}>
        {props.children}
      </div>
    </div>
  );
}

HeaderPane.propTypes =
{
  headerText: PropTypes.string.isRequired
}


// Helper component to reduce code size for panels
const AdminPane = (props) =>
{
  return(
    <SwitchedPane iconName={props.iconName} paneGroup='AdminContent' paneName={props.paneName}>
      <HeaderPane headerText={props.headerText}>
        {props.children}
      </HeaderPane>
    </SwitchedPane>
  );
}

// Collapsible app view, with filtering
export default class Admin extends PureComponent
{
  static adminIcons = ['account_circle', 'settings', 'widgets',             'language',     'device_hub',      'computer',        'description', 'tune',            'queue'            ,  'recent_actors', 'lock_open'];
  static adminNames = ['Users',          'Jobs',     'Apps',                'Zones',        'Clusters',        'Machines',        'Logs',        'Limits',          'HPC Queues'       ,  'Metadata',      'License Manager'];
  static adminTexts = ['Users',          'Jobs',     'Manage Applications', 'Manage Zones', 'Manage Clusters', 'Manage Machines', 'Logs',        'Resource Limits', 'Manage HPC Queues',  'User Metadata', 'License Manager Configuration'];

  static billerIcons = ['assessment', 'credit_card', 'local_atm', 'folder_shared'];
  static billerNames = ['Stats', 'Billing', 'Discounts', 'Users By Zone'];
  static billerTexts = ['Statistics', 'Billing', 'Discounts', 'Users By Zone'];

  constructor(props)
  {
    super(props);
    SwitchedPane.setDefault(['AdminContent'], 'Users');
  }

  switchPane = (pane) =>
  {
    SwitchedPane.switch('AdminContent', pane);
  }

  componentWillMount()
  {
    this.adminPanes =
    [
      <AdminUsers/>,
      <AdminJobs/>,
      <AdminApps/>,
      <AdminZones/>,
      <AdminClusters/>,
      <AdminMachines/>,
      <AdminLogs/>,
      <AdminLimits/>,
      <AdminHPCQueues/>,
      <AdminMetaData/>,
      <AdminLicenses/>,
    ];

    this.billerPanes =
    [
      <AdminMCStats/>,
      <AdminBilling/>,
      <AdminDiscounts/>,
      <AdminZoneUsers/>
    ];
  }

  render()
  {
    return MUI(
      <div ref='content-admin'>
      {
        // No need to check for individual panes visibility since they all share it
        GlobalUI.IsVisible('AdminContent/Users') && Admin.adminNames.map
        (
          (e, i) => <AdminPane iconName={Admin.adminIcons[i]}
                               paneName={Admin.adminNames[i]}
                               headerText={Admin.adminTexts[i]}
                               children={this.adminPanes[i]}
                               key={i}/>
        )
      }

      {
        // No need to check for individual panes visibility since they all share it
        GlobalUI.IsVisible('AdminContent/Billing') && Admin.billerNames.map
        (
          (e, i) => <AdminPane iconName={Admin.billerIcons[i]}
                               paneName={Admin.billerNames[i]}
                               headerText={Admin.billerTexts[i]}
                               children={this.billerPanes[i]}
                               key={i}/>
        )
      }
      </div>
    );
  }
}

