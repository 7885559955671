// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Our UI data, styles and colors
import {Styles, clrTrans, Colors} from './UIConst.js';

import {TaskBuilderParamSelect} from './TaskBuilderParamSelect';
import {TaskBuilderParamSlider} from './TaskBuilderParamSlider';
import {TaskBuilderParamFile} from './TaskBuilderParamFile';
import {TaskBuilderParamRow} from './TaskBuilderParamRow';
import {TaskBuilderParamUpload} from './TaskBuilderParamUpload';


export class TaskBuilderParameter extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  getValue = () =>
  {
    switch(this.state.type.toUpperCase())
    {
      case 'CONST':
      case 'STR':
      case 'INT':
      case 'FLOAT':
      case 'PASSWORD':
        return this.paramField.value;

      case 'RANGE':
        return this.paramField.getValue();

      case 'FILE':
        return this.paramField.inputField.value;

      case 'BOOL':
        return this.paramField.checked;


      case 'SELECTION':
        const elem = this.paramField.selectField;
        return elem.options[elem.selectedIndex].value;

      // For upload params, do a base64 encode
      case 'UPLOAD':
        return this.paramField.data;

      default:
        throw new Error('Unknown parameter type :' + this.state.type);
    }
  };

  setValue = (val) =>
  {
    const refParam = this.paramField;

    switch(this.state.type.toUpperCase())
    {
      case 'RANGE':
        refParam.setValue(val);
      break;

      case 'INT':
      case 'STR':
      case 'FLOAT':
      case 'PASSWORD':
        refParam.value = val;
      break;

      case 'FILE':
        refParam.inputField.value = val;
      break;

      case 'BOOL':
        refParam.checked = val;
      break;

      case 'SELECTION':
        const elem = refParam.selectField;
        for(let i = 0; i < elem.length; ++i)
        {
          if(elem[i].value === val)
          {
            elem[i].selected = true;
            break;
          }
        }
      break;

      case 'UPLOAD':
        // Do nothing - upload params cant be cloned
      break;

      default:
        throw new Error('Unknown parameter type :' + this.state.type);
    }

  };

  getParamKey()
  {
    return this.state.paramkey;
  }

  // Update the state when props change
  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps});
  }

  render()
  {
    let elemField;
    let padding = 16;

    const dctStyleInput = {...Styles.ParamInput};
    if(this.state.error)
    {
      dctStyleInput.border=`2px solid ${clrTrans(Colors.clrNimbixErr, 0.5)}`;
    }

    const dctMinMax = {min: this.state.min, max: this.state.max};

    // If there is a precision field, set the step
    if(this.props.precision)
    {
      dctMinMax.step = 1/Math.pow(10, this.props.precision|1);
    }

    switch(this.state.type.toUpperCase())
    {
      case 'STR':
      {
        elemField = <input data-cy={this.state.name}
                           ref={el=>this.paramField = el}
                           defaultValue={this.state.value}
                           style={dctStyleInput}/>;
      }
      break;

      case 'RANGE':
      {
        elemField = <TaskBuilderParamSlider paramName={this.state.name}
                                            onSliderChange={this.state.onChange}
                                            ref={el=>this.paramField = el}
                                            defaultValue={this.state.value}
                                            styleInput={dctStyleInput} {...dctMinMax} />;
        padding = '16px 16px 0px 16px';

      }
      break;

      case 'INT':
      case 'FLOAT':
      {

        let placeHolder = 'Enter a number';
        if(dctMinMax.min != null && dctMinMax.max != null)
        {
          placeHolder += ` (${dctMinMax.min}-${dctMinMax.max})`
        }
        elemField = <input data-cy={this.state.name}
                           placeholder={placeHolder}
                           defaultValue={this.state.value}
                           type='number'
                           ref={el=>this.paramField = el}
                           style={dctStyleInput}
                           {...dctMinMax}/>;
      }
      break;

      case 'BOOL':
      {
        elemField = <input data-cy={this.state.name}
                           onChange={this.state.onChange}
                           ref={el=>this.paramField = el}
                           defaultChecked={this.state.value}
                           type='checkbox'/>;
      }
      break;

      case 'PASSWORD':
      {
        elemField = <input data-cy={this.state.name}
                           ref={el=>this.paramField = el}
                           type='password'
                           style={dctStyleInput}/>;
      }
      break;

      case 'FILE':
      {
        elemField = <TaskBuilderParamFile paramName={this.state.name}
                                          inputStyle={dctStyleInput}
                                          defaultValue={this.state.value}
                                          filter={this.state.filter}
                                          ref={el=>this.paramField = el}/>;
      }
      break;

      case 'SELECTION':
      {
        elemField =
          <TaskBuilderParamSelect
            paramName={this.props.name}
            ref={el=>this.paramField = el}
            values={this.state.values}
            mvalues={this.state.mvalues}
            defaultValue={this.state.value}
            onChange={this.state.onChange}
            hasSeparator={this.state.hasSeparator}
            placeHolder={this.state.placeHolder}
            style={{...dctStyleInput, height: 30, width: '75%'}}/>;
      }
      break;

      case 'UPLOAD':
      {
        elemField =
          <TaskBuilderParamUpload
            size={this.state.size}
            paramName={this.state.name}
            inputStyle={dctStyleInput}
            defaultValue={this.state.value}
            filter={this.state.filter}
            ref={el=>this.paramField = el}/>
      }
      break;

      default:
        throw new Error('Unknown parameter type :' + this.state.type);
    }

    return (
      <TaskBuilderParamRow name={this.state.name} description={this.state.description}
                           elem={elemField} padding={padding}/>
    );
  }
}

TaskBuilderParameter.propTypes =
{
  name: PropTypes.string,
  paramkey: PropTypes.string,
  required: PropTypes.bool,
  positional: PropTypes.bool,
  description: PropTypes.any,
  type: PropTypes.oneOf(['STR', 'CONST', 'FILE', 'INT', 'FLOAT', 'RANGE', 'SELECTION', 'selection', 'BOOL', 'PASSWORD', 'UPLOAD']),
  max: PropTypes.number,
  min: PropTypes.number,
  mvalues: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func
};
