import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'material-ui/Checkbox';


import {ShowOnly, Separator, HLine} from './Components';
import {Styles, LayoutDims, Colors} from './UIConst';
import {GlobalUI} from '../Global';
import {Data, PriceStr} from '../Models/Model';


// Table that shows a list of machines and their limits
// Each row has a checkbox to disable/enable
export default class LimitsTable extends Component
{
  static propTypes =
  {
    limitstr: PropTypes.string,    // limits string like "mc1:n:N,mc2:n:N..."
    hidePrices: PropTypes.bool,
    readOnly: PropTypes.bool,
    tag: PropTypes.string.isRequired, // For cypress, and also varying behavior for SysAdmin/TAdmin
  }

  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['mc_name', 'mc_description', 'scale', 'conc'];
  static arrColNames = ['Name', 'Description', 'Max Per Job', 'Max Concurrent'];

  constructor(props)
  {
    super(props);
    this.state = this.makeData(props.limitstr, props.readOnly, props.user);

    // Init styles here because LayoutDims may be undefined during static init
    this.styleInput =
    {
      border: 'none',
      fontSize: LayoutDims.FontSize,
      color: Colors.clrNimbixDark,
      width: '95%',
      outline: 'none',
      lineHeight: (LayoutDims.nMargin * 4) + 'px',
    };

    this.styleTD =
    {
      ...Styles.TableCellBordered,
      fontSize: LayoutDims.FontSize,
      padding: 0,
      paddingLeft: LayoutDims.nMargin / 2,
    }
  }

  componentWillReceiveProps(nextProps)
  {
    // Dont update unless limit string changed
    if(this.state.limitstr !== nextProps.limitstr || this.state.readOnly !== nextProps.readOnly || this.state.user !== nextProps.user)
    {
      const state = this.makeData(nextProps.limitstr, nextProps.readOnly, nextProps.user);
      this.setState(state);
      this.updateUI(state);
    }
  }

  // On mount set the input controls
  componentDidMount()
  {
    this.updateUI(this.state);
  }

  // Sets the values of the input controls to the current known data
  updateUI = (state) =>
  {
    for(let i = 0; i < Object.keys(this.state.machines).length; ++i)
    {
      // Disabled ones get blank, enabled ones the values
      let conc = '', scale='';
      if(state.enabled[i])
      {
        conc = state.data[i].conc;
        scale = state.data[i].scale;
      }

      this.refs['conc_' + i].value = conc;
      this.refs['scale_' + i].value = scale;
    }

    // Set the global limit input box
    this.refs.inputGlobal.value = state.globalLimit;

    this.calcPrice(state.enabled);
  }

  // We have a list of machine limits as a string
  // construct an array of all machines
  // apply the limits from the string onto that
  // returns {data: ..., enabled: ... } as used in this.state
  makeData = (limitstr, readOnly, user) =>
  {
    // Parse the string
    const arrLimits = limitstr ? limitstr.split(',') : [];
    const dctLimits = {};

    // Put a dict entry for each machine with a limit
    for(const limit of arrLimits)
    {
      const arrLimit = limit.split(':');
      dctLimits[arrLimit[0]] = {scale: arrLimit[1], conc: arrLimit[2]};
    }

    // Make an array of mc_name,desc and one for enabled
    const data = [], enabled = [];

    // Get list of machine names sorted by name
    let arrMCNames = Object.keys(Data.MachinesAll);

    // If this is the Account -> Limits page we filter out machines that are not visible to the team
    let machines = Data.MachinesAll;
    arrMCNames.sort
    (
      (a, b) =>
      {
        const mcA = machines[a].mc_name;
        const mcB = machines[b].mc_name;
        return mcA < mcB ? -1 : (mcA > mcB ? 1 : 0);
      }
    );

    for(const mc_name of arrMCNames)
    {
      // Make an entry for a machine
      data.push
      (
        {
          mc_name,
          mc_description: machines[mc_name].mc_description,
          mc_scale_max: machines[mc_name].mc_scale_max,
          mc_max_concurrent: machines[mc_name].mc_max_concurrent,
          ...dctLimits[mc_name]
        }
      );

      // Set if that machine limit is enabled or not
      enabled.push(mc_name in dctLimits);
    }

    const hasStar = '*' in dctLimits;
    this.enableGlobal = hasStar && (dctLimits['*'].conc > 0);
    this.enablePerUser = hasStar && (dctLimits['*'].scale == 1);
    this.enableMachines = hasStar ? arrLimits.length - 1 > 0 : arrLimits.length > 0;

    return {
      data,
      enabled,
      limitstr,
      readOnly,
      enableMachines: this.enableMachines,
      enableGlobal: this.enableGlobal,
      enablePerUser: this.enablePerUser,
      globalLimit: this.enableGlobal ? dctLimits['*'].conc : 1,
      user,
      machines
    };
  }

  // When checkbox changes, set enabled for that row
  // Checkbox is a controlled one, input fields are uncontrolled
  onCheck = (evt) =>
  {
    const idx = evt.target.getAttribute('data-index');

    // Make a deep copy before flipping enabled
    const enabled = {...this.state.enabled};
    enabled[idx]= !enabled[idx];
    this.setState({enabled});

    // Set the input controls values to blank if disabled
    if(!enabled[idx])
    {
      this.refs['conc_' + idx].value = '';
      this.refs['scale_' + idx].value = '';
    }
    else // Set max concurrent to the mc_scale_max of this machine
    {
      this.refs['conc_' + idx].value = 2;
      this.refs['scale_' + idx].value = 1;
      this.refs['scale_' + idx].max = this.state.machines[this.state.data[idx].mc_name].mc_scale_max;
    }

    this.calcPrice(enabled);
  }

  // Calculate a total monthly price
  calcPrice = (enabled) =>
  {
    // The set of machines is either all of them or the selected subset
    const dctMCLimits = {};
    const arrMCNamesAll = Object.keys(this.state.machines);
    let fPrice = 0;

    if(this.enableGlobal)
    {
      for(let i = 0; i < arrMCNamesAll.length; ++i)
      {
        const sMC = this.state.data[i].mc_name;
        const dctMC = this.state.machines[sMC];
        const price = dctMC.mc_price / dctMC.mc_cores;

        // If we have a white list, get the data for those machines alone, else all of them
        // The limit is either from the white list or the mc def we have
        if(this.enableMachines)
        {
          if(enabled[i])
          {
            dctMCLimits[sMC] = {price, limit: (this.refs['conc_' + i].value | 0) * dctMC.mc_cores};
          }
        }
        else
        {
          dctMCLimits[sMC] = {price, limit: dctMC.mc_cores * dctMC.mc_scale_max};
        }
      }

      // Get the names alone, sort them by descending price
      const arrMCNames = Object.keys(dctMCLimits).sort
      (
        (a, b) =>
        {
          const ma = -dctMCLimits[a].price;
          const mb = -dctMCLimits[b].price;
          if(ma > mb) return 1;
          if(ma < mb) return -1;
          return 0;
        }
      );

      // Get the global limit if any else its infinite
      const iGlobalMax = this.enableGlobal ? (this.refs.inputGlobal.value | 0) : 0x7FFFFFFF;

      // Iterate through the machines, counting cores accumulating the price
      let iCounted = 0;
      for(let i = 0; i < arrMCNames.length; ++i)
      {
        // If we exhausted the global limit, we're done
        if(iCounted === iGlobalMax)
        {
          break;
        }

        // How many cores can we use here?
        const dctMCLimit = dctMCLimits[arrMCNames[i]];
        const iAvailCores = Math.min
        (
          dctMCLimit.limit,
          iGlobalMax - iCounted
        );

        // Add up the price (per month) for iAvailCores no of cores
        fPrice += iAvailCores * dctMCLimit.price * 720;

        // Bump up the counted cores
        iCounted += iAvailCores;
      }
    }
    else
    {
      for(let i = 0; i < arrMCNamesAll.length; ++i)
      {
        if(enabled[i])
        {
          const fPriceThis = (this.refs['conc_' + i].value|0) * this.state.machines[this.state.data[i].mc_name].mc_price * 720;
          fPrice += fPriceThis;
        }
      }
    }

    this.setState({price: fPrice});
  }

  onChange = () => this.calcPrice(this.state.enabled)

  // Get the limits data as "mc1:n:N,mc2:n:N..."
  getData = () =>
  {
    const ret = [];

    if(this.enableMachines)
    {
      for(let i = 0; i < this.state.data.length; ++i)
      {
        // Get only the ones that are checked
        if(this.state.enabled[i])
        {
          const name = this.state.data[i].mc_name;
          const conc = this.refs['conc_' + i].value | 0;
          const scale = this.refs['scale_' + i].value | 0;

          // Do a basic sanity check
          if(conc < scale)
          {
            GlobalUI.Dialog.show
            (
              'Error',
              'Max Concurrent must be greater than or equal to Max Scale',
              false,
              LayoutDims.wContent * 0.65
            );

            return null;
          }

          // Format as mc:X:Y and push
          ret.push(`${name}:${scale}:${conc}`);
        }
      }
    }

    if(this.enableGlobal)
    {
      const iGlobal = this.refs.inputGlobal.value || 0;
      ret.push(`*:${this.enablePerUser ? 1 : 0}:${iGlobal}`);
    }

    // Join with commas
    return ret.join(',');
  }

  // We need to store the global checked status both in state and in the object due to async
  // nature of steState
  onCheckGlobal = (e, v) =>
  {
    this.setState({enableGlobal: v});
    this.enableGlobal = v;

    // Uncheck the per-user if its checked
    if(this.enablePerUser)
    {
      this.setState({enablePerUser: false});
      this.enablePerUser = false;
    }

    this.calcPrice(this.state.enabled);
  }

  // We need to store the global checked status both in state and in the object due to async
  // nature of steState
  onCheckPerUser = (e, v) =>
  {
    this.setState({enablePerUser: v});
    this.enablePerUser = v;
    this.calcPrice(this.state.enabled);
  }


  // We need to store the checked status both in state and in the object due to async
  // nature of steState
  onCheckMachines = (e, v) =>
  {
    this.setState({enableMachines: v});
    this.enableMachines = v;
    this.calcPrice(this.state.enabled);
  }

  render()
  {
    const allowGlobal = this.state.enableGlobal && !this.state.readOnly;
    const colorGlobal = allowGlobal ? Colors.clrNimbixDark : Colors.clrNimbixDarkGray;

    const allowMC = this.state.enableMachines && !this.state.readOnly;
    const colorMC = allowMC ? Colors.clrNimbixDark : Colors.clrNimbixDarkGray;

    const showPrice = this.state.price > 0 && (this.state.enableGlobal || this.state.enableMachines || this.state.enablePerUser)

    return(
      <ShowOnly if={this.state.data.length}>
        {
          !this.props.hidePrices &&
          <div style={{visibility: showPrice ? 'visible' : 'hidden'}}>
            Monthly max (est.)
            <sup>*</sup>
            : <b>{PriceStr(this.state.price)}</b>

            <div style={{fontSize: LayoutDims.TailFontSize}}>
              <sup>*</sup>
              &nbsp;machine prices only, does not include software licenses or hourly charges
              <br/>
              <sup>*</sup>
              &nbsp;when specifying maximum concurrent CPUs, actual cost may vary significantly based on exact resources requested
            </div>
            <br/>
          </div>
        }

        <ShowOnly if={this.state.user === '*'} >
        </ShowOnly>

        <div style={{...Styles.Inline}}>
          <div>
            <Checkbox data-cy={'checkLimitGlobal' + this.props.tag}
                      checked={this.state.enableGlobal}
                      disabled={this.state.readOnly}
                      onCheck={this.onCheckGlobal}/>
          </div>


          <div style={{color: colorGlobal}}>
            Maximum concurrent CPUs (regardless of machine types):
          </div>
          &nbsp;


          <input data-cy={'inputLimitGlobal' + this.props.tag}
                 ref='inputGlobal'
                 type='number'
                 min='1'
                 readOnly={!allowGlobal}
                 onChange={this.onChange}
                 style={{...Styles.ParamInput, flex: 0, width: 200}}/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <Checkbox data-cy={'checkLimitPerUser' + this.props.tag}
                    style={{width: '30%'}}
                    label='Per User'
                    checked={this.state.enablePerUser}
                    disabled={!allowGlobal}
                    onCheck={this.onCheckPerUser}/>

        </div>

        <Separator/>
        <HLine margin={0}/>
        <Separator units={2}/>

        <div style={{...Styles.Inline}}>
          <div>
            <Checkbox data-cy={'checkLimitMachines'  + this.props.tag}
                      checked={this.state.enableMachines}
                      disabled={this.state.readOnly}
                      onCheck={this.onCheckMachines}/>
          </div>

          <div style={{color: colorMC}}>
            Allow only the following machines and concurrency limits:
          </div>
        </div>


        <table style={{...Styles.Table, ...Styles.ThickBordered, width: '100%'}}>
          <thead>
            <tr>
              <th style={Styles.TableHeader}>Enable</th>
              {
                LimitsTable.arrColNames.map
                (
                  (e, i) => <th key={i} style={Styles.TableHeader}>{e}</th>
                )
              }
            </tr>
          </thead>

          <tbody>
          {
            this.state.data.map
            (
              (mc, j)=>
              {
                const row = this.state.data[j];
                const enabled = !!this.state.enabled[j];
                const styleTR = !enabled ? {backgroundColor:Colors.clrNimbixGray} : null;

                return (
                  <tr key={'row' + j} style={styleTR}>
                    <td style={Styles.TableCellBordered}>
                      <Checkbox data-index={j} data-mc={mc.mc_name} checked={enabled} disabled={!allowMC} onCheck={this.onCheck}/>
                    </td>

                    {
                      LimitsTable.arrColFields.map
                      (
                        (col, i) =>
                        {
                          const isScale = col === 'scale';
                          const isEditable = col === 'scale' || col === 'conc';
                          return (
                            <td key={'col' + i} style={this.styleTD}>
                            {
                              isEditable
                              ?
                                <input style={{...this.styleInput, ...styleTR}}
                                       type='number'
                                       ref={col + '_' + j}
                                       readOnly={!enabled || !allowMC}
                                       min='0'
                                       max={mc[isScale ? 'mc_scale_max' : 'mc_max_concurrent']}
                                       onChange={this.onChange}
                                       />
                              :
                                <div>{row[col]}</div>
                            }
                            </td>
                          );

                        }
                      )
                    }
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </ShowOnly>
    );
  }
}
