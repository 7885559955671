// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {clrTrans} from './UIConst.js';

export class TaskBuilderParamSelect extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  // Update the state when props change
  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps});
  }

  scrollIntoView()
  {
    this.selectField.scrollIntoView();
  }

  render()
  {
    const arrItems = [];
    const mValues = this.state.mvalues || this.state.values;
    const elemSeparator =
      <option style={{fontSize: 1, backgroundColor: clrTrans('#000000', 0.2)}} disabled>&nbsp;</option>

    if(this.state.values)
    {
      if(this.state.placeHolder)
      {
        arrItems.push(<option key='_default' value=''>{this.state.placeHolder}</option>)
      }

      for(let i = 0; i < this.state.values.length; ++i)
      {
        //let sel = mValues[i] === this.state.defaultValue ? {selected: 'selected'}: {};
        arrItems.push
        (
          !this.state.hasSeparator || mValues[i] !== ""
          ?
            <option key={mValues[i]} value={mValues[i]}>
              {this.state.values[i]}
            </option>
          :
            elemSeparator
        );
      }
    }

    return <select ref={el=>this.selectField = el}
                   data-cy={this.props.paramName}
                   defaultValue={this.state.defaultValue || this.state.placeHolder}
                   onChange={this.state.onChange}
                   style={this.state.style}
                   children={arrItems}/>;
  }
}

TaskBuilderParamSelect.propTypes =
{
  paramName: PropTypes.string.isRequired,
  mvalues: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.string),
  that: PropTypes.node,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  hasSeparator: PropTypes.bool,
};

