import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import {red900} from 'material-ui/styles/colors';

import {Separator, SearchBox, Pager, ShowOnly, Icon, fromThis} from './Components';

import {Styles, LayoutDims, Btns, Colors, clrTrans} from './UIConst';
import {GlobalUI, Spinner} from '../Global';
import {DataFetcher} from '../Models/Model';
import {filterRows, StrICmp, IntCmp} from './Utils'


// Returns an array of th for the table headers for Admin Log and Jobs tables
const makeHeader = (fnExtraCol, arrColText, onClickHeader, iSortCol, bSortDesc) =>
{
  let arrHeaders = arrColText.map
  (
    (e, i) =>
    {
      return (
        <th key={i}
            name={i}
            onClick={onClickHeader}
            style={{...Styles.TableHeader, fontSize: 12}}>
          {e}
          <div style={{float: 'right', minWidth: 12}}>
            {(iSortCol === i ? bSortDesc ? '▾' : '▴' : '  ')}
          </div>
        </th>
      );
    }
  );

  if(fnExtraCol)
  {
    arrHeaders.splice(0, 0, <th style={Styles.TableHeader} key={-1}> </th>);
  }

  return arrHeaders;
}


// This component abstracts the view used by both the Admin Jobs and Audit log view
// Most of the code is common, this serves a a base class which is overridden for those 2 views

class AdminFilteredList extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      rows: null,
      page: 1,
      pagecount: 1,
      busy: false,
      filterChanged: false, // Has the filter changed since last refresh for text fields
      filterVals: {},        // last known value of the filter input boxes
      selectedIndex : -1,
    };

    // Current filter criteria
    this.filter = {};

    // Override these in the subclass

    // Names of the fields in the data rows
    this.arrColFields = null;

    // Names of the parameters passed as a filter
    this.arrFilters = null;

    // Types of fields - currently only "number" for numeric sort is used
    this.arrColTypes = {};

    // Human readable names for the columns
    this.arrColText = null;

    // Prefix used for the refs of filter edit boxes
    this.filterName = null;

    // URL used to fetch teh count
    this.urlCount = '';

    // Params to be passed to above URL
    this.urlCountParams = {}

    // URL used to fetch the data
    this.urlData = '';

    // Params to be passed to above URL
    this.urlDataParams = {}

    // Under what key is the count of rows returned
    this.keyCount = '';

    // What column/order to sort on initially
    this.sortCol = null
    this.sortDesc = null

    // Optionally override these in the subclass

    // Which columns onward to do the realtime search
    this.minSearchCol = 0;

    // Number of entries per page
    this.pagesize = 100;

    // Cached row count
    this.total = 0;

    // Option lists for the filters
    // dctOptions[column] must have an array of options to filter by
    this.dctOptions = {};

    // Function will be used to render an extra column at start
    this.fnExtraCol = null;

    // Height of anything rendered in renderExtra()
    this.extraHeight = 0;
  }

  // Override these methods
  ///////////////////////////////////////////////////////////////////////////

  // Called to get the count from the result of the request that fetches the count
  getCount(fetcher)
  {
    //return fetcher.data.result.count
  }

  // Called to construct the data row elements given the filtered data
  getDataRowElems(arrFilterData)
  {
    /*return makeDataRows(arrFilterData, this.arrColFields, {}, 'key_field');*/
  }

  // Called to get the column group component for the view
  getColGroup()
  {
    //return <ColGroup cols={[10, 10, 10, 10, 20]} />;
  }

  // Called to get the data rows from the fetcher
  getRows(fetcher)
  {
    // return fetcher.data.result;
  }


  // Called before filtering to allow modification of filter dict
  translateFilter()
  {
  }

  // Called to render extra content between the table and the search/pager UI
  renderExtra()
  {
    return null;
  }
  ///////////////////////////////////////////////////////////////////////////

  // Called before refresh to allow modification of urlDataParams (for new job-page-list)
  beforeRefresh(page)
  {
  }

  refreshData = () =>
  {
    this.refresh(this.state.page);
  }

  // Called after render - the filter strings are filled into the input fields
  // modified flags are cleared
  setFilterText = () =>
  {
    if(this.hasFilter())
    {
      // Check which filters are set
      for(let i = 0; i < this.arrFilters.length; ++i)
      {
        const sFilterValue = this.filter[this.arrFilters[i]] || '';
        const sRef = this.filterName + '-' + this.arrColFields[i];
        this.refs[sRef].value = sFilterValue;
      }
    }
  }

  refresh(page, filterUnchanged)
  {
    // If anything needs to be done to the filter do it
    this.translateFilter();

    // Call beforeRefresh to allow modification of urlDataParams
    this.beforeRefresh(page);

    // Fetch the total number of entries for the current filter if it changed
    // This is used to show the page numbers UI
    // We dont need to refresh that data on every navigation

    if(!filterUnchanged)
    {
      // New job-page-list code does not need separate call for counting rows
      if(this.urlCount)
      {
        // Retrieve total rows count
        const fetcher = new DataFetcher(this.urlCount, {...this.filter, ...this.urlCountParams});
        fetcher.whenDone
        (
          () => this.setState
          (
            {
              pagecount: Math.ceil(this.getCount(fetcher) / this.pagesize),
              filterChanged: false,
              busy: false
            }
          )
        );
        this.setState({busy: true});
        fetcher.fetch();
      }
    }

    const start = (page - 1) * this.pagesize;
    const params = {start, [this.keyCount]: this.pagesize, ...this.filter, ...this.urlDataParams};
    const fetcher = new DataFetcher(this.urlData, params);
    fetcher.whenDone
    (
      ()=>
      {
        const rows = this.getRows(fetcher);
        const sorted = this.doSortData(rows, this.state.sortCol, this.state.sortDesc)
        this.setState({rows: sorted, page, busy: false})
      }
    );

    fetcher.ifFail
    (
      (jqXHR) =>
      {
        this.setState({busy: false});
        if(this.hasFilter())
        {
          this.setFilterText();
          GlobalUI.DialogConfirm.showErr(jqXHR, 'Error Applying Filter');
        }
      }
    );

    this.setState({busy: true});
    fetcher.fetch();
  }

  // Is there any filter (including option fields)
  hasFilter = () => Object.keys(this.filter).length > 0;

  // Is there any filter (excluding option fields)
  // Check all the filter field names, if there in any in this.filter and it is not an option field, then there is a text filter (clear-able)
  hasTextFilter = () => this.arrFilters.some((e, i) => e in this.filter && !this.dctOptions[this.arrColFields[i]])

  // get the names of the refs that are filter controls
  getFilterRefs = () => Object.keys(this.refs).filter((s)=>s.indexOf(this.filterName + '-') === 0);

  onNavPage = (page) => this.refresh(page);
  onPrevPage = () => this.refresh(this.state.page - 1);
  onNextPage = () => this.refresh(this.state.page + 1);

  onFilterChange = (evt) =>
  {
    const elem = evt.target;
    const sField = elem.id.substr(this.filterName.length + 1);
    const isOption = this.dctOptions[sField];

    // If an option field changed
    if(isOption)
    {
      this.applyFilter();
    }
    else // has the text changed compared to the last filter
    {
      const sFilterKey = elem.getAttribute('data-filter')
      const sFilter = this.filter[sFilterKey] || '';
      const filterVals = {...this.state.filterVals, [sFilterKey]: elem.value }
      this.setState({filterChanged: sFilter !== elem.value, filterVals});
    }
  }

  clearFilter = () =>
  {
    // Clear all the input fields but not the option ones
    for(const sRef of this.getFilterRefs())
    {
      // strip the 'xyz-' prefix
      const sField = sRef.substr(this.filterName.length + 1)
      if(!this.dctOptions[sField])
      {
        this.refs[sRef].value = '';
      }
    }

    // Delete the filter keys where dctOptions is not set for the corresponding entry in arrFields
    // In other words only clear text fields not option fields
    for(let i = 0; i < this.arrFilters.length; ++i)
    {
      if(!this.dctOptions[this.arrColFields[i]])
      {
        delete this.filter[this.arrFilters[i]];
      }
    }

    this.setState({filterChanged: true, filterVals: {}});
    this.refresh(1, false);
  }

  applyFilter = () =>
  {
    // Get the filter values from the fields
    this.filter = {};
    for(const sRef of this.getFilterRefs())
    {
      const sVal = this.refs[sRef].value.trim();
      if(sVal)
      {
        const sDataFilter = this.refs[sRef].getAttribute('data-filter');
        if(sDataFilter)
        {
          this.filter[sDataFilter] = sVal;
        }
      }
    }

    // Set the filter and refresh, set to page 1
    if(this.hasFilter())
    {
      this.refresh(1, false);
    }
  }

  onSearchClear = () =>
  {
    this.refs.searchBox.inputField.value = '';
    this.setState({search: null});
  }

  onSearchIncr = (s) =>
  {
    this.setState({search: s.trim().toUpperCase()});
  }

  onClickHeader = (e) =>
  {
    const sortCol = e.target.getAttribute('name') | 0;
    const bReverse = this.state.sortCol === sortCol;
    const sortDesc = bReverse ? !this.state.sortDesc : false;

    let rows;

    // If sort column is same, just reverse
    if(bReverse)
    {
      rows = [...this.state.rows];
      rows.reverse();
    }
    else // sort ascending
    {
      rows = this.doSortData(this.state.rows, sortCol, sortDesc)
    }

    this.setState({sortDesc, sortCol, rows});
  }


  doSortData(rows, sortCol, bSortDesc)
  {
    // Sort the data and set the UI
    const field = this.arrColFields[sortCol];
    const sorted = [...rows];

    // Reverse after sorting if needed
    const comp = this.arrColTypes[field] === 'number' ? IntCmp : StrICmp;
    sorted.sort((a, b)=>comp(a[field], b[field]))

    if(bSortDesc)
    {
      sorted.reverse();
    }

    return sorted;
  }


  componentDidMount()
  {
    // If no sort column is assigned, do it
    if(this.state.sortCol == null)
    {
      this.setState({sortCol: this.sortCol || 0, sortDesc: this.sortDesc })
    }
  }


  render()
  {
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 160 + (this.extraHeight || 0));

    const spinner =
      <div style={{position: 'absolute', left: 0, right: 0, top: 0, margin: 'auto',  height: '100%', zIndex: 999}}>
        <Spinner style={Styles.Full} size={64} textColor={Colors.clrNimbixDark} status='Loading...'/>
      </div>

    // Create the editable header row for filter input
    let arrEditHeaders = makeEditableHeader
    (
      this.fnExtraCol,
      this.arrColFields,
      this.arrFilters,
      this.state,
      this.filterName,
      this.dctOptions,
      this.onFilterChange,
      this.filter,

      // key handling
      (evt) =>
      {
        if(evt.key === 'Enter')
        {
          if(this.state.filterChanged) this.applyFilter();
          evt.preventDefault();
          return false;
        }

        if(evt.key === 'Escape')
        {
          const elem = evt.target;
          elem.value = '';
          this.onFilterChange(evt); // fake the change - evt.elem is correct

          if(this.hasTextFilter())
          {
            this.applyFilter();
            evt.preventDefault();
            return false;
          }
        }
      }
    );

    this.arrFilterData = filterRows(this.state.rows, this.state.search, this.minSearchCol);
    const arrDataRows = this.getDataRowElems(this.arrFilterData);

    // if pagecount is 0 set it to 1
    let pagecount = this.state.pagecount;
    pagecount = pagecount === 0 ? 1 : pagecount;

    const styleCtl = {...Styles.InlineFlexRow, margin: LayoutDims.nMargin/2};

    return (
      <div>
        <div style={Styles.Inline}>

          <div tabIndex='0' style={{...styleCtl, flex: 1}}>
            <SearchBox disabled={this.state.busy} width='100%' ref='searchBox' onSearch={this.onSearchIncr} onClear={this.onSearchClear}/>
            &nbsp;
          </div>

          <div style={styleCtl}>
            <RaisedButton {...Btns.Green}
                          label='Apply Filter'
                          data-cy='btnApplyFilter'
                          disabled={!this.state.filterChanged}
                          onClick={this.applyFilter} />
            &nbsp;
            <RaisedButton {...Btns.Blue}
                          label='Clear Filter'
                          data-cy='btnClearFilter'
                          disabled={!this.hasTextFilter()}
                          onClick={this.clearFilter} />

            &nbsp;
            {this.fnExtraButton && this.fnExtraButton()}
            &nbsp;
          </div>


          <div style={styleCtl}>
            <Pager page={this.state.page} pagecount={pagecount}
                   {...fromThis(this, ['onNavPage', 'onPrevPage', 'onNextPage'])}
                   disable={this.state.busy}
                   paneGroup={'@Admin'+ this.filterName +'Pager'}/>
            &nbsp;&nbsp;
            <FloatingActionButton disabled={this.state.busy}
                                  disabledColor={Colors.clrNimbixDarkGray}
                                  backgroundColor={Colors.clrNimbixDarkGreen}
                                  mini
                                  data-cy={`btnRefreshAdmin${this.filterName}`}
                                  onClick={()=>this.refreshData()}>
              {Icon('refresh', {fontSize: 16, color: 'white'})}
            </FloatingActionButton>
          </div>

        </div>

        <Separator/>

        <ShowOnly if={true} otherwise={spinner}>

          {this.renderExtra()}

          <div style={{maxHeight: hAvail, minHeight: hAvail, overflowY: 'auto', opacity: this.state.busy ? 0.7 : 1}}>

            {
              <table data-cy={'table' + this.filterName} style={{...Styles.Table, tableLayout: 'fixed', width: '100%'}}>

                {this.getColGroup()}

                <thead>
                <tr>{arrEditHeaders}</tr>
                <tr style={{cursor: 'pointer'}}>{makeHeader(this.fnExtraCol, this.arrColText, this.onClickHeader, this.state.sortCol, this.state.sortDesc)}</tr>
                </thead>

                <tbody>{arrDataRows}</tbody>
              </table>
            }

            {this.state.busy && spinner}

          </div>
        </ShowOnly>

      </div>
    );
  }
}


// Function that generates editable headers for a table - used for Admin jobs and log
// Generates an array of tr whose id has sRefPrefix-field for all fields in arrColField
// if the field text does not match the filter, then a red border is drawn, else if there is a filter applied blue, otherwise normal border
// each <th> has a data-filter attribute to store the api data field name for each column
// fnExtraCol means render a dummy header at the start
export function makeEditableHeader(fnExtraCol, arrColField, arrFilters, state, sRefPrefix, dctOpts, onFilterChange, filter, onKeyPress)
{
  const arrHeaders = arrColField.map
  (
    (e, i) =>
    {
      const sFilterKey = arrFilters[i];
      const dctProps =
      {
        style: {...Styles.EditableHeaderFilterInput},
        id: sRefPrefix + '-' + e,
        ref: sRefPrefix + '-' + e,
        'data-filter': sFilterKey,
        'data-cy': sRefPrefix + '-' + e,
        onChange: onFilterChange,
        onKeyUp: onKeyPress,
        autoComplete: 'off'
      }

      const arrOptions = dctOpts[e];

      // If current filter mismatches with last value its modified
      const modified = !arrOptions && (state.filterVals[sFilterKey] || '') !== (filter[sFilterKey] || '');
      const filtered = !arrOptions && filter[sFilterKey];

      const style = {};
      if(modified || filtered)
      {
        style.border = `2px solid ${modified ? clrTrans(red900, 0.5): Colors.clrNimbixDarkTrans}`;
      }
      else
      {
        style.border = `1.5px solid ${Colors.clrNimbixGray}`;
      }

      return (
        <th key={e} style={{...style, ...Styles.EditableHeaderFilter}}>
        {
          arrOptions
          ?
            <select tabIndex={i+1} {...dctProps} defaultValue=''>
              {arrOptions.map((e, i)=><option key={i+1}>{e}</option>)}
            </select>
          : // We need this <form> tag and dummy submit button hack to thwart chromes overzealous auto-fill!
            <form autoComplete='off' >
              <button type="submit" disabled style={{display: 'none'}}> </button>
              <input tabIndex={i+1} {...dctProps} readOnly={arrFilters[i] === ''}/>
            </form>
        }
        </th>
      );
    }
  );

  if(fnExtraCol)
  {
    arrHeaders.splice(0, 0, <th style={{border: 'none'}} key={-1}> </th>)
  }

  return arrHeaders;

}


// Creates the data rows for Admin job and log tables
// arrRows contains the data as an array of dicts
// arrColFields is an array of field names
// dctProps contains style and onClick props to be set on a given field td
// sRowKey is the field that represents the primary unique key for the data
export const makeDataRows = (fnExtraCol, arrRows, arrColFields, dctProps, sRowKey, sSelectedID, onClick, fnExtraRowStyle) =>
{

  const makeTDs = (row, style) =>
  {
    const tds = arrColFields.map
    (
      (col, i) =>
      {
        const props = dctProps[col] || {style: {...Styles.AdminTD, ...style, wordWrap: 'break-word'}};
        return (
          <td key={'col' + i} {...props}>
            {row[col]}
          </td>
        );
      }
    )

    // Splice in the extra column data if any
    if(fnExtraCol)
    {
      tds.splice(0, 0, <td key='-1' style={{...Styles.AdminTD, padding: 0}}>{fnExtraCol(row)}</td>);
    }

    return tds;
  }


  return (
    !arrRows ?
      []
    :
      arrRows.map(
        (row, i) =>
        {
          const sID = row[sRowKey];
          let styleTR = (sID === sSelectedID) ? Styles.TRSel : Styles.TRDesel;
          let styleTD = (sID === sSelectedID) ? Styles.TDSel : Styles.TDDesel;

          if(fnExtraRowStyle)
          {
            styleTR = {...styleTR, ...fnExtraRowStyle(row)}
          }

          return (
            <tr key={row[sRowKey] + '.' + i}
                name={row[sRowKey]}
                style={styleTR}
                onMouseDown={onClick}
            >
                {makeTDs(row, styleTD)}
            </tr>
          );
        }
      )
  );
}



export
{
  AdminFilteredList
}
