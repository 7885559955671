// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {MUI, SearchPane, EventMap} from './Components';
import AppView from './AppView';
import {GlobalUI} from '../Global'
import {LayoutDims} from './UIConst';

// Controller class that orchestrates the Searchbox, Appview and filter pane
export class AppSearcher
{
  constructor(fetcher)
  {
    // We keep our component references inside refs like React components for consistency
    this.refs = {'this': this};

    this.fetcher = fetcher;

    // Event map for search pane, redirect everything into this class
    this.paneSearchEvts = new EventMap();
    this.paneSearchEvts.connectAll(this, ['onSearch', 'onSearchClear'], 'this');

    // Event map for filter pane, redirect everything into this class
    this.paneFilterEvts = new EventMap();
    this.paneFilterEvts.connectAll
    (
      this,
      [
        'onSelectVendor', 'onSelectClass', 'onSelectLicense','onSelectCommunity',
        'onSelectAll', 'onSelectOwned', 'onSelectTeam'
      ],
      'this'
    );

    this.dctFilter = {};
    this.headerText = 'All Apps';
  }

  // This is called by the main component to give us references to the components that we control
  connect(paneCompute, paneFilter)
  {
    if(!this.refs.paneCompute)
    {
      this.refs.paneCompute = paneCompute;
      this.refs.paneSearch = paneCompute.refs.paneSearch;
      this.refs.paneFilter = paneFilter;

      // Function to call when fetcher updates
      const whenDone = () =>
      {
        // There may or may not be a filter at this point
        this.doSearch();

        // Tell the UI what filter is applicable (needed for deep link)
        this.refs.paneFilter.setFilter(this.dctFilter);

        // If there was a filter, force navigation to compute page
        if(GlobalUI.URLPage === 'compute')
        {
          GlobalUI.Main.onNavigate('Compute');
        }
      };

      // Set the handler, and call it once right now for initial data load
      this.fetcher.whenDone(whenDone);
      whenDone();
    }
  }

  // Set the app view state
  setAppViewState = (arrApps, headerText) =>
  {
    this.refs.paneCompute.refs.viewApp.setState({arrApps, headerText});
  };

  // Sets a filter manually ( called when deep link filter is used )
  setFilter = (dctFilter) =>
  {
    this.dctFilter = {...dctFilter};
  }

  // Apply the current filter
  doSearch = () =>
  {
    this.setAppViewState(this.fetcher.getFilteredApps(this.dctFilter),
      this.fetcher.getFilterHeaderText(this.dctFilter));
  };

  // On search is called from the search box
  // Set the name filter key and filter apps
  onSearch = (sText) =>
  {
    this.dctFilter.name = sText;
    this.doSearch();
  };

  // On clear, empty the search box and re-render
  onSearchClear = () =>
  {
    // There may or may not be a searchbox so we should check before clearing
    if(this.refs.paneSearch)
    {
      this.refs.paneSearch.searchBox.inputField.value = '';
    }

    delete this.dctFilter.name;
    this.doSearch();
  };

  // Reset everything except licensed
  resetFilter = () =>
  {
    this.refs.paneFilter.closeAll();
    this.dctFilter = {licensed: this.dctFilter.licensed};

    // There may or may not be a searchbox so we should check before clearing
    if(this.refs.paneSearch)
    {
      this.refs.paneSearch.searchBox.inputField.value = '';
    }
  };

  // Filter apps based on a key:value
  filterApps = (sKey, sValue) =>
  {
    // Clear the deep link URL params if any
    if(GlobalUI.URLPage === 'compute')
    {
      GlobalUI.URLPage = '';
      window.history.pushState(null, '', '/');
    }

    if(sKey === 'all')
    {
      this.resetFilter();
    }
    else
    {
      // Owned, Team, Community, Category and Vendor are exclusive filters
      // License and name are combinable with one of the above

      // If its an exclusive filter key
      if(sKey !== 'licensed' && sKey !== 'name')
      {
        // combine the licensed and name filters from before if any
        const dctFilter = {};
        if(this.dctFilter.licensed != null) dctFilter.licensed = this.dctFilter.licensed;
        if(this.dctFilter.name) dctFilter.name = this.dctFilter.name;
        this.dctFilter = dctFilter;
      }

      // Set the key value
      this.dctFilter[sKey] = sValue;
    }

    this.doSearch();
  };

  // The events from the filter pane connect to these
  onSelectVendor = this.filterApps.bind(this, 'author');
  onSelectClass = this.filterApps.bind(this, 'category');
  onSelectLicense = this.filterApps.bind(this, 'licensed');
  onSelectCommunity = this.filterApps.bind(this, 'community-category');
  onSelectAll = this.filterApps.bind(this, 'all');
  onSelectOwned = this.filterApps.bind(this, 'owned');
  onSelectTeam = this.filterApps.bind(this, 'team');
}

// Compute content pane with a searchbox on top
export class ComputePane extends Component
{
  static propTypes =
  {
    onClickAppEntry: PropTypes.func,

    // Searcher object that ties the searchbox, filter pane and this view
    searcher: PropTypes.instanceOf(AppSearcher).isRequired,

    // What kind of app entries - ptc, recent or "" (for compute view)
    entryType: PropTypes.string
  };

  // Use a wrapper method because this.onClickAppEntry will be assigned later
  onClickEntry = (sAppName) =>
  {
    this.onClickAppEntry(sAppName);
  }

  componentDidMount()
  {
    // Assign this component to the global UI map, so others can access it
    if(this.props.entryType === 'ptc')
    {
      GlobalUI.PTCAppView = this.refs.viewApp;
    }
    if(!this.props.entryType)
    {
      GlobalUI.AppView = this.refs.viewApp;
    }
  }

  render()
  {
    return MUI(
      <div ref='content-compute' style={{margin: LayoutDims.nMargin}}>
        <SearchPane entryType={this.props.entryType || 'compute'} ref='paneSearch' {...this.props.searcher.paneSearchEvts.map}/>
        <AppView entryType={this.props.entryType}
                 onClickAppEntry={this.onClickEntry}
                 ref='viewApp' />
      </div>
    );
  }
}

