import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import Checkbox from 'material-ui/Checkbox';


import {Styles, LayoutDims, Btns, Colors} from './UIConst';

import {Separator, HLine, FileChooser, MUI, ModalView, ModalTitle, SearchBox, AdminAppRow} from './Components';
import {AdminAppsDataFetcher, AdminAppsData, FilterAppNames} from '../Models/AppsData';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {Data, DataFetcher} from '../Models/Model';

import {deepCopy} from './Utils';


// Dialog content of app edit dialog
export class AppEditDlg extends Component
{
  // List of fields
  static arrFields =
    ['id', 'owner', 'name', 'public', 'certified', 'team', 'price', 'repo', 'src', 'privs', 'arch'];

  // Fields that can be changed
  static arrEditableFields =
    ['public', 'certified', 'team', 'price', 'repo', 'src', 'privs', 'arch'];

  // Names to show
  static arrFieldNames =
    ['ID', 'Owner', 'Name', 'Public', 'Certified', 'Team Visible', 'Price', 'Repo', 'Source',
      'Privileges', 'Architecture'];

  // type of each field
  static arrFieldTypes =
    ['readonly', 'readonly', 'readonly', 'boolean', 'boolean', 'boolean', 'text', 'text', 'text',
      'text', 'arch'];

  constructor(props)
  {
    super(props);
    this.state = {app: null, appDefChanged: false};
    this.elems = {};
  }

  // Save the app definition
  onOK = () =>
  {
    const dct = {};

    // Go through the list of editable fields and grab the edited values if changed
    for(const k of AppEditDlg.arrEditableFields)
    {
      const val = String(this.elems[k].value);
      const old = String(this.state.app[k]);
      if(old !== val)
      {
        dct[k] = val;
      }
    }

    // If app def was loaded, add teh filename as its value (just to show the user)
    if(this.state.appDefChanged)
    {
      // path is faked by browser with C:\fakepath\ prefix
      let sFile = this.refs.fileChooserLoad.refs.inputFile.value;
      sFile = sFile.split('\\');
      sFile = sFile[sFile.length - 1];
      dct['appdef'] = sFile;
    }

    const keys = Object.keys(dct);
    if(keys.length)
    {
      const ctrls =
        <div>
          <div>The following changes will be applied:</div>
          <pre style={{...Styles.Bordered, backgroundColor: Colors.clrNimbixGray, padding: LayoutDims.nMargin}}>
            {
              keys.map((k, i) => <div key={i}>{k + ':' + dct[k]}</div>)
            }
          </pre>
        </div>

      // If save confirmed
      const onDoSave = () =>
      {
        // If appdef was changed, add the JSON to the key
        if('appdef' in dct)
        {
          dct['appdef'] = JSON.stringify(this.state.app.data);
        }

        // Set the ID 
        dct.appid = this.state.app.id;

        // Send the request
        const fetcher = new DataFetcher('/portal/admin-app-edit', dct);
        fetcher.whenDone(() => {AdminAppsDataFetcher.fetch(); this.setState({open:false});});
        fetcher.ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Error Saving Application'));
        FetchBusy(fetcher, 'Saving app...');
      }

      // Confirm and save
      GlobalUI.Dialog.confirm(ctrls, 'Confirm updates', onDoSave);
    }
  }

  // On Save, download the data key of the appdef into a file
  onClickSave = () =>
  {
    this.refs.downloadLink.download = this.state.app.id + '.json';
    this.refs.downloadLink.href = 'data:,' + JSON.stringify(this.state.app.data, null, 4);
    this.refs.downloadLink.click();
  }

  // Open the file chooser
  onClickLoad = () =>
  {
    this.refs.fileChooserLoad.open();
  }

  // File only contains the data key
  onReadAppDef = (sData) =>
  {
    const data = JSON.parse(sData);
    const app = {...this.state.app};
    app.data = data;
    this.setState({app, appDefChanged: true});
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({app: {...nextProps.app}, appDefChanged: false})
  }

  static styleInput = {border: 'none', padding: 4, marginLeft: 4, width: 'calc(100% - 16px)', background: 'none'};

  openWithApp(app)
  {
    // Clear any old state
    GlobalUI.Dialog.clear();

    // Open dialog
    this.setState({app: app, open: true});
  }

  render()
  {
    // Ignore if empty
    if(!this.state.app)
    {
      return null;
    }

    const app = deepCopy(this.state.app);
    app['name'] = app.data.name;
    app['privs'] = app['privs'].join(',');

    // For readability, don't show the image data
    if(app.data.image)
    {
      app.data.image.data = '<base64 image data not shown>';
    }

    const reffer = (f, r) => {this.elems[f] = r; if(r) r.value = app[f];};

    return MUI(
      <ModalView ref='viewModal' open={this.state.open} onClose={()=>this.refs.viewModal.setState({open: false})}>
        <Paper zDepth={3} style={Styles.ModalPaper}>
          <ModalTitle scale={0.5} title={'Edit application: ' + app.id} />

          <div style={{height: 360, overflowY: 'auto', backgroundColor: 'white'}}>
            <table style={{...Styles.Table, tableLayout: 'fixed', width: '100%' }}>
              <colgroup>
                <col style={{width: '20%'}}/>
                <col style={{width: '80%'}}/>
              </colgroup>

              <tbody>
              {
                AppEditDlg.arrFields.map
                (
                  (f, i) =>
                  {
                    const ref = reffer.bind(this, f);
                    const bg = AppEditDlg.arrFieldTypes[i] === 'readonly' ? Colors.clrNimbixGray : 'none';
                    return (
                      <tr key={i}>
                        <td style={{...Styles.TableCellBordered, textAlign: 'right'}}>{AppEditDlg.arrFieldNames[i]}</td>
                        <td style={{...Styles.TableCellBordered, padding: 0, width: '100%', backgroundColor: bg}}>
                          {

                            AppEditDlg.arrFieldTypes[i] === 'text' ?
                              <input ref={ref} style={AppEditDlg.styleInput}/>
                            :

                            AppEditDlg.arrFieldTypes[i] === 'readonly' ?
                              <input ref={ref}
                                     style={{...AppEditDlg.styleInput, fontWeight: 'bold'}}
                                     value={app[f]}
                                     readOnly/>
                            :

                            AppEditDlg.arrFieldTypes[i] === 'boolean' ?
                              <select ref={ref} style={AppEditDlg.styleInput} defaultValue={app[f]}>
                                <option>true</option>
                                <option>false</option>
                              </select>
                            :

                            AppEditDlg.arrFieldTypes[i] === 'arch' ?
                              <select ref={ref} style={AppEditDlg.styleInput}>
                                {
                                  Data.MachineArchs.arch.map((k, j) => <option key={j}>{k}</option>)
                                }
                              </select>
                            :

                              null
                          }
                          </td>
                      </tr>
                    );
                  }
                )
              }

              <tr key={-1}>
                <td style={{...Styles.TableCellBordered, textAlign:'right', verticalAlign: 'top'}}>App Def</td>
                <td style={{...Styles.TableCellBordered, padding: 8, backgroundColor: Colors.clrNimbixGray}}>
                  <div style={Styles.Inline}>
                    <RaisedButton {...Btns.Blue} label='Load From File' onClick={this.onClickLoad}/>
                    &nbsp;
                    <RaisedButton {...Btns.Green} label='Save To File' onClick={this.onClickSave}/>
                    &nbsp;
                    <a ref='downloadLink' />
                  </div>

                  <Separator/>
                  <HLine margin={0}/>

                  <pre ref={(r) => this.elems['data'] = r} style={{whiteSpace: 'pre-wrap', padding: 4, color: Colors.clrNimbixDark}}>
                    {JSON.stringify(app.data, null, 4)}
                  </pre>
                </td>
              </tr>

              </tbody>
            </table>
          </div>

          <Separator units={2}/>

          <div style={{...Styles.FlexSpread}}>
            <RaisedButton {...Btns.Gray} label='Cancel'/>
            <RaisedButton {...Btns.Blue} onClick={this.onOK} label='Save'/>
          </div>

          <FileChooser ref='fileChooserLoad' asURL={false} onRead={this.onReadAppDef} filter='.json' />
        </Paper>

      </ModalView>
    )
  }
}

export default class AdminApps extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {searchText: '', showOnlyPublic: true};
  }

  // When this page mounts, force a refresh
  componentDidMount()
  {
    AdminAppsDataFetcher.whenDone(()=>this.forceUpdate());
  }

  onClickEntry = (evt) =>
  {
    // Open the dialog with admin data (which has the reg symbols unprocessed)
    GlobalUI.PaneAppEditDlg.openWithApp
    (
      AdminAppsDataFetcher.admindata[evt.currentTarget.getAttribute('data-app')]
    );
  }

  // Searchbox change and clear handlers
  onSearch = (searchText) =>
  {
    this.setState({searchText: searchText.toUpperCase().trim()});
  }

  onSearchClear = () =>
  {
    this.setState({searchText: ''});
  }

  render()
  {
    // Filter out the search
    const arrFiltered = FilterAppNames(AdminAppsData, this.state.searchText, this.state.showOnlyPublic);

    // Render the apps rows
    const elemApps =
      arrFiltered.map(app => <AdminAppRow key={app} app={app} appdata={AdminAppsData[app]}
                                          onClickEntry={this.onClickEntry}/>);
    return (
      <div>
        {
          AdminAppsData && Object.keys(AdminAppsData).length > 0 ?
          <div>
            <div style={Styles.FlexSpread}>
              <SearchBox onSearch={this.onSearch} onClear={this.onSearchClear} width='50%'/>
              <Checkbox style={{width: '20%'}}
                        onCheck={(e, v) => this.setState({showOnlyPublic: v})}
                        label='Public Only'
                        defaultChecked={true}/>
            </div>
            <Separator/>
            <Separator/>

            <HLine margin={0}/>
            <Separator/>
          </div>
          :
          <Spinner style={{...Styles.Full, marginTop: '10%'}} size={64}
                   textColor={Colors.clrNimbixDark} status='Loading...'/>
        }

        {elemApps}
      </div>
    );
  }
}
