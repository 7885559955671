import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

import {Styles, LayoutDims, Colors, Btns} from './UIConst';

import {Separator, ShowOnly, HLine, SearchBox, AdminAppRow} from './Components';
import {FilterAppNames} from '../Models/AppsData';
import {Spinner, FetchBusy, GlobalUI} from '../Global';
import {Data, DataFetcher, FixRegSymbols, GetTeamList} from '../Models/Model';

export class SettingsTeamApps extends Component
{

  constructor(props)
  {
    super(props);
    this.state =
    {
      searchText: '',   // Current search string
      selected: '*',    // Currently selected user
      apps: null,       // List of apps
      checked: {},      // map of app to bool specifying if its white listed for current used
      mode: 'view',     // View / Edit
      filter: 'all',    // all/list/blank for *, explicit list and no list
      showOnlyPublic: true,
    };

    // Fetch all apps here regardless of zone (zone=None)
    this.fetcher = new DataFetcher('/portal/app-quick-list');
    this.fetcher.whenDone
    (
      ()=>
      {
        // Convert array to map of appid->app
        const apps = {};
        for(const app of this.fetcher.data)
        {
          apps[app.id] = app;

          // Fix (R) and (TM) HTML entities
          app.data.name = FixRegSymbols(app.data.name);
          app.data.description = FixRegSymbols(app.data.description);
          app.data.author = FixRegSymbols(app.data.author);
        }

        this.setState({apps});
        this.onChangeUser()
      }
    );

    // Get the team list
    this.teamList = GetTeamList("omitAdmins");
  }

  // When this page mounts, fetch apps list
  componentDidMount()
  {
    // Get team owner
    const payer = Data.User.Profile.payer || Data.User.Profile.user_login;
    this.fetcher.fetch('POST', {user: payer});
  }

  // On dropdown change
  onChangeUser = () =>
  {
    // get selected user name, and current whitelist
    const selected = this.refs.selectMember.value;
    const sList = Data.AppsWhitelist[selected];
    let arrList = sList ? sList.split(/\s+/) : [];

    // set whitelist filter state
    const bEmpty = arrList.length === 0;
    const bAll = !bEmpty && arrList[0] === '*';
    const bInherit = arrList.includes('**');
    const filter = bEmpty  ? 'none' : bAll ? 'all' : bInherit ? 'inherit' : 'list';

    // If it's 'inherit', remove the '**' entry (its added implicitly when saving and not seen by the user)
    if(bInherit)
    {
      arrList = arrList.filter(x => x !== '**');
    }

    // Make a map of app to bool
    const checked = Object.fromEntries(arrList.map(x=>[x, true]));

    // Set the current user, checked apps and filter state
    this.setState({selected, checked, filter});
  }

  // Searchbox change and clear handlers
  onSearch = (searchText) =>
  {
    this.setState({searchText: searchText.toUpperCase().trim()});
  }

  onSearchClear = () =>
  {
    this.setState({searchText: ''});
    this.refs.search.inputField.value = '';
  }

  onCheckApp = (e, v) =>
  {
    // Set this.state.checked[app] to true or remove it
    const app = e.target.getAttribute('data-app');
    const checked = {...this.state.checked};
    if(v)
    {
      checked[app] = true;
    }
    else
    {
      delete checked[app];
    }

    this.setState({checked});
  }

  onCheckAll = () =>
  {
    const checked = {...this.state.checked};
    const allChecked = this.allChecked();

    // Change the state of the visible checkboxes only
    for(const app of this.arrFiltered)
    {
      if(!allChecked)
      {
        checked[app] = true;
      }
      else
      {
        delete checked[app];
      }
    }

    this.setState({checked});
  }

  // For a new whitelist, clear search, all the checkboxes and set ui mode
  onAdd = () =>
  {
    this.setState({searchText: '', mode: 'add', filter:'all', checked: {'jarvice-filemanager': true}});
    this.onSearchClear();
  }

  // For edit, values are already set
  onEdit = () =>
  {
    // Set UI mode
    this.setState({mode: 'edit'});
  }

  // When cancelled set to whatever list it was before
  onCancel = () =>
  {
    // Set UI mode, clear search
    this.setState({mode: 'view'});
    this.onSearchClear();

    // Refresh the list by triggering a dummy dropdown change
    this.onChangeUser();
  }

  // Save the current whitelist
  onSave = () =>
  {
    if(this.state.filter === 'all')
    {
      this.doSave(['*']);
    }
    else
    {
      let arrList = Object.keys(this.state.checked);

      if(arrList.length === Object.keys(this.state.apps).length)
      {
        GlobalUI.Dialog.show('Error', 'You cannot add all apps to the whitelist!');
      }
      else if(arrList.length === 0)
      {
        GlobalUI.Dialog.show('Error', 'You must check at least one application entry!');
      }
      else
      {
        // Reject wl appid entries that dont exist
        arrList = arrList.filter(app => app in this.state.apps);

        // If we have the 'inherit' option, add the special app entry '**' that tells the
        // DAL to inherit the team default
        if(this.state.filter === 'inherit')
        {
          arrList.push('**');
        }

        this.doSave(arrList);
      }
    }
  }

  onChangeRadio = (e, filter)=>
  {
    // When switching from a list to all apps or vice versa, get rid of checked list
    // Add file-manager in by default
    this.setState({filter, checked: filter === 'list' ? {'jarvice-filemanager': true} : {}});
  }

  // Do the actual saving
  doSave = (wl) =>
  {
    const isDelete = wl.length === 0;
    const sWhiteList = wl.join(' ');
    const params = {member: this.state.selected, whitelist: sWhiteList};
    const fetcher = new DataFetcher('/portal/team-set-app-whitelist', params);
    fetcher.whenDone
    (
      ()=>
      {
        // Save locally
        Data.AppsWhitelist[this.state.selected] = sWhiteList;

        // Go back to readonly state
        this.setState({mode: 'view'});
        this.onSearchClear();

        // Refresh the list by triggering a dummy dropdown change
        this.onChangeUser();

        //AuditLog('limit', sUser === '*' ? 'team' : sUser, 'Deleted');
        //this.updateState(sUser, null, null, null);
      }
    );

    const sAction = isDelete ? 'Deleting' : 'Saving';
    fetcher.ifFail(jqXHR => GlobalUI.Dialog.showErr(jqXHR, 'Error ' + sAction));
    FetchBusy(fetcher, sAction + '...');
  }

  // Whether the visible apps are ALL checked
  allChecked = () =>
  {
    let nChecked = 0;
    for(const k of this.arrFiltered)
    {
      if(this.state.checked[k]) ++nChecked;
    }

    const len = this.arrFiltered.length;
    return len ? nChecked === len : false;
  }

  render()
  {
    // Calculate available space
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 400);

    const bIsView = this.state.mode === 'view';
    const arrChecked = Object.keys(this.state.checked);

    let sHelp = this.state.selected === '*' ? 'non-admin team members' : 'this team member';
    if(bIsView)
    {
      sHelp = 'Currently enabled apps for ' + sHelp;
    }
    else
    {
      sHelp = 'Check the apps to enable for ' + sHelp +
              '. Note that this is a white-list, and apps that are not checked will not be enabled';
    }

    // Render the apps rows, on;y checked apps if viewing
    this.arrFiltered = FilterAppNames(this.state.apps, this.state.searchText, this.state.showOnlyPublic,
                                      bIsView ? arrChecked : null);

    const elemApps =
      this.state.filter !== 'all' &&
      this.arrFiltered.map
      (
        (app) =>
          <AdminAppRow key={app} app={app}
                       isTeamAdmin={true}
                       appdata={this.state.apps[app]}
                       checked={app in this.state.checked}
                       onCheckApp={this.onCheckApp}
                       readOnly={bIsView} />
      );

    return (
      <div>
        {
          this.state.apps ?
          <div>
            <div style={Styles.FlexSpread}>
              <div style={{flex: 1}}>
                Select User:&nbsp;
                <select data-cy='selectWhitelistMember'
                        ref='selectMember'
                        disabled={!bIsView}
                        style={{...Styles.ParamInput, backgroundColor: !bIsView ? Colors.clrNimbixGray : Colors.clrLight}}
                        onChange={this.onChangeUser}>
                  <option value='*'>&lt;Team Default&gt;</option>
                  {this.teamList.map((e,i)=><option key={i} value={e}>{e}</option>)}
                </select>
              </div>

              &nbsp; &nbsp;

              {
                bIsView ?
                  (
                    this.state.filter === 'none'
                    ?
                      <RaisedButton {...Btns.Green} label='Add Whitelist' onClick={this.onAdd}/>
                    :
                      <div>
                        <RaisedButton {...Btns.Green} label='Edit' onClick={this.onEdit}/>
                        &nbsp;
                        <RaisedButton {...Btns.DarkRed} label='Delete' onClick={()=>this.doSave([])}/>
                      </div>
                  )
                :
                  <div style={Styles.InlineFlexRow}>
                    <RaisedButton key={0} {...Btns.Green} label='Save' onClick={this.onSave}/>
                    &nbsp;
                    <RaisedButton key={1} {...Btns.DarkRed} label='Cancel' onClick={this.onCancel}/>
                  </div>
              }

            </div>

            <Separator units={2}/>

            <ShowOnly if={(bIsView && this.state.filter !== 'none') || !bIsView}>
              <div>
                <RadioButtonGroup ref='radioGroupApps'
                                  name='teamApps'
                                  valueSelected={this.state.filter}
                                  style={{...Styles.Inline, width: '100%'}}
                                  onChange={this.onChangeRadio}>

                  <RadioButton data-cy='radioTeamAppsAll'
                               disabled={bIsView}
                               style={Styles.Radio}
                               value='all'
                               label='Allow all apps, including PushToCompute™ if applicable'/>

                  <RadioButton data-cy='radioTeamAppsList'
                               disabled={bIsView}
                               style={Styles.Radio}
                               value='list'
                               label='Select individual apps'/>

                  <RadioButton data-cy='radioTeamAppsList'
                               disabled={bIsView || this.state.selected === '*'}
                               style={{...Styles.Radio, display: this.state.selected === '*' ? 'none' : 'inline-block'}}
                               value='inherit'
                               label='Select individual apps in addition to team default list'/>

                </RadioButtonGroup>

                <ShowOnly if={['list', 'inherit'].includes(this.state.filter)}>
                  <p>{sHelp}</p>
                  <Separator units={0.5}/>
                  <div style={{...Styles.FlexSpread}}>
                    <div style={{...Styles.InlineFlexRow, flex: 1}}>
                      <SearchBox entryType='Whitelist'
                                 onSearch={this.onSearch}
                                 onClear={this.onSearchClear}
                                 width='50%'
                                 ref='search'/>
                      &nbsp;
                      <Checkbox data-cy='checkPublicOnly'
                                style={{width: '20%', marginLeft: LayoutDims.nMargin * 2}}
                                onCheck={(e, v) => this.setState({showOnlyPublic: v})}
                                labelStyle={{whiteSpace: 'nowrap'}}
                                label='Public Only'
                                defaultChecked={true}/>
                    </div>

                    <div style={Styles.InlineFlexRow}>
                      <ShowOnly if={!bIsView}>
                        <div style={Styles.InlineFlexRow}>
                          Select all visible&nbsp;&nbsp;
                          <Checkbox data-cy='checkSelectAllVisible'
                                    disabled={bIsView}
                                    labelStyle={{whiteSpace: 'nowrap'}}
                                    style={{width: '5%'}}
                                    checked={this.allChecked()}
                                    onCheck={this.onCheckAll} />
                        </div>
                      </ShowOnly>
                    </div>
                  </div>

                  <Separator units={2}/>
                  <HLine margin={0}/>
                  <Separator units={1}/>

                  <div style={{maxHeight: hAvail, overflowY: 'scroll', overflowX: 'hidden'}}>
                    {elemApps}
                  </div>
                </ShowOnly>

              </div>

            </ShowOnly>

          </div>
          :
          <Spinner style={{...Styles.Full, marginTop: '10%'}} size={64}
                   textColor={Colors.clrNimbixDark} status='Loading...'/>
        }

      </div>
    );
  }
}
