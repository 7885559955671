// Core
import React, {Component} from 'react';

// Material UI Controls
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';

import {GlobalUI, FetchBusy} from '../Global'

// Our custom components
import {ContextMenu} from './Components';
import {DataFetcher, GetPortalAuthURL} from '../Models/Model';
import {PTCAppsData, PTCAppsDataFetcher} from '../Models/AppsData';

import PTCEditDlg from './PTCEditDlg'

export default class PTCContextPane extends Component
{
  // Map between the context menu items and the material design icons
  static ElemMenuItems =
  {
    'Edit': 'mode_edit',
    'Delete': 'delete',
    'Build': 'build',
    'Build+Pull': 'low_priority',
    'Abort Build': 'error',
    'Pull': 'cloud_download',
    'History': 'restore',
    'Download AppDef' : 'get_app'
  }

  constructor(props)
  {
    super(props);

    this.state =
    {
      menuHandlers :
      {
        'History': this.dockerHistory,
        'Build': this.ptcBuild,
        'Build+Pull': this.ptcBuildPull,
        'Abort Build': this.ptcAbortBuild,
        'Pull': this.ptcPull,
        'Delete': this.ptcDelete,
        'Edit': this.ptcEdit,
        'Download AppDef' : this.ptcDownload,
      },
    };
  }

  // Build and abort build use the same API
  ptcDoBuild = (bAbort, bPull, sClause, sTitle) =>
  {
    const params =
    {
      repo: PTCAppsData[this.props.app].repo,
      target: this.props.app,
      pull: bPull,
      abort: bAbort
    };

    // The action tp be performed after user confirms
    const fnAction = () =>
    {
      // Perform the build, with a spinner
      const fetcher = new DataFetcher('/portal/docker-build', params)
        .ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, sTitle + ' Status'))
        .whenDone((jqXHR) => GlobalUI.Dialog.show(sTitle + ' Status', jqXHR.responseJSON));

      FetchBusy(fetcher, sTitle === 'Abort Build'
        ? 'Aborting Build...'
        : 'Scheduling ' + sTitle + '...');
    }

    // If its abort, no web link shown
    if(bAbort)
    {
      this.ptcConfirmAndBuild(params, null, sClause, sTitle, fnAction);
    }
    else
    {
      // Fetch URL, display confirmation dialog and then pull
      new DataFetcher('/portal/get-docker-url', {...params, action: 'build'})
      .whenDone
      (
        (jqXHR) => this.ptcConfirmAndBuild(params, jqXHR.responseText, sClause, sTitle, fnAction)
      )
      .fetch();
    }
  }

  // Performs the pull operation with a spinner
  ptcDoPull = (params) =>
  {
    const fetcher = new DataFetcher('/portal/docker-pull', {...params, action: 'pull'})
      .ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Pull failed'))
      .whenDone
      (
        (jqXHR) => GlobalUI.Dialog.show('Container Pull Response', jqXHR.responseJSON)
      )
    FetchBusy(fetcher, 'Pulling from container repository...');
  }

  dockerHistory = () =>
  {
    GlobalUI.PTCAppView.refs.ptcHistoryDlg.setState({open: true});
    GlobalUI.PTCAppView.refs.ptcHistoryDlg.startTimer(this.props.app);
  }

  // Pull with confirm
  ptcConfirmAndBuild = (params, webLink, action, title, fn) =>
  {
    GlobalUI.Dialog.confirm
    (
      <div>
        <div>
          Are you sure you wish to {action} the application&nbsp;
          <b>'{params.target}'</b>?
        </div>
        <br/>

        {
          webLink &&
          <div>
            <i>
              (You can also copy this <a href={webLink} target='webhook'><u>link </u></a>
              to use as a web hook in the future)
            </i>
          </div>
        }
      </div>,
      'Confirm ' + title,
      fn
    )
  }

  // Send build request
  ptcBuild = () =>
    this.ptcDoBuild(false, false, 'build', 'Build');

  ptcAbortBuild = () =>
    this.ptcDoBuild(true, false, 'abort the build for', 'Abort Build');

  ptcBuildPull = () =>
    this.ptcDoBuild(false, true, 'build and pull', 'Build & Pull');

  // Pull with confirm
  ptcConfirmAndPull = (params, webLink) =>
  {
    GlobalUI.Dialog.confirm
    (
      <div>
        <div>
          Are you sure you wish to pull the following repository into application&nbsp;
          <b>'{params.target}'</b>?
        </div>
        <pre>
              &nbsp;{params.repo || "<No repository specified>"}&nbsp;
        </pre>
        <div>
          <i>
            (You can also copy this <a href={webLink} target='webhook'><u>link </u></a>
            to use as a web hook in the future)
          </i>
        </div>
        <p>
          <b>NOTE:</b> pulling an application will deploy the updates immediately
          after the pull completes!
        </p>
      </div>,
      'Confirm pull',
      () => this.ptcDoPull(params)
    )
  }

  ptcPull = () =>
  {
    // Get the Pull URL
    const params =
    {
      repo: PTCAppsData[this.props.app].repo,
      target: this.props.app,
      action: 'pull'
    };

    // Display confirmation dialog and then pull
    new DataFetcher('/portal/get-docker-url', params)
      .whenDone((jqXHR) => this.ptcConfirmAndPull(params, jqXHR.responseText))
      .fetch();
  }

  ptcDelete = () =>
  {
    GlobalUI.Dialog.confirm
    (
      <div>
        <p>
          Are you sure you wish to delete the application {this.props.app}?
        </p>
      </div>,
      'Confirm Delete',
      ()=>
      {
        // For delete, we manually remove from the UI
        // appList doesn't immediately remove the app
        const fetcher = new DataFetcher('/portal/ptc-delete', {name: this.props.app})
          .ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Delete failed'))
          .whenDone
          (
            ()=>
            {
              delete PTCAppsData[this.props.app];
              PTCAppsDataFetcher.notifyAllSuccess();
            }
          );

        FetchBusy(fetcher, 'Deleting...');
      },
      null,
      'Yes',
      'No'
    );
  }

  ptcEdit = () =>
  {
    const fetcher = new DataFetcher(`/portal/ptc-get-app-def?app=${this.props.app}`);
    fetcher.whenDone
    (
      ()=>
      {
        const elems = <PTCEditDlg edit={true} app={this.props.app} appdef={fetcher.data}/>
        GlobalUI.Dialog.show('Edit Application', elems);
        GlobalUI.Dialog.setState({top: 0});
      }
    );

    FetchBusy(fetcher, 'Loading...');
  }

  ptcDownload = () =>
  {
    const url = `/portal/ptc-get-app-def?app=${this.props.app}`;
    window.open(GetPortalAuthURL(url, '_blank'));
  }

  onMenuItemClick = (sItem) =>
  {
    this.state.menuHandlers[sItem]();
  }

  getMenuEnabled = () =>
  {
    const hasRepo = PTCAppsData[this.props.app].repo;
    const hasSrc  = PTCAppsData[this.props.app].src;

    let menuEnabled =
    {
      'History': true,
      'Build': true,
      'Build+Pull': true,
      'Abort Build': true,
      'Pull': true,
      'Delete': true,
      'Edit': true,
      'Download AppDef' : true,
    };

    if(!hasRepo || !hasSrc)
    {
      menuEnabled['Build'] = false;
      menuEnabled['Abort Build'] = false;
      menuEnabled['Build+Pull'] = false;
    }

    if(!hasRepo)
    {
      menuEnabled['Pull'] = false;
    }

    return menuEnabled;
  }

  render()
  {
    return <ContextMenu items={PTCContextPane.ElemMenuItems}
                        name={this.props.appName}
                        icon={<NavigationMenu/>}
                        iconStyle={{padding: 0, width: 24, height: 24}}
                        onMenuItemClick={this.onMenuItemClick}
                        fnMenuEnabled={this.getMenuEnabled}/>
  }
}


