/*
This file is the main entry point for the  react application
 */
/* eslint-disable eqeqeq */

import {init} from './init';
import {DataFetcher} from './Models/Model';
import {InitColors, InitImages} from './Components/UIConst';
import {Elem, GlobalUI} from './Global';

// Extract the URL data
let URLData = window.location.search.slice(1);
for(const param of URLData.split('&'))
{
  const arrParam = param.split('=');
  GlobalUI.URLParams[arrParam[0]] = decodeURIComponent(arrParam[1]);
}

GlobalUI.URLPage = GlobalUI.URLParams['page'];
if(GlobalUI.URLPage)
{
  delete GlobalUI.URLParams['page'];

  // Strip the "&page=<whatever>" part of the URL
  GlobalUI.URLParams[GlobalUI.URLPage + 'data'] = URLData.replace('&page=' + GlobalUI.URLPage, '');
}

const isImgLoaded = (img) =>
{
  return img.complete && typeof img.naturalWidth !== 'undefined' && img.naturalWidth !== 0;
}

// Before calling init() lets load the palette
const fetcher = new DataFetcher('/static/palette.json', {}, null, false);
fetcher.whenDone
(
  // On success set the palette
  (jqXHR)=>
  {
    // Avoid messing up if json is bad
    try
    {
      const dctColors = JSON.parse(jqXHR.responseText);
      InitColors(dctColors);
    }
    catch(e)
    {
      console.log('Failed to load palette')
    }

    // Check if the custom logo etc loaded and if so set them
    let imgFavicon = Elem('img-favicon');
    let imgLogo = Elem('img-logo');
    let imgDefault = Elem('img-default');

    const sImgFavicon = isImgLoaded(imgFavicon) ? imgFavicon.src : null;
    const sImgLogo = isImgLoaded(imgLogo) ? imgLogo.src : null;
    const sImgDefault = isImgLoaded(imgDefault) ? imgDefault.src : null;

    InitImages(sImgDefault, sImgFavicon, sImgLogo);

    init();
  }
)
.ifFail
(
  // On fail set default palette
  () =>
  {
    InitColors();
    init();
  }
)
.fetch('GET');


