import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import {Styles, LayoutDims, Colors, Btns} from './UIConst';

import {Separator, ShowOnly, ColGroup, ReportDisplay} from './Components';
import {Spinner} from '../Global';
import {DataFetcher} from '../Models/Model';

export default class AdminZoneUsers extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {users: null, usersInZone: null, zoneDesc: null, selected: null, firstTime: true};

    this.fetcher = new DataFetcher('/portal/admin-zone-users');
    this.fetcher.whenDone
    (
      ()=>
      {
        // Convert the zone data [{id, desc}, ... ] into a dict { id: desc, ... }
        const zoneDesc = {};
        for(const zone of this.fetcher.data.zones)
        {
          zoneDesc[zone.id] = zone.desc;
        }

        // Add the description unless set
        if(!zoneDesc[0])
        {
          zoneDesc[0] = '<Default>'
        }

        // Grab the first zone that is in users_in_zone as the selection
        let selected;
        for(const k in Object.keys(this.fetcher.data.users_in_zone))
        {
          selected = k;
          break;
        }

        this.setState
        (
          {
            usersInZone: this.fetcher.data.users_in_zone,
            users: this.fetcher.data.users,
            zoneDesc,
            selected,
          }
        )
      }
    );
  }

  // Creates the data for ReportDisplay for given zone
  makeData = (zone) =>
  {
    const arrData = [];

    let id = 0;
    for(const user of this.state.usersInZone[zone] || [])
    {
      const email = this.state.users[user][0];
      const payer = this.state.users[user][1];
      const payer_email = payer && this.state.users[payer][0];
      arrData.push({id, user, email, payer: payer || '', payer_email: payer_email || ''})
      ++id;
    }

    return arrData;
  }

  // Clear state and refetch
  refetch = () =>
  {
    this.setState
    (
      {firstTime: false, users: null, usersInZone: null, zoneDesc: null, selected: null},
      ()=>this.fetcher.fetch()
    );
  }

  // On dropdown change set selected zone
  onChangeZone = () => this.setState({selected: this.refs.selectZone.value});


  render()
  {
    // Calculate available space
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 200);
    const data = this.state.usersInZone && this.makeData(this.state.selected);

    return (
      <div>
      {
        this.state.usersInZone || this.state.firstTime
        ?
        <div>
          <div style={Styles.FlexSpread}>
            <div style={{flex: 1}}>
              <b>Zone:&nbsp;</b>
              <select data-cy='selectZoneUser'
                      ref='selectZone'
                      style={Styles.ParamInput}
                      onChange={this.onChangeZone}>
                {
                  Object.keys(this.state.usersInZone || []).map
                  (
                    (e, i) => <option key={i} value={e}>{`${e}: ${this.state.zoneDesc[e] || '-'}`}</option>
                  )
                }
              </select>
            </div>

            <RaisedButton {...Btns.Green} label={this.state.firstTime ? 'Fetch' : 'Refresh'} onClick={this.refetch}/>
          </div>

          <Separator units={2}/>
          {
            !this.state.firstTime &&
            <div style={{maxHeight: hAvail, minHeight: hAvail, overflowY: 'auto'}}>
              <ReportDisplay ref='zoneUserList'
                             data={data || []}
                             colFields={['user', 'email', 'payer', 'payer_email']}
                             colText={['User', 'Email', 'Payer', 'Payer Email']}
                             colGroup={<ColGroup cols={[15, 35, 15]}/>}/>

              <ShowOnly if={!data.length}>
                <div style={{margin: 0, padding: LayoutDims.nMargin, ...Styles.Bordered}}>None</div>
              </ShowOnly>

            </div>
          }
        </div>
        :
        <div>
          <Separator units={12}/>
          <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
        </div>
      }
      </div>
    );
  }
}
