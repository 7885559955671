/* eslint-disable default-case */
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {Separator, SearchBox, ReportDisplay, InputRow, ColGroup} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher, Data} from '../Models/Model';
import {IntCmp} from './Utils';


//Dialog for editing or adding a zone
class ZoneDlg extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props, error: ''};
  }

  // When reopened reset state to new props
  componentWillReceiveProps(nextProps)
  {
    this.setState
    (
      {...nextProps},

      // Set input values after update
      () => this.setInputValues(nextProps)
    );
  }

  // On rerender, set the values
  componentDidMount()  {this.setInputValues(this.state); }


  // Sets the desc field if editing/adding
  setInputValues(state)
  {
    if(state.action !== 'Delete')
    {
      this.refs['zone_desc'].value = state.action === 'Edit' ? state['desc'] : '';

      // Defer focus 
      setTimeout(()=>this.refs['zone_desc'].focus(), 1);
    }
  }

  // On save fire the request
  onSave = () =>
  {
    // Get the values
    let data = {};

    // For non add, we need an id
    if(this.state.action !== 'Add')
    {
      data.id = this.state.id
    }

    // For non delete we need a desc
    const isDelete = this.state.action === 'Delete'
    if(!isDelete)
    {
      data.desc = this.refs['zone_desc'].value;
    }

    // Fire the request
    const sURL = '/portal/admin-zone-' + (isDelete ? 'delete' : 'set');
    const fetcher = new DataFetcher(sURL, data)
    .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
    .whenDone
    (
      // On success, Update the Data.Zones and make the parent refresh
      ()=>
      {
        GlobalUI.Dialog.onClose();

        switch(this.state.action)
        {
          case 'Edit':
          case 'Add':
            // force the id fields to integer type
            data.id |= 0;
            data.id = fetcher.data.result;
            Data.Zones[data.id] = {...data};
          break;

          case 'Delete':
            delete Data.Zones[data.id];
          break;
        }

        this.state.parent.doUpdate();
      }
    );

    const sMsg = isDelete ? 'Deleting zone...' : 'Saving...';
    FetchBusy(fetcher, sMsg);
  }

  onChange = () =>
  {
    const val = this.refs['zone_desc'].value;
    if(!val || val.length === 0)
    {
      this.setState({err: 'Enter a valid zone description'});
      return true;
    }

    this.setState({err: null});
  }

  render()
  {
    const isDelete = this.state.action === 'Delete';
    const sWidth = `calc(100% - ${LayoutDims.nMargin * 2}px)`;

    return (
      <div>
        {
          !isDelete
          ?
            <div>
              <InputRow title='Description:' width={sWidth}>
                <input data-cy='inputZoneDesc' ref='zone_desc' style={Styles.ParamInputWide} onChange={this.onChange} />
              </InputRow>

              <span data-cy='divZoneErr' className='errortext' style={{fontSize: LayoutDims.FontSize}}>
                {this.state.err}&nbsp;
              </span>
              <Separator units={1}/>

            </div>
          :
            <div>
              <Separator/>
              Are you sure you wish to delete this zone?
              <Separator units={3}/>
            </div>
        }

        <div style={Styles.FlexSpread}>
          <RaisedButton {...Btns.Gray} label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton
            data-cy='confirmOK'
            {...Btns.Blue}
            label='OK'
            onClick={this.onSave}
            disabled={!isDelete && this.state.err != null} />
        </div>
      </div>
    );
  }
}

export default class AdminZones extends Component
{
  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['zid', 'desc'];
  static arrColNames = ['Zone ID', 'Description'];

  constructor(props)
  {
    super(props);
    this.state = {data: null, search: '', selectedIndex: -1};
  }

  // On mount update once
  componentDidMount()
  {
    this.doUpdate();
  }

  // Fix up the data as required for rendering and perform render
  doUpdate = () =>
  {
    // make the data from a dict to array of dict
    const arrNames = Object.keys(Data.Zones);
    arrNames.sort((a, b) => IntCmp(Data.Zones[a].id, Data.Zones[b].id));

    // We cant use the field name ID because ReportDisplay needs it as the row index
    const data = arrNames.map
    (
      (name, i) =>
      {
        const zone = Data.Zones[name];
        return {zid: zone.id, desc: zone.desc, id : i}
      }
    );

    this.setState({data, selectedIndex: -1});
  }

  onSearchClear = () =>
  {
    this.refs.searchBox.inputField.value = '';
    this.setState({search: '', selectedIndex: -1});
  }

  onSearchIncr = (s) =>
  {
    // Case insensitive search
    this.setState({search: s.toUpperCase().trim(), selectedIndex: -1});
  }

  onAdd = () => this.onZoneAction('Add')
  onEdit = () => this.onZoneAction('Edit')
  onDel = () => this.onZoneAction('Delete')

  // Show dialog box (delete shows confirm dialog)
  onZoneAction = (action) =>
  {
    // Get the selected index and corresponding zone ID
    const idx = this.state.selectedIndex;
    const zid = this.state.data[idx] && this.state.data[idx].zid;
    const zone = zid !== null ? {...Data.Zones[zid]} : null;
    const props = {action, parent: this, ...zone};

    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      action === 'Delete' ? 'Confirm' : `${action} Zone`,
      <ZoneDlg {...props}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  // Row select handler
  onClickRow = (i) =>
  {
    this.setState({selectedIndex: i});
  }

  render()
  {
    // Use as much height as possible
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 160);

    const idx = this.state.selectedIndex;
    const zid = idx >= 0 ? (this.state.data[idx] && this.state.data[idx].zid) : 0;

    // Filter on search string (already upper cased)
    const search = this.state.search;
    const data = this.state.data && this.state.data.length > 0
    ?
      this.state.data.filter(row => !search || (row.cid + '|' + row.urlText + '|' + row.desc).toUpperCase().indexOf(search) >= 0)
    :
      [];

    return (
      <div>
        <div style={Styles.FlexSpread}>
          <SearchBox entryType='Zone' ref='searchBox' onSearch={this.onSearchIncr} onClear={this.onSearchClear} width='50%'/>
        </div>

        <Separator />

        <div style={Styles.FlexSpread}>
          <div>
            <RaisedButton data-cy='btnZoneNew' {...Btns.Green} label='New' onClick={this.onAdd}/>
            &nbsp;&nbsp;
            <RaisedButton data-cy='btnZoneEdit' disabled={zid === 0} {...Btns.Blue} label='Edit' onClick={this.onEdit}/>
            &nbsp;&nbsp;
            <RaisedButton data-cy='btnZoneDelete' disabled={zid === 0} {...Btns.DarkRed} label='Delete' onClick={this.onDel}/>
          </div>
        </div>

        <Separator />

        <div style={{maxHeight: hAvail, minHeight: hAvail, overflowY: 'auto'}}>

          {
            this.state.data
            ?
              <ReportDisplay ref='zoneList'
                             data={data}
                             colFields={AdminZones.arrColFields}
                             getExtraStyle={row => row.zid ? null : {fontWeight: 'bold'}}
                             colText={AdminZones.arrColNames}
                             colGroup={<ColGroup cols={[15]} />}
                             onClickRow={this.onClickRow}
                             selectedIndex={this.state.selectedIndex}
              />

            :
              <div>
                <Separator units={12}/>
                <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
              </div>
          }

        </div>
      </div>

    );
  }
}
