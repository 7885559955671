// Core
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

// Our UI data, styles and colors
import {Styles, Btns, Colors, LayoutDims} from './UIConst.js';
import {InputRow, Separator, ShowOnly} from './Components'

import {Data, DataFetcher, AuditLog} from '../Models/Model';
import {FetchBusy, GlobalUI, LocalStorage} from '../Global'


// Collapsible app view, with filtering
export class SettingsProfile extends Component
{
  static arrFieldNames = ['user_nicename', 'user_company', 'user_phone'];

  constructor(props)
  {
    super(props);
    this.state = {editing: false};
  }

  // Update edit state based on parent
  componentWillReceiveProps(nextProps)
  {
    this.setState({editing: nextProps.editing});
  }

  // On save send the request and set to non-edited state if successful
  trySave = (parent) =>
  {
    // Grab all the fields
    let userdata = {};
    const arrFields = ['user_nicename', 'user_phone', 'user_company'];
    for(const field of arrFields)
    {
      userdata[field] = this.refs[field].value;
    }

    // convert to JSON and make the request
    userdata = JSON.stringify(userdata);
    const fetcher = new DataFetcher('/portal/user-save-settings-profile', {userdata});
    fetcher.whenDone
    (
      // On success, update our local store
      ()=>
      {
        for(const field of arrFields)
        {
          Data.User.Profile[field] = this.refs[field].value;
        }

        AuditLog('settings', 'profile', 'Changed');

        // Let the parent pane know the save is successful
        parent.saved(this);
      }
    );

    // On failure, show the error
    fetcher.ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Failed to update profile'));

    // Send the request with a spinner
    FetchBusy(fetcher, 'Saving...');
  }

  // If canceled, just reset all fields to original values
  cancel()
  {
    for(const k of SettingsProfile.arrFieldNames)
    {
      this.refs[k].value = Data.User.Profile[k];
    }
  }

  render()
  {
    const styleInput = {...Styles.ParamInputWide};
    if(!this.state.editing) styleInput.backgroundColor = Colors.clrNimbixGray;

    //console.log(Data.User.Profile)
    return (
      <div style={{...Styles.InlineFlexRow, alignItems: 'flex-start', width: '100%'}}>
        <div style={{flexGrow: 1}}>

          <InputRow title='Full Name'>
            <input data-cy='inputProfileName' defaultValue={Data.User.Profile.user_nicename} ref='user_nicename' readOnly={!this.state.editing} style={styleInput}/>
          </InputRow>

          <InputRow title='Company'>
            <input data-cy='inputProfileCompany' defaultValue={Data.User.Profile.user_company} ref='user_company' readOnly={!this.state.editing} style={styleInput}/>
          </InputRow>

          <InputRow title='Phone'>
            <input data-cy='inputProfilePhone' defaultValue={Data.User.Profile.user_phone} ref='user_phone' readOnly={!this.state.editing} style={styleInput}/>
          </InputRow>
        </div>

        {
          Data.User.Profile.billing_url &&
            <RaisedButton onClick={()=>window.open(Data.User.Profile.billing_url, '_blank')}
                          {...Btns.Green} label='Billing Details'/>
        }
      </div>
    );
  }
}

export class SettingsAuth extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {user_apikey:  Data.User.Profile.user_apikey, oldPasswd:'', newPasswd:''};
  }

  onRegenerateAPIKey = () =>
  {
    const fetcher = new DataFetcher('/portal/user-api-key');

    // On failure, show the error
    fetcher.ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Failed to generate API Key'));

    fetcher.whenDone
    (
      ()=>
      {
        Data.User.Profile.user_apikey = fetcher.data.value;
        this.setState({user_apikey: Data.User.Profile.user_apikey});
        AuditLog('settings', 'apikey', 'Generated');
      }
    );

    // Send the request with a spinner
    FetchBusy(fetcher, 'Fetching New API Key...');
  }

  onChangePassword = () =>
  {
    const oldpass = this.refs.inputPassOld.value;
    const newpass = this.refs.inputPassNew.value;
    const confirm = this.refs.inputPassConfirm.value;

    const err = !oldpass ? 'Please enter current password' :
                !newpass ? 'New password cannot be blank!' :
                newpass !== confirm ? 'Confirm the new password correctly!' :
                newpass === oldpass ? 'New password is the same as the current one!' : null

    if(err)
    {
      GlobalUI.Dialog.show('Error', err, false, LayoutDims.wContent * 0.5)
    }
    else
    {
      const fetcher = new DataFetcher('/portal/change-password', {oldpass, newpass});
      fetcher.ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Failed to change password'));

      fetcher.whenDone
      (
        ()=>
        {
          GlobalUI.Dialog.show('Information', 'Password changed', false, LayoutDims.wContent * 0.5);
          this.refs.inputPassOld.value = '';
          this.refs.inputPassNew.value = '';
        }
      )

      // Send the request with a spinner
      FetchBusy(fetcher, 'Setting new password...');
    }
  }

  toggleAPIShow = () =>
  {
    this.setState({showAPIKey: !this.state.showAPIKey})
  }

  render()
  {
    return (
      <div>
        <div style={{width: '100%'}}>

          <InputRow title='Username'>
            <input defaultValue={Data.User.Profile.user_login}  readOnly={true} style={{...Styles.ParamInputWide, backgroundColor: Colors.clrNimbixGray}}/>
          </InputRow>

          <InputRow title='API Key' width={512}>
            <div disabled style={{...Styles.FlexSpread, padding:0, ...Styles.ParamInput, width: '100%'}}>
              <input data-cy='inputProfileAPIKey'
                     style={{flex: 1, outline: 'none', fontFamily: 'monospace', fontSize: LayoutDims.FontSize, border: 'none'}}
                     value={this.state.showAPIKey ? this.state.user_apikey : '******'}
                     readOnly
                     ref='user_apikey'/>

              <i className='material-icons' onClick={this.toggleAPIShow} data-cy='iconShowAPIKey'
                 style={{margin: 0, color: Colors.clrNimbixLite, cursor: 'pointer', userSelect: 'none', fontSize:LayoutDims.FontSize+2}}>
                {this.state.showAPIKey ? 'visibility_off': 'visibility'}
              </i>

            </div>
          </InputRow>

          <RaisedButton onClick={this.onRegenerateAPIKey} {...Btns.Green}  label='Regenerate'/>

          <ShowOnly if={!Data.User.Profile.user_login.endsWith('_')}>
            <Separator units={4}/>

            <InputRow title='Current Password'>
              <input data-cy='inputPassOld' ref='inputPassOld' type='password' style={Styles.ParamInputWide}/>
            </InputRow>

            <InputRow title='New Password'>
              <input data-cy='inputPassNew' ref='inputPassNew' type='password' style={Styles.ParamInputWide}/>
            </InputRow>

            <InputRow title='Confirm New Password'>
              <input data-cy='inputPassConfirm' ref='inputPassConfirm' type='password' style={Styles.ParamInputWide}/>
            </InputRow>

            <RaisedButton onClick={this.onChangePassword} {...Btns.Blue}  label='Change Password'/>
          </ShowOnly>

        </div>
      </div>
    );
  }
}

export class SettingsMisc extends Component
{
  onClearConfirms = () =>
  {
    LocalStorage.keys()
      .filter((k)=>k.indexOf('CheckBox') === 0)
      .forEach((checkKey) => LocalStorage.set(checkKey, false));

    GlobalUI.Dialog.show
    (
      'Information',
      <div>All dialog preferences have been reset.<br/>
        You will be required to re-confirm some preferences when needed.</div>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  render()
  {
    return (
      <div>
        Reset all dialog preferences
        <br/>
        <br/>
        <RaisedButton onClick={this.onClearConfirms} {...Btns.Green} label='Reset'/>
      </div>
    );
  }
}

