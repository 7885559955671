import React, {Component} from 'react';

import {JobActiveView} from './JobView';
import {JobHistoryView} from './JobHistoryView';
import {JobByLabelView} from './JobByLabelView';

import {ReportView} from './ReportView';

import {TeamJobs} from './TeamJobs';

import {GlobalUI, Spinner} from '../Global';
import {MUI, SwitchedPane} from './Components';
import {Styles, LayoutDims, Colors} from './UIConst';
import {JobsActiveFetcher, AppsRecentFetcher, DataFetcher, IsTeamAdmin} from '../Models/Model';


// Dashboard pane
export default class DashboardPane extends Component
{
  static styleFetchMore = null;

  constructor(props)
  {
    super(props);
    this.firstTime = true;
    this.fnAfterHistoryFetch = null;

    // When a job was last killed
    this.lastJobEnded = 0;

    this.state = {jobs: {}, fetching: false};

    SwitchedPane.setDefault(['DashboardContent'], 'History');
    this.historyFetcher = new DataFetcher('/portal/job-history');
    this.historyFetcher.whenDone
    (
      () =>
      {
        this.mergeJobState(this.historyFetcher.data);
        this.setState({fetching: false});
        if(this.fnAfterHistoryFetch)
        {
          this.fnAfterHistoryFetch();
        }
      }
    );
  }

  // Given the data returned from /portal/job-history merges it into the current state
  mergeJobState = (data) =>
  {
    const jobs = {...this.refs.viewJobsHistory.state.jobs, ...data};
    this.refs.viewJobsHistory.setState({jobs});
  }

  switchPane = (currentPane) =>
  {
    // If switching to history, fetch the latest
    // Also clear any "Recent" entries in the Active jobs pane
    if(currentPane === 'History')
    {
      this.updateHistory();
      this.refs.viewJobsActive.setState({recent:{}});
    }

    SwitchedPane.switch('DashboardContent', currentPane);
  }

  // This is called when an active job moved to inactive state
  jobEnded = (sJobNum, dctJobActive) =>
  {
    // When a job is shutdown manually, we want to put it in the in the "recent" section at
    // the bottom of the active jobs view, which appear exactly like History entries
    // Initially these entries show "Shutting down...", later the actual status
    // To do that, we need to fetch job status for each until we know the jobs actually terminated
    // This is an expensive operation if jobs are bulk terminated, so we skip this unless
    // the last time this was called is > 2 seconds

    const now = (new Date()).getTime() / 1000;
    const diff = now - this.lastJobEnded;
    this.lastJobEnded = now;
    let bJobRecentExists = null;

    if(diff > 2)
    {
      // Add an entry opportunistically to the Recent pane
      dctJobActive.job_status = 'Shutting Down... ';
      bJobRecentExists = this.refs.viewJobsActive.addRecent(sJobNum, {...dctJobActive});

      // We can get multiple notifies for the same job, avoid that
      if(!bJobRecentExists)
      {
        // Fire off a request for the job
        const fetcher = new DataFetcher('/portal/job-list', {jobnum: sJobNum, exactmatch: true});
        fetcher.whenDone
        (
          () =>
          {
            // Check if job is still active, in which case we will try again in 5 seconds
            const dctJob = fetcher.data[sJobNum];
            if(dctJob.job_status === 'PROCESSING STARTING' ||  dctJob.job_status === 'SUBMITTED')
            {
              window.setTimeout(()=>fetcher.fetch(), 5000);
            }
            else
            {
              // Job is done, update the "recent" entry
              dctJob.done = true;
              this.refs.viewJobsActive.addRecent(sJobNum, {...dctJob});
            }
          }
        );

        fetcher.fetch();

        // Update the recent apps pane 5 seconds after
        if(this.timerIDRecentApps)
        {
          window.clearTimeout(this.timerIDRecentApps);
        }
        this.timerIDRecentApps = window.setTimeout
        (
          () =>
          {
            this.timerIDRecentApps = null;
            AppsRecentFetcher.reset();
            AppsRecentFetcher.fetchUpdate(GlobalUI.Main.refs.paneRecent, 'arrApps');
          },
          5000
        );
      }
    }
  }


  setActiveJobs = (data) => this.refs.viewJobsActive.setState({jobs: data});

  updateJobs()
  {
    const view = this.refs.viewJobsActive;
    JobsActiveFetcher.fetchUpdate
    (
      view,
      'jobs',
      null,
      () =>
      {
        // Update the view
        view.forceUpdate();

        this.refs.viewJobsActiveByLabel.setState({jobs: view.state.jobs})

        // Switch the main page to Dashboard,
        // the very first time after a manual login if there is any active job
        // if the current URL isn't a App filter one
        if(this.firstTime)
        {
          if(GlobalUI.ManualLogin && GlobalUI.URLPage == null)
          {
            if(Object.keys(view.state.jobs).length > 0)
            {
              // Navigate to Dashboard page, but signal that this is not from UI
              GlobalUI.Main.onNavigate('Dashboard', false);
              this.switchPane('Current');
            }
          }
          this.firstTime = false;
        }
      }
    );
  }

  // update job history (only once at start and later when any job ends)
  // Calls fn if specified after update
  updateHistory(fn)
  {
    this.fnAfterHistoryFetch = fn;
    this.historyFetcher.fetch('POST', {count: 15});
  }

  // Fetch more jobs from history
  fetchMoreHistory = () =>
  {
    // See how many jobs are already loaded
    const nJobs = Object.keys(this.refs.viewJobsHistory.state.jobs).length;
    this.historyFetcher.fetch('POST', {count: 500, start: nJobs});
    this.setState({fetching: true});
  }

  // When this component is renderable, fetchUpdate to the active job data source
  componentDidMount()
  {
    this.updateJobs();
    this.updateHistory();
    window.setInterval
    (
      () =>
      {
        // Avoid making the ajax call unless were on the active/by label jobs page
        const sMainPane = SwitchedPane.getActivePane('DrawerCtx');
        const sDashboardPane = SwitchedPane.getActivePane('DashboardContent');
        if(sMainPane === 'Dashboard' && (sDashboardPane === 'Current' || sDashboardPane === 'By Label') )
        {
          JobsActiveFetcher.data = null;
          this.updateJobs();
        }
      },
      5000
    );
  }

  render()
  {
    // Init static styles
    if(!DashboardPane.styleFetchMore)
    {
      DashboardPane.styleFetchMore =
      {
        ...Styles.InlineFlexRow,
        width: '100%',
        justifyContent: 'center',
        marginBottom: LayoutDims.nMargin * 2,
        color: Colors.clrNimbixMed,
        cursor: 'pointer',
        fontSize: 18
      };
    }

    return MUI(

      <div ref='content-dashboard'>
        <SwitchedPane iconName='today' paneGroup='DashboardContent' paneName='Current'>
          <JobActiveView ref='viewJobsActive' headerText='Current' historyFetcher={this.historyFetcher}/>
        </SwitchedPane>

        <SwitchedPane iconName='history' paneGroup='DashboardContent' paneName='History'>
          <JobHistoryView ref='viewJobsHistory' headerText='History' allowEmpty/>

          <div onClick={this.fetchMoreHistory} style={DashboardPane.styleFetchMore}>
            {
              // TODO Move this conditional spinner into JobHistoryView to avoid viewJobsActive rerender
              this.state.fetching &&
                <Spinner style={{width: 16, marginRight: 16}} size={16} status=''/>
            }
            {
              this.state.fetching ? 'Fetching...' : 'Fetch more jobs'
            }
          </div>
        </SwitchedPane>

        <SwitchedPane iconName='label' paneGroup='DashboardContent' paneName='By Label'>
          <JobByLabelView ref='viewJobsActiveByLabel' headerText={'Active jobs by label'} />
        </SwitchedPane>

        <SwitchedPane iconName='assignment' paneGroup='DashboardContent' paneName='Reports'>
          <ReportView/>
        </SwitchedPane>

        {
          IsTeamAdmin() &&
          <SwitchedPane iconName='group' paneGroup='DashboardContent' paneName='Team Jobs'>
            <TeamJobs/>
          </SwitchedPane>
        }

      </div>
    );
  }
}
