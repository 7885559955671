// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';

// Our custom components
import {ColGroup, Icon, SearchBox, ReportDisplay} from './Components';
import {LayoutDims, Styles, Colors, Btns} from './UIConst'
import {DataFetcher} from '../Models/Model';

import {BusyHelper} from '../Global'
import {dateToLocalTimeStr} from './Utils';

// Get the filename part of a path
function getFileName(path)
{
  const parts = path.split('/');
  return parts[parts.length-1];
}

// Get the deepest folder name of a path (which has trailing /)
function getFolderName(path)
{
  const parts = path.split('/');
  return parts[parts.length-2];
}


// Get the parent directory
function getParentDir(path)
{
  // Add trailing / if not
  if(!path.endsWith('/'))
  {
    path += '/';
  }

  // Get all the parts, rejoin all but last 2
  const parts = path.split('/');
  return parts.slice(0, parts.length-2).join('/') + '/';
}


// Returns text with search highlighted - props : search, text, start, and highlight()
function HighLightText(props)
{
  // Get three sub-strings for the un-highlighted prefix, highlighted section and un-highlighted suffix
  const iStart = props.start;
  const iStop = Math.min(props.text.length, iStart + props.search.length);
  const sLeft = props.text.substr(0, iStart);
  const sMid = props.text.substr(iStart, iStop - iStart);
  const sRight = props.text.substr(iStop, props.text.length);

  // Call props.highlight to apply highlighting
  return (
    <div>
      {sLeft}
      {props.highlight(sMid)}
      {sRight}
    </div>
  );
}

// Highlight function adds bold
function doHighlight(s)
{
  return <span style={Styles.Highlight}>{s}</span>
}


export class VaultFileBrowser extends Component
{
  static propTypes =
  {
    onSelectFile: PropTypes.func,
    vault: PropTypes.string,
    filter: PropTypes.string
  }

  // Temporary cache to avoid re-fetching previously seen entries
  static cache = {};

  constructor(props)
  {
    super(props);

    this.state = {data: null, arrPathNavLinks: '', search: '', selectedIndex: -1, cursor: 0};

    // Set up the data fetcher for the vault
    this.vault = props.vault;
    this.filter = props.filter || '';
    this.path = '/';
    this.fetcher = new DataFetcher('/portal/vault-file-list/');

    this.fetcher.whenDone
    (
      () =>
      {
        // Reset the view - clear search
        this.doResetView();

        // Populate cache
        const sKey = this.path + ':' + this.filter;
        VaultFileBrowser.cache[sKey] = this.fetcher.data;

        this.setState({data: [...this.fetcher.data]});
        BusyHelper.Unbusy();
      }
    )
    .ifFail
    (
      ()=>
      {
        this.setState({err: 'Unable to list folder content', data: null});
        BusyHelper.Unbusy();
      }
    );
  }

  // On initial load, fetch the root dir
  componentDidMount()
  {
    // Cache is a map of "path:filter" -> listing, so that we never send the same request multiple times
    VaultFileBrowser.cache = {};
    this.doFetch();
  }

  // Handle props updation that happens when same control is
  // re-rendered with a different file filter
  componentWillReceiveProps(nextProps)
  {
    // If filter has changed from before, we need to refetch the data
    if(nextProps.filter !== this.props.filter)
    {
      this.filter = nextProps.filter;
      this.path = '/';
      this.doFetch();
    }
    else
    {
      window.setTimeout(() =>this.refs.searchBox.focus(), 300);
    }
  }

  // Initiates fetch for a given folder, with a spinner, if flushCache is specified, discards cached listing
  doFetch = (flushCache) =>
  {
    // Generate the element for the path navigation
    const pathParts = this.path.slice(0, this.path.length-1).split('/');

    let sPath = '';
    const arrPathNavLinks = pathParts.map
    (
      (e, i) =>
      {
        // Form the incremental paths like /a, /a/b, /a/b/c from the parts a,b,c
        sPath += e + '/';
        const thePath = sPath;

        // Each link navigates to the directory, except the last (which is the current dir)
        const handler = (i < pathParts.length - 1) ? {onClick: ()=>this.doSelectEntry(thePath)} : {};
        return (
          <div style={Styles.InlineFlexRow} key={thePath}>
            <div style={Styles.Link} {...handler}>
              {e}
            </div>
            <div style={{color: Colors.clrNimbixMed}} >/</div>
          </div>
        );
      }
    );

    // Set the states and fire the request for the directory list with a spinner
    this.setState({arrPathNavLinks});

    // Check the cache
    const sKey = this.path + ':' + this.filter;
    if(!flushCache && sKey in VaultFileBrowser.cache)
    {
      // Reset the view - clear search
      this.doResetView();

      // Update UI
      this.setState({data:[...VaultFileBrowser.cache[sKey]]});
    }
    else
    {
      // make a fresh request
      this.setState({data:[]});
      this.fetcher.params = {path: this.path, vault: this.vault, patterns: this.filter, details: this.showDetails};
      BusyHelper.BusyStatus('Fetching Directory List...');
      this.fetcher.fetch();
    }
  }

  // Selects a path
  doSelectEntry = (path) =>
  {
    if(path === '..')            // Handle '..' - Don't go above root dir
    {
      if(this.path !== '/')
      {
        this.path = getParentDir(this.path);
        this.doFetch();
      }
    }
    else if(path.endsWith('/'))  // Handle folder
    {
      this.path = path;
      this.doFetch();
    }
    else                        // Handle file - Call the select handler if any
    {
      if(this.props.onSelectFile)
      {
        this.props.onSelectFile(path);
      }
    }
  }

  // Resets the view after navigation to a new folder, clear search, scroll to top
  doResetView = () =>
  {
    this.onClearSearch();
    this.refs.searchBox.focus();
    this.setState({err: '', selectedIndex: 0, cursor: 0});
  }

  onSearch = search => this.setState({search});

  onClearSearch = () =>
  {
    this.refs.searchBox.inputField.value = '';
    //this.refs.searchBox.inputField.focus();
    this.setState({search: ''});
  }

  // Shows 3 columns instead of only one
  onCheckDetails = (e, v) =>
  {
    // If we are moving into details view, clear the cache, since we need to refetch the file list
    this.showDetails = v;
    if(v)
    {
      VaultFileBrowser.cache = {};
      this.doFetch();
    }

    // Dont reset the view unless there is no error message displayed
    if(!this.state.err)
    {
      this.doResetView();
    }
  }

  // Renders the icon and filename
  getFileNameField = (elemEntry, isFolder, path) =>
  {
    // folder or file icon
    const color = path === this.state.selectedIndex ? Colors.clrNimbixBlueGray : null;
    const icon = isFolder ?
                 Icon('folder', {color: color || Colors.clrNimbixLite}) :
                 Icon('description', {color: color || Colors.clrNimbixDark});

    return <div style={Styles.InlineFlexRow}>{icon}&nbsp;{elemEntry}</div>
  }

  // Click handler for files and folders
  onClickRow = (i) =>
  {
    this.setState({selectedIndex: i});
    let path = this.files[i].path;

    // Strip the @ we put in front of dirs
    if(path[0] === '@') path = path.substr(1)
    this.doSelectEntry(path);
  }

  // Keyboard handler - Up down moves cursor and Enter selects
  onKeyPressSearch = (e) =>
  {
    const setCursor = (cursor, isUp) =>
    {
      const fileView = this.refs.fileView;

      // If we hit the top, scroll the header into view
      if(cursor < 0)
      {
        fileView.scrollTop = 0;
      }
      else if(cursor < this.files.length)
      {
        const fileList = this.refs.fileList;
        const selectedIndex = fileList.arrIDX[cursor]
        this.setState
        (
          {selectedIndex, cursor},
          ()=>
          {
          }
        );

        // Check if item is outside viewport and scroll it in
        const viewport = this.refs.fileView.getBoundingClientRect();
        if(!fileList.rowVisible(cursor, viewport.top, viewport.bottom))
        {
          fileList.scrollRowIntoView(cursor, isUp);
        }
      }
    }

    let bStopEvent = true;

    if(e.key === 'ArrowUp')
    {
      setCursor(this.state.cursor - 1, true);
    }
    else if(e.key === 'ArrowDown')
    {
      setCursor(this.state.cursor + 1, false);
    }
    else if(e.key === 'Enter' && this.state.cursor >= 0)
    {
      // Do we have a '..' entry?
      const hasUp = this.path !== '/';

      // Handle first entry as '..'
      if(this.state.cursor === 0 && hasUp)
      {
        this.doSelectEntry('..');
      }
      else
      {
        // Get the (unsorted) index of the element from the cursor
        const selectedIndex = this.refs.fileList.arrIDX[this.state.cursor] - (hasUp ? 1 : 0);
        this.doSelectEntry(this.state.data[selectedIndex][0]);
      }
    }
    else if(e.key === 'Escape')
    {
      this.onClearSearch();
    }
    else
    {
      bStopEvent = false;
    }

    if(bStopEvent)
    {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  render()
  {
    this.files = [];
    let sMesg, i=0;

    // Add the ".." entry if needed
    const hasUp = this.path !== '/'
    if(hasUp)
    {
      this.files.push({id: i, name: this.getFileNameField('..', true, i), path: '@..'});
      i++;
    }

    // Generate the rows ( we use a table to allow extra fields like size and date in future)
    if(this.state.data && this.state.data.length)
    {
      for(const e of this.state.data)
      {
        // Get the entry name
        const name = e[0];
        const isFolder = name.endsWith('/');
        const sText = isFolder ? getFolderName(name) : getFileName(name);

        // Find the search string - if no search string, treat as found
        const bFilter = this.state.search;
        const iStart = bFilter ? sText.toLowerCase().indexOf(this.state.search.toLowerCase()) : 0;

        // Construct the search highlighted text if needed
        let elemEntry = bFilter && iStart >= 0
          ?
          <HighLightText text={sText} start={iStart} search={this.state.search} highlight={doHighlight}/>
          :
          <div>{sText}</div>;

        // Put in the icon
        elemEntry = this.getFileNameField(elemEntry, isFolder, i);

        if(!bFilter || iStart >= 0)
        {
          this.files.push
          (
            {
              id: i,
              name: elemEntry,
              size: <div style={{textAlign: 'right'}}>{isFolder ? '' : e[1].toLocaleString()}</div>,
              bytes: e[1],
              modified: <div style={{textAlign: 'right'}}>{dateToLocalTimeStr(e[2])}</div>,
              path: (isFolder ? '@' : '') + name
            }
          );
          i++;
        }
      }
    }

    // Show a message if no files, no search results or an error
    if(this.state.err || !this.state.data)
    {
      sMesg = this.state.err;
    }
    else if(this.state.data.length && this.state.search && this.files.length < (hasUp ? 2 : 1))
    {
      sMesg = 'No Matches...';
    }
    else if(!this.state.data.length)
    {
      sMesg = 'No Files...';
    }

    return (
      <div>

        <div >
          <div style={{fontSize: 22, color: Colors.clrNimbixDark, margin: LayoutDims.nMargin, marginTop: 0}}>
            <div style={Styles.Inline}>
              <div style={Styles.Link} onClick={()=>this.doSelectEntry('/')}>
                {this.vault}
              </div>
              {this.state.arrPathNavLinks}
            </div>
          </div>

          <div style={{margin: LayoutDims.nMargin}} >
            <div style={{ ...Styles.InlineFlexRow, width: '100%', justifyContent: 'space-between',}}>
              <SearchBox tabIndex='1' ref='searchBox' onKeyPress={this.onKeyPressSearch} onSearch={this.onSearch} onClear={this.onClearSearch} style={{flex:1}}/>
              <Checkbox onCheck={this.onCheckDetails} label='Show Details' style={{marginLeft: LayoutDims.nMargin * 2, whiteSpace: 'nowrap', width: 'auto'}} />
            </div>
          </div>

          <div ref='fileView'
               title={sMesg ? '' : 'Use Up/Down to highlight, Enter to Select'}
               style={{margin: LayoutDims.nMargin, height: 384, maxHeight: 384, overflow: 'auto', ...Styles.Bordered}}>

              <ReportDisplay ref='fileList'
                             data={this.files}
                             colFields={this.showDetails ? ['name', 'size', 'modified'] : ['name'/*, 'id'*/]}
                             colText={this.showDetails ? ['Name', 'Size', 'Modified'] : ['Name'/*, 'id'*/]}
                             colGroup={this.showDetails ? <ColGroup cols={[60, 15]} /> : null}
                             colTypes={{name: 'path', size: 'bytes'}}
                             onClickRow={this.onClickRow}
                             fixedRows={this.path === '/' ? 0 : 1}
                             selectedIndex={this.state.selectedIndex}
                             getExtraStyle={()=>({cursor: 'pointer'})}
                             styleTable={{border: 'none'}}
              />

              <p style={{textAlign: 'center'}}>
                {sMesg}
              </p>
          </div>

          <div style={{margin: LayoutDims.nMargin, marginTop: LayoutDims.nMargin * 2, marginBottom: 0}}>
            <div style={{...Styles.Inline, justifyContent:'flex-end'}}>
              &nbsp;
              <RaisedButton {...Btns.Green} label='Refresh' onClick={()=>this.doFetch(true)}/>
            </div>
          </div>
        </div>

        <div tabIndex='2' onFocus={()=>this.refs.searchBox.focus()} />

      </div>
    );
  }
}

