/*
 This file contains the code that renders the main application views
 */

import React from 'react';
import ReactDOM from 'react-dom';

import Checkbox from 'material-ui/Checkbox';

import {AppSearcher, ComputePane} from './Components/Compute';
import AppFilterPane from './Components/AppFilterPane';
import DockerLoginPane from './Components/DockerLoginPane';

import DashboardPane from './Components/Dashboard';
import JobDrawer from './Components/JobDrawer';

import SettingsPane from './Components/Settings';
import GenericDrawer from './Components/GenericDrawer';

import AppCard from './Components/AppCard';
import {AppEditDlg} from './Components/AdminApps';

import Main from './Components/Main';
import Admin from './Components/Admin';
import AboutPane from './Components/About';


import {SwitchedPane} from './Components/Components';

import {RenderSync, GlobalUI, StartHeartBeat, Elem, LogOut, LocalStorage, ShowMOTD} from './Global';
import {AppsRecentFetcher, VaultsDataFetcher, MachineDataFetcher} from './Models/Model';
import {Data, IsAdmin, IsTeamAdmin, HasTeam, HasRole, ROLE_BILLING_ANALYST, ROLE_SAML_ADMIN} from './Models/Model';
import {AppsDataFetcher, PTCAppsDataFetcher, AdminAppsDataFetcher} from './Models/AppsData';


// App search helper - links the app filter pane, and compute view + searchbox together
const AppSearcherObj = new AppSearcher(AppsDataFetcher);
const PTCAppSearcherObj = new AppSearcher(PTCAppsDataFetcher);

// This function is called after apps data arrives
const renderAppFilterPanes = (paneCompute, sElem, pSearcher, prefixPane=null) =>
{
  // Render the apps filtering UI inside the left drawer
  let paneFilter = RenderSync
  (
    <AppFilterPane ref={GlobalUI.release}  prefixPane={prefixPane} fetcher={pSearcher.fetcher}
                   {...pSearcher.paneFilterEvts.map}/>,
    Elem(sElem)
  );

  // Wire up the app searcher - it connects the above UI to the search box and app view
  pSearcher.connect(paneCompute, paneFilter);

  return paneFilter;
}

// Once the main container is rendered, we embed the context drawers in it
// only one drawer pane remains visible at a time (see SwitchedPane)
// We also render the content components in it, making sure they are created
// in the right order and linked to each other properly
export const postRenderMain = (main) =>
{
  const canSAML = IsTeamAdmin() && HasRole(ROLE_SAML_ADMIN);

  // Save the main window component so that AppSearcher etc can use it
  GlobalUI.Main = main;

  // Settings pane and drawer
  GlobalUI.Settings = RenderSync
  (
    <SettingsPane ref={GlobalUI.release} canSAML={canSAML}/>,
    Elem('Content/Settings')
  );

  // TODO: make this compatible with async render
  GlobalUI.SettingsDrawer = RenderSync
  (
    <GenericDrawer ref={GlobalUI.release} title='Settings' contentPane={GlobalUI.Settings} paneGroup='SettingsContent'/>,
    Elem('DrawerCtx/Settings')
  );

  if(IsAdmin() || HasRole(ROLE_BILLING_ANALYST))
  {
    GlobalUI.Admin = RenderSync(<Admin ref={GlobalUI.release}/>, Elem('Content/Admin'));
    GlobalUI.AdminDrawer = RenderSync
    (
      <GenericDrawer ref={GlobalUI.release} title='Admin' contentPane={GlobalUI.Admin} paneGroup='AdminContent'/>,
      Elem('DrawerCtx/Admin')
    );
  }

  // if the page is "compute" then apply the filter to the app searcher
  // renderAppFilterPanes() calls AppSearcherObj.connect() which will trigger a search
  // Filter could also be set via WSGI conf which puts app_filter in User profile data
  if(GlobalUI.URLPage === 'compute')
  {
    delete GlobalUI.URLParams['computedata'];
    AppSearcherObj.setFilter(GlobalUI.URLParams);
  }
  else if(Data.User.Profile.app_filter)
  {
    const filter = {};
    for(const param of Data.User.Profile.app_filter.split('&'))
    {
      const arrParam = param.split('=');
      filter[arrParam[0]] = decodeURIComponent(arrParam[1]);
    }

    AppSearcherObj.setFilter(filter);
  }

  // Function that handles common code for Apps and PTCApps
  const initAppsPane = (pane, fetcher, searcherObj, sName, prefixPane=null) =>
  {
    // TODO: make this compatible with async render
    renderAppFilterPanes(pane, 'DrawerCtx/' + sName, searcherObj, prefixPane);

    // Tell switched pane to inform us when switched to this view
    // Force re-search when this pane is clicked
    // Not strictly necessary, but it fixes the issue of the collapsible view rendering wrong
    // TODO find a better way
    SwitchedPane.RegisterOnSwitch('Content/' + sName, searcherObj.doSearch);

    // Add the apps re-fetch handler, also call it once right now
    const whenAppsDone = () => AppsRecentFetcher.fetchUpdate(main.refs.paneRecent, 'arrApps');
    fetcher.whenDone(whenAppsDone);
    whenAppsDone();
  };

  // Compute pane
  let paneCompute = RenderSync(<ComputePane ref={GlobalUI.release}  entryType='' searcher={AppSearcherObj}/>, Elem('Content/Compute'));
  initAppsPane(paneCompute, AppsDataFetcher, AppSearcherObj, 'Compute');

  // Render PTC stuff only if developer
  if(Data.User.Profile.developer)
  {
    let panePTCompute = RenderSync
      (
      <ComputePane ref={GlobalUI.release} entryType='ptc' searcher={PTCAppSearcherObj}/>,
        Elem('Content/PushToCompute')
      );
    initAppsPane(panePTCompute, PTCAppsDataFetcher, PTCAppSearcherObj, 'PushToCompute',
      <DockerLoginPane/>)
  }

  // Render Dashboard pane content (contains active jobs view, and history) and drawer
  GlobalUI.Dashboard = RenderSync(<DashboardPane ref={GlobalUI.release}/>, Elem('Content/Dashboard'));
  GlobalUI.JobDrawer = RenderSync(<JobDrawer ref={GlobalUI.release} />, Elem('DrawerCtx/Dashboard'));


  // About page
  GlobalUI.About = RenderSync(<AboutPane ref={GlobalUI.release}/>, Elem('Content/About'));

  // App card / task builder
  GlobalUI.PaneAppCard = RenderSync(<AppCard ref={GlobalUI.release}/>, Elem('AppCard'));
  GlobalUI.PaneAppEditDlg = RenderSync(<AppEditDlg ref={GlobalUI.release}/>, Elem('AppEditDlg'));
}


// Handle machine data update (Data.Machines)
const processMachineData = () =>
{
  // Save the names of machines sorted by price
  Data.MachinesByPrice = Object.keys(Data.Machines);
  Data.MachinesByPrice.sort
  (
    (a, b) =>
    {
      const ma = Data.Machines[a];
      const mb = Data.Machines[b];
      if(ma.mc_price > mb.mc_price) return 1;
      if(ma.mc_price < mb.mc_price) return -1;
      return 0;
    }
  );
}


// Common code that initializes apps and PTC apps
const initAppFetcher = (fetcher, arrApps) =>
{
  // Set the machine info and MachinesByPrice into the apps fetcher
  // so the "from" price can be calculated for each app in AppsDataFetcher
  // Then set the apps data and call processData()
  fetcher.machines = Data.Machines;
  fetcher.machinesByPrice = Data.MachinesByPrice;
  fetcher.responseData = arrApps;
  fetcher.processData();

  // Set the zone for this fetcher so refreshes get the proper apps
  fetcher.setZone(Data.CurrentZone);
}


// Set the apps data received on login (Data.Apps)
const initAppData = () =>
{
  initAppFetcher(AppsDataFetcher, Data.Apps);

  // Init PTC apps data if developer
  if(Data.User.Profile.developer)
  {
    initAppFetcher(PTCAppsDataFetcher, Data.PTCApps);
  }
    
  // Fetch the admin apps if needed
  if(IsAdmin())
  {
    AdminAppsDataFetcher.machines = Data.Machines;
    AdminAppsDataFetcher.machinesByPrice = Data.MachinesByPrice;
    AdminAppsDataFetcher.fetch();
  }
}


// Does all the work of rendering the main UI
const doRenderMain = (fauxLogin) =>
{
  // Set the handler for when vaults data is re-fetched
  // Initial login data already contains the info per each vault
  VaultsDataFetcher.whenDone
  (
    () => Data.Vaults.VaultInfo = {...VaultsDataFetcher.data.result}
  );

  // Set the handler for when machines data is re-fetched
  MachineDataFetcher.whenDone
  (
    ()=>
    {
      // Save machine data and process
      Data.Machines = MachineDataFetcher.data;
      processMachineData();
    }
  );

  // Add the zone parameter to MachineDataFetcher so that
  // Data.Machines lists only machines within current zone
  MachineDataFetcher.addParams({zone: Data.User.CurrentZone})

  // Call the same handler (since we have the machine data on login)
  processMachineData();

  // The complete list of machines is fetched by AdminMachinesFetcher into Data.MachinesAll
  // its success handler is in AdminMachines.js, no need to do anything here

  // Set the initial apps data into AppsDataFetcher
  initAppData();

  // Render the main app component
  // Other components are rendered once that is done in postRenderMain()
  const main = RenderSync(<Main ref={GlobalUI.release} postRender={()=>{}}/>, Elem('root'));
  Elem('root').onclick = () => { GlobalUI.Active = true; }

  if(!fauxLogin && Data.MOTD && !IsAdmin())
  {
    ShowMOTD();
  }

  postRenderMain(main);

  // Start the heartbeat timer
  StartHeartBeat();
}

// Called after login success
export const renderMain = (fauxLogin) =>
{
  // If versionUpdated flag is true here, the page is reloading, so exit
  const versionUpdated = LocalStorage.get('versionUpdated', '*');
  if(versionUpdated) return;

  // Check if this is an admin impersonating and clear that flag
  const bImpersonated = LocalStorage.get('Impersonated', '*');
  LocalStorage.del('Impersonated', '*');

  // Calculation of what panes are visible or not - key is pane name, value is a function
  GlobalUI.Visibility =
    {
      'SettingsContent/Team': IsTeamAdmin,
      'SettingsContent/Team Log': IsTeamAdmin,
      'SettingsContent/Team Apps': IsTeamAdmin,
      'SettingsContent/Identity': IsTeamAdmin,
      'SettingsContent/Limits': IsTeamAdmin,

      'SettingsContent/Summary': ()=>IsTeamAdmin() || Data.User.Profile.team_jobs_public,
      'SettingsContent/SAML2 IdP': ()=>IsTeamAdmin() && HasRole(ROLE_SAML_ADMIN),
      'SettingsContent/LDAP': ()=>IsTeamAdmin() && HasRole(ROLE_SAML_ADMIN),
      'SettingsContent/Projects': IsTeamAdmin,


      'DrawerCtx/Admin': () => HasRole(ROLE_BILLING_ANALYST),
      'Content/Admin': () => HasRole(ROLE_BILLING_ANALYST),

      'DrawerCtx/PushToCompute': ()=>Data.User.Profile.developer,
      'Content/PushToCompute': ()=>Data.User.Profile.developer,

      'AdminContent/Users': IsAdmin,
      'AdminContent/Jobs': IsAdmin,
      'AdminContent/Apps': IsAdmin,
      'AdminContent/Clusters': IsAdmin,
      'AdminContent/Machines': IsAdmin,
      'AdminContent/Nodes': IsAdmin,
      'AdminContent/Logs': IsAdmin,
      'AdminContent/Limits': IsAdmin,

      'AdminContent/Stats': ()=>HasRole(ROLE_BILLING_ANALYST),
      'AdminContent/Billing': ()=>HasRole(ROLE_BILLING_ANALYST),
      'AdminContent/Discounts': ()=>HasRole(ROLE_BILLING_ANALYST),
      'AdminContent/Users By Zone': ()=>HasRole(ROLE_BILLING_ANALYST),
    }

  GlobalUI.IsVisible = (panePath) => !GlobalUI.Visibility[panePath] || GlobalUI.Visibility[panePath]();

  //console.log('App Init');
  //console.log(JSON.parse(localStorage.settings)[Data.User.Profile.user_login]);

  // Get rid of any "recent" panes data stored in the local storage that are not available for this user
  // Go though the local storage keys for this user
  const arrKeys = LocalStorage.keys();
  for(const key of arrKeys)
  {
    // Make the key: value in LocalStorage into "key/value"
    const sView = `${key}/${LocalStorage.get(key)}`;

    // Check if that string is in GlobalUI.Visibility and if so, delete if that pane is unavailable
    if(sView in GlobalUI.Visibility && !GlobalUI.IsVisible(sView))
    {
      LocalStorage.del(key);
    }
  }

  // If our team has impersonation enabled, and this is not an impersonation session or the payer
  // then warn the user
  Data.Config.ImpersonationDialog = !fauxLogin && Data.User.Profile.payer &&
                                       Data.User.Identity.impersonation && !bImpersonated &&
                                       !LocalStorage.get('CheckBoxNeverAskImpersonateConfirm');

  if(Data.Config.ImpersonationDialog)
  {
    ReactDOM.unmountComponentAtNode(Elem('root'));

    GlobalUI.DialogConfirm.confirm
    (
      <div>
        <p>
          Your team administrator enabled impersonation of user accounts for this team.<br/>
          This means that at any time, team administrator(s) may run jobs, inspect data,
          and review job history on your behalf with or without notice.<br/><br/>
          <b>If you do not agree with this policy, please click "Decline" and contact your
            team administrator.</b>
        </p>
        <br/>
        <Checkbox label='Do not show this warning on this client again'
                  onCheck={(e, v)=>LocalStorage.set('CheckBoxNeverAskImpersonateConfirm', v)}/>
      </div>,
      'Confirm Impersonation Access',
      () => doRenderMain(fauxLogin),
      () =>
      {
        // If declined, make sure the question will be asked again
        LocalStorage.set('CheckBoxNeverAskImpersonateConfirm', false);
        LogOut('User declined impersonation policy');
      },
      'Accept',
      'Decline',
      '50%',
      true
    );
  }
  else
  {
    doRenderMain(fauxLogin);
  }
}

