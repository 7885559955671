// Core
import React, { Component } from 'react';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';


// Our custom components
import {MUI, ModalView, ModalTitle, Separator, HLine, ReportDisplay} from './Components';

// Our layout, styles and colors
import {Styles, Colors, LayoutDims, Btns} from './UIConst';
import {GlobalUI} from '../Global'

export class SamlTest extends Component
{
  constructor(props)
  {
    super(props);
    this.state = JSON.parse(atob(props.params));
  }

  componentDidMount()
  {
    GlobalUI.getMetrics();
    this.setState({width: LayoutDims.wContent})
  }

  onGetXML = () =>
  {
    window.open('/portal/saml-get-test-response-xml?id=' + this.state.id, '_blank');
  }

  render()
  {
    const hasErr = this.state.errors.length > 0;

    // Attributes is a dict of key to array of vals
    const arrElemAttrs = [];
    for(const key of Object.keys(this.state.attributes))
    {
      const val = this.state.attributes[key].join(',');
      let attr = key.split('/');
      attr = attr[attr.length-1];
      arrElemAttrs.push({key: attr, val});
    }

    const hasAttr = arrElemAttrs.length > 0;

    return MUI(
      <ModalView width={this.state.width} open onClose={window.close} >
        <Paper zDepth={3} style={Styles.ModalPaper}>
          <div>
            <ModalTitle scale={0.4} fontSize={18}
                        title={hasErr ? 'SAML Config Test Failed' : 'SAML Config Test Succeeded'}/>
            {
              hasErr
              ?
                <div>
                  The SAML response for the specified IdP configuration seems to be invalid.
                  <br/><br/>
                  Error:
                  <div style={{fontFamily: 'monospace', padding: 4, margin: 0, ...Styles.Bordered,
                    backgroundColor: Colors.clrNimbixGray, minHeight: 64}} >
                    {
                      this.state.reason
                    }
                  </div>

                  <Separator units={2}/>
                  <HLine margin={0}/>
                  <Separator units={2}/>

                  <b>Please contact an administrator to resolve this issue</b>
                  <br/>
                </div>
              :
                <div>
                  Authenticated User EntityID: <b>{this.state.user}</b>
                  <br/>
                  <br/>
                </div>
            }

            {
              hasAttr &&
              <div>
                <Separator/>
                <b>SAML Attributes:</b>

                <div style={{overflowX: 'auto', overflowY: 'auto', maxHeight: 400}}>
                  <ReportDisplay ref='attrList'
                                 data={arrElemAttrs}
                                 colFields={['key', 'val']}
                                 colText={['Attribute', 'Value(s)']}/>
                  <Separator/>
                </div>

                <Separator/>
              </div>
            }

            <Separator units={2}/>
            <RaisedButton {...Btns.Green} label='View SAML Assertion XML' onClick={this.onGetXML}/>

          </div>

        </Paper>

      </ModalView>
    );
  }
}

