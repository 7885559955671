// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import DatePicker from 'material-ui/DatePicker';

// Our stuff
import {Styles} from './UIConst';
import {DatePickerProps} from '../Global';

// Shows radio buttons for current last, last month and custom date range
// If custom date range specified, start and end date pickers are shown
// Used by billing reports UI for Admin/Billing as well as DashBoard/Reports
export class BillingDateChooser extends Component
{
  static propTypes =
    {
      // Click handler which gets the string
      onChangeTimePeriod: PropTypes.func,
    };

  constructor(props)
  {
    super(props);
    this.state =
      {
        timePeriod: 'current',
        startDate: new Date(),
        endDate: new Date(),
      };

    this.state.startDate.setDate(this.state.startDate.getDate() - 90);
  }

  getParams = () =>
  {
    const params =
      {
        timeperiod: this.state.timePeriod,
      }

    // Add date range if any
    if(params.timeperiod === 'range')
    {
      params.startdate = this.state.startDate.toISOString().substring(0, 10);
      params.enddate = this.state.endDate.toISOString().substring(0, 10);
    }

    return params;
  }

  // When time period radio is changed
  onChangeTimePeriod = (e, timePeriod) =>
  {
    this.setState({timePeriod});
    if(this.props.onChangeTimePeriod)
    {
      this.props.onChangeTimePeriod();
    }
  }

  render()
  {
    return (
      <div>
        <RadioButtonGroup onChange={this.onChangeTimePeriod} ref="timePeriod" name="timePeriod" defaultSelected="current">
          <RadioButton style={Styles.Radio} value="current" label="Current Month"/>
          <RadioButton style={Styles.Radio} value="last"    label="Previous Month"/>
          <RadioButton style={Styles.Radio} value="range"   label="Custom Date Range"/>
        </RadioButtonGroup>

        {
          this.state.timePeriod === 'range' &&
          <div style={{...Styles.Inline}}>
            <DatePicker {...DatePickerProps}
                        onChange={(e, startDate)=>this.setState({startDate})}
                        ref='startDate'
                        hintText="Start date"
                        maxDate={this.state.endDate}
                        defaultDate={this.state.startDate}
            />

            &nbsp;&nbsp;to&nbsp;&nbsp;

            <DatePicker {...DatePickerProps}
                        onChange={(e, endDate)=>this.setState({endDate})}
                        ref='endDate'
                        hintText="End date"
                        minDate={this.state.startDate}
                        defaultDate={this.state.endDate}
            />
          </div>
        }
      </div>
    );
  }
}

