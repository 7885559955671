// Core
import React, {Component} from 'react';

import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';

// Our custom controls
import {SectionViewHeader, HLine, ReportDisplay, RawDataDownloader} from './Components.js';
import {LayoutDims, Colors, clrTrans} from './UIConst.js';
import {Data, DataFetcher} from '../Models/Model';
import {FetchBusy} from '../Global'

import {BillingDateChooser} from './BillingDateChooser.js';


export class ReportView extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      dataReport: null,
      itemized: false,
    };

    // Set to true after a report is shown first time
    // Reset after the report is scrolled into view
    this.reportRefreshed = false;
  }

  generateReport = (itemized=false, download=false) =>
  {
    // Set up the request params
    const params =
    {
      user: Data.User.Profile.user_login,
      itemized,
      ...this.refs.dateChooser.getParams()
    }

    // Do we want a CSV download?
    if(download)
    {
      params.csv = 1;
    }

    // admin-billing can be called by non admins for themselves
    let url = `/portal/admin-billing${itemized ? '-itemized' : ''}-report`;
    const fetcher = new DataFetcher(url, params);

    // If report to be displayed
    if(!download)
    {
      this.setState({dataReport: null});
      fetcher.whenDone
      (
        () =>
        {
          this.reportRefreshed = true;
          this.setState({dataReport: fetcher.data});
        }
      );

      FetchBusy(fetcher, 'Generating report...');
    }
    else // else download CSV report
    {
      fetcher.whenDone(()=>this.refs.downloader.save(fetcher.data));
      FetchBusy(fetcher, 'Downloading CSV...');
    }
  }

  // When time period radio is changed
  onChangeTimePeriod = () =>
  {
    this.setState({dataReport: null});
  }

  render()
  {
    const showReport = this.state.dataReport;
    const itemized = this.state.itemized;

    // When report is visible for first time, scroll it into view
    if(showReport)
    {
      window.setTimeout
      (
        ()=>
        {
          if(this.refs.reportView && this.reportRefreshed)
          {
            this.reportRefreshed = false;
            this.refs.reportView.scrollIntoView();
          }
        },
        300
      );
    }

    let arrCol, arrColText, arrSummary, dctColTypes = {};

    if(this.state.dataReport)
    {
      const data = this.state.dataReport;
      if(this.state.itemized)
      {
        arrCol = ['datestr', 'job_number', 'job_owner_username', 'email',
          'job_application', 'job_label', 'job_project', 'machine', 'compute_time', 'compute_cost']

        arrColText = ['Start' ,'Job No.' ,'User' ,'Email' ,'App' ,'Job Label', 'Project',
        'Machine' ,'Compute Time', 'Cost'];

        arrSummary = ['Totals', '', '', '', '', '', '', '', data.params.compute_time, data.params.compute_cost];

        // Tell ReportView to sort datestr using job_start_time
        dctColTypes['datestr'] = 'job_start_time';
      }
      else
      {
        arrCol = ['user', 'email', 'job_count', 'compute_time', 'compute_cost'];
        arrColText = ['Username', 'Email', 'Job Count', 'Compute Time', 'Cost'];
        arrSummary = ['Totals', '', data.totaljobs, data.totaltime, data.totalcost];

        // Tell ReportView to sort job_count as a number
        dctColTypes['job_count'] = 'number';
      }

      // Tell ReportView to sort compute_cost as a number
      dctColTypes['compute_cost'] = 'number';

      // Tell ReportView to sort compute_time after padding to 12 chars
      dctColTypes['compute_time'] = 12;
    }

    return (
      <div>
        <SectionViewHeader headerText='Reports'/>

        <div style={{padding: LayoutDims.nMargin * 2}}>
          <br/>

          <BillingDateChooser ref='dateChooser' onChangeTimePeriod={this.onChangeTimePeriod}/>
          <br/>

          <Checkbox ref='checkItemized' label='Itemized'
                    onCheck={(e, v)=>this.setState({itemized: v, dataReport: null})}/>

          <br/>
          <RaisedButton label='Generate' onClick={()=>this.generateReport(this.state.itemized)}/>

          {
            showReport &&
            <div>

              <br/> <br/>
              <HLine margin={2} color={Colors.clrNimbixDark}/>
              <br/> <br/>

              <div ref='reportView'>
                <RaisedButton onClick={()=>this.generateReport(itemized, true)} label='Download as CSV'/>
                <br/> <br/> <br/>

                <ReportDisplay key='report' data={this.state.dataReport.rows} summary={arrSummary}
                               colFields={arrCol} colText={arrColText} colTypes={dctColTypes}
                               colID={this.state.itemized ? 'job_number' : 'user'}
                />
              </div>
            </div>
          }

        </div>
        <HLine thickness={2} color={clrTrans(Colors.clrNimbixDark, 0.05)}/>

        <RawDataDownloader name='dashboard_billing'
                           filename={itemized ? 'report_itemized.csv': 'report.csv'}
                           ref='downloader'/>

      </div>

    );
  }
}

