/* eslint-disable default-case */
import React, {Component} from 'react';

import {Separator, ShowOnly, ReportDisplay, InputRow, ColGroup} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher, Data} from '../Models/Model';
import {RaisedButton} from 'material-ui';


// Simple foolproof deep copy
const deepCopy = (v) =>
{
  return JSON.parse(JSON.stringify(v));
}

const getFirstKey = (dct) =>
{
  const keys = dct && Object.keys(dct);
  return (keys && keys.length) ? keys[0] : null;
}

// Converts the feature/project data in a form palatable to ReportDisplay
const dictToReport = (data) =>
{
  // Dump data as key and values
  return Object.keys(data).map((key, id) => ({id, key, value: data[key]}));
}

// Converts the ReportDisplay data format to a dict, trimming the keys and values
const reportToDict = (arrData) =>
{
  const dct = {};
  for(const row of arrData)
  {
    dct[row.key.trim()] = typeof row.value === 'string' ? row.value.trim() : row.value;
  }

  return dct;
}

// Gets the numeric value of a string iff its non negative int > 0
const parseNonZeroPositiveInt = (n) =>
{
  const v = n|0;
  // Coerced != is necessary below
  // eslint-disable-next-line eqeqeq
  return (!n || v != n || v < 1) ? null : v;
}

// Validates project priority format string names, and returns false if error
// If valid, returns the parsed string with trimmed values or as a array of arrays
const parseProjectPriorityStr = (s, asDict) =>
{
  let dctData = {};
  let nTotalAlloc = 0;
  let arrRet = [];

  // Accept a blank/null string as valid
  if(s)
  {
    try
    {
      // Split by comma to p:a:b
      const arrProjs = s.split(',');
      for(const entry of arrProjs)
      {
        // Trim entry and split project and priority value and trim them
        const arrEntry = entry.trim().split(':');
        const project = arrEntry[0].trim();
        let priority = arrEntry[1].trim();
        let allocation = arrEntry[2].trim();

        // Check if priority is a positive number
        priority = parseNonZeroPositiveInt(priority)
        if(priority === null)
        {
          console.log('parseNonZeroPositiveInt(priority) failed ' + priority);
          return false;
        }

        // Convert allocation to int, check range
        allocation = allocation|0;
        if(allocation > 100 || allocation < 0)
        {
          console.log('Allocation is not a percentage' + allocation);
          return false;
        }

        // Sum to check for total <= 100
        nTotalAlloc += allocation;

       // Build up the output string
        arrRet.push(`${project}:${priority}:${allocation}`);
        dctData[project] = {priority, allocation};
      }
    }
    catch(e)
    {
      console.log('parseProjectPriorityStr exception:')
      console.log(s)
      console.log(e)
      return false;
    }
  }
  
  if(nTotalAlloc > 100)
  {
    console.log('nTotalAlloc > 100 failed ' + nTotalAlloc);
    return false;
  }

  return asDict ? dctData : arrRet.join(',');
}

class KeyValEdit extends Component
{
  constructor(props)
  {
    super(props);
    this.state={keyName: props.keyName || '', value: props.value || '', hint: this.props.hint, options: this.props.options || {}}
  }

  componentDidMount()
  {
    this.setState({error: ''});
    const elem = this.refs[this.props.readOnly ? 'inputVal' : 'inputKey'];
    setTimeout(()=>elem.focus(), 200);
  }

  makeInput = (sTag, sKey, readOnly=false) =>
  {
    return (
      <input data-cy={sTag} ref={sTag}
             style={Styles.ParamInputWide}
             readOnly={readOnly}
             value={this.state[sKey]}
             placeholder={this.state.hint}
             list={this.state.options[this.state.keyName]}
             onChange={e=>this.setState({[sKey]: e.target.value, error: ''})}/>
    );
  }

  onSave = () =>
  {
    if(this.props.validators && this.props.validators[this.state.keyName])
    {
      const sErr = this.props.validators[this.state.keyName](this.state.value);
      if(sErr)
      {
        this.setState({error: sErr});
        setTimeout(()=>this.refs.inputVal.focus(), 200);
        return;
      }
    }

    this.props.onSave(this.state.keyName, this.state.value);
   }

  render()
  {
    return (
      <div>
        <InputRow title={this.props.keyTitle} width='100%'>
          {this.makeInput('inputKey', 'keyName', this.props.readOnly)}
        </InputRow>

        <InputRow title={this.props.valTitle} width='100%'>
          {this.makeInput('inputVal', 'value')}
        </InputRow>

        <span className='errortext' style={{fontSize: LayoutDims.TailFontSize}}>
          {this.state.error}&nbsp;
        </span>

        <Separator/>

        <div style={Styles.FlexSpread}>
          <RaisedButton label='Cancel' onClick={this.props.onClose}/>
          &nbsp;
          <RaisedButton {...Btns.Blue}
                        disabled={!(this.state.keyName && this.state.value)}
                        label='Save'
                        onClick={this.onSave}
          />
        </div>

      </div>
    );
  }
}

// Generic component to add/edit/delete a JSON of form
// { key: { subKey: val1, subKey: val2, ...} }
class AdminLicenseEdit extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props, error: ''};
  }

  // When reopened reset state to new props
  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps, error: ''});
  }

  componentDidMount()
  {
    if(this.props.action === 'Add')
    {
      setTimeout(()=>this.refs.inputLicenseEditName.focus(), 200);
    }
  }

  doAddEdit = bEdit =>
  {
    // Grab selected values for edit
    let key, value, id, hint;
    if(bEdit)
    {
      ({key, value, id} = this.state.data[this.state.selectedIndex]);
    }

    hint = this.state.hints[this.state.selectedIndex];

    const onSave = (k, v) =>
    {
      // Either append or replace
      const data = [...this.state.data]
      id = bEdit ? id : this.state.data.length;
      data[id] = {id, key: k, value: v};
      this.setState({data});
      GlobalUI.DialogConfirm.onClose(true);
    }

    GlobalUI.DialogConfirm.show
    (
      `${bEdit ? 'Edit' : 'Add'} Property`,
      <KeyValEdit
        key={id}
        keyTitle={this.props.colText[0]}
        valTitle={this.props.colText[1]}
        readOnly={bEdit}
        keyName={key}
        value={value}
        hint={hint}
        options={this.props.options}
        validators={this.props.validators}
        onSave={onSave}
        onClose={GlobalUI.DialogConfirm.onClose}
      />,
      false,
      LayoutDims.wContent * 0.8
    );

  }

  onAdd = () => this.doAddEdit(false)
  onEdit = () => this.doAddEdit(true)

  onDel = () =>
  {
    const data = this.state.data.filter(row=>row.id !== this.state.selectedIndex);
    this.setState({data, selectedIndex: -1});
  }

  onSave = () =>
  {
    // Convert the data format
    const data = reportToDict(this.state.data);
    this.props.onSave(this.state.kind, data, this.state.entry, this.props.entry, this.props.action);
  }

  // Edit on double click
  onDoubleClickRow = (id) =>
  {
    this.setState({selected: id}, this.onEdit)
  }

  render()
  {
    const isSelected = this.state.selectedIndex >= 0;
    const arrHidden = this.props.hiddenFields || [];
    const data = [...this.state.data].filter(e => arrHidden.indexOf(e.key) < 0);

    return (
      <div>
        <InputRow title={this.state.keyTitle + ':'} width='100%'>
          <input data-cy='inputLicenseEditName'
                 ref='inputLicenseEditName'
                 style={{...Styles.ParamInputWide}}
                 value={this.state.entry}
                 list={this.props.datalist}
                 onChange={e=>this.setState({entry: e.target.value})}/>
        </InputRow>

        <div style={{...Styles.FlexSpread, alignItems: 'flex-end'}}>
          Properties:

          <div style={{...Styles.InlineFlexRow, justifyContent: 'flex-end'}}>
            {this.props.noAdd ? null : <> <RaisedButton onClick={this.onAdd} {...Btns.Green} label='Add'/> &nbsp;&nbsp; </>}
            <> <RaisedButton onClick={this.onEdit} disabled={!isSelected} {...Btns.Blue} label='Edit'/> &nbsp;&nbsp; </>
            {this.props.noAdd ? null : <> <RaisedButton onClick={this.onDel} disabled={!isSelected} {...Btns.DarkRed} label='Delete'/> &nbsp;&nbsp; </>}
          </div>
        </div>

        <Separator/>
        <ReportDisplay data={data}
                       selectedIndex={this.state.selectedIndex}
                       colFields={['key', 'value']}
                       colText={this.props.colText}
                       colGroup={<ColGroup cols={[20]} />}
                       onClickCell={this.onClickCell}
                       onClickRow={i=>this.setState({selectedIndex: i})}
                       onDoubleClickRow={this.onDoubleClickRow}
        />

        <Separator/>
        <div style={Styles.FlexSpread}>
          <RaisedButton label='Cancel' onClick={this.props.onClose}/>
          &nbsp;
          <RaisedButton {...Btns.Blue}
                        disabled={!this.state.entry}
                        label='Save'
                        onClick={this.onSave}
          />
        </div>

      </div>
    );
  }
}

class PFeatureEdit extends Component
{
  constructor(props)
  {
    // Keep only updatable data in state
    super(props);
    this.state = {project: this.props.project || '', priority: this.props.priority || 1, allocation: this.props.allocation || 1};
  }

  makeInput = (sKey, extra) =>
  {
    return (
    <InputRow title={sKey[0].toUpperCase() + sKey.substr(1)} width='100%'>
      <input data-cy={`inputPFeature${sKey}`}
             style={{...Styles.ParamInput, width: '95%'}}
             value={this.state[sKey]}
             onChange={e=>this.setState({[sKey]: e.target.value})}
             {...extra}
      />
    </InputRow>
    );
  }

  // Call the parent to save the changed values
  onSave = () =>
  {
    this.props.onSave(this.state);
  }

  render()
  {
    return (
      <div>
        {this.makeInput('project', {list: 'projectNames'})}
        {this.makeInput('priority', {type: 'number', min: 1, max: 999})}
        {this.makeInput('allocation', {type: 'number', min: 1, max: 100})}

        <Separator/>
        <div style={Styles.FlexSpread}>
          <RaisedButton label='Cancel' onClick={GlobalUI.DialogConfirm.onClose}/>
          &nbsp;
          <RaisedButton {...Btns.Blue} disabled={!this.state.project} label='Save' onClick={this.onSave}/>
        </div>
      </div>
    );
  }
}

class PFeatureProjects extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {data: this.makeData(props.data)};
  }

  componentWillReceiveProps(nextProps) {
    this.setState({data: this.makeData(nextProps.data), selectedIndex: -1})
  }

  // Convert projects string to a report dict
  makeData = (s) =>
  {
    const sData = parseProjectPriorityStr(s);
    return (
      sData
      ?
        s.split(',').map
        (
          (e, id) =>
          {
            const arrFields = e.split(':');
            return {id, project: arrFields[0], priority: arrFields[1], allocation: arrFields[2]}
          }
        )
      :
        []
    );
  }

  doAddEdit = bEdit =>
  {
    // Grab selected values for edit
    const data = bEdit ? this.state.data[this.state.selectedIndex] : null;
    GlobalUI.DialogConfirm.show
    (
      `${bEdit ? 'Edit' : 'Add'} Project Configuration`,
      <PFeatureEdit {...data} onSave={this.props.onSave}/>,
      false,
      LayoutDims.wContent * 0.6
    );
  }

  onAdd = () => this.doAddEdit(false)
  onEdit = () => this.doAddEdit(true)

  onDelete = () =>
  {
    const data = this.state.data[this.state.selectedIndex];
    GlobalUI.Dialog.confirm
    (
      `Are you sure you want to delete the entry for project '${data.project}'?`,
      `Confirm Delete`,
      () => this.props.onSave(data, 'delete')
    );
  }


  render()
  {
    const isSelected = this.state.selectedIndex >= 0;

    return (
    <>
      <Separator/>
      <div style={{...Styles.FlexSpread, alignItems: 'end'}}>
        <div style={{flex: 3}}>
          <b style={{color: Colors.clrNimbixDark}}>Project config:</b>
        </div>

        <div style={{...Styles.InlineFlexRow, flex: 1, justifyContent: 'flex-end'}}>
          <RaisedButton {...Btns.Green} label='Add' onClick={this.onAdd}/>
          &nbsp;&nbsp;
          <RaisedButton {...Btns.Blue} disabled={!isSelected} label='Edit' onClick={this.onEdit}/>
          &nbsp;&nbsp;
          <RaisedButton {...Btns.DarkRed} disabled={!isSelected} label='Delete' onClick={this.onDelete}/>
        </div>
      </div>

      <Separator/>

      <ReportDisplay data={this.state.data}
                     colFields={['project', 'priority', 'allocation']}
                     colText={['Project', 'Priority', 'Minimum Allocation %']}
                     colGroup={<ColGroup cols={[50]} />}
                     selectedIndex={this.state.selectedIndex}
                     onClickRow={i=>this.setState({selectedIndex: i})}
      />

      {
        this.state.data.length === 0
        ?
          <div style={{margin: 0, padding: LayoutDims.nMargin, ...Styles.Bordered}}>None</div>
        :
          null
      }

    </>
    )
  }
}

export default class AdminLicenses extends Component
{
  static dctColTexts =
  {
    server: ['Key', 'Value'],
    feature: ['Feature', 'Allocation'],
    project: ['Feature', 'Maximum Reservation'],
    pfeature: ['Key', 'Value']
  };

  static arrServerMandatoryFields = ['config', 'binary', 'port'];
  static arrServerMandatoryFieldHints = ['e.g. LICENSE_SERVER_IP=10.0.0.128', 'e.g. s3:///app/lmutil', '', 'lmstat timeout in seconds, 0 for default'];

  static arrPfeatureMandatoryFields = ['feature', 'default_priority', 'signal'];
  static arrPfeatureMandatoryFieldsHints = ['Name of an existing feature', 'Lower number is higher priority', 'One of SIGTSTP, SIGSTOP, SIGTERM or SIGKILL',
                                            'Comma separated list with items of format payer-proj:priority:allocation'];

  static dctSignalNums = {SIGTSTP: 20, SIGSTOP: 19, SIGTERM: 15, SIGKILL: 9}

  constructor(props)
  {
    super(props);
    this.state = {data: null, error: null, server: null, feature: null, pfeature: null};

    this.fetcher = new DataFetcher('/portal/admin-license-get');
    this.fetcher.whenDone
    (
      ()=>
      {
        const sErr = this.validateData(this.fetcher.data.result);
        if(sErr === null)
        {
          this.updateState(this.fetcher.data.result, null, null, 'Init')
        }
        else
        {
          GlobalUI.Dialog.confirm(sErr, 'Invalid license configuration');
        }
      }
    );
  }

  componentDidMount()
  {
    this.validatorsPFeature =
    {
      // Priority should be a positive number
      default_priority : v => parseNonZeroPositiveInt(v) !== null ? '' : 'Default priority should be an integer > 0' ,

      // Signal should be one of ....
      signal : v =>
      {
        const arrNames = Object.keys(AdminLicenses.dctSignalNums);
        return arrNames.indexOf(v) >= 0 ? '' : `Signal should be one of ${arrNames.join(',')}`
      },

      // projects definition should be in format { k:v:p, k:v:p, k:v:p ...}
      projects : v =>
      {
        const ret = parseProjectPriorityStr(v);
        return ret ? '' : 'Value should be a comma separated list where each item is of form "payer-proj:priority:allocation".'
                          + 'Allocation values should total to 100'
      }
    }
    this.fetcher.fetch();
  }

  // Checks the config and returns an error message if any
  validateData = (data) =>
  {
    // TODO - how much should we check?
    // Is a JSON schema necessary?
    return null;
  }

  getEntries = (dctData, sKind) => dctData[this.state.server][sKind + 's']

  // Updates the state based on what is changed
  // sKind is one of 'data', 'server', 'project', 'feature', 'pfeature'
  // dctData is the entire updated dictionary corresponding to the license JSON
  // sName specifies the server/project|feature|pfeature name that changed
  // sAction specifies what happened to it Init/Add/Edit/Delete/Select
  // Init is for initial loading of data
  updateState = (dctData, sKind, sName, sAction) =>
  {
    const getDefaults = (sServer) =>
    {
      const dctServers = dctData || {...this.state.data};
      const server = sServer;
      const project = server && getFirstKey(dctServers[server].projects);
      const feature = server && getFirstKey(dctServers[server].features);
      const pfeature = server && getFirstKey(dctServers[server].pfeatures);
      return {server, project, feature, pfeature};
    }

    switch(sAction)
    {
      // For additions, switch selection to the added one
      case 'Add':
      {
        // if a server was added, Update data, set current server to the new one and project/feature to the first entries available
        if(sKind === 'server')
        {
          this.setState({data: dctData, ...getDefaults(sName)});
        }
        else // Update data, leave current server alone, set project or feature to new one
        {
          this.setState({data: dctData, [sKind]: sName});
        }
      }
      break;

      // For edits, update data, reset the current to the name of what changed
      case 'Edit':
      {
        this.setState({data: dctData, [sKind]: sName});
      }
      break;

      // Move out the "available" and "total" keys for each server entry to a different state var
      case 'Init':
      {
        const dctStats = {};

        const arrServers = Object.keys(dctData);
        for(const server of arrServers)
        {
          const dctServer = dctData[server];
          dctStats[server] = {total: dctServer.total, available: dctServer.available};
          delete dctServer.total;
          delete dctServer.available;
        }

        this.setState({stats: dctStats, data: dctData, ...getDefaults(getFirstKey(dctData))});
      }
      break;

      // For deletion, set the current entries to the first of whatever remains
      case 'Delete':
      {
        // if a server was deleted, set all of current server/project|feature|pfeature to the first entries available
        if(sKind === 'server')
        {
          this.setState({data: dctData, ...getDefaults(getFirstKey(dctData))});
        }
        else // Update data, leave current server alone, set project/feature/pfeature to the first entry
        {
          this.setState({data: dctData, [sKind]: getFirstKey(this.getEntries(dctData, sKind))});
        }
      }
      break;

      case 'Select':
      {
        // If a server is selected, set both project&feature&pfeature to first entries
        if(sKind === 'server')
        {
          this.setState(getDefaults(sName));
        }
        else // set project/feature to the specified entry
        {
          this.setState({[sKind]: sName});
        }
      }
      break;
    }
  }

  // Add or edit preemptible feature
  doSavePfeature = (data, doDelete) =>
  {
    // Get the current pfeature as a dict, and projects string
    const dctPFeature = {...this.state.data[this.state.server].pfeatures[this.state.pfeature]};
    let sProjects = dctPFeature.projects;

    // Convert projects string to a dict
    const dctProject = parseProjectPriorityStr(sProjects, true);
    if(dctProject)
    {
      // Save provided data into the dict
      if(doDelete)
      {
        delete dctProject[data.project];
      }
      else
      {
        dctProject[data.project] = {priority: data.priority, allocation: data.allocation};
      }

      // Convert back to string and put it in the pfeature dict
      dctPFeature.projects = Object.keys(dctProject).map
      (
        k =>
        {
          const e = dctProject[k];
          return `${k}:${e.priority}:${e.allocation}`;
        }

      ).join(',');

      this.doMergeEdits('pfeature', dctPFeature, this.state.pfeature, this.state.pfeature, 'Edit');
    }

    return false;
  };

  // Called by the edit dialog to validate before saving
  doValidateData = (sKind, dctData, sName, sOldName) =>
  {
    const errFail = (s) => {GlobalUI.DialogConfirm.show('Error', s, false, LayoutDims.wContent * 0.75); return false;}

    // No key should be empty, except pfeatures projects
    if(!Object.keys(dctData).every(e => dctData[e] !== '' || (sKind === 'pfeature' && e === 'projects') ))
    {
      return errFail('One or more values are blank');
    }

    // Prevent overwriting an existing entry
    if(sName !== sOldName)
    {
      let bOverWrite;
      if(sKind === 'server')
      {
        bOverWrite = sName in this.state.data;
      }
      else
      {
        const dct = this.getEntries(this.state.data, sKind);
        bOverWrite = dct && (sName in dct);
      }

      if(bOverWrite)
      {
        return errFail(`A ${sKind} entry with the name '${sName}' already exists`);
      }
    }

    switch(sKind)
    {
      case 'server':
      {
        // Ensure that the mandatory keys exist
        for(const key of AdminLicenses.arrServerMandatoryFields)
        {
          if(!(key in dctData))
          {
            return errFail(`Required key '${key}' is missing for server entry`);
          }
        }

        // check Name=Value format for config
        if(!dctData.config.match(/^[^=]+=[^=]+$/))
        {
          return errFail(`The 'config' value should be of the format 'NAME=VALUE'`);
        }

        // Check that port is a number
        if(!String(dctData.port).match(/^\d+$/))
        {
          return errFail(`The 'port' value should be a number`);
        }

        break;
      }

      case 'feature':
      {
        // Every feature key's value should be an integer
        if(!Object.keys(dctData).every(e => String(dctData[e]).match(/^\d+$/)))
        {
          return errFail('One or more feature allocation values is not a number');
        }
      }
      break;

      // For project, check that allocation values are numbers or percent
      case 'project':
      {
        if(!Object.keys(dctData).every(e => String(dctData[e]).match(/^\d+%?$/)))
        {
          return errFail('Pseudo-feature allocation value should be a number or a percentage');
        }
      }
      break;

      // For pfeature, validate everything (even though AddEditDialog does some)
      case 'pfeature':
      {
        for(const key of AdminLicenses.arrPfeatureMandatoryFields)
        {
          const validator = this.validatorsPFeature[key];
          const sErr = validator ? validator(dctData[key]) : null;
          if(sErr)
          {
            return errFail(sErr);
          }
        }
      }
      break;
    }

    return true;
  }

  // Saves changed data to server after validation, updates UI to keep dropdowns selection correct for all edits
  doSaveChanges = (sKind, dctData, sName, sOldName, sAction) =>
  {
    // Fixup the "config" key for the server entry that changed from {config : "x=y"} to {config: {name: x, value: y}}
    // Only for add/edit
    if(sKind === 'server' && (sAction === 'Edit' || sAction === 'Add'))
    {
      const [name, value] = dctData[sName].config.split('=');
      dctData[sName].config = {name, value};

      // Make port a number (its already been checked)
      dctData[sName].port |= 0;
    }

    // Fixup feature allocation values to be numbers rather than strings
    if(sKind === 'feature' && sAction !== 'Delete')
    {
      const dctFeatures = dctData[this.state.server].features[sName];
      for(const feature of Object.keys(dctFeatures))
      {
        dctFeatures[feature] |= 0;
      }
    }

    // Fixup pfeature priority values so that the priority values are numbers rather than strings
    if(sKind === 'pfeature' && sAction !== 'Delete')
    {
      const dctPfeatures = dctData[this.state.server].pfeatures[sName];
      dctPfeatures.default_priority |= 0;
    }

    GlobalUI.DialogConfirm.confirm
    (
      <div style={{margin: 0}}>
        <div>The following configuration will be applied:</div>
        <pre style={{...Styles.Bordered, marginTop: 0, overflow: 'auto', maxHeight: 400, backgroundColor: Colors.clrNimbixGray, padding: LayoutDims.nMargin}}>
          {JSON.stringify(dctData, null, 2)}
        </pre>
      </div>,
      'Confirm',
      () =>
      {
        const fetcher = new DataFetcher('/portal/admin-license-save', {data: JSON.stringify(dctData)});
        fetcher.whenDone
        (
        () =>
          {
            this.updateState(dctData, sKind, sName, sAction);
            GlobalUI.Dialog.onClose();
          }
        );

        fetcher.ifFail(
          (jqXHR) =>
          {
            // We may end up with a nested error object here like - {"error":{"code":400,"msg":{"code":400,"msg":"Name or service not known"}}}
            // Un-nest it if needed for GlobalUI.Dialog.showErr() to work OK
            if(jqXHR.responseJSON && jqXHR.responseJSON.error)
            {
              let msg = jqXHR.responseJSON.error.msg;

              // If message itself is a nested error, unwrap it
              if(msg && msg.code)
              {
                jqXHR.responseJSON.error.code = msg.code || 501
                jqXHR.responseJSON.error.msg = msg.msg || 'Unknown Error'
              }
            }
            GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed');

          }
        );
        FetchBusy(fetcher, 'Saving license config...');
      },
      null,
      'OK',
      'Cancel',
      LayoutDims.wContent * 0.85
    );
  }

  // Validates the new changes,applies them to the current data and saves the state
  // called from AdminLicenseEdit's Save button onClick
  doMergeEdits = (sKind, dctNewData, sName, sOldName, sAction) =>
  {
    // Trim name
    sName = sName.trim()
    if(!this.doValidateData(sKind, dctNewData, sName, sOldName, sAction))
    {
      return false;
    }

    const dctData = deepCopy(this.state.data);
    let dctSubDict;

    // Get a reference to the sub-dictionary that we are updating in the data
    if(sKind === 'server')
    {
      dctSubDict = dctData;
    }
    else
    {
      // For project/feature the corresponding sub-key under the current server, create if missing
      let sKinds = sKind + 's';
      if(!(sKinds in dctData[this.state.server]))
      {
        dctData[this.state.server][sKinds] = {};
      }
      dctSubDict = dctData[this.state.server][sKinds];
    }

    if(sAction === 'Add')
    {
      dctSubDict[sName] = {};
    }
    else
    {
      // If its an edit and the name changed, do the rename
      if(sOldName !== sName)
      {
        dctSubDict[sName] = dctSubDict[sOldName];
        delete dctSubDict[sOldName];
      }

      // For the server entry, preserve existing "features" and "projects" keys
      if(sKind === 'server')
      {
        // Grab features and projects
        const {features, projects, pfeatures} = dctSubDict[sName];

        // Clear the remaining and put the above three back
        dctSubDict[sName] = {};
        if(features) dctSubDict[sName].features = features;
        if(projects) dctSubDict[sName].projects = projects;
        if(pfeatures) dctSubDict[sName].pfeatures = pfeatures;
      }
      else
      {
        // Clear the sub-dict
        dctSubDict[sName] = {};
      }
    }

    // Add the key/values given from AdminLicenseEdit into the sub-dict
    Object.keys(dctNewData).forEach(key=>dctSubDict[sName][key] = dctNewData[key]);

    // Save changes, specifying what changed too
    this.doSaveChanges(sKind, dctData, sName, sOldName, sAction);

    return true;
  }

  // Deletes current server/project or feature and saves the state
  doDelete = (sKind) =>
  {
    const dctData = deepCopy(this.state.data);

    // For server, delete the top level key for current selection
    if(sKind === 'server')
    {
      delete dctData[this.state.server];
    }
    else
    // For feature or project, delete the key under feature/project
    {
      delete this.getEntries(dctData, sKind)[this.state[sKind]];
    }

    this.doSaveChanges(sKind, dctData, null, null, 'Delete');
  };

  // Show the add or edit dialog for servers/projects/features
  doShowEditDlg = (action, keyTitle, sKind) =>
  {
    const isEdit = action === 'Edit';

    let data = [], hints = [], validators, options, datalist;
    switch(sKind)
    {
      case 'server':
        if(isEdit)
        {
          data = this.makeServerReportData();
        }
        else
        {
          data = AdminLicenses.arrServerMandatoryFields.map((key, id)=>({id, key, value: ''}));
          hints = AdminLicenses.arrServerMandatoryFieldHints;
        }
      break;

      case 'project':
        datalist = 'projectNames';
        //fall thru

      case 'feature':

      case 'pfeature':
        // If editing put the values in
        if(isEdit)
        {
          data = dictToReport(this.getEntries(this.state.data, sKind)[this.state[sKind]]);
        }

        // For pfeatures set hints, validators and options
        if(sKind === 'pfeature')
        {
          if(!isEdit)
          {
            data = AdminLicenses.arrPfeatureMandatoryFields.map((key, id) => ({id, key, value: ''}));
          }

          hints = AdminLicenses.arrPfeatureMandatoryFieldsHints;

          // Functions to perform validation of above fields, returns error message if invalid, else null
          validators = this.validatorsPFeature;

          // Autocomplete
          options = {signal: 'signalNames'}
        }
        break;
    }

    GlobalUI.Dialog.show
    (
      `${action} ${keyTitle}`,
      <AdminLicenseEdit action={action}
                        kind={sKind}
                        hints={hints}
                        noAdd={sKind === 'pfeature'}
                        hiddenFields={sKind === 'pfeature' ? ['projects'] : []}
                        validators={validators}
                        entry={isEdit ? this.state[sKind] : ''}
                        data={data}
                        datalist={datalist}
                        options={options}
                        colText={AdminLicenses.dctColTexts[sKind]}
                        keyTitle={keyTitle}
                        onClose={GlobalUI.Dialog.onClose}
                        onSave={this.doMergeEdits}/>,
      false,
      LayoutDims.wContent * 0.85
    );
  }

  // Show a delete confirmation dialog and delete server/project|feature
  doShowDeleteDlg = (sKind) =>
  {
    // Get the current server/project|feature name
    const sSubKey = this.state[sKind];
    const sTitle = sKind[0].toUpperCase() + sKind.substring(1);
    GlobalUI.Dialog.confirm
    (
      `Are you sure you want to delete the ${sKind} entry '${sSubKey}'?`,
      `Confirm ${sTitle} Delete`,
      ()=>this.doDelete(sKind, sSubKey)
    );
  }

  // Converts the license server data in a form palatable to ReportDisplay, removing projects and features keys
  makeServerReportData = () =>
  {
    // Dump data as key and values, except projects and features
    const data = this.state.data[this.state.server];
    return Object.keys(data).filter(e=>['projects', 'features', 'pfeatures', 'available', 'total'].indexOf(e) < 0).map
    (
      (key, id) =>
      {
        // convert the {config: {name: X, value: Y}} into {config: X=Y}
        const val = key === 'config' ? `${data[key].name}=${data[key].value}` : data[key];
        return {id, key: key, value: val}
      }
    );
  }

  // Creates the select field for servers/projects/features
  makeSelect = (arrData, sTag, sKind) =>
  {
    // When select changes, update the relevant UI
    const onChange = e => this.updateState(null, sKind, e.target.value, 'Select');
    arrData.sort();
    return (
      <select data-cy={sTag} ref={sTag}
              style={{...Styles.ParamInput, ...Styles.Bordered, width: '65%'}}
              value={this.state[sKind] || ''}
              onChange={onChange}>
      {
        arrData.map(e=><option key={e} value={e}>{e}</option>)
      }
      </select>
    );
  }

  // Renders one section of the UI
  doRenderSection = (sKind, arrReportData, arrKeys, disable, sTitle, elemExtra, sConfigTitle, arrHidden=[]) =>
  {
    sTitle = sTitle || sKind[0].toUpperCase() + sKind.substr(1);
    const arrColText = AdminLicenses.dctColTexts[sKind];
    const hasEntry = arrKeys.length > 0;
    const data = [...arrReportData].filter(e => arrHidden.indexOf(e.key) < 0);

    let elemMain =
    <>
      {elemExtra && <><b style={{color: Colors.clrNimbixDark}}>{sConfigTitle}:</b> <Separator/> </>}
      <ReportDisplay data={data}
                     colFields={['key', 'value']}
                     colText={arrColText}
                     colGroup={<ColGroup cols={[30]} />}
      />
      <ShowOnly if={!data.length}>
        <div style={{margin: 0, padding: LayoutDims.nMargin, ...Styles.Bordered}}>None</div>
      </ShowOnly>

      <Separator units={2}/>
      {elemExtra}
    </>

    if(elemExtra)
    {
      elemMain =
      <div style={{...Styles.ThickBordered, padding: LayoutDims.nMargin}}>
        {elemMain}
      </div>
    }

    return (
    <div>
      <div style={Styles.FlexSpread}>
        <div style={{flex: 3}}>
          <b style={{color: Colors.clrNimbixDark}}>{sTitle}: &nbsp;&nbsp;</b>
            {this.makeSelect(arrKeys, sTitle, sKind)}
        </div>

        <div style={{...Styles.InlineFlexRow, flex: 1, justifyContent: 'flex-end'}}>
          <RaisedButton onClick={()=>this.doShowEditDlg('Add', sTitle, sKind)} data-cy={`btn${sTitle}New`} disabled={disable} {...Btns.Green} label='New'/>
          &nbsp;&nbsp;
          <RaisedButton onClick={()=>this.doShowEditDlg('Edit', sTitle, sKind)} data-cy={`btn${sTitle}Edt`} disabled={!hasEntry} {...Btns.Blue} label='Edit'/>
          &nbsp;&nbsp;
          <RaisedButton onClick={()=>this.doShowDeleteDlg(sKind)} data-cy={`btn${sTitle}Del`} disabled={!hasEntry} {...Btns.DarkRed} label='Delete'/>
        </div>
      </div>

      <Separator/>
      {elemMain}
      <Separator units={4}/>
    </div>
    );
  }

  doRenderData = () =>
  {
    const server = this.state.server;
    const feature = this.state.feature;
    const project = this.state.project;
    const pfeature = this.state.pfeature;

    const servers = this.state.data;
    const projects = (server && servers[server].projects) || {};
    const features = (server && servers[server].features) || {};
    const pfeatures = (server && servers[server].pfeatures) || {};

    const dctServer = server ? this.makeServerReportData() : [];
    const dctFeature = server && feature ? dictToReport(this.state.data[server].features[feature]) : [];
    const dctProject = server && project ? dictToReport(this.state.data[server].projects[project]) : [];

    const dctPFeatureRaw = server && pfeature ? this.state.data[server].pfeatures[pfeature] : null
    const dctPfeature = dctPFeatureRaw ? dictToReport(dctPFeatureRaw) : [];

    // Check format and reset projects string if bad
    if(pfeature)
    {
      const sData = parseProjectPriorityStr(dctPFeatureRaw.projects);
      if(sData === false)
      {
        console.log('Invalid Pfeature data:', dctPFeatureRaw.projects);
        return <div className='errortext' style={{fontSize: LayoutDims.FontSize + 4}}>Configuration data is invalid</div>
      }
    }

    // Create the extra project config pane
    const elemProjectConfig =
        pfeature
        ?
          <PFeatureProjects data={dctPFeatureRaw.projects} onSave={this.doSavePfeature} />
        :
          null;

    return (
      <div>
        {this.doRenderSection('server', dctServer, Object.keys(servers))}
        {this.doRenderSection('feature', dctFeature, Object.keys(features), !server, 'Pseudofeature')}
        {this.doRenderSection('project', dctProject, Object.keys(projects), !server)}
        {this.doRenderSection('pfeature', dctPfeature, Object.keys(pfeatures), !server, 'Preemptible Feature', elemProjectConfig, 'Feature config', ['projects'])}
      </div>
    );
  };

  doRenderErr = () =>
  {
    return (
      <div>
        Failed to connect to jarvice-license-manager. It is currently unavailable or not deployed.
        <p>
          {this.state.error}
        </p>

        <RaisedButton onClick={() => FetchBusy(this.fetcher)} {...Btns.Green} label="Retry"/>
      </div>
    );
  }

  render()
  {
    const projs = new Set();
    for(const item of Data.Projects)
    {
      projs.add(`${item.payer}-${item.project}`)
    }

    return (
      <div>
      {
        this.state.data
        ?
          this.doRenderData()
        :
          this.state.error
          ?
            this.doRenderErr()
          :
          <div>
            <Separator units={12}/>
            <Spinner size={64} textColor={Colors.clrNimbixDark} status="Loading..." style={Styles.Full}/>
          </div>
      }

        <datalist id='signalNames'>
          {Object.keys(AdminLicenses.dctSignalNums).map((s,i)=><option key={i}>{s}</option>)}
        </datalist>

        <datalist id='projectNames'>
          {[...projs].map((e, i)=><option key={i}>{e}</option>)}
        </datalist>

      </div>
    );
  }
};
