import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

// Our UI data, styles and colors
import {Styles, Btns, LayoutDims, Colors} from './UIConst.js';
import {SSHKeyFetcher, StateFetcher, DataFetcher, Data, AuditLog} from '../Models/Model';
import {GlobalUI, Spinner, FetchBusy, BusyHelper} from '../Global'

import {FileChooser, Separator, TableHeaderCells} from './Components';


export class SettingsDeployKey extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {deployKey: null}
  }

  // Shoot out a request to the server to get the key
  fetchKey = () =>
  {
    new StateFetcher('/portal/user-get-deploy-key', {}, 'key')
    .fetchUpdate
    (
      this,
      'deployKey',
      null,
      ()=>AuditLog('settings', 'deploykey', 'Fetched')
    );
  }

  render()
  {
    const styleKeyPane =
    {
      ...Styles.Bordered,
      width: '100%',
      maxWidth: '100%',
      height: 150,
      maxHeight: 150,
      backgroundColor: Colors.clrNimbixGray,
      fontFamily: 'monospace',
      fontSize: 'large'
    };

    return (
      <div>
        <div>
          The Deploy Key button displays a public key that can be used to authorize
          Git pulls from this account, such as the Deploy Key in GitHub.
          This key is only needed when using a JARVICE build service for PushToCompute™ applications.
        </div>

        {
          this.state.deployKey &&
          <div>
            <br/>
            <textarea readOnly style={styleKeyPane} ref='textDeployKey' value={this.state.deployKey}/>
          </div>
        }

        <Separator units={2}/>
        <RaisedButton {...Btns.Blue} label='Fetch Deploy Key' onClick={this.fetchKey}/>
      </div>
    );
  }
}


// Content of the add SSh key dialog box
class AddKeyDlg extends Component
{

  // regex to validate rsa SSH key data
  static rxSSH = /ssh-rsa\sAAAA[0-9A-Za-z+/]+[=]{0,3}\s([^@]+@[^@]+)?/;

  constructor(props)
  {
    super(props);
    this.state = {readOnly: false, sKey: ''};
  }

  // Prevent massive files from being loaded
  validate = (file) =>
  {
    if(file.size > 8192)
    {
      GlobalUI.Dialog.show('Error', 'Not a valid SSH public key file');
      return false;
    }

    return true;
  }

  // verify regex
  isKeyValid = () => AddKeyDlg.rxSSH.test(this.state.sKey);

  // Save the current key in state so we can verify
  onChange = (e) =>
  {
    this.setState({sKey: e.target.value});
  }

  // When file is read, set the UI and the state
  onReadFile = (t) =>
  {
    this.refs.sshKeyText.value = t;
    this.setState({readOnly: true, sKey: t});
  }

  onLoad = () => this.refs.fileChooser.open();
  onClear = () => { this.setState({readOnly: false}); this.refs.sshKeyText.value = ''};

  render()
  {
    // Pass up our references to whoever needs
    if(this.props.giveRefs)
    {
      this.props.giveRefs(this.refs);
    }

    const styleText =
    {
      ...Styles.ParamInputWide,
      height: 150,
      maxHeight: 150,
      fontFamily: 'monospace',
      flex: 4
    };

    const styleBtns =
    {
      ...Styles.Inline,
      flexDirection: 'column',
      width: 'auto',
      alignItems: 'stretch',
      marginLeft: 8
    }

    const validKey = this.isKeyValid();

    return (
      <div>
        <div style={{padding: LayoutDims.nMargin}}>
          <div>Paste key data here or load from a file:</div>

          <div style={{...Styles.Inline, alignItems: 'stretch'}}>

            <textarea onChange={this.onChange} readOnly={this.state.readOnly} ref='sshKeyText'
                      spellCheck={false} style={styleText}/>

            <div style={styleBtns}>
              <RaisedButton {...Btns.Green} label='Load from file' onClick={this.onLoad}/>
              <Separator units={0.5}/>
              <RaisedButton label='Clear' onClick={this.onClear}/>

              <div style={{flex:1}}></div>

              <RaisedButton label='Add' {...Btns.Blue} onClick={this.props.onAdd} disabled={!validKey}/>
            </div>

          </div>

          <div className='errortext'>{validKey ? <div>&nbsp;</div> : 'Not a valid SSH key'}</div>
          <FileChooser ref='fileChooser' onRead={this.onReadFile} asURL={false} validate={this.validate}/>
        </div>

      </div>
    );
  }
}


export class SettingsSSH extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      editing: false,
      selectedKey: null, // Selected SSH key
      dctKeys: null,     // map of key => [type, comment] to prevent duplicate keys
      keysCount: 0
    };

    SSHKeyFetcher.whenDone(this.setSSHKeysFromData);
  }

  // On Initial load, feed the existing SSH keys data (got from login) in
  componentDidMount()
  {
    this.setSSHKeysFromData();
  }

  setSSHKeysFromData = () =>
  {
    try
    {
      // split data into lines
      let arrKeys = [];
      if(Data.User.SSHKeys)
      {
        arrKeys = Data.User.SSHKeys.split('\n').filter(s=>s !== '');
      }

      // split each line and store it as dctKeys[<key>] = [type, comment]
      const dctKeys = {};
      for(const sKey of arrKeys)
      {
        const arrKey = sKey.trim().split(' ');
        dctKeys[arrKey[1]] = [arrKey[0], arrKey[2] ? arrKey[2].trim() : null]; // trim any trailing whitespace
      }

      // Save the keys and also how many there are (used for auditing if the count changed)
      this.setState({dctKeys, keysCount: arrKeys.length});
    }
    catch(x)
    {
      GlobalUI.Dialog.show('Error', 'Could not parse SSH key data');
    }

    // This may have been triggered after save, so remove the spinner (Unbusy is harmless if not busy)
    BusyHelper.Unbusy();
  }

  // Reset the keys table to show from what we already have from the server
  // Maker it readonly
  cancel = () =>
  {
    this.setState({editing: false, selectedIdx: null});
    this.setSSHKeysFromData();
  }

  // Called by parent when Save is clicked
  trySave = (parent) =>
  {
    this.setState({editing: false, selectedIdx: null});

    // Reassemble the ssh keys
    const keys = Object.keys(this.state.dctKeys).map
    (
      (key) =>
      {
        const arrKey =  this.state.dctKeys[key];
        return arrKey[0] + ' ' + key + (arrKey[1] ? ' ' + arrKey[1] : '');
      }
    );

    // Rudimentary smartness for detecting added/deleted for audit
    const nChanged = keys.length - this.state.keysCount;

    // Make a request
    const fetcher = new DataFetcher('/portal/config-set-ssh', {keys: JSON.stringify(keys)});

    // Fire the request and spin it till done
    FetchBusy(fetcher, 'Saving keys...');

    // Let the parent pane know the save is successful, refetch the data with a spinner
    // for safety
    fetcher.whenDone
    (
      ()=>
      {
        AuditLog('settings', 'sshkeys', nChanged < 0 ? 'Deleted' : nChanged > 0 ? 'Added' : 'Changed');
        parent.saved(this);
        BusyHelper.Status('Reloading keys...');
        BusyHelper.Busy();
        SSHKeyFetcher.fetch();
      }
    );
  }

  // delete key from list
  onRemoveKey = () =>
  {
    // Make a copy of the state, remove the key and set the state back
    const dctKeys =  {...this.state.dctKeys};
    delete dctKeys[this.state.selectedKey];
    this.setState({dctKeys, selectedKey: null});
  }

  // Show add key dialog
  onAddKey = () =>
  {
    // This gets the refs member of the dialog, so we can read te value
    let dlgRefs;

    // Actually add the key to the list
    const doAdd = () =>
    {
      // Get the key, append to state
      const arrKey = dlgRefs.sshKeyText.value.split(' ');
      const dctKeys = {...this.state.dctKeys, [arrKey[1]]: [arrKey[0], arrKey[2]]};
      this.setState({dctKeys});
      GlobalUI.Dialog.onClose();
    }

    // Display the dialog
    GlobalUI.Dialog.show('Add New Key', <AddKeyDlg giveRefs={(refs) => dlgRefs = refs} onAdd={doAdd}/>);
  }

  render()
  {
    // Make table rows if we have data
    let rowKeys = [];
    if(this.state.dctKeys)
    {
      const selectedKey = this.state.selectedKey;
      rowKeys = Object.keys(this.state.dctKeys).map
      (
        (key) =>
        {
          const styleTR = selectedKey === key ? Styles.TRSel : Styles.TRDesel;
          const styleTD = selectedKey === key ? Styles.TDSel : Styles.TDDesel;
          const val = this.state.dctKeys[key][1];
          return(
            <tr key={'ssh-key-' + key}
                onClick={() => this.state.editing && this.setState({selectedKey: key})} style={styleTR}>
              <td style={{...styleTD, wordBreak: 'break-all'}}>
                {val}
              </td>
              <td style={{...styleTD, textOverflow: 'ellipsis', overflow: 'hidden'}}>
                {key}
              </td>
            </tr>
          );
        }
      );
    }

    const cantDelete = !this.state.editing || this.state.selectedKey == null;
    return (
      <div>
        <div style={Styles.Inline}>
          <RaisedButton label='Add' {...Btns.Green} disabled={!this.state.editing} onClick={this.onAddKey}/>
          &nbsp;&nbsp;&nbsp;
          <RaisedButton disabled={cantDelete} label='Delete' onClick={this.onRemoveKey}/>
        </div>

        <Separator units={1}/>

        {
          // Show table rows if we have data, else show a spinner
          this.state.dctKeys
          ?
            <table style={{...Styles.Table, tableLayout:'fixed', width:'100%', cursor: 'default'}}>
              <colgroup>
                <col style={{width: '30%'}}/>
                <col style={{width: '70%'}}/>
              </colgroup>

              <thead>
                <tr>
                  {TableHeaderCells(['Comment', 'SSH Key'])}
                </tr>
              </thead>

              <tbody>
                {rowKeys}
              </tbody>
            </table>
          :
            <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
        }

      </div>
    );
  }
}

