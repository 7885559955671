import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

import {Separator, ShowOnly, HLine, ReportDisplay, ColGroup, SwitchedPane} from './Components';
import {Styles, Btns, Colors, LayoutDims} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher} from '../Models/Model';
import LimitsTable from './LimitsTable';

export default class AdminLimits extends Component
{

  static colText = {payer: ['Payer', 'Comments'], billing: ['Billing Code Range', 'Comments']};
  static colFields = {payer: ['payer', 'comments'], billing: ['range', 'comments']};
  static colTypes = {payer: {}, billing: {range: 'bc_start'}};

  constructor(props)
  {
    super(props);
    this.state =
    {
      data: {payer: null, billing: null},
      selIndex: {payer: -1, billing: -1},
      mode: 'billing',   // can be billing or payer
      pane: 'View',
      payers: null,
    };

    // Store this in GlobalUI, AdminUsers will call takeUsers() on us when it gets the data
    GlobalUI.AdminLimits = this;

    // Fetch the limits for payer and billing
    this.fetcher = new DataFetcher('/portal/admin-limits-list-all');
    this.fetcher.whenDone(this.onUpdate);
    this.fetcher.fetch();

    // Register the callbacks for the switched panes to freeze and unfreeze heartbeat reload
    SwitchedPane.RegisterOnSwitch('@AdminLimits/Edit', () => GlobalUI.freeze(true));
    SwitchedPane.RegisterOnSwitch('@AdminLimits/View', () => GlobalUI.freeze(false));
  }


  // Called by AdminUsers to provide list of users
  takeUsers = (users) =>
  {
    // Extract all payers (aka non team members) from the list
    const payers = [];

    for(const row of users)
    {
      if(!row.payer)
      {
        payers.push(row.user_login)
      }
    }

    this.setState({payers});
  }

  // Switches between edit/view panes
  setPane = (pane) =>
  {
    SwitchedPane.switch('@AdminLimits', pane);
    this.setState({pane});
  }

  // Gets the selected index for whichever mode - billing/payer
  getSelIdx = () => this.state.selIndex[this.state.mode];

  // Gets the data for whichever mode - billing/payer
  getData = () => this.state.data[this.state.mode];

  // Clear existing view and refresh
  refetch = () =>
  {
    this.setState
    (
      {
        selIndex: {payer: -1, billing: -1},
        data: {payer: null, billing: null}
      }
    );

    this.fetcher.fetch();
  }

  // When new data arrives
  onUpdate = () =>
  {
    // Data has two keys payer and billing
    // Each has {id1 :{<limits>}, id2 :{<limits>}, ... }
    // Convert each to [ {id1, <limits>} , {id2, <limits>}, ...]

    const data = {payer:[], billing: []};
    let id = 0;
    const billing = this.fetcher.data.billing;
    for(const idx of Object.keys(billing))
    {
      const limit = billing[idx];
      data.billing.push({id, idx, ...limit, range: limit.bc_start + '-' + limit.bc_end});
      ++id;
    }

    const payers = this.fetcher.data.payer;
    id = 0;
    for(const idx of Object.keys(payers))
    {
      const limit = payers[idx];
      data.payer.push({id, idx, ...limit});
      ++id;
    }

    this.setState({selIndex: {payer: -1, billing: -1}, data});
    this.setPane('View');
  }

  // Add new range entry
  onAdd = () =>
  {
    // Clear the selection, after saving it
    const oldSelIndex = this.state.selIndex;
    this.setState({selIndex: {payer: -1, billing: -1}, oldSelIndex});

    // Clear the input fields
    this.refs.comments.value = '';
    if(this.state.mode === 'billing')
    {

      this.refs.bc_start.value = 0;
      this.refs.bc_end.value = 0;
    }
    else
    {
      this.refs.payer.disabled = false;
    }

    this.setPane('Edit');

    // For audit message
    this.action = 'Added';
  }

  // Edit range entry
  onEdit = () =>
  {
    // Choose the right data set
    const data = this.getData();
    const limit = data[this.getSelIdx()];

    // Init the input fields
    this.refs.comments.value = limit.comments;

    if(this.state.mode === 'billing')
    {
      this.refs.bc_start.value = limit.bc_start;
      this.refs.bc_end.value = limit.bc_end;
    }
    else
    {
      this.refs.payer.value = limit.payer;
      this.refs.payer.disabled = true;
    }

    this.setPane('Edit');

    // Clear out the saved selIndex
    this.setState({oldSelIndex: null});

    // For audit message
    this.action = 'Changed';
  }

  onDel = () =>
  {
    // Get the selected index and the corresponding DB id
    const selIndex = this.getSelIdx();
    const data = this.getData();
    const idx = selIndex === -1 ? -1 : data[selIndex].idx;

    if(idx !== -1)
    {
      GlobalUI.Dialog.confirm
      (
        'Are you sure you want to delete this limit definition?',
        'Confirm',
        () =>
        {
          // Make the right request
          let url = `/portal/admin-limits-${this.state.mode}-delete`;
          const fetcher = new DataFetcher(url, {id: idx})
            .ifFail(jqXHR=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Delete Failed'))
            .whenDone
            (
              () =>
              {
                this.refetch();
              }
            );

          FetchBusy(fetcher, 'Deleting...');
        }
      );
    }
  }

  onSave = () =>
  {
    // Get the selected index and the corresponding DB id
    const selIndex = this.getSelIdx();
    const data = this.getData();
    const idx = selIndex === -1 ? -1 : data[selIndex].idx;

    const comments = this.refs.comments.value;
    const limitstr = this.refs.limits.getData();

    // This is null if error
    if(limitstr != null)
    {
      // Get values from UI
      const params =
      {
        mc_limits: limitstr,
        comments: comments
      };

      // Set ID if changed
      if(idx !== -1) params.id = idx;

      let url;
      if(this.state.mode === 'billing')
      {
        // Get values from UI
        params.bc_start = this.refs.bc_start.value;
        params.bc_end = this.refs.bc_end.value;
        url = '/portal/admin-limits-set';
      }
      else
      {
        // Get values from UI
        params.payer = this.refs.payer.value;

        // Make sure the payer is selected
        if(!params.payer)
        {
          GlobalUI.DialogConfirm.show('Error', 'Please select the payer for whose team the resource limits will be applied', false, LayoutDims.wContent * 0.75);
          return;
        }

        url = '/portal/admin-limits-payer-set';
      }

      const fetcher = new DataFetcher(url, params)
        .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
        .whenDone
        (
          () =>
          {
            this.refetch();
          }
        );

      FetchBusy(fetcher, 'Saving...');
    }
  }

  onCancel = () =>
  {
    // If we had a selection before (from coming from onAdd), restore it
    if(this.state.oldSelIndex != null)
    {
      // Clear out the saved selIndex
      this.setState({selIndex: this.state.oldSelIndex});
    }

    this.setPane('View');
  }

  onClick = (id) =>
  {
    // Change the correct selIndex based on the mode
    const selIndex = this.state.selIndex;
    selIndex[this.state.mode] = id;
    this.setState({selIndex});
  }

  render()
  {
    const hasData = this.state.data.billing !== null &&
                    this.state.data.payer !== null &&
                    this.state.payers !== null;

    const selIndex = this.getSelIdx();
    const isEditing = this.state.pane === 'Edit';

    const isSelected = selIndex >= 0;
    const limit = isSelected ? this.getData()[selIndex] : {};


    return (
      hasData
      ?
        <div>
          <div style={{overflowY: 'auto'}}>

            <ShowOnly if={!isEditing}>

              <Separator/>
              <RadioButtonGroup onChange={(e, mode)=>this.setState({mode})}
                                ref='radioGroupView'
                                name='adminLimitsRadio'
                                defaultSelected={this.state.mode}
                                style={{...Styles.InlineFlexRow, width: '100%'}}>

                <RadioButton data-cy='radioLimitBilling'
                             style={Styles.Radio}
                             value='billing'
                             label='Limits For Billing Codes'
                             disabled={isEditing}/>

                <RadioButton data-cy='radioLimitPayer'
                             style={Styles.Radio}
                             value='payer'
                             label='Limits For Payers'
                             disabled={isEditing}/>

              </RadioButtonGroup>

              <Separator/>
              <HLine margin={0}/>
              <Separator/>
            </ShowOnly>

            <SwitchedPane paneGroup='@AdminLimits' paneName='Edit'>

              <Separator/>
              <div style={Styles.FlexSpread}>
                <RaisedButton data-cy='btnAdminLimitsSave' {...Btns.Green} label='Save' onClick={this.onSave}/>
                <RaisedButton data-cy='btnAdminLimitsCancel' {...Btns.DarkRed} label='Cancel' onClick={this.onCancel}/>
              </div>
              <Separator/>

              <ShowOnly if={this.state.mode === 'billing'}>
                <Separator units={2}/>
                <div style={{...Styles.InlineFlexRow, alignItems: 'baseline', width: '100%', margin: 0}}>

                  <div style={{...Styles.InlineFlexCol, alignItems: 'stretch', width: '30%', margin: 0}}>
                    <label>Billing Code Start:&nbsp;</label>
                    <input data-cy='inputBCStart'  ref='bc_start' type='number' min='0' style={Styles.ParamInput}/>
                  </div>

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div style={{...Styles.InlineFlexCol, alignItems: 'stretch', width: '30%', margin: 0}}>
                    <label>Billing Code End:&nbsp;</label>
                    <input data-cy='inputBCEnd' ref='bc_end' type='number' min='0' style={Styles.ParamInput}/>
                  </div>

                </div>
              </ShowOnly>

              <ShowOnly if={this.state.mode === 'payer'}>
                <Separator units={2}/>

                <datalist id='payer-data-list'>
                  {this.state.payers.map((e, i) => <option key={i} value={e}/>)}
                </datalist>

                <div style={{...Styles.InlineFlexCol, alignItems: 'stretch', width: '30%', margin: 0}}>
                  <label>Payer:&nbsp;</label>
                  <input data-cy='inputListLimitPayer' ref='payer' style={Styles.ParamInput} list='payer-data-list'/>
                </div>
              </ShowOnly>


              <Separator units={2}/>
              <label>Comments: </label>
              <input ref='comments' style={{...Styles.ParamInput, width: '95%'}}/>

              <Separator units={2}/>
              <LimitsTable tag='Admin' ref='limits' limitstr={limit.mc_limits}/>
            </SwitchedPane>

            <SwitchedPane paneGroup='@AdminLimits' paneName='View' active={true}>
              <Separator/>

              <div style={Styles.FlexSpread}>
                <RaisedButton data-cy='btnAdminLimitsNew' {...Btns.Green} label='New' onClick={this.onAdd}/>

                <div>
                  <RaisedButton data-cy='btnAdminLimitsEdit' {...Btns.Blue} label='Edit' disabled={!isSelected} onClick={this.onEdit}/>
                  &nbsp;&nbsp;
                  <RaisedButton data-cy='btnAdminLimitsDelete' {...Btns.DarkRed} label='Delete' disabled={!isSelected} onClick={this.onDel}/>
                </div>
              </div>

              <Separator units={2}/>


                {
                  /* We have to duplicate this control because the columns differ
                  * causing issues with sorting */
                }
                <ShowOnly if={this.state.mode === 'billing'}>
                  <ReportDisplay data={this.state.data.billing}
                                 selectedIndex={selIndex}
                                 onClickRow={this.onClick}
                                 colText={AdminLimits.colText.billing}
                                 colFields={AdminLimits.colFields.billing}
                                 colTypes={AdminLimits.colTypes.billing}
                                 colGroup={<ColGroup cols={[25]}/>}/>
                </ShowOnly>


                <ShowOnly if={this.state.mode === 'payer'}>
                  <ReportDisplay data={this.state.data.payer}
                                 selectedIndex={selIndex}
                                 onClickRow={this.onClick}
                                 colText={AdminLimits.colText.payer}
                                 colFields={AdminLimits.colFields.payer}
                                 colTypes={AdminLimits.colTypes.payer}
                                 colGroup={<ColGroup cols={[25]}/>}/>
                </ShowOnly>

            </SwitchedPane>

          </div>
        </div>
      :
        <div>
          <Separator units={12}/>
          <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
        </div>
    );
  }
}

