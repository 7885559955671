import {Data, DataFetcher, FixRegSymbols, HasRole, PriceStr, ROLE_MACHINE_TESTER} from './Model';

import {StrICmp, deepCopy} from '../Components/Utils'
import {LocalStorage} from '../Global';


// Replace (R), (TM) and their HTML entities with corresponding unicode chars
// Apply to name, description and author of app, as well as every command
const FixAppRegSymbols = (appdef) =>
{
  appdef.name = FixRegSymbols(appdef.name);
  appdef.description = FixRegSymbols(appdef.description);
  appdef.author = FixRegSymbols(appdef.author);
  for(const sCmd of Object.keys(appdef.commands))
  {
    const cmd = appdef.commands[sCmd];
    cmd.name = FixRegSymbols(cmd.name);
    cmd.description = FixRegSymbols(cmd.description);
  }
}


// Fetches the data for all apps (uses app-quick-list to avoid pulling image data )
// Also contains logic to classify the app categories
// And filter out app names for given search criteria
class AppsDataFetcherProto extends DataFetcher
{
  machines = {};
  machinesByPrice = {};
  nonCertTree = {};
  certTree = {};
  invalidAppCmds = {};  // Map of app -> [cmd, cmd2 ...]
  hasTeamApps = false;

  // request json response, set from ajax response handler
  // Or set from initial data got on login
  responseData = [];

  // This is used for text search within these fields : name desc command
  searchText = {} // map of app name -> "name" +  "desc" + "command1" + "command2" ....

  constructor(params = {}, timeout=null, tag='')
  {
    super('/portal/app-quick-list', params, timeout);

    // A tag to recognize this object by
    this.tag = tag;
    this.admindata = {};
  }

  // Sets the zone that will be passed in to the request
  setZone(zone)
  {
    if(zone !== null)
    {
      this.params.zone = zone

      // Tell appList to filter out non-runnable apps
      this.params.restrict = true;
    }
  }

  // Not simply assigning to {} so that references are preserved
  emptyDict = (dct) =>
  {
    for(const prop of Object.keys(dct))
    {
      delete dct[prop];
    }
  }

  // Do the equivalent of mkdir -p
  mkClassHier = (dctRoot, sClassPath) =>
  {
    // Break down each classification name by '/' for sub categories
    const arrPath = sClassPath.split('/');
    for(let i = 1; i <= arrPath.length; ++i)
    {
      const sPath = arrPath.slice(0, i).join('/');
      if(!(sPath in dctRoot))
      {
        dctRoot[sPath] = {};
      }
      dctRoot = dctRoot[sPath];
    }
  }

  // generates a tree of classifications
  makeAppClassificationTree(bLicensed)
  {
    // CLear any previous entries
    this.emptyDict(this.nonCertTree);
    this.emptyDict(this.certTree);

    for(const sAppName of Object.keys(this.data))
    {
      // Get list of classes
      const app = this.data[sAppName];
      if(app.data.classifications && (!bLicensed || app.data.licensed))
      {
        // Add the tree to either classifications or certified (community) classifications
        for(const sClassPath of app.data.classifications)
        {
          if(app.certified)
          {
            this.mkClassHier(this.certTree, sClassPath);
          }
          else
          {
            this.mkClassHier(this.nonCertTree, sClassPath);
          }
        }
      }
    }
  }

  // Check if an array of machine names has no unknown machines
  checkMCArrValid = (arrMC, isMachineTester) =>
  {
    for(const mc of arrMC)
    {
      // If we are a ROLE_MACHINE_TESTER user, ignore entries after the "" (separator)
      if(isMachineTester && mc === '')
      {
        break;
      }

      // * (any machine) is exempt from check
      if(mc !== '*' && !(mc in this.machines))
      {
        return false;
      }
    }

    // Machine testers have the one dummy "" entry that represents a separator
    return arrMC.length > (isMachineTester ? 1 : 0);
  }

  // convert data array into dict and replace trademark symbols etc with glyphs
  // save the cheapest app cost ( assumes machines is loaded previously)
  storeAppData = () =>
  {
    const isMachineTester = HasRole(ROLE_MACHINE_TESTER);
    this.emptyDict(this.data);

    for(let app of this.responseData)
    {
      // Check if app.machines is a subset of Data.Machines
      // otherwise app wont be added to this.data
      let ok = false;
      if(app.data.machines && app.data.machines.length)
      {
        ok = this.checkMCArrValid(app.data.machines, isMachineTester);

        // Now check the same for each command
        if(ok)
        {
          let arrCmds = Object.keys(app.data.commands);
          for(const cmd of arrCmds)
          {
            if('machines' in app.data.commands[cmd])
            {
              // If it fails, set the command invalid
              if(!this.checkMCArrValid(app.data.commands[cmd].machines, isMachineTester))
              {
                // add this command to the bad command array for this app
                let arrBad = this.invalidAppCmds[app.id];
                if(!arrBad)
                {
                  this.invalidAppCmds[app.id] = [];
                }

                this.invalidAppCmds[app.id].push(cmd);
              }
            }
          }

          // Get the cheapest machine that this app accepts to calculate "from" price
          let cheapestMC = null;

          // If app accepts any machine, choose the cheapest possible
          if(app.data.machines[0] === '*')
          {
            cheapestMC = this.machinesByPrice[0];
          }
          else
          {
            // Get the app machines before the separator if needed
            let arrAppDataMC = app.data.machines;
            if(isMachineTester)
            {
              arrAppDataMC = arrAppDataMC.slice(0, arrAppDataMC.indexOf(''));
            }

            // Look for the first machine (sorted by price) and choose it of app accepts it
            for(const m of this.machinesByPrice)
            {
              if(arrAppDataMC.indexOf(m) >= 0)
              {
                cheapestMC = m;
                break;
              }
            }
          }

          // From price is cost of app + machine (there are some test apps with no machines)
          app.from_price = parseFloat(app.price) +
                           (cheapestMC ? this.machines[cheapestMC].mc_price : 0.0);
        }
      }

      // Replace (R), (TM) and their HTML entities with corresponding unicode chars
      FixAppRegSymbols(app.data);

      // ok can only be false for sys admins and app owners who see non-runnable apps
      // Machine testers (and Sys Admins) and will be able to click and run the apps
      // App owners will see it with a red "invalid" band
      app.ok = ok;
      this.data[app.id] = app;

      // Concatenate app name, command names and description into one text string
      const arrTxtList = Object.keys(app.data.commands)
        .map
        (
          (sCmd) =>
          {
            const cmd = app.data.commands[sCmd];
            return cmd.name + '\n' + cmd.description;
          }
        );

      arrTxtList.push(app.data.name);
      arrTxtList.push(app.data.description);
      this.searchText[app.id] = arrTxtList.join('\n').toLowerCase().replace(/™|®/g, '');
    }

  }

  // Called after responseData is filled in
  processData = () =>
  {
    // Admin apps are handled differently
    if(this.tag === 'ADMIN')
    {
      for(let app of this.responseData)
      {
        // Make a copy
        this.admindata[app.id] = deepCopy(app);
        FixAppRegSymbols(app.data);
        this.data[app.id] = app;
      }
    }
    else
    {
      this.storeAppData();
    }

    // Do we have team apps
    this.hasTeamApps = this.getFilteredApps({team:true}).length > 0;

    // Call all the callbacks registered by whenDone()
    this.notifyAllSuccess();
  }

  // Overridden from DataFetcher
  handleDone = (jqXHR) =>
  {
    // Happens sometimes we get here with jqXHR.readyState == 0 (UNSENT)
    // whereas DONE is 4
    if(jqXHR.readyState !== 4)
    {
      console.log('Request cancelled');
    }
    else
    {
      // Save the data into responseData and process it
      this.responseData = jqXHR.responseJSON;
      this.processData();
    }
  }

  getAppNames = () =>
  {
    return Object.keys(this.data)
      .sort((a, b) => StrICmp(this.data[a].data.name, this.data[b].data.name))
  }

  getAppVendorList = (bLicenced) =>
  {
    return [
      ...new Set
      (
        this.getAppNames(this.data)
          .filter((sAppName)=> !bLicenced || this.data[sAppName].data.licensed)
          .map
          (
            (sAppName) => this.data[sAppName].data.author
          )
      )
    ].sort((a, b)=>StrICmp(a,b));
  }

  // Returns app data as lowercase string given a particular key
  // Removes &reg; and &trade; if any
  getTextDataKey = (sAppName, sKey) =>
  {
    return String(this.data[sAppName].data[sKey]).toLowerCase().replace(/™|®/g, '');
  }

  // Filter function finds the class name as a substring of the class list
  isInClass = (sAppName, sClassName) =>
  {
    const arrClasses = this.data[sAppName].data.classifications;

    // Compare without case
    const sClassNameLower = sClassName.toLowerCase();

    for(const sClassPath of arrClasses)
    {
      const sClassPathLower = sClassPath.toLowerCase();

      // Either the entire class should match or the top level path should match
      // For ex Science/Space/Cosmology should be matched by
      // Science/, Science/Space/ and Science/Space/Cosmology too
      if(sClassPathLower === sClassNameLower || sClassPathLower.indexOf(sClassNameLower + '/') === 0)
      {
        return true;
      }
    }
    return false;
  }

  // returns a set of filtered apps
  getFilteredApps(dctFilter)
  {
    // Filter by each key
    let arrApps = this.getAppNames();
    for(const sKey of Object.keys(dctFilter))
    {
      const sValue = dctFilter[sKey];

      // Text based search is fuzzy
      const sTextVal = String(sValue).toLowerCase().trim();

      if(sKey === 'category' || sKey === 'community-category')
      {
        arrApps = arrApps.filter((sAppName) => this.isInClass(sAppName, sValue))
          .filter((sAppName) => !!this.data[sAppName].certified === (sKey === 'category'));
      }
      else if(sKey === 'licensed')
      {
        if(sValue)
        {
          arrApps = arrApps.filter((sAppName) => this.data[sAppName].data.licensed);
        }
      }
      // "My apps"
      else if(sKey === 'owned')
      {
        arrApps = arrApps.filter
        (
          (sAppName) =>
            !this.data[sAppName].public &&
            this.data[sAppName].owner === Data.User.Profile.user_login
        );
      }
      // "team apps"
      else if(sKey === 'team')
      {
        arrApps = arrApps.filter
        (
          (sAppName) =>
            !this.data[sAppName].public &&
            this.data[sAppName].owner !== Data.User.Profile.user_login
        );
      }
      // text search - key is "name", but we search app name, and app author
      else if(sKey === 'name')
      {
        arrApps = arrApps.filter
        (
          (sAppName) =>
            (this.searchText[sAppName].indexOf(sTextVal) >= 0) ||
            (this.getTextDataKey(sAppName, 'author').indexOf(sTextVal) >= 0)
        );
      }
      else // Any other key do a substring match without case
      {
        arrApps = arrApps.filter
        (
          (sAppName) => this.getTextDataKey(sAppName, sKey).indexOf(sTextVal) >= 0
        );
      }
    }

    return arrApps;
  }

  // Returns the
  getFilterHeaderText = (dctAppFilter) =>
  {
    let arrTitle = [];

    if(Object.keys(dctAppFilter).length === 0)
    {
      return 'All Apps';
    }
    else if(dctAppFilter.owned)
    {
      arrTitle.push('My Apps');
    }
    else if(dctAppFilter.team)
    {
      arrTitle.push('Team Apps');
    }
    else if(dctAppFilter['community-category'])
    {
      arrTitle.push('Community');
    }
    else if(dctAppFilter.author)
    {
      arrTitle.push(dctAppFilter.author);
    }

    // Category and community-category are exclusive
    if(dctAppFilter.category)
    {
      arrTitle.push(dctAppFilter.category);
    }
    else if(dctAppFilter['community-category'])
    {
      arrTitle.push(dctAppFilter['community-category']);
    }

    if(dctAppFilter.name)
    {
      arrTitle.push('Search For "' + dctAppFilter.name + '"');
    }

    let sText = arrTitle.join('/').replace(/\//g, ' ▸ ');

    return sText || 'All Apps';
  }

}

// Reg'lar apps
const AppsDataFetcher = new AppsDataFetcherProto();
const AppsData = AppsDataFetcher.data;

// Push to compute apps
const PTCAppsDataFetcher = new AppsDataFetcherProto({owned: true, image: true}, null, 'PTC');
const PTCAppsData = PTCAppsDataFetcher.data;

// Admin apps
const AdminAppsDataFetcher = new AppsDataFetcherProto({isAdmin: true, expand: false, image: true}, null, 'ADMIN');
const AdminAppsData = AdminAppsDataFetcher.data;

// NAE app place holder
const AppNAE =
{
  data:
  {
    description: 'Nimbix Application Environment',
    author: 'Nimbix, Inc.',
    name: 'NAE'
  }
}

// Gets an app by name checking both regular apps and PTS apps
const GetApp = (sAppName) =>
{
  if(sAppName === 'NAE')
  {
    return AppNAE;
  }
  else if(sAppName in AppsData)
  {
    return AppsData[sAppName];
  }
  else if(sAppName in PTCAppsData)
  {
    return PTCAppsData[sAppName];
  }
  return null;
}

const GetAppFetcher = (sAppName) =>
{
  if(sAppName in PTCAppsData)
  {
    return PTCAppsDataFetcher;
  }
  else if(sAppName in AppsData)
  {
    return AppsDataFetcher;
  }

  return null;
}

const GetAppPriceStr = (dctApp) =>
{
  return dctApp.from_price != null
    ?
      PriceStr(dctApp.from_price) + '/hr' + (!dctApp.data.licensed ? ' + license' : '' )
    :
    '<Unknown>'
}

const FilterAppNames = (apps, searchText, showOnlyPublic, prefilter) =>
{
  let arrAppNames = apps ? Object.keys(apps) : [];

  // If prefilter is specified, use that, but ensure that prefilter is a subset of Object.keys(apps)
  // to prevent looking up a deleted or bogus app
  if(prefilter && prefilter.length)
  {
    arrAppNames = prefilter.filter(app => app in apps);
    if(arrAppNames.length < prefilter.length)
    {
      console.log('Deleted app(s) in team apps wl:' + prefilter);
    }
  }

  // Filter out apps using the whitelist, "*" means no filter
  return (
    arrAppNames[0] === '*'
    ?
      arrAppNames
    :
      arrAppNames.filter
      (
        (app) =>
        {
          const dctApp = apps[app];
          let show = true;

          // Do we have a search string, if so filter based on substring match in name and id
          if(searchText)
          {
            show = show &&
                   (app.toUpperCase().indexOf(searchText) >= 0 ||
                    dctApp.data.name.toUpperCase().indexOf(searchText) >= 0);
          }

          // Are we to show only public apps?
          if(showOnlyPublic)
          {
            show = show && dctApp.public;
          }

          return show;
        }
      )
  );
}


// Gets if a boolean flag from the app is set in local storage for current user
// Rather than store a boolean value, it stores the app_updated timestamp
const GetAppFlag = (dctApp, sFlag) =>
{
  const version = LocalStorage.get(sFlag);
  return (version === dctApp.updated);
}

const SetAppFlag = (dctApp, sFlag, val) =>
{
  LocalStorage.set(sFlag, val ? dctApp.updated : 0);
}


export
{
  AppsDataFetcher,
  AppsData,
  PTCAppsDataFetcher,
  PTCAppsData,
  AdminAppsDataFetcher,
  AdminAppsData,
  GetApp,
  GetAppFetcher,
  GetAppPriceStr,
  FilterAppNames,
  FixAppRegSymbols,
  AppNAE,
  GetAppFlag,
  SetAppFlag,
};
