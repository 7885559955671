// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import Chip from 'material-ui/Chip';

import {Tabs, Tab} from 'material-ui/Tabs';

// Our custom components
import {Separator, FileChooser, ShowOnly} from './Components';
import {LayoutDims, Styles, Btns, Colors, Images} from './UIConst';
import {PTCAppsData, PTCAppsDataFetcher} from '../Models/AppsData';
import {DataFetcher, Data, GetAppClasses, GetImgSrc, GetAppImgSrc} from '../Models/Model';

import {GlobalUI, FetchBusy, Validators, ValidationErrs, DefaultJarviceApp, DefaultSingularityApp} from '../Global';

class InputWrapper extends Component
{
  render()
  {
    return(
      <div>
        <p style={{margin: 0, fontSize: 14}}>{this.props.label}</p>
        {this.props.children}
        <Separator units={1.5}/>
      </div>
    );
  }
}


export default class PTCEditDlg extends Component
{
  static propTypes =
  {
    app: PropTypes.string,
    edit: PropTypes.bool.isRequired,
    appdef: PropTypes.object
  };

  constructor(props)
  {
    super(props);
    this.state = {err: '', tab: 'general', app: props.app, edit: props.edit, classes: [], image: null};

    this.stylePre =
    {
      ...Styles.Bordered,
      backgroundColor: Colors.clrNimbixGray,
      whiteSpace: 'no-wrap',
      padding: 4,
      color: Colors.clrNimbixDark,
      overflowY: 'scroll',
      maxHeight: 300,
      minHeight: 300
    };
  }

  // When dialog is reopened with new app, update the state
  componentWillReceiveProps(nextProps)
  {
    this.setInputDefValues(nextProps);
  }

  // When dialog is opened first, update the state
  componentDidMount()
  {
    this.setInputDefValues(this.props);
  }

  // Forward this button click to the file chooser
  onSelectImage = () =>
  {
    this.setState({err: ''});
    this.refs.inputImg.click();
  }

  // Converts a image data URI to the format stored in the appdef {type, data}
  dataURIToImageKey(dataURI)
  {
    const arrImg = dataURI.split(';');

    // Chop off "data:" to get mimetype
    const type = arrImg[0].slice(5);

    // Chop the "base64," prefix to get data
    const data = arrImg[1].slice(7);

    return {type, data};
  }

  // Gets the data in the input controls based on arrNames and puts it in dict
  getElemData = (arrNames, dict) =>
  {
    for(const f of arrNames)
    {
      const sRefName = 'input' + f[0].toUpperCase() + f.substr(1);
      let ref = this.refs[sRefName];

      // Handle checkboxes
      if(ref.isChecked)
      {
        dict[f] = ref.isChecked();
      }
      else
      {
        const val = ref.value.trim();
        if(val) dict[f] = val;
      }
    }
  }

  onOK = () =>
  {
    // Fetch all the data from the input fields
    const dctTopLevel = {price: '0.00', appid: ''};
    this.getElemData([ 'repo', 'team', 'arch', 'src', 'appid'], dctTopLevel);

    // Verify appid
    if(!this.props.edit && !Validators['appid'](dctTopLevel.appid))
    {
      this.setState({err: ValidationErrs['appid'], tab: 'general'});
      this.refs.inputAppid.focus();
      return;
    }

    // If appdef has been loaded set it appdef else get a default one, and deep copy it
    let dctAppDef = this.state.appdef ? {...this.state.appdef} : null;
    if(!dctAppDef)
    {
      dctAppDef = dctTopLevel.repo && dctTopLevel.repo.startsWith('shub://') ? DefaultSingularityApp : DefaultJarviceApp;
      dctAppDef = JSON.parse(JSON.stringify(dctAppDef));
    }

    // fetch all the appdef data from the input fields
    this.getElemData(['name', 'author', 'description', 'licensed'], dctAppDef);

    // If name is not specified, generate it
    if(!dctAppDef.name)
    {
      dctAppDef.name = dctTopLevel.appid;
    }

    // Update the app id to include username prefix if new app
    if(!this.props.edit)
    {
      dctTopLevel.appid = Data.User.Profile.user_login + '-' + dctTopLevel.appid;
    }

    // Set the image only if this.state.image is not null
    // TODO: Resize image to 64x64 if too large
    if(this.state.image)
    {
      dctAppDef.image = this.state.image;
    }

    // If no image is specified at all, put in a default
    if(!dctAppDef.image || !dctAppDef.image.type || !dctAppDef.image.data)
    {
      dctAppDef.image = {type: 'image/png', data: ''};
    }

    // Get Classifications, put in a default one if none present
    dctAppDef.classifications = this.state.classes.length ? [...this.state.classes] : ['Uncategorized'];

    // Send the request
    let params = {...dctTopLevel, appdef: JSON.stringify(dctAppDef)};
    let saver = new DataFetcher('/portal/ptc-save', params)
    .ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Save failed'))
    .whenDone
    (
      () =>
      {
        // Invalidate image cache id and refresh the PTC apps data
        Data.Config.ImgCacheID++;
        FetchBusy(PTCAppsDataFetcher, 'Refreshing...');

        // Force the classification filter panes to refresh
        GlobalUI.PTCAppFilterPane.forceUpdate();
        GlobalUI.AppFilterPane.forceUpdate();
      }
    );

    // Send the request
    GlobalUI.Dialog.setState({open:false});
    FetchBusy(saver, 'Saving application...');
  }

  // When a file is selected
  onChangeAppImageFile = (event) =>
  {
    const file = event.target.files[0];

    // If image fails to load
    const handleError = (err)=> this.setState({err});

    if(file.size > 65536)
    {
      this.setState({err: 'Image file should be less than 64 KiB'});
      return false;
    }

    // Setup a file reader and read it
    const reader = new FileReader();
    reader.onload = (e) =>
    {
      // Create a temp image
      const img = new Image();

      // Handle failure and success
      img.onerror = () => this.setState({err: 'Image file could not be loaded'});
      img.onload = () =>
      {
        this.setState({image: this.dataURIToImageKey(e.target.result)});
      };

      // Load it
      img.src = e.target.result;
    };

    reader.onerror = (e) => handleError('Unable to load image');

    reader.readAsDataURL(event.target.files[0]);
  }

  // Sets the data in the input controls based on arrNames from dict
  setElemData = (arrNames, dict) =>
  {
    for(const f of arrNames)
    {
      const sRefName = 'input' + f[0].toUpperCase() + f.substr(1);
      let ref = this.refs[sRefName];

      // Handle checkboxes
      if(ref.setChecked)
      {
         ref.setChecked(!!dict[f]);
      }
      else
      {
        ref.value = dict[f] || '';
      }
    }
  }


  // Force a default value and readonly attributes based on whether
  // we are editing an existing app or creating a new one
  setInputDefValues(theProps)
  {
    let arch;

    if(theProps.edit)
    {
      // We use the top level data from PTCAppsData, but the appdef itself has been
      // sent in theProps.appdef (with unexpanded machine array)
      const dctApp = PTCAppsData[theProps.app];

      // Put in the values from the app dict
      this.setElemData(['repo', 'src', 'team', 'arch'], dctApp);

      // Put in the values from the appdef
      this.setElemData(['name', 'description', 'author', 'licensed'], theProps.appdef);

      // Set the classifications and the appdef data
      this.setState({classes: dctApp.data.classifications, appdef: theProps.appdef});

      // Set the app id and disallow changing it
      this.refs.inputAppid.value = theProps.app;
      this.refs.inputAppid.setAttribute('readonly', 'readonly');
      this.refs.inputArch.setAttribute('disabled', 'disabled');

      arch = dctApp.arch;
    }
    else
    {
      // Clear all the fields
      this.setElemData(['appid', 'repo', 'src', 'team', 'name', 'description', 'author', 'licensed'], {});
      this.refs.inputArch.selectedIndex = 0;
      this.refs.inputAppid.removeAttribute('readonly');
      this.refs.inputArch.removeAttribute('disabled');
      this.setState({app: '', classes: [], appdef: null, image: null});
    }

    // Reset the error and the image, set edit state
    this.setState({err: '', app: theProps.app, edit: theProps.edit, arch, tab: 'general', image: null});
  }

  // When enter is pressed on the classification field, add that
  onKeyPressClass = (evt) =>
  {
    if(evt.key === 'Enter')
    {
      const c = this.refs.inputClassifications.value.trim();
      if(c !== '' && this.state.classes.indexOf(c) === -1)
      {
        const classes = [...this.state.classes, c];

        // Get rid of "Uncategorized"
        const idx = classes.indexOf('Uncategorized');
        if(idx !== -1) classes.splice(idx, 1);
        this.setState({classes})
      }

      this.refs.inputClassifications.value = '';
    }
  }

  // Classification chip delete btn handler
  onDelClass = (idx) =>
  {
    const classes = [...this.state.classes];
    classes.splice(idx, 1);
    this.setState({classes});
  }

  onChangeTab = tab => this.setState({tab});

  onClickSave = () =>
  {
    this.refs.downloadLink.download = this.props.app + '.json';
    this.refs.downloadLink.href = 'data:,' + JSON.stringify(this.state.appdef, null, 4);
    this.refs.downloadLink.click();
  }

  // Open the file chooser
  onClickLoad = () => this.refs.fileChooserLoad.open();

  // File only contains the data key
  onReadAppDef = (sData) =>
  {
    try
    {
      const appdef = JSON.parse(sData);

      // Basic sanity check
      const requiredFields = ['name', 'author', 'classifications'];
      for(const f of requiredFields)
      {
        if(!(f in appdef)) throw f;
      }

      // Put in the values from the appdef
      this.setElemData(['name', 'description', 'author', 'licensed'], appdef);

      // Set the classifications and the appdef data
      this.setState({classes : appdef.classifications, appdef});

      // Set the image
      if(appdef.image && appdef.image.type && appdef.image.data)
      {
        this.setState({image : appdef.image});
      }
    }
    catch(e)
    {
      GlobalUI.DialogConfirm.show('Error', 'App definition JSON is invalid or malformed', false, LayoutDims.wContent * 0.5);
    }
  }

  render()
  {
    const arrElemOpts = [];
    const ma = Data.MachineArchs;
    for(let i of Object.keys(ma.arch))
    {
      let sel = {};
      if(this.props.edit && this.state.arch === ma.arch[i])
      {
        sel = {selected: 'selected'};
      }

      arrElemOpts.push
      (
        // key=i is fine here because i is a unique string
        <option {...sel} value={ma.arch[i]} key={i}>
          {`${ma.archdesc[i]} (${ma.arch[i]})`}
        </option>
      );
    }

    const styleInput = {...Styles.ParamInput, ...Styles.Bordered, width: '60%'};
    const styleInputGrayIfRO = {...styleInput};
    if(this.state.edit)
    {
      styleInputGrayIfRO.backgroundColor = Colors.clrNimbixGray;
    }

    let sImgSrc;

    // First check for user overridden image - if not use the apps image
    if(!this.state.image)
    {
      sImgSrc = GetAppImgSrc(this.state.app, false)
    }
    // Else only assign if there is an actual image
    else if(this.state.image.data)
    {
      sImgSrc = GetImgSrc(this.state.image.type, this.state.image.data);
    }

    return (
      <div style={{margin: LayoutDims.nMargin}}>

        <Tabs ref='tabs' onChange={this.onChangeTab} value={this.state.tab}>
          <Tab label='General' value='general'>

            <Separator units={1.5}/>

            <div style={{...Styles.Inline, alignItems: 'flex-start'}}>

              <div style={{flex: 1}}>
                <InputWrapper label='App ID:'>
                  <input data-cy='inputAppid' style={styleInputGrayIfRO} ref='inputAppid' name='inputAppid'/>
                </InputWrapper>

                <InputWrapper label='Docker Repository:'>
                  <input data-cy='inputRepo' style={styleInput} ref='inputRepo' name='inputRepo'/>
                </InputWrapper>

                <InputWrapper label='Git Source URL (to Clone) - required only for building:'>
                  <input data-cy='inputSrc' style={styleInput} ref='inputSrc' name='inputSrc'/>
                </InputWrapper>

                <InputWrapper label='System Architecture:'>
                  <select data-cy='inputArch' style={styleInputGrayIfRO} ref='inputArch' name='inputArch'>
                    {arrElemOpts}
                  </select>
                </InputWrapper>

                <Separator units={1}/>
                <Checkbox ref='inputTeam' label='Team Visible'
                          labelStyle={{color: Colors.clrNimbixDark, fontSize: 16, marginLeft: -8}}/>
                <p>
                  <i>
                    "Team Visible" setting applies only to private applications.
                    If the application is public, this setting is ignored, since public
                    applications cannot be hidden from team members.
                  </i>
                </p>

              </div>

              <div style={{...Styles.InlineFlexCol, alignItems: 'baseline'}}>
                <img ref='img'
                     role='presentation'
                     src={sImgSrc}
                     onError={()=>this.refs.img.src = Images.DefaultIcon}
                     style={{width: LayoutDims.nIconSize}}/>

                <Separator units={1}/>
                <RaisedButton {...Btns.Green} ref='btnImg' label='Change Icon' onClick={this.onSelectImage}/>
              </div>

            </div>

            <input type="file" accept="image/*" ref='inputImg' className='hide' onChange={this.onChangeAppImageFile}/>

          </Tab>

          <Tab label='Details' value='details'>

            <Separator units={1.5}/>

            <div style={{...Styles.Inline, flexFlow: 'column', alignItems: 'stretch'}}>
              <InputWrapper label='Name:'>
                <input style={styleInput} ref='inputName' name='inputName'/>
              </InputWrapper>

              <InputWrapper label='Description:'>
                <input style={styleInput} ref='inputDescription' name='inputDescription'/>
              </InputWrapper>

              <InputWrapper label='Author:'>
                <input style={styleInput} ref='inputAuthor' name='inputAuthor'/>
              </InputWrapper>

              <datalist id='appClasses'>
                {GetAppClasses().map((s,i)=><option key={i}>{s}</option>)}
              </datalist>

              <InputWrapper label='Classifications:'>
                <input name='inputClassifications'
                       placeholder='Type a classification tag and press Enter to add'
                       list='appClasses'
                       style={styleInput}
                       onKeyPress={this.onKeyPressClass}
                       ref='inputClassifications'/>
              </InputWrapper>

              <div style={Styles.Inline}>
              {
                this.state.classes.map
                (
                  (c, i) =>
                    <Chip backgroundColor={Colors.clrNimbixDark}
                          labelColor={Colors.clrLight}
                          onRequestDelete={()=>this.onDelClass(i)}
                          style={{margin: 4, fontSize: 12}}
                          deleteIconStyle={{fill: Colors.clrLight}}
                          key={i}>{c}
                    </Chip>
                )
              }
              </div>

              <Separator units={2}/>

              <Checkbox ref='inputLicensed' label='Licensed'
                        labelStyle={{color: Colors.clrNimbixDark, fontSize: 16, marginLeft: -8}}/>

              <Separator units={1}/>

            </div>
          </Tab>

          <Tab label='Appdef' value='appdef'>
            <div>
              <Separator units={2}/>
              <div style={Styles.Inline}>
                <RaisedButton data-cy='btnPtcLoadAppdef' {...Btns.Blue} label='Load From File' onClick={this.onClickLoad}/>
                &nbsp;&nbsp;
                <ShowOnly if={this.props.edit}>
                  <RaisedButton data-cy='btnPtcSaveAppdef' {...Btns.Green} label='Save To File' onClick={this.onClickSave}/>
                </ShowOnly>
                <a ref='downloadLink' />
              </div>

              <p>
                Note that the values loaded from the file here will override the data entered in the Details tab as well as the app icon
              </p>

              <pre data-cy='preAppdef' style={this.stylePre}>
                {this.state.appdef ? JSON.stringify(this.state.appdef, null, 4) : ''}
              </pre>
            </div>
          </Tab>

        </Tabs>

        <div className='errortext' style={{fontSize: 14, marginBottom: 2}}>&nbsp;{this.state.err}</div>

        <div style={Styles.FlexSpread}>
          &nbsp;
          <RaisedButton {...Btns.Blue} label='OK' onClick={this.onOK}/>
        </div>

        <FileChooser ref='fileChooserLoad' asURL={false} onRead={this.onReadAppDef} filter='.json' />

      </div>
    );
  }
}

