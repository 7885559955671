// Core
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

// Our UI data, styles and colors
import {Styles, Btns} from './UIConst.js';
import {InputRow, Separator} from './Components'

import {Data, DataFetcher} from '../Models/Model';
import {GlobalUI, Validators, FetchBusy} from '../Global'


// map of provider names and email domains
const SMSProviders =
{
  'txt.att.net': 'AT&T',
  'messaging.sprintpcs.com': 'Sprint',
  'vtext.com': 'Verizon',
  'tmomail.net': 'T-Mobile'
};

// Options list for SMS providers dropdown
const SMSProviderOpts = Object.keys(SMSProviders).map
(
  (s) => <option key={s} value={s}>{SMSProviders[s]}</option>
);

// Add email notify dialog content
class AddEmailDlg extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {email: ''};
  }

  onChange = () => this.setState({email: this.refs.inputEmail.value});
  onAdd = () => {GlobalUI.Dialog.onClose(); this.props.onAdd(this.state.email)};

  render()
  {
    // Validate email using regex and also based on whether it already exists in the list
    const isValid = this.props.existing.indexOf(this.state.email) === -1 &&
                    Validators['user_email'](this.state.email);
    return (
      <div>
        <InputRow title='Email to notify on:'>
          <input name='email' ref='inputEmail' onChange={this.onChange} style={Styles.ParamInputWide}/>
        </InputRow>

        <br/>

        <div style={Styles.FlexSpread}>
          <RaisedButton label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton label='Add' {...Btns.Blue} disabled={!isValid} onClick={this.onAdd}/>
        </div>
      </div>
    );
  }
}

// Add mobile number notify dialog content
class AddSMSDlg extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {sms: '', num: ''};
  }

  // If either field changed, generate the full sms email address
  onChange = () =>
  {
    const provider = this.refs.inputProvider.value;
    const num = this.refs.inputNumber.value;
    this.setState({num, sms: num + (provider && ('@' + provider))});
  }

  // If Add clicked, close dialog and pass the event to the parent
  onAdd = () =>{GlobalUI.Dialog.onClose(); this.props.onAdd(this.state.sms)};

  render()
  {
    // Number should be non null and not already existing in the list
    const isValid = this.props.existing.indexOf(this.state.sms) === -1 && this.state.num;
    const inputProps = {onChange:this.onChange, style: Styles.ParamInputWide};
    return (
      <div>
        <InputRow title='Mobile Number:'>
          <input name='number' ref='inputNumber' {...inputProps}/>
        </InputRow>

        <InputRow title='Provider:'>
          <select name='provider' ref='inputProvider' {...inputProps}>
            {SMSProviderOpts}
            <option value=''>Custom</option>
          </select>
        </InputRow>

        <br/>

        <div style={Styles.FlexSpread}>
          <RaisedButton label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton label='Add' {...Btns.Blue} disabled={!isValid} onClick={this.onAdd}/>
        </div>

      </div>
    );
  }
}

// Notification settings UI
export class SettingsNotify extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      editing: false,
      emails: this.getEmailsFromProfile(),
      sms: this.getSMSFromProfile(),
      selectedEmail: '',
      selectedSMS: ''
    };
  }

  // Extract the CSV list of sms from profile data
  getSMSFromProfile= () =>
  {
    let sms = Data.User.Profile.user_notify_texts;
    return (sms && sms.split(',')) || [];
  }

  // Extract the CSV list of emails from profile data
  getEmailsFromProfile  = () =>
  {
    let emails = Data.User.Profile.user_notify_emails;
    return (emails && emails.split(',')) || [];
  }

  // Update edit state based on parent
  componentWillReceiveProps(nextProps)
  {
    this.setState({editing: nextProps.editing});
  }

  // On save send the request and set to non-edited state if successful
  trySave = (parent) =>
  {
    let userdata =
    {
      user_notify_emails: this.state.emails.join(','),
      user_notify_texts: this.state.sms.join(',')
    };

    // convert to JSON and make the request
    userdata = JSON.stringify(userdata);
    const fetcher = new DataFetcher('/portal/user-save-settings-profile', {userdata});
    fetcher.whenDone
    (
      // On success, update our local store
      ()=>
      {
        Data.User.Profile.user_notify_emails = this.state.emails.join(',');
        Data.User.Profile.user_notify_texts = this.state.sms.join(',');

        // Let the parent pane know the save is successful
        parent.saved(this);
      }
    );

    // On failure, show the error
    fetcher.ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Failed to update profile'));

    // Send the request with a spinner
    FetchBusy(fetcher, 'Saving...');
  }

  // If canceled, just reset all fields to original values
  cancel()
  {
    this.setState
    (
      {
        emails: this.getEmailsFromProfile(),
        sms: this.getSMSFromProfile(),
        editing: false
      }
    );
  }

  // Remove an entry
  removeEmail = () => this.setState
  (
    {
      emails: this.state.emails.filter((x) => x !== this.state.selectedEmail),
      selectedEmail: ''
    }
  );

  removeSMS = () => this.setState
  (
    {
      sms: this.state.sms.filter((x) => x !== this.state.selectedSMS),
      selectedSMS: ''
    }
  );

  // add an entry
  addEmail = (e) => this.setState({emails: this.state.emails.concat(e)});
  addSMS = (e) => this.setState({sms: this.state.sms.concat(e)});

  // Button click handlers
  onClickAddEmail = () =>
    GlobalUI.Dialog.show('Add Email', <AddEmailDlg existing={this.state.emails} onAdd={this.addEmail}/>);

  onClickAddSMS = () =>
    GlobalUI.Dialog.show('Add Mobile Number', <AddSMSDlg existing={this.state.sms} onAdd={this.addSMS}/>);

  render()
  {
    return (
      <div>
        <div style={Styles.Inline}>
          <RaisedButton label='Add' {...Btns.Green} onClick={this.onClickAddEmail}  disabled={!this.state.editing} />
          &nbsp;&nbsp;&nbsp;
          <RaisedButton label='Remove' disabled={!(this.state.editing && this.state.selectedEmail)}
                        onClick={this.removeEmail}/>
        </div>

        <Separator units={1}/>

        <table style={{...Styles.Table, tableLayout:'fixed', width:'100%', cursor: 'default'}}>
          <thead>
          <tr>
            <th style={Styles.TableHeader}>Email</th>
          </tr>
          </thead>

          <tbody>
          {
            this.state.emails.map
            (
              (e) =>
              {
                const isSel = this.state.selectedEmail === e && this.state.editing;
                const styleTR = isSel ? Styles.TRSel : Styles.TRDesel;
                const styleTD = isSel ? Styles.TDSel : Styles.TDDesel;
                return (
                  <tr style={styleTR} key={'email-' + e} onClick={()=>this.setState({selectedEmail: e})}>
                    <td style={styleTD}>{e}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>

        <Separator units={6}/>

        <div style={Styles.Inline}>
          <RaisedButton label='Add' {...Btns.Green} onClick={this.onClickAddSMS}  disabled={!this.state.editing} />
          &nbsp;&nbsp;&nbsp;
          <RaisedButton label='Remove' disabled={!(this.state.editing && this.state.selectedSMS)}
                        onClick={this.removeSMS} />
        </div>

        <Separator units={1}/>

        <table style={{...Styles.Table, tableLayout:'fixed', width:'100%', cursor: 'default'}}>
          <thead>
            <tr>
              <th style={Styles.TableHeader}>Mobile Number</th>
              <th style={Styles.TableHeader}>Provider</th>
            </tr>
          </thead>

          <tbody>
          {
            this.state.sms.map
            (
              (e) =>
              {
                const isSel = this.state.selectedSMS === e && this.state.editing;
                const styleTR = isSel ? Styles.TRSel : Styles.TRDesel;
                const styleTD = isSel ? Styles.TDSel : Styles.TDDesel;

                const arrNum = e.split('@');
                const sProvider = SMSProviders[arrNum[1]];
                const sNum = sProvider ? arrNum[0] : e;
                return (
                  <tr style={styleTR} key={'sms-' + e} onClick={()=>this.setState({selectedSMS: e})}>
                    <td style={styleTD}>{sNum}</td>
                    <td style={styleTD}>{sProvider}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>

        <br/>
      </div>
    );
  }
}
