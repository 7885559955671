// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Our custom controls
import {MUI, SectionView} from './Components.js';
import Paper from 'material-ui/Paper';
import AvLibraryAdd from 'material-ui/svg-icons/av/library-add';

// Our UI data, styles and colors
import {Styles, LayoutDims, Colors} from './UIConst.js';
import {GlobalUI} from '../Global';
import {AppEntry} from './AppEntry';

import PTCEditDlg from './PTCEditDlg';
import PTCHistoryDlg from './PTCHistoryDlg';

// Collapsible app view, with filtering
export default class AppView extends Component
{
  static propTypes =
  {
    headerText: PropTypes.string,
    noCollapse: PropTypes.bool,

    onClickAppEntry: PropTypes.func,

    // Style for the content pane of the section view
    stylePane: PropTypes.any,

    // What kind of app entries these are - ptc, recent or "" for compute
    entryType: PropTypes.string,
  };

  constructor(props)
  {
    super(props);
    this.state =
    {
      // List of names of apps to show
      arrApps: props.apps || [],

      // Header text to display
      headerText: props.headerText,
    };
  }

  onNewApp = () =>
  {
    const elems = <PTCEditDlg edit={false} app={this.props.app} />
    GlobalUI.Dialog.show('Create Application', elems);
  }

  // Display the app card / task builder for an app when its clicked on compute and recent apps
  onClickEntry = (sAppName) => GlobalUI.PaneAppCard.openWithApp(sAppName, this.props.entryType === 'ptc');


  render()
  {
    // No data, dont render anything
    if(!this.state.arrApps) return null;

    let arrAppEntries = this.state.arrApps.map
    (
      (sAppName) =>
        <AppEntry entryType={this.props.entryType} parent={this} key={sAppName} app={sAppName}
                  ref={sAppName} onClick={this.onClickEntry}/>
    );

    // New App entry
    if(this.props.entryType === 'ptc')
    {
      const newApp =
        <Paper key={'*new'} data-cy='ptcNew' onClick={this.onNewApp} zDepth={1} style={{...AppEntry.stylePaper, display: 'block'}}>
          <div>
            <AvLibraryAdd color={Colors.clrNimbixDark} style={{width:LayoutDims.nMargin * 4}} />
          </div>
          <div style={{...Styles.FullCenter, height: '50%'}}>
            <div style={{color: Colors.clrNimbixDark, fontSize: LayoutDims.nMargin * 3}}>New</div>
          </div>
        </Paper>

      arrAppEntries = [newApp].concat(arrAppEntries);
    }

    // Calculate available space and adjust so
    // integral number of rows are visible
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 192);
    hAvail = (hAvail / LayoutDims.AppEntryRow)|0;
    hAvail *= LayoutDims.AppEntryRow;
    hAvail += LayoutDims.nMargin;

    return MUI(
      <div>
        <SectionView collapsedSize={hAvail} showLine noCollapse={this.props.noCollapse}
                     headerText={this.state.headerText} children={arrAppEntries}
                     stylePane={this.props.stylePane} sectionName={this.props.entryType || 'App'}/>

        <PTCHistoryDlg ref='ptcHistoryDlg'/>

      </div>
    );
  }
}

