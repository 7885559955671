// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Our UI data, styles and colors
import {Styles, LayoutDims} from './UIConst.js';
import {TableFixedHeaderTab, ParamColGroup} from './Components';
import {TaskBuilderParameter} from './TaskBuilderParameter';
import {TaskBuilderParamRow} from './TaskBuilderParamRow';
import {TaskBuilderParamSlider} from './TaskBuilderParamSlider';

import {Data, PriceStr} from '../Models/Model'

import {pluralize} from './Utils';

// Task builder options pane for machine params
export class TaskBuilderPaneMachine extends Component
{
  constructor(props)
  {
    super(props);

    // Get the machines from the app-command or else the machines in the app
    const dctAppData = props.app.data;
    this.arrMCName = dctAppData.commands[props.cmd].machines || dctAppData.machines;
    this.arrMCDesc = this.arrMCName.map
    (
      (m) =>
      {
        const mc = Data.Machines[m];
        return mc ? `${mc.mc_description} (${m})` : m;
      }
    );

    // Use nodes if hints has SCALE_NODES on app scope
    // Override if command scope has SCALE_CORES
    const dctCmd = dctAppData.commands[props.cmd];
    this.bUseNodes = props.app.data.hints && props.app.data.hints.indexOf('SCALE_NODES') >= 0;

    // If app has the SCALE_NODES override if cmd has SCALE_CORES
    if(this.bUseNodes && dctCmd.hints && dctCmd.hints.indexOf('SCALE_CORES') >= 0)
    {
      this.bUseNodes = false;
    }
    // App has default cores scaling, see if command overrides it
    else if(!this.bUseNodes)
    {
      this.bUseNodes = dctCmd.hints && dctCmd.hints.indexOf('SCALE_NODES') >= 0;
    }

    // Put in a default machine
    const sMCDef = this.arrMCName[0];
    this.state = this.getMachineData(sMCDef === '' ? this.arrMCName[1] : sMCDef);
  }

  getMachineData = (sMachineName) =>
  {
    //console.log('Getting machines for ' + this.props.app.id + ': ' + this.props.cmd);
    const dctMC = Data.Machines[sMachineName];

    function override_scale_max(scale_max_orig, scale_max_override)
    {
      if(scale_max_override && scale_max_override < scale_max_orig)
      {
        return scale_max_override;
      }
      return scale_max_orig;
    }

    // Check if appdef has a scale_max that overrides the machines, as long as it's smaller
    // Then, check if app command scope scale_max overrides scale_max with a smaller value
    let scale_max = override_scale_max(dctMC.mc_scale_max, this.props.app.data.scale_max);
    scale_max = override_scale_max(scale_max, this.props.app.data.commands[this.props.cmd].scale_max);

    const nMultiplier = this.bUseNodes ? 1 : dctMC.mc_cores;
    return {
      machine: sMachineName,
      max_cores: scale_max * nMultiplier,
      min_cores: dctMC.mc_scale_min * nMultiplier,
      cores: dctMC.mc_scale_min * nMultiplier,
      mc_price: dctMC.mc_price,
      cores_per_node: nMultiplier
    };
  };

  // Retrieve the data in the machines option pane
  getParams = () =>
  {
    // Decide if we use core counts or node counts
    const nDivisor = this.bUseNodes ? 1 : this.state.cores_per_node;
    return (
      {
        type: this.paramMC.getValue(),
        nodes: this.paramMCCores.getValue() / nDivisor,
      }
    );
  };

  // Set machine type and nodes
  setParams = (dctParams) =>
  {
    // Set the machines dropdown value
    this.paramMC.setValue(dctParams.type);

    // Get the machine specs
    const dctMC = this.getMachineData(dctParams.type);

    // Set the slider max/min/step and force a rerender
    this.setState(dctMC);

    // Set the sliders value (HTML range will accept out of range values)
    // nodes == 0 means do nothing
    // Take into account of were using node or core count
    const nMultiplier = this.bUseNodes ? 1 : dctMC.cores_per_node;
    if(dctParams.nodes)
    {
      const cores = dctParams.nodes * nMultiplier
      this.paramMCCores.setValue(cores);
      this.setState({cores});
    }
  };

  componentDidMount()
  {
    this.onMCChange();
  }

  // On machine drop down change, set the slider
  onMCChange = () =>
  {
    // 0 nodes means minimum
    this.setParams({type: this.paramMC.getValue(), nodes: 0})
  };

  // If slider changes render with new price
  onCoresChange = (cores) =>
  {
    this.setState({cores});
  };

  render()
  {
    const nCoresPerNode = this.state.cores_per_node;

    // price is nodes times (machine price + app price)
    const nNodes = (this.state.cores / nCoresPerNode) | 0;
    const fTotal = nNodes * (parseFloat(this.state.mc_price) + parseFloat(this.props.app.price));

    // If the front end hint is node rather than core, show cores.
    let sResourceHint = this.bUseNodes ?
      pluralize('core', this.state.cores) + ', '
    :
      pluralize('node', nNodes) + ', ';

    // Show RAM and GPU if it has any
    const mc = Data.Machines[this.state.machine];
    sResourceHint += (mc.mc_ram * nNodes) + 'GB RAM';

    if(mc.mc_gpus)
    {
      sResourceHint += ', ' + pluralize('GPU', mc.mc_gpus + (nNodes - 1) * mc.mc_slave_gpus);
    }

    const sName = this.bUseNodes ? 'Nodes' : 'Cores';

    const dctPropSlider =
    {
      min: this.state.min_cores,
      max: this.state.max_cores,
      step: this.state.cores_per_node,
      onChange: this.onCoresChange,
      ref: el=>this.paramMCCores = el,
      paramName: sName,
    };

    // Slider and value next to it
    const elemField =
      <div style={Styles.Inline}>
        <TaskBuilderParamSlider styleOuter={{flex: 1}} extra={64} {...dctPropSlider}/>
        <div style={{fontSize: 14, marginBottom: LayoutDims.nMargin * 2}}>
          <b>{fTotal > 0 ?  PriceStr(fTotal) + '/hr' : ''}</b>
        </div>
      </div>

    // Properties for SELECTION Parameter
    const dctPropParam =
    {
      name: 'Machine type',
      type: 'SELECTION',
      values: this.arrMCDesc,
      mvalues: this.arrMCName,
      hasSeparator: true,
    };

    const elemDesc = <div style={{marginLeft: -LayoutDims.nMargin * 8, width:'100%', textAlign: 'center'}}>{sResourceHint}</div>;


    return (
      <div>
        <TableFixedHeaderTab title='Machine'/>
        <div>
          <table style={Styles.Table}>
            <ParamColGroup/>

            <tbody>
              <TaskBuilderParameter onChange={this.onMCChange} ref={el=>this.paramMC = el} {...dctPropParam}/>
              <TaskBuilderParamRow paramName={sName}
                                   description={elemDesc}
                                   name={sName}
                                   elem={elemField}
                                   padding='16px 16px 0px 16px'/>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}




TaskBuilderPaneMachine.propTypes =
{
  app: PropTypes.object.isRequired,
  cmd: PropTypes.string.isRequired,
};


