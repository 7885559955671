// Core
import React, {Component} from 'react';

import {ReportDisplay} from './Components.js';
import {TeamJobsFetcher} from '../Models/Model';
import {TeamJobs, getTeamJobDataRows} from './TeamJobs';


// Displays the same data as TeamJobs, without the terminate button
export class SettingsSummaryTeamJobs extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {jobsData: null};
    TeamJobsFetcher.whenDone(()=>this.setState({jobsData: TeamJobsFetcher.data}));
  }

  render()
  {
    return (
      this.state.jobsData && Object.keys(this.state.jobsData).length
      ?
        <ReportDisplay colText={TeamJobs.arrColText}
                       colFields={TeamJobs.arrCol}
                       colTypes={TeamJobs.dctColTypes}
                       data={getTeamJobDataRows(this.state.jobsData)}/>
      :
        null
    );
  }
}

