// Core
import React, {Component} from 'react';

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

import {ReportDisplay, SwitchedPane, fromThis} from './Components.js';
import {Styles, Colors} from './UIConst.js';
import {Data, TeamJobsFetcher} from '../Models/Model';
import {Spinner} from '../Global';

export class SettingsSummaryJobStats extends Component
{
  // The table column titles and the corresponding keys in the data dict
  static arrColFieldsUser = ['user_login', 'job_count', 'mc_cores', 'mc_gpus'];
  static arrColTextUser = ['Username', 'Jobs', 'CPUs', 'GPUs'];
  static arrColFieldsApp = ['app', 'job_count', 'mc_cores', 'mc_gpus'];
  static arrColTextApp = ['Application', 'Jobs', 'CPUs', 'GPUs'];

  constructor(props)
  {
    super(props);
    this.state = {jobsActiveData: null, viewType: 'user'};

    // Make the data refresh when this pane is switched to
    // TODO - wont switch if moving between top level items via right hand drawer menu
    SwitchedPane.RegisterOnSwitch('SettingsContent/Summary', this.refreshData);
    TeamJobsFetcher.whenDone(()=>this.setState({jobsActiveData: TeamJobsFetcher.data}));

  }

  // Fetch both active and all jobs
  refreshData = () =>
  {
    this.setState({jobsActiveData: null});
    TeamJobsFetcher.fetch();
  }

  // gets jobs on a per user basis
  getDataRowsUser = (jobsData) =>
  {
    const ret = {};

    // Iterate over each job, sum up nodes, cores and GOU cores per user
    for(const jobnum of Object.keys(jobsData))
    {
      const data = jobsData[jobnum];
      const user = data.job_owner_username;
      const mc = (data.job_api_submission && data.job_api_submission.machine) || null;

      // First time
      if(!ret[user])
      {
        ret[user] = {job_count: 0, mc_cores: 0, mc_gpus: 0};
      }

      const dctMC = mc && mc.type && Data.Machines[mc.type];
      if(dctMC)
      {
        ret[user].mc_cores += Data.Machines[mc.type].mc_cores * mc.nodes;
        ret[user].mc_gpus += Data.Machines[mc.type].mc_gpus * mc.nodes;
      }
      ret[user].job_count++;
    }

    return Object.keys(ret).map
    (
      (user_login) =>
      {
        return {
          ...fromThis(ret[user_login], ['job_count', 'mc_cores', 'mc_gpus']),
          user_login,
        };
      }
    );
  }

  // gets jobs on a per app basis
  getDataRowsApp = (jobsData) =>
  {
    const ret = {};

    // Iterate over each job, sum up nodes, cores and GOU cores per user
    for(const jobnum of Object.keys(jobsData))
    {
      const data = jobsData[jobnum];
      const app = data.job_application;
      const mc = (data.job_api_submission && data.job_api_submission.machine) || null;

      // First time
      if(!ret[app])
      {
        ret[app] = {job_count: 0, mc_cores: 0, mc_gpus: 0};
      }


      const dctMC = mc && mc.type && Data.Machines[mc.type];
      if(dctMC)
      {
        ret[app].mc_cores += Data.Machines[mc.type].mc_cores * mc.nodes;
        ret[app].mc_gpus += Data.Machines[mc.type].mc_gpus * mc.nodes;
      }
      ret[app].job_count++;
    }

    return Object.keys(ret).map
    (
      (app) =>
      {
        return {
          ...fromThis(ret[app], ['job_count', 'mc_cores', 'mc_gpus']),
          app,
        };
      }
    );
  }

  // totals up the job data
  getSummary = (rows) =>
  {
    const dctSummary = ['Total', 0, 0, 0];
    for(const row of rows)
    {
      dctSummary[1] += row['job_count'];
      dctSummary[2] += row['mc_cores'];
      dctSummary[3] += row['mc_gpus'];
    }

    return dctSummary;
  }

  render()
  {
    // Check if we have data
    const bHasData = this.state.jobsActiveData && Object.keys(this.state.jobsActiveData).length;
    let arrData, arrFields, arrText, arrSummary;

    // Setup the rows based on which view is active
    if(bHasData)
    {
      if(this.state.viewType === 'user')
      {
        arrData = this.getDataRowsUser(this.state.jobsActiveData);
        arrText = SettingsSummaryJobStats.arrColTextUser;
        arrFields = SettingsSummaryJobStats.arrColFieldsUser;
      }
      else
      {
        arrData = this.getDataRowsApp(this.state.jobsActiveData);
        arrText = SettingsSummaryJobStats.arrColTextApp;
        arrFields = SettingsSummaryJobStats.arrColFieldsApp;
      }

      arrSummary = this.getSummary(arrData);
    }

    return (
      <div>
        {
          bHasData
          ?
          <div>
            <RadioButtonGroup onChange={(e, viewType)=>this.setState({viewType})}
                              ref='radioGroupView'
                              name='jobStatsView'
                              defaultSelected='user'
                              style={{...Styles.InlineFlexRow, width: '40%'}}>
              <RadioButton value='user' label='By User' style={Styles.Radio} />
              <RadioButton value='app' label='By App' style={Styles.Radio} />
            </RadioButtonGroup>

            <ReportDisplay colText={arrText} colFields={arrFields} data={arrData} summary={arrSummary}/>

          </div>
          :
          (
            // Spinner is shown only if data was null (as opposed to empty)
            !this.state.jobsActiveData &&
            <Spinner style={Styles.Full} textColor={Colors.clrNimbixDark} size={48} fontSize={16}
                     status='Fetching...'/>
          )
        }
      </div>
    );
  }
}
