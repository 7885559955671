import React, {Component} from 'react';

import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import DatePicker from 'material-ui/DatePicker';

import {HLine, Separator, ReportDisplay, RawDataDownloader} from './Components';
import {Styles, LayoutDims, Btns} from './UIConst';
import {FetchBusy} from '../Global';
import {DataFetcher, PriceStr} from '../Models/Model';
import {Checkbox} from 'material-ui';


export default class AdminMCStats extends Component
{
  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['machine', 'total_jobs', 'average_nodes_str', 'average_price_str', 'total_ctime', 'average_queue', 'max_queue', 'total_price_str'];
  static arrColNames = ['Machine',	'Total Jobs',	'Avg. Nodes',	'Avg. Price',	'Total Compute Time',	'Avg. Queue Time', 'Max Queue Time', 'Total Price'];

  // Ensure that the given columns are sorted numerically
  static dctColTypes =
  {
    total_jobs: 'number',
    average_nodes_str: 'average_nodes', // Sort this column using average_nodes as the proxy
    average_price: 'number',
    total_ctime: 'time',
    average_queue: 'time',
    max_queue: 'time',
    total_price: 'number',
    average_price_str : 'average_price', // Sort this column using average_price as the proxy
    total_price_str : 'total_price', // Sort this column using total_price as the proxy
  };

  static styleInput = {...Styles.ParamInput, maxWidth: 200};

  constructor(props)
  {
    super(props);
    this.state = {data: null, timeperiod: 'current', startHour: 0, endHour: 24};
  }

  // Fire off request to server
  onRefresh = () =>
  {
    const data = {timeperiod: this.state.timeperiod};
    if(data.timeperiod === 'range')
    {
      data.startdate = this.state.startdate.toISOString().substring(0, 10);
      data.enddate = this.state.enddate.toISOString().substring(0, 10);
    }

    // If an hour range was specified, pass it on
    if(this.state.showTimeRange && (this.state.startHour > 0 || this.state.endHour < 24))
    {
      data.starthour = this.state.startHour;
      data.endhour = this.state.endHour;
    }

    const fetcher = new DataFetcher('/portal/admin-machine-stats', data);
    fetcher.whenDone(()=>this.setState({data: fetcher.data}));
    FetchBusy(fetcher, 'Fetching Machine Stats...');
  }

  // Download CSV
  onDownload = () =>
  {
    // Note - machine column values are added manually below
    const arrMCFields =
      ['total_jobs', 'average_nodes', 'average_price', 'total_ctime',
       'average_queue', 'average_inf_queue', 'average_res_queue',
       'average_lic_queue', 'max_queue', 'total_price'];

    let sCSV =
      '"Machine", "Total Jobs", "Average Nodes", "Average Price", "Total Compute Time", ' +
      '"Average Queue Time (total)", "Average Queue Time (infrastructure)", ' +
      '"Average Queue Time (limits)", "Average Queue Time (license)", "Max Queue Time", ' +
      '"Total Price"\n';

    // get machine name and sort
    const arrMCNames = Object.keys(this.state.data);
    arrMCNames.sort();

    // For each machine append the table row HTML, create the CSV data
    for(const sMCName of arrMCNames)
    {
      sCSV += `"${sMCName}", `;
      for(const sField of arrMCFields)
      {
        sCSV += this.state.data[sMCName][sField] + ',';
      }
      sCSV += '\n';
    }

    // Save the local data as a download
    this.refs.downloader.save(sCSV);
  }

  onChangeTimePeriod = (e, timeperiod) =>
  {
    this.setState({timeperiod, data: null});
  }

  onChangeStartHour = e =>
  {
    // Restrict the start hour to within 1 before the stop
    const n = e.target.value;
    if(this.state.endHour - n > 0)
    {
      this.setState({startHour: n})
    }
  }

  onChangeEndHour = e =>
  {
    // Restrict the stop hour to within 1 after the start
    const n = e.target.value;
    if(n - this.state.startHour > 0)
    {
      this.setState({endHour: n})
    }
  }

  render()
  {
    const dateFormatter = new Intl.DateTimeFormat
    (
      'en-US',
      {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }
    ).format;

    const dateProps =
    {
      formatDate: dateFormatter,
      dialogContainerStyle: {backgroundColor: '#EEEEEE'},
      autoOk:true,
      container:"inline"
    }

    const fnFormatNodesAvg = avg => (avg - (avg|0)) > 0 ? Number(avg).toFixed(2) : avg|0;

    // Use as much height as possible
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 400);

    // Prepare the data ( this.state.data can be null )
    let data = this.state.data;
    const arrData = data && Object.keys(data).map
    (
      (mc)=>
        (
          {
            ...data[mc],
            machine: mc,
            average_price_str: PriceStr(data[mc].average_price),
            total_price_str: PriceStr(data[mc].total_price),
            average_nodes_str: fnFormatNodesAvg(data[mc].average_nodes),
          }
        )
    );

    // Is it a date range
    const isRanged = this.state.timeperiod === 'range';
    const hasRange = !isRanged || (this.state.enddate && this.state.startdate);

    return (
      <div>

        <div style={{margin: LayoutDims.nMargin * 2}}>
          <p>Choose Time Period</p>
          <HLine margin={4}/>
          <br/>
          <RadioButtonGroup onChange={this.onChangeTimePeriod} ref="timePeriod" name="timePeriod" defaultSelected="current">
            <RadioButton value="current" label="Current Month" style={Styles.Radio} />
            <RadioButton value="last" label="Previous Month" style={Styles.Radio} />
            <RadioButton value="range" label="Custom Date Range" style={Styles.Radio} />
          </RadioButtonGroup>

          {
            isRanged &&
            <div style={{...Styles.Inline}}>
              <DatePicker {...dateProps} onChange={(e, startdate)=>this.setState({startdate})}
                          ref='startDate' hintText="Start date" maxDate={this.state.endDate} value={this.state.startdate} />
              &nbsp;&nbsp;to&nbsp;&nbsp;
              <DatePicker {...dateProps} onChange={(e, enddate)=>this.setState({enddate})}
                          ref='endDate' hintText="End date" minDate={this.state.startdate} value={this.state.enddate} />
            </div>
          }

          <Separator/>
          <div style={{...Styles.InlineFlexRow, width:'80%', height: '24px'}}>
            <Checkbox label='Specify Time Range in Hours'
                      title='Restrict the statistics calculation to a specific part of the day'
                      onCheck={(e, v)=>this.setState({showTimeRange: v})}/>
            {
              this.state.showTimeRange &&
              <div style={Styles.Inline}>
                <input type="number" style={{...Styles.ParamInput, width: 25, flex: 0}} min='0' max='23' value={this.state.startHour} onChange={this.onChangeStartHour}/>
                &nbsp;&nbsp;to&nbsp;&nbsp;
                <input type="number" style={{...Styles.ParamInput, width: 25, flex: 0}} min='1' max='24' value={this.state.endHour}  onChange={this.onChangeEndHour}/>
              </div>
            }
           </div>

        </div>

        <Separator/>

        <Paper style={{height: hAvail, overflowY: 'auto'}} zDepth={0}>

          <div style={Styles.FlexSpread}>
            <RaisedButton {...Btns.Green} disabled={!hasRange} label='Fetch' onClick={this.onRefresh}/>
            &nbsp;
            <RaisedButton {...Btns.Blue} label='Download' onClick={this.onDownload}
                          disabled={!data || (arrData.length === 0)} />
          </div>

          <Separator />
          {
            data &&
            (
              arrData.length > 0
              ?
                <ReportDisplay data={arrData}
                             colTypes={AdminMCStats.dctColTypes}
                             colText={AdminMCStats.arrColNames}
                             colFields={AdminMCStats.arrColFields}/>
              :
                'No jobs in selected time period'
            )
          }
        </Paper>

        <RawDataDownloader name='admin_stats' filename='mc_stats.csv' ref='downloader'/>

      </div>
    );
  }
}

