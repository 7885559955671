/* eslint-disable default-case */
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';

import {Separator, SearchBox, ReportDisplay, InputRow, ColGroup, Icon, ShowOnly} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner, getZoneOpts} from '../Global';
import {DataFetcher, Data} from '../Models/Model';
import {IntCmp} from './Utils';


//Dialog for editing or adding a cluster
class ClusterDlg extends Component
{
  static Validators =
  {
    desc: /.+/,
    url: /https?:\/\/.+\..+/,
    auth: /(.+:.+)?/
  };

  static FieldNames =
  {
    desc: 'Description',
    url: 'URL',
    auth: 'Authentication'
  };

  constructor(props)
  {
    super(props);
    this.state = {...props, err: ''};
  }

  // When reopened reset state to new props
  componentWillReceiveProps(nextProps)
  {
    this.setState
    (
      {...nextProps},
      // Set input values after update
      () => this.setInputValues(nextProps)
    );
  }

  static FieldDefaults = {id: '', url: '', desc: '', auth: '', ml: '0', zone: '0'};

  // On rerender, set the values
  componentDidMount()  { this.setInputValues(this.state); }

  // Sets the host field if editing/adding
  setInputValues(state)
  {
    if(state.action !== 'Delete')
    {
      for(const name of Object.keys(ClusterDlg.FieldDefaults))
      {
        this.refs['cluster_' + name].value = state.action === 'Edit' ? state[name] : ClusterDlg.FieldDefaults[name];
      }

      // On create set the default value for verification
      if(state.action !== 'Edit')
      {
        this.setState({verify: true});
      }
    }
  }

  // On save fire the request
  onSave = () =>
  {
    const isDelete = this.state.action === 'Delete';

    // Get the values
    const data = {};
    if(!isDelete)
    {
      for(const name of ['id', 'url', 'desc', 'auth', 'ml', 'zone'])
      {
        data[name] = this.refs['cluster_' + name].value;
      }

      data.verify = this.state.verify;
    }
    else
    {
      data.id = this.state.id;
    }

    // Remove the id for adds
    if(this.state.action === 'Add')
    {
      delete data['id'];
    }

    // Fire the request
    const sURL = '/portal/admin-cluster-' + (isDelete ? 'delete' : 'save');
    const fetcher = new DataFetcher(sURL, data)
    .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
    .whenDone
    (
      // On success, Update the Data.Clusters and make the parent refresh
      ()=>
      {
        GlobalUI.Dialog.onClose();

        switch(this.state.action)
        {
          case 'Edit':
          case 'Add':
            // force the id fields to integer type
            data.id |= 0;
            data.zone |= 0;
            data.ml |= 0;
            data.id = fetcher.data.result;
            Data.Clusters[data.id] = {...data};
          break;

          case 'Delete':
            delete Data.Clusters[data.id];
          break;
        }

        this.state.parent.doUpdate();
      }
    );

    const sMsg = isDelete ? 'Deleting cluster...' : 'Saving...';
    FetchBusy(fetcher, sMsg);
  }

  onChange = () =>
  {
    for(const name of ['url', 'desc', 'auth', 'ml', 'zone'])
    {
      const val = this.refs['cluster_' + name].value;
      const validator = ClusterDlg.Validators[name];
      const m = validator && val.match(validator);
      if(validator && (!m || m[0].length !== val.length))
      {
        this.setState({err: 'Enter a valid value for: ' + ClusterDlg.FieldNames[name]});
        return true;
      }
    }

    this.setState({err: null});
  }

  onCheckNoVerify = () =>
  {
    this.setState({verify: !this.state.verify});
    this.onChange();
  }

  render()
  {
    const isEdit = this.state.action === 'Edit';
    const isDelete = this.state.action === 'Delete';
    const sWidth = `calc(100% - ${LayoutDims.nMargin * 2}px)`;

    const elemZoneOpts = getZoneOpts();

    return (
      <div>
        {
          !isDelete
          ?
            <div>
              <ShowOnly if={isEdit}>
                <InputRow title='Cluster ID:' width={sWidth}>
                  <input data-cy='inputClusterID' ref='cluster_id' type='number'
                         style={{...Styles.ParamInput, maxWidth: '20%', backgroundColor: isEdit ? Colors.clrNimbixGray : 'none'}}
                         disabled={isEdit} />
                </InputRow>
              </ShowOnly>

              <InputRow title='URL:' width={sWidth}>
                <input data-cy='inputClusterURL' ref='cluster_url' style={Styles.ParamInputWide} onChange={this.onChange} />
              </InputRow>

              <InputRow title='Description:' width={sWidth}>
                <input data-cy='inputClusterDesc' ref='cluster_desc' style={Styles.ParamInputWide} onChange={this.onChange} />
              </InputRow>

              <InputRow title='Authentication:' width={sWidth}>
                <input data-cy='inputClusterAuth' ref='cluster_auth' style={Styles.ParamInputWide} onChange={this.onChange} />
              </InputRow>

              <InputRow title='mL:' width={sWidth}>
                <input data-cy='inputClusterML' ref='cluster_ml' style={Styles.ParamInputWide} onChange={this.onChange} />
                <div>
                  <label>Used in a MIN() calculation for limits of CPUs (regardless of machine type), either per team or per user; 0 means no further restriction.  Use to reduce concurrency limits on smaller clusters.</label>
                </div>
                <div>
                  <label><b>Note that this only takes effect when limits are applied!</b></label>
                </div>
              </InputRow>

              <InputRow title='Zone:' width={sWidth}>
                <select data-cy='inputClusterZone' ref='cluster_zone' style={Styles.ParamInputWide} onChange={this.onChange} >
                {elemZoneOpts}
                </select>

              </InputRow>

              <Separator units={1}/>

              <Checkbox label='Do not verify SSL certificate'
                        ref='cluster_verify'
                        checked={!this.state.verify}
                        onCheck={this.onCheckNoVerify}/>

              <Separator units={1}/>

              <span data-cy='divClusterErr' className='errortext' style={{fontSize: LayoutDims.FontSize}}>
                {this.state.err}&nbsp;
              </span>
              <Separator units={1}/>

            </div>
          :
            <div>
              <Separator/>
              Are you sure you wish to delete this cluster configuration?
              <Separator units={3}/>
            </div>
        }

        <div style={Styles.FlexSpread}>
          <RaisedButton {...Btns.Gray} label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton
            data-cy='confirmOK'
            {...Btns.Blue}
            label='OK'
            onClick={this.onSave}
            disabled={!isDelete && this.state.err != null} />
        </div>
      </div>
    );
  }
}

export default class AdminClusters extends Component
{
  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['cid', 'zone', 'url', 'desc', 'ml'];
  static arrColNames = ['ID', 'Zone', 'URL', 'Description', 'mL'];

  constructor(props)
  {
    super(props);
    this.state = {data: null, search: '',selectedIndex: -1};
  }

  // On mount update once
  componentDidMount()
  {
    this.doUpdate();
  }

  // Fix up the data as required for rendering and perform render
  doUpdate = () =>
  {
    // make the data from a dict to array of dict
    const data = [];
    const arrNames = Object.keys(Data.Clusters);
    arrNames.sort((a, b) => IntCmp(Data.Clusters[a].id, Data.Clusters[b].id));

    let i = 0;
    for(const name of arrNames)
    {
      const row = {...Data.Clusters[name]};

      row.urlText = row.url;
      row.url =
        <div style={Styles.FlexSpread}>
          <div>{row.url}</div>
          <span>
            {row.auth ? Icon('lock', {fontSize: 18, verticalAlign: 'middle'}) : ''}
            {row.verify ? Icon('verified_user', {fontSize: 18, verticalAlign: 'middle'}) : ''}
          </span>
        </div>

      // We cant use the field name ID because ReportDisplay needs it as the row index
      row.cid = row.id
      row.id = i;

      row.zoneText = Data.Zones[row.zone].desc
      row.zone = row.zone + ': ' + row.zoneText;

      data.push(row);
      ++i;
    }

    this.setState({data, selectedIndex: -1});
  }

  onSearchClear = () =>
  {
    this.refs.searchBox.inputField.value = '';
    this.setState({search: '', selectedIndex: -1});
  }

  onSearchIncr = (s) =>
  {
    // Case insensitive search
    this.setState({search: s.toUpperCase().trim(), selectedIndex: -1});
  }

  onAdd = () => this.onClusterAction('Add')
  onEdit = () => this.onClusterAction('Edit')
  onDel = () => this.onClusterAction('Delete')

  // Show dialog box (delete shows confirm dialog)
  onClusterAction = (action) =>
  {
    // Get the selected index and corresponding cluster ID
    const idx = this.state.selectedIndex;
    const cid = this.state.data[idx] && this.state.data[idx].cid;
    const cluster = cid !== null ? {...Data.Clusters[cid]} : null;
    const props = {action, parent: this, ...cluster};

    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      action === 'Delete' ? 'Confirm' : `${action} Cluster Configuration`,
      <ClusterDlg {...props}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  // Row select handler
  onClickRow = (i) =>
  {
    this.setState({selectedIndex: i});
  }

  render()
  {
    // Use as much height as possible
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 160);

    const idx = this.state.selectedIndex;
    const cid = idx >= 0 ? (this.state.data[idx] && this.state.data[idx].cid) : 0;

    // Filter on search string (already upper cased)
    const search = this.state.search;
    const data = this.state.data && this.state.data.length > 0
    ?
      this.state.data.filter(row => !search || (row.cid + '|' + row.urlText + '|' + row.desc).toUpperCase().indexOf(search) >= 0)
    :
      [];

    return (
      <div>
        <div style={Styles.FlexSpread}>
          <SearchBox entryType='Cluster' ref='searchBox' onSearch={this.onSearchIncr} onClear={this.onSearchClear} width='50%'/>
        </div>

        <Separator />

        <div style={Styles.FlexSpread}>
          <div>
            <RaisedButton data-cy='btnClusterNew' {...Btns.Green} label='New' onClick={this.onAdd}/>
            &nbsp;&nbsp;
            <RaisedButton data-cy='btnClusterEdit' disabled={cid === 0} {...Btns.Blue} label='Edit' onClick={this.onEdit}/>
            &nbsp;&nbsp;
            <RaisedButton data-cy='btnClusterDelete' disabled={cid === 0} {...Btns.DarkRed} label='Delete' onClick={this.onDel}/>
          </div>
        </div>

        <Separator />

        <div style={{maxHeight: hAvail, minHeight: hAvail, overflowY: 'auto'}}>

          {
            this.state.data
            ?
              <ReportDisplay ref='clusterList'
                             data={data}
                             colFields={AdminClusters.arrColFields}
                             getExtraStyle={row => row.id ? null : {fontWeight: 'bold'}}
                             colTypes={{cid: 'number', url: 'urlText', zone: 'zoneText', ml: 'number'}}
                             colText={AdminClusters.arrColNames}
                             colGroup={<ColGroup cols={[6, 15, 50]} />}
                             onClickRow={this.onClickRow}
                             selectedIndex={this.state.selectedIndex}
              />

            :
              <div>
                <Separator units={12}/>
                <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
              </div>
          }

        </div>
      </div>

    );
  }
}
