import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {Separator, ShowOnly, ReportDisplay, InputRow} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher, Data, GetTeamList} from '../Models/Model';


class ProjectActionDialog extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {};
  }

  // On mount, give our parent our reference if asked
  componentDidMount()
  {
    if(this.props.giveThis) this.props.giveThis(this);
  }

  render()
  {
    return (
      // Click Add on Enter key
      <div onKeyPress={e => e.key === 'Enter' && this.props.onAdd()}>
        <InputRow title={this.props.label} width='100%'>
          {this.props.field}
        </InputRow>
        <Separator/>

        <div style={Styles.FlexSpread}>
          <RaisedButton label='Cancel' onClick={this.props.onClose}/>
          <RaisedButton label='Add' disabled={!this.state.canAdd} {...Btns.Blue} onClick={this.props.onAdd}/>
        </div>
      </div>
    );
  }
}

export class SettingsProjects extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      arrProjects: [],
      dctProjUsers : {}, // map of project to users
      idxSelProj: -1,
      idxSelUser: -1,
    };

    // Init the project list fetcher
    this.fetcher = new DataFetcher('/portal/team-project-list');
    this.fetcher.whenDone(()=>this.setState({arrProjects: this.fetcher.data.result}));

    // The username of this teams payer
    this.payerName = Data.User.Profile.payer || Data.User.Profile.user_login;
  }

  componentDidMount()
  {
    // Fetch on initial load, also grab the team list, leaving out the payer
    this.fetcher.fetch();
    this.members = GetTeamList().filter(u => u !== this.payerName) ;
  }

  // Helper for deferred focus of a control
  doFocusElem = e => {e && setTimeout(()=>e.focus(), 100); this.input = e;}

  // Does a create/delete for projects or users
  projectAction = (sAction, sTitle, dctParams, sStatus, fnDone) =>
  {
    const fetcher = new DataFetcher(`/portal/project-${sAction}`, {payer: this.payerName, ...dctParams});
    fetcher.whenDone(fnDone).ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, `${sTitle} Failed`));
    FetchBusy(fetcher, sStatus);
  }

  // Gets the selected project and users array (makes a deep copy)
  getCurrProj = () =>
  {
    const sProject = this.state.arrProjects[this.state.idxSelProj];
    const arrProjUsers = [...this.state.dctProjUsers[sProject]];
    return [sProject, arrProjUsers];
  }

  // Close dialog passing true for the "doClear" flag to clean up the dialog fully
  dlgClose = () => GlobalUI.Dialog.onClose(true);

  // Helper to show a confirm dialog for deletions
  doConfirmDelete = (elemText, sTitle, fnOnOK) => GlobalUI.DialogConfirm.confirm(elemText, sTitle, fnOnOK, null, 'Yes', 'No');

  // Updates the userProjects state
  doUsersUpdate = (sProject, arrProjUsers, fnLater) =>
  {
    // Copy the state, change and apply
    const dctProjUsers = {...this.state.dctProjUsers};
    dctProjUsers[sProject] = arrProjUsers;
    this.setState({dctProjUsers, idxSelUser: -1}, fnLater);
  }

  onClickRowProj = (i) =>
  {
    if(i !== this.state.arrProjects[i])
    {
      const sProject = this.state.arrProjects[i];
      this.setState({idxSelProj: i, idxSelUser: -1});

      if(!(sProject in this.state.dctProjUsers))
      {
        const fetcher = new DataFetcher('/portal/project-users', {payer: this.payerName, project: sProject});
        const onDone = () =>
        {
          // Filter out the team payers name from the list
          const arrMembers = fetcher.data.result.filter(m => m !== this.payerName);
          this.setState({dctProjUsers: {...this.state.dctProjUsers, [sProject]: arrMembers}});
        }
        fetcher.whenDone(onDone);
        FetchBusy(fetcher, 'Loading...');
      }
    }
  }

  onClickRowUser = (i) =>  this.setState({idxSelUser: i});

  onCreateProj = () =>
  {
    let sProject, elemDlg;

    // Fire the request to add project, and update data locally
    const onAdd = () =>
    {
      const onDone = () =>
      {
        // Add an empty user list to this project to prevent needless query
        const dctProjUsers = {...this.state.dctProjUsers};
        dctProjUsers[sProject] = [];

        // Add the project to the head and select it
        this.setState({dctProjUsers, arrProjects: [sProject, ...this.state.arrProjects], idxSelProj: 0, idxSelUser: -1}, this.dlgClose);
      }

      this.projectAction('create', 'Create User', {project: sProject}, 'Saving...', onDone);
    }

    const field =
      <input ref={this.doFocusElem}
             onChange={e => {sProject = e.target.value; elemDlg.setState({canAdd: !!sProject})}}
             style={Styles.ParamInputWide}/>;

    GlobalUI.Dialog.show
    (
      'Create New Project',
      <ProjectActionDialog label='Project name:' giveThis={t => elemDlg = t} field={field} onClose={this.dlgClose} onAdd={onAdd}/>,
      false,
      LayoutDims.wContent * 0.75,
    );
  }

  onDelProj = () =>
  {
    // Get selected project
    const sProject = this.state.arrProjects[this.state.idxSelProj];

    const onDone = () =>
    {
      // Remove the local entry
      const arrProjects = [...this.state.arrProjects];
      arrProjects.splice(this.state.idxSelProj,1);
      this.setState({arrProjects, idxSelProj: -1, idxSelUser: -1})
    };

    // Confirm and delete
    this.doConfirmDelete
    (
      <div>Are you sure you want to delete the project <b>{sProject}</b>?</div>,
      'Confirm Project Delete',
      () => this.projectAction('delete', 'Project Delete', {project: sProject}, 'Deleting...', onDone),
    );
  }

  onAddUser = () =>
  {
    // Filter out the users who are already in the project
    const [sProject, arrProjUsers] = this.getCurrProj();
    const arrUsers = this.members.filter(u => arrProjUsers.indexOf(u) === -1);
    let user = arrUsers[0];

    // Fire the request to add project, and update data locally
    const onAdd = () =>
    {
      let showErr = msg =>
      {
        GlobalUI.DialogConfirm.show('Error', msg, false, 0.75 * LayoutDims.wContent);
        this.input.value = '';
        this.doFocusElem(this.input);
      }

      if(arrProjUsers.indexOf(user) !== -1)
      {
        showErr('User already exists in project');
        return;
      }

      if(this.members.indexOf(user) === -1)
      {
        showErr('User not a team member');
        return;
      }

      arrProjUsers.push(user);
      this.projectAction('add-user', 'Add User', {project: sProject, user}, 'Adding...', () => this.doUsersUpdate(sProject, arrProjUsers, this.dlgClose));
    }

    const field =
      <input ref={this.doFocusElem}
             onChange={e => {user = e.target.value}}
             style={Styles.ParamInputWide}
             list='team-data-list'/>

    GlobalUI.Dialog.show
    (
      'Add Team Member To Project',
      <ProjectActionDialog label='Username:' giveThis={t => t.setState({canAdd: true})} field={field} onClose={this.dlgClose} onAdd={onAdd}/>,
      false,
      LayoutDims.wContent * 0.75,
    );
  }

  onDelUser = () => {

    // Get selected project
    const [sProject, arrProjUsers] = this.getCurrProj();
    const sUser = arrProjUsers[this.state.idxSelUser];

    const onDone = () => {
      // Remove the local entry
      arrProjUsers.splice(this.state.idxSelUser, 1);
      this.doUsersUpdate(sProject, arrProjUsers, this.dlgClose);
    };

    this.doConfirmDelete
    (
      <div>Are you sure you want to remove user <b>{sUser}</b> from the project <b>{sProject}</b>?</div>,
      'Confirm User Removal',
      () => this.projectAction('del-user', 'User Remove', {project: sProject, user: sUser}, 'Removing...', onDone),
    );

  };

  render()
  {
    // Get the projects, make it into an array of dict for ReportDisplay
    const arrProjectsData = this.state.arrProjects && this.state.arrProjects.map((name,id) => ({name, id}));

    // Get current project's members if any
    const sProj = this.state.idxSelProj >= 0 && this.state.arrProjects[this.state.idxSelProj];
    const dctProjUsers = sProj && this.state.dctProjUsers[sProj] && this.state.dctProjUsers[sProj];
    const arrUsersData = dctProjUsers && dctProjUsers.map((user, id) => ({user, id}));

    return (
      <div>
        <ShowOnly if={!this.state.arrProjects}>
          <Separator units={12}/>
          <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
        </ShowOnly>

        {
          arrProjectsData &&
          <div>
            <Separator/>
            <div style={{...Styles.Inline, justifyContent: 'flex-end'}}>
              <div>
                <RaisedButton data-cy='btnProjNew' {...Btns.Green} label='New' onClick={this.onCreateProj}/>
                &nbsp;&nbsp;
                <RaisedButton data-cy='btnProjDel' disabled={this.state.idxSelProj < 0} {...Btns.DarkRed} label='Delete' onClick={this.onDelProj}/>
              </div>
            </div>

            <Separator/>


            <ReportDisplay ref='projectList'
                           data={arrProjectsData.length ? arrProjectsData : [{name: 'None'}]}
                           colFields={['name']}
                           colText={['Projects']}
                           onClickRow={this.onClickRowProj}
                           selectedIndex={this.state.idxSelProj}
            />
          </div>
        }

        <Separator units={8}/>

        {
          arrUsersData &&
          <div>
            <Separator/>
            <div style={{...Styles.Inline, justifyContent: 'flex-end'}}>
              <div>
                <RaisedButton data-cy='btnProjUserAdd' disabled={dctProjUsers.length === this.members.length} {...Btns.Green} label='Add' onClick={this.onAddUser}/>
                &nbsp;&nbsp;
                <RaisedButton data-cy='btnProjUserDel' disabled={this.state.idxSelUser < 0 || this.state.idxSelUser == null} {...Btns.DarkRed} label='Remove' onClick={this.onDelUser}/>
              </div>
            </div>

            <Separator/>

            <ReportDisplay ref='teamList'
                           data={arrUsersData.length ? arrUsersData : [{user : 'None'}]}
                           colFields={['user']}
                           colText={['Project Members']}
                           onClickRow={this.onClickRowUser}
                           selectedIndex={this.state.idxSelUser}
            />

          </div>
        }

        {
          this.members &&
          <datalist id='team-data-list'>
            {this.members.map((e, i) => <option key={i}>{e}</option>)}
          </datalist>
        }
      </div>
    );
  }
}

