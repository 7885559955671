// Core
import React from 'react';
import PropTypes from 'prop-types';

// Our UI data, styles and colors
import {Styles} from './UIConst.js';
import {TableFixedHeaderTab, ParamColGroup} from './Components';

// Displays a pane of parameters
export const TaskBuilderParamsPane = (props) =>
{
  return (
    <div>
      {!props.hideTitle && <TableFixedHeaderTab title='Parameters'/>}

      <div>
        <table style={{...Styles.Table}} >
          <ParamColGroup/>

          <tbody>
          {props.items}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TaskBuilderParamsPane.propTypes =
{
  items: PropTypes.array,
  height: PropTypes.any,
  hideTitle: PropTypes.bool
};
