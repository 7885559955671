/*
 This file is the main entry point for the  react application
 */

import React from 'react';
import ReactDOM from 'react-dom';

import {renderMain} from './App';
import {RenderSync, GlobalUI, BusyScreenView, DialogView, CleanUp, Elem, LocalStorage, getXHRErr} from './Global';
import {Login, tryLogin} from './Components/Login';
import {Register} from './Components/Register';
import {SamlTest} from './Components/SamlTest';
import {DataFetcher} from './Models/Model';
import {Images} from './Components/UIConst';

// Everything starts here
export const init = () =>
{
  // Insert the favicon image data into the link tag in index.html
  Elem('favicon').href = Images.FavIcon;

  // For now we are using a URL param as the page demux
  // In future we can change it to react router, but it needs a lot of work

  const showLoginPage = (err, rootUser, cookie) =>
  {
    RenderSync(<Login ref={GlobalUI.release} err={err} rootUser={rootUser} cookie={cookie}/>, Elem('root'));
  }

  const FauxLogin = () =>
  {
    const versionUpdated = LocalStorage.get('versionUpdated', '*');
    LocalStorage.del('versionUpdated', '*');

    tryLogin
    (
      '',
      // If this flag is set, pass a dummy password "-" so the server knows this is an autorefresh for version change
      versionUpdated ? '-' : '',
      () => renderMain(true),
      (jqXHR) =>
      {
        CleanUp();
        showLoginPage(getXHRErr(jqXHR));
      },
      true
    );
  }

  GlobalUI.BusyScreen = RenderSync(<BusyScreenView ref={GlobalUI.release} open={false}/>, Elem('busyroot'));

  // Global dialog frame
  GlobalUI.Dialog = RenderSync(<DialogView ref={GlobalUI.release} open={false}/>, Elem('dialog'));

  // Global dialog frame
  GlobalUI.DialogConfirm = RenderSync(<DialogView ref={GlobalUI.release} open={false}/>, Elem('dialog-extra'));

  // If the URL is registration
  if(GlobalUI.URLPage === 'signup')
  {
    ReactDOM.render(<Register ref={GlobalUI.release}  params={GlobalUI.URLParams}/>, Elem('root'));
  }
  else if(GlobalUI.URLPage === 'reset')
  {
    ReactDOM.render(<Login ref={GlobalUI.release}  params={GlobalUI.URLParams} pane='Reset'/>, Elem('root'));
  }
  else if(GlobalUI.URLPage === 'samltest')
  {
    ReactDOM.render(<SamlTest ref={GlobalUI.release}  params={GlobalUI.URLParams.data}/>, Elem('root'));
  }
  else if(GlobalUI.URLPage === 'ldap-login')
  {
    ReactDOM.render(<Login ref={GlobalUI.release}  params={GlobalUI.URLParams} pane='LDAP'/>, Elem('root'));
  }
  else
  {
    // Check if were logged in and the root user exists
    const LoginCheck = new DataFetcher('/portal/logged-in');
    LoginCheck.whenDone
    (
      () =>
      {
        // If were already logged in, do a faux login to get the user profile
        // server login handler ignores parameters if session cookie exists
        // Else show the login page
        if(LoginCheck.data.user)
        {
          FauxLogin();
        }
        else
        {
          // Show login dialog, passing in the root user name and security cookie
          // Root user name is sent only if root user is non existent (i.e. empty DB)
          showLoginPage('', LoginCheck.data.root, LoginCheck.data.cookie);
        }
      }
    )
    .fetch();
  }
}

