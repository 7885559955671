// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Styles, LayoutDims} from './UIConst';
import {FileChooser} from './Components';
import {GlobalUI} from '../Global';
import {Data} from '../Models/Model';


export class TaskBuilderParamUpload extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  // Update the state when props change
  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps});
  }

  scrollIntoView()
  {
    this.selectField.scrollIntoView();
  }

  // Prevent too large files from being loaded
  onValidateFile = (file) =>
  {
    let limit = Data.User.Profile.upload_param_size_limit;
    if(this.state.size) limit = Math.min(this.state.size, limit);

    if(file.size > limit)
    {
      GlobalUI.Dialog.show('Error',
                           'Maximum file size allowed is ' + limit + ' bytes',
                           false,
                           LayoutDims.wContent * 0.75);
      return false;
    }

    return true;
  }


  // Called when file is read by file chooser
  // It arrives as a base64 encoded URLData, we snip off the header
  onRead = (sData) =>
  {
    try
    {
      this.inputField.value = this.refs.fileChooser.refs.inputFile.files[0].name;

      // Chop off the url data upto the "base64,"
      const prefixLen = sData.indexOf('base64,');
      if(!prefixLen) {throw 0;}
      this.data = sData.substr(prefixLen + 7);
    }
    catch(e)
    {
      GlobalUI.Dialog.show
      (
        'Error loading file',
        <div>
          File could not be read!
        </div>,
        false,
        LayoutDims.wContent * 0.75
      );
    }
  }

  // Called when the ... button is clicked
  onBrowse = () =>
  {
    this.refs.fileChooser.open();
  }


  render()
  {
    return (
      <div>
        <div style={{...Styles.Inline,  width: 'calc(50% + 48px)', alignItems: 'stretch'}}>
          <input data-cy={this.props.paramName}
                 ref={el=>this.inputField = el}
                 defaultValue={this.props.defaultValue}
                 onChange={this.state.onChange}
                 style={this.props.inputStyle} />

          <button style={{width: 32}} onClick={this.onBrowse} title='Browse'>
            ...
          </button>
        </div>

        <FileChooser ref='fileChooser' asURL={true} onRead={this.onRead} filter={this.state.filter} validate={this.onValidateFile}/>
      </div>
    );
  }
}

TaskBuilderParamUpload.propTypes =
{
  paramName: PropTypes.string.isRequired,
  mvalues: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.string),
  that: PropTypes.node,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  hasSeparator: PropTypes.bool,
};

