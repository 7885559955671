import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {Separator, ReportDisplay, InputRow} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher} from '../Models/Model';


//Dialog for editing or adding a node
class DiscountDlg extends Component
{
  static styleInput = {...Styles.ParamInput, minWidth: '100%'}

  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  // When reopened reset state to new props
  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps});
  }

  // On update and rerender, set the values
  componentDidUpdate() { this.setInputValues(); }
  componentDidMount()  { this.setInputValues(); }


  // Sets the fields if not deleting
  setInputValues()
  {
    if(this.state.action !== 'Delete')
    {
      this.refs.billing_code.value = this.state.billing_code;
      this.refs.mc_discount.value = this.state.mc_discount;
      this.refs.app_discount.value = this.state.app_discount;
    }
  }

  // On save fire the request
  onSave = () =>
  {
    const data = {};

    const isDelete = this.state.action === 'Delete';

    // Set the action
    data.action = this.state.action.toLowerCase();

    // Add discount parameters for non delete actions
    if(!isDelete)
    {
      data.billing_code = this.refs.billing_code.value;
      data.mc_discount = this.refs.mc_discount.value;
      data.app_discount = this.refs.app_discount.value;
    }
    else
    {
      data.billing_code = this.state.billing_code;
    }

    // Fire the request
    const sURL = '/portal/admin-discount-set';
    const fetcher = new DataFetcher(sURL, data)
      .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
      .whenDone
      (
        // On success, close dlg and refresh parent
        ()=>
        {
          GlobalUI.Dialog.onClose();
          this.state.parent.refetch();
        }
      );

    const sMsg = isDelete ? 'Deleting node...' : 'Saving...';
    FetchBusy(fetcher, sMsg);
  }

  render()
  {
    const isEdit = this.state.action === 'Update';
    const isConfirm = this.state.action === 'Delete';
    const sWidth = `calc(100% - ${LayoutDims.nMargin * 2}px)`;

    const sConfirm = 'Are you sure you wish to delete this discount?';

    return (
      <div>
        {
          !isConfirm
            ?
            <div>
              <InputRow title='Billing Code' width={sWidth}>
                <input ref='billing_code' style={DiscountDlg.styleInput} readOnly={isEdit}/>
              </InputRow>

              <InputRow title='Machine Discount' width={sWidth}>
                <input ref='mc_discount' style={DiscountDlg.styleInput} />
              </InputRow>

              <InputRow title='App Discount' width={sWidth}>
                <input ref='app_discount' style={DiscountDlg.styleInput} />
              </InputRow>
            </div>
            :
            <div>
              <Separator/>
              {sConfirm}
              <Separator units={3}/>
            </div>
        }

        <div style={Styles.FlexSpread}>
          <RaisedButton {...Btns.Gray} label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton {...Btns.Blue} label='OK' onClick={this.onSave}/>
        </div>
      </div>
    );
  }
}

export default class AdminDiscounts extends Component
{
  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['billing_code', 'mc_discount', 'app_discount'];
  static arrColNames = ['Billing Code', 'Machine Discount', 'App Discount'];

  constructor(props)
  {
    super(props);
    this.state = {data: null, selectedIndex: -1};

    this.fetcher = new DataFetcher('/portal/admin-discount-list');
    this.fetcher.whenDone(this.onUpdate);
    this.fetcher.fetch();
  }

  // Clear existing view and refresh
  refetch = () =>
  {
    this.setState({data: null, search: null, selectedIndex: -1});
    this.fetcher.fetch();
  }

  // When data is fetched, update the list
  onUpdate = () =>
  {
    // make the data from a dict to array of dict
    let i = 0;
    const data = [];

    for(const row of this.fetcher.data.result)
    {
      data.push({id: i, billing_code: row[0], app_discount: row[1], mc_discount: row[2]});
      ++i;
    }

    this.setState({data, selectedIndex: -1});
  }

  onAdd = () => this.onDiscountAction('Add')
  onEdit = () => this.onDiscountAction('Update')
  onDel = () => this.onDiscountAction('Delete')

  // Show dialog box (delete shows confirm dialog)
  onDiscountAction = (action) =>
  {
    const idx = this.state.selectedIndex;
    const bNoVals = (idx < 0 || action === 'Add');
    const billing_code = bNoVals ? '' : this.state.data[idx].billing_code;
    const mc_discount = bNoVals ? '' : this.state.data[idx].mc_discount;
    const app_discount = bNoVals ? '' : this.state.data[idx].app_discount;

    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      `${action} Discount`,
      <DiscountDlg action={action}
                   billing_code={billing_code}
                   mc_discount={mc_discount}
                   app_discount={app_discount}
                   parent={this}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  // Row select handler
  onClickRow = (i) => this.setState({selectedIndex: i});

  render()
  {
    // Use as much height as possible
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 160);

    return (
      this.state.data ?
      <div>

        <div style={Styles.Inline}>
            <RaisedButton {...Btns.Green} label='New' onClick={this.onAdd}/>
            &nbsp;&nbsp;
            <RaisedButton disabled={this.state.selectedIndex < 0} {...Btns.Blue} label='Edit' onClick={this.onEdit}/>
            &nbsp;&nbsp;
            <RaisedButton disabled={this.state.selectedIndex < 0} {...Btns.DarkRed} label='Delete' onClick={this.onDel}/>
        </div>

        <Separator />

        <div style={{maxHeight: hAvail, minHeight: hAvail, overflowY: 'auto'}}>

          <ReportDisplay ref='discountList'
                         data={this.state.data}
                         colFields={AdminDiscounts.arrColFields}
                         colText={AdminDiscounts.arrColNames}
                         onClickRow={this.onClickRow}
                         selectedIndex={this.state.selectedIndex}
          />

        </div>
      </div>
        :
      <div>
        <Separator units={12}/>

        <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
      </div>
    );
  }
}
