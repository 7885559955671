import React, {Component} from 'react';

import {MUI, SectionViewHeader, HLine, Separator, ColGroup , SwitchedPane} from './Components';
import {LayoutDims, Styles, Colors} from './UIConst.js';
import {Data} from '../Models/Model';

const KeyVal = (props) =>
{
  return (
    <tr style={Styles.TableCellBordered}>
      <td style={Styles.TableCellBordered}>{props.title}</td>
      <td style={Styles.TableCellBordered}>{props.value}</td>
    </tr>
  )
}

// Collapsible app view, with filtering
export default class AboutPane extends Component
{
  constructor(props)
  {
    super(props);
    SwitchedPane.RegisterOnSwitch('Content/About', this.onLoad)
  }

  onLoad = () =>
  {
    // Set the iframes onload to the onLoadIFrame function
    this.refs['aboutFrame'].onload = this.onLoadIFrame;
    this.refs['aboutFrame'].setAttribute('src', '/static/about.html');
  }

  onLoadIFrame = () =>
  {
    // Get the iFrame content
    const iframe = this.refs['aboutFrame'];
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    // If the content length is 0 set the iframe height to 0 to hide it (otherwise it defaults to 150px height)
    if(iframeDocument.body.textContent.length === 0)
    {
      iframe.style.height = 0;
      iframe.style['flex-grow'] = 0;
    }
    else
    {
      iframe.style['flex-grow'] = 1;
    }
  }

  render()
  {
    return MUI(
      <div ref='content-about' style={{ ...Styles.InlineFlexCol, width: '100%', height: '90vh', alignItems: 'stretch', justifyContent: 'flex-start', margin: 0 }}>

        <SectionViewHeader headerText='About JARVICE™' />

        <iframe ref='aboutFrame' style={{ margin: LayoutDims.nMargin}} frameBorder="0" sandbox="allow-same-origin"/>

        <table style={{ ...Styles.Table, margin: LayoutDims.nMargin, width: '' }}>
          <ColGroup cols={[20]} />
          <tbody>
            <KeyVal title='Version' value={Data.version} />
            {
              Data.License
                ?
                <KeyVal title='License information' value={Data.License} />
                :
                null
            }
          </tbody>
        </table>

        <div style={{ margin: LayoutDims.nMargin, fontSize: LayoutDims.FontSize + 2, color: Colors.clrNimbixDark }}>

          <Separator />
          <b>{Data.copyright}</b>
        </div>

      </div>
    );
  }
}

