import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Card, CardHeader, CardText} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';

import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {dateToLocalTimeStr, ReportDisplay, SectionViewHeader, Separator, ColGroup} from './Components';
import {Spinner, GlobalUI} from '../Global';

import {GetApp} from '../Models/AppsData';
import {KillJob} from '../Models/Model';

const StatusNames = {'SUBMITTED': 'Queued', 'PROCESSING STARTING': 'Processing'};

class JobGroup extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {label: props.label, jobs: props.jobs || [], queuedCount: props.queuedCount, killing: false};

    // Temporary list of jobs to kill when bulk terminating
    this.arrJobNumKill = null;

    // Map of jobnum to job for above
    this.dctJobs = {};

    // Failed terminations
    this.dctJobNumKillFailed = {};

    // Whether shutdown or abort
    this.bTerminate = false;
  }

  componentWillReceiveProps(nextProps)
  {
    const jobs = this.setJobsTerminationStatus(nextProps.jobs, this.state.killed);
    this.setState({label: nextProps.label, jobs, queuedCount: nextProps.queuedCount})
  }

  // if the group size changed or if any jobs detail changed, update
  shouldComponentUpdate(nextProps, nextState)
  {
    // Whether we are killing jobs
    if(nextState.killing !== this.state.killing)
    {
      return true;
    }

    // Label change
    if(nextState.label !== this.state.label)
    {
      return true;
    }

    // Group size change
    if(nextState.jobs.length !== this.state.jobs.length)
    {
      return true;
    }

    // Any job's fields change
    for(let i = 0; i < this.state.jobs.length; ++i)
    {
      for(const sKey of JobByLabelView.arrColFields)
      {
        if(nextState.jobs[i][sKey] !== this.state.jobs[i][sKey])
        {
          return true;
        }
      }
    }

    //console.log('Skipping update for label ' + this.state.label);
    return false;
  }

  // Sets the status of jobs depending on termination result
  setJobsTerminationStatus(aJobs, killed)
  {
    if(killed)
    {
      const jobs = [...aJobs];
      const sSuccessMsg = this.bTerminate ? 'Terminating ...' : 'Shutting down...';
      const sFailDefMsg = (this.bTerminate ? 'Terminate' : 'Shutdown') + ' failed';
      for(const j of jobs)
      {
        const sFailMsg = this.dctJobNumKillFailed[j.job_number];
        const bFailed = sFailMsg != null;
        const bHasErr = bFailed && sFailMsg !== '-';

        j.job_status = bFailed ? (bHasErr ? sFailMsg : sFailDefMsg) : sSuccessMsg;
      }

      return jobs;
    }

    return aJobs;
  }

  killOneByOne = () =>
  {
    // Grab list of job numbers to kill, set "killing" state so a spinner is shown
    if(this.arrJobNumKill == null)
    {
      this.arrJobNumKill = this.state.jobs.map
      (
        (j) =>
        {
          this.dctJobs[j.job_number] = j;
          return j.job_number
        }
      );

      this.setState({killing: true});
    }

    // If we have any left to kill, fire off a delayed request to kill the first
    // and remove that entry from the list
    if(this.arrJobNumKill.length > 0)
    {
      // console.log('Killing ' + this.arrJobNumKill[0]);
      const iJobNum = this.arrJobNumKill[0];
      KillJob
      (
        // Force termination of queued jobs
        this.bTerminate || this.dctJobs[iJobNum].job_status === 'Queued',
        this.dctJobs[iJobNum],
        (jqXHR) => this.dctJobNumKillFailed[iJobNum] = (jqXHR.responseJSON && jqXHR.responseJSON.error) ? jqXHR.responseJSON.error.msg : '-',
      );

      this.arrJobNumKill.splice(0, 1);
      window.setTimeout(this.killOneByOne, 200);
    }
    else // Job termination requests are done
    {
      // Set UI state
      const jobs = this.setJobsTerminationStatus(this.state.jobs, true);
      this.setState({jobs, killing: false, killed: true});
    }
  }

  // Ask user before killing the set of jobs
  onClickTerminate = () =>
  {
    // Checkbox specifies whether to call runtimeShutDown or /terminate endpoint
    const ctrls =
      <div key={this.state.label}>
        <p>Are you sure you wish to request shutdown for all jobs in this group?</p>
        <br/>
        <Checkbox data-cy='checkboxForceStop' defaultChecked={false} onCheck={(e, v)=>{this.bTerminate = v}} label='Force Stop'/>
      </div>

    // Show the confirm dialog and kill the job if
    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.confirm
    (
      ctrls,
      'Confirm Shutdown',
      () =>
      {
        this.arrJobNumKill = null;
        this.dctJobNumKillFailed = {};
        this.killOneByOne();
      },
      null,
      'Yes',
      'No'
    );
  }

  render()
  {
    const nQueued = this.state.queuedCount;
    let sSubTitle = this.state.jobs.length + ' jobs ' + (nQueued ? `(${nQueued} queued)` : '');

    return (
    <div style={{margin: LayoutDims.nMargin * 2}} key={this.state.label}>
      <Separator/>
      <Card>
        <CardHeader
          title={this.state.label}
          subtitle={sSubTitle}
          actAsExpander={true}
          showExpandableButton={true}
          titleStyle={{fontSize: LayoutDims.FontSize + 4}}
          subtitleStyle={{fontSize: LayoutDims.FontSize + 2}}
        />

        <CardText expandable={true} style={{paddingTop: 0}}>
          <RaisedButton {...Btns.DarkRed} label='Terminate All' onClick={this.onClickTerminate} disabled={this.state.killing}/>

          <Separator/>

          {
            this.state.killing ?
            <Spinner style={Styles.Full} size={64} textColor={Colors.clrNimbixDark} status='Terminating...'/>
            :
            <ReportDisplay ref='clusterList'
                           data={this.state.jobs}
                           colFields={JobByLabelView.arrColFields}
                           colText={JobByLabelView.arrColText}
                           colGroup={<ColGroup cols={[8, 30, 15, 15]} />}
            />
          }
        </CardText>
      </Card>
    </div>
    );
  }
}

// Shows old jobs
export class JobByLabelView extends Component
{
  static propTypes =
  {
    headerText: PropTypes.string.isRequired,
  };

  static arrColFields = ['job_number', 'job_appname', 'job_command', 'job_start_time', 'job_status'];
  static arrColText = ['Job No.', 'App', 'Command', 'Start time', 'Status'];


  constructor(props)
  {
    super(props);
    this.state =
    {
      headerText: props.headerText,
      jobs: null,
    };
  }

  // make a map of (non-blank) job label to array of jobs
  getGroupedJobs = (jobs) =>
  {
    const arrJobNums = Object.keys(jobs);
    const dctGrouped = {}, dctQueuedCount = {};
    for(const jobnum of arrJobNums)
    {
      const dctJob = jobs[jobnum];
      if(dctJob.job_label)
      {
        // Make a copy of the job struct
        const tmp = {...dctJob};

        // Put app name into it if exists, else use ID, show local start time if running
        const dctApp = GetApp(dctJob.job_application);
        tmp.job_appname = dctApp ? dctApp.data.name : ('*' + dctJob.job_application);
        tmp.job_start_time = dctJob.job_start_time ? dateToLocalTimeStr(dctJob.job_start_time) : '---';

        // Setup queued job count
        if(dctQueuedCount[dctJob.job_label] == null)
        {
          dctQueuedCount[dctJob.job_label] = 0;
        }

        // Count queued jobs
        if(tmp.job_status === 'SUBMITTED')
        {
          dctQueuedCount[dctJob.job_label]++;
        }

        // Set human friendly status
        tmp.job_status = StatusNames[tmp.job_status] || tmp.job_status;

        // Add to array
        if(!dctGrouped[dctJob.job_label])
        {
          dctGrouped[dctJob.job_label] = [tmp];
        }
        else
        {
          dctGrouped[dctJob.job_label].push(tmp);
        }
      }
    }

    return {dctGrouped, dctQueuedCount};
  }

  render()
  {
    let elem = null;

    if(this.state.jobs !== null)
    {
      const {dctGrouped, dctQueuedCount} = this.getGroupedJobs(this.state.jobs);
      const arrGroups = Object.keys(dctGrouped);
      arrGroups.sort();

      elem = arrGroups.map
      (
        label =>
        <JobGroup key={label}
                  label={label}
                  jobs={dctGrouped[label]}
                  queuedCount={dctQueuedCount[label]}
        />
      )
    }
    else
    {
      elem = <Spinner style={{...Styles.Full, marginTop: 64}} size={64} textColor={Colors.clrNimbixDark} status='Loading...'/>
    }

    return (
      <div>
        <SectionViewHeader headerText={this.state.headerText}/>
        <div>{elem}</div>
      </div>
    );
  }
}
