// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import {List, ListItem} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Toggle from 'material-ui/Toggle';
import Drawer from 'material-ui/Drawer';
import Checkbox from 'material-ui/Checkbox';
import {Popover, PopoverAnimationVertical} from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import RaisedButton from 'material-ui/RaisedButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';

// Material UI theme
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

// Our UI data, styles and colors
import {LayoutDims, Styles, PaletteMain, Colors, Images, Btns} from './UIConst.js';
import {GlobalUI, LocalStorage, ShowMOTD} from '../Global';
import {GetAppImgSrc, IsAdmin} from '../Models/Model';

// zero pads a number to 2 digits
function padToTwoDigits(number)
{
  return number <= 9 ? '0' + number : number;
}

// Returns uppercase if item is a string, else itself
function safeToUpper(x)
{
  return typeof(x) === 'string' ? x.toUpperCase() : x;
}

// Format UNIX time_t as YYYY MM DD HH MM SS, browser doesn't honor OS settings
export function dateToLocalTimeStr(unixTime)
{
  if(!unixTime)
  {
    return '--:--:--';
  }

  const dt = new Date(unixTime * 1000);

  const yyyy = dt.getFullYear();
  const mm = padToTwoDigits(dt.getMonth() + 1);
  const dd = padToTwoDigits(dt.getDate());
  const hr = padToTwoDigits(dt.getHours());
  const mn = padToTwoDigits(dt.getMinutes());
  const sc = padToTwoDigits(dt.getSeconds());

  // use non breaking hypens
  return yyyy + '‑' + mm + '‑' + dd + ' ' + hr + ':' + mn + ':' + sc;
}

// Left pad!
function padLeft(s, len, padCh=' ')
{
  if(s.length >= len)
  {
    return s;
  }

  const pad = Array(len-s.length).join(padCh);
  return pad + s;
}

// Gets the hours and mins for a time period
export function getHHMM(s)
{
  s = (s / 60) | 0;
  const mm = s % 60;
  const hh = (s / 60) | 0;
  return [padToTwoDigits(hh), padToTwoDigits(mm)];
}

// Helper to wrap top level components in MUI theme
export function MUI(x)
{
  return <MuiThemeProvider muiTheme={getMuiTheme(PaletteMain)}>{x}</MuiThemeProvider>
}

// Dummy function
export function Nop()
{
}

export function Icon(sIconName, dctStyle = {})
{
  return (
    <i className='material-icons'
            style={{color: Colors.clrNimbixDark, fontSize: 32, ...dctStyle}}>
      {sIconName}
    </i>
  );
}

export function ModalTitle(props)
{
  return (
    <div>
      <div style={Styles.Inline}>
        <img src={Images.FavIcon} style={{width: LayoutDims.nIconSize * (props.scale || 0.6)}}/>
        <div style={{...Styles.InlineFlexRow, fontSize: props.fontSize || 22}}>&nbsp;&nbsp;{props.title}</div>
      </div>

      <Separator units={1}/>
      <HLine margin={0}/>
      <Separator units={2}/>

    </div>
  )
}

// Contents of the right hand drawer
export class NavPane extends Component
{
  static propTypes =
  {
    // Menu items that will be rendered
    menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,

    // The tag for each menu item
    menuActions: PropTypes.arrayOf(PropTypes.string).isRequired,

    // Click handler which gets the string
    onClick: PropTypes.func,

    // Logout handler
    onLogout: PropTypes.func.isRequired,

    // Child rendered at the bottom
    childItem: PropTypes.element,

    // Selected item
    selected: PropTypes.string
  };

  componentWillReceiveProps(nextProps)
  {
    this.setState({selected: nextProps.selected});
  }

  render()
  {
    // List BG is colored same as the app bar
    const styleList =
    {
      paddingTop: '1px',
      paddingBottom: '0px',
      backgroundColor: Colors.clrNimbixDark,
      flex: 1,
    };

    const styleOuter =
    {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      padding: 0,
      width: LayoutDims.wDrawerRight,
    };

    // Each item has a divider and light color text
    return (
      <div style={styleOuter}>
        <List style={styleList}>
          {
            this.props.menuItems.map
            (
              (sItem, i) =>
              {
                const style = {color: Colors.clrLight, marginBottom: 2};
                if(sItem === this.props.selected)
                {
                  style.backgroundColor = Colors.clrNimbixDarker;
                  style.fontWeight = 600;
                }

                return (
                  <div key={sItem}>
                    <Divider/>
                    <ListItem onClick={this.props.onClick.bind(this, sItem)}
                              innerDivStyle={style}
                              data-cy={'menuNav' + this.props.menuActions[i]}>
                      {sItem}
                    </ListItem>
                  </div>
                );
              }
            )
          }

          <Divider/>
          <ListItem data-cy='menuNavLogout' onClick={this.props.onLogout} style={{color: Colors.clrLight}}>
            Log Out
          </ListItem>

        </List>
      </div>
    );
  }
}

export class DrawerX extends Component
{
  static propTypes =
  {
    dir: PropTypes.oneOf(['left', 'right']),
    children: PropTypes.any,
    scrollable: PropTypes.bool,
    hidden: PropTypes.bool,
  };

  constructor(props)
  {
    super(props);
    this.state = {open: false, visible: true};
  }

  toggle = () => this.setState({open: !this.state.open});

  render()
  {
    const isRight = this.props.dir === 'right';
    const style = {...Styles.Drawer, overflowX: 'hidden', overflowY : this.props.scrollable ? 'auto': 'hidden'};
    if(this.props.hidden)
    {
      style.display = 'none';
    }

    return (
      <Drawer containerStyle={style}
              width={isRight ? LayoutDims.wDrawerRight : LayoutDims.wDrawerLeft}
              openSecondary={isRight}
              open={this.state.open}>
        {this.props.children}
      </Drawer>
    );
  }
}

export class NavToolbar extends Component
{
  static propTypes =
  {
    onClick: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    motd: PropTypes.string,
  };

  constructor(props)
  {
    super(props)
    this.state = {zonesPopupOpen: false};
  }

  closeZonePopup = () => this.setState({zonesPopupOpen: false});

  onZoneClick = () =>
  {
    this.setState({anchor: this.refs.labelZone, zonesPopupOpen: !this.state.zonesPopupOpen});
  }

  render()
  {
    let zone = this.props.currentZone | 0
    if(zone === null) zone = 'None';

    // eslint-disable-next-line
    const sZone = zone == -1 ? 'None' : this.props.zones[zone].desc;

    // Build zone menu if we are not in zone -1 add an entry for admins
    let arrItems = [];
    if(IsAdmin() && zone !== -1)
    {
      arrItems.push(<ListItem onClick={() => this.props.onZoneSelect(-1)}
                              primaryText={<zone>None</zone>}
                              key={-1}/>)
    }

    let i = 0;
    for(const e of this.props.zoneNames)
    {
      if((e|0) !== zone)
      {
        const elem = <zone>{this.props.zones[e].desc}</zone>
        arrItems.push(
          <ListItem onClick={() => {this.closeZonePopup(); this.props.onZoneSelect(e);}}
                    primaryText={elem}
                    key={i}/>)
      }
      ++i;
    }

    let sZoneLabel = sZone;
    if(arrItems.length > 0)
    {
      sZoneLabel += ' ▾';
    }

    const hasZones = arrItems.length > 0;

    return (
      <div key='NavToolbar' style={{...Styles.InlineFlexRow, justifyContent: 'flex-end',
        width: `calc(100% - ${LayoutDims.wDrawerLeft}px)`, paddingLeft: LayoutDims.nMargin}}>

        {
          hasZones &&
          <div ref='labelZone'
               data-cy='labelZone'
               style={{
                 ...Styles.InlineFlexCol, justifyContent: 'center', minHeight: '100%',
                 cursor: 'pointer', color: Colors.clrLight, fontSize: LayoutDims.FontSize * 1.15
               }}
               onClick={this.onZoneClick}>
            <div>{sZoneLabel}</div>
          </div>
        }

        <div data-cy='labelUserDisplay' style={{textAlign: 'right', color: Colors.clrLight, flex: 1}}>{this.props.username}</div>

        <div style={{width: LayoutDims.wDrawerRight, display: 'inline-flex', justifyContent: 'space-between'}}>
          {
            this.props.motd &&
            <div onClick={ShowMOTD} style={{marginLeft: LayoutDims.nMargin*4}}>
              {
                Icon('notifications_active', {fontSize: 24, color: 'white', cursor: 'pointer'})
              }
            </div>
          }
          &nbsp;
          <img role='presentation'
               style={{float: 'right', cursor:'pointer', height: 24}}
               src={Images.Logo}
               onClick={this.props.onClick}/>
        </div>

        {
            hasZones &&
            <Popover animated={true}
                     style={{...Styles.PopupStyle, overflowY: 'hidden'}}
                     animation={PopoverAnimationVertical}
                     open={this.state.zonesPopupOpen}
                     useLayerForClickAway={true}
                     anchorEl={this.state.anchor}
                     anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                     targetOrigin={{horizontal: 'left', vertical: 'top'}}
                     onRequestClose={this.closeZonePopup}
                     zDepth={1}>
              <Menu style={{minWidth: 250}}>
                <List> {arrItems} </List>
              </Menu>
            </Popover>
        }
      </div>
    );
  }
}

export class SearchBox extends Component
{
  static propTypes =
  {
    onSearch: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    width: PropTypes.string,
    entryType: PropTypes.string,
    onKeyPress: PropTypes.func,
    disabled: PropTypes.bool
  };

  onKeyDown = (evt) =>
  {
    if(evt.key === 'Enter')
    {
      evt.stopPropagation();
      evt.preventDefault();
      return false;
    }
  }

  onKeyPress = (evt) =>
  {
    const elem = evt.target;

    // Clear search on ESC
    let val = '';
    if(evt.key === 'Escape')
    {
      elem.value = '';
      this.props.onSearch(val);
    }
    else
    {
      val = elem.value;
      this.props.onSearch(val);
    }

    if(this.props.onKeyPress)
    {
      this.props.onKeyPress(evt)
    }

    evt.stopPropagation();
    evt.preventDefault();
    return true;
  }

  focus = () => this.inputField.focus();

  render()
  {
    const styleSearch =
    {
      margin: 0,
      border: `${Colors.clrNimbixDark} 1px solid`,
      color: Colors.clrNimbixDark,
      backgroundColor: this.props.disabled ? Colors.clrNimbixGray : Colors.clrLight,
      width: this.props.width || '80%',
      height: LayoutDims.hAppBar / 2,
      flexWrap: 'nowrap',
    };

    const styleEmbed = `
          ::-webkit-input-placeholder {color: ${Colors.clrNimbixDarkTrans};}
          ::-moz-placeholder { color: ${Colors.clrNimbixDarkTrans};}
          :-ms-input-placeholder { color: ${Colors.clrNimbixDarkTrans}; }
          :-moz-placeholder { color: ${Colors.clrNimbixDarkTrans};}`;

    const styleInput =
    {
      minWidth: 64,
      width: '100%',
      color: Colors.clrNimbixDark,
      outline: 'none',
      border: 'none',
      fontSize: 'larger',
      backgroundColor: 'inherit',
    };

    // to do use refs
    return (
      <div style={{...Styles.Inline, ...styleSearch}}>
        <style media='screen' type='text/css'>
          {styleEmbed}
        </style>
        <i className='material-icons' style={{color: Colors.clrNimbixDark, marginLeft: '2px'}}>
          search
        </i>
        <form style={{flex: 1}} autoComplete='off' >
          <input disabled={this.props.disabled}
                 onKeyUp={this.onKeyPress}
                 onKeyDown={this.onKeyDown}
                 ref={(ref) => this.inputField = ref}
                 tabIndex={this.props.tabIndex}
                 placeholder='Search'
                 style={styleInput}
                 data-cy={'searchBox' + this.props.entryType}/>
        </form>

        <div onClick={this.props.onClear.bind(this)}
             style={{color: Colors.clrNimbixDark, cursor: 'pointer', marginRight: '4px'}}>
          ✕
        </div>
      </div>
    );
  }
}

// Toolbar sepearator, just a semi transparent line
export class Separator extends Component
{
  static propTypes =
  {
    units: PropTypes.number,
  };

  render()
  {
    return (
      <div style={{
        backgroundColor: 'rgba(255,255,255,0.15)',
        width: '100%',
        margin: '0px',
        height: (this.props.units || 1) * LayoutDims.nMargin,
        padding: '0px',
      }}></div>
    );
  }
}

// Floating button with text
export class TextLogoButton extends Component
{
  static propTypes =
  {
    onClick: PropTypes.func,
    text: PropTypes.any,
    size: PropTypes.any,
  };

  render()
  {
    const style = {marginRight: LayoutDims.nMargin, fontSize: this.props.size || '1.75em', fontWeight: 'bold'};
    return (
      <FloatingActionButton style={style} secondary mini zDepth={1} onClick={this.props.onClick}>
        <div>{this.props.text}</div>
      </FloatingActionButton>
    );
  }
}

// Toggle switch
export class Toggler extends Component
{
  static propTypes =
  {
    onToggle: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
  };

  constructor(props)
  {
    super(props);
    this.state =
    {
      toggle: false,
    };

    this.whenToggled = this.whenToggled.bind(this);
  }

  whenToggled()
  {
      this.setState({ toggle: !this.state.toggle }, () => {this.props.onToggle(this.state.toggle);});
  }

  render()
  {
    return (
      <div style={Styles.Inline}>
        {Icon(this.props.icon, {margin: '16px 8px 16px 16px'})}
        <Toggle {...this.props} labelStyle={{color: Colors.clrNimbixDark, fontSize: LayoutDims.FontSize}}
                onToggle={this.whenToggled} />
      </div>
    );
  }
}

// Text wrapper
export class Text extends Component
{
  static propTypes =
  {
    children: PropTypes.any,
    style: PropTypes.object,
  };

  render()
  {
    return (
      <div {...this.props} style={{...this.props.style, fontFamily: 'Roboto, Sans Serif'}} >
        {this.props.children}
      </div>);
  }
}

// Simple horizontal line
export class HLine extends Component
{
  static propTypes =
  {
    thickness: PropTypes.number,
    margin: PropTypes.number,
    color: PropTypes.string,
  };

  render()
  {
    const margin = this.props.margin == null ? LayoutDims.nMargin * 2 : this.props.margin;
    return (
      <hr style={{
        margin,
        height: this.props.thickness || 1,
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: this.props.color || Colors.clrLine,
        border: 'none',
        width: this.props.width
      }} />
    );
  }
}

// Favorite star
export class Star extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {toggled: false};
  }

  render()
  {
    const dctStyleOn =
    {
      color: '#000000',
      webkitTextFillColor: '#FFFFFF',
      webkitTextStroke: 1,
      webkitTextStrokeColor: '#000000',
    };

    return <i style={this.state.toggled ? dctStyleOn : {}} className='material-icons'>grade</i>;
  }
}

// Modal dialog
export class ModalView extends Component
{
  static propTypes =
  {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onKeyPress: PropTypes.func,
    children: PropTypes.any,
    noCloseBox: PropTypes.bool,
    topMost: PropTypes.bool,
    width: PropTypes.any,
    height: PropTypes.any,
    maxHeight: PropTypes.any,
  };

  constructor(props)
  {
    super(props);
    this.state =
    {
      open: props.open,
      tag: props.tag,
      width: this.props.width,
      height: 'auto',
      top: this.props.top == null ? LayoutDims.hAppBar : this.props.top
    };
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({open: nextProps.open});
    if(nextProps.width)
    {
      this.setState({width: nextProps.width});
    }

    if(nextProps.height)
    {
      this.setState({height: nextProps.height});
    }

    if(nextProps.top != null)
    {
      this.setState({top: nextProps.top});
    }

    if(nextProps.tag != null)
    {
      this.setState({tag: nextProps.tag});
    }
  }

  // Close the dialog when esc is pressed if noEscape is false
  onKeyPress = (evt) =>
  {
    if(this.props.onKeyPress)
    {
      return this.props.onKeyPress(evt);
    }

    if(this.props.onClose && evt.key === 'Escape' && !this.props.noEscape)
    {
      this.props.onClose();
    }

    return true;
  };

  render()
  {
    // A semi transparent flex box that spans the whole screen and darkens underlying content
    // there is one box on either side of the central dialog like this
    // |      |         X|       |
    // | LEFT | DIALOG   | RIGHT |
    // |      |          |       |
    // The X button is actually in the right box with negative left margin to make it appear
    // on top of the dialog content

    const styleModal =
    {
      display: this.state.open ? 'inline-flex' : 'none',
      position: 'absolute',
      zIndex: this.props.topMost ? 1999 : 1998,
      left: 0,
      top: 0,
      width: '100%',
      minHeight: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: 'rgba(0,0,0,0.7)',
    };

    // Style of each box on either side of the central dialog area
    const styleModalMargin =
    {
      marginTop: this.state.top,
      flex: 1,
      zIndex: 1999,
    };

    // Style of the central dialog area (background is transparent)
    const styleModalContent =
    {
      backgroundColor: 'transparent',
      marginTop: this.state.top,
      marginBottom: LayoutDims.nMargin * 4,
      width: this.state.width || LayoutDims.wContent,
      height: this.state.height,
      maxHeight: this.state.maxHeight || '100%',
      overflow: 'hidden'
    };

    // The X button to close the dialog which is part of the right hand box
    const styleCloseBtn =
    {
      marginLeft: -40,
      marginTop: 8,
      cursor: 'pointer',
      fontSize: '32px',
      color: Colors.clrNimbixDark,
    };

    if(this.state.tag)
    {
      window.dialogOpen = this.state.open;
    }

    return (
      <div name={this.props.name} ref={(e) => {if(e !== null) {e.focus();}}} onKeyUp={this.onKeyPress}
           tabIndex={1} style={styleModal}>

        <div style={styleModalMargin}>&#8239;</div>

        <div style={styleModalContent}> {this.props.children} </div>

        <div style={styleModalMargin}>
        {
          !this.props.noCloseBox
          ?
            <div data-cy='btnModalClose' name='closebtn' onClick={this.props.onClose} style={styleCloseBtn}>✕</div>
          :
            <div>&#8239;</div>
        }
        </div>

      </div>
    );
  }
}

// Header text in a section view
export class SectionViewHeader extends Component
{
  static propTypes =
  {
    fontSize: PropTypes.number,
    headerText: PropTypes.string,
    link: PropTypes.any // A small link text shown at the right end
  };

  render()
  {
    if(!this.props.headerText) return null;

    const styleMargin = {marginTop: LayoutDims.nMargin * 2};
    return (
      <div style={{width: '100%'}}>
        <Text style={{...Styles.FlexSpread, alignItems: 'baseline'}}>
          <div style={{...styleMargin, marginLeft: LayoutDims.nMargin, marginBottom: 2,
            fontSize: this.props.fontSize || 24, color: Colors.clrNimbixDark,
            textTransform: 'capitalize'}}>
            {this.props.headerText}
          </div>
          {this.props.link}
        </Text>

        <HLine margin={LayoutDims.nMargin} color={Colors.clrNimbixDark} thickness={2}/>
      </div>
    );
  }
}

export class CollapsibleView extends Component
{
  static propTypes =
  {
    collapsedSize: PropTypes.number,
    collapsedText: PropTypes.string,
    expandedText: PropTypes.string,
    expanded: PropTypes.bool,
    children: PropTypes.any,
    sectionName: PropTypes.string,
  };

  constructor(props)
  {
    super(props);
    this.state =
    {
      expanded: CollapsibleView.expandState[props.sectionName],
      collapsedText: props.collapsedText || 'More',
      collapsedSize: props.collapsedSize === null ? 8 : props.collapsedSize,
      expandedText: props.expandedText || 'Less',
    };

    this.firstRender = true;
  }

  static expandState = {};

  toggleExpand()
  {
    this.setState({expanded: !this.state.expanded});
    CollapsibleView.expandState[this.props.sectionName] = !this.state.expanded;
  }

  // Gets called when the view changes
  componentWillReceiveProps(nextProps)
  {
    this.firstRender = true;
    this.setState({expanded: CollapsibleView.expandState[this.props.sectionName]});
  }

  render()
  {
    const styleLink = {cursor: 'pointer', color: Colors.clrNimbixDark};
    const styleContainer =
    {
      ...Styles.Inline,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    };

    // If this is rendering the first time after new props
    let bShowExpander = false;
    let bExpanded = true;
    let height = this.state.collapsedSize;
    if(this.firstRender)
    {
      // Render the view fully so we can measure the contents
      // Schedule a re-render
      window.setTimeout(()=>this.forceUpdate(), 10);
      this.firstRender = false;
    }
    else
    {
      // Expander shown only if contents is taller than this view
      bShowExpander = this.refs.contents.clientHeight > this.state.collapsedSize;
      bExpanded = bShowExpander && this.state.expanded;
      height = bExpanded ? this.refs.contents.clientHeight : this.state.collapsedSize;
    }

    const styleOuter =
    {
      width: '100%',
      height: height,
      overflowY: 'hidden',
      transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
    };

    return (
      <div>
        <div style={styleOuter}>
          <div ref='contents' style={styleContainer} children={this.props.children}/>
        </div>
        <Separator units={1}/>
        <div>
        {
          bShowExpander &&
          <div className='collapserButton' style={{...Styles.Inline, justifyContent: 'center'}}>
            <div style={{...Styles.Inline, width: 'initial'}} onClick={this.toggleExpand.bind(this)}>
              {Icon(this.state.expanded ? 'expand_less' : 'expand_more', styleLink)}

              <Text style={styleLink}>
                {bExpanded ? this.state.expandedText : this.state.collapsedText}
              </Text>
            </div>
          </div>
        }
        </div>

      </div>
    );
  }
}

// View with  header text, underline and a collapsble view
export class SectionView extends Component
{
  static propTypes =
  {
    headerText: PropTypes.string,
    showLine: PropTypes.bool.isRequired,
    collapsedSize: PropTypes.number,
    headerFontSize: PropTypes.number,
    noCollapse: PropTypes.bool,
    children: PropTypes.any,
    stylePane: PropTypes.any,
    sectionName: PropTypes.string,
  };

  render()
  {
    const paneContent = this.props.noCollapse ?
      <div id={this.props.headerText} style={this.props.stylePane} >{this.props.children}</div> :
      <CollapsibleView collapsedSize={this.props.collapsedSize}
                       children={this.props.children}
                       sectionName={this.props.sectionName}
      />;

    return (
      <div>
        <SectionViewHeader fontSize={this.props.headerFontSize} headerText={this.props.headerText}
                           ref='viewHeader'/>
        {paneContent}
      </div>
    );
  }
}

// Search box for apps etc
export class SearchPane extends Component
{
  static propTypes =
  {
    // Event handlers
    onSearch: PropTypes.func.isRequired,
    onSearchClear: PropTypes.func.isRequired,
  };

  constructor(props)
  {
    super(props);
    this.state = {searchText: ''};
  }

  render()
  {
    return (
      <div style={{...Styles.Inline, justifyContent: 'center', marginTop: LayoutDims.nMargin * 2}}>
        <SearchBox entryType={this.props.entryType} ref={(ref) => this.searchBox = ref} searchText={this.state.searchText}
                   onSearch={this.props.onSearch}
                   onClear={this.props.onSearchClear}/>
      </div>
    );
  }
}

export class TimeView extends Component
{
  static propTypes =
  {
    secStart: PropTypes.number,
    timeScale: PropTypes.number,
  };


  constructor(props)
  {
    super(props);
    this.state =
    {
      secStart: this.props.secStart,
      timeScale: this.props.timeScale | 1,
      color: this.props.color
    };


    // Refresh the timer faster if the timescale is faster
    let refresh = 30000 / this.state.timeScale;
    if(refresh < 1000)
    {
      refresh = 1000;
    }
    this.timerID = window.setInterval(()=>this.forceUpdate(), refresh);
  }

  // This method is called before the component is removed
  componentWillUnmount()
  {
    clearInterval(this.timerID);
  }

  componentWillReceiveProps(nextProps)
  {
    if(this.state.secStart !== nextProps.secStart)
    {
      this.setState({secStart: nextProps.secStart});
    }

    if(this.state.timeScale !== (nextProps.timeScale | 1))
    {
      this.setState({timeScale: nextProps.timeScale | 1});
    }
  }

  render()
  {
    let timeDisplay;
    if(this.state.secStart)
    {
      let timeDiff = ((Date.now() / 1000) | 0) - this.state.secStart;
      timeDiff = Math.max(0, timeDiff);
      const [HH, MM] = getHHMM(timeDiff * this.state.timeScale);
      timeDisplay =
        <div onMouseEnter={this.props.onMouseEnter}
             onMouseLeave={this.props.onMouseLeave}
             style={{...Styles.Inline, flexFlow: 'row nowrap', cursor: 'default'}}>
          {HH}
            <div className='blinker'>:</div>
          {MM}
        </div>;
    }
    else
    {
      timeDisplay = <div className='blinker'>00:00</div>;
    }

    const style = this.props.style ||
    {
      fontSize: 'xx-large',
      color: this.props.color,
    };

    if(!this.props.style && !this.props.noShadow)
    {
      style.textShadow = '2px 2px 12px black';
    }

    return (
      <div className='seven-seg' style={style}>
        {timeDisplay}
      </div>
    );
  }
}

// Helper to forward events between components transparently, like signal/slots mechanism
// Example Usage : See AppSearcher
export class EventMap
{
  constructor()
  {
    this.map = {};
  }

  // Makes signal connect to that.refs.dest.slot(...)
  // If slot not specified, it assumes same name as signal
  connect = (that, signal, dest, slot) =>
  {
    this.map[signal] = function(){ that.refs[dest][slot || signal].call(that.refs[dest], ...arguments); };
  };

  // Makes each signal in signals[] connect to that.refs.dest.signal(...)
  connectAll(that, signals, dest)
  {
    signals.map((signal) => this.connect(that, signal, dest));
  }
}

// Helper to show and hide panes
export class SwitchedPane extends Component
{
  static propTypes =
  {
    paneName: PropTypes.string.isRequired,
    children: PropTypes.any,
    paneGroup: PropTypes.string.isRequired,
    active: PropTypes.bool,
    iconName: PropTypes.string, // optional material icon name
    fullHeight: PropTypes.bool  // Whether the pane is full height
  };

  static paneGroupStateMap = {};
  static paneGroupCallbacks = {};
  static paneRefs = {};
  static paneIcons = {};

  // Get the name of the pane that's active in a group
  static getActivePane(paneGroup)
  {
    return SwitchedPane.paneGroupStateMap[paneGroup];
  }

  // Sets the pane active for all groups specified
  // To be called before rendering
  static setDefault(arrPaneGroup, paneName)
  {
    for(const paneGroup of arrPaneGroup)
    {
      // If its not already set (by local storage), set it
      if(!SwitchedPane.paneGroupStateMap[paneGroup])
      {
        SwitchedPane.paneGroupStateMap[paneGroup] = paneName;
      }
    }
  }

  // Add a function to be called when this pane is switched to
  static RegisterOnSwitch(panePath, fnCallback)
  {
    // paneGroupCallbacks is an array
    if(!SwitchedPane.paneGroupCallbacks[panePath])
    {
      SwitchedPane.paneGroupCallbacks[panePath] = [];
    }

    // Add this function to the array
    SwitchedPane.paneGroupCallbacks[panePath].push(fnCallback);
  }

  static switch(paneGroup, paneName)
  {
    // Set this pane groups active pane to this pane
    SwitchedPane.paneGroupStateMap[paneGroup] = paneName;

    // Save the active pane to local storage unless pane name it starts with '@'
    if(paneGroup[0] !== '@')
    {
      LocalStorage.set(paneGroup, paneName);
    }

    // Force the instance of all panes in that group to re-render
    const sPaneGroupSlash = paneGroup + '/';
    const sPanePath = sPaneGroupSlash + paneName;
    for(const s of Object.keys(SwitchedPane.paneRefs))
    {
      if(s.indexOf(sPaneGroupSlash) === 0)
      {
        SwitchedPane.paneRefs[s].forceUpdate();

        // Call any registered callbacks
        if(s === sPanePath && SwitchedPane.paneGroupCallbacks[s])
        {
          const arrCallbacks = SwitchedPane.paneGroupCallbacks[s];
          for(let i = 0; i < arrCallbacks.length; ++i)
          {
            arrCallbacks[i]();
          }
        }
      }
    }

    // Tell any drawers that this changed
    // eslint-disable-next-line
    const drawer = SwitchedPaneDrawerItems.Drawers[paneGroup];
    if(drawer)
    {
      drawer.highlight(paneName);
    }
  }

  static getIcon(paneGroup, paneName)
  {
    return SwitchedPane.paneIcons[`${paneGroup}/${paneName}`];
  }

  // Gets the pane names of a given group
  static getPaneNames(paneGroup)
  {
    const sPaneGroupSlash = paneGroup + '/';
    return Object.keys(SwitchedPane.paneRefs).filter((s)=>s.indexOf(sPaneGroupSlash) === 0)
      .map((s)=>s.slice(sPaneGroupSlash.length));
  }

  constructor(props)
  {
    super(props);

    // If pane is active, set the pane name active in the state map
    // Else set it from local storage
    const sPane = props.active ? props.paneName: LocalStorage.get(props.paneGroup);
    if(sPane)
    {
      SwitchedPane.paneGroupStateMap[props.paneGroup] = sPane;
    }

    // Save this instance pointer and icon under "group/pane"
    const sPanePath = `${props.paneGroup}/${props.paneName}`;
    SwitchedPane.paneRefs[sPanePath] = this;
    SwitchedPane.paneIcons[sPanePath] = props.iconName;
  }

  render()
  {
    const isVisible = SwitchedPane.paneGroupStateMap[this.props.paneGroup] === this.props.paneName;
    const id = `${this.props.paneGroup}/${this.props.paneName}`;
    return (
      <div data-cy={id} id={id} className={isVisible ? 'show' : 'hide'}
           style={{width: '100%', ...(this.props.fullHeight ? {height:'100%'} : {})}}>
        {this.props.children}
      </div>
    );
  }
}

// Table with header that looks like a tab
export const TableFixedHeaderTab = (props) =>
{
  return (
    <table style={{...Styles.Table, margin: `0px ${LayoutDims.nMargin*2}px`,
      width: `calc(90% - ${LayoutDims.nMargin*4}px)`}} >
      <colgroup>
        <col style={{width: '100%'}}/>
      </colgroup>
      <thead>
      <tr>
        <th style={Styles.TableHeaderTab}>{props.title}</th>
      </tr>
      </thead>
    </table>
  );
};

TableFixedHeaderTab.propTypes =
{
  title: PropTypes.string.isRequired,
};

export const ParamColGroup = () =>
{
  return (
    <colgroup>
      <col style={{width: '20%'}}/>
      <col style={{width: '80%'}}/>
    </colgroup>
  );
};

// Label and field under it
export const InputRow = (props) =>
{
  return (
    <div style={{paddingBottom: LayoutDims.nMargin * 2}}>
      <div style={{padding:2}}>
        {props.title}
      </div>
      <div style={{width: props.width || 400}}>
        {props.children}
      </div>
    </div>
  );
};

// Returns the list items for navigating a given Switched panes entries
export class SwitchedPaneDrawerItems extends Component
{
  static propTypes =
  {
    paneGroup: PropTypes.string.isRequired,
    contentPane: PropTypes.object.isRequired
  };

  constructor(props)
  {
    super(props);
    this.state = {activePane: SwitchedPane.getActivePane(this.props.paneGroup)};
    SwitchedPaneDrawerItems.Drawers[this.props.paneGroup] = this;
  }

  onSelectItem(s)
  {
    this.props.contentPane.switchPane(s);
    this.highlight(s);
  }

  highlight(s)
  {
    this.setState({activePane: s});
  }

  render()
  {
    return (
      <div>
        {
          SwitchedPane.getPaneNames(this.props.paneGroup).map
          (
            (s, i)=>
            {
              const sIcon = SwitchedPane.getIcon(this.props.paneGroup, s);
              const style =
              {
                color: Colors.clrNimbixDark,
                backgroundColor: s === this.state.activePane ? Colors.clrNimbixBlueGray: Colors.clrLight,
                fontSize: LayoutDims.FontSize
              };

              return (
                [
                  <ListItem key={0} data-cy={'menuView' + s} style={style} leftAvatar={Icon(sIcon)} onClick={()=>this.onSelectItem(s)}>
                    {s}
                  </ListItem>,
                  <Divider key={1}/>
                ]
              );
            }
          )
        }
      </div>
    )
  }
}

// Static store of the SwitchedPaneDrawerItems object for each pane group
SwitchedPaneDrawerItems.Drawers = {};

// Encapsulate a file chooser - renders a hidden file input field and exposes
// callbacks as properties to handle file read etc
export class FileChooser extends Component
{
  static propTypes =
  {
    filter: PropTypes.string,                 // MIME type of files to allow
    validate: PropTypes.func,                 // function called on the file object
    onRead: PropTypes.func.isRequired,        // File data callback
    asURL: PropTypes.bool.isRequired,         // Whether to read raw or as data URI
    cyTag: PropTypes.string,
  };

  // When a file is selected
  onChangeFile = (event) =>
  {
    const file = event.target.files[0];
    if(this.props.validate)
    {
      if(!this.props.validate(file))
        return false;
    }

    // Setup a file reader and read it
    const reader = new FileReader();
    reader.onload = (e) => {this.props.onRead(e.target.result)};
    if(this.props.asURL)
    {
      reader.readAsDataURL(file);
    }
    else
    {
      reader.readAsText(file);
    }
  };

  open()
  {
    this.refs.inputFile.value = '';
    this.refs.inputFile.click();
  }

  render()
  {
    return <input data-cy={this.props.cyTag || 'file'} type="file" ref='inputFile' accept={this.props.filter}
                  className='hide' onChange={this.onChangeFile}/>;
  }
}

// Allows one to save arbitrary generated MIME data as a download
export class RawDataDownloader extends Component
{

  static propTypes =
  {
    name: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  };

  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps});
  }

  save = (data) =>
  {
    const a = this.refs.downloadLink;
    a.href = `data:application/octet-stream;charset=utf-8,${encodeURIComponent(data)}`;
    a.click();
  }

  render()
  {
    return (
      <div>
        <iframe title='dummy' className='hide' name={this.props.name}>
        {/*
           This blank dummy iframe is used as the target of the download link to prevent
           the browser switching to and fro to a blank tab
        */}
        </iframe>

        <a className='hide'
           target={this.props.name}
           download={this.state.filename}
           ref='downloadLink'/>
        
      </div>
    );
  }
}


export const fromThis = (that, arrPropNames) =>
{
  const props = {};
  for(const k of arrPropNames)
  {
    props[k] = that[k];
  }

  return props;
};


export const fromProps = (that, arrPropNames) =>
{
  const props = {};
  for(const k of arrPropNames)
  {
    props[k] = that.props[k];
  }

  return props;
};

export class CloneAppBtn extends Component
{
  onClick = (evt) =>
  {
    GlobalUI.PaneAppCard.cloneJob(this.props.jobnum);
  };

  render()
  {
    return (
      <i data-cy='buttonClone' className='material-icons' onClick={this.onClick} title='Clone this job'
         style={{margin:4, color: Colors.clrNimbixDark, fontSize: this.props.size || 30,
           padding: 0, cursor: 'pointer', transform:'scale(1,0.8)'}}>
        content_copy
      </i>
    );
  }
}

// Hides or shows its children based on a flag
export class ShowOnly extends Component
{
  render()
  {
    return (
      <div style={this.props.style}>
        <div className={this.props.if ? 'show' : 'hide'}>
          {this.props.children}
        </div>

        {
          this.props.otherwise &&
          <div className={this.props.if ? 'hide' : 'show'}>
            {this.props.otherwise}
          </div>
        }
      </div>
    );
  }
}

// App icon wrapper - loads default icon if image load fails
export class AppIcon extends Component
{
  static propTypes =
  {
    app: PropTypes.string,
    admin: PropTypes.bool,
    onLoad: PropTypes.func
  };

  constructor(props)
  {
    super(props);
    this.state = {...props, img: GetAppImgSrc(props.app, props.admin)};
  }

  componentWillReceiveProps(nextProps)
  {
    const img = GetAppImgSrc(nextProps.app, nextProps.admin);
    this.setState({...nextProps, img});
    this.refs.img.src = img;
  }

  render()
  {
    return <img ref='img' src={this.state.img}
                onError={()=>this.refs.img.src = Images.DefaultIcon}
                onLoad={()=>this.state.onLoad && this.state.onLoad(this.refs.img)}
                style={this.state.style}/>
  }
}

// Returns an array of <td> given the text in an array as props.cols
export const TableHeaderCells = (cols, sortCol, sortAsc, onClick, style, hasExtraButton=false) =>
{
  onClick = onClick || Nop;
  let ret = null;
  if(cols)
  {
    ret = hasExtraButton ? [<th key={-1} style={Styles.TableHeader}/>] : [];
    ret = ret.concat(
      cols.map
      (
        (e, i) =>
        {
          return (
            <th key={i} onClick={()=>onClick(i)}
                style={{...Styles.TableHeader, cursor: sortCol ? 'pointer': 'default', ...style}}>
              {e}
              <div style={{float: 'right', minWidth: 12}}>
                {(sortCol === i ? sortAsc ? '▴' : '▾' : ' ')}
              </div>
            </th>
          );
        }
      )
    )
  }

  return ret;
};

// Returns an array of <td> given the text in an array as props.cells
// Optionally specify a dict to map values form
export const TableCellsBordered = (cells, dict) =>
{

  return cells && cells.map((e, i) =>
      <td key={i} style={Styles.TableCellBordered}>{dict ? dict[e] : e}</td>);
};

// returns an array of <tr> with <td> named for each entry in col and value as
// props.row[i][col]
export const TableRowsBordered = (rows, cols, style, onClickRow, getExtraStyle, selectedIndex=-1, extraButton=null, onClickCell=null, fnRowRef, onDoubleClickRow) =>
{
  let styleCell = {...Styles.TableCellBordered, ...style};
  let propOnClickCell = {onClick: onClickCell ? onClickCell : Nop}
  onClickRow = onClickRow || Nop;
  onDoubleClickRow = onDoubleClickRow || Nop;

  return rows.map
  (
    (row, j)=>
    {
      const isSel = row.id === selectedIndex;
      let styleTR = isSel ? Styles.TRSel : Styles.TRDesel;
      let styleTD = isSel ? Styles.TDSel : Styles.TDDesel;

      if(getExtraStyle)
      {
        styleTD = {...styleTD, ...getExtraStyle(row)}
      }

      // Call the ref function with both the ref and index
      const fnRef = fnRowRef ? (ref)=>fnRowRef(ref, j) : Nop;

      return (
        <tr key={'row' + j} ref={fnRef} onClick={()=>onClickRow(row.id)} onDoubleClick={()=>onDoubleClickRow(row.id)}  style={styleTR}>
        {
          extraButton &&
          <td key='button' style={{styleCell, ...styleTD, ...{width: 32, padding: 0, margin: 'auto'}}}>
          {
            extraButton(row)
          }
          </td>
        }

        {
          cols.map
          (
            (col, i) =>
              <td key={'col' + i} style={{styleCell, ...styleTD}} {...propOnClickCell}>
              {
                row[col]
              }
              </td>
          )
        }
        </tr>
      );
    }
  );
};


// Report table
export class ReportDisplay extends Component
{
  static propTypes =
  {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    colText: PropTypes.arrayOf(PropTypes.string).isRequired,
    colFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    summary: PropTypes.arrayOf(PropTypes.any),
    colTypes: PropTypes.object,
    onClickRow: PropTypes.func,
    onClickCell: PropTypes.func,
    selectedIndex: PropTypes.number,
    getExtraStyle: PropTypes.func,
    extraButton: PropTypes.func,   // If this is specified, its called to render as the first column
                                   // the parameter passed is the row
    colGroup: PropTypes.object,    // pass a col group here if any
    sortCol: PropTypes.number,
    sortAsc: PropTypes.bool,
    noSort: PropTypes.bool,       // Disable sorting

    fixedRows: PropTypes.number,  // Rows at the top that will remained pinned even after sorts

    styleTable: PropTypes.object, // Style override for the table
  };

  constructor(props)
  {
    super(props);
    const sortCol = props.sortCol || 0;       // current sort column
    const sortAsc = props.sortAsc || true;    // ascending/descending
    this.state =
    {
      sortCol,
      sortAsc,

      // Copy of data, sorted
      dataSorted: this.getSortedData(props.data, sortCol, sortAsc),
    };

    // List of row indices as per current sort order
    this.arrIDX = [];

    // List of refs to each table row
    this.rowRefs = [];
  }

  // When data changes, sort according to current criteria, and set the selected index
  componentWillReceiveProps(nextProps)
  {
    this.setState
    (
      {
        dataSorted: this.getSortedData(nextProps.data, this.state.sortCol, this.state.sortAsc)
      }
    );
  }

  // Returns a (stably) sorted deep copy of data, based on criteria
  getSortedData = (data, sortCol, sortAsc) =>
  {
    // Make an index array for stable sorting
    let arrIDX = [];

    if(!data.length) return [];

    const fixedRows = this.props.fixedRows || 0;
    for(let i = fixedRows; i < data.length; ++i)
    {
      arrIDX.push(i);
    }

    let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

    // Get the column name and type
    let colName = this.props.colFields[sortCol];

    // Get the column type if any
    const colType = this.props.colTypes && this.props.colTypes[colName];

    // Is this column a number?
    let isNum = colType === 'number';

    // If col type itself is a numeric constant, it indicates how many chars to pad
    const isPad = typeof(colType) === 'number';

    // HHHHH:MM:SS format
    let isTime = colType === 'time';

    // If col type is a unknown string, treat that as an alternate proxy column to actually sort on
    // use numeric collation sort
    if(!isNum && !isTime && typeof(colType) === 'string')
    {
      colName = colType;

      // Proxy column may itself be a number!
      isNum = this.props.colTypes[colName];
    }

    arrIDX.sort
    (
      (a, b) =>
      {
        // Convert to uppercase for case insensitive compare
        // Protect against nulls and handle numeric and HHHHH:MM:SS sort
        const da = data[a][colName];
        const db = data[b][colName];

        let va = isNum ? parseFloat(da) : (isTime ? da.replace(/:/g, '') : safeToUpper(da));
        let vb = isNum ? parseFloat(db) : (isTime ? db.replace(/:/g, '') : safeToUpper(db));

        if(isPad)
        {
          va = padLeft(va, colType);
          vb = padLeft(vb, colType);
        }

        // No two items can be equal because we use the index as the tie breaker

        const n = collator.compare(va, vb);
        if(sortAsc)
        {
          if(n !== 0) return n;
        }
        else
        {
          if(n !== 0) return -n;
        }

        if(sortAsc)
        {
          if(data[a].id < data[b].id) return -1;
          if(data[a].id > data[b].id) return 1;
        }
        else
        {
          if(data[a].id > data[b].id) return -1;
          if(data[a].id < data[b].id) return 1;
        }

        // Will actually never reach here unless row.id values are not unique
        return a < b ? -1 : (a > b ? 1 : 0);
      }
    );

    // Make the sorted data array and return it
    const dataSorted = [];

    for(let i = 0; i < fixedRows; ++i)
    {
      dataSorted.push(data[i]);
    }

    for(let i = 0; i < (data.length - fixedRows); ++i)
    {
      dataSorted.push(data[arrIDX[i]]);
    }

    // Save the index array for the parent to be able to iterate
    this.arrIDX = [];
    for(let i = 0; i < fixedRows; ++i)
    {
      this.arrIDX.push(i);
    }

    for(let i = 0; i < (data.length - fixedRows); ++i)
    {
      this.arrIDX.push(arrIDX[i]);
    }

    return dataSorted;
  }

  // Handle sorting When header clicked
  onClickHeader = (idx) =>
  {
    let sortCol = this.state.sortCol;
    let sortAsc = this.state.sortAsc;

    // If same header clicked, invert the sort order
    if(idx === sortCol)
    {
      sortAsc = !sortAsc;
    }
    else // sort ascending by new column
    {
      sortCol = idx;
      sortAsc = true;
    }

    // Update the data and the sort indicator
    this.setState
    (
      {
        dataSorted: this.getSortedData(this.state.dataSorted, sortCol, sortAsc),
        sortCol,
        sortAsc
      }
    );
  }

  scrollRowIntoView = (idx, isUp) => this.rowRefs[idx].scrollIntoView({behavior: 'smooth', block: isUp ? 'start' : 'end'});

  rowVisible = (idx, viewportTop, viewportBottom) =>
  {
    const rcElem = this.rowRefs[idx].getBoundingClientRect();
    return rcElem.top >= viewportTop && rcElem.bottom <= viewportBottom;
  }

  render()
  {
    this.tableRows = TableRowsBordered
    (
      this.state.dataSorted,
      this.props.colFields,
      null,
      this.props.onClickRow,
      this.props.getExtraStyle,
      this.props.selectedIndex,
      this.props.extraButton,
      this.props.onClickCell,
      (ref, j) => this.rowRefs[j] = ref,
      this.props.onDoubleClickRow,
    );

    return (
      <table data-cy='reportDisplay' style={{...Styles.Table, ...Styles.ThickBordered, width: '100%', ...this.props.styleTable}}>
        {this.props.colGroup}
        <thead>
          <tr style={{userSelect: 'none'}}>
            {
              TableHeaderCells
              (
                this.props.colText,
                this.state.sortCol,
                this.state.sortAsc,
                this.props.noSort? null : this.onClickHeader,
                null,
                this.props.extraButton
              )
            }
          </tr>
        </thead>

        <tbody>{this.tableRows}</tbody>

        {
          this.props.summary &&
          <thead>
          <tr>
            {TableHeaderCells(this.props.summary)}
          </tr>
          </thead>
        }
      </table>
    );
  }
}


// Field with a ▼ button on the right
// The button is just for looks, popup activates regardless of where you click
export class InputMenu extends Component
{
  static propTypes =
  {
    onDrop: PropTypes.func.isRequired,  // Function called when control is clicked
    title: PropTypes.string.isRequired,   // Label shown on top
    text: PropTypes.string.isRequired,   // Value
    onClear: PropTypes.func.isRequired,
    width: PropTypes.number,              // Width of text field
  };


  static styleDropBtn = null;
  static styleClearBtn = null;
  static styleInput = null
  static styleCtr = null;

  // Field that serves as the anchor
  anchor = () => this.refs.inputField;

  render()
  {
    if(!InputMenu.styleDropBtn)
    {

      InputMenu.height = LayoutDims.FontSize + 4;

      InputMenu.styleDropBtn =
      {
        minHeight: InputMenu.height,
        minWidth: InputMenu.height + 4,
        margin: 0,
        textAlign: 'center',
        backgroundColor: PaletteMain.palette.disabledColor,
        cursor: 'default',
        ...Styles.Bordered,
      }

      InputMenu.styleClearBtn =
      {
        width: InputMenu.height,
        paddingTop: 2,
        textAlign: 'center',
        cursor: 'default',
        ...Styles.Bordered,
        borderLeft: 'none',
        color: Colors.clrNimbixDark
      }

      InputMenu.styleInput =
      {
        ...Styles.ParamInput,
        minHeight: InputMenu.height,
        height: InputMenu.height,
        wordBreak: 'break-all',
        borderRight: 'none'
      };

      InputMenu.styleCtr = {...Styles.FlexSpread, minWidth: 400, alignItems:'stretch'};

    }


    return (
      <InputRow title={this.props.title} width={this.props.width} >
        <div style={InputMenu.styleCtr}>

          <div onClick={this.props.onDrop} style={InputMenu.styleInput} ref='inputField'>
            {this.props.text}
          </div>

          <div title='Clear Filter' className={this.props.text ? 'show' : 'hide'}
               onClick={this.props.onClear}
               style={InputMenu.styleClearBtn}>✕</div>

          <div onClick={this.props.onDrop} style={InputMenu.styleDropBtn}>▾</div>

        </div>
      </InputRow>
    );
  }
}

// Popover with a List view in it
export class OptionPopup extends Component
{
  static propTypes =
    {
      anchorElem: PropTypes.object,  // Where to anchor the menu
      open: PropTypes.bool.isRequired,
      onClose: PropTypes.func.isRequired,
      items: PropTypes.array.isRequired,
    };

  render()
  {
    // We have to keep the list inside a menu to allow animation!
    return(
      <Popover animated={true}
               style={{...Styles.PopupStyle}}
               animation={PopoverAnimationVertical}
               canAutoPosition={true}
               open={this.props.open}
               anchorEl={this.props.anchorElem}
               anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
               targetOrigin={{horizontal: 'left', vertical: 'top'}}
               useLayerForClickAway={true}
               onRequestClose={this.props.onClose}
               zDepth={3} >
        <Menu>
          <List>{this.props.items}</List>
        </Menu>
      </Popover>
    );
  }
}

// Dropdown with list items
export class OptionDropDown extends Component
{
  static propTypes =
    {
      items: PropTypes.array.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      width: PropTypes.any,              // Width of text field
      onClear: PropTypes.func,
      inputField: PropTypes.any,          // override the input component
    };

  constructor(props)
  {
    super(props);
    this.state={open: false, text: this.props.text};
  }

  onClear = (evt) =>
  {
    this.setState({text: ''});
    evt.preventDefault();
    this.props.onClear();
    return false;
  }

  // When opening, set the anchor to the input field
  openPopup = (elemAnchor) =>
  {
    const input = this.refs.input;
    this.setState({open: true, anchor: input ? input.anchor() : elemAnchor});
  }

  closePopup = () => this.setState({open: false});

  // Handle props updation
  componentWillReceiveProps(nextProps)
  {
    this.setState({text: nextProps.text});
  }

  render()
  {
    return(
      <div>
        {
          this.props.inputField
          ||
          <InputMenu width={this.props.width}
                     text={this.state.text}
                     title={this.props.title}
                     onDrop={this.openPopup}
                     onClear={this.onClear}
                     ref='input'/>
        }

        <OptionPopup anchorElem={this.state.anchor}
                     open={this.state.open}
                     onClose={this.closePopup}
                     items={this.props.items} />
      </div>
    );
  }
}


// Creates a list of checkbox items for use in a multi select menu popup
// arrItems contains the options
// The selected items are read and written to pOwner.state[sStateVar]
// List Item keys are numbers prefixed with sKeyPrefix
// List display text is generated by fnText applied on list items (if specified)
export function CheckListItems(pOwner, arrItems, sStateVar, sKeyPrefix, fnText)
{
  return arrItems.map
  (
    (s, i) =>
    {
      // on checkbox click, either remove or add to the set
      const onCheck = (e, v) =>
      {
        let k = new Set(pOwner.state[sStateVar]);
        if(v)
        {
          k.add(s);
        }
        else
        {
          k.delete(s);
        }

        pOwner.setState({[sStateVar]: k});
      };

      const elemCheckBox = <Checkbox checked={pOwner.state[sStateVar].has(s)} onCheck={onCheck}/>
      return <ListItem primaryText={fnText ? fnText(s) : s} key={sKeyPrefix + i} leftCheckbox={elemCheckBox}/>
    }
  );
}


// Pager interface
export class Pager extends Component
{
  static propTypes =
  {
    pagecount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    paneGroup: PropTypes.string.isRequired,
    onPrevPage : PropTypes.func.isRequired,
    onNextPage : PropTypes.func.isRequired,
    onNavPage : PropTypes.func.isRequired,

    disable: PropTypes.bool
  }

  constructor(props)
  {
    super(props);
    this.state = {pagecount: this.props.pagecount || 1, page: 1, disable: false};
  }

  componentWillReceiveProps(nextProps)
  {
    if(nextProps.pagecount)
      this.setState({pagecount: nextProps.pagecount});

    if(nextProps.page)
      this.setState({page: nextProps.page});

    // Handle disable state change, make sure the up down edit control is hidden if disabled
    this.setState({disable: nextProps.disable});
    if(nextProps.disable)
    {
      SwitchedPane.switch(this.props.paneGroup, 'Label');
    }
  }

  onBlurInput = (cancel) =>
  {
    SwitchedPane.switch(this.props.paneGroup, 'Label');
    let page = this.refs.inputPage.value|0;
    if(!cancel && page && page !== this.state.page)
    {
      if(page < 1) page = 1;
      if(page > this.state.pagecount) page = this.state.pagecount;
      this.props.onNavPage(page);
    }
  }

  onClickLabel = () =>
  {
    if(!this.state.disable)
    {
      SwitchedPane.switch(this.props.paneGroup, 'UpDown');
      setTimeout(()=>this.refs.inputPage.focus(),100)
    }
  }

  onKeyPressInput = (evt) =>
  {
    if(evt.key === 'Enter' || evt.key === 'Escape')
    {
      // esc ignores
      this.onBlurInput(evt.key === 'Escape');

      // Prevent bubbling up
      evt.stopPropagation();
    }
  }

  render()
  {
    return(
      <div style={Styles.InlineFlexRow}>
        <FloatingActionButton mini
                              disabled={this.state.page === 1 || this.state.disable}
                              disabledColor={Colors.clrNimbixDarkGray}
                              onClick={this.props.onPrevPage}>
          {Icon('navigate_before', {fontSize: 16, color: 'white'})}
        </FloatingActionButton>

        <SwitchedPane paneName='UpDown' paneGroup={this.props.paneGroup} >
          <input style={{...Styles.ParamInput, width: 140, marginLeft: 8, marginRight: 8}}
                 onBlur={this.onBlurInput}
                 title='Press Tab or Enter to submit'
                 placeholder='Enter Page No.'
                 min='1'
                 max={this.state.pagecount}
                 type='number'
                 onKeyUp={this.onKeyPressInput}
                 ref='inputPage'
          />
        </SwitchedPane>

        <SwitchedPane active paneName='Label' paneGroup={this.props.paneGroup}>
          <div onClick={this.onClickLabel}>
            <div title='Click to set page number'
                 style={{color: Colors.clrNimbixLite, width: 140, marginLeft: 8, marginRight: 8, textAlign : 'center'}}>
              Page {this.state.page} of {this.state.pagecount}
            </div>
          </div>
        </SwitchedPane>

        <FloatingActionButton mini
                              disabled={this.state.page === this.state.pagecount || this.state.disable}
                              disabledColor={Colors.clrNimbixDarkGray}
                              onClick={this.props.onNextPage}>
          {Icon('navigate_next', {fontSize: 16, color: 'white'})}
        </FloatingActionButton>
      </div>
    );
  }
}

// Wrapper for column group
export const ColGroup = (props) =>
{
  return (
    <colgroup>
    {
      props.cols.map
      (
        (e, i) => <col key={i} style={{width: e ?  e + '%' : 'auto'}}/>
      )
    }
    </colgroup>
  )
}


// Input field layout helper for Login/Forgot/Reset dialogs
export class LoginInputFieldRow extends Component
{
  render()
  {
    return (
      <tr style={{height: 64}}>
        <td style={{...Styles.TableCell, fontSize: 18, textAlign: 'right'}}>
          {this.props.label}
        </td>
        <td style={{...Styles.TableCell, padding: 8}}>
          {this.props.children}
        </td>
      </tr>
    );
  }
}

// Form wrapper with a table inside for login/register/forgot-password/ldap dialogs
export class LoginInputPane extends Component
{
  onSubmitForm = (event) =>
  {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  render()
  {
    return (
      <form ref='form' method='post' action='#' target='dummyframe' onSubmit={this.onSubmitForm}>
        <table style={Styles.Table}>
          <colgroup>
            <col style={{width: this.props.left || '35%'}}/>
            <col style={{width: this.props.right || '65%'}}/>
          </colgroup>
          <tbody>
          {this.props.children}
          </tbody>
        </table>
      </form>
    );
  }
}

// Error display for login dialogs
export const LoginErrField = (props) =>
{
  return (
    <p className='errortext' style={{marginLeft: LayoutDims.nMargin * 4}}>
      {props.err}
    </p>
  );
}

// Button and "back" link for login dialogs
export class LoginSubmitPane extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {disabled: false};
  }

  render()
  {
    return (
      <div style={{...Styles.FlexSpread, width: '95%', margin: '0px 2.5% 0px 2.5%'}}>
        <a onClick={this.props.back} href='#'>{this.props.labelBack}</a>
        <RaisedButton data-cy='btnLoginSubmit' {...fromProps(this, ['label', 'onClick'])} disabled={this.state.disabled}
                      {...Btns.Blue}/>
      </div>
    );
  }
}

// Common component for Admin and Tenant admin apps row
export class AdminAppRow extends Component
{
  static propTypes =
  {
    appdata: PropTypes.object.isRequired,
    app: PropTypes.string.isRequired,
    isTeamAdmin: PropTypes.bool,
    checked: PropTypes.bool,
    onCheckApp: PropTypes.func,
    onClickEntry: PropTypes.func,
    readOnly: PropTypes.bool,
  }

  constructor(props)
  {
    super(props);
    this.state = {checked: props.checked, readOnly: props.readOnly};
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({checked: nextProps.checked, readOnly: nextProps.readOnly});
  }

  render()
  {
    const isEnabled = this.state.checked || !this.props.isTeamAdmin;
    let gray = isEnabled ? '(0)' : '(100)';
    return (
      <div key={this.props.app}
           data-app={this.props.app}
           style={{margin: LayoutDims.nMargin, width: '100%'}}
           onClick={this.props.onClickEntry}>

        <div name='now' style={Styles.FlexSpread}>
          <div style={{...Styles.InlineFlexRow, cursor: 'pointer'}}>
            <AppIcon app={this.props.app} admin
                     style={{...Styles.AdminAppIcon, filter: 'grayscale' + gray}}/>

            {
              this.props.isTeamAdmin
              ?
                <div>
                  <span><b>{this.props.appdata.data.name}</b></span>
                  <div>{!this.props.appdata.public && this.props.app}</div>
                </div>
              :
                <div>
                  <span><b>{this.props.appdata.data.name}</b> - {this.props.appdata.owner}</span>
                  <div>{this.props.app}</div>
                </div>
            }

          </div>

          <div readOnly={this.state.readOnly}
               style={{...Styles.InlineFlexRow, marginRight: LayoutDims.nMargin, alignItems: 'flex-end'}}>
            {!this.props.appdata.public && Icon('lock')}

            {
              this.props.isTeamAdmin ?
                <ShowOnly if={!this.state.readOnly}>
                  &nbsp;&nbsp;
                  <Checkbox data-app={this.props.app}
                            checked={this.state.checked}
                            onCheck={this.props.onCheckApp}/>
                </ShowOnly>
              :
                this.props.appdata.certified && Icon( 'verified_user', {color: Colors.clrNimbixDarkGreen})
            }
          </div>

        </div>

        <Separator/>
        <HLine margin={0}/>
      </div>
    )
  }
}


export class ContextMenu extends Component
{
  static propTypes =
  {
    name: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
    fnMenuEnabled: PropTypes.func,
    icon: PropTypes.any.isRequired,
    iconStyle: PropTypes.any.isRequired,
  }

  render()
  {
    const icon =
      <IconButton name={this.props.name} style={this.props.iconStyle}>
        {this.props.icon}
      </IconButton>

    const menuEnabled = this.props.fnMenuEnabled && this.props.fnMenuEnabled();

    return (
      <IconMenu animated={true}
                iconButtonElement={icon}
                useLayerForClickAway={true}
                onClick={()=>this.forceUpdate() /* required to rerender items whose disabled state changes */}
                menuStyle={{...Styles.Bordered, width:250, maxWidth: 250, backgroundColor: Colors.clrLight}}>
        {
          Object.keys(this.props.items).map
          (
            (s) =>
            {
              const disabled = menuEnabled ? !menuEnabled[s] : false;
              const style = {padding: 8, margin: 0};
              if(disabled)
              {
                style.color = Colors.clrNimbixBlueGray;
              }

              return <MenuItem data-cy={this.props.name + '-' + s} primaryText={s}
                               onClick={()=>this.props.onMenuItemClick(s)}
                               key={s}
                               leftIcon={Icon(this.props.items[s], style)}
                               disabled={disabled}/>
            }
          )
        }
      </IconMenu>
    );
  }
}
