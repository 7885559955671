// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import Paper from 'material-ui/Paper';

import {LayoutDims, Styles, Colors, clrTrans} from './UIConst.js';
import {Nop, AppIcon, Icon} from './Components.js';
import {GetApp, GetAppPriceStr} from '../Models/AppsData';
import {Data, HasRole, ROLE_MACHINE_TESTER} from '../Models/Model';
import {GlobalUI} from '../Global';


import PTCContextPane from './PTCContextPane'

// Displays one apps entry in the app view
export class AppEntry extends Component
{
  static propTypes =
    {
      app: PropTypes.string,
      onClick: PropTypes.func,
      entryType: PropTypes.string              // ptc, recent or "" (for compute)
    };

  static stylePaper = null;
  static styleIcon = null;

  initStyles = () =>
  {
    if(!AppEntry.stylePaper)
    {
      AppEntry.stylePaper =
      {
        fontFamily: 'Roboto, Sans Serif',
        width: LayoutDims.AppEntryWidth,
        height: LayoutDims.AppEntryHeight,
        minHeight: LayoutDims.AppEntryHeight,
        margin: LayoutDims.nMargin,
        marginLeft: LayoutDims.nMargin * 2,
        marginTop: LayoutDims.nMargin * 1.5,
        padding: LayoutDims.nMargin,
        flexGrow: 0,
        cursor: 'pointer',
        display: 'inline-flex',
        flexBasis: 'column'
      };

      AppEntry.styleIcon =
      {
        opacity:0,
        position: 'absolute',
        width: LayoutDims.nIconSize,
        top: 10,
        right: 10,
        zIndex: 0,
        transition: 'opacity 0.5s ease-in-out'
      }
    }
  }

  onLoadIcon = (img) => {img.style.opacity = 1};

  constructor(props)
  {
    super(props);
    this.onClick = props.onClick || Nop;
    this.state = {app: GetApp(this.props.app)};
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({app: GetApp(this.props.app)});
  }

  onClickEntry = () =>
  {
    // IF app is invalid show a warning dialog
    if(this.state.app.ok)
    {
      this.onClick(this.props.app)
    }
    else
    {
      GlobalUI.Dialog.show('Error',
        'This application is disabled because there are no valid values in its machine list',
        false, LayoutDims.wContent * 0.75);
    }
  }

  render()
  {
    // Calculate the styles if first time
    this.initStyles();
    const app = this.state.app;

    if(!app)
    {
      return (
        <Paper zDepth={2} style={{...AppEntry.stylePaper, display: 'block', position: 'relative'}}>
          {this.props.app}
          <p className='errortext' style={{textAlign: 'center'}}><br/>App not found</p>
        </Paper>
      )
    }

    const sPrice = app.ok && app.from_price > 0 ? 'from ' + GetAppPriceStr(app) : '';

    // Strip out <username>- from the app name
    const sPrefix = Data.User.Profile.user_login + '-';
    const sPTCAppID =
      this.props.app.startsWith(sPrefix)
      ?
        this.props.app.substr(sPrefix.length)
      :
        this.props.app;

    const dctAppData = app.data;
    const margin = this.props.entryType === "ptc" ? 24 : 0;
    const elemMenuBtn = this.props.entryType === "ptc"
      ?
      <div data-cy={'menuPTC-' + sPTCAppID}  style={{position: 'absolute'}}>
        <PTCContextPane appName={'ptcBreadCrumb-' + sPTCAppID} app={this.props.app}/>
      </div>
      :
      null;

    const styleOuter =
    {
      ...Styles.InlineFlexCol,
      alignItems:'flex-start',
      justifyContent:'stretch',
      width: '100%'
    };

    const styleDetails =
    {
      marginTop: margin,
      flex: 1,
      width: `calc(100% - ${LayoutDims.nIconSize}px)`
    };

    let stylePaper = {...AppEntry.stylePaper, position: 'relative'};

    // Invalid app gets a mildly transparent red band saying "INVALID"
    const styleInvalid =
    {
      zIndex: 1,
      position: 'absolute',
      top: 36,
      left: 0,
      overflow: 'hidden',
      width: '100%',
      height: 48,
      backgroundColor: clrTrans('#ff2000', 0.8),
      color: Colors.clrLight,
      textAlign: 'center',
      fontWeight: 600
    };

    const sID = this.props.entryType ? this.props.entryType + '-' + sPTCAppID : sPTCAppID;

    return (
      <Paper zDepth={2} style={stylePaper}>
        {elemMenuBtn}

        <div data-cy={(this.props.entryType || 'compute') + 'App'} id={sID} style={styleOuter} onClick={this.onClickEntry} >
          {
            !app.public &&
            <div style={{zIndex: 1, position: 'absolute', top: 0, right: 0, overflow: 'hidden', width: 64, height: 64}}>
              <div
                style={
                  {
                    backgroundColor: Colors.clrNimbixDark,
                    transform: 'rotate(45deg)',
                    height: 28,
                    width: 128,
                  }
                }>
                {Icon('lock', {transform: 'rotate(-45deg)', fontSize: 13, marginTop: 12, marginLeft: 48, color: Colors.clrLight})}
              </div>
            </div>
          }


          {
            !app.ok && !HasRole(ROLE_MACHINE_TESTER) &&
            <div style={styleInvalid}>
              <div data-cy={sID + '-INVALID'} style={{lineHeight: '48px'}}>INVALID</div>
            </div>
          }

          <AppIcon app={this.props.app} onLoad={this.onLoadIcon} style={AppEntry.styleIcon}/>

          <div style={styleDetails}>
            <div style={{fontSize: '15px', color: Colors.clrNimbixDark}}>{dctAppData.name}</div>
            <div style={{fontSize: '13px', fontWeight: 'bold'}}>{dctAppData.author}</div>
          </div>

          <div style={{textAlign: 'right', width: '100%', fontSize: 13}}>
            {
              this.props.entryType === 'ptc'
              ?
                <b style={{color: Colors.clrNimbixLite}}>
                  <i>
                    {sPTCAppID}
                  </i>
                </b>
              :
                sPrice
            }
          </div>

        </div>

      </Paper>
    );
  }
}
