// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentCopy from 'material-ui/svg-icons/content/content-copy';

import * as jquery from 'jquery';

import {Tabs, Tab} from 'material-ui/Tabs';
import Paper from 'material-ui/Paper';
import {Card, CardActions, CardHeader, CardMedia, CardTitle } from 'material-ui/Card';

// Our UI data, styles and colors
import {LayoutDims, Styles, Btns, Colors} from './UIConst.js';

import {MUI, Separator, ModalView, SwitchedPane, HLine, ShowOnly, AppIcon, ContextMenu, ParamColGroup, TableFixedHeaderTab} from './Components.js';
import {BusyHelper, GlobalUI, Spinner, FetchBusy} from '../Global'

import {assignIf, numToText} from './Utils';

// Data access
import {Data, DataFetcher, AppScreenshotTitleFetcher, BlankImg,  GetAppScreenshotSrc, GetAppImgSrc,
  DefaultAppScreenshot, ImageToBase64, IsTeamAdmin} from '../Models/Model';

import {GetAppFetcher, GetAppPriceStr, SetAppFlag, GetAppFlag} from '../Models/AppsData';


import {AppCommandButtons} from './AppCommandButtons';
import {TaskBuilderPaneMachine} from './TaskBuilderPaneMachine';
import {TaskBuilderParamsPane} from './TaskBuilderParamsPane';
import {TaskBuilderPaneVault } from './TaskBuilderPaneVault';
import {TaskBuilderParameter} from './TaskBuilderParameter';
import {Icon} from './Components';

const GitIcon = '../static/images/git.jpg';
const GitHubIcon = '../static/images/github.png';
const DockerIcon = '../static/images/docker.png';

// "no project"  entry (only for team admins)
// We cant use an empty string here, so use a single unicode enquad space (which cant be typed by chance)
const PROJ_NONE = ' ';

// Place holder text for projects dropdown
const PROJ_PLACEHOLDER = '<Select Project>';


function IsURL(s)
{
  return s.indexOf('http://') === 0 || s.indexOf('https://') === 0;
}


const TaskBuilderPage = (props) =>
{
  const styleOverflow = props.noscroll ? {} : {overflowY:'auto'};
  return (
    <Paper zDepth={0} style={{...styleOverflow, width: '100%'}}>
      {props.children}
    </Paper>
  );
};

const Params = (props) =>
{
  return (
    <ShowOnly if={props.params.length}>
      <Separator/>
      <TaskBuilderParamsPane id='reqparams' items={props.params}/>
    </ShowOnly>
  );
}

// Submission JSON wrapper
class AppSubmissionCode extends Component
{
  submission = '';

  componentDidUpdate()
  {
    // The submission JSON actually has <a> elements for missing values
    // Setup the click handlers for that
    const arrElemErr = Array.from(document.getElementById('appsubmission')
      .getElementsByClassName('errorlink'));

    for(const elem of arrElemErr)
    {
      elem.onclick = (evt) => {this.props.onClickErr(evt.target.id)};
    }
  }

  onCopy = () =>
  {
    // TODO: use Utils.copyToClipboard
    this.refs.submissionTextShadow.value = this.props.submission;
    jquery.default(this.refs.submissionTextShadow).select();
    document.execCommand('copy');
    this.refs.labelCopied.style.transition = null;
    this.refs.labelCopied.style.opacity = 1;
    window.setTimeout
    (
      ()=>
      {
        this.refs.labelCopied.style.transition = 'opacity 1s ease-in-out';
        this.refs.labelCopied.style.opacity = 0;
      },
      500
    );
  }

  render()
  {
    return(
      <pre id='appsubmission' style={{margin:0, width: '100%', fontSize: 16}}>
        <code ref='submissionText' data-cy='divSubmission' dangerouslySetInnerHTML={{__html: this.props.submission}}>
        </code>

        <textarea ref='submissionTextShadow' style={{opacity:0, width:1, height:1}}>
        </textarea>

        <div style={{display: 'inline-flex', fontSize: 24, position:'absolute', right: LayoutDims.nMargin, bottom: LayoutDims.nMargin}}>
          <div ref='labelCopied' style={{marginTop:8, color:Colors.clrNimbixDarkGreen, opacity: 0}}>
            <b>COPIED </b>
          </div>

          <FloatingActionButton mini={true} title='Copy Submission to Clipboard' onClick={this.onCopy}>
            <ContentCopy />
          </FloatingActionButton>
        </div>
      </pre>
    );
  }
}

// General params tab
class TabGeneral extends Component
{
  static propTypes =
  {
    app: PropTypes.object.isRequired,
    cmd: PropTypes.string.isRequired,
    params: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    isErr: PropTypes.bool
  }

  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  // Update the state when props change
  componentWillReceiveProps(nextProps)
  {
    this.setState({...nextProps});
  }

  getParams = () =>
  {
    const data = {};
    if(this.props.projects.length)
    {
      const job_project = this.refs.job_project.getValue();

      // Get the job project, ignore if it was None
      if(job_project && job_project !== PROJ_NONE)
      {
        data.job_project = job_project;
      }
    }

    data.machine = this.refs.paneAppMachine.getParams();
    return data;
  }

  setParams = (dctMachine, job_project) =>
  {
    this.refs.paneAppMachine.setParams(dctMachine);
    let placeHolder = null;
    let bTAdmin = IsTeamAdmin();

    // If the given project doesn't exist
    if(this.state.projects.indexOf(job_project) === -1)
    {
      // Set a placeholder entry to force them to select one unless team admin
      if(!bTAdmin)
      {
        this.setState({placeHolder: PROJ_PLACEHOLDER});
      }
    }
    else
    {
      this.refs.job_project.setValue(job_project);
    }

    // If we are team admin, Add a "None" entry so they can launch without project
    if(bTAdmin)
    {
      this.setState({projects: [...this.state.projects, PROJ_NONE]});
    }

    this.setState({placeHolder});
  }

  render()
  {
    return (
      <TaskBuilderPage>

        <Separator/>
        <ShowOnly if={this.props.projects.length} >
          <TableFixedHeaderTab title='Project'/>
          <div>
            <table style={Styles.Table}>
              <ParamColGroup/>
              <tbody>
                <TaskBuilderParameter error={this.props.isErr}
                                      ref='job_project'
                                      name='Job Project'
                                      placeHolder={this.state.placeHolder}
                                      type='SELECTION'
                                      values={this.state.projects}/>
              </tbody>
            </table>
          </div>
        </ShowOnly>

        <Separator/>
        <TaskBuilderPaneMachine ref='paneAppMachine' app_price={this.props.app.price}
                                app={this.props.app} cmd={this.props.cmd}/>
        <Params params={this.props.params}/>
      </TaskBuilderPage>
    );
  }
}

// Storage tab
class TabStorage extends Component
{
  static propTypes =
  {
    app: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  };

  getParams = () => this.refs.paneAppVault.getParams();
  setParams = (dctVault) => this.refs.paneAppVault.setParams(dctVault);

  render()
  {
    return (
      <TaskBuilderPage>
        <Separator/>
        <TaskBuilderPaneVault onChange={this.props.onChange} app={this.props.app}
                              cmd={this.props.cmd} data={this.props.data} ref='paneAppVault'/>
      </TaskBuilderPage>
    );
  }
}

// Preview tab
class TabPreview extends Component
{
  static propTypes =
  {
    onClickErr: PropTypes.func.isRequired,
    submission: PropTypes.string.isRequired,
  }

  render()
  {
    return(
      <TaskBuilderPage noscroll>
        <div style={{...Styles.Bordered, boxSizing: 'border-box', width: '100%', height:'100%',
          overflowY:'auto', padding:8}}>
          <AppSubmissionCode submission={this.props.submission} onClickErr={this.props.onClickErr}/>
        </div>
      </TaskBuilderPage>
    );
  }
}

//App blurb pane
class AppBlurb extends Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      screenshot: props.screenshot,
      screenshotChanged: false,
      loading: false,
      imgWidth: props.imgWidth,
      maxHeight: props.maxHeight,
      isPTC: props.isPTC,           // If PTC app, show screenshot change icon
    };

    this.aspect = 16/9;
    this.styleIcon = {cursor: 'pointer', height: LayoutDims.nIconSize / 2, marginLeft: LayoutDims.nMargin / 2};
    this.styleIconSrcDlg = {height: LayoutDims.nIconSize / 3, margin: LayoutDims.nMargin / 2};
    this.stylePreSrcDlg =
    {
      ...Styles.Bordered,
      padding: LayoutDims.nMargin,
      margin: 0
    };

    this.menuItemIcons =
    {
      'Change...': 'mode_edit',
      'Delete': 'delete',
      'Download' : 'get_app'
    }

    this.menuItemHandlers =
    {
      eula:
      {
        'Change...': () => this.refs.inputEULA.click(),     // click the text file chooser
        'Delete':    this.deleteEULA,                       // set blank eula
        'Download':  this.downloadEULA
      },

      screenshot:
      {
        'Change...': () => this.refs.inputImg.click(),      // Click the image file chooser
        'Delete': this.deleteScreenshot,
        'Download': this.downloadScreenshot
      }
    }
  }

  // On mount, set loading state
  componentDidMount()
  {
    this.setState({loading: true, screenshotChanged: false, eula: null});
    this.mounted = true;
    this.loadScreenshotImage(this.props.screenshot);
  }

  componentWillReceiveProps(nextProps)
  {
    // If the screenshot has changed (happens when app updates or screenshot is loaded by owner)
    if(nextProps.screenshot !== this.props.screenshot)
    {
      this.setState({loading: true, screenshotChanged: false});
      this.loadScreenshotImage(nextProps.screenshot);
    }

    // Show github source icon if app repo is github
    const sData = nextProps.app.src;
    this.setState
    (
      {
        bShowSourceIcon: Data.User.Profile.developer && sData.indexOf('@') === -1 && IsURL(sData),
        bIsGitHub: sData.indexOf('github') >= 0
      }
    );
  }

  componentWillUnmount()
  {
    this.mounted = false;
  }

  // Warn about file size
  checkFileSize = (event, iMaxSize, sErr, elemInput) =>
  {
    if(event.target.files[0].size > iMaxSize)
    {
      GlobalUI.DialogConfirm.show('Error', sErr, false, LayoutDims.wContent * 0.75);
      elemInput.value = '';
      return false;
    }
    return true;
  }

  // Returns the disable state of the screenshot context menu items
  getMenuEnabledScreenShot = () =>
  {
    return (
      {
        'Change...': true,
        'Delete': this.state.screenshot !== DefaultAppScreenshot,
        'Download' : this.state.screenshot !== DefaultAppScreenshot
      }
    );
  }

  // Returns the disable state of the screenshot context menu items
  getMenuEnabledEULA = () =>
  {
    return (
      {
        'Change...': true,
        'Delete': this.state.license,
        'Download' : this.state.license
      }
    );
  }

  // Load a new app screenshot image
  loadScreenshotImage = (screenshot, byUser) =>
  {

    // Make a dummy image, and use it to handle load and error states
    this.img = new Image();

    // If image loads successfully
    this.img.onload = () =>
    {
      if(this.mounted)
      {
        // Save the very first screenshot before setting the new one
        if(!this.oldScreenshot)
        {
          this.oldScreenshot = this.state.screenshot;
        }

        this.setState({screenshot, loading: false, screenshotChanged: byUser});
      }
    }

    // If image fails to render, show an error dialog if it was triggered by user, else revert to a default Nimbix screenshot
    this.img.onerror = () =>
    {
      if(this.mounted)
      {
        if(byUser)
        {
          GlobalUI.DialogConfirm.show('Error', 'Unable to load the image', false, LayoutDims.wContent * 0.75);
        }
        else
        {
          this.setState({screenshot: DefaultAppScreenshot, loading: false, screenshotChanged: false});
        }
      }
    }

    this.img.src = screenshot;
  }

  // Send request to update EULA
  doSaveEULA = (license) =>
  {
    const params = {appid: this.props.app.id, license};
    const fetcher = new DataFetcher('/portal/ptc-set-eula', params);
    fetcher.ifFail(jqXHR => GlobalUI.Dialog.showErr(jqXHR, 'Failed to save EULA'));

    // When done, clear the flag that this user accepted EULA
    fetcher.whenDone
    (
      () =>
      {
        SetAppFlag(this.props.app.data, 'EULA.Accepted.' + this.props.app.id, false);

        // license is a base64 string, decode it before storing
        this.setState({license: atob(license)});
      }
    );
    FetchBusy(fetcher, `${license ? 'Saving' : 'Removing'} EULA...`);
  }

  // Save EULA to text file
  downloadEULA = () =>
  {
    this.refs.downloadEULA.href = 'data:text/plain;charset=UTF-8,' + encodeURIComponent(this.state.license);
    this.refs.downloadEULA.click();
  }

  // Delete sets blank EULA
  deleteEULA = () => this.doSaveEULA('')

  // Sends request to save the screenshot
  doSaveScreenshot = (png) =>
  {
    // Resize to 960x540
    const params = {appid: this.props.app.id, png};
    const fetcher = new DataFetcher('/portal/ptc-set-screenshot', params);

    fetcher.whenDone
    (
      () =>
      {
        // For delete, set default screenshot
        if(!png)
        {
          // We need to call loadimage to update the display
          this.setState({screenshotChanged: false, screenshot: DefaultAppScreenshot});
          this.loadScreenshotImage(DefaultAppScreenshot);
        }
        else
        {
          // Set the current image, set this as the "old" one, save locally to prevent reloads
          this.setState({screenshotChanged: false});
          this.oldScreenshot = this.state.screenshot;

          // Update the image cache id to cause reload
          Data.Config.ImgCacheID++;
        }
      }
    );
    fetcher.ifFail(jqXHR => GlobalUI.Dialog.showErr(jqXHR, 'Failed to save screenshot'))
    FetchBusy(fetcher, png ? 'Saving...' : 'Deleting...');
  }

  // Save screenshot to png file
  downloadScreenshot = () =>
  {
    this.refs.downloadScreenshot.href = this.state.screenshot;
    this.refs.downloadScreenshot.click();
  }

  // Delete sets blank screenshot data
  deleteScreenshot = () => this.doSaveScreenshot('');

  // Context menu handler
  onClickMenuItemScreenshot = s => this.menuItemHandlers.screenshot[s]();

  // Called when a screenshot file is chosen by user
  onSelectFileScreenshot = (event) =>
  {
    // Prevent excessively large files
    if(!this.checkFileSize(event, 1048576, 'Image file should be less than 1 MB', this.refs.inputImg)) return false;

    // Setup a file reader and read it
    const reader = new FileReader();
    reader.onload = (e) => this.loadScreenshotImage(e.target.result, true);
    reader.readAsDataURL(event.target.files[0]);
    this.refs.inputImg.value = '';
  }

  // Called to update screenshot for app
  onSetScreenshot = () =>
  {
    // Show the confirm dialog and kill the job if size is different
    if(this.img.width !== 960 || this.img.height !== 540)
    {
      GlobalUI.Dialog.confirm
      (
        <div>
          The image selected has dimensions {this.img.width}x{this.img.height}<br/>
          It will be scaled to 960x540
        </div>,
        'Confirm Resize',
        () => this.doSaveScreenshot(ImageToBase64(this.img, 960, 540))
      );
    }
    else
    {
      this.doSaveScreenshot(ImageToBase64(this.img, 960, 540));
    }
  }

  // Cancel screenshot, reverts to last saved one
  onCancelScreenshot = () => this.setState({screenshot: this.oldScreenshot, screenshotChanged: false});

  // Context menu handlers
  onClickMenuItemEULA = (s) => this.menuItemHandlers.eula[s]();

  // Called when a EULA text file is chosen by user
  onSelectFileEULA = (event) =>
  {
    // Prevent excessively large files
    if(!this.checkFileSize(event, 65536, 'EULA should be less than 64 KB', this.refs.inputEULA)) return false;

    // Setup a file reader and read it
    const reader = new FileReader();
    reader.onload = (e) =>
    {
      // Get the data and strip the data prefix
      const license = e.target.result.substr(e.target.result.indexOf(',') + 1);
      this.doSaveEULA(license);
    }

    reader.readAsDataURL(event.target.files[0]);
    this.refs.inputEULA.value = '';
  }

  // Shows app source repo in a dialog
  onClickRepoIcon = () =>
  {
    // URL may be like https://github.com/nimbix/a0#branch1?Dockerfile2
    // Split off the part before # first to get the base URL
    const arrSrc = this.props.app.src.split('#');
    const sURLSrc = arrSrc[0];
    let sBranch = '';

    // Did we have a # in the source
    if(arrSrc.length > 1)
    {
      // get the branch name
      const arrParams = arrSrc[1].split('?');
      sBranch = '-b ' + arrParams[0];
    }

    const content =
      <div style={{overflowX: 'auto'}}>
        <div style={Styles.InlineFlexRow}>
          <img style={this.styleIconSrcDlg} src={this.state.bIsGitHub ? GitHubIcon : GitIcon}/>
          &nbsp;{this.state.bIsGitHub ? 'Github:' : 'Git:'}&nbsp;
          <div>
            <b>
              <a style={{whiteSpace: 'nowrap'}} target='blank' href={sURLSrc}>{sURLSrc}</a>
            </b>
          </div>
        </div>

        <pre style={this.stylePreSrcDlg}>
          {`git clone ${sBranch} ${sURLSrc}`}
        </pre>

        {
          this.props.app.src &&
          <div>
            <Separator units={3}/>
            <div style={Styles.InlineFlexRow}>
              <img style={this.styleIconSrcDlg} src={DockerIcon}/>
              &nbsp;Docker:&nbsp;
              <div><b>{this.props.app.repo}</b></div>
            </div>

            <pre style={this.stylePreSrcDlg}>
              docker pull {this.props.app.repo}
            </pre>
          </div>
        }
        <Separator units={3}/>
      </div>;

    GlobalUI.Dialog.show('Application Sources', content, false, LayoutDims.wContent, GlobalUI.Dialog.clear);
  }


  render()
  {
    let width = this.state.imgWidth;
    let height = width / this.aspect;

    // If the image is too tall, clamp it down to max
    if(height > this.props.maxHeight)
    {
      height = this.props.maxHeight;
    }

    const style =
    {
      width,
      height,
      overflow:'hidden',
      ...Styles.Inline,
      backgroundColor:'#000000',
    };

    // Assume that 80 columns of monospace text is close to 80 columns of regular text
    // In that case, allow the text to fit 2.75 lines before truncating
    const iMaxDesc = 2.75 * 80 * (width - 4 * LayoutDims.nMargin) / LayoutDims.Tail80Column;
    const bLongDesc = this.props.app.data.description.length > iMaxDesc;
    const sDesc = bLongDesc ? this.props.app.data.description.substr(0, iMaxDesc) : this.props.app.data.description;

    const elemOverlay =
      <CardTitle title={this.props.title.title.trim() || 'Loading...'} subtitle={this.props.title.subtitle}>
        {
          !this.state.loading &&
          <div style={{position: 'absolute', zIndex: 9999, top: LayoutDims.nMargin * 3, right: LayoutDims.nMargin * 2}}>
          {
            this.state.screenshotChanged
            ?
              <div>
                <a href='#' title='Cancel' onClick={this.onCancelScreenshot}>
                  {Icon('clear', {color: '#FF0000'})}
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href='#' title='Save screenshot image' onClick={this.onSetScreenshot}>
                  {Icon('check', {color: '#00FF00'})}
                </a>
              </div>
            :
              this.props.app.owner === Data.User.Profile.user_login &&
              <div style={Styles.InlineFlexRow}>

                <div title='Screenshot'>
                  <ContextMenu items={this.menuItemIcons}
                               fnMenuEnabled={this.getMenuEnabledScreenShot}
                               name='setScreenshot'
                               icon={Icon('collections')}
                               iconStyle={{padding: 0, width: 32, height: 32, color: '#FFFFFF'}}
                               onMenuItemClick={this.onClickMenuItemScreenshot}/>
                </div>
                &nbsp;&nbsp;

                <div title='EULA'>
                  <ContextMenu items={this.menuItemIcons}
                               fnMenuEnabled={this.getMenuEnabledEULA}
                               name='setEULA'
                               icon={Icon('announcement')}
                               iconStyle={{padding: 0, width: 32, height: 32, color: '#FFFFFF'}}
                               onMenuItemClick={this.onClickMenuItemEULA}/>
                </div>

                <a ref='downloadEULA' download={this.props.app.id + '.eula.txt'}/>
                <a ref='downloadScreenshot' download={this.props.app.id + '.png'}/>

              </div>
          }
          </div>
        }
      </CardTitle>


    return MUI(
      <div>
        <CardMedia overlay={elemOverlay}>
          <div ref='imgPane' style={style}>
            {
              <div>
                <img ref='img' name='screenshot' src={this.state.screenshot} style={{height, width, margin: 'auto', transition: 'opacity 0.5s ease-in-out'}}/>
                <input type="file" ref='inputImg' name='inputImg' className='hide' onChange={this.onSelectFileScreenshot} accept="image/png" />
                <input type="file" ref='inputEULA' name='inputEULA' className='hide' onChange={this.onSelectFileEULA} accept="text/plain" />
              </div>
            }
          </div>

        </CardMedia>

        <div style={{padding: 8, paddingBottom: 0}}>
          <div style={{...Styles.InlineFlexRow, width: '100%'} }>
            <div style={{flexGrow: 1}}>
              {sDesc}
              {bLongDesc && <b style={{cursor: 'help'}} title={this.props.app.data.description}> ...</b>}
            </div>

            {
              this.state.bShowSourceIcon &&
              <img style={this.styleIcon} onClick={this.onClickRepoIcon}
                   src={this.state.bIsGitHub ? GitHubIcon : GitIcon}/>
            }
          </div>
        </div>
        <AppCommandButtons data={this.props.app.data} onClick={this.props.onClick}
                           that={this.props.that} invalid={this.props.invalid}/>
      </div>
    );
  }
}

// Submit button
const SubmitPane = (props) =>
{
  return (
    <CardActions style={{...Styles.Inline, justifyContent: 'flex-end', marginTop: LayoutDims.nMargin}}>
      <RaisedButton className='jobSubmit' disabled={props.disabled} label='Submit' {...Btns.Green} onMouseUp={props.onMouseUp}/>
    </CardActions>
  );
}


// EULA display component
class EULADlg extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {read: false};

    this.styleEULA =
    {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: 350,
      height: 350,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      padding: 8,
      fontSize: LayoutDims.FontSize,
      background: Colors.clrNimbixGray,
      ...Styles.Bordered
    };
  }

  // On scroll enable the Agree button if they scrolled to the end
  onScroll = () =>
  {
    if(!this.state.read)
    {
      const pre = this.refs.pre;
      this.setState({read: (pre.scrollHeight - pre.scrollTop) < pre.offsetHeight});
    }
  }

  // On accept, close, do the action and remember that the EULA was accepted
  onClickAccept = () =>
  {
    GlobalUI.Dialog.onClose();
    this.props.onAccept();
    SetAppFlag(this.props.appData, 'EULA.Accepted.' + this.props.appData.id, true);
  }

  render()
  {
    // During the first render, this.refs.pre is undefined, so we cannot detect the scroll state
    // In that case, we set a setTimeout to call scroll
    if(!this.refs.pre)
    {
      setTimeout(this.onScroll, 100);
    }

    // Show EULA text in wrapped pre and Accept/Decline buttons
    return (
      <div style={{marginLeft: LayoutDims.nMargin}} >
        <pre style={this.styleEULA} ref='pre' onScroll={this.onScroll}>
          {this.props.text}
        </pre>
        <Separator units={1}/>
        <div style={Styles.FlexSpread}>
          <RaisedButton className='btnEulaDecline' label='Decline' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton className='btnEulaAgree' disabled={!this.state.read} label='Agree' {...Btns.Blue} onClick={this.onClickAccept}/>
        </div>
      </div>
    );
  }
}

// App expanded view
export default class AppCard extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      open: false,
      curCmd: null,
      submission: '{}',
      appImgSrc: '',
      appScreenShot: '',
      appScreenShotTitle: {title: '', subtitle: ''},
      curTab: 'General',
      errField: '',
      errCount: 0,
      fetcher: null,
      app: null,
      firstUpdate: false,
      showBuilder: false,
      isPTC: false,
      projects: [],
    };
  }

  // Checks and warns if the appdef allows identity override but the team identity does not
  confirmIdentityOverride = (dctJob, onYes) =>
  {
    // Does appdef have an identity
    const dctApp = this.state.fetcher.data[this.state.app];
    if('identity' in dctApp.data && !Data.User.Identity.app_override)
    {
      GlobalUI.Dialog.confirm
      (
        <p>
          This application is attempting to override identity defaults and this account does not permit it.
          It may not behave properly if you launch it.
          <br/>
          If you are not sure what this means, please contact your team administrator.
          <br/><br/>
          Launch anyway?
        </p>,
        'Confirm identity override',
        onYes
      );
    }
    else
    {
      onYes();
    }
  }


  confirmWallTimeLimit = (dctJob, onYes) =>
  {
    const dctApp = this.state.fetcher.data[this.state.app];
    const sCmd = dctJob.submission.application.command;

    // Does the command have a walltime, or else, does the app have one ?
    const tmWallApp = dctApp.data.commands[sCmd].walltime || dctApp.data.walltime;

    // Has the user specified a walltime?
    const tmWallUser = dctJob.submission.application.walltime;

    // Let user walltime override the app/commands walltime
    let sTime = tmWallUser || tmWallApp;

    // If there is any
    if(sTime)
    {
      // Split the HH:MM:SS
      const sParts = sTime.split(':');
      const units = ['hour', 'minute', 'second'];
      const arrTime = [];

      // collect the non empty parts into an array
      for(let i = 0; i < 3; ++i)
      {
        let n = parseInt(sParts[i], 10);
        if(n)
        {
          arrTime.push(numToText(n, units[i]))
        }
      }

      // Build the time description with "," and "and" as necessary
      const l = arrTime.length;
      sTime = l === 1 ? arrTime[0] : l === 2 ? arrTime.join(' and ') : arrTime[0] + ', ' + arrTime[1] + ' and ' + arrTime[2];

      // Show appropriate warning message box
      const ctrls =
        <div style={{marginLeft: LayoutDims.nMargin}} >
          <div style={{margin: LayoutDims.nMargin, color: Colors.clrNimbixDark}}>
          {
            tmWallUser ?
              <div>
                <b>WARNING:</b><br/><br/>
                At your request, JARVICE will automatically terminate this job after {sTime} of runtime.
                Please make sure to save any important work before then.
              </div>
            :
              <div>
                <b>WARNING:</b><br/><br/>
                JARVICE will automatically terminate your job after {sTime} of runtime at the application provider's request.
                Please make sure to save any important work before then. For further clarification, please contact customer support.
              </div>
          }
          </div>
        </div>

      // Show the confirm dialog and continue if
      GlobalUI.Dialog.confirm(ctrls, 'Confirm job wall time limit', onYes);

      return;
    }

    onYes();
  }

  confirmAPIKeyUsage = (onYes) =>
  {
    // Get the current app params
    const dctApp = this.state.fetcher.data[this.state.app];
    const sConfirmKey = 'CheckBoxNeverAskAPIKeyConfirm.' + this.state.app;
    if(!GetAppFlag(dctApp, sConfirmKey))
    {
      const dctCmd = dctApp.data.commands[this.state.curCmd];
      const dctParams = dctCmd.parameters || {};

      for(const sKey of Object.keys(dctParams))
      {
        const dctParam = dctParams[sKey];
        if (dctParam.type === 'CONST' && dctParam.value === '%APIKEY%' && !Data.User.DisableAPISubstWarning)
        {
          let bNeverAsk = false;
          const ctrls =
            <div style={{marginLeft: LayoutDims.nMargin}} >
              <div style={{margin: LayoutDims.nMargin, color: Colors.clrNimbixDark}}>
                This application is requesting your API key,
                which can be used for submitting jobs.
                <br/>
                Please note that you will incur usage charges (if applicable)
                for any jobs submitted by this application.
                If you are not sure what this means, please contact Support.
                <br/>
                <br/>
                Allow this application to access your API key?
                <br/>
                If you answer No, this job will not be submitted.
              </div>
              <br/>
              <Checkbox onCheck={(e, v)=>{bNeverAsk = v}}
                        label='Allow this application to use my API key in the future without prompting me'/>
            </div>

          // Show the confirm dialog and kill the job if
          GlobalUI.Dialog.confirm
          (
            ctrls,
            'Confirm API Key Usage',
            () =>
            {
              // Perform the action and save the checkbox status
              onYes();
              SetAppFlag(dctApp, sConfirmKey, bNeverAsk);
            },
            null,
            'Yes',
            'No'
          );

          return;
        }
      }
    }

    onYes();
  }

  // Check that any file parameters dont have invalid chars in them
  confirmValidFileParams = (dctJob, onYes) =>
  {
    const dctApp = this.state.fetcher.data[this.state.app];
    const dctCmd = dctApp.data.commands[this.state.curCmd];
    const dctParams = dctCmd.parameters || {};

    const rxBad = /[?*&|$<>\s]/;

    let sBadFile = '';

    const dctJobParams = dctJob.submission.application.parameters;

    if(dctJobParams)
    {
      for(const sKey of Object.keys(dctJobParams))
      {
        const dctParam = dctParams[sKey];
        if(dctParam.type === 'FILE')
        {
          const sParamVal = dctJobParams[sKey];
          if(sParamVal.match(rxBad))
          {
            sBadFile = sParamVal;
            break;
          }
        }
      }
    }

    if(sBadFile)
    {
      const sText =
        <div>
          The file name <b>'{sBadFile}'</b> contains one or more special characters or spaces.<br/>
          This may cause certain applications to behave in unexpected ways, up to and including failure.
          <br/><br/>
          Are you sure you wish to submit this job?
        </div>

      GlobalUI.Dialog.confirm(sText, 'Confirm filename validity', onYes, null, 'OK', 'Cancel', LayoutDims.wContent * 0.75);
    }
    else
    {
      onYes();
    }
  }

  // Fetch app submission for a job number and launch task builder to clone the job
  // If app doesn't exist, it simply shows the json
  cloneJob = (jobnum) =>
  {
    const dctParams =
    {
      fields: 'job_api_submission,job_application,job_label,job_command,job_project',
      jobnum: jobnum,
      exactmatch: true
    }

    const fetcher = new DataFetcher('/portal/job-list', dctParams);
    fetcher.whenDone
    (
      () =>
      {
        const dctJob = fetcher.data[dctParams.jobnum];

        // Before cloning, verify that the machine is available and app and its command exists
        // If not show the job submission json (remove the jobsub key)
        const appFetcher = GetAppFetcher(dctJob.job_application);
        const bCmdExists = appFetcher && dctJob.job_api_submission.application.command in appFetcher.data[dctJob.job_application].data.commands;
        const dctMachine = dctJob.job_api_submission.machine;
        const bMCExists = dctMachine.type in Data.Machines;
        const bVaultExists = dctJob.job_api_submission.vault.name in Data.Vaults.VaultInfo;

        if(!appFetcher || !bCmdExists || !bMCExists || !bVaultExists)
        {
          const sErr = 'This job cannot be cloned because ' +
            `${!bMCExists ? 'the machine type' : !appFetcher ? 'its app definition' : !bCmdExists ?  'the app command' : 'its vault'} is no longer available.`

          delete dctJob.job_api_submission.jobsub;
          GlobalUI.Dialog.show
          (
            'Error',
            <div>
              {sErr}
              <br/><br/>

              <div style={{fontSize: LayoutDims.FontSize}}>Job API submission:</div>
              <pre style={{...Styles.Bordered, marginTop: 0, overflow: 'auto', maxHeight: 400, backgroundColor: Colors.clrNimbixGray, padding: LayoutDims.nMargin}}>
                {
                  JSON.stringify(dctJob.job_api_submission, null, 2)
                }
              </pre>

              <RaisedButton label='Copy to clipboard' {...Btns.Green} />

            </div>,

            false,
            LayoutDims.wContent * 0.75
          );
        }
        else
        {
          this.fetchProjects((arrProj)=>this.clone(dctJob, arrProj))
        }
      }
    );
    FetchBusy(fetcher, 'Retrieving Job Submission...');
  }

  // Launches task builder cloning an app submission
  clone(dctCloneData, arrProj)
  {
    GlobalUI.Dialog.clear();

    this.setState
    (
      {
        open: true,
        app: dctCloneData.job_application,
        appImgSrc: GetAppImgSrc(dctCloneData.job_application),
        appScreenShot: BlankImg,
        errCount: 0,
        errField: '',
        fetcher: GetAppFetcher(dctCloneData.job_application),
        showBuilder: true,
        curCmd: dctCloneData.job_command,
        firstUpdate: true,
        projects: arrProj,
      },
      // TODO: Move all the code below into this completion callback
      // () => {}
    );

    // Switch to Preview pane (TODO: Merge this into above setState)
    this.onChangeTab('General');

    // Ensure that render() is called and refs are alive (TODO: Remove this after above TODO)
    this.forceUpdate();

    const dctSubmission = dctCloneData.job_api_submission;

    // Set the machine and project, also tell it if were
    this.refs.TabGeneral.setParams(dctSubmission.machine, dctCloneData.job_project);

    // Set the vault if any
    if(dctSubmission.vault)
    {
      this.refs.TabStorage.setParams(dctSubmission.vault);
    }

    // If any params exist, set them
    const dctParams = dctSubmission.application.parameters;
    if(dctParams)
    {
      // Set the parameters
      const arrReqParams = Object.keys(this.refs).filter((s)=>s.startsWith('reqparam-'));
      const arrOptParams = Object.keys(this.refs).filter((s)=>s.startsWith('optparam-'));

      const dctParamNames = {}
      arrReqParams.concat(arrOptParams).map((s)=>dctParamNames[s.slice(9)] = s);

      // For each parameter in the app submission, set the value
      for(const sParam of Object.keys(dctParams))
      {
        const sRefName = dctParamNames[sParam];
        if(this.refs[sRefName])
        {
          this.refs[sRefName].setValue(dctParams[sParam]);
        }
      }
    }

    // Set other stuff
    this.refs.paramJobLabel.setValue(dctCloneData.job_label || "");
    this.refs.paramWallTime.setValue(dctCloneData.job_api_submission.application.walltime || "");
    this.refs.paramLicenses.setValue(dctSubmission.licenses || "");
    this.refs.paramRSAKey.setValue(dctSubmission.gen_sshkey || false);

    // If the job label equals the value of the first file parameter,
    // check the "Use file param as job label" checkbox

    if(dctCloneData.job_label)
    {
      // Get the appdef command params
      const dctCmdParams = this.state.fetcher.data[dctCloneData.job_application].data.commands[this.state.curCmd].parameters;
      const sFirstFileParam = Object.keys(dctCmdParams).find(e=>dctCmdParams[e].type === 'FILE' && dctCmdParams[e].required);
      if(sFirstFileParam)
      {
        let sFile = dctParams[sFirstFileParam];
        if(sFile && this.getJobLabelFromFileName(sFile) === dctCloneData.job_label)
        {
          this.refs.paramUseFileJobLabel.setValue(true);
        }
      }
    }

    if(this.refs.paramRequestJobIP)
    {
      this.refs.paramRequestJobIP.setValue(dctSubmission.publicip || "");
    }

    if(this.refs.paramIPAddress)
    {
      this.refs.paramIPAddress.setValue(dctSubmission.ipaddr || "");
    }

    // Load the submission data
    this.onPreview();
  }

  fetchProjects = (fnLater) =>
  {
    // Fetch the list of projects for this user before anything else
    const fetcherProj = new DataFetcher('/portal/project-list')
    fetcherProj.whenDone(()=>fnLater(fetcherProj.data.result));
    FetchBusy(fetcherProj, 'Loading...');
  }

  openWithApp(sAppName, isPTC=false)
  {
    // Find out the fetcher
    let fetcher = GetAppFetcher(sAppName);

    // Clear old dialog content
    // (resets the file browser if that was the last dialog content)
    GlobalUI.Dialog.clear();

    // Fetch the list of projects for this user before anything else
    this.fetchProjects
    (
      (arrProj)=>
      {
        this.setState
        (
          {
            open: true,
            app: sAppName,
            appImgSrc: GetAppImgSrc(sAppName),
            appScreenShot: BlankImg,
            errCount: 0,
            errField: '',
            fetcher: fetcher,
            showBuilder: false,
            isPTC,
            projects: arrProj
          }
        );

        AppScreenshotTitleFetcher.fetchUpdate(this, 'appScreenShotTitle', sAppName);

        // Force a fetch of EULA if we are the owner
        const dctApp = fetcher.data[sAppName];
        if(isPTC && dctApp.owner === Data.User.Profile.user_login)
        {
          const fetcherEULA = new DataFetcher('/portal/app-license', {appid: this.state.app});
          fetcherEULA.whenDone(jqXHR => this.refs.appBlurb && this.refs.appBlurb.setState({license: jqXHR.responseJSON.result}));
          fetcherEULA.fetch();
        }
      }
    );
  }

  // Called by AppSubmissionCode when a missing param link is clicked
  onClickErr = (sParamName) =>
  {
    this.setState({curTab: 'General', errField: sParamName});
  }

  onClose = () =>
  {
    //this.refs.viewModal.setState({open: false});
    this.setState({open: false});
  };

  onPreview = () =>
  {
    const dctJob = this.getSubmission();
    const json = JSON.stringify(dctJob.submission, null, '  ');
    this.setState({errCount: dctJob.errors.length}); // TODO: Merge these two lines
    this.setState({submission: json});
    return dctJob;
  }

  // Commented this code as it causes a race condition causing spurious entries in the Recent pane

  // After submission, whether success or fail, add an entry to the
  // active jobs view
  /*addJobEntry = (dctJobSubmission, dctResponse) =>
  {
    // Insert a job entry opportunistically into the jobs data and then refresh the view
    if(!JobsActiveFetcher.data)
    {
      JobsActiveFetcher.data = {};
    }

    const job = GetNewJob(dctJobSubmission, dctResponse);
    JobsActiveFetcher.data[dctResponse ?  dctResponse.number : 0] = job;

    GlobalUI.Dashboard.setActiveJobs({...JobsActiveFetcher.data});
  }*/

  doSubmit = (dctJob) =>
  {
    BusyHelper.Status('Submitting Job...');
    BusyHelper.Busy();

    const jobSubmitter =
      new DataFetcher('/portal/job-submit', {data: JSON.stringify(dctJob.submission)});

    jobSubmitter.whenDone
    (
      // On success, close Task builder
      () =>
      {
        // The jobs list will update in at most 2 seconds from now
        // Set a timer for 3 second delay, so that the display will be up to date when
        // the user gets back control
        window.setTimeout
        (
          () =>
          {
            GlobalUI.Main.onNavigate('Dashboard');
            SwitchedPane.switch('DashboardContent', 'Current');
            BusyHelper.Busy(false);
          },
          3000
        );

        this.onClose();
      }
    )
    .ifFail
    (
      (jqXHR)=>
      {
        BusyHelper.Busy(false);
        GlobalUI.Dialog.showErr(jqXHR, 'Job submission was unsuccessful');
      }
    )
    .fetch();
  }

  showEULA = (sEULA, onAccept) =>
  {
    // Show the confirm dialog and kill the job if
    GlobalUI.Dialog.show
    (
      'End User License Agreement',
      <EULADlg onAccept={onAccept} text={sEULA} app={this.state.app} appData={this.state.fetcher.data[this.state.app]} />,
      false,
      LayoutDims.wContent + LayoutDims.nMargin * 20,
      null,
      LayoutDims.hAppBar
    );
  }

  // Submit button
  onSubmit = () =>
  {
    const dctJob = this.onPreview()

    // Submit only if there were no errors
    if(dctJob.errors.length === 0)
    {
      // Check and warn for each condition in turn, then submit if confirmed
      const confirmAndSubmit = () =>
      {
          // Check if app overrides identity against team policy
          this.confirmIdentityOverride
          (
            dctJob,
            // Check if wall time limit is set and warn about automatic termination
            ()=>this.confirmWallTimeLimit
            (
              dctJob,
              // Warn if app asks for API key
              ()=>this.confirmAPIKeyUsage
              (
                ()=>this.doSubmit(dctJob)
              )
            )
         )
      }

      // Check for invalid chars in file name params
      this.confirmValidFileParams
      (
        dctJob,
        ()=>
        {
          // Fetch app EULA and if its not blank and user hasn't agreed before, call showEULA
          const fetcher = new DataFetcher('/portal/app-license', {appid: this.state.app})
            .whenDone
            (
              (jqXHR) =>
              {
                const sEula = jqXHR.responseJSON.result;
                const appData = this.state.fetcher.data[this.state.app];
                const sEulaKey = 'EULA.Accepted.' + this.state.app;

                // No EULA, submit job, but forget if they had ever agreed
                if(!sEula.length)
                {
                  SetAppFlag(appData, sEulaKey, false);
                  confirmAndSubmit();
                }
                else // A EULA exists
                {
                  // If they had agreed before, submit, else show confirm dialog
                  if(GetAppFlag(appData, sEulaKey))
                  {
                    confirmAndSubmit();
                  }
                  else
                  {
                    this.showEULA(sEula, confirmAndSubmit);
                  }
                }
              }
            );

          FetchBusy(fetcher, 'Submitting Job...');
        }
      )
    }
    else
    {
      // If the error starts with * then dont do anything
      if(!dctJob.errors[0].startsWith('*'))
      {
        // the tab on which the erroneous field is either general or optional
        const errField = dctJob.errors[0];
        this.setState({curTab: errField.startsWith('param') ? 'Optional' : 'General', errField});
      }
    }
  }

  // Event handler passed to TabStorage to signal vault selection
  onVaultChange = (sVault) =>
  {
    // Tell all the FILE param controls about this vault
    const arrRefs =
      Object.keys(this.refs).filter((s)=>s.startsWith('reqparam-') || s.startsWith('optparam-'));

    for(const sComp of arrRefs)
    {
      if(this.refs[sComp].props.type === 'FILE')
      {
        this.refs[sComp].paramField.setVault(sVault);
      }
    }
  }

  // Called when "Use input file as job label" checkbox changes
  onCheckFileJobLabelChange = () =>
  {
    // Get the description of the parameter which we stored in the checkbox data-file attr
    const refCheck = this.refs.paramUseFileJobLabel;
    const v = refCheck.getValue();
    const sFileParamName = refCheck.props['data-file'];

    // Set the text of the job label field to empty or a descriptive message
    this.refs.paramJobLabel.setValue(v ? `Use value of "${sFileParamName}" parameter` : '');

    if(v)
    {
      this.refs.paramJobLabel.paramField.setAttribute('disabled', true);
    }
    else
    {
      this.refs.paramJobLabel.paramField.removeAttribute('disabled');
    }

    // Save this state for later
    this.useFileJobLabel = v;
  }


  showParamErrDlg = (sErr, curTab, errField) =>
  {
    // Show error dialog
    GlobalUI.Dialog.show
    (
      'Error',
      sErr,
      false,
      LayoutDims.wContent * 0.5,

      // On dialog close make the specified tab and error field get focused
      () => this.setState({curTab, errField})
    );

  }

  // Shortens a file path to be used as a job label
  // The filename is extracted and truncated to 50 chars max
  getJobLabelFromFileName = (sFileIn) =>
  {
    // First try to get rid of the path
    let sFile = sFileIn;
    if(sFile.length > 50)
    {
      const arrParts = sFile.split('/');
      sFile = arrParts[arrParts.length - 1];
    }

    // If its still lengthy, truncate
    if(sFile.length > 50)
    {
      sFile = sFile.substr(0, 50);
    }
    return sFile;
  }

  // Gets the job submission dict for the current job
  // ret.submission is the data, ret.errors is the error count
  getSubmission = () =>
  {
    let dctJob =
    {
      submission: {
        app: this.state.app,
        staging:false, // TODO
        checkedout:false, // TODO
        application: {
          command: this.state.curCmd,
        }
      },
      errors: []
    };

    // Get the general params - machine details and project if any
    const dctGeneral = this.refs.TabGeneral.getParams();
    dctJob.submission = {...dctJob.submission, ...dctGeneral};

    assignIf(dctJob.submission, 'vault', this.refs.TabStorage.getParams());
    assignIf(dctJob.submission.application, 'walltime', this.refs.paramWallTime.getValue());
    assignIf(dctJob.submission.application, 'geometry', this.refs.paramGeometry.getValue());

    const sIpAddr = this.refs.paramIPAddress.getValue();
    if(sIpAddr)
    {
      dctJob.submission.application.ipaddr = sIpAddr;
    }
    const sLicenses = this.refs.paramLicenses.getValue();
    if (sLicenses)
    {
      dctJob.submission.licenses = sLicenses;
    }

    const [arrErrors, dctParams] = this.getParams();


    // If we have projects, we are not a team admin, and the project field is blank set an error
    if(this.state.projects.length > 1 && !IsTeamAdmin() && !dctGeneral.job_project)
    {
      arrErrors.push('job_project');
      dctJob.submission.job_project = `<a id='job_project' class=errorlink>Project not specified</a>`;
    }

    // Copy first file param to job label if specified
    const dctApp = this.state.fetcher.data[this.state.app];
    const dctCmd = dctApp.data.commands[this.state.curCmd];
    const dctAppdefParams = dctCmd.parameters || {};

    // If job label is to be from file param, fill it
    if(this.useFileJobLabel)
    {
      // Find first required file param and set its value as job label
      const sFirstFileParam = Object.keys(dctAppdefParams).find(e=>dctAppdefParams[e].type === 'FILE' && dctAppdefParams[e].required);
      if(sFirstFileParam)
      {
        // Fix up the path to be used as a label
        dctJob.submission.job_label = this.getJobLabelFromFileName(dctParams[sFirstFileParam])
      }
    }
    else // Use what user entered
    {
      assignIf(dctJob.submission, 'job_label', this.refs.paramJobLabel.getValue());
    }

    // Get publicip if interactive
    if(dctCmd.interactive || dctCmd.webshell || dctCmd.desktop)
    {
      assignIf(dctJob.submission, 'publicip', this.refs.paramRequestJobIP.getValue());
    }

    // Get 'Use RSA key' flag
    assignIf(dctJob.submission, 'gen_sshkey', this.refs.paramRSAKey.getValue());

    // Check vault params
    if(arrErrors.length === 0 && dctJob.submission.vault)
    {
      // Verify that the password and confirmation match
      if('password' in dctJob.submission.vault)
      {
        if(dctJob.submission.vault.password !== dctJob.submission.vault.confirm)
        {
          // Error with * prefix does not cause any change of focus
          arrErrors.push('*password');

          // Show error dialog
          this.showParamErrDlg
          (
            <div>Vault passwords do not match</div>,
            'Storage',
            this.refs.TabStorage.refs.paneAppVault.refs.paramPassword.paramField
          );
        }

        // Delete the "confirm" key in the submission - whether or not it matched the password
        delete dctJob.submission.vault['confirm'];
      }
    }

    // Verify walltime parameter for HH:MM:SS
    const sWallTime = dctJob.submission.application.walltime;
    if(sWallTime)
    {
      let bValid = true;
      if(!/^\d+:\d\d:\d\d$/.test(sWallTime))
      {
        bValid = false;
      }
      else
      {
        // Ensure time format is right
        const hms = sWallTime.split(':');
        const h = parseInt(hms[0], 10);
        const m = parseInt(hms[1], 10);
        const s = parseInt(hms[2], 10);
        if(m > 59 || s > 59 || h > 9999)
        {
          bValid = false;
        }
      }

      if(!bValid)
      {
        arrErrors.push('*walltime');

        // Show error dialog
        this.showParamErrDlg
        (
          <div>
            Invalid job wall time<br/>
            Please enter a time duration (upto 10000 hours) in HH:MM:SS format.
          </div>,
          'Optional',
          this.refs.paramWallTime.paramField
        );
      }
    }

    if(Object.keys(dctParams).length || arrErrors.length)
    {
      dctJob.submission.application.parameters = dctParams;
      dctJob.errors = arrErrors;
    }

    dctJob.submission.user =
    {
      username: Data.User.Profile.user_login,
      apikey: Data.User.Profile.user_apikey
    }

    return dctJob;
  };

  // Switch to task builder pane and General tab
  showBuilder = (sCmd) =>
  {
    this.setState({showBuilder: true, curCmd: sCmd, firstUpdate: true});
    this.onChangeTab('General');
  };

  // Fetch all the parameters as a dict
  getParams = () =>
  {
    let arrErrs = [];
    let dctParams = {};
    for(const sComp of Object.keys(this.refs).filter((s)=>s.startsWith('reqparam-')))
    {
      const sVal = this.refs[sComp].getValue();
      const sKey = this.refs[sComp].getParamKey();

      // Missing required parameters get a link pointing to the field
      // Some hackery to allow 0 but no blank values
      const isOK = (sVal !== null && sVal !== '');
      dctParams[sKey] = isOK ? sVal : `<a id=${sComp} class=errorlink>Required parameter missing</a>`;

      if(!isOK)
      {
        arrErrs.push(sComp);
      }
    }

    for(const sComp of Object.keys(this.refs).filter((s)=>s.startsWith('optparam-')))
    {
      const sVal = this.refs[sComp].getValue();
      if(sVal !== null && sVal !== '')
      {
        dctParams[this.refs[sComp].getParamKey()] = sVal;
      }
    }

    return [arrErrs, dctParams];
  };

  // Triggered by the tab pane
  onChangeTab = (sTabName) =>
  {
    // Since onChange can bubble up from input fields within the params pane
    // Ignore this unless sTabName is a string
    // No thanks to Material UI!
    if(typeof(sTabName) === 'string')
    {
      this.setState({curTab: sTabName, errField: ''});
    }
  };

  // After everything is rendered, focus the error field if possible and scroll it in
  componentDidUpdate()
  {
    // Do we have any error field and is the dialog open
    if(this.state.errField && this.state.open)
    {
      // Get the component and try to focus it, then scroll it into view
      let ref = this.state.errField;
      if(typeof this.state.errField === 'string')
      {
        // Handle "job_project" specifically since its part of TabGeneral
        if(this.state.errField !== 'job_project')
        {
          ref = this.refs[ref].paramField;
        }
        else
        {
          ref = this.refs.TabGeneral.refs.job_project.paramField.selectField;
        }
      }

      if(ref.focus)
      {
        ref.onfocus = (evt) => {evt.target.scrollIntoView(true)};
        ref.focus();
        ref.placeholder='Please enter a value';
      }
      else // just scroll it into view
      {
        ref.scrollIntoView(true);
      }
    }

    // If this is the first render, trigger a vault change event using whatever value is default
    // because the onchange wont trigger unless the user changes the selection
    if(this.state.firstUpdate)
    {
      const dctVault = this.refs.TabStorage.getParams();
      this.onVaultChange(dctVault ? dctVault.name : '');
      this.setState({firstUpdate: false});

      // Reset the "use file param as label" value
      this.useFileJobLabel = false;
    }
  }

  makeParamFields(dctCmd)
  {
    const dctParams = dctCmd.parameters || {};
    const arrParamNames = Object.keys(dctParams);
    const arrParams = {req: [], opt: []};

    // Add job label and wall time optional params
    const dctParamJobLabel = {name:'Job Label', type: 'STR',};
    const dctParamWallTime = {name:'Wall Time Limit (HH:MM:SS)', type: 'STR',};

    arrParams.opt.push(<TaskBuilderParameter ref='paramJobLabel' key='job_label' {...dctParamJobLabel}/>);

    // If there is a file param, add a "use file param as job label" checkbox
    let sFirstFileParam = arrParamNames.find(e=>dctParams[e].type === 'FILE' && dctParams[e].required);
    if(sFirstFileParam)
    {
      arrParams.opt.push
      (
        <TaskBuilderParameter
          ref='paramUseFileJobLabel'
          key='file_job_label'
          name={'Use file as job label'}
          type='BOOL'
          data-file={dctParams[sFirstFileParam].name}
          description='Uses the value of the first input file parameter as the job label'
          onChange={this.onCheckFileJobLabelChange}
        />
      );
    }

    arrParams.opt.push(<TaskBuilderParameter ref='paramWallTime' key={'wall_time'} {...dctParamWallTime}/>);

    // Request job IP address checkbox if the command is interactive
    if(dctCmd.interactive || dctCmd.webshell || dctCmd.desktop)
    {
      arrParams.opt.push
      (
        <TaskBuilderParameter
          ref='paramRequestJobIP'
          key='publicip'
          name={'Request Job IP Address'}
          type='BOOL'
          value={!!dctCmd.publicip}
          description='If checked, requests an IP address as well as a connect link to allow for non-HTTPS clients (e.g. SSH, VNC); note that the underlying infrastructure may not support assigning IP addresses to jobs and may ignore this request.'
        />
      );
    }

    arrParams.opt.push
    (
      <TaskBuilderParameter
        ref='paramRSAKey'
        key='rsakey'
        name='Use RSA instead of ED25519 keys for SSH between nodes'
        type='BOOL'
        description='Use only if application’s SSH service does not support ED25519 keys.'
      />
    );

    // Make a list of parameters
    for(const sName of arrParamNames)
    {
      const dctParam = dctParams[sName];

      // Ignore CONST
      if(dctParam.type !== 'CONST')
      {
        // Each field has a ref name like reqparam-somename
        const sPrefix = dctParam.required ? 'req' : 'opt';
        const sParamRefName = sPrefix + 'param-' + sName;
        const isErr = this.state.errField === sParamRefName;

        // Error field will be rendered with red border
        arrParams[sPrefix].push
        (
          <TaskBuilderParameter
            error={isErr} key={sName} paramkey={sName} ref={sParamRefName} {...dctParam}/>
        );
      }
    }

    return arrParams;
  }

  // Renders the App Blurb page
  renderAppBlurb(dctApp, imgWidth, imgMaxHeight)
  {
    // Is this command OK?
    const arrInvalidCmd = this.state.fetcher.invalidAppCmds[this.state.app] || [];
    const dctScreenshot = GetAppScreenshotSrc(this.state.app, true);
    return (
      <AppBlurb that={this}
                app={dctApp}
                isPTC={this.state.isPTC}
                title={this.state.appScreenShotTitle}
                screenshot={dctScreenshot}
                invalid={arrInvalidCmd}
                imgWidth={imgWidth}
                maxHeight={imgMaxHeight}
                ref='appBlurb'
                onClick={this.showBuilder}/>
    );
  }

  renderTaskBuilder(dctApp, imgMaxHeight)
  {
    const bDisableSubmit = this.state.errCount > 0 && this.state.curTab === 'Preview';
    const dctCmd = dctApp.data.commands[this.state.curCmd];
    const arrParams = this.makeParamFields(dctCmd);

    // Get the geometry from appdef or command
    let sGeometry = dctApp.data.geometry || dctCmd.geometry || '0x0';

    // Set geometry to max of specified or browser size
    const arrXY = sGeometry.split('x');
    let iGeomX = Math.max(parseInt(arrXY[0], 10), window.innerWidth - 16) | 0;
    let iGeomY = Math.max(parseInt(arrXY[1], 10), window.innerHeight - 40) | 0;
    sGeometry =`${iGeomX}x${iGeomY}`;

    // If there is any project at all for a team admin, add a blank entry
    let arrProjs = [...this.state.projects];
    const bTAdmin = IsTeamAdmin();
    if(bTAdmin && arrProjs.length > 0)
    {
      arrProjs.splice(0, 0, PROJ_NONE)
    }

    // For non team members, hide the dropdown if only 1 project
    // If more than one project, add a placeholder to the dropdown to force user to choose
    let sProjPlaceHolder = null;
    if(!bTAdmin)
    {
      if(arrProjs.length <= 1)
      {
        arrProjs = [];
      }
      else
      {
        sProjPlaceHolder = PROJ_PLACEHOLDER;
      }
    }

    return (
      <div>
        <HLine margin={4}/>

        <CardTitle style={{paddingTop: LayoutDims.nMargin}} title={dctCmd.name} subtitle={dctCmd.description}/>

        <Tabs value={this.state.curTab} onChange={this.onChangeTab}
              contentContainerStyle={{maxHeight: imgMaxHeight - 56, overflowY: 'auto'}}>

          <Tab value='General' label='General'>
            <TabGeneral ref='TabGeneral'
                        isErr={this.state.errField === 'job_project'}
                        projects={arrProjs}
                        placeHolder={sProjPlaceHolder}
                        app={dctApp}
                        cmd={this.state.curCmd}
                        params={arrParams.req}/>
          </Tab>

          <Tab value='Optional' label='Optional'>
            <TaskBuilderPage>
              <Params params={arrParams.opt}/>
              <TaskBuilderParamsPane
                hideTitle
                items={
                  [
                    <TaskBuilderParameter
                      ref='paramGeometry'
                      key='geometry'
                      name='Window size'
                      value={sGeometry}
                      type='STR'/>,

                    <TaskBuilderParameter
                      ref='paramIPAddress'
                      key='ip_address'
                      name='IP Address Tag'
                      description='If your service provider or system administrator provisioned a static IP address for you, and you wish to assign it to this job, please enter the tag name associated with it; note that the behavior for multiple jobs with the same address request is undefined.'
                      type='STR'/>,

                    <TaskBuilderParameter
                      ref='paramLicenses'
                      key='licenses'
                      name='License Features'
                      type='STR'/>
                  ]
                }/>
            </TaskBuilderPage>
          </Tab>

          <Tab value='Storage' label='Storage'>
            <TabStorage ref='TabStorage' app={this.state.app}
                        cmd={this.state.curCmd}
                        onChange={this.onVaultChange}
                        data={this.state.fetcher && this.state.fetcher.data}/>
          </Tab>

          <Tab value='Preview' label='Preview Submission' onActive={this.onPreview}>
            <TabPreview onClickErr={this.onClickErr} submission={this.state.submission}/>
          </Tab>
        </Tabs>

        <SubmitPane disabled={bDisableSubmit} onMouseUp={this.onSubmit}/>

      </div>

    );
  }

  render()
  {
    // If there is no app, render blank
    if(!this.state.app)
    {
      return <div></div>;
    }

    // Calculate sizing dynamically
    let iWidth = window.innerWidth;
    let iHeight = window.innerHeight;
    let imgWidth, imgMaxHeight;
    let dctSize = {};

    // If portrait mode, dialog is full width, and no limit for height
    if(iWidth < iHeight)
    {
      dctSize = {width: '100%', height: 'auto', maxHeight: '100%', top: LayoutDims.hAppBar};
      imgWidth = iWidth - LayoutDims.nMargin * 4;
    }
    // landscape mode, use at most 1024 px width
    else
    {
      dctSize = {height: 'auto', maxHeight: 'calc(100% - 16px)', top: 0};
      dctSize.width = iWidth > 1024 ? 1024 : iWidth;
      imgWidth = dctSize.width - LayoutDims.nMargin * 4;

      // Restrict max image height to the available space - 280
      imgMaxHeight = iHeight - (280 - LayoutDims.hAppBar);
    }

    const dctApp = this.state.fetcher.data[this.state.app];
    const sPrice = dctApp.from_price > 0 ? 'From ' + GetAppPriceStr(dctApp) : '';

    const elemContent =
      this.state.showBuilder
      ?
        this.renderTaskBuilder(dctApp, imgMaxHeight)
      :
        this.renderAppBlurb(dctApp, imgWidth, imgMaxHeight);

    return MUI(
      <ModalView ref='viewModal' name='appcard' open={this.state.open} onClose={this.onClose} {...dctSize}>

        <Card containerStyle={{backgroundColor: Colors.clrLight, padding: LayoutDims.nMargin * 2, paddingTop: 0}}>
          <CardHeader style={{padding: LayoutDims.nMargin, paddingLeft: 0}}
                      title={dctApp.data.name} subtitle={sPrice}
                      avatar={<AppIcon app={this.state.app} style={{height: LayoutDims.nIconSize/1.5}} />}/>

          {this.state.open && elemContent}

        </Card>

      </ModalView>
    );
  }
}
