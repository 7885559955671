// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';

import {EULA} from './EULA';

// Our custom components
import {MUI, ModalView, Separator, HLine, ModalTitle} from './Components';

// Our layout, styles and colors
import {Styles, LayoutDims, Btns, Colors, clrTrans} from './UIConst';
import {GlobalUI, FetchBusy, ReLogin, Validators, ValidationErrs} from '../Global';
import {DataFetcher} from '../Models/Model';


class FieldTRow extends Component
{
  static propTypes =
  {
    label: PropTypes.string.isRequired
  }

  render()
  {
    return (
      <tr style={{height: 40}}>
        <td style={{...Styles.TableCell, fontSize: 18, textAlign: 'right'}}>
          {this.props.label}
        </td>
        <td style={{...Styles.TableCell, padding: 8}}>
          {this.props.children}
        </td>
      </tr>
    );
  }
}

// Table row with a horizontal line
const HLineRow = () =>
  <tr>
    <td colSpan='2'>
      <Separator units={3}/>
      <HLine/>
      <Separator units={3}/>
    </td>
  </tr>

// Main outer page container
export class Register extends Component
{
  static dctReadOnlyFields =
  {
    user_email: true,
    payer: true,
    billing_code: true,
    user_privs: true,
    account_vars: true,
  };

  constructor(props)
  {
    super(props);
    this.state =
    {
      canSubmit: false,
      errField: '',
      params: this.props.params,
      eula: EULA,
      width: 0
    };

    // If user login is specified by the admin, let it be un-changeable
    // Same logic is used in the web portal
    Register.dctReadOnlyFields['user_login'] = props.params.user_login != null;
  }

  componentDidMount()
  {
    const fetcher = new DataFetcher('/static/eula.txt', {}, null, false);
    fetcher.whenDone((jqXHR)=>this.setState({eula: jqXHR.responseText})).fetch('GET');

    GlobalUI.getMetrics();
    this.setState({width: LayoutDims.wContent * 0.8})
  }

  // Validator functions for each field
  static dctCheck =
  {
    user_pass: (p) =>
    {
      if(!Validators.user_pass(p))
      {
        return ValidationErrs['user_pass'];
      }
    },

    user_login: (u) =>
    {
      if(!Validators.user_login(u))
      {
        return ValidationErrs['user_login'];
      }
    },

    user_pass_confirm: (p, p2) =>
    {
      if(p !== p2)
      {
        return 'Passwords do not match';
      }
    }
  }

  // Checks a field, shows an error message and focuses that field if bad
  checkField(field, check = Register.dctCheck[field])
  {
    const err = check(this.refs[field].value)
    if(err)
    {
      GlobalUI.Dialog.show('Error', <div style={{padding: 4, fontSize: 20}}>{err}</div>,
        false, LayoutDims.wContent * 0.6);
      this.refs[field].focus();
      this.setState({errField: field});
      throw err;
    }
  }

  onSubmit = () =>
  {
    try
    {
      // Validate fields
      const sPass = this.refs['user_pass'].value;
      this.checkField('user_login');
      this.checkField('user_pass');
      this.checkField('user_pass_confirm', Register.dctCheck['user_pass_confirm'].bind(this, sPass));
      this.checkField('user_nicename', (s)=>s.length ? null : 'Please enter full name');

      // Make the POST params
      const params = {...this.state.params};
      for(const key of Object.keys(this.refs))
      {
        if(key !== 'user_pass_confirm')
        {
          params[key] = this.refs[key].value;
        }
      }

      // Send the request with a spinner and redirect to the Login page after success
      const fetcher = new DataFetcher('/portal/user-register', params, null, false)
        .ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Signup failed'))
        .whenDone(() => ReLogin(params.user_login, params.user_pass));

      FetchBusy(fetcher, 'Signing Up...');
    }
    catch(e){}
  }

  render()
  {
    const inputProp = (theRef, gray) =>
    {
      return {
        ref: theRef,
        style:
        {
          ...Styles.ParamInput,
          ...this.state.errField === theRef ? {border: `1px solid ${clrTrans(Colors.clrNimbixErr, 0.5)}`} : {},
          ...(gray ? {backgroundColor: Colors.clrNimbixGray} : null)
        }
      }
    }

    const getParamProp = (p) =>
    {
      const sProp = this.state.params[p];
      const isReadOnly = Register.dctReadOnlyFields[p];
      let dctProps = inputProp(p, isReadOnly);

      if(sProp)
      {
        if(Register.dctReadOnlyFields[p])
        {
          dctProps.value = sProp;
          dctProps.readOnly = 'readOnly';
        }
        else
        {
          dctProps.defaultValue = sProp;
        }
      }

      return dctProps;
    }


    return MUI(
      <ModalView open={true} width={this.state.width} noCloseBox onKeyPress={this.onKeyPress} >
        <Paper zDepth={3} style={{...Styles.ModalPaper, overflowY: 'auto', maxHeight: '100%', height: 'auto'}}>

          <ModalTitle title='Register'/>

          <form method='post' action='#' id='signup'>
            <table style={Styles.Table}>
              <colgroup>
                <col style={{width: '30%'}}/>
                <col style={{width: '70%'}}/>
              </colgroup>

              <tbody>

                <FieldTRow label='Email'>
                  <input name='email' {...getParamProp('user_email')}/>
                </FieldTRow>

                <FieldTRow label='User Name'>
                  <input name='username' {...getParamProp('user_login')}/>
                </FieldTRow>

                <FieldTRow label='Password'>
                  <input type='password' {...inputProp('user_pass')}/>
                </FieldTRow>

                <FieldTRow  label='Confirm Password'>
                  <input type='password' {...inputProp('user_pass_confirm')}/>
                </FieldTRow>

                <FieldTRow label='Full Name'>
                  <input name='name' defaultValue={this.state.params.user_nicename} {...getParamProp('user_nicename')}/>
                </FieldTRow>

                <FieldTRow label='Promotional Code'>
                  <input {...getParamProp('coupon')}/>
                </FieldTRow>

                <HLineRow/>

                <FieldTRow label='Company'>
                  <input {...getParamProp('user_company')}/>
                </FieldTRow>

                <FieldTRow label='Phone'>
                  <input name='phone' {...getParamProp('user_phone')}/>
                </FieldTRow>

                <HLineRow/>
              </tbody>
            </table>

            <div style={{margin: LayoutDims.nMargin, marginTop: 0}}>
              <b>Terms & Conditions</b>
              <br/>
              <pre style={
                {
                  ...Styles.Bordered,
                  overflowY: 'auto',
                  width: '100%',
                  minHeight: 128,
                  height: 128,
                  whiteSpace: 'pre-wrap',
                  padding: LayoutDims.nMargin / 2,
                  color: Colors.clrNimbixDark
                }
              }>
                {this.state.eula}
              </pre>

              <Separator units={1}/>
              <Checkbox labelStyle={{color: Colors.clrNimbixMed}}
                        onCheck={(e, v)=>{this.setState({canSubmit: v})}}
                        label='You must agree to the terms and conditions before you can sign up'/>
            </div>
          </form>

          <Separator units={1}/>

          <div style={Styles.FlexSpread}>
            &nbsp;
            <RaisedButton disabled={!this.state.canSubmit} onClick={this.onSubmit} label='Sign Up'
                          {...Btns.Blue} />
          </div>

          <Separator units={1}/>

        </Paper>
      </ModalView>
    );
  }
}

