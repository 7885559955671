// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {MUI, SwitchedPane, SectionViewHeader, Separator} from './Components';
import {GlobalUI} from '../Global';

import {SettingsProfile, SettingsAuth, SettingsMisc} from './SettingsProfile';
import {SettingsTeam} from './SettingsTeam';
import {SettingsSSH, SettingsDeployKey} from './SettingsSSH';
import {SettingsNotify} from './SettingsNotify';
import {SettingsVault} from './SettingsVault';
import {SettingsSAML} from './SettingsSAML';
import {SettingsLDAP} from './SettingsLDAP';
import {SettingsSummary} from './SettingsSummary';
import {SettingsIdentity} from './SettingsIdentity';
import {SettingsTeamLimits} from './SettingsTeamLimits';
import {SettingsTeamApps} from './SettingsTeamApps';
import {SettingsProjects} from './SettingsProjects';


// Team admin audit log
import AdminLogs from './AdminLogs';


// Our custom controls
import RaisedButton from 'material-ui/RaisedButton';

// Our UI data, styles and colors
import {Styles, LayoutDims, Btns} from './UIConst.js';

// Provides a container with Edit/Save and Cancel buttons for settings
class SettingsPaneContainer extends Component
{
  static propTypes =
  {
    parent: PropTypes.object.isRequired,
    paneRef: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    noEdit: PropTypes.bool,
  };

  constructor(props)
  {
    super(props);
    this.state = {editing: false};
  }

  // Edit/Save button
  onClickEditSave = () =>
  {
    const pane = this.props.parent.refs[this.props.paneRef];
    if(!this.state.editing)
    {
      this.setState({editing: true});
      pane.setState({editing: true});
    }
    else
    {
      // Call save, passing the Settings object so that it can call back later with saved()
      pane.trySave(this);
    }
  }

  // On cancel pass the state to the child and set ourselves to readonly non-editing state
  onClickCancel = () =>
  {
    this.setState({editing: false});
    this.props.parent.refs[this.props.paneRef].setState({editing: false});
    this.props.parent.refs[this.props.paneRef].cancel();
  }

  // Called by the child when changes have been saved
  saved = (child) =>
  {
    this.setState({editing: false});
    child.setState({editing: false});
  }

  render()
  {
    return (
      <div>
        <SectionViewHeader headerText={this.props.headerText}/>
        <div style={{padding: LayoutDims.nMargin * 2}}>
          {this.props.children}

          <Separator units={2}/>

          {
            !this.props.noEdit &&
              <div style={Styles.FlexSpread}>
                <RaisedButton {...Btns.Blue} label={this.state.editing ? 'Save' : 'Edit'}
                              onClick={this.onClickEditSave}/>
                {this.state.editing && <RaisedButton label='Cancel' onClick={this.onClickCancel}/>}
              </div>
          }
        </div>
      </div>
    );
  }
}

function Pane(props)
{
  return (
    GlobalUI.IsVisible('SettingsContent/' + props.name)
    &&
    <SwitchedPane iconName={props.icon} paneGroup='SettingsContent' paneName={props.name}>
      <div>
        {props.noHeader ? null : <SectionViewHeader headerText={props.title || props.name}/>}
        <div style={{padding: LayoutDims.nMargin * 2}}>
          {props.children}
        </div>
      </div>
    </SwitchedPane>
  );
}

function ContainerPane(props)
{
  return (
    GlobalUI.IsVisible('SettingsContent/' + props.name)
    &&
    <SwitchedPane iconName={props.icon} paneGroup='SettingsContent' paneName={props.name}>
      <SettingsPaneContainer headerText={props.title || props.name} paneRef={props.paneRef} parent={props.parent}>
        {props.children}
      </SettingsPaneContainer>
    </SwitchedPane>
  );
}

export default class SettingsPane extends Component
{
  constructor(props)
  {
    super(props);
    SwitchedPane.setDefault(['SettingsContent'], 'Profile');
  }

  switchPane = (pane) =>
  {
    SwitchedPane.switch('SettingsContent', pane);
  }

  componentDidMount()
  {
    // Setup a switch handler for the Vaults pane onShow,
    // unless its visible by default, in which case, call the onShow Handler manually
    if(SwitchedPane.getActivePane('SettingsContent') === 'Vaults')
    {
      this.refs.paneVault.onShow();
    }
    else
    {
      SwitchedPane.RegisterOnSwitch('SettingsContent/Vaults', () => this.refs.paneVault.onShow());
    }
  }

  render()
  {
    return MUI(

      <div ref='content-settings'>
        <SwitchedPane  iconName='account_circle' paneGroup='SettingsContent' paneName='Profile'>
          <SettingsPaneContainer headerText='Profile' paneRef='paneProfile' parent={this}>
            <SettingsProfile ref='paneProfile'/>
          </SettingsPaneContainer>

          <br/><br/>
          <div>
            <SectionViewHeader headerText='Authentication'/>
            <div style={{padding: LayoutDims.nMargin * 2}}>
              <SettingsAuth/>
            </div>
          </div>

          <br/><br/>
          <div>
            <SectionViewHeader headerText='Miscellaneous'/>
            <div style={{padding: LayoutDims.nMargin * 2}}>
              <SettingsMisc/>
            </div>
          </div>
        </SwitchedPane>

        <Pane icon='supervisor_account' name='Team'>
          <SettingsTeam ref='paneTeam'/>
        </Pane>

        <Pane icon='description' name='Team Log'>
          <AdminLogs ref='paneTeamLog' logrole='team'/>
        </Pane>

        <Pane icon='widgets' name='Team Apps'>
          <SettingsTeamApps ref='paneTeamApps'/>
        </Pane>

        <Pane icon='insert_chart' name='Summary' title='Team Summary'>
          <SettingsSummary ref='paneTeamSummary'/>
        </Pane>

        <Pane icon='assignment_ind' name='Identity' title='Job Identity'>
          <SettingsIdentity ref='paneIdentity'/>
        </Pane>

        <Pane icon='tune' name='Limits' title='Team Limits'>
          <SettingsTeamLimits ref='paneLimits'/>
        </Pane>

        <Pane icon='group_work' name='Projects' title='Team Projects'>
          <SettingsProjects ref='paneProjects'/>
        </Pane>

        <ContainerPane icon='add_alert' name='Notifications' paneRef='paneNotify' parent={this}>
          <SettingsNotify ref='paneNotify'/>
        </ContainerPane>

        <Pane icon='lock_open' name='SSH Keys' noHeader>
          <SettingsPaneContainer headerText='SSH Keys' paneRef='paneSSH' parent={this}>
            <SettingsSSH ref='paneSSH'/>
          </SettingsPaneContainer>
          <br/><br/>
          <div>
            <SectionViewHeader headerText='Deploy key'/>
            <div style={{padding: LayoutDims.nMargin * 2}}>
              <SettingsDeployKey/>
            </div>
          </div>
        </Pane>

        <Pane icon='work' name='Vaults' title='Manage Vaults'>
          <SettingsVault ref='paneVault'/>
        </Pane>

        <ContainerPane icon='group' name='SAML2 IdP' title='SAML2 IdP Configuration' paneRef='paneIDP' parent={this}>
          <SettingsSAML ref='AdminSAML'/>
        </ContainerPane>

        <ContainerPane icon='folder_shared' name='LDAP' title='LDAP Authentication Configuration' paneRef='paneLDAP' parent={this}>
          <SettingsLDAP ref='AdminLDAP'/>
        </ContainerPane>

      </div>
    );
  }
}

