// Core
import React, { Component } from 'react';

import Paper from 'material-ui/Paper';

import {renderMain} from '../App';

// Our custom components
import {MUI, ModalView, ModalTitle, Separator, SwitchedPane,
  LoginInputFieldRow, LoginInputPane, LoginSubmitPane, LoginErrField} from './Components';

// Our layout, styles and colors
import {Styles, LayoutDims, Colors} from './UIConst';
import {BusyHelper, FetchBusy, ReLogin, LocalStorage, ReloadIfNewerVersion, GlobalUI,
  Validators, ValidationErrs, getXHRErr} from '../Global'
import {DataFetcher, Data} from '../Models/Model'


export const tryLogin = (user_login, password, onSuccess, onFail, showSpinner=true, auth=null, tag=null, return_url=null, zone=null) =>
{
  if(showSpinner)
  {
    BusyHelper.Busy();

    // If user_login is blank - it is a re-login, show a different status
    BusyHelper.Status(user_login ? 'Logging in...' : 'Loading...');
  }

  const version = LocalStorage.get('version', '*');
  const params = {user_login, password, version, auth, tag, return_url};

  // null = last known zone, -1 = unzoned
  if(zone != null)
  {
    params['zone'] = zone;
  }

  const LoginFetcher = new DataFetcher('/portal/user-zoned-login', params);

  // On success stop the busy spinner, set the user profile and invoke the callback
  LoginFetcher.whenDone
  (
    (jqXHR) =>
    {
      ReloadIfNewerVersion(LoginFetcher.data.version);

      // Copy each key into the Data dict (can't assign whole thing, since there may be references)
      for(const sKey of Object.keys(LoginFetcher.data))
      {
        Data[sKey] = LoginFetcher.data[sKey];
      }

      // If our payer is set to self, make it ''
      if(Data.User.Profile.payer === Data.User.Profile.user_login)
      {
        Data.User.Profile.payer = '';
      }

      if(showSpinner)
      {
        BusyHelper.Busy(false);
      }

      // Useful for debugging
      if(process.env.REACT_APP_MC_DEV === 'devtest')
      {
        console.log(Data);
      }

      // Show the version tag
      console.log(Data.version);


      // Store the current user (for development)
      LocalStorage.set('CurrentUser', Data.User.Profile.user_login, '*');

      // If this is a fresh login as opposed to refreshing the page, save the return URL
      if(user_login)
      {
        LocalStorage.set('returnURL', Data.return_url);
      }

      if(onSuccess) onSuccess(jqXHR);
    }
  );

  LoginFetcher.ifFail
  (
    // On fail stop spinning and invoke the fail callback
    (jqXHR) =>
    {
      BusyHelper.Busy(false);
      if(onFail) onFail(jqXHR);
    }
  );

  LoginFetcher.fetch();
}

// When either field changes, if they mismatch, show a message
// and disable the Submit button
// Bind "this" to this method to reuse
function onPassChange()
{
  const sPass = this.refs.user_pass.value;
  const sPassConfirm = this.refs.user_pass_confirm.value;
  const isValid = sPass === sPassConfirm;
  this.refs.paneSubmit.setState({disabled: !isValid});
  this.setState({err: isValid ? '' : "Passwords don't match"});
}

// Check field  validity and set error state on object
function validateField(that, fieldName, params)
{
  const isValid = Validators[fieldName](params[fieldName]);
  that.setState({err: isValid? '' : ValidationErrs[fieldName]});

  // Clear the error message after 2 seconds
  setTimeout(()=>that.setState({err: ''}), 2000);
  return isValid;
}

// Submits request and logs into the user in the response
// Displays error dialog if password is weak
function doFetchAndLogin(that, msg, url, params)
{
  // Send request and login automatically after done
  const fetcher = new DataFetcher(url, params, null, false);
  fetcher.ifFail((jqXHR)=>that.setState(getXHRErr(jqXHR)));
  fetcher.whenDone(()=>ReLogin(fetcher.data.user, params.user_pass));
  FetchBusy(fetcher, msg);
}


// Login page
class LoginPane extends Component
{
  constructor(props)
  {
    super(props);
    const err = props.err && props.err.err;
    this.state = {err: err || LocalStorage.get('logoutReason', '*')};
    LocalStorage.set('logoutReason', '', '*')
  }

  onSubmit = () =>
  {
    tryLogin
    (
      this.refs.loginField.value,
      this.refs.passwordField.value,
      ()=>
      {
        this.refs.formPane.refs.form.submitted = true;
        this.refs.formPane.refs.form.submit();
        GlobalUI.ManualLogin = true;
        renderMain();
      },

      (jqXHR) => this.setState(getXHRErr(jqXHR)),
      true,
      //null,
      //null,
      //null,
      //zone,
    );
  }

  onKeepLoginChange = () =>
  {
    LocalStorage.set('keepLoggedIn', this.refs.keepField.checked, '*');
  }

  render()
  {
    return (
      <div>
        <ModalTitle title='Login' />

        <LoginInputPane ref='formPane' onSubmit={this.onSubmit}>
          <LoginInputFieldRow label='User name'>
            <input data-cy='username' autoComplete='username' ref='loginField' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Password'>
            <input data-cy='password' autoComplete='current-password' ref='passwordField' type='password' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Keep me logged in'>
            <input data-cy='keepLoggedin' ref='keepField' type='checkbox' onChange={this.onKeepLoginChange}/>
          </LoginInputFieldRow>

        </LoginInputPane>

        <iframe title='dummy' style={{display: 'none'}} width="0" height="0" name="dummyframe" id="dummyframe"> </iframe>

        <Separator units={0.5}/>

        <LoginErrField err={this.state.err}/>
        <LoginSubmitPane back={this.props.back} onClick={this.onSubmit} labelBack='Forgot Password' label='Login'/>

      </div>
    );
  }
}


// Pane for first time registration of root user
class RootRegPane extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {err: LocalStorage.get('rootErrReason', '*')};
    LocalStorage.set('rootErrReason', '', '*');

    this.onChange = onPassChange.bind(this);
  }

  onSubmit = () =>
  {
    const params =
    {
      user_email: this.refs.user_email.value,
      user_pass: this.refs.user_pass.value,
      user_nicename: this.refs.user_nicename.value,
      cookie: this.props.cookie,
    };

    // Check field validity
    if(!validateField(this, 'user_email', params)) return;
    if(!validateField(this, 'user_nicename', params)) return;
    if(!validateField(this, 'user_pass', params)) return;

    // Send request and login automatically after done
    doFetchAndLogin(this, 'Registering root user...', '/portal/root-register', params);
  }

  render()
  {
    return (
      <div>
        <ModalTitle title='Setup Administrator Account' />

        <LoginInputPane ref='formPane' onSubmit={this.onSubmit}>

          <LoginInputFieldRow label='Admin user name'>
            <input name='root' type='text' value={this.props.rootUser} readOnly
                   style={{...Styles.ParamInput, backgroundColor: Colors.clrNimbixGray}}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Email address'>
            <input data-cy='inputEmailRoot' name='email' type='email' ref='user_email' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Name'>
            <input data-cy='inputNameRoot' name='name' ref='user_nicename' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Password'>
            <input data-cy='inputPasswdRoot' onChange={this.onChange} type='password' ref='user_pass'
                   style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Confirm Password'>
            <input data-cy='inputPasswdConfirmRoot' onChange={this.onChange} type='password' ref='user_pass_confirm'
                   style={Styles.ParamInput}/>
          </LoginInputFieldRow>

        </LoginInputPane>

        <iframe title='dummy' style={{display: 'none'}} width="0" height="0" name="dummyframe" id="dummyframe"> </iframe>

        <Separator units={0.5}/>

        <LoginErrField err={this.state.err}/>
        <LoginSubmitPane back={this.props.back} onClick={this.onSubmit} ref='paneSubmit' labelBack='Back to Login' label='Submit'/>

      </div>
    );
  }
}


// Forgot password request
class ForgotPasswordPane extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {err: props.error || ''};
  }

  onSubmit = () =>
  {
    const params = {};
    for(const k of ['user_email', 'user_login'])
    {
      params[k] = this.refs[k].value;
    }

    // Check email validity
    if(!validateField(this, 'user_email', params)) return;

    // Send request and return to login page after done
    const fetcher = new DataFetcher('/portal/forgot-password', params, null, false);
    fetcher.whenDone(this.props.back);
    fetcher.ifFail((jqXHR) => this.setState(getXHRErr(jqXHR)));

    FetchBusy(fetcher, 'Sending Request...');
  }

  render()
  {
    return (
      <div>
        <ModalTitle title='Forgot Password' />

        <LoginInputPane>
          <LoginInputFieldRow label='User name'>
            <input autoComplete='username' ref='user_login' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Email'>
            <input autoComplete='email' ref='user_email' style={Styles.ParamInput}/>
          </LoginInputFieldRow>
        </LoginInputPane>

        <Separator units={0.5}/>

        <LoginErrField err={this.state.err}/>
        <LoginSubmitPane back={this.props.back} onClick={this.onSubmit} labelBack='Back To Login' label='Submit'/>
      </div>
    );
  }
}

// Reset password dialog pane
class ResetPasswordPane extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {err: props.error || ''};

    this.onChange = onPassChange.bind(this);
  }

  onSubmitReset = () =>
  {
    const params =
    {
      resetdata: this.props.params.resetdata,
      user_pass: this.refs.user_pass.value
    };

    if(!validateField(this, 'user_pass', params)) return;

    // Send request and login automatically after done
    doFetchAndLogin(this, 'Saving Password...', '/portal/reset-password', params);
  }

  render()
  {
    return (
      <div>
        <ModalTitle title='Reset Password' />

        <LoginInputPane>
          <LoginInputFieldRow label='New Password'>
            <input onChange={this.onChange} type='password' ref='user_pass' data-cy='inputResetPass'
                   style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Confirm New Password'>
            <input onChange={this.onChange} type='password' ref='user_pass_confirm' data-cy='inputResetPassConfirm'
                   style={Styles.ParamInput}/>
          </LoginInputFieldRow>
        </LoginInputPane>

        <Separator units={0.5}/>
        <LoginErrField err={this.state.err}/>
        <LoginSubmitPane back={this.props.back} ref='paneSubmit' labelBack='Back To Login'
                         onClick={this.onSubmitReset} label='Submit'/>
      </div>
    );
  }
}

// Login page
class LDAPLoginPane extends Component
{
  constructor(props)
  {
    super(props);
    const err = LocalStorage.get('logoutReason', '*') || (props.params && props.params.err)
    this.state = {err};
    LocalStorage.set('logoutReason', '', '*')
  }

  onSubmit = () =>
  {
    tryLogin
    (
      this.refs.loginField.value,
      this.refs.passwordField.value,
      ()=>
      {
        window.history.pushState(this.refs.loginField.value, '', '/');
        this.refs.formPane.refs.form.submitted = true;
        this.refs.formPane.refs.form.submit();
        GlobalUI.ManualLogin = true;
        renderMain();
      },

      (jqXHR) => this.setState(getXHRErr(jqXHR)),
      true,
      'ldap',
      this.props.params.tag,
      '/portal/ldap-login/' + GlobalUI.URLParams.tag
    );
  }

  onKeepLoginChange = () =>
  {
    LocalStorage.set('keepLoggedIn', this.refs.keepField.checked, '*');
  }

  render()
  {
    return (
      <div>
        <ModalTitle title='LDAP Login' />

        <LoginInputPane ref='formPane' onSubmit={this.onSubmit}>
          <LoginInputFieldRow label='User name'>
            <input data-cy='inputLoginLDAPUser'  name='user' ref='loginField' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Password'>
            <input data-cy='inputLoginLDAPPassword' name='ldap-password' ref='passwordField' type='password' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Keep me logged in'>
            <input ref='keepField' type='checkbox' onChange={this.onKeepLoginChange}/>
          </LoginInputFieldRow>

        </LoginInputPane>

        <iframe title='dummy' style={{display: 'none'}} width="0" height="0" name="dummyframe" id="dummyframe"> </iframe>

        <Separator units={0.5}/>

        <LoginErrField err={this.state.err}/>
        <LoginSubmitPane back={this.props.back} onClick={this.onSubmit} label='Login'/>

      </div>
    );
  }
}

export class Login extends Component
{
  constructor(props)
  {
    super(props);

    let pane;
    if(props.pane)
    {
      pane = props.pane;
    }
    else if(props.rootUser)
    {
      pane = 'Root';
    }
    else
    {
      pane = 'Login';
    }
    this.state = {pane};

    SwitchedPane.setDefault(['@LoginDialog'], pane);
  }

  componentDidMount()
  {
    GlobalUI.getMetrics();
    this.setState({width: LayoutDims.wContent * 0.8})
  }

  switchPane = () =>
  {
    // Login page has link to forgot, other panes have link back to login
    let pane = SwitchedPane.getActivePane('@LoginDialog') === 'Login' ? 'Forgot' : 'Login';
    SwitchedPane.switch('@LoginDialog', pane);
    this.setState({pane});
  }

  // Submit the active pane if enter is pressed
  onKeyPress = (evt) =>
  {
    if(evt.key === 'Enter')
    {
      this.refs[this.state.pane].onSubmit();
    }
  }

  render()
  {
    return MUI(
      <ModalView width={this.state.width} open noCloseBox onKeyPress={this.onKeyPress} >
        <Paper zDepth={3} style={{...Styles.ModalPaper, width: 'calc(100% - 16px)'}}>

          <SwitchedPane paneName='Login' paneGroup='@LoginDialog'>
            <LoginPane ref='Login' back={this.switchPane} err={this.props.err}/>
          </SwitchedPane>

          <SwitchedPane paneName='Forgot' paneGroup='@LoginDialog'>
            <ForgotPasswordPane ref='Forgot' back={this.switchPane}/>
          </SwitchedPane>

          <SwitchedPane paneName='Reset' paneGroup='@LoginDialog'>
            <ResetPasswordPane params={this.props.params} ref='Reset' back={this.switchPane}/>
          </SwitchedPane>

          <SwitchedPane paneName='Root' paneGroup='@LoginDialog'>
            <RootRegPane ref='Root'
                         params={this.props.params}
                         rootUser={this.props.rootUser}
                         cookie={this.props.cookie}
                         back={this.switchPane}/>
          </SwitchedPane>

          <SwitchedPane paneName='LDAP' paneGroup='@LoginDialog'>
            <LDAPLoginPane params={this.props.params} ref='ldap'/>
          </SwitchedPane>

          <Separator units={1}/>

        </Paper>

      </ModalView>
    );
  }
}
