import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {Separator, ShowOnly, HLine} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher, Data, AuditLog, GetTeamList} from '../Models/Model';
import LimitsTable from './LimitsTable';
import Checkbox from 'material-ui/Checkbox';

export class SettingsTeamLimits extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      ids: {},       // dict of username to DB id
      limits: {},    // dict of username to limit string
      comments: {},  // dict of username to comment
      selected: '*',  // currently selected user name
      mode: 'view'    // view/edit/add
    };

    this.teamList = GetTeamList();

    // Portal will handle the case where were team admin rather than owner
    const params = {payer: Data.User.Profile.user_login};
    this.fetcher = new DataFetcher('/portal/limits-team-list-all', params);
    this.fetcher.whenDone
    (
      // fill up state.limits
      () =>
      {
        const data = this.fetcher.data;
        const limits = {}, ids = {}, comments = {};

        // For each ID make map of user name to each of the 3 fields
        for(const id of Object.keys(data))
        {
          const limit = data[id];
          const user = limit.user;
          limits[user] = limit.mc_limits;
          comments[user] = limit.comments;

          // Team limit vs member limit
          if(id === '*')
          {
            ids[id] = data[id].id;
          }
          else
          {
            ids[user] = id;
          }
        }

        // Set state and force updation
        this.setState({ids, comments, limits, mode: 'view'});
        this.forceUpdate();

        // Now the uncontrolled UI can be updated, by simulating dropdown change
        this.onChangeUser();
      }
    );
  }

  componentDidMount()
  {
    this.fetcher.fetch();
  }

  // Updates the 3 sets of data and resets to view mode
  updateState = (sUser, sLimits, sComments, nId) =>
  {
    const limits = {...this.state.limits};
    const comments = {...this.state.comments};
    const ids = {...this.state.ids};

    limits[sUser] = sLimits;
    comments[sUser] = sComments;
    ids[sUser] = nId;

    this.setState({mode: 'view', limits, comments, ids});
  }

  // On dropdown change
  onChangeUser = () =>
  {
    // get selected user name
    const sUser = this.refs.selectMember.value;
    this.setState({selected: sUser});

    // Set the comments (since its an uncontrolled component)
    this.refs.comments.value = this.state.comments[sUser] || '';
  }

  // Sets limits[user] to the given limit string
  // TODO: Something is wrong here see onCancel
  setCurrentLimit = (sLimit) =>
  {
    const limits = {...this.state.limits};
    limits[this.state.selected] = sLimit;
    this.setState({limits});
  }

  // For a new limit, add a blank string
  onAdd = () =>
  {
    this.setCurrentLimit('');

    // Set the comments
    this.refs.comments.value = '';

    // Set UI mode
    this.setState({mode: 'add'});
  }

  // For edit, values are already set
  onEdit = () =>
  {
    // Set UI mode
    this.setState({mode: 'edit'});
  }

  // When cancelled set to whatever limit it was before
  onCancel = () =>
  {
    const sUser = this.refs.selectMember.value;

    // TODO: Something is wrong here see setCurrentLimit
    // Reset the limits to null if add or old value if edit, and reset the comments
    this.setCurrentLimit(this.state.mode === 'add' ? null: this.state.limits[sUser]);
    this.refs.comments.value = this.state.comments[this.refs.selectMember.value] || '';

    // Set UI mode
    this.setState({mode: 'view'});
  }

  // On delete send request and locally update UI
  onDelete = () =>
  {
    const sUser = this.state.selected;

    GlobalUI.Dialog.confirm
    (
      sUser === '*' ?
        'Are you sure you want to delete the limit definition for this team?'
      :
        'Are you sure you want to delete this limit definition?'
      ,
      'Confirm',
      () =>
      {
        const params = {id: this.state.ids[sUser]};
        const url = sUser === '*' ? '/portal/limits-team-delete' : '/portal/limits-team-user-delete';
        const fetcher = new DataFetcher(url, params);
        fetcher.whenDone
        (
          ()=>
          {
            AuditLog('limit', sUser === '*' ? 'team' : sUser, 'Deleted');
            this.updateState(sUser, null, null, null);
          }
        );
        FetchBusy(fetcher, 'Deleting...');
      }
    );
  }

  // Set limits for a member
  doSaveMember = (sLimits, sUser, bAdd) =>
  {
    const params =
    {
      mc_limits: sLimits,
      user: sUser
    }

    const comments = this.refs.comments.value;
    if(comments)
    {
      params.comments = comments;
    }

    // Set ID if its add rather than update
    if(!bAdd)
    {
      params.id = this.state.ids[sUser];
    }

    // Send request
    const fetcher = new DataFetcher('/portal/limits-team-user-set', params);
    fetcher.whenDone
    (
      // Update UI locally
      ()=>
      {
        AuditLog('limit', sUser, bAdd ? 'Added' : 'Changed');
        const id = bAdd ? fetcher.data.result : params.id;
        this.updateState(params.user, params.mc_limits, params.comments, id);
      }
    );

    FetchBusy(fetcher, 'Saving...');
  }

  // Set limits for the payer/whole team
  doSaveTeam = (sLimits, sUser, bAdd) =>
  {
    const params =
    {
      mc_limits: sLimits,
    }

    const comments = this.refs.comments.value;
    if(comments)
    {
      params.comments = comments;
    }

    // Set ID if its add rather than update
    if(!bAdd)
    {
      params.id = this.state.ids['*'];
    }

    // payer is self if team owner
    params.payer = Data.User.Profile.payer || Data.User.Profile.user_login

    // Send request
    const fetcher = new DataFetcher('/portal/limits-team-set', params);
    fetcher.whenDone
    (
      // Update UI locally
      ()=>
      {
        AuditLog('limit', 'team', bAdd ? 'Added' : 'Changed');
        const id = bAdd ? fetcher.data.result : params.id;
        this.updateState('*', params.mc_limits, params.comments, id);
      }
    );

    FetchBusy(fetcher, 'Saving...');
  }

  // Save changes
  onSave = () =>
  {
    // get the limit string from the table, show err if blank
    const sLimits = this.refs.limits.getData();

    // NULL means error
    if(sLimits === null)
    {
      return;
    }

    // sLimits will be a blank string if no limits were checked
    if(sLimits === '')
    {
      GlobalUI.Dialog.show('Error', 'No limits were specified', false, LayoutDims.wContent * 0.75);
      return;
    }

    // Is this an Add or Edit? Add means limits[selected] will be empty
    const sUser = this.state.selected;
    const bAdd = this.state.limits[sUser] === '';

    // Team limits have user "*"
    if(sUser === '*')
    {
      this.doSaveTeam(sLimits, sUser, bAdd)
    }
    else
    {
      this.doSaveMember(sLimits, sUser, bAdd)
    }
  }

  render()
  {
    const sLimits = this.state.limits && this.state.limits[this.state.selected];
    const bIsView = this.state.mode === 'view';

    return (
      <div>
        <ShowOnly if={this.state.limits === null}>
          <Separator units={12}/>
          <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
        </ShowOnly>

        <ShowOnly if={this.state.limits !== null}>

          <div style={Styles.Inline}>
            <select data-cy='selectTeamLimitMember' ref='selectMember' disabled={!bIsView} style={Styles.ParamInput} onChange={this.onChangeUser}>
              <option value='*' >&lt;Team Default&gt;</option>
              {this.teamList.map((e,i)=><option key={i} value={e}>{e}</option>)}
            </select>
            &nbsp; &nbsp;
            {
              bIsView ?
              (
                sLimits == null
                ?
                  <RaisedButton {...Btns.Green} label='Add' onClick={this.onAdd}/>
                :
                  <div>
                    <RaisedButton {...Btns.Green} label='Edit' onClick={this.onEdit}/>
                    &nbsp;
                    <RaisedButton {...Btns.DarkRed} label='Delete' onClick={this.onDelete}/>
                  </div>
              )
              :
              <div style={Styles.InlineFlexRow}>
                <RaisedButton key={0} {...Btns.Green} label='Save' onClick={this.onSave}/>
                &nbsp;
                <RaisedButton key={1} {...Btns.DarkRed} label='Cancel' onClick={this.onCancel}/>
              </div>
            }
          </div>

          <Separator units={2}/>
          <HLine margin={0}/>

          <Separator units={2}/>
          {
            <ShowOnly if={sLimits != null}
                      otherwise={
                        <div style={{color: Colors.clrNimbixDark}}>

                          <div style={Styles.FullCenter}><br/><b>No Limits Set</b><br/><br/></div>
                          {
                            this.state.selected === '*'
                            ?
                              <div>
                                Click <b>Add</b> to define a default set of limits for all team members.
                                Note that the entire team will be limited to this capacity, except for
                                users with their own set of limits.
                              </div>
                            :
                              <div>
                                Click <b>Add</b> to define an overriding set of limits for this user.
                                Note that any estimated monthly max pricing is in addition to the
                                Team Default limits, if set.
                              </div>
                          }
                        </div>
                      }>

              <label>Comments: </label>
              <input ref='comments'
                     disabled={bIsView}
                     style={{...Styles.ParamInput, width: '95%',
                       backgroundColor: bIsView ? Colors.clrNimbixGray : Colors.clrLight}}/>

              <Separator units={2}/>
              <LimitsTable ref='limits'
                           tag='Settings'
                           user={this.state.selected}
                           limitstr={sLimits}
                           readOnly={bIsView}/>
            </ShowOnly>
          }

        </ShowOnly>
      </div>
    );
  }

}

