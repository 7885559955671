// Core
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';


import {SectionViewHeader, ReportDisplay, SwitchedPane, Icon, fromThis, dateToLocalTimeStr} from './Components.js';
import {LayoutDims, Styles, Colors} from './UIConst.js';
import {DataFetcher, TeamJobsFetcher, AuditLog} from '../Models/Model';
import {AppsData} from '../Models/AppsData';
import {GlobalUI, Spinner, FetchBusy, userLink} from '../Global';


// This is used by SettingsSummaryTeamJobs too
export const getTeamJobDataRows = (jobsData) =>
{
  return Object.keys(jobsData).map
  (
    (jobnum) =>
    {
      const data = jobsData[jobnum];
      const submission = data.job_api_submission;
      const sAppName =
        (
          (data.job_application in AppsData) &&
          AppsData[data.job_application].data &&
          AppsData[data.job_application].data.name
        )
        || data.job_application;

      const sCommand =
        (submission.application && submission.application.command) ||
        (submission.nae && submission.nae.name) ||
        'N/A';

      const sMCType = (submission.machine && submission.machine.type) ||
                      'N/A';

      const sMCNodes = (submission.machine && submission.machine.nodes &&
                        `[${submission.machine.nodes}]`) || '';

      return {
        ...fromThis(data, ['job_number', 'job_owner_username', 'job_name']),
        job_start_time: dateToLocalTimeStr(data.job_start_time),
        app: sAppName + ':' + sCommand,
        mc: sMCType + sMCNodes,

        // Make the owner name column a clickable one
        user: userLink(data.job_owner_username, data.job_owner_username)
      };
    }
  );
}


export class TeamJobs extends Component
{
  static arrColText = [ 'Job No.', 'Username', 'Start Time', 'Application', 'Machine'];
  static arrCol = ['job_number', 'user', 'job_start_time', 'app', 'mc'];
  static dctColTypes = {job_number: 'number', user: 'job_owner_username'};

  constructor(props)
  {
    super(props);
    this.state = {jobsData: null};

    // Make the data refresh when this pane is switched to
    SwitchedPane.RegisterOnSwitch('DashboardContent/Team Jobs', this.refreshData);
    TeamJobsFetcher.whenDone(()=>this.setState({jobsData: TeamJobsFetcher.data}));
  }

  componentDidMount()
  {
    GlobalUI.TeamJobs = this;
    this.refreshData();
  }

  refreshData = () =>
  {
    this.setState({jobsData: null});
    TeamJobsFetcher.fetch();
  }


  onClickTerminateJob = (evt) =>
  {
    const elem = evt.target;
    const sJobName = elem.parentElement.getAttribute('data-jobname');
    const iJobNum = elem.parentElement.getAttribute('data-jobnum');
    const user = elem.parentElement.getAttribute('data-user');

    // Confirm termination
    GlobalUI.Dialog.confirm(
      <div>
        Are you sure you want to terminate this team member's job #{iJobNum}?
        <br/>
        <br/>
        The job will stop immediately and this may result in data loss.
      </div>,
      'Confirm Job Termination',
      () =>
      {
        // Send request
        const fetcher = new DataFetcher('/portal/job-terminate', {jobname: sJobName});
        fetcher.whenDone
        (
          () =>
          {
            AuditLog('job', iJobNum, 'Terminated by Admin on behalf of ' + user);

            // After termination, show success indication and refresh the list
            GlobalUI.Dialog.show
            (
              'Success',
              `Job ${iJobNum} was successfully terminated`,
              false,
              LayoutDims.wContent * 0.75, 
              this.refreshData
            );
          }
        );

        fetcher.ifFail((jqXHR) => GlobalUI.Dialog.showErr(jqXHR, 'Error Terminating Job #' + iJobNum));
        FetchBusy(fetcher, 'Terminating...');
      }
    );
  }

  render()
  {
    const btnTerminate = (row) =>
      <div data-jobnum={row.job_number}
           data-jobname={row.job_name}
           data-user={row.job_owner_username}
           onClick={this.onClickTerminateJob}>
        {Icon('cancel', {color: Colors.clrNimbixDarkRed, cursor: 'pointer'})}
      </div>

    return (
      <div>
        <SectionViewHeader headerText='Team Jobs'/>
        <div style={{padding: LayoutDims.nMargin * 2}}>
          <RaisedButton backgroundColor={Colors.clrNimbixDarkGreen} label='Refresh'
                        labelColor={Colors.clrLight} onClick={this.refreshData}/>

          <br/>
          <br/>
          {
            this.state.jobsData && Object.keys(this.state.jobsData).length
            ?
              <ReportDisplay colText={TeamJobs.arrColText}
                             colFields={TeamJobs.arrCol}
                             colTypes={TeamJobs.dctColTypes}
                             data={getTeamJobDataRows(this.state.jobsData)}
                             extraButton={btnTerminate}/>
            :
              (
                !this.state.jobsData &&
                <Spinner style={Styles.Full} textColor={Colors.clrNimbixDark} size={48} fontSize={16}
                       status='Fetching...'/>
              )
          }
        </div>
      </div>

    );
  }
}

