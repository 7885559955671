import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {Separator, InputRow, ColGroup} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI} from '../Global';
import {DataFetcher} from '../Models/Model';

import {AdminFilteredList, makeDataRows} from './AdminFilteredList';


//Dialog for editing or adding metadata
class MetaDlg extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props, err: ''};
  }

  // When reopened reset state to new props
  componentWillReceiveProps(nextProps)
  {
    this.setState
    (
    {...nextProps},
    // Set input values after update
    () => this.setInputValues(nextProps)
    );
  }

  // On rerender, set the values
  componentDidMount()  { this.setInputValues(this.state); }

  // Sets the host field if editing/adding
  setInputValues(state)
  {
    if(state.action !== 'Delete')
    {
      for(const name of ['user', 'key', 'value'])
      {
        this.refs['meta_' + name].value = state.action === 'Edit' ? state.meta[name] : '';
      }
    }
  }

  // On save fire the request
  onSave = () =>
  {
    const isDelete = this.state.action === 'Delete';

    // Get the values
    const data = {};
    if(!isDelete)
    {
      for(const name of ['user', 'key', 'value'])
      {
        data[name] = this.refs['meta_' + name].value;
      }
    }
    else
    {
      data.user = this.state.meta.user;
      data.key = this.state.meta.key;
    }

    // Fire the request
    const sURL = '/portal/admin-user-' + (isDelete ? 'del-meta' : 'set-meta');
    const fetcher = new DataFetcher(sURL, data)
    .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Operation Failed'))
    .whenDone
    (
      // On success, make the parent update the view
      ()=>
      {
        GlobalUI.Dialog.onClose();
        this.props.parent.refreshData();
      }
    );

    const sMsg = isDelete ? 'Deleting...' : 'Saving...';
    FetchBusy(fetcher, sMsg);
  }

  onChange = () =>
  {
    for(const name of ['user', 'key', 'value'])
    {
      const val = this.refs['meta_' + name].value;
      if(!val)
      {
        this.setState({err: 'Enter a valid value for: ' + name[0].toUpperCase() + name.substr(1)});
        return true;
      }
    }

    this.setState({err: null});
  }

  render()
  {
    const isEdit = this.state.action === 'Edit';
    const isDelete = this.state.action === 'Delete';
    const sWidth = `calc(100% - ${LayoutDims.nMargin * 2}px)`;

    return (
    <div>
      {
        !isDelete
        ?
        <div>
          <InputRow title='User Name:' width={sWidth}>
            <input data-cy='inputMetaUser' ref='meta_user'
                   style={{...Styles.ParamInput, maxWidth: '20%', backgroundColor: isEdit ? Colors.clrNimbixGray : 'none'}}
                   disabled={isEdit}
                   list='user-data-list' />
          </InputRow>

          <InputRow title='Key:' width={sWidth}>
            <input data-cy='inputMetaKey' ref='meta_key' style={Styles.ParamInputWide} disabled={isEdit} onChange={this.onChange} />
          </InputRow>

          <InputRow title='Value:' width={sWidth}>
            <input data-cy='inputMetaValue' ref='meta_value' style={Styles.ParamInputWide} onChange={this.onChange} />
          </InputRow>

          <Separator units={1}/>

          <span data-cy='divMetaErr' className='errortext' style={{fontSize: LayoutDims.FontSize}}>
                {this.state.err}&nbsp;
          </span>
          <Separator units={1}/>

        </div>
        :
        <div>
          <Separator/>
          Are you sure you wish to delete this meta data key?
          <Separator units={3}/>
        </div>
      }

      <div style={Styles.FlexSpread}>
        <RaisedButton {...Btns.Gray} label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
        <RaisedButton
        data-cy='confirmOK'
        {...Btns.Blue}
        label='OK'
        onClick={this.onSave}
        disabled={!isDelete && this.state.err != null} />
      </div>
    </div>
    );
  }
}

// Component that shows the meta data for sys admins
// Shares most of the code with AdminJobs via AdminFilteredList
export default class AdminMetaData extends AdminFilteredList {

  // Set up the UI for Admins or Team admins based on who we are
  componentWillMount()
  {
    this.initAdmin()

    // Under what key is the count of rows requested in the ajax call
    this.keyCount = 'limit';

    // Sort col and direction
    this.sortCol = 0;
    this.sortDesc = false;

    // Entries per page
    this.pagesize = 100;

    // Skips the implicit hidden "id" column
    // We need id because metadata rows dont have a unique primary key
    this.minSearchCol = 1;

    // Extra height needed for rendering Add/Edit/Delete
    this.extraHeight = 64;

    this.refreshData()
  }

  // Changes filter string
  translateFilter()
  {
  }

  // Set up UI for sysadmins
  initAdmin()
  {
    // Override the columns, filters and titles
    this.arrColFields = ['user', 'key', 'value'];
    this.arrFilters = ['user', 'key', ''];
    this.arrColText = ['User', 'Key', 'Value'];

    // URL used to fetch the count
    this.urlCount = '/portal/admin-meta-count';

    // URL used to fetch the data
    this.urlData = '/portal/admin-meta-query';

    // Prefix used for the refs of filter edit boxes
    this.filterName = 'metaadminfilter';

    // Column dimensions
    this.colGroup = <ColGroup cols={[25, 20]}/>;
  }

  // Overridden methods

  // Called to get the count from the result of the request that fetches the count
  getCount(fetcher)
  {
    return fetcher.data.result;
  }

  // Called to construct the data row elements given the filtered data
  getDataRowElems(arrFilterData)
  {
    return makeDataRows
    (
      null,
      arrFilterData,
      this.arrColFields,
      {},
      'id',
      this.state.selectedIndex,
      this.onClickRow
    );
  }

  // Called to get the column group component for the view
  getColGroup()
  {
    return this.colGroup;
  }

  // Called to get the data rows from the fetcher
  getRows(fetcher)
  {
    const arrKeys = Object.keys(fetcher.data.result);
    return arrKeys.map
    (
      (key, id) =>
      {
        const data = fetcher.data.result[key];
        data.id = String(id);
        return data;
      }
    );
  }

  renderExtra()
  {
    return (
      <div>
        <div>
          <RaisedButton data-cy='btnMetaNew' {...Btns.Green} label='New' onClick={this.onAdd}/>
          &nbsp;&nbsp;
          <RaisedButton data-cy='btnMetaEdit' disabled={this.state.selectedIndex === -1} {...Btns.Blue} label='Edit' onClick={this.onEdit}/>
          &nbsp;&nbsp;
          <RaisedButton data-cy='btnMetaDelete' disabled={this.state.selectedIndex === -1} {...Btns.DarkRed} label='Delete' onClick={this.onDel}/>
        </div>
        <Separator units={2}/>
      </div>
    )
  }

  onClickRow = (evt) =>
  {
    const selectedIndex = evt.target.parentElement.getAttribute('name');
    this.setState({selectedIndex});
  }

  onAdd = () => this.onMetaAction('Add')
  onEdit = () => this.onMetaAction('Edit')
  onDel = () => this.onMetaAction('Delete')

  // Show dialog box (delete shows confirm dialog)
  onMetaAction = (action) =>
  {
    // Get the selected index and corresponding metadata if any
    const idx = this.state.selectedIndex;
    const meta = this.state.rows.find(e=>e.id === idx)
    const props = {action, parent: this, meta};

    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      action === 'Delete' ? 'Confirm' : `${action} Metadata`,
      <MetaDlg {...props}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }
}
