// Core
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import {red900} from 'material-ui/styles/colors';

// Our UI data, styles and colors
import {Styles, Colors, Btns, LayoutDims} from './UIConst.js';
import {InputRow, ReportDisplay, Separator, Icon} from './Components';

import {StatsFetcher, DataFetcher, Data, ROLE_ADMIN, ROLE_TENANT_ADMIN, AuditLog} from '../Models/Model';
import {GlobalUI, BusyHelper, Spinner, FetchBusy, ValidationErrs, Validators, userLink} from '../Global';
import {copyToClipboard} from './Utils';

const LabelTeamRestrict = 'Restrict this user to team applications only. '
                        + 'If checked, the user will only be able to run applications '
                        + 'that are shared with the team, and will not be able to '
                        + 'use PushToCompute to build new applications';
//Team member invite dialog
class InviteDlg extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {err:''};   // Error message
    this.vals = {};           // Contains the user name and email
  }

  // When either field changes, validate and set the error message
  onChange = () =>
  {
    for(const k of ['user_email', 'user_nicename'])
    {
      if(!Validators[k](this.refs[k].value))
      {
        this.setState({err: ValidationErrs[k]});
        return;
      }
      else
      {
        this.vals[k] = this.refs[k].value;
      }
    }

    this.setState({err: null});
  }

  // Add "-" privilege for team only invite
  onCheckTeamOnly = (e, v) =>
  {
    if(v)
    {
      this.vals.user_privs = '-';
    }
    else
    {
      delete this.vals.user_privs;
    }
  }

  render()
  {
    return (
      <div style={{margin: LayoutDims.nMargin, marginTop:0}}>
        <InputRow title='Email'>
          <input ref='user_email' onChange={this.onChange} style={Styles.ParamInputWide}/>
        </InputRow>

        <InputRow title='Full Name'>
          <input ref='user_nicename' onChange={this.onChange} style={Styles.ParamInputWide}/>
        </InputRow>

        <br/>
        <Checkbox ref='checkTeamOnly' label={LabelTeamRestrict} onCheck={this.onCheckTeamOnly}
                  labelStyle={{color: Colors.clrNimbixDark, fontSize: 16, marginLeft: -8}}/>

        <div className='errortext'>{this.state.err || <span>&nbsp;</span>}</div>
        <br/>

        <div>
          <div style={{...Styles.Inline, justifyContent: 'flex-end'}}>
            <RaisedButton label='Get Invite Link' {...Btns.Blue} disabled={this.state.err != null}
                          onClick={()=>this.props.onOK(this.vals, true)}/>
            &nbsp;&nbsp;
            <RaisedButton label='Email Invite Link' {...Btns.Green} disabled={this.state.err != null}
                          onClick={()=>this.props.onOK(this.vals, false)}/>
            &nbsp;&nbsp;
          </div>
        </div>

      </div>
    );
  }
}


export class SettingsTeam extends Component
{
  // The table column titles and the corresponding keys in the data dict
  static arrColFields = ['user_nicename', 'user', 'user_email', 'priv_label'];
  static arrColNames = ['Name', 'Username', 'Email', 'Restrictions'];
  static dctColTypes = {user : 'user_login'}

  constructor(props)
  {
    super(props);

    // we have stats data from login response, so fill it
    this.state =
    {
      data: this.getData(),
      selectedIndex: -1,
      allowImpersonation: Data.User.Identity.impersonation,
      disablePasswordSubst: Data.User.DisablePasswordSubst
    };

    // Setup the stats fetcher to update this
    // Call unbusy here since we may have been triggered by onPromote
    StatsFetcher.whenDone
    (
      ()=>
      {
        this.setState({data: this.getData()});
        this.forceUpdate();
        BusyHelper.Unbusy();
      }
    );
  }

  // Stats contains the team info too
  // Converts the stats data from the portal to an array suitable for rendering team list
  getData = () =>
  {
    const data = [];
    let i = 0;
    for(const user of Object.keys(Data.User.Stats))
    {
      // Stats data has @ as a username representing the total team, ignore that
      // Ignore self and former team members too
      const userData = {...Data.User.Stats[user]};
      const isTotal = user === '@';

      // Make the user name column a clickable one
      userData.user = userLink(userData.user_login, userData.user_login);

      if(!isTotal && !userData.former && user !== Data.User.Profile.user_login)
      {
        userData.id = i++;
        // If the privs is '-', then label for restriction is "team apps"
        // else show that sysadmin has set a restriction
        userData.priv_label = userData.user_privs ? userData.user_privs === '-' ? 'Team Apps' : 'Sysadmin' : '';
        data.push(userData);
      }
    }

    return data;
  }

  // Send the invite request with a spinner
  // vals contains user_nicename and user_email
  doInvite = (vals, bLinkOnly) =>
  {
    if(bLinkOnly)
    {
      vals.linkonly = true;
    }

    const fetcher = new DataFetcher('/portal/invite-team-member', vals)
    .whenDone
    (
      (jqXHR)=>
      {
        AuditLog('team', vals.user_email, bLinkOnly ? 'Invite Link Created' : 'Invited');
        if(bLinkOnly)
        {
          copyToClipboard(jqXHR.responseJSON.invite_link);
        }

        GlobalUI.Dialog.show('Information', bLinkOnly ? 'Invitation link copied to clipboard' : `An invitation link was sent to ${vals.user_email}`);
      }
    )
    .ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Invite Error'));

    FetchBusy(fetcher, 'Sending Invitation...');
  }

  // Show invite dialog
  onInvite = () =>
  {
    GlobalUI.Dialog.show('Invite Team Member', <InviteDlg onOK={this.doInvite}/>);
  }

  toggleLock = () =>
  {
    const member = this.state.data[this.state.selectedIndex].user_login;
    const lock = !this.state.data[this.state.selectedIndex].user_locked;
    let verb = (lock ? 'L' : 'Unl') + 'ock';

    GlobalUI.Dialog.confirm
    (
      <div>
        <p>
          Are you sure you wish to {verb.toLowerCase()} the account for {member}?
        </p>

        {
          verb[0] === 'L' &&
          <p>
            Note that locking an account immediately kills any jobs the user is running
            and prevents them from running any more until unlocked.
          </p>
        }
      </div>,
      verb + ' Team Member',
      ()=>
      {
        const fetcher = new DataFetcher('/portal/lock-team-member', {member, lock})
          .whenDone
          (
            ()=>
            {
              AuditLog('team', member, verb + 'ed');
              // Opportunistically update UI
              const data = [...this.state.data];
              data[this.state.selectedIndex].user_locked = lock;
              this.setState({data});
              GlobalUI.Dialog.clear();
            }
          )
          .ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Team Error'));
        FetchBusy(fetcher, verb + 'ing Team Member...');
      },
      null,
      'Yes',
      'No'
    );
  }

  // Confirms member removal and sends request with a spinner
  onRemove = () =>
  {
    const member = this.state.data[this.state.selectedIndex].user_login;
    let lock = true;
    const onCheck = (e, v) => lock = v;

    GlobalUI.Dialog.confirm
    (
      <div>
        <p>
          Are you sure you wish to remove {member} from your team?
        </p>
        <Checkbox label='Lock account and data' defaultChecked={true} onCheck={onCheck}/>
        <p>
          <b>NOTE: </b>
          Removing a user from your team automatically kills all his/her jobs
          and optionally locks the account. If you choose to lock the account
          and its data, you must contact Support to recover it.
        </p>
      </div>,
      'Remove Team Member',
      ()=>
      {
        const fetcher = new DataFetcher('/portal/del-team-member', {member, lock})
        .whenDone
        (
          ()=>
          {
            AuditLog('team', member, 'Removed');
            StatsFetcher.fetch();
            GlobalUI.Dialog.clear();
          }
        )
        .ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Team Error'));
        FetchBusy(fetcher, 'Removing Team Member...');
      },
      null,
      'Yes',
      'No'
    );
  }

  onRestrict = () =>
  {
    const idx = this.state.selectedIndex;
    const member = this.state.data[idx].user_login;
    const sUserPrivs = this.state.data[idx].user_privs;
    let bRestrict = sUserPrivs === '-';

    // If its a sysadmin restriction, don't allow change
    if(sUserPrivs && !bRestrict)
    {
      GlobalUI.Dialog.show('Information', 'Only a system administrator can remove this restriction', false, LayoutDims.wContent * 0.65);
      return;
    }

    const onCheckTeamOnly = (e, v) => bRestrict = v;
    GlobalUI.Dialog.confirm
    (
      <div>
        <Checkbox label={LabelTeamRestrict} onCheck={onCheckTeamOnly}
                  labelStyle={{color: Colors.clrNimbixDark, fontSize: 16}}
                  defaultChecked={bRestrict}
        />
      </div>,
      'Set Team Member App Restriction',
      ()=>
      {
        const fetcher = new DataFetcher
        (
          '/portal/user-set-privileges',
          {
            member,
            privs: bRestrict ? '-': ''
          }
        )
        .whenDone
        (
          () =>
          {
            AuditLog('team', member, bRestrict ? 'Restricted' : 'Unrestricted');
            const data = [...this.state.data];
            data[idx].user_privs = bRestrict ? '-' : '';
            data[idx].priv_label = bRestrict ? 'Team Apps' : '';
            this.setState({data});

            // Clear the dialog
            GlobalUI.Dialog.clear();
          }
        )
        .ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Team Error'));
        FetchBusy(fetcher, 'Applying Settings...');
      }
    );
  }

  onClickRow = (i) =>
  {
    this.setState({selectedIndex: i});
  }

  doPromote = (data, role) =>
  {
    const params =
    {
      user: data.user_login,
      promote: role === 0
    };

    const fetcher = new DataFetcher('/portal/team-set-admin', params);
    fetcher.whenDone
    (
      () =>
      {
        AuditLog('team', params.user, params.promote ? 'Promoted' : 'Demoted');
        FetchBusy(StatsFetcher, 'Refreshing...')
      }
    );

    fetcher.ifFail
    (
      (jqXHR)=>
      {
        BusyHelper.Unbusy();
        GlobalUI.DialogConfirm.showErr(jqXHR, 'Failed to set role');
      }
    )

    BusyHelper.BusyStatus('Updating...');
    fetcher.fetch();
  }

  onPromote = () =>
  {
    const data = this.state.data[this.state.selectedIndex];
    const role = data.user_roles & ROLE_TENANT_ADMIN;

    const sMsg = role === 0
    ?
      <div>
        Are you sure you want to promote <b>{data.user_nicename}</b> to the Team Admin role?
        <br/><br/>
        Team Admins have full permission to act on team users, query job history,
        and terminate any running jobs on behalf of these users. Once promoted,
        these functions will be enabled immediately for this user.
      </div>
    :
      <div>
        Are you sure you want to demote <b>{data.user_nicename}</b> from the Team Admin role?
        <br/><br/>
        Team Admin functions will be disabled immediately for this user if you answer yes.
      </div>

    GlobalUI.DialogConfirm.confirm
    (
      sMsg,
      'Confirm Role Change',
      ()=>this.doPromote(data, role),
      null,
      'Yes',
      'No',
      LayoutDims.wContent * 0.75
    );
  }

  // Owner icon is in red
  // Owner and admins are filled, tenant admins are outlined
  renderTenantAdminIcon = (row) =>
  {
    const role = row.user_roles;
    if(row.user_login === Data.User.Profile.payer)
    {
      return Icon('person', {...Styles.TableBtn, color: Colors.clrNimbixDarkRed});
    }

    if(role === ROLE_ADMIN)
    {
      return Icon('person', Styles.TableBtn);
    }

    if(role & ROLE_TENANT_ADMIN)
    {
      return Icon('person_outline', Styles.TableBtn);
    }

    return '';
  }


  fetchWithErrorHandler = (url, params, whenDone) =>
  {
    const fetcher = new DataFetcher(url, params);
    fetcher.whenDone(whenDone);

    // On failure, show error dialog
    fetcher.ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Failed to save'));
    FetchBusy(fetcher, 'Saving...');
  }

  // Impersonation Checkbox handler
  allowImpersonation = (e, v) =>
  {
    // Impersonation is part of identity
    const params = {...Data.User.Identity, username: Data.User.Profile.user_login, impersonation: v};

    this.fetchWithErrorHandler
    (
      '/portal/ident-update',
      params,
      // On success update both Data and UI
      ()=>
      {
        Data.User.Identity.impersonation = v;
        this.setState({allowImpersonation: v, data: this.getData()});
        GlobalUI.TeamJobs.forceUpdate();
      }
    );
  }

  // Disable password Checkbox handler
  onDisablePasswdSubst = (e, v) =>
  {
    // Impersonation is part of identity
    const params = {username: Data.User.Profile.user_login, disable: v};
    this.fetchWithErrorHandler
    (
      '/portal/user-disable-passwd-subst',
      params,
      // On success update both Data and UI
      ()=>
      {
        Data.User.DisablePasswordSubst = v;
        this.setState({disablePasswordSubst: v});
      }
    );
  }

  render()
  {
    const isSelected = this.state.selectedIndex >= 0;
    const data = isSelected ? this.state.data[this.state.selectedIndex] : {};

    // Is user locked
    const locked = isSelected && data.user_locked;

    // Are we allowed to perform an action on this user
    const canChange = isSelected && data.user_roles !== ROLE_ADMIN && data.payer;

    // Is the selected user a tenant admin (or sysadmin)
    const isTAdmin = isSelected && (data.user_roles & ROLE_TENANT_ADMIN);

    // Generate the table rows from the team data
    return (
      <div>
        <div style={Styles.FlexSpread}>
          <RaisedButton label='Invite' {...Btns.Blue} onClick={this.onInvite}/>

          <div style={Styles.InlineFlexRow}>

            <RaisedButton data-cy='btnPromote'
                          label={isTAdmin ? 'Demote' : 'Promote'}
                          disabled={!canChange}
                          {...Btns.Blue}
                          onClick={this.onPromote}/>
            &nbsp;&nbsp;&nbsp;

            <RaisedButton data-cy='btnRestrict'
                          label='Restrict'
                          disabled={!canChange}
                          onClick={this.onRestrict}/>
            &nbsp;&nbsp;&nbsp;

            <RaisedButton data-cy='btnLock'
                          label={locked ? 'Unlock' : 'Lock'}
                          disabled={!canChange}
                          {...(locked ? Btns.Green : Btns.Gray)}
                          onClick={this.toggleLock}/>
            &nbsp;&nbsp;&nbsp;

            <RaisedButton data-cy='btnRemove'
                          {...Btns.DarkRed}
                          disabled={!canChange}
                          label='Remove'
                          onClick={this.onRemove}/>
          </div>


        </div>

        <Separator units={1}/>

        {
          this.state.data
          ?
            <div>

              <Separator units={2}/>
              <Checkbox data-cy='checkImpersonation'
                label='Allow team administrators to impersonate team user accounts.
                Note that this permits team administrators to run jobs, inspect data, and review job history
                on behalf of other users on the team.
                Do not check this box if your company policies or local regulations forbid this!'
                onCheck={this.allowImpersonation}
                checked={this.state.allowImpersonation}
              />

              <Separator units={2}/>
              <Checkbox data-cy='checkDisablePasswdSubst'
                label='Do not encode generated job passwords into URLs.  Note that this will require users to
                key or paste in passwords when connecting to remote sessions via the browser.'
                checked={this.state.disablePasswordSubst}
                onCheck={this.onDisablePasswdSubst}/>

              <Separator units={2}/>
              <ReportDisplay ref='userList' data={this.state.data}
                             sortAsc={true}
                             sortCol={0}
                             colID={'user_login'}
                             colFields={SettingsTeam.arrColFields}
                             colText={SettingsTeam.arrColNames}
                             colTypes={SettingsTeam.dctColTypes}
                             onClickRow={this.onClickRow}
                             selectedIndex={this.state.selectedIndex}
                             getExtraStyle={(row) => row.user_locked ? {color: red900} : {}}
                             extraButton={this.renderTenantAdminIcon}/>
            </div>
          :
            <Spinner style={{...Styles.Full, marginTop: '10%'}}  size={64} textColor={Colors.clrNimbixDark} status='Loading...'/>
        }

      </div>
    );
  }
}
