// This module contains classes and global objects that fetch certain data resources from the server
// There are two kinds of fetchers, one for custom actions (Fetcher) and one that automagically
// set the state of a subscriber component with the data fetched

// Generic AJAX dara fetcher
// Construct and call whenDone() to register a callback and call fetch()
// whenDone() can be called anytime, it will fire if data has been fetched
// whenFailed() also exists
// Extend class and override handleDone() to inject custom behavior before
// whenDone() registered callbacks are called

import * as jquery from 'jquery';

import {AppsData, PTCAppsData, AdminAppsData} from './AppsData';
import {LocalStorage} from '../Global';

const DefaultIcon = '/static/images/default.png';
const ScreenShot = '/static/images/screenshot.jpg';


// Setup CSRF protection for AJAX POST requests
function csrfSafeMethod(method)
{
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

// Parses out a cookie out of the browser cookie format name=value;name=value ...
function GetCookie(name)
{
  var cookieValue = null;
  if(document.cookie && document.cookie !== '')
  {
    var cookies = document.cookie.split(';');
    for(var i = 0; i < cookies.length; i++)
    {
      var cookie = jquery.trim(cookies[i]);

      // Does this cookie string begin with the name we want?
      if(cookie.substring(0, name.length + 1) === (name + '='))
      {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// Setup our CRSF token with every POST
jquery.ajaxSetup
(
  {
    beforeSend: function(xhr, settings)
    {
      if(!csrfSafeMethod(settings.type) && !this.crossDomain)
      {
        xhr.setRequestHeader('X-CSRFToken', GetCookie('csrftoken'));
      }
    },
  }
);

function FixRegSymbols(s)
{
  return s.replace(/&trade;|\(TM\)/g, '™').replace(/&reg;|\(R\)/g, '®');
}

// User roles - see models.py in the DAL
export const ROLE_ADMIN = 65535
export const ROLE_BILLING_ANALYST = 1
export const ROLE_EXPERIMENTAL = 2
export const ROLE_PLATFORM_DEV = 4
export const ROLE_MACHINE_TESTER = 8
export const ROLE_SAML_ADMIN = 16
export const ROLE_TENANT_ADMIN = 32
const MaxRole = ROLE_TENANT_ADMIN;

export const JobSubstatus =
{
  SUBSTATUS_NONE: 0,
  SUBSTATUS_LIMITS: 1,
  SUBSTATUS_TERMINATED_OUTSIDE: 2,
  SUBSTATUS_LICENSE_FEATURES: 3,
  SUBSTATUS_SUSPENDED_USER: 4,
  SUBSTATUS_SUSPENDED_TEMP_LM: 5,
}

export const JobSuspSubstatuses= [JobSubstatus.SUBSTATUS_SUSPENDED_TEMP_LM, JobSubstatus.SUBSTATUS_SUSPENDED_USER];


export const RoleNames =
{
  0: 'User',
  [ROLE_EXPERIMENTAL]: 'Experimental User',
  [ROLE_PLATFORM_DEV]: 'Platform Developer',
  [ROLE_MACHINE_TESTER]: 'Machine Tester',
  [ROLE_BILLING_ANALYST]: 'Analyst',
  [ROLE_SAML_ADMIN]: 'SAML/LDAP Admin',
  [ROLE_TENANT_ADMIN]: 'Team Admin',
  [ROLE_ADMIN]: 'System Admin',
};


export const RoleNamesShort =
{
  0: 'User',
  [ROLE_EXPERIMENTAL]: 'EXP',
  [ROLE_PLATFORM_DEV]: 'PD',
  [ROLE_MACHINE_TESTER]: 'MCT',
  [ROLE_BILLING_ANALYST]: 'BA',
  [ROLE_SAML_ADMIN]: 'LA',
  [ROLE_TENANT_ADMIN]: 'TA',
  [ROLE_ADMIN]: 'SA',
};

// Human readable representation of role
export const makeRoleString = (role) =>
{
  // Keep linter happy
  role |= 0;
  if(role === 0 || role === ROLE_ADMIN)
  {
    return RoleNames[role];
  }

  // Accumulate the roles
  const arrRoles = [];
  for(let i = 1; i <= MaxRole; i <<= 1)
  {
    if(i & role)
    {
      arrRoles.push(RoleNames[i]);
    }
  }

  // Make it a list
  return arrRoles.join(', ');
}



//Placeholder for all data, te data gets filled in by AJAX
let Data =
{
  Apps: [],
  
  PTCApps: [],

  // User profile data
  User:
  {
    Profile:
    {
      // Dummy data for auto complete help
      payer_vaults: false,//bool,
      user_credits: 0,
      user_notify_emails: '', // CSV list of emails
      user_privs: '',
      user_company: 'company' ,
      payer: 'payer',
      billing_code: 0,
      payer_apps: false,
      user_login: null,
      user_phone: '',
      enabled: true,
      user_locked: false,
      user_nicename: 'User',
      user_apikey: '',
      user_registered: '',
      user_roles: 65535,
      user_notify_texts: '',
      user_email: '',
      developer: true,
      active: true,
      privs: [], //array of privs - why duplicated?
      currency_symbol: '$',

      team_jobs_public: false, // Whether team members can see team jobs (stored in usermeta of the payer)
    },

    Identity: {},
    DisablePasswordSubst: false, // password substitution user meta-data
    CurrentZone: -1, // Zone were logged into
    DisableAPISubstWarning : false, // Dont warn about %APIKEY% substitution

    AdminJobTerminateLimit: 0,   // Max number of jobs that can be bulk terminated on Admin/Jobs (0 means all visible)
  },

  // List of machines
  Machines:
  {
    // Dummy data for auto complete help
    dummy:
    {
      mc_name: '',
      mc_description: '',
      mc_cores: 4,
      mc_slots: 4,
      mc_gpus: 2,
      mc_ram: 32,
      mc_swap: 64,
      mc_scratch: 64,
      mc_properties: '',
      mc_devices: '',
      mc_slave_properties: '',
      mc_slave_gpus: 0,
      mc_slave_ram: 0,
      mc_scale_min: 0,
      mc_scale_max: 0,
      mc_scale_select: '',
      mc_lesser: 0,
      mc_price: 1.00,
      mc_priority: 0,
      mc_privs: []
    },
  },

  // This contains the list of all machines for team admins (ignoring limits)
  MachinesAll: {},

  // List of vaults and info for this user
  Vaults:
  {

    VaultInfo:
    {
      // vaultname : { info :{<info>} , meta: {<meta info>} }
      // ...
    },

    // The default vault for this user
    DefaultVault: null
  },

  // Jobs data
  Jobs:
  {
    History:
    {
      // job id : {job details}
    }
  },

  // Docker data
  Docker:
  {
    LoginInfo: null
  },

  // Misc config
  Config:
  {
    ImgCacheID: Math.random()
  },

  Audit:
  {
    Categories: []
  },

  AppsWhitelist: {},

  Clusters: {},

  Zones: {},

  HPCQueues: {},

  ValidZones: [],

  MOTD: '',

  License: '',

  TeamZones: [],
}

class DataFetcher
{
  // TODO
  constructor(url, params = {}, timeout = null, enableDev = true)
  {
    this.url = url;
    this.params = params;
    this.timeout = timeout;

    this.data = {};
    this.arrFnDone = [];
    this.arrFnFail = [];
    this.done = false;
    this.error = false;
    this.jqXHR = null;
    this.enableDev = enableDev;
  }

  // Register a function to be notified on success
  whenDone(fnDone)
  {
    // Add to our array and also notify if the request is already done
    this.arrFnDone.push(fnDone);
    if(this.done && !this.error) {fnDone(this.jqXHR);}

    // return this to allow chaining
    return this;
  }

  // Register a function to be notified on fail
  ifFail(fnFail)
  {
    // Add to our array and also notify if the request is already failed
    this.arrFnFail.push(fnFail);
    if(this.done && this.error) {fnFail(this.jqXHR);}

    // return this to allow chaining
    return this;
  }

  // Adds or overrides params
  addParams(params)
  {
    this.params = {...this.params, ...params}
  }

  // Send off the AJAX
  fetch(method = 'POST', params)
  {
    this.done = false;
    this.error = false;

    if(params)
    {
      this.params = params;
    }

    // Construct the JQuery AJAX and launch
    const req =
    {
      url: this.url,
      data: this.params,
      method: method,

      success: (data, textStatus, jqXHR) =>
      {
        this.done = true;
        this.error = false;
        this.jqXHR = jqXHR;
        this.handleDone(jqXHR);
        this.notifyAllSuccess();
      },

      error: (jqXHR) =>
      {
        this.error = true;
        this.jqXHR = jqXHR;
        this.handleFail();
        this.notifyAllFail();
      }
    }

    if(this.timeout)
    {
      req.timeout = this.timeout;
    }

    jquery.ajax(req);

    return this;
  }

  // Call the success functions
  notifyAllSuccess()
  {
    if(!this.error)
    {
      this.arrFnDone.map((fn) => fn(this.jqXHR));
    }
  }

  // Call the fail functions
  notifyAllFail()
  {
    if(this.error)
    {
      this.arrFnFail.map((fn) => fn(this.jqXHR));
    }
  }

  // Called on success (override)
  handleDone = () =>
  {
    // Handle JSON or perhaps other blob data
    if(this.jqXHR.responseJSON)
    {
      this.data = this.jqXHR.responseJSON;
    }
    else
    {
      this.data = this.jqXHR.responseText;
    }
  }

  //called on fail (override)
  handleFail()
  {
  }
}

// Handles lazy fetching of data into a components state
class StateFetcher
{
  data = null;

  constructor(url, params, dataKey, defaultVal, fnTransform, fnGet, fnSet, fnURL)
  {
    this.url = url;               // URL to fetch
    this.params = params;         // POST params
    this.dataKey = dataKey;       // Which key in the AJAX response is the data
    this.defaultVal = defaultVal; // What do we assign if data is yet to fetch
    this.fnTransform = fnTransform || ((x) => x); // Optional transform to apply to data

    // What getter and setter function to use - by default just save in this.data
    this.fnGet = fnGet;
    this.fnSet = fnSet;

    // URL transformation function
    this.fnURL = fnURL || ((x, y) => x);
  }

  reset()
  {
    this.data = null;
  }

  // returns the data available, else fetches it
  fetchUpdate = (component, sPropName, extra, fnAfterFetch, fnAfterFail) =>
  {
    // Default setter and getter just save to the this.data member
    // Otherwise we invoke the specified ones passing the 'extra' and data params
    const fnGet = this.fnGet || (() => this.data);
    const fnSet = this.fnSet || ((data) => {this.data = data});

    // if we have data with us apply it to the component state
    if(fnGet(extra))
    {
      component.setState({[sPropName]: this.fnTransform(fnGet(extra))});

      // if specified, call a function after fetch
      if(fnAfterFetch)
      {
        fnAfterFetch(this.data);
      }
    }
    else
    {
      // We need to fetch the data from the server
      const dataFetcher = new DataFetcher(this.fnURL(this.url, extra), this.params);
      dataFetcher.whenDone
      (
        () =>
        {
          // get the data out - which may be in a sub key of name dataKey
          const data = this.dataKey ? dataFetcher.data[this.dataKey] : dataFetcher.data;

          // Update the cache
          fnSet(data, extra);

          // Forward the state to the component which requested this fetch
          component.setState({[sPropName]: this.fnTransform(fnGet(extra))});

          // if specified, call a function after fetch
          if(fnAfterFetch)
          {
            fnAfterFetch(this.data);
          }
        }
      );

      // Set a fail handler useful for retries
      if(fnAfterFail)
      {
        dataFetcher.ifFail(fnAfterFail);
      }

      // Fire off the request
      dataFetcher.fetch('POST', this.params)

      // Whilst it fetches return a default value if any specified
      if(this.defaultVal != null)
      {
        component.setState({[sPropName]: this.defaultVal});
      }
    }
  }
}

// Fetches active jobs
const JobsActiveFetcher = new StateFetcher('/portal/job-active-list');

// Fetches recent apps, AJAX has 'value' as the main key and [] is the default value
const AppsRecentFetcher = new StateFetcher('/portal/job-get-recent-apps', {}, 'value');

// Convert the JSON response to a image data url to use in <img src=...>
const GetImgSrc = (type, data) =>
{
  return `data:${type};base64,${data}`;
}

function ImageToBase64(img, w, h)
{
  // Create an empty canvas element
  const canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;

  // Copy the image contents to the canvas
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, w, h);

  // Get the data-URL formatted image (always assuming PNG)
  const dataURL = canvas.toDataURL("image/png");

  // Chop the "data:image/png;base64," prefix
  return dataURL.slice(22);
}

// Fetches the titles for the app screenshot
const AppsScreenshotTitlesData = {};
const AppScreenshotTitleFetcher =
  new StateFetcher
  (
    '/portal/app-screenshot-title',
    {},
    null,                               // No sub key
    {title: '', subtitle: ''},          // Default sub/title is blank
    null,
    (sAppName) => AppsScreenshotTitlesData[sAppName],
    (fetchedData, sAppName) =>
      {
        AppsScreenshotTitlesData[sAppName] =
        {
          title: FixRegSymbols(fetchedData.title),
          subtitle: FixRegSymbols(fetchedData.subtitle),
        }
      },
    (baseURL, sAppName) => `${baseURL}?noimg=true&appid=${sAppName}`
  );


const BlankImg = GetImgSrc('image/png', 'iVBORw0KGgoAAAANSUhEUgAAA8AAAAIcAQMAAAADk1U4AAAABlBMVEX///8AAABVwtN+AAAAAnRSTlP/AOW3MEoAAAAJcEhZcwAAFE0AABRNAZTKjS8AAABWSURBVHic7cExAQAAAMKg9U9tCF+gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAeA3/PAABbUCdOQAAAABJRU5ErkJggg==');

// gets the URL for a app image (use directly as src of <img> tag )
const GetAppImgSrc = (sAppID) =>
{
  return `/portal/app-icon?app=${sAppID}&cacheid=${Data.Config.ImgCacheID}`
}


let DefaultAppScreenshot = ScreenShot;

// IIFE to fetch custom app screenshot
(
  function()
  {
    // Try getting the custom screenshot
    const img = new Image();

    // If it succeeds set that URL as the one to be used
    img.onload = () => DefaultAppScreenshot = img.src;
    img.src = '/static/screenshot.jpg';
  }
)
();

// Create a list of classifications (lazy)
let AppClasses = [];
const GetAppClasses = () =>
{
  if(AppClasses.length === 0)
  {
    const s = new Set();
    const fn = app=>app.data.classifications && app.data.classifications.map(c=>s.add(c));
    Data.Apps.map(fn);
    Data.PTCApps.map(fn);
    AppClasses = [...s].sort();
  }
  return AppClasses;
}


// gets the URL for a app screenshot (use directly as src of <img> tag )
const GetAppScreenshotSrc = (sAppID, forURL=true) =>
{
  if(sAppID === 'NAE')
  {
    return DefaultAppScreenshot;
  }
  else
  {
    return `/portal/app-screenshot?appid=${sAppID}`;
  }
}

const GetNewJob = (dctSubmission, dctSubmissionResponse) =>
{
  return (
    {
      job_status: 'SUBMITTED',
      job_walltime: "00:00:00",
      job_starttime: 0,
      job_application: dctSubmission.app,
      job_mc_name: dctSubmission.machine.type,
      job_command: dctSubmission.application.command,
      job_label: dctSubmission.job_label,
      job_name: dctSubmissionResponse && dctSubmissionResponse.name,
      job_number: dctSubmissionResponse && dctSubmissionResponse.number,
    }
  );
}

const DockerInfoFetcher = new DataFetcher('/portal/docker-info');
DockerInfoFetcher.whenDone((jqXHR) => {Data.Docker.LoginInfo = jqXHR.responseJSON;})
DockerInfoFetcher.ifFail(()=>{Data.Docker.LoginInfo = null});

const StatsFetcher = new DataFetcher('/portal/fetch-user-stats');
StatsFetcher.whenDone((jqXHR) => {Data.User.Stats = jqXHR.responseJSON;})
StatsFetcher.ifFail(()=>{Data.User.Stats = null});

const SSHKeyFetcher = new DataFetcher('/portal/config-get-ssh');
SSHKeyFetcher.whenDone((jqXHR) => {Data.User.SSHKeys = jqXHR.responseJSON.result;})
SSHKeyFetcher.ifFail(()=>{Data.User.SSHKeys = null});

const ArchFetcher = new DataFetcher('/portal/machine-archs');
ArchFetcher.whenDone((jqXHR) => {Data.MachineArchs = jqXHR.responseJSON;})
ArchFetcher.ifFail(()=>{Data.MachineArchs = null});

const VaultsDataFetcher = new DataFetcher('/portal/vault-list', {info: true});

// This one only fetches machines in the current zone
// the actual zone parameter is added to this fetcher in App.js after login
const MachineDataFetcher = new DataFetcher('/portal/machine-list');

// This one fetches all machines as machineList(zone=None)
const AdminMachineDataFetcher = new DataFetcher('/portal/machine-list');

const TeamJobsFetcher = new DataFetcher('/portal/job-team-active-list');

const IsAdmin = () => Data.User.Profile.user_roles === ROLE_ADMIN;
const IsTeamAdmin = () => Data.User.Profile.user_roles & ROLE_TENANT_ADMIN || !Data.User.Profile.payer;
const HasRole = (role) => (Data.User.Profile.user_roles & role) === role;

// Fixes a URL to include dev auth param
function GetPortalAuthURL(url)
{
  if(process.env.REACT_APP_MC_DEV === 'devtest')
  {
    url += '&devuser=' + Data.User.Profile.user_login;
  }
  return url;
}

const PriceStr = (x) =>
{
  return Data.User.Profile.currency_symbol + Number(x).toFixed(2);
}

// Audit log API - we dont bother about the return value
// user omitted means self
function AuditLog(category, resource, message)
{
  const fetcher = new DataFetcher('/portal/audit-log');
  fetcher.params = {category, resource, message};
  fetcher.fetch();
}


function GetTeamList(bOmitTAdmins)
{
  let ret = Object.keys(Data.User.Stats).filter
  (
    user => user !== '@' && !Data.User.Stats[user].former
  )

  if(bOmitTAdmins)
  {
    ret = ret.filter
    (
      user => !(
        Data.User.Stats[user].user_roles & ROLE_TENANT_ADMIN ||
        (!Data.User.Stats[user].payer || Data.User.Stats[user].payer === user)
      )
    )
  }

  return ret.sort();
}


function KillJob(bTerminate, job, onFail, onSuccess)
{
  const killStateNames = {'PROCESSING STARTING' : 'running job', 'SUBMITTED' : 'queued job'}

  // Select the backend method to call
  const url = bTerminate ? '/portal/job-terminate' : '/portal/runtime-shutdown';
  const jobKiller = new DataFetcher(url, {jobname: job.job_name});

  jobKiller.ifFail(onFail);
  jobKiller.whenDone
  (
  ()=>
    {
      let sAuditMsg = (bTerminate ? 'Terminated ' : 'Shutdown ') + (killStateNames[job.job_status] || '');
      AuditLog('job', job.job_number, sAuditMsg);
      onSuccess && onSuccess();
    }
  );
  jobKiller.fetch();
}


export
{
  DataFetcher,
  StateFetcher,
  JobsActiveFetcher,
  AppsRecentFetcher,
  AppScreenshotTitleFetcher,
  DockerInfoFetcher,
  StatsFetcher,
  SSHKeyFetcher,
  ArchFetcher,
  VaultsDataFetcher,
  MachineDataFetcher,
  TeamJobsFetcher,
  AdminMachineDataFetcher,

  GetAppImgSrc,
  GetAppScreenshotSrc,
  GetNewJob,
  GetImgSrc,
  GetCookie,
  GetPortalAuthURL,
  GetAppClasses,
  GetTeamList,
  BlankImg,
  DefaultAppScreenshot,
  ImageToBase64,
  FixRegSymbols,
  Data,
  IsAdmin,
  IsTeamAdmin,
  HasRole,
  PriceStr,
  AuditLog,
  KillJob
};
