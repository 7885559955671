/* eslint-disable default-case */
import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {Separator, SearchBox, ReportDisplay, InputRow, ColGroup} from './Components';
import {Styles, LayoutDims, Btns, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher, Data} from '../Models/Model';

const FieldNames = ['name', 'app', 'machine', 'size'];
const FieldTexts = ['Queue Name', 'Application', 'Machine', 'Size'];

// TODO: Make this common code, which is also used in AdminBilling
function GetMCNames()
{
  const arrMC = Object.keys(Data.Machines);
  arrMC.sort
  (
    (a, b) =>
    {
      const nameA = Data.MachinesAll[a].mc_description.toUpperCase();
      const nameB = Data.MachinesAll[b].mc_description.toUpperCase();
      return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
    }
  );

  return arrMC;
}

//Dialog for editing or adding a HPCQ
class HPCQDlg extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props, error: null};

    // Component props for <input> fields
    this.arrPropFields = FieldNames.map
    (
      (e) => ({'data-cy': `inputHPCQ${e}`, ref: `hpcq_${e}`, style: Styles.ParamInputWide, onChange: this.onFieldChange})
    );
  }

  // On rerender, set the values
  componentDidMount()  {this.setInputValues(this.state);}


  initFieldOpts = () =>
  {
    if(!this.elemMCOpts)
    {
      // init the option lists for the app and machine field
      this.elemMCOpts = GetMCNames().map
      (
        (m, i) => <option value={m} key={i}>{`${Data.MachinesAll[m].mc_description} [${m}]`}</option>
      )

      // Get all apps, ignore non public
      const arrAllApps = [...Data.Apps, ...Data.PTCApps].filter((app) => app.public)

      this.elemAppOpts = arrAllApps.map
      (
        (app, i) => <option value={app.id} key={i}>{`${app.data.name} [${app.id}]`}</option>
      )
    }
  }

  // If any field changes, verify that all are non empty
  onFieldChange = () =>
  {
    // If any field is empty show error text
    let i = 0;
    for(const f of FieldNames)
    {
      if(!this.refs['hpcq_' + f].value)
      {
        this.setState({err: `Enter a value for '${FieldTexts[i]}'`})
        return
      }
      ++i;
    }

    // All fields are populated, no error
    this.setState({err:null});
  }

  // Sets the fields data based on edit/add
  setInputValues(state)
  {
    if(state.action !== 'Delete')
    {
      for(const f of FieldNames)
      {
        const ref = this.refs['hpcq_' + f];
        if(ref) ref.value = state.action === 'Edit' ? state[f] : '';
      }

      if(state.action === 'Edit')
      {
        this.onFieldChange();
      }
    }
  }

  // On save fire the request
  onSave = () =>
  {
    const isDelete = this.state.action === 'Delete'

    // Get the values
    let data = {};
    if(!isDelete)
    {
      // Check if adding existing name
      const name = this.refs['hpcq_name'].value.trim();
      if(this.state.action === 'Add' && Data.HPCQueues.find(e => e.name === name))
      {
        this.setState({err: 'Queue already exists!'});
        return;
      }

      for(const key of FieldNames)
      {
        let val = this.refs[`hpcq_${key}`].value.trim();

        // Omit unchanged values except the name
        if(key === 'name' || val !== this.state[key])
        {
          data[key] = val;
        }
      }
    }
    else
    {
      data = {name: this.state.name};
    }

    // Fire the request
    const sURL = '/portal/admin-hpc-queue-' + (isDelete ? 'delete' : 'set');
    const fetcher = new DataFetcher(sURL, data)
    .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
    .whenDone
    (
      // On success, make the parent refresh
      ()=>
      {
        GlobalUI.Dialog.onClose();
        this.state.parent.doUpdate();
      }
    );

    FetchBusy(fetcher, isDelete ? 'Deleting queue...' : 'Saving...');
  }

  render()
  {
    this.initFieldOpts();
    const isDelete = this.state.action === 'Delete';
    return (
      <div>
        {
          !isDelete
          ?
            <div>
              <InputRow title={FieldTexts[0]} width='100%'>
                <input {...this.arrPropFields[0]} readOnly={this.state.action !== 'Add'}/>
              </InputRow>

              <InputRow title={FieldTexts[1]} width='100%'>
                <select {...this.arrPropFields[1]} >
                  {this.elemAppOpts}
                </select>
              </InputRow>

              <InputRow title={FieldTexts[2]} width='100%'>
                <select {...this.arrPropFields[2]} >
                  {this.elemMCOpts}
                </select>
              </InputRow>

              <InputRow title={FieldTexts[3]} width='100%'>
                <input {...this.arrPropFields[3]} />
              </InputRow>

              <span data-cy='divHPCQErr' className='errortext' style={{fontSize: LayoutDims.FontSize}}>
                {this.state.err}&nbsp;
              </span>
              <Separator units={1}/>

            </div>
          :
            <div>
              <Separator/>
              Are you sure you wish to delete this queue definition?
              <Separator units={3}/>
            </div>
        }

        <div style={Styles.FlexSpread}>
          <RaisedButton {...Btns.Gray} label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton
            data-cy='confirmOK'
            {...Btns.Blue}
            label='OK'
            onClick={this.onSave}
            disabled={!isDelete && this.state.err != null} />
        </div>
      </div>
    );
  }
}

export default class AdminHPCQueues extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {data: null, search: '', selectedIndex: -1};
  }

  // On mount update once
  componentDidMount()
  {
    this.updateState();
  }

  // Fixes up the data by adding an "id" key needed for ReportDisplay
  updateState = () =>
  {
    this.setState({search:'', data: Data.HPCQueues.map((e, i) => ({...e, id: i})), selectedIndex: -1});
    this.onSearchClear();
  }

  // Called by the Add/Edit dialog to refetch data
  doUpdate = () =>
  {
    // Fire the request
    const fetcher = new DataFetcher('/portal/admin-hpc-queue-list')
    .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Refresh Failed'))
    .whenDone
    (
      () =>
      {
        // Save results to Data and update state
        Data.HPCQueues = fetcher.data.result;
        this.updateState();
      }
    );

    FetchBusy(fetcher, 'Loading...');
  }

  onSearchClear = () =>
  {
    if(this.refs.searchBox) this.refs.searchBox.inputField.value = '';
    this.setState({search: '', selectedIndex: -1});
  }

  onSearchIncr = (s) =>
  {
    // Case insensitive search
    this.setState({search: s.toUpperCase().trim(), selectedIndex: -1});
  }

  onAdd = () => this.onHpcqAction('Add')
  onEdit = () => this.onHpcqAction('Edit')
  onDel = () => this.onHpcqAction('Delete')

  // Show dialog box (delete shows confirm dialog)
  onHpcqAction = (action) =>
  {
    // Get the selected index and corresponding queue name
    const idx = this.state.selectedIndex;
    const hpcq = idx > -1 && this.state.data[idx];
    const props = {action, parent: this, ...hpcq};

    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      action === 'Delete' ? 'Confirm' : `${action} HPC queue definition`,
      <HPCQDlg {...props}/>,
      false,
      LayoutDims.wContent * 0.85,
      GlobalUI.Dialog.clear
    );
  }

  // Row select handler
  onClickRow = (i) =>
  {
    this.setState({selectedIndex: i});
  }

  render()
  {
    // Use as much height as possible
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 250);
    const isSelected = this.state.selectedIndex > -1;

    // Filter on search string (already upper cased)
    const search = this.state.search;
    const data = this.state.data && this.state.data.length > 0
    ?
      this.state.data.filter(row => !search || `${row.name}|${row.app}|${row.machine}|${row.size}`.toUpperCase().indexOf(search) >= 0)
    :
      [];

    return (
      <div>
        <div style={Styles.FlexSpread}>
          <SearchBox entryType='HPCQ' ref='searchBox' onSearch={this.onSearchIncr} onClear={this.onSearchClear} width='50%'/>
        </div>

        <Separator />

        <div style={Styles.FlexSpread}>
          <div>
            <RaisedButton data-cy='btnHPCQNew' {...Btns.Green} label='New' onClick={this.onAdd}/>
            &nbsp;&nbsp;
            <RaisedButton data-cy='btnHPCQEdit' disabled={!isSelected} {...Btns.Blue} label='Edit' onClick={this.onEdit}/>
            &nbsp;&nbsp;
            <RaisedButton data-cy='btnHPCQDelete' disabled={!isSelected} {...Btns.DarkRed} label='Delete' onClick={this.onDel}/>
          </div>
        </div>

        <Separator />

        <div style={{maxHeight: hAvail, minHeight: hAvail, overflowY: 'auto'}}>

          {
            this.state.data
            ?
              <ReportDisplay ref='hpcqList'
                             data={data}
                             colFields={FieldNames}
                             colText={FieldTexts}
                             colGroup={<ColGroup cols={[25]} />}
                             onClickRow={this.onClickRow}
                             selectedIndex={this.state.selectedIndex}
              />

            :
              <div>
                <Separator units={12}/>
                <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}  />
              </div>
          }

        </div>
      </div>

    );
  }
}
