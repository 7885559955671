import React from 'react';

import {GlobalUI, Validators} from '../Global';
import {LayoutDims} from './UIConst';

export const checkTag = (sTag) =>
{
  const bValid = sTag && Validators.auth_tag(sTag);

  if(!bValid)
  {
    GlobalUI.Dialog.show
    (
      'Validation Error',
      <div>
        Tag must begin with a lowercase alphabet and contain only lowercase alphabets
        digits or hypens, upto a maximum of 32 characters
        <br/>
      </div>,
      false,
      LayoutDims.wContent * 0.8
    );

  }

  return bValid;
}
