// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Our UI data, styles and colors
import {SectionViewHeader, Separator} from './Components';
import {JobEntry} from './JobEntry';
import {JobHistoryView} from './JobHistoryView';
import {IntCmp} from './Utils';

import {GlobalUI} from '../Global'

// Shows all the active jobs
export class JobActiveView extends Component
{
  static propTypes =
  {
    headerText: PropTypes.string.isRequired,
  };

  constructor(props)
  {
    super(props);
    this.state =
    {
      headerText: props.headerText,
      jobs: {},    // Active jobs
      recent: {}   // jobs which ended recently
    };

    // Last known job id array, to detect if any job ended
    this.dctLastJobs = null;
  }

  // Adds a job to the recent pane
  addRecent = (sJobID, dctJob) =>
  {
    // done is set after the entry has been updated from history
    // We should not change it again
    const dctExisting = this.state.recent[sJobID];
    if(!dctExisting || !dctExisting.done)
    {
      const recent = {...this.state.recent};
      recent[sJobID] = dctJob;
      this.setState({recent});
    }

    return dctExisting;
  }

  render()
  {
    const arrJobNums = Object.keys(this.state.jobs).sort(IntCmp).reverse();

    // Check if any job disappeared from the lastJobs and schedule update the job stats
    if(this.dctLastJobs)
    {
      // Get the last known jobs and the current jobs IDs
      const curr = arrJobNums, prev = Object.keys(this.dctLastJobs).sort(IntCmp).reverse();

      // If anything changed...
      if(!(curr.length === prev.length && curr.every((v,i) => v === prev[i])))
      {
        // Force stats pane to update
        GlobalUI.UserStatsPane.fetchStats();

        // Check which jobs ended -> select * from prev not in current jobs
        const arrEnded = prev.filter((j)=>!(j in this.state.jobs));
        if(arrEnded.length)
        {
          const dctEnded = {};
          for(const job of arrEnded)
          {
            // If the job ended and we have a reference to it,
            // Tell it to stop calling for runtimeInfo from beyond the grave
            if(('JobEntry' + job) in this.refs)
            {
              this.refs['JobEntry' + job].silence = true;
            }
            dctEnded[job] = {...this.dctLastJobs[job]};
          }

          // Add all ended jobs to recent pane
          // But after a timeout because we are inside render()
          window.setTimeout
          (
            ()=>
            {
              for(const j of Object.keys(dctEnded))
              {
                GlobalUI.Dashboard.jobEnded(j, dctEnded[j]);
              }
            },
            100
          );
        }
      }
    }
    this.dctLastJobs = {...this.state.jobs};

    const arrJobEntries = arrJobNums.map
    (
      (sJobID) => <JobEntry ref={'JobEntry' + sJobID} key={sJobID} job={this.state.jobs[sJobID]}/>
    );

    const hasRecent = Object.keys(this.state.recent).length > 0;
    const hasActive = Object.keys(this.state.jobs).length > 0;

    return (
      <div>
        {
          (hasActive || (!hasRecent && !hasActive)) &&
          <div>
            <SectionViewHeader headerText={this.state.headerText}/>
            {arrJobEntries}
            <Separator units={2}/>
          </div>
        }

        <div>
          <JobHistoryView showKill ref='viewJobsHistory' headerText='Recent' jobs={this.state.recent}/>
        </div>

      </div>
    );
  }
}
