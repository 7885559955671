// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import Paper from 'material-ui/Paper';
import Checkbox from 'material-ui/Checkbox';
import {red900} from 'material-ui/styles/colors';

// Our UI data, styles and colors
import {LayoutDims, Styles, JobStatusStyles, Colors} from './UIConst.js';
import {CloneAppBtn, AppIcon, ModalTitle, ModalView} from './Components';
import {ParamColGroup, ShowOnly, Icon, Separator, TimeView} from './Components';

import {Spinner, GlobalUI, Elem} from '../Global';
import {JobMetrics} from './JobMetrics';
import {getMemSummaryText, isElementVisible, copyToClipboard} from './Utils';

// Data fetchers
import {GetApp} from '../Models/AppsData';
import {GetImgSrc, StateFetcher, DataFetcher, KillJob, GetPortalAuthURL} from '../Models/Model';

import $ from 'jquery';
import * as jquery from 'jquery';


// Tool button used for job entry title
const ToolButton = (sName, sIcon, fnClick, clr = Colors.clrNimbixDark, jobnum=null) =>
{
  return (
    <div data-cy={'jobEntry' + sName} data-jobnum={jobnum} title={sName} key={sIcon} onClick={fnClick}>
      {Icon(sIcon, {fontSize: 36, margin: 4, color: clr})}
    </div>
  );
}

const JobIcons =
{
  TogglePreview: 'aspect_ratio',
  ShowInfo: 'info_outline',
  Shutdown: 'power_settings_new',
  Abort: 'clear',
  Metrics: 'equalizer'
}

function getLinesAdded(arrOld, arrNew, maxLines)
{
  const lenOld = arrOld.length;
  let lenNew = arrNew.length;

  // Handle weird extra newline returned by tail
  if(lenNew - 1 === maxLines && arrNew[lenNew-1] === "")
  {
    arrNew.splice(lenNew - 1, 1);
    lenNew--;
  }

  // Find the best match index between the new and old lines
  let nMaxMatch = 0;

  // For every index in old lines
  for(let i = 0; i < lenOld; ++i)
  {
    // Check how many lines from new lines match at that index
    let nMatch = 0;
    for(let j = i, k = 0; j < lenOld && k < lenNew && arrOld[j] === arrNew[k]; ++j, ++k, ++nMatch)
    {
    }

    if(nMatch > nMaxMatch)
    {
      nMaxMatch = nMatch;
    }
  }

  // Make a combined list of all lines
  let arrAll = arrOld.slice(0);
  for(let i = nMaxMatch; i < lenNew; ++i)
  {
    arrAll.push(arrNew[i]);
  }

  let nAdded = lenNew - nMaxMatch;

  // Return number of extra lines added and complete list of lines
  return [nAdded, arrAll];
}

// Tool bar/ Title bar of job entry
class JobToolbar extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {...this.props};
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState
    (
      {
        showShutdown: nextProps.showShutdown,
        showAbort: nextProps.showAbort,
        showMetrics: nextProps.showMetrics,
        runtimeInfo: nextProps.runtimeInfo,
        app: nextProps.app
      }
    );
  }

  // Ask user before killing the job
  confirmAndKill = () =>
  {
    // Checkbox specifies whether to call runtimeShutDown or /terminate endpoint
    let bTerminate = false;
    const ctrls =
      <div>
        <p>Are you sure you wish to shutdown this job?</p>
        <br/>
        <Checkbox data-cy='checkboxForceStop' onCheck={(e, v)=>{bTerminate = v}} label='Force Stop'/>
      </div>

    // Show the confirm dialog and kill the job if
    GlobalUI.Dialog.confirm
    (
      ctrls,
      'Confirm Shutdown',
      () => {this.props.parent.jobKill(bTerminate)},
      null,
      'Yes',
      'No'
    );
  }

  showJobInfo = () =>
  {
    // Substitute connection URL in help text (simpler to do it on the fly before showing)
    let sHTML = this.state.runtimeInfo.help;
    if(this.state.runtimeInfo.url)
    {
      sHTML = sHTML.replace(/%CONNECTURL%/gi, this.state.runtimeInfo.url);
    }

    GlobalUI.Dialog.show
    (
      'Job Information',
      <div style={{maxHeight: 250, overflow: 'auto'}} dangerouslySetInnerHTML={{__html: sHTML}}></div>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  render()
  {
    return (
      <div data-cy='jobToolbar' data-jobnum={this.props.job.job_number} style={{...Styles.FlexSpread, width: '100%', alignItems: 'flex-start'}}>
        <AppIcon style={{width: LayoutDims.nIconSize}}
                 app={this.state.app.id}/>

        <div style={{flex: 1, marginLeft: LayoutDims.nMargin}}>
          <div style={{display: 'inline-flex', justifyContent: 'flex-end', fontSize: '36px',
            width: '100%', alignItems: 'center', cursor: 'pointer' }}>

            <div style={{fontSize: '20px', flex: 1}}>
              {this.props.app.data.name || this.props.app.id}
              <span data-cy='jobEntryTitle' data-jobnum={this.props.job.job_number}>
              {
                this.props.job.job_number
                ?
                  '(' + this.props.job.job_number + ')'
                :
                  ' '
              }
              </span>
            </div>

            <ShowOnly if={this.state.showMetrics}>
              {ToolButton('Detailed Job Metrics', JobIcons.Metrics, this.props.parent.showMetrics)}
            </ShowOnly>

            <div>
              <CloneAppBtn jobnum={this.props.job.job_number} size={38}/>
            </div>

            <ShowOnly if={this.state.showPreview}>
              {ToolButton('Toggle Preview', JobIcons.TogglePreview, this.props.parent.togglePreview)}
            </ShowOnly>

            <ShowOnly if={this.state.showPreview && this.state.runtimeInfo.help}>
              {ToolButton('Show Info', JobIcons.ShowInfo, this.showJobInfo)}
            </ShowOnly>

            <ShowOnly if={this.state.showShutdown}>
              {ToolButton('Shutdown', JobIcons.Shutdown, this.confirmAndKill, red900)}
            </ShowOnly>

            <ShowOnly if={this.state.showAbort && !this.state.showShutdown}>
              {ToolButton('Abort', JobIcons.Abort, () => this.props.parent.jobKill(true), red900)}
            </ShowOnly>

          </div>
          <Separator units={0.25}/>
        </div>
      </div>
    );
  }
}

class FullJobOutput extends Component
{
  static dlgVisible = false;

  constructor(props)
  {
    super(props);
    this.state = {open:false, tailText: ''}
  }

  show = (runtimeurl) =>
  {
    const tailFetcher = new StateFetcher
    (
      '/portal/runtime-tail',
      {},
      'result',
    );

    tailFetcher.params =
    {
      jobname: this.props.jobname,
      url: runtimeurl
    }

    tailFetcher.fetchUpdate
    (
      this,
      'tailText',
      null,
      () =>
      {
        // Scroll to bottom
        window.setTimeout
        (
          () => {this.refs.outView.scrollTop = this.refs.outView.scrollHeight;},
          500
        );
      }
    );

    FullJobOutput.dlgVisible = true;
    this.setState({open: true});
  }

  onClose = () =>
  {
    FullJobOutput.dlgVisible = false;
    this.setState({open: false});
  }

  render()
  {
    // eslint-disable-next-line
    const nLines = JobOutput.TailLines;

    return (
      <ModalView ref='viewModal' onClose={this.onClose} open={this.state.open}
                 width={LayoutDims.Tail80Column + LayoutDims.nMargin * 4}>
        <Paper zDepth={3} style={Styles.ModalPaper}>
          <ModalTitle scale={0.40} title='Job Output'/>

          <div ref='outView' style={{...Styles.TailFrame, margin: 0}}>
            <div id='tail' style={{...Styles.Tail(nLines), overflow: 'initial'}}>
              <pre title='Only a maximum of 512 lines of output are displayed here' style={{margin:0}}>
                {this.state.tailText}
              </pre>
            </div>
          </div>

        </Paper>
      </ModalView>
    );
  }
}

// Job screenshot view
class JobOutput extends Component
{
  static ScreenshotTimeout = 10000;
  static TailTimeout = 2000;
  static TailLines = 25;

  constructor(props)
  {
    super(props);
    this.state =
    {
      imageSrc: '',
      hasImage: false,
      tailText: '',
      shutdown: false,
      runtimeInfo: null,
      interactive: props.interactive,
      imgFilter: 'none',
    }

    this.fetchScreenshot();
    this.fetchTailText();

    // setup the tail text
    this.oldlines = new Array(JobOutput.TailLines).fill('');
    this.lastTailLine = 0;
  }

  componentDidMount()
  {
    this.mounted = true;
  }

  componentWillUnmount()
  {
    this.mounted = false;
    clearInterval(this.timerIDTail);
    clearInterval(this.timerIDScreenshot);
  }

  // retrieve last 25 lines of output text
  fetchTailText = () =>
  {
    const refetch = () =>
    {
      this.timerIDTail = setTimeout(this.fetchTailText, JobOutput.TailTimeout);
    }

    // Only fire request if
    // 1) we have the rt info URL
    // 2) Its not a GUI app
    // 3) Tail text view is fully visible
    // 4) Metrics dialog for any job is not open
    // 5) Popup job output is not open
    const canFetch = this.state && this.state.runtimeInfo && !this.state.runtimeInfo.url;
    const isTailTextVisible = !JobEntry.metricsDlgVisible && this.refs.paneJobPreview && isElementVisible(this.refs.paneJobPreview, LayoutDims.hAppBar);

    if(canFetch && isTailTextVisible && !FullJobOutput.dlgVisible)
    {
      const tailFetcher = new StateFetcher
      (
        '/portal/runtime-tail',
        {},
        'result',
      );

      tailFetcher.params =
      {
        lines: 25,
        jobname: this.props.job.job_name,
        url: this.state.runtimeInfo.runtimeurl
      }

      //console.log(Date.now() + ' Fetching tail for ' + this.props.job.job_number)
      tailFetcher.fetchUpdate
      (
        this,
        'tailText',
        null,
        refetch, // refetch on success or failure
        refetch
      );
    }
    else // refetch if we are waiting for runtimeinfo
    {
      refetch();
    }
  }

  // Fetch the screenshot
  fetchScreenshot = () =>
  {
    const refetch = () => this.timerIDScreenshot = setTimeout(this.fetchScreenshot, JobOutput.ScreenshotTimeout);

    // Only fire runtime-call screenshot request if
    // 1) we have the rt info URL
    // 2) Screenshot view is fully visible
    // 3) Metrics dialog is not open
    // 4) Job output text popup is not open
    const canFetch = this.state && this.state.runtimeInfo && this.state.runtimeInfo.url;
    if(canFetch)
    {
      const isScreenshotVisible = !JobEntry.metricsDlgVisible && this.refs.paneJobPreview && isElementVisible(this.refs.paneJobPreview, LayoutDims.hAppBar);
      if(isScreenshotVisible && !FullJobOutput.dlgVisible)
      {
        const screenFetcher = new DataFetcher
        (
          '/portal/runtime-call',
          {
            action: 'screenshot',
            jobname: this.props.job.job_name,
            url: this.state.runtimeInfo.runtimeurl,
            params: `width=${LayoutDims.Tail80Column}`
          }
        );

        // On success set the image
        screenFetcher.whenDone
        (
          () =>
          {
            this.setState({hasImage: true, imgFilter: 'none', imageSrc: GetImgSrc('image/png', screenFetcher.data.image)});
            refetch();
          }
        )
        .ifFail(refetch);

        //console.log(Date.now() + ' Fetching screenshot for ' + this.props.job.job_number)
        screenFetcher.fetch();
      }
      else
      {
        this.setState({imgFilter: 'grayscale(1) blur(2px)'});
        refetch();
      }
    }
    else
    {
      refetch();
    }
  }

  onConnect = () =>
  {
    // Handle case that user clicks the window before RT info arrived
    if(this.state.runtimeInfo)
    {
      if(this.state.runtimeInfo.url)
      {
        window.open(this.state.runtimeInfo.url, '_blank');
        /*let formConnect = Elem('form-connect');
        let sTarget = `jarvice_job_${this.props.job.job_number}`;
        formConnect.setAttribute('target', sTarget);
        formConnect.setAttribute('action', `/portal/job-connect/${this.props.job.job_number}`);
        formConnect.url.value = this.state.runtimeInfo.url;
        formConnect.job_number.value = this.props.job.job_number;
        window.open('', sTarget);
        formConnect.submit();*/
      }
      else
      {
        this.refs.fullJobOutput.show(this.state.runtimeInfo.runtimeurl);
      }
    }
  }

  onCopyConnectURL = () =>
  {
    if(this.state.runtimeInfo && this.state.runtimeInfo.url)
    {
      copyToClipboard(this.state.runtimeInfo.url);

      this.refs.labelURLCopied.style.transition = null;
      this.refs.labelURLCopied.style.opacity = 1;
      window.setTimeout
      (
        ()=>
        {
          this.refs.labelURLCopied.style.transition = 'opacity 1s ease-in-out';
          this.refs.labelURLCopied.style.opacity = 0;
        },
        500
      );

    }
  }

  doScroll = (name) =>
  {
    const elem = $(`[name='${name}']`);
    if(elem.length)
    {
      const scrollTop = (elem[0].scrollHeight - elem[0].offsetHeight);
      if (scrollTop >= 0)
      {
        elem.animate({scrollTop}, 'slow');
      }
    }
  }

  render()
  {
    let content = null;
    const hasConnectURL = this.state.runtimeInfo && this.state.runtimeInfo.url;

    const elemCopyURL =
      <div style={{position: 'absolute', right: LayoutDims.nMargin, bottom: LayoutDims.nMargin, display: 'inline-flex'}}>
        <div ref='labelURLCopied' style={{color: this.state.hasImage ? Colors.clrLight : Colors.clrNimbixDark, opacity: 0}}>
          <b>COPIED</b>
        </div>

        <a title="Copy public remote access link" onClick={this.onCopyConnectURL}>
          {Icon('content_copy', {color: this.state.hasImage ? '#FFFF00' : Colors.clrNimbixDark, fontSize: 24, cursor: 'pointer'})}
        </a>
      </div>

    if(this.state.hasImage)
    {
      content =
        <div style={{position: 'relative'}}>
          {elemCopyURL}
          <img data-cy='jobPreview'
               data-link={this.state.runtimeInfo.url}
               style={{width:'100%', cursor: 'pointer', filter: this.state.imgFilter}}
               src={this.state.imageSrc}
               onClick={this.onConnect}
               title='Click here to connect'/>
        </div>
    }
    else if(hasConnectURL)
    {
      content =
      <div style={{position: 'relative'}}>
        {elemCopyURL}
        <div style={{backgroundColor: Colors.clrLight, height: 360, width: '100%', display: 'flex', alignItems:'center', justifyContent: 'center'}}>
          <a style={{cursor: 'pointer', color: Colors.clrNimbixDark, fontSize: 'xx-large', textAlign: 'center'}}
             data-cy='jobPreview'
             data-link={this.state.runtimeInfo.url}
             onClick={this.onConnect}>
            Click here to connect
          </a>
        </div>
      </div>
    }
    else if(this.state.tailText)
    {
      // Split the output text, delete blank lines because the tail command doesnt count them
      const newlines = this.state.tailText.split('\n');

      // Get the combined tail and the count of how many lines are getting added
      const [nLinesAdded, arrAll] = getLinesAdded(this.oldlines, newlines, JobOutput.TailLines);

      const arrElems = arrAll.map
      (
        (line, idx) =>
          <pre style={Styles.TailLine} key={this.lastTailLine + idx}>
          {line || ' '}
          </pre>
      );

      // retain knowledge of the last line visible so that the key attribute above is ideal
      this.lastTailLine += nLinesAdded;

      content =
        <div style={Styles.TailFrame} onClick={this.onConnect}>
          <div name={this.props.job.job_name} style={Styles.Tail(JobOutput.TailLines)} >
            {arrElems}
          </div>
        </div>

        // new lines are invisible right now, defer scroll it into place with animation
        window.setTimeout(() => this.doScroll(this.props.job.job_name), 100);

        // Trim the oldlines array
        const iOffset = arrAll.length - JobOutput.TailLines;
        this.oldlines = arrAll.slice(iOffset);
    }
    else
    {
      content = <div style={{height: 1, width: '100%'}}> </div>
    }

    return (
      <div ref='paneJobPreview' className={content ? 'show' : 'hide'}>
        <FullJobOutput ref='fullJobOutput' jobName={this.props.job.job_name}/>

        <div style={{position: 'relative'}}>
          {content}
        </div>
      </div>
    );
  }
}

// Connection info table rows
class RowPasswordInfo extends Component
{
  constructor(props)
  {
    super(props);
    this.state={showPassword: false}
  }

  onCopyPasswd = () =>
  {
    // TODO: use Utils.copyToClipboard
    this.refs.passwdShadow.value = this.props.info.password;
    jquery.default(this.refs.passwdShadow).select();
    document.execCommand('copy');
    this.refs.labelCopied.style.transition = null;
    this.refs.labelCopied.style.opacity = 1;
    window.setTimeout
    (
      ()=>
      {
        this.refs.labelCopied.style.transition = 'opacity 1s ease-in-out';
        this.refs.labelCopied.style.opacity = 0;
      },
      500
    );
  }

  render()
  {
    return (
      <tr key={this.props.info.address + '-pass'}>
        <td style={{...Styles.TableCell, color: Colors.clrNimbixMed}} colSpan='2'>

          <div style={{...Styles.InlineFlexRow, cursor: 'pointer'}} onClick={this.onCopyPasswd}>
            <b>Click to copy password to clipboard</b>
            <div ref='labelCopied' style={{color:Colors.clrNimbixDarkGreen, opacity: 0}}>
              <b>&nbsp;&nbsp;&nbsp;COPIED</b>
            </div>
          </div>
          <textarea ref='passwdShadow' style={{opacity:0, width:1, height:1}} />
        </td>
      </tr>
    );
  }
}


const MetricsTitle = (props) =>
{
  return (
    <div style={Styles.InlineFlexRow}>
      <div>
        Utilization ({props.jobNumber})&nbsp;&nbsp;-&nbsp;&nbsp;
      </div>
      <div style={Styles.InlineFlexRow}>
        refreshing&nbsp;
        {
          props.nextRef > 0
          ?
            `in ${props.nextRef}s ...`
          :
            <Spinner style={{width:12, marginLeft:12}} size={12} status=''/>
        }
      </div>
    </div>
  );

}


const jobDetailDisplayRow = (data, label) =>
{
  return (
    data
    ?
      <tr ref={'job' + label}>
        <td style={Styles.TableCellKey}>{label}</td>
        <td style={{...Styles.TableCell, wordBreak: 'break-all'}}>{data}</td>
      </tr>
    :
      null
  );
}


export class JobEntry extends Component
{
  static propTypes =
  {
    job: PropTypes.object,
  };

  static metricRefreshInterval = 30;
  static metricsDlgVisible = false;

  constructor(props)
  {
    super(props);
    this.state =
    {
      previewPaneVisible: true,
      detailsVisible: false,
      shutdown: false,
      job: props.job,
      runtimeInfo: null,
      screenShotImg: null,
      dead: false,
      sMetricsSummary: null,

      // Blank metrics data
      metrics:
      {
        itemized: {memory_used: [],cpu_used: [], memory_total: []},
        summary: {memory_used: 0, cpu_used: 0, memory_total: 0}
      }
    };

    this.retryCount = 0;
    this.silence = false;
    this.metricsDlg = null;
    this.metricNextRefresh = 1;

    this.metricTimerID = window.setInterval(this.onMetricsTimer, 1000);

    // When this Job card is created, fetch info
    this.fetchJobInfo(props.job.job_status);
  }

  componentWillReceiveProps(nextProps)
  {
    // If react recycles entries...
    if(nextProps.job.job_number !== this.state.job.job_number)
    {
      this.retryCount = 0;
      this.setState({runtimeInfo: null});
    }

    // Trigger fetchJobInfo only if the job status changed
    if(this.state.job.job_status !== nextProps.job.job_status)
    {
      // Pass the new state directly to fetchJobInfo
      this.fetchJobInfo(nextProps.job.job_status);
    }

    this.setState({job: nextProps.job});
  }

  componentWillUnmount()
  {
    window.clearInterval(this.metricTimerID);
  }

  // Only update if something really changes
  shouldComponentUpdate(nextProps, nextState)
  {
    // Update if jobs changed
    for(const sKey of ['job_status', 'job_substatus_text', 'job_walltime', 'job_number', 'job_starttime'])
    {
      if(nextProps.job[sKey] !== this.state.job[sKey])
      {
        return true;
      }
    }

    // Update if local states changed
    for(const sKey of ['previewPaneVisible', 'detailsVisible', 'shutdown', 'screenShotImg', 'dead', 'sMetricsSummary'])
    {
      if(nextState[sKey] !== this.state[sKey])
      {
        return true;
      }
    }

    // Update if metrics changed
    for(const sKey of ['memory_used', 'cpu_used', 'memory_total'])
    {
      if(nextState.metrics.itemized[sKey] !== this.state.metrics.itemized[sKey] || nextState.metrics.summary[sKey] !== this.state.metrics.summary[sKey])
      {
        return true;
      }
    }

    // Update if RT info is provided
    if(!this.state.runtimeInfo && nextState.runtimeInfo)
    {
      return true;
    }

    //console.log('Skipping update for ' + this.state.job.job_number);
    return false;
  }

  // Called every second, update the metrics dialog title if visible
  onMetricsTimer = () =>
  {
    // Skip if job not running
    // or status row or this jobs metric dialog is not visible
    const isMetricsVisible = this.metricsDlg || (this.refs.jobUtilization && isElementVisible(this.refs.jobUtilization, LayoutDims.hAppBar));
    if(!isMetricsVisible || this.state.dead || this.state.job.job_status !== 'PROCESSING STARTING' || !this.state.runtimeInfo)
    {
      return;
    }

    // Have we hit the refresh interval? if so fetch metrics
    if(this.metricNextRefresh === 1)
    {
      this.fetchMetrics();
    }

    // Count off seconds unless fetching
    if(this.metricNextRefresh >= 0)
    {
      this.metricNextRefresh--;
    }

    // Update the dialog title (shows remaining time to next refresh)
    this.setMetricsTitle();
  }

  fetchMetrics = () =>
  {
    const fetcher = new DataFetcher
    (
      '/portal/runtime-call',
      {
        action: 'metrics',
        jobname: this.state.job.job_name,
        url: this.state.runtimeInfo.runtimeurl
      }
    );

    fetcher.whenDone
    (
      () =>
      {
        const metrics = fetcher.data;
        const sMetricsSummary = `CPU: ${metrics.summary.cpu_used}%       MEM: ${getMemSummaryText(metrics)}`;
        this.setState({sMetricsSummary, metrics});

        // this.metricsDlg has the JobMetrics instance if visible
        if(this.metricsDlg)
        {
          this.metricsDlg.setState({metrics: fetcher.data});
        }

        // Reset the count from -1
        this.metricNextRefresh = JobEntry.metricRefreshInterval;
      }
    );

    // On fail, just schedule refetch
    fetcher.ifFail(() => this.metricNextRefresh = JobEntry.metricRefreshInterval);

    //console.log(Date.now() + ' Fetching metrics for ' + this.state.job.job_number );
    fetcher.fetch();
  }

  setMetricsTitle = () =>
  {
    // Set the title for the dialog if its visible
    if(this.metricsDlg)
    {
      GlobalUI.Dialog.setState
      (
        {
          title:
            <MetricsTitle
              jobNumber={this.props.job.job_number}
              nextRef={this.metricNextRefresh}
            />
        }
      );
    }
  }

  showMetrics = () =>
  {
    // Calculate sizing dynamically
    let iHeightAvail = window.innerHeight - (LayoutDims.hAppBar + 128);

    // Clear any residual old component in the dialog
    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      `Utilization (${this.state.job.job_number})`,
      <JobMetrics width={LayoutDims.wContent}
                  height={iHeightAvail}
                  giveThis={(that) => {this.metricsDlg = that; JobEntry.metricsDlgVisible = true;}}
                  metrics={this.state.metrics}/>,
      false,
      LayoutDims.wContent,
      () => {this.metricsDlg = null; JobEntry.metricsDlgVisible = false;},
      LayoutDims.hAppBar
    );

    // Force update when first shown
    window.setTimeout
    (
      () => { if(this.metricsDlg) this.metricsDlg.setState({metrics: this.state.metrics}); },
      500
    )
  }

  // If job is processing then fetch runtime connection info if we don't have it
  // We cant use state here because state is async'ly updated from
  fetchJobInfo = (job_status) =>
  {
    // Get runtime info
    if(job_status === 'PROCESSING STARTING')
    {
      if(!this.state.runtimeInfo && this.retryCount === 0)
      {
        const RTInfoFetcher = new StateFetcher('/portal/runtime-info', {jobname: this.state.job.job_name});
        this.refetchRTInfo(RTInfoFetcher);
      }
    }
  }

  // tries to fetch the runtime connection info, retries on fail
  refetchRTInfo = (fetcher) =>
  {
    fetcher.fetchUpdate
    (
      this,
      'runtimeInfo',
      {},

      () => // On success, set the Rt info to the preview pane to show screenshot if any
      {
        if(this.refs.viewJobOutput)
        {
          if(this.refs.viewJobOutput.mounted)
          {
            this.refs.viewJobOutput.setState({runtimeInfo: this.state.runtimeInfo});
          }

          this.refs.jobToolbar.setState({showPreview: true});
        }
      },

      (jqXHR) => // On 404 fail, schedule a retry in 3 seconds
      {
        this.retryCount++;
        if(jqXHR.status === 404)
        {
          // If we have been silenced, stop calling out forever
          if(!this.silence && this.state.job.job_status === 'PROCESSING STARTING')
          {
            // job is still starting: retry fetch if need be but use a
            // longer timeout if we've already tried 20 times
            let rtTimeout = 10000;
            if(this.retryCount < 20)
            {
              rtTimeout = 3000;
            }

            //console.log(`Retry(${this.retryCount}) fetch runtime info for ` + this.state.job.job_number);
            window.setTimeout(() => this.refetchRTInfo(fetcher), rtTimeout);
          }
        }
      }
    );
  }

  togglePreview = () => this.setState({previewPaneVisible: !this.state.previewPaneVisible});


  jobKill = (bTerminate) =>
  {
    // Set is job entry state to shutting down, hide the shutdown button
    this.setState({shutdown: true});
    this.refs.jobToolbar.setState({showShutdown: false});

    KillJob
    (
      bTerminate,
      this.state.job,
      (jqXHR)=>
      {
        this.setState({shutdown: false});
        GlobalUI.Dialog.showErr(jqXHR, 'Job shutdown was unsuccessful');
      },

      // Once it succeeds, call GlobalUI.Dashboard.jobEnded so the "recent" page can update
      () =>
      {
        GlobalUI.Dashboard.jobEnded(this.state.job.job_number, this.state.job);
        this.setState({dead: true});
      }
    );
  }

  getStatusText = (status, missing) =>
  {
    if(!missing && status === 'PROCESSING STARTING' && !this.state.runtimeInfo)
    {
      // 'starting' is a pseudo state that happens while a job is starting
      // but before it gives us any runtime info
      return 'starting...';
    }

    // Convert "SUBMITTED" to "queued" and chop all but the first word off
    return status === 'SUBMITTED' ? 'queued...' : status.split(' ')[0].toLowerCase();
  }

  render()
  {
    // If its dead and not yet gone from the list, render nothing
    if(this.state.dead)
    {
      return <div></div>
    }

    const sAppName = this.state.job.job_application;
    let dctApp = GetApp(sAppName);
    const bAppMissing = !dctApp;

    if(bAppMissing)
    {
      dctApp =
      {
        id: this.state.job.job_application,
        data:{ commands: { [this.state.job.job_command]: {} } }
      }
    }

    const dctCmd = dctApp.data.commands[this.state.job.job_command];
    this.interactive = dctCmd && (dctCmd.interactive || dctCmd.webshell || dctCmd.desktop);
    const sJobStatus = this.state.shutdown ? 'Shutting down...' : this.getStatusText(this.state.job.job_status, bAppMissing);
    const sJobSubStatus = this.state.job.job_substatus_text;

    const tinySpinner = sJobStatus === 'queued...' || sJobStatus === 'starting...' || this.state.shutdown?
       <Spinner style={{width:16, marginLeft:16}} size={16} status=''/> : null

    const rowStatus =
      <tr>
        <td style={Styles.TableCellKey}>Status</td>
        <td style={{...Styles.TableCell, ...JobStatusStyles[sJobStatus], textTransform: 'capitalize'}}>
          <div data-cy='jobStatus' style={Styles.Inline}> {sJobStatus} {tinySpinner} </div>
        </td>
      </tr>

    const rowSubStatus = sJobSubStatus ?
      <tr>
        <td> </td>
        <td data-cy='jobSubstatus' style={{...Styles.TableCell, color: Colors.clrNimbixErr, fontSize: LayoutDims.FontSize - 2}}>
          {sJobSubStatus}
        </td>
      </tr>
    :
      null;

    const hasAddress = this.state.runtimeInfo && this.state.runtimeInfo.address;
    const rowAddress = hasAddress && jobDetailDisplayRow(this.state.runtimeInfo.address, 'Address');
    const rowMetrics = jobDetailDisplayRow(this.state.sMetricsSummary || '-', 'Utilization');
    const rowPass = hasAddress && <RowPasswordInfo info={this.state.runtimeInfo} />;

    const nodes = this.state.job.job_mc_scale;

    return (
      <div data-cy='jobEntry' ref='jobEntry' style={{...Styles.InlineFlexRow, alignItems:'flex-start'}}>
        <Paper zDepth={1} style={Styles.JobEntry}>
          <JobToolbar ref='jobToolbar'
                      parent={this}
                      job={this.state.job}
                      app={dctApp}
                      showPreview={false}
                      showShutdown={sJobStatus === 'processing'}
                      showAbort={sJobStatus === 'queued...' || sJobStatus === 'starting...'}
                      showMetrics={rowMetrics != null}
                      runtimeInfo={this.state.runtimeInfo}/>

          <div style={{padding: LayoutDims.nMargin}}>
            <table style={Styles.Table} ref='tableJobDetails'>
              <ParamColGroup/>

              <tbody>
                <tr>
                  <td style={Styles.TableCell}>Command</td>
                  <td style={Styles.TableCell}>{this.state.job.job_command}</td>
                </tr>

                {rowStatus}
                {rowSubStatus}
                {rowMetrics}
                {rowAddress}
                {jobDetailDisplayRow(this.state.job.job_label,'Job Label')}
                {jobDetailDisplayRow(this.state.job.job_project,'Project')}
                {rowPass}

              </tbody>
            </table>
          </div>

          <div className={this.state.previewPaneVisible ? 'show' : 'hide'} >
            <JobOutput ref='viewJobOutput' interactive={this.interactive} job={this.state.job} parent={this}/>
          </div>


        </Paper>

        <div style={{marginTop: LayoutDims.nMargin * 4}}>
          <div title='Approximate HH:MM since job started; multiply by number of nodes to get billable compute time'>
            <TimeView style={{fontSize:32 , color: Colors.clrNimbixDark}} ref='viewTimeRow'
                      secStart={this.state.job.job_start_time} />
          </div>

          <div style={{float: 'right', cursor: 'default', fontSize: 'x-large', marginTop: LayoutDims.nMargin}}>
            {nodes && ('x ' + nodes + ' node' + (nodes > 1 ? 's' : ''))}
          </div>

        </div>
      </div>
    );
  }
}


