// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import AppBar from 'material-ui/AppBar';

// Our custom components
import {MUI, DrawerX, NavPane, NavToolbar, SwitchedPane, SectionViewHeader} from './Components';

import AppView from './AppView';

// Our layout, styles and colors
import {Styles, LayoutDims} from './UIConst';
import {GlobalUI, LogOut, ZoneSwitch} from '../Global';
import {Data} from '../Models/Model';

// Main outer page container
export default class Main extends Component
{
  static propTypes =
  {
    // This function is called after this component renders
    postRender: PropTypes.func.isRequired,
  }

  constructor(props)
  {
    super(props);

    // pane names ( appear in the navigation menu )
    this.dctPaneNames =
    {
      'Compute': 'Compute',
      'Dashboard': 'Dashboard',
      'PushToCompute™': 'PushToCompute',
      'Account': 'Settings',
      'Administration': 'Admin',
      'About': 'About'
    };

    // Mark About page as having no context
    this.dctNoContext = {'About': true};

    // Reverse map of above
    this.dctPaneTitles = {};
    for(const key of Object.keys(this.dctPaneNames))
    {
      this.dctPaneTitles[this.dctPaneNames[key]] = key;
    }

    // Set the default for the drawer and the content pane
    SwitchedPane.setDefault(['DrawerCtx', 'Content'], 'Compute');

    this.state = {curPaneTitle : '', curPaneName: ''};
  }

  // When this component renders
  componentDidMount()
  {
    // Open the drawers if we are on widescreen
    GlobalUI.getMetrics();

    this.refs.drawerCtx.setState({open: LayoutDims.bLeftOpen});
    this.refs.drawerNav.setState({open: LayoutDims.bRightOpen});

    // Remove PTC pane if not available
    if(!GlobalUI.IsVisible('DrawerCtx/PushToCompute'))
    {
      delete this.dctPaneNames['PushToCompute™'];
      delete this.dctPaneTitles['PushToCompute'];
    }

    // Remove the admin pane if not available
    if(!GlobalUI.IsVisible('DrawerCtx/Admin'))
    {
      delete this.dctPaneNames['Administration'];
    }

    // Set the title based on what Switchedpane switched to
    const curPaneTitle = this.dctPaneTitles[SwitchedPane.getActivePane('DrawerCtx')]
    this.setState({curPaneTitle, curPaneName: this.dctPaneNames[curPaneTitle]});

    // Invoke post render handler
    this.props.postRender(this);
  }

  onNavigate = (sItemText, fromUI=true) =>
  {
    const sItemName = this.dctPaneNames[sItemText];
    SwitchedPane.switch('DrawerCtx', sItemName);
    SwitchedPane.switch('Content', sItemName);
    this.setState({curPaneTitle: sItemText, curPaneName: sItemName});

    if(fromUI && !LayoutDims.bRightOpen)
    {
      this.toggleDrawerNav();
    }

  }

  toggleDrawerNav = () => this.refs.drawerNav.toggle();
  toggleDrawerCtx = () => this.refs.drawerCtx.toggle();

  onZoneSelect = (zone) =>
  {
    ZoneSwitch(zone)
  }

  // TODO make recent apps view entries have unique id
  render()
  {
    const noContextDrawer = this.dctNoContext[this.state.curPaneName];
    return MUI(
      <div>

        {/*Top App bar with navigation toolbar at the right*/}
        <AppBar onLeftIconButtonClick={noContextDrawer ? null : this.toggleDrawerCtx}
                title={this.state.curPaneTitle}
                style={{paddingRight: LayoutDims.nMargin}}
                titleStyle={{maxWidth: LayoutDims.wAppBarTitle, fontSize: LayoutDims.TitleFontSize}}
        >
          <NavToolbar ref='appBar'
                      motd={Data.MOTD}
                      currentZone={Data.CurrentZone}
                      zoneNames={Data.ValidZones}
                      zones={Data.Zones}
                      username={`${Data.User.Profile.user_nicename} <${Data.User.Profile.user_email}>`}
                      onZoneSelect={this.onZoneSelect}
                      onClick={this.toggleDrawerNav} />
        </AppBar>

        {/* Context drawer*/}
        <DrawerX ref='drawerCtx' dir='left' scrollable hidden={noContextDrawer} >
          <SwitchedPane fullHeight paneName='Compute' paneGroup='DrawerCtx' />
          <SwitchedPane fullHeight paneName='Dashboard' paneGroup='DrawerCtx' />

          {
            Data.User.Profile.developer &&
            <SwitchedPane fullHeight paneName='PushToCompute' paneGroup='DrawerCtx' />
          }

          <SwitchedPane fullHeight paneName='Settings' paneGroup='DrawerCtx' />
          <SwitchedPane fullHeight paneName='Admin' paneGroup='DrawerCtx' />
        </DrawerX>

        {/* Navigation drawer*/}
        <DrawerX ref='drawerNav' dir='right'>
          <div id='DrawerNav' style={Styles.DrawerNav}>
            <div id='NavPaneOuter' style={{width: '100%'}}>
              <NavPane onLogout={()=>LogOut('Logged out')}
                       menuItems={Object.keys(this.dctPaneNames)}
                       menuActions={Object.keys(this.dctPaneTitles)}
                       onClick={this.onNavigate}
                       selected={this.state.curPaneTitle}/>
            </div>

            <SectionViewHeader headerText='Recent'/>

            <div id='RecentAppsCtr' style={{overflowY: 'auto', width: '100%'}}>
              <AppView ref='paneRecent' entryType='recent' noCollapse
                       stylePane={{display: 'inline-flex', flexFlow: 'column nowrap'}} />
            </div>

          </div>
        </DrawerX>

        {/* Central content pane */}
        <div key='client' style={Styles.ClientArea}>

          <div key='left' style={{flex: 1}}></div>

          <div key='center' style={Styles.Content}>
            {/* Compute  and Dashboard panes are rendered inside these SwitchedPanes */}
            <SwitchedPane paneName='Compute' paneGroup={'Content'}/>
            <SwitchedPane paneName='Dashboard' paneGroup={'Content'}/>

            {
              Data.User.Profile.developer &&
              <SwitchedPane paneName='PushToCompute' paneGroup={'Content'}/>
            }

            <SwitchedPane paneName='Settings' paneGroup={'Content'}/>

            <SwitchedPane paneName='Admin' paneGroup={'Content'}/>

            <SwitchedPane fullHeight paneName='About' paneGroup={'Content'}/>

          </div>

          <div key='right' style={{flex: 1}}></div>

        </div>

        <div key='appcard' id='AppCard'> </div>
        <div key='ptceditdlg' id='PTCEditDlg'> </div>
        <div key='appeditdlg' id='AppEditDlg'> </div>

        {
          // render this only if impersonation dialog wasn't shown
          !Data.Config.ImpersonationDialog && <div className='hide' data-cy='cy-confirm-impersonate' data-dialog='false'/>
        }


      </div>
    );
  }
}
