import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {Styles, Btns, LayoutDims, Colors} from './UIConst';
import {FetchBusy, GlobalUI, Spinner} from '../Global';
import {DataFetcher, Data, AuditLog} from '../Models/Model';
import {HLine, InputRow, Separator} from './Components'
import {cleanUserWhiteList, getHost} from './Utils';
import {checkTag} from './SettingsAuthCommon';


export class SettingsSAML extends Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      uri: '',
      tag: null, // Null indicates data being fetched
      changed: false,
      allowed: ''
    };

    // Setup a fetcher to get the data
    this.fetcher = new DataFetcher('/portal/saml-get', {payer: Data.User.Profile.user_login});
    this.fetcher.whenDone(()=>this.onUpdate(this.fetcher.data));

    // If it fails set the values blank (tag != null means spinner vanishes)
    this.fetcher.ifFail(()=>this.onUpdate({uri: '', tag: ''}));

    this.sHost = getHost();
  }

  // Fetch data
  refresh = () =>
  {
    // set tag to null causes the spinner to show
    this.setState({tag: null});
    this.fetcher.fetch();
  }

  // When data arrives, put it in the input fields
  onUpdate = (data) =>
  {
    for(const key of ['tag', 'uri', 'upn', 'sam', 'groups'])
    {
      this.refs[`saml_${key}`].value = data[key] || ''
    }

    try
    {
      if(data.allowed && data.allowed.result)
      {
        this.refs.saml_allowed.value = data.allowed.result.split(',');
      }
    }
    catch(e)
    {
      console.log('Invalid list of allowed users');
    }
    this.setState(data);
  }

  // Create, edit and delete use the same API samlUpdate
  onSave = () => this.onAction(false);
  onDel = () => this.onAction(true);

  // Update
  onAction = (isDel) =>
  {
    const data = {payer: Data.User.Profile.user_login};

    // if isDel is specified, fields are left blank which is
    // interpreted as delete by the DAL
    if(!isDel)
    {
      // Get the input values
      for(const key of ['tag', 'uri', 'upn', 'sam', 'groups'])
      {
        const sRef = `saml_${key}`;
        let val = this.refs[sRef].value, val2;
        if(val && (val2 = val.trim()))
        {
          data[key] = val2;
        }
      }

      // Get cleaned up csv list of white-listed users, put it back in the UI
      data.allowed = cleanUserWhiteList(this.refs.saml_allowed.value);
      this.refs.saml_allowed.value = data.allowed;
    }

    // Test tag with regex unless its a delete
    // checkTag shows an error dialog and returns false if invalid
    if(isDel || checkTag(data.tag))
    {
      const fetcher = new DataFetcher('/portal/saml-update', data)
        .ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
        .whenDone
        (
          () =>
          {
            AuditLog('settings', 'saml', (isDel ? 'Deleted' : 'Added') + ' tag: ' + (data.tag || this.state.tag));

            // If it was a delete, clear the input fields
            if(isDel)
            {
              for(const key of ['tag', 'uri', 'allowed', 'upn', 'sam', 'groups'])
              {
                this.refs[`saml_${key}`].value = '';
              }
              data.tag = '';
            }

            // State is used merely to show/hide the buttons
            this.setState({...data, changed: false});
          }
        );

      FetchBusy(fetcher, isDel ? 'Deleting...' : 'Saving...');
    }
  }

  // Just open the metadata uri in a new tab
  onDetails = () =>
  {
    window.open(this.refs.saml_uri.value, '_blank');
  }

  onChange = () => this.setState({changed: true});

  onTest = () =>
  {
    GlobalUI.Dialog.confirm
    (
      <div>
        You will now be redirected to the IdP's login page
        <br/>
        <br/>
        If that step succeeds, try to log in to your account, after which
        the SAML response from your IdP will be verified.
      </div>,
      'Test SAML configuration',
      this.onDoTest,
      null,
      'OK',
      'Cancel',
      LayoutDims.wContent * 0.65,
    );
  }

  onDoTest = () =>
  {
    const sTestURL = `${this.sHost}/portal/saml-login/@test?uri=${this.state.uri}`;
    window.open(sTestURL, '_blank');
  }

  componentDidMount()
  {
    this.refresh();
  }

  render()
  {
    const sSSOURL = `${this.sHost}/portal/saml-login/${this.state.tag}`;
    const sMetaURL = `${this.sHost}/portal/saml-metadata`;
    const hasTag = (this.state.tag !== null);
    const blankTag = this.state.tag === "";

    const sTestURL = `${this.sHost}/portal/saml-login/@test?uri=${this.state.uri}`;

    return (
      <div style={{width: '100%'}}>
        {
          <div className={!hasTag ? 'show' : 'hide'}>
            <Separator units={12}/>
            <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}/>
          </div>
        }

        <div className={hasTag ? 'show' : 'hide'}>
          <InputRow width='25%' title='Tag'>
            <input ref='saml_tag'
                   data-cy='inputSamlTag'
                   placeholder='Enter a string that will be used to build the SSO URL'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>

          <InputRow width='100%' title='SAML Metadata URL'>
            <input ref='saml_uri'
                   type='uri'
                   data-cy='inputSamlMetaDataUrl'
                   spellCheck='false'
                   placeholder='Enter the metadata URL for your SAML 2.0 IDP'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>

          <InputRow width='50%' title='Attribute for UPN'>
            <input ref='saml_upn'
                   data-cy='inputSamlUPNAttrName'
                   spellCheck='false'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>

          <InputRow width='50%' title='Attribute for SAM account name'>
            <input ref='saml_sam'
                   data-cy='inputSamlSAMAttrName'
                   spellCheck='false'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>

          <InputRow width='50%' title='Attribute for user groups'>
            <input ref='saml_groups'
                   data-cy='inputSamlGroupsAttrName'
                   spellCheck='false'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>


          <InputRow width='90%' title='List of authorized users (comma or newline separated, must match SAML user ID/email address exactly); leave completely blank to authorize any authenticated user from this IdP'>
            <textarea style={{...Styles.Full, height: 60}} onChange={this.onChange} ref='saml_allowed'/>
          </InputRow>



          <Separator/>


          <div style={Styles.FlexSpread}>

            <div style={Styles.InlineFlexRow}>
              {
                hasTag && !blankTag && 
                <div>

                  <RaisedButton {...Btns.DarkRed} name='delete' label='Delete' onClick={this.onDel}/>
                  &nbsp;&nbsp;

                  {
                    !this.state.changed &&
                    <RaisedButton {...Btns.Green}
                                  data-cy='btnTestSAML'
                                  data-link={sTestURL}
                                  title='Test SAML Login with this IDP'
                                  label='Test'
                                  onClick={this.onTest}/>
                  }
                  &nbsp;&nbsp;

                  <RaisedButton {...Btns.Gray} label='Details' title='Show IDP XML Metadata' onClick={this.onDetails}/>

                </div>
              }
            </div>

            <RaisedButton disabled={!this.state.changed} {...Btns.Blue} label='Save' onClick={this.onSave}/>

          </div>

          <Separator units={3}/>
          <HLine margin={0}/>
          <Separator units={3}/>

          {
            // Show these urls only if data is fetched and also not blank
            hasTag && this.state.tag !== '' &&
            <div>
              SSO URL: &nbsp;
              <a data-link={sSSOURL} data-cy='linkSSO' target='_blank' rel='noreferrer' href={sSSOURL}>{sSSOURL}</a> <br/>
              <br/>
              SP Metadata URL: &nbsp; <a target='_blank' rel='noreferrer' href={sMetaURL}>{sMetaURL}</a> <br/>
            </div>
          }

        </div>
      </div>
    );
  }
}
