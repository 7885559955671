// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Our UI data, styles and colors
import {Styles} from './UIConst.js';
import {TableFixedHeaderTab, ParamColGroup} from './Components';
import {TaskBuilderParameter} from './TaskBuilderParameter';
import {Data} from '../Models/Model';
import {getAppHint} from './Utils'

export class TaskBuilderPaneVault extends Component
{
  static propTypes =
  {
    app: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  };

  constructor(props)
  {
    super(props);

    // vault is the name of the vault
    this.state = {vault: null};
    this.vaults = [];
  }

  // Is the specified vault a member of the theProps.apps vaults list?
  hasVault = (sVault, theProps) => theProps.data[theProps.app].data['vault-types'].indexOf(sVault) >= 0

  updateVaultsList = (theProps) =>
  {
    // Get a copy of the vaults list and filter the vaults that this app can use, then sort it
    this.vaults = Object.keys(Data.Vaults.VaultInfo).filter
    (
      (v) =>
      {
        const dctVault = Data.Vaults.VaultInfo[v];
        // Types may differ here so ignore warning
        // eslint-disable-next-line
        return this.hasVault(dctVault.type, theProps) && (Data.CurrentZone == -1 || dctVault.zone == -1 || dctVault.zone == Data.CurrentZone);
      }
    );
    this.vaults.sort();

    // If "NONE" vault type is available, add a blank entry at the top
    if(this.hasVault('NONE', theProps))
    {
      this.vaults.splice(0, 0, '');
    }

    // Default selected vault can be specified in:
    // 1) appdef command.hints entry "vault=ABC"
    // 2) appdef top level hints entry "vault=ABC"
    // 3) User default Data.Vaults.DefaultVault
    // Choose whichever is specified, in that order
    // If available, move that to the top of the vault list

    const dctApp = this.props.data[theProps.app]
    const sHintsApp = getAppHint(dctApp.data.commands[theProps.cmd].hints, 'VAULT') ||
                      getAppHint(dctApp.data.hints, 'VAULT') ||
                      Data.Vaults.DefaultVault;

    const iDefVault = this.vaults.indexOf(sHintsApp);
    if(iDefVault > 0)
    {
      const item = this.vaults[iDefVault];
      this.vaults.splice(iDefVault, 1);
      this.vaults.splice(0, 0, item);
    }

    // Make sure the first vault has been chosen if any
    if(this.vaults.length && this.vaults[0])
    {
      this.setState({vault: this.vaults[0]});
    }
    else
    {
      this.setState({vault: null});
    }
  }

  // When dialog is reopened with new app, update the state
  componentWillReceiveProps(nextProps)
  {
    // Do not reset the vaults list unless something actually changed
    if(this.props.app !== nextProps.app || this.props.cmd !== nextProps.cmd)
    {
      this.updateVaultsList(nextProps);
    }
  }

  // When dialog is opened first, update the state
  componentWillMount()
  {
    this.updateVaultsList(this.props);
  }

  // Given a vault name, set the UI state and pass a notification to parent
  doVaultChange = (sVaultName) =>
  {
    this.setState({vault: sVaultName});
    this.props.onChange(sVaultName);
  }

  onVaultChange = (evt) => this.doVaultChange(evt.nativeEvent.target.value);

  setParams = (dctParams) =>
  {
    // Strip trailing slash if any
    dctParams.name = dctParams.name.split('/')[0];

    // Set the vault name, refresh the UI
    this.paramVault.setValue(dctParams.name);
    this.doVaultChange(dctParams.name);

    // Force update so that the following controls get rendered if any
    this.forceUpdate();

    // Set the remaining params if specified
    const dct =
    {
      force: 'paramForce',
      readonly: 'paramReadonly',
      password: 'paramPassword',
      bucket: 'paramBucket'
    };

    for(const sKey of Object.keys(dct))
    {
      if(sKey in dctParams)
      {
        const sRef = dct[sKey];
        const ref = this[sRef];
        if(ref)
        {
          ref.setValue(dctParams[sKey]);
        }
      }
    }
  }

  // get the data for the app machine params
  getParams()
  {
    let ret = null;

    // If null vault selected, then don't put the vault sub-key in the job submission
    const sVault = this.paramVault.getValue();
    if(sVault)
    {
      const dctVaultInfo = Data.Vaults.VaultInfo[this.state.vault];
      const sVaultType = dctVaultInfo.type;
      const bIsVaultTypeObject = sVaultType === 'OBJECT';
      const bIsVaultEncrypted = dctVaultInfo && dctVaultInfo.encrypted;

      ret = {};
      ret.name = sVault;
      ret.readonly = this.paramReadonly.getValue();

      if(!bIsVaultTypeObject)
      {
        ret.force = this.paramForce.getValue();
      }

      // If encrypted or object vault, get the password value in if any
      let password = null;
      if(bIsVaultEncrypted || bIsVaultTypeObject)
      {
        password = this.paramPassword.getValue();
      }

      // Encrypted vaults use a password confirm field
      if(bIsVaultEncrypted)
      {
        const confirm = this.paramPasswordConfirm.getValue();
        if(password || confirm)
        {
          ret.password = password;
          ret.confirm = confirm;
        }
      }

      // If its a OBJECT vault, add the bucket value if any, and the password
      // No confirm field for such vaults
      if(bIsVaultTypeObject)
      {
        const bucket = this.paramBucket.getValue();
        if(bucket) ret.bucket = bucket;

        if(password)
        {
          ret.password = password;
          ret.confirm = password;
        }
      }
    }

    return ret;
  }

  render()
  {
    const paramVault =
    {
      name: 'Select Vault',
      type: 'SELECTION',
      onChange: this.onVaultChange,
      that: this,
      value: this.state.vault,
      values: this.vaults
    };

    const dctVaultInfo = Data.Vaults.VaultInfo[this.state.vault];
    const sVaultType = dctVaultInfo && dctVaultInfo.type;
    const bIsVaultTypeObject = sVaultType === 'OBJECT';
    const bIsVaultEncrypted = dctVaultInfo && dctVaultInfo.encrypted;

    // Show password fields for encrypted vault
    let elemPasswordParams = [];
    if(bIsVaultEncrypted)
    {
      elemPasswordParams =
      [
        <TaskBuilderParameter key='0' ref={el=>this.paramPassword = el} name='Password' type='PASSWORD'/>,
        <TaskBuilderParameter key='1' ref={el=>this.paramPasswordConfirm = el} name='Confirm password' type='PASSWORD'/>,
      ];
    }

    // Show password and bucket field for object vaults
    if(bIsVaultTypeObject)
    {
      const desc =
        <div>Format: <i>&lt;accessKeyId&gt;:&lt;secretAccessKey&gt;</i></div>;

      elemPasswordParams =
      [
        <TaskBuilderParameter key='0' description={desc} ref={el=>this.paramPassword = el} name='Key' type='STR'/>,
        <TaskBuilderParameter key='2' ref={el=>this.paramBucket = el} name='Bucket' type='STR'/>,
      ];
    }

    return (
      <div>
        <TableFixedHeaderTab title='Vaults'/>
        <div>
          <table style={Styles.Table} >
            <ParamColGroup/>

            <tbody>

            <TaskBuilderParameter ref={el=>this.paramVault = el} {...paramVault}/>

            {elemPasswordParams}

            { // Show checkboxes fields only if vault is specified
              this.state.vault &&
              [
                <TaskBuilderParameter key='0' ref={el=>this.paramReadonly = el} name='Read only' type='BOOL'/>,

                // No "force" for objects
                !bIsVaultTypeObject &&
                <TaskBuilderParameter key='1' ref={el=>this.paramForce = el} name='Force' type='BOOL'/>,
              ]
            }

            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
