import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {red900} from 'material-ui/styles/colors';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import FloatingActionButton from 'material-ui/FloatingActionButton';

import {Tabs, Tab} from 'material-ui/Tabs';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

import {TableHeaderCells, SearchBox, Separator, ShowOnly, SwitchedPane, OptionDropDown, ColGroup,
  InputRow, CheckListItems, ReportDisplay, fromThis, Pager, Icon} from './Components';

import {Styles, LayoutDims, Btns, clrTrans, Colors} from './UIConst.js';
import {Spinner, GlobalUI, FetchBusy, userImpersonate, Validators, ValidationErrs, getZoneOpts} from '../Global';

import {DataFetcher, AuditLog, Data} from '../Models/Model';
import {RoleNames, RoleNamesShort, makeRoleString, ROLE_ADMIN, ROLE_TENANT_ADMIN, ROLE_SAML_ADMIN} from '../Models/Model';

import {noWrap, dateToLocalTimeStr, copyToClipboard, StrICmp} from './Utils';

// Map of vault types to human name
const VaultNames =
{
  nfs: 'NFS Vault',
  fs: 'File System Vault',
  pvc: 'PVC',
  empty: 'Ephemeral Vault',
};

// Vault types names in right order
const VaultTypeNames =
[
  'nfs',
  'fs',
  'pvc',
  'empty'
];

// Map of vault types to storage plugin
const VaultPlugins =
{
  nfs: 'nfs',
  fs: 'fs',
  pvc: 'pvc',
  empty: 'empty',
}

// Data used to generate the vaults options
const VaultTypes =
{
  // param is the name of the AJAX param,
  // extra sets the input field type or select options
  // value specifies default
  // field sets whether its an input, select or multi
  // multi renders as a UI that can allow multiple options to be selected
  // hide is for fields that are passed but not shown in the UI
  nfs:
  [
    {title: 'NFS mount address', param: 'address', field: 'input', extra: 'text',   required: true},
    {title: 'Shared',            param: 'shared',  field: 'input', extra: 'number', hide: true}
  ],

  fs:
  [
    {title: 'Remote Path', param: 'remotepath', field: 'input',  extra: 'text',   required: true},
    {title: 'File System', param: 'fstype',     field: 'input',  extra: 'text',   required: true},
    {title: 'Options',     param: 'options',    field: 'input',  extra: 'text'},
    {title: 'Shared',      param: 'shared',     field: 'input',  extra: 'number', hide: true}
  ],

  pvc:
  [
    {title: 'Access Modes',         param: 'accessModes',       field: 'multi', extra: ['ReadWriteOnce', 'ReadOnlyMany', 'ReadWriteMany'], required: true, value: []},
    {title: 'Size (GB)',            param: 'size',              field: 'input', extra: 'number', value: '1'},
    {title: 'Storage Class Name',   param: 'storageClassName',  field: 'input', extra: 'text'},
    {title: 'Volume Name',          param: 'volumeName',        field: 'input', extra: 'text'},
    {title: 'Sub Path',             param: 'subpath',           field: 'input', extra: 'text'},
    {title: 'Shared',               param: 'shared',            field: 'input', extra: 'number', hide: true}
  ],

  empty: []
};

const VaultValidators =
{
  pvc: (vault, elem) =>
  {
    if(!vault.volumeName && !vault.storageClassName)
    {
      throw([`Please enter a value for either 'Storage Class Name' or 'Volume Name'`])
    }
  }
}

const styleInput =
{
  border:'none',
  margin:0,
  paddingLeft:4,
  fontSize: 14,
  lineHeight: '30px',
  width: '100%'
};

const styleSelect =
{
  border: 'none',
  margin: 0,
  padding: 6,
  fontSize: 14,
  height: '100%',
  width: '100%',
  backgroundColor: 'white'
};

// Two column table
const OptionTable = (props) =>
{
  return (
    <table style={{...Styles.Table, tableLayout: 'fixed', width: '100%'}}>

      {props.cols || <ColGroup cols={[40]}/>}

      <thead>
      <tr>{TableHeaderCells(props.headers, null, null, null, {fontSize: 14})}</tr>
      </thead>

      <tbody>
        {props.children}
      </tbody>
    </table>
  );
}

OptionTable.propTypes =
{
  headers: PropTypes.array.isRequired,
};

// Table row with input fields used for Vault options
class VaultOptionTableRow extends Component
{
  constructor(props)
  {
    super(props);

    // This bit of state is needed for multi-select options
    this.state = {...props, value: new Set([])};
  }

  // Fills the default value
  setDefault = () =>
  {
    const val = (this.props.field === 'input' && !this.props.noDefault && this.props.value) || '';
    this.setParamValue(val);
  }

  // Sets a value into the field for this option
  setParamValue = (value) =>
  {
    let sFieldRef = this.props.field;
    sFieldRef = 'field' + sFieldRef.charAt(0).toUpperCase() + sFieldRef.slice(1);

    switch(this.props.field)
    {
      // Multi select fields get data from this.state.value
      case 'multi':
      {
        // We have a comma separated list, make it a set - handle empty strings specially
        const setVals = value ? new Set(value.split(',')) : new Set();
        this.setState({value: setVals});
      }
      break;

      // Other options read from the input or select elements value
      default:
      {
        this.refs[sFieldRef].value = value;
      }
    }
  }

  // sets the key value pair into dctParams
  // Returns the control if its a required param but blank
  setParamInto(dctParams, sParamName)
  {
    // Create the name of the ref like 'fieldInput' or 'fieldMulti'
    let sFieldRef = this.props.field;
    sFieldRef = 'field' + sFieldRef.charAt(0).toUpperCase() + sFieldRef.slice(1);

    let val = null;
    switch(this.props.field)
    {
      // Multi select fields get an array value stored in val
      case 'multi':
      {
        val = Array.from(this.state.value);
        if(this.props.required && !val.length)
        {
          return this.refs[sFieldRef];
        }
      }
      break;

      // Other options read from the input or select elements value
      default:
      {
        val = this.refs[sFieldRef].value;
        if(this.props.required && (val === null || val === ''))
        {
          return this.refs[sFieldRef];
        }
      }
    }

    if(val)
    {
      dctParams[sParamName || this.props.param] = val;
    }

    return null;
  }

  // Render the parameter based on one of the entries in VaultTypes
  render()
  {
    const styleTD = {...Styles.TableCellBordered, wordBreak: 'break-all'};

    let elemField;
    const sCYTag = this.props['data-cy'];

    // Create the right field based on the type in VaultTypes
    switch(this.props.field)
    {
      case 'select':
      {
        elemField =
          <select data-cy={sCYTag} ref='fieldSelect' style={{...Styles.SmallInput, width: '100%'}}>
          {
            this.props.extra.map((e) => <option key={e}>{e}</option>)
          }
          </select>
      }
      break;

      case 'input':
      {
        const propRange = this.props.extra === 'number'
        ?
          {step: '1.0', min: '0.00'}
        :
          {}

        elemField =
          <input data-cy={sCYTag}
                 ref='fieldInput'
                 type={this.props.extra}
                 defaultValue={this.props.noDefault ? '' : this.props.value}
                 style={{...Styles.SmallInput, width: '100%'}}
                 {...propRange}
          />
      }
      break;

      case 'multi':
      {
        const arrItems = CheckListItems(this, this.props.extra, 'value', this.props.field);
        const sValue = Array.from(this.state.value).join(',');

        // Override the OptionDropDown's input field with this plain div
        const elemInput =
        <div data-cy={sCYTag}
             ref='fieldMulti'
             style={{paddingTop: 4, height: 24, width: '100%', fontSize: 14, color: '#000000'}}
             onClick={()=>this.refs.popup.openPopup(this.refs.fieldMulti)}>
          {sValue} &nbsp;
        </div>

        elemField =
        <OptionDropDown title='' items={arrItems}
                        width='100%'
                        text={sValue}
                        ref='popup'
                        inputField={elemInput}
        />

      }
      break;

      default: // keep linter happy

    }

    return (
      <tr className={this.props.hide ? 'hide' : 'show'} style={{height:LayoutDims.nMargin * 4}}>
        <td style={{...styleTD, padding: 2}}>{this.props.title}</td>
        <td style={{...styleTD, padding: 4}}>
          {elemField}
        </td>
      </tr>
    );
  }
}

VaultOptionTableRow.propTypes =
{
  title: PropTypes.string.isRequired,
  param: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  extra: PropTypes.any.isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  noDefault: PropTypes.bool,
};


// Popup for setting user privileges
class UserRoleDlg extends Component
{
  // There are two different ways this control is used
  // 1) Define a role
  // 2) Get a list of roles to filter (OR logic)
  // Under (1) Sys Admin makes all other roles checked
  // Under (2) Sys Admin is an individual role
  // (1) uses a bitmask for state
  // (2) uses a Set() of role values

  constructor(props)
  {
    super(props);
    this.state = {role: props.role, payer: props.payer};
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({role: nextProps.role});
  }

  // Set empty set or 0 depending on props.simple
  onClearAll = ()=>
  {
    const role = this.props.simple ? new Set() : 0;
    this.setState({role});
    this.props.onChange(role);
  }

  onChange = (evt) =>
  {
    // evt.target.name has the role flag, make it a number
    let role = evt.target.name|0;

    // If in simple mode, add or remove from the role set
    if(this.props.simple)
    {
      const roleSet = new Set(this.state.role);
      if(roleSet.has(role))
      {
        roleSet.delete(role);
      }
      else
      {
        roleSet.add(role);
      }

      this.setState({role: roleSet});
      this.props.onChange(roleSet);
    }
    else // mask mode, add or remove all the the mask bits
    {
      const bChecked = (this.state.role & role) === role;

      // Set or unset
      if(!bChecked)
      {
        role |= this.state.role;
      }
      else
      {
        role = ~role & this.state.role;
      }

      this.setState({role});
      this.props.onChange(role);
    }
  }

  render()
  {
    return (
      <div>
        <div style={{marginLeft: LayoutDims.nMargin}}>
          <RaisedButton
            {...Btns.DarkRed}
            label='Clear All Roles'
            disabled={(this.props.simple && this.state.role.size === 0) || this.state.role === 0}
            onClick={this.onClearAll}
          />
        </div>
        <br/>

        {
          Object.keys(RoleNames).map
          (
            (e, i) =>
            {
              let bChecked, bDisabled = false;
              const role = e|0;

              // Render differently based on whether simple is specified
              if(this.props.simple)
              {
                bChecked = this.state.role.has(role);
              }
              else
              {
                bChecked = (this.state.role & role) === role;

                // All checkboxes except ROLE_ADMIN are disabled if user is a sysadmin
                bDisabled = role !== ROLE_ADMIN && this.state.role === ROLE_ADMIN;

                // ROLE_TENANT_ADMIN is disabled if the user has no payer
                bDisabled = bDisabled || (role === ROLE_TENANT_ADMIN && !this.props.payer);

                // ROLE_SAML_ADMIN is disabled if the user is not a payer
                bDisabled = bDisabled || (role === ROLE_SAML_ADMIN && !!this.props.payer);
              }

              return(
                role
                  ?
                  <Checkbox
                    style={{margin: LayoutDims.nMargin, cursor: 'default'}}
                    ref={'role_' + e}
                    name={e}
                    key={i}
                    label={RoleNames[e]}
                    data-cy={RoleNames[e]}
                    checked={bChecked}
                    disabled={bDisabled}
                    onCheck={this.onChange}/>
                  :
                  <div key='-1'> </div>
              );

            }
          )
        }
      </div>
    );

  }
}

// Class renders a row of user data
class UserRow extends Component
{
  static propTypes=
  {
    user: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    show: PropTypes.bool,
  }

  // All rows have the same parent, so keep it static
  static parent = null;

  constructor(props)
  {
    super(props);
    this.state =
    {
      selected: false,
      edited: false,
      user: {...props.user},
      diff: {},
      deleted: false
    };

    // this is the original unchanged user data
    this.orig = {...props.user};
  }

  onClickRow = (evt) =>
  {
    if(evt.target.parentElement.className.indexOf('user-tr') === 0)
    {
      this.props.onClick(this.orig.user_login);
    }
  }

  // On clicking the user name, impersonate login as them
  onClickUser = () => userImpersonate(this.props.user.user_login)

  // Forces keyboard focus to the outermost pane
  focusUserList()
  {
    document.getElementById('admin-users-pane').focus();
  }

  // Role change dialog
  onChangeRole = () =>
  {
    let role = this.state.user.user_roles;

    const onOK = () =>
    {
      this.setState({user: {...this.state.user, user_roles: role}});
      this.focusUserList();
    }

    GlobalUI.DialogConfirm.clear();
    GlobalUI.DialogConfirm.confirm
    (
      <UserRoleDlg
        onChange={(theRole) => role = theRole} role={role}
        payer={this.state.user.payer}
      />,
      'Choose Roles',
      onOK,
      this.focusUserList,
      'OK',
      'Cancel',
      LayoutDims.wContent * 0.5
    );
  }

  revert = (newUser) =>
  {
    // If new data is sent, update this.orig
    if(newUser)
    {
      this.orig = newUser;
    }

    // tell parent if state changed
    if(this.state.edited)
    {
      UserRow.parent.rowEdited(false);
    }

    // deselect this row, reset data to original value
    this.setState
    (
      {
        selected: false,
        edited: false,
        user: {...this.orig},
        diff: {}
      }
    );
  }

  // When deselecting, get the values from the fields
  deselect = () =>
  {
    if(!this.state.deleted)
    {
      // Get new values if visible
      let dctUserEdit;
      if(this.props.show)
      {
        dctUserEdit =
        {
          ...this.orig,
          user_privs: this.refs.user_privs.value || null,
          payer: this.refs.payer.value,
          enabled: this.refs.enabled.checked,
          developer: this.refs.developer.checked,
          user_roles: this.state.user.user_roles,
          billing_code: this.refs.billing_code.value
        }

        // We need to update the existing diff
        const dctDiff = this.state.diff;

        // note the keys that are different
        for(const sKey of Object.keys(this.orig))
        {
          // Null and blank means no value, but numeric 0 is a value
          // eslint-disable-next-line
          const hadValue = dctUserEdit[sKey] || this.orig[sKey] ||
                           dctUserEdit[sKey] === 0 || this.orig[sKey] === 0;

          // We use != rather than !== because "N" and N should compare equal for numbers
          // eslint-disable-next-line
          if(hadValue && dctUserEdit[sKey] != this.orig[sKey])
          {
            dctDiff[sKey] = 'changed';
          }
        }

        // If anything changed set the diff so we have red border on changed items
        if(Object.keys(dctDiff).length)
        {
          // Inform parent only if state changes
          if(!this.state.edited)
          {
            UserRow.parent.rowEdited(true);
          }
          this.setState({user: dctUserEdit, diff: dctDiff, edited: true});
        }
      }
      else
      {
        // Cancel the edit
        if(this.state.edited)
        {
          UserRow.parent.rowEdited(false);
        }
        this.setState({user: this.orig, diff: {}, edited: false});
      }
    }

    // deselect this row
    this.setState({selected: false});
  }

  // Lock / unlock
  lock = (bLock) =>
  {
    this.orig.user_locked = bLock;
    const user = {...this.state.user};
    user.user_locked = bLock;
    this.setState({user});
  }

  // Returns "changed" or '' depending on whether this field is changed
  getFieldClass = (sKey) =>
  {
    return (this.state.edited && this.state.diff[sKey]) || '';
  }

  // Returns a dict of changed data
  data = () =>
  {
    const data = {};
    for(const k of Object.keys(this.state.diff))
    {
      data[k] = this.state.user[k];

      // Trim payer name
      if(k === 'payer')
      {
        data[k] = data[k].trim();
      }
    }
    return data;
  }

  // Hides this row for good
  del()
  {
    this.revert();

    // Set the delete flag
    this.setState({deleted: true});
  }

  render()
  {
    let cols;
    const dctUser = this.state.user;

    // Show is used for filtering
    if(this.props.show)
    {
      if(this.state.selected)
      {
        cols = [

          <td key='privs'>
            <input ref='user_privs' style={Styles.SmallInput}
                   defaultValue={dctUser.user_privs}/>
          </td>,

          <td key='payer'>
            <input ref='payer' style={Styles.SmallInput} list='user-data-list'
                   defaultValue={dctUser.payer}/>
          </td>,

          <td key='enabled'>
            <input ref='enabled' className='user-check' type='checkbox'
                   defaultChecked={dctUser.enabled ? 'checked' : null}/>
          </td>,

          <td key='developer'>
            <input ref='developer' className='user-check' type='checkbox'
                   defaultChecked={dctUser.developer ? 'checked' : null}/>
          </td>,

          <td ref='user_roles' key='roles' onClick={this.onChangeRole} style={{cursor: 'pointer'}} >
            {makeRoleString(dctUser.user_roles)}
          </td>,

          <td key='billing_code'>
            <input ref='billing_code' style={Styles.SmallInput} type='text' defaultValue={dctUser.billing_code}/>
          </td>
        ];
      }
      else
      {
        // Read only view
        cols = [
          <td className={this.getFieldClass('user_privs')} key='user_privs'>
            {dctUser.user_privs}
          </td>,

          <td className={this.getFieldClass('payer')} key='payer'>
            {dctUser.payer}
          </td>,

          <td className={this.getFieldClass('enabled') + ' user-check'} key='enabled'>
            {dctUser.enabled && '✔'}
          </td>,

          <td className={this.getFieldClass('developer') + ' user-check'} key='developer'>
            {dctUser.developer && '✔'}
          </td>,

          <td className={this.getFieldClass('user_roles')}  key='roles' style={{cursor: 'pointer'}}>
            {makeRoleString(dctUser.user_roles)}
          </td>,

          <td className={this.getFieldClass('billing_code')} key='billing_code'>
            {dctUser.billing_code}
          </td>,
        ]
      }
    }

    // Text color for locked is red (when selected pinkish), disabled is gray,
    let styleTD = {};
    if(dctUser.user_locked)
    {
      styleTD = {color: this.state.selected ? '#FFAAAA' : red900}
    }
    else if(!dctUser.enabled)
    {
      styleTD = {color: 'gray'}
    }

    const sClassName = (this.state.selected ? 'user-tr-selected': 'user-tr') +
                       (this.state.deleted ? ' hide' : '');

    return (
        <tr className={sClassName} onMouseDown={this.onClickRow}>
          <td style={{...styleTD, cursor: 'pointer'}}>
            <a onClick={this.onClickUser}>
              {dctUser.user_login}
            </a>
          </td>
          <td style={styleTD}>{dctUser.user_nicename}</td>
          <td style={styleTD}>{dctUser.user_email}</td>
          {cols}
        </tr>
    );
  }
}

const UserBtn = (label, color, disabled, onClick) =>
{
  return <RaisedButton disabled={disabled} {...color} label={label} onClick={onClick} style={{marginRight: LayoutDims.nMargin, marginTop: LayoutDims.nMargin}}/>
}

// Panel of Action buttons
class UserBtns extends Component
{
  static propTypes =
  {
    onNotify: PropTypes.func.isRequired,
    onVars: PropTypes.func.isRequired,
    onVaults: PropTypes.func.isRequired,
    onCredits: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onLock: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onLog: PropTypes.func.isRequired,
  }

  constructor(props)
  {
    super(props);
    this.state = {selected: false, editedCount: 0, locked: false}
  }

  // Called by parent to indicate the current state of the row
  rowEdited(edited)
  {
    // Keep a count of edited rows
    this.setState({editedCount : this.state.editedCount + (edited ? 1: -1)});
  }

  render()
  {
    // Other than Invite and Apply all buttons disabled, unless a row is selected
    const disabled = !this.state.selected;
    const cantApply = this.state.editedCount === 0 || this.state.selected;
    return (
      <div>
        {this.props.children}

        <div style={Styles.FlexSpread}>

          <div style={{...Styles.InlineFlexRow, marginTop: LayoutDims.nMargin}}>
            <RaisedButton disabled={cantApply}
                          {...Btns.Blue} label='Save Edits' onClick={this.props.onApply}/>
            &nbsp;&nbsp;

            <RaisedButton disabled={cantApply}
                          {...Btns.DarkRed} label='Cancel' onClick={this.props.onReset}/>
            &nbsp;&nbsp;
          </div>

          <div style={{...Styles.InlineFlexRow, flexWrap: 'wrap', alignContent: 'space-between'}}>
            {UserBtn('Notifications', Btns.DarkRed, false, this.props.onNotify)}
            {UserBtn('Variables', Btns.Blue, disabled, this.props.onVars)}
            {UserBtn('Vaults', Btns.Blue, disabled, this.props.onVaults)}
            {UserBtn('Credits', null, disabled, this.props.onCredits)}
            {UserBtn(this.state.locked? 'Unlock' : 'Lock', Btns.Blue, disabled, this.props.onLock)}
            {UserBtn('Delete', Btns.Gray, disabled, this.props.onDelete)}
            {UserBtn('Invite', Btns.Green, false, this.props.onInvite)}
            {UserBtn('Log', Btns.Blue, disabled, this.props.onLog)}
          </div>
        </div>
        <Separator/>
        <span style={{fontSize: 12, color: Colors.clrNimbixMed}}>
          &nbsp;
          {this.state.selected ? 'Press Esc to revert selected row, Enter to accept edits' : 'Click to edit'}
        </span>
      </div>
    );
  }
}

class UserNotify extends Component
{
  onPageDone = () =>
  {
    // Save the MOTD as root/motd in the metadata
    const fetcher = new DataFetcher('/portal/admin-user-set-meta', {key: 'motd', user: 'root', value: this.refs.textMOTD.value });
    fetcher.ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'));
    fetcher.whenDone
    (
      ()=>
      {
        Data.MOTD = this.refs.textMOTD.value;
        GlobalUI.Dialog.onClose(true);
      }
    );
    FetchBusy(fetcher, 'Saving...');
  }

  onPageCancel = () => GlobalUI.Dialog.onClose()

  render()
  {
    const n = LayoutDims.nMargin;
    return (
      <div style={{margin: LayoutDims.nMargin, marginTop: 0}}>
          <div style={{position: 'sticky', top: n, padding: n, width: n*3, left: `calc(100% - ${n*4}px)`}}
               title='Clear' onClick={()=>this.refs.textMOTD.value=''}>
            {Icon('delete', {color: Colors.clrNimbixDark, fontSize: n*4, cursor: 'pointer'})}
          </div>
          <textarea defaultValue={this.props.motd} ref='textMOTD'
                    style={{...Styles.Bordered, maxWidth: '100%', minWidth: '100%', minHeight: 200, maxHeight: 200,
                      fontSize: 'larger', fontFamily: 'monospace', whiteSpace: 'pre', resize: false,  
                      marginTop: -n*6}}/>
        <OKCancelPane parent={this} label='Save' id='Create' extra={Btns.Blue}/>
      </div>
    );
  }
}

class UserInvite extends Component
{
  // If name ends with a space its a boolean
  static fieldNames =
  [
    'Email', 'Username', 'Full Name', 'Company', 'Phone', 'Enabled ', 'Payer',
    'Billing Code', 'Developer ', 'Privileges', 'Account Vars', 'Credits', 'Coupon'
  ];

  static fieldIDs =
  [
    'user_email', 'user_login', 'user_nicename', 'user_company', 'user_phone',
    'enabled', 'payer', 'billing_code', 'developer', 'user_privs', 'account_vars',
    'account_credits', 'coupon'
  ];

  constructor(props)
  {
    super(props);
    this.state = {sErrField : '', sErr: ''}
  }

  // After everything is rendered, focus the error field if possible and scroll it in
  componentDidUpdate()
  {
    // Do we have any error field
    if(this.state.sErrField)
    {
      // Get the component and try to focus it, then scroll it into view
      const ref = this.refs[this.state.sErrField];
      if(ref.focus)
      {
        ref.focus();
      }
      else // just scroll it into view
      {
        ref.scrollIntoView();
      }
    }
  }

  // Get all fields as a dict
  getData = () =>
  {
    // Clear any error
    this.setState({sErrField: '', sErr: ''});

    // Collect fields and return
    const ret = {};
    for(const sField of UserInvite.fieldIDs)
    {
      if(this.refs[sField].value !== '' && this.refs[sField].value !== null)
      {
        ret[sField] = this.refs[sField].value;
      }
    }

    // Pick up the default vault params
    for(const opt of VaultTypes['pvc'])
    {
      if(opt.param !== 'shared')
      {
        // convert strings like fooBar to vaultFooBar
        const sParamName = `vault${opt.param[0].toUpperCase()}${opt.param.substr(1)}`;
        this.refs[sParamName].setParamInto(ret, sParamName);
      }
    }

    // Fix up the accessModes param from array to CSV
    if(ret.vaultAccessModes)
    {
      ret.vaultAccessModes = ret.vaultAccessModes.join(',');
    }

    // Add meta data if any
    if(this.refs.vaultDalHookMeta.value)
    {
      ret.vaultDalHookMeta = this.refs.vaultDalHookMeta.value;
    }

    if(this.refs.vaultName.value)
    {
      ret.vaultName = this.refs.vaultName.value;
    }

    if(this.refs.vaultZone.value !== "-1")
    {
      ret.vaultZone = this.refs.vaultZone.value;
    }

    if(this.refs.vaultEphemeralZone.value !== "-1")
    {
      ret.vaultEphemeralZone = this.refs.vaultEphemeralZone.value;
    }

    console.log(ret);

    return ret;
  }

  setErr = (sErrField, sErr) =>
  {
    this.setState({sErrField, sErr});
  }

  render()
  {
    const styleErr = {border: '2px solid red'};
    const styleTD = {...Styles.TableCellBordered,  wordBreak: 'break-all'};

    // Generate vault UI fields table
    const arrElemVaultOpts = [];
    for(const opt of VaultTypes['pvc'])
    {
      if(opt.param !== 'shared')
      {
        // convert strings like fooBar to vaultFooBar
        const sParamName = `vault${opt.param[0].toUpperCase()}${opt.param.substr(1)}`;
        arrElemVaultOpts.push
        (
          <VaultOptionTableRow data-cy={'invite-pvc-' + opt.param}
                               ref={sParamName}
                               key={opt.param}
                               noDefault={true}
                               {...opt}/>
        )
      }
    }

    return (
      <div>
        <div className='errortext' style={{fontSize: 14, marginLeft: LayoutDims.nMargin}}>
          {this.state.sErr}
          &nbsp;
        </div>

        <div style={{maxHeight: 512, overflowY: 'scroll', overflowX: 'hidden', padding: 4}}>
          <table style={{...Styles.Table, tableLayout: 'fixed', width: '100%'}}>
            <ColGroup cols={[30, 70]}/>

            <tbody>
            {
              UserInvite.fieldNames.map
              (
                (e, i) =>
                {
                  const id = UserInvite.fieldIDs[i];
                  const isBool = e.endsWith(' ');
                  const isErr = id === this.state.sErrField;
                  return (
                    <tr key={i}>
                      <td style={{...styleTD, paddingLeft:4}}>{e}</td>
                      <td style={{...styleTD, ...(isErr ? styleErr : {})}}>
                      {
                        isBool
                        ?
                          <select ref={UserInvite.fieldIDs[i]} style={styleSelect} >
                            <option value='true'>True</option>
                            <option value='false'>False</option>
                          </select>
                        :
                          <input ref={id} style={styleInput}/>
                      }
                      </td>
                    </tr>
                  );
                }
              )
            }
            </tbody>
          </table>

          <Separator units={6}/>

          <b>Default PVC vault configuration for invited user</b>
          <Separator units={1}/>

          <div style={Styles.FlexSpread}>
            <InputRow title='Vault Name:'>
              <input data-cy='inputVaultNameInvite' ref='vaultName' style={Styles.ParamInputWide}/>
            </InputRow>
            &nbsp;&nbsp;
            <InputRow title='Zone:'>
              <select data-cy='inputVaultZoneInvite' ref='vaultZone' style={Styles.ParamInputWide}>
                {getZoneOpts(true)}
              </select>
            </InputRow>
          </div>

          Options:
          <table style={{...Styles.Table, tableLayout: 'fixed', width: '98%'}}>
            <tbody>{arrElemVaultOpts}
            </tbody>
          </table>

          <Separator units={2}/>
          DAL Metadata:<br/>
          <textarea ref='vaultDalHookMeta' style={{height: 100, width: '98%', resize: 'none'}}/>

          <Separator units={2}/>

          <InputRow title='Default zone for ephemeral vault:'>
            <select data-cy='inputEphemeralZoneInvite' ref='vaultEphemeralZone' style={Styles.ParamInputWide}>
              {getZoneOpts(true)}
            </select>
          </InputRow>

        </div>

        <br/>

        <div style={{...Styles.Inline, justifyContent: 'flex-end'}}>
          <RaisedButton {...Btns.Blue} label='Get Invite Link' onClick={()=>this.props.onInvite(this, true)}/>
          &nbsp;&nbsp;
          <RaisedButton {...Btns.Green} label='Email Invite Link' onClick={()=>this.props.onInvite(this, false)}/>
          &nbsp;&nbsp;
        </div>

      </div>
    );
  }
}

class UserVars extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {vars: {}, edit: false, selected : null, err: '', user: props.user};
  }

  componentWillMount()
  {
    this.refresh(this.props.user);
  }

  // On change of user, refresh
  componentWillReceiveProps(nextProps)
  {
    if(nextProps.user !== this.state.user)
    {
      this.setState({vars: {}, edit: false, selected: null, err: '', user: nextProps.user});
      this.refresh(nextProps.user);
    }
  }

  // Fetch data from server
  refresh(user)
  {
    const fetcher = new DataFetcher('/portal/admin-user-var-list', {user})
      .whenDone(()=>this.setState({vars: fetcher.data}));
    FetchBusy(fetcher, 'Loading...');
  }

  // Shows the add page or list
  doSwitchPage = (edit, list) =>
  {
    this.setState({edit: edit, err: ''});
    SwitchedPane.switch('@UserVars', list ? 'List' : 'AddEdit');

    if(edit)
    {
      this.refs.inputVal.value = this.state.vars[this.state.selected];
    }
    else
    {
      this.refs.inputName.value = '';
      this.refs.inputVal.value = '';
    }
  }

  // Add or edit
  onAddEdit = () =>
  {
    const sKey = this.state.edit ? this.state.selected : this.refs.inputName.value;
    const sVal = this.refs.inputVal.value;

    // If either field is empty, show error and focus empty field
    if(!this.state.edit && (!sKey || !sVal))
    {
      this.setState({err: 'Please enter a value'});
      // eslint-disable-next-line
      sKey ? this.refs.inputVal.focus() : this.refs.inputName.focus();
    }
    // If adding existing key, show error
    else if(!this.state.edit && (sKey in this.state.vars))
    {
      this.setState({err: 'Variable is already defined'});
      this.refs.inputName.focus();
    }
    else // set the key and switch back to the list
    {
      this.applyAction
      (
        sKey,
        sVal,
        this.state.edit ? 'Updating' : 'Adding',
        ()=>
        {
          const vars = {...this.state.vars, ...{[sKey]: this.refs.inputVal.value}};
          this.setState({vars});
          this.doSwitchPage(false, true);
        }
      );
    }
  }

  // Delete == set empty
  onDelete = () =>
  {
    this.applyAction
    (
      this.state.selected,
      '',
      'Deleting',
      () =>
      {
        const vars = {...this.state.vars};
        delete vars[this.state.selected];
        this.setState({vars, selected: ''});
      }
    );
  }

  // Applies add/edit/delete on server
  applyAction(name, value, action, then)
  {
    const fetcher = new DataFetcher
    (
      '/portal/admin-user-var-action/',
      {name, value, user: this.props.user}
    );

    if(then)
    {
      fetcher.whenDone(then);
    }

    FetchBusy(fetcher, action + '...');
  }

  render()
  {
    const styleTD = {...Styles.TableCellBordered,  wordBreak: 'break-all'};

    return (
      <div>
        <SwitchedPane active paneGroup='@UserVars' paneName='List'>
          <div>
            <div style={Styles.InlineFlexRow}>
              <RaisedButton {...Btns.Green} label='Add' onClick={()=>this.doSwitchPage(false, false)}/>
              &nbsp;&nbsp;

              <RaisedButton disabled={!this.state.selected} {...Btns.Blue} label='Edit' onClick={()=>this.doSwitchPage(true, false)}/>
              &nbsp;&nbsp;

              <RaisedButton disabled={!this.state.selected} label='Delete' onClick={this.onDelete}/>
              &nbsp;&nbsp;

            </div>

            <br/>
            <br/>

            <div style={{maxHeight: 250, overflowY: 'auto', overflowX: 'hidden', padding: 4}} >
              <OptionTable headers={['Account Variable', 'Value']} cols={<ColGroup cols={[60, 40]}/>}>
              {
                Object.keys(this.state.vars).map
                (
                  (e) =>
                  {
                    const styleCell =
                    {
                      ...styleTD,
                      ...(e === this.state.selected ? Styles.Selected : Styles.DeSelected)
                    };

                    return (
                      <tr key={e} onMouseDown={()=>this.setState({selected: e})}>
                        <td style={{...styleCell, paddingLeft:4}}>{e}</td>
                        <td style={styleCell}>{this.state.vars[e]}</td>
                      </tr>
                    );
                  }
                )
              }

              {
                Object.keys(this.state.vars).length === 0 &&
                <tr style={{height: 100}}>
                  <td style={styleTD} colSpan='2'> </td>
                </tr>
              }
              </OptionTable>
            </div>

          </div>
        </SwitchedPane>

        <SwitchedPane paneGroup='@UserVars' paneName='AddEdit'>
          <div>
            <div style={Styles.Blue}>
              {this.state.edit ? 'Edit account variable' : 'Add a new account variable'}
            </div>
            <br/>

            <InputRow width='100%' title='Variable name:'>
              <ShowOnly if={this.state.edit}
                        children={<div disabled style={Styles.ParamInputWide}>{this.state.selected}</div>}
                        otherwise={<input ref='inputName' style={Styles.ParamInputWide}/>}/>
            </InputRow>

            <InputRow width='100%' title='Value:'>
              <input ref='inputVal' style={Styles.ParamInputWide}/>
            </InputRow>

            <div className='errortext'>{this.state.err}&nbsp;</div>

            <div style={{...Styles.FlexSpread, marginTop: LayoutDims.nMargin * 1.5}}>
              <RaisedButton label='Cancel' onClick={()=>this.doSwitchPage(false, true)} />
              <RaisedButton label={this.state.edit ? 'Update' : 'Add'} {...Btns.Blue}
                            onClick={this.onAddEdit} />
            </div>
          </div>

        </SwitchedPane>

      </div>
    );
  }
}

// OK and cancel button pair
const OKCancelPane = (props) =>
{
  return (
    <div>
      <Separator/>
      <div style={Styles.FlexSpread}>
        <RaisedButton data-cy='buttonVaultCancel' key={0} label='Cancel' onClick={()=>props.parent.onPageCancel()}/>
        <RaisedButton data-cy='buttonVaultOK' key={1} label={props.label} {...props.extra} onClick={()=>props.parent.onPageDone(props.id || props.label)}/>
      </div>
    </div>
  );
}

const OKPane = (props) =>
{
  return (
    <div>
      <Separator/>
      <div style={Styles.FlexSpread}>
        &nbsp;
        <RaisedButton key={0} label='Dismiss' onClick={()=>props.this.onPageCancel()}/>
      </div>
    </div>
  );
}

class UserCredits extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {user: props.user};
    this.additive = false;
  }

  // On initial load, refresh the credits
  componentWillMount()
  {
    this.refresh(this.props.user);
  }

  // On change of user, refresh
  componentWillReceiveProps(nextProps)
  {
    if(nextProps.user !== this.state.user)
    {
      this.refs.inputCredits.value = 0;
      //this.setState({credits: 0, user: nextProps.user});
      this.setState({user: nextProps.user});
      this.refresh(nextProps.user);
    }
  }

  // Fetch credit data from server and apply it when done
  refresh(user)
  {
    const fetcher = new DataFetcher('/portal/admin-user-get-credits', {user})
    .whenDone
    (
      ()=>this.refs.inputCredits.value = fetcher.data.user_credits.toFixed(2)
    );
    FetchBusy(fetcher, 'Loading...');
  }

  addCredits = () =>
  {
    //const additive =
    const params = {user: this.props.user, credits: this.refs.inputCredits.value, additive: this.additive}
    const fetcher = new DataFetcher('/portal/admin-user-set-credits', params)
    .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Error'))
    .whenDone
    (
      ()=>
      {
        this.refs.inputCredits.value = fetcher.data.user_credits.toFixed(2);
        GlobalUI.Dialog.show('Credits', 'Credits value was successfully set')
      }
    );


    FetchBusy(fetcher, 'Saving...');
  }

  onChangeAdditive = (e, additive) =>
  {
    this.additive = additive === 'add';
  }

  render()
  {
    return(
      <div>
        Credits:
        <InputRow>
          <input style={Styles.ParamInputWide} type='number' step='1.0' min='0.00' max='1000.00'
                 ref='inputCredits'/>
        </InputRow>

        <RadioButtonGroup onChange={this.onChangeAdditive} ref="additiveMode"
                          name="additiveMode" defaultSelected="set">
          <RadioButton value="add" label="Add Credits" style={Styles.Radio} />
          <RadioButton value="set" label="Set Credits" style={Styles.Radio} />
        </RadioButtonGroup>

        <Separator/>
        <div style={Styles.FlexSpread}>
          <RaisedButton key={0} label='Cancel' onClick={GlobalUI.Dialog.onClose}/>
          <RaisedButton key={1} label='Apply' {...Btns.Blue} onClick={this.addCredits}/>
        </div>

      </div>
    );
  }
}

class VaultTabs extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {...props};
  }

  // Gets the type of the vault
  getVaultType = (action) =>
  {
    // The vault type is got from the selected tab, ow whatever single tab is shown
    if(this.props.vaultGlobal)
    {
      return this.props.vaultGlobal.plugin;
    }
    else
    {
      return VaultTypeNames[this.refs['tabs' + action].state.selectedIndex];
    }
  }


  // Gets the parameters from the fields into a dict
  getParams = (action) =>
  {
    const sVaultType = this.getVaultType(action);

    const params =
    {
      globaldata: {},
      plugin: VaultPlugins[sVaultType],
    }

    // Find the option controls
    for(const sRef of Object.keys(this.refs))
    {
      // The controls' ref names for each vault type have the vault type prefixed after the action
      // e.g. "create-pvc-xxxxx"
      if(sRef.indexOf(sVaultType) === (action.length + 1))
      {
        const ctrl = this.refs[sRef];

        // Ask each control to put the value in and report error if any
        const elemBad = ctrl.setParamInto(params.globaldata);
        if(elemBad)
        {
          throw([`Please enter a value for '${ctrl.props.title}'`, elemBad])
        }
      }
    }

    // Validate vault params (validators throw error on failure)
    const fnValidator = VaultValidators[params.plugin];
    if(fnValidator)
    {
      fnValidator(params.globaldata);
    }

    return params;
  }

  componentWillReceiveProps(nextProps)
  {
    if(nextProps)
    {
      this.setState({...nextProps});
    }
  }

  // For Create, clear the values of controls except name, for Edit, populate the globaldata
  initValues = (action) =>
  {
    if(action === 'Create')
    {
      const sVaultType = this.getVaultType(action);

      // Find the option controls
      for(const sRef of Object.keys(this.refs))
      {
        // The controls' ref names for each vault type have the vault type prefixed after the action
        // e.g. "create-pvc-xxxxx"
        if(sRef.indexOf(sVaultType) === (action.length + 1))
        {
          // Set the default value for inputs
          this.refs[sRef].setDefault();
        }
      }
    }
    else
    {
      // Iterate over each param of the plugin
      const sPlugin = this.state.vaultGlobal.plugin;
      for(const opt of VaultTypes[sPlugin])
      {
        // Set the params into each control
        const sRef = 'edit-' + sPlugin + '-' + opt.param;

        // param keys are lowercase
        const sVal = this.state.vaultGlobal[opt.param.toLowerCase()];

        // If null, put a blank value
        this.refs[sRef].setParamValue(sVal || '');
      }
    }
  }

  renderAll()
  {
    // Build vault options tabs rows
    const dctVaultOptions = {};
    for(const sVaultType of Object.keys(VaultTypes))
    {
      let n = 0;
      dctVaultOptions[sVaultType] = [];
      for(const opt of VaultTypes[sVaultType])
      {
        if(opt !== 'empty')
        {
          // name each vaults params with the vault type as prefix
          const sRefName = 'create-' + sVaultType + '-' + n;
          dctVaultOptions[sVaultType].push
          (
           <VaultOptionTableRow data-cy={sRefName} ref={sRefName} key={sRefName} {...opt}/>
          );

          ++n;
        }
      }
    }

    // Construct the tabbed interface, one tab for every type of vault
    return (
      <Tabs ref='tabsCreate' onChange={this.props.onChange}>
        {
          VaultTypeNames.filter(v => v !== 'empty').map
          (
            (v) =>
              <Tab label={VaultNames[v]} key={v}>
                <OptionTable headers={['Option', 'Value']}>
                  {dctVaultOptions[v]}
                </OptionTable>
              </Tab>
          )
        }
      </Tabs>
    );
  }

  // Render only one vault tab
  renderSingle()
  {
    // Build vault options rows
    const arrVaultOptions = [];
    const sPlugin = this.state.vaultGlobal.plugin;

    // Dont show anything if its an unrecognized vault type
    if(sPlugin in VaultNames && sPlugin !== 'empty')
    {
      for(const opt of VaultTypes[sPlugin])
      {
        // name each vaults params with the vault type as prefix
        const sRefName = 'edit-' + sPlugin + '-' + opt.param;
        arrVaultOptions.push
        (
          <VaultOptionTableRow ref={sRefName} key={sRefName} {...opt}/>
        );
      }

      // Construct the tabbed interface but only one tab
      return (
        <Tabs ref='tabsEdit'>
          <Tab label={VaultNames[sPlugin]}>
            <OptionTable headers={['Option', 'Value']}>
              {arrVaultOptions}
            </OptionTable>
          </Tab>
        </Tabs>
      );
    }

    return null;
  }

  render()
  {
    return (
      this.state.vaultGlobal
      ?
        this.renderSingle()
      :
        this.renderAll()
    );
  }
}

// Vault info view helper
const PreDisplay = (props) =>
{
  return props.data && Object.keys(props.data).length > 0 ?
    <div style={{margin: LayoutDims.nMargin}}>
      {props.title}
      <pre style={{whitespace: 'pre-wrap', ...Styles.Bordered, padding: LayoutDims.nMargin, margin: 0, backgroundColor: Colors.clrNimbixGray}}>
        <div>{JSON.stringify(props.data, null, 2)}</div>
      </pre>
    </div>
  :
    null
}


class UserVaults extends Component
{
  static instance = null;

  constructor(props)
  {
    super(props);
    this.state =
    {
      vaultInfo: {},
      vaultMeta: {},
      vaultGlobal: {},
      page: 'List',
      selected : null,
      err: '',
      user: props.user,
      curTab: '',
      arrSortedVaults: [],
      defaultVault: ''
    };

    this.arrUserOpts =
      props.users.map((dctUser) => <option key={dctUser.user_login}>{dctUser.user_login}</option>);

    // This component is created and destroyed each time "Vaults" is clicked
    // We have to bind these pane switch handlers only once
    // There is no "unregister" so we have to prevent multiple instances (including older closed ones) getting the handler
    // So we use a static var for the current instance of this dialog
    if(UserVaults.instance == null)
    {
      SwitchedPane.RegisterOnSwitch('@UserVaults/Create', UserVaults.initCreate)
      SwitchedPane.RegisterOnSwitch('@UserVaults/Edit', UserVaults.initEdit)
    }

    // Save the "current instance" for the above handlers
    UserVaults.instance = this;
  }

  // When the Edit or Create pane is switched to, tell them to init the values in the controls
  // This is made static because its bound to the SwitchedPane onSwitch handler, only once ever
  static initCreate = () =>
  {
    // The current component instance set in the ctor
    const self = UserVaults.instance;

    // Reset the value of zone to default (-1) (control is not in the tabs)
    self.refs.inputVaultZoneCreate.value = -1;

    // Set the remaining data
    self.refs.vaultTabsCreate.initValues('Create');

    // Set metadata
    self.refs.metaDataCreate.value = '';

    // Clear error text
    self.setState({error: ''});
  }

  // This is made static because its bound to the SwitchedPane onSwitch handler, only once ever
  static initEdit = () =>
  {
    // The current component instance set in the ctor
    const self = UserVaults.instance;

    // Set the value of zone for edits (control is not in the tabs)
    self.refs.inputVaultZoneEdit.value = self.state.vaultGlobal[self.state.selected].zone || 0;

    // set the remaining data
    self.refs.vaultTabsEdit.initValues('Edit');

    // Set metadata iff its not an ephemeral vault
    if(self.state.selected !== 'ephemeral')
    {
      const dctMeta = self.state.vaultMeta[self.state.selected];
      self.refs.metaDataEdit.value = Object.keys(dctMeta).length ? JSON.stringify(dctMeta) : '';
    }
    // Clear error text
    self.setState({error: ''});
  }

  // On initial load, refresh the vaults list
  componentWillMount()
  {
    this.refresh(this.props.user);
  }

  // Fetch vault data from server and apply it when done
  refresh(user)
  {
    const fetcher = new DataFetcher('/portal/admin-get-user-vault-info', {user})
    .whenDone
    (
      ()=>
      {
        const data = fetcher.data
        UserVaults.instance.setState({vaultInfo: data.info, vaultMeta: data.meta, defaultVault: data.default.result, vaultGlobal: data.global})
      }
    )

    FetchBusy(fetcher, 'Fetching vaults info...');
  }

  // Switches to the associated page (we can only show one modal dialog)
  onClickBtnMain(page)
  {
    // Except Default all others just switch to the page
    // Default invokes the action directly
    if(page === 'Default')
    {
      this.doDefault();
      page = 'List';
    }

    this.setState({page});
    SwitchedPane.switch('@UserVaults', page);
  }

  // Handles actions by calling the portal
  doAction = (url, params, text, onSuccess) =>
  {
    if(!onSuccess) onSuccess = this.handleActionSuccess;
    const fetcher = new DataFetcher(url, params);
    fetcher.whenDone(onSuccess).ifFail(this.handleActionFail);
    FetchBusy(fetcher, text);
  }

  // When network request succeeds, switch back to list and refresh
  handleActionSuccess = () =>
  {
    this.onClickBtnMain('List');
    this.refresh(this.state.user);
  }

  // When request fails, switch to the error page
  handleActionFail = (jqXHR) =>
  {
    this.setState({actionerr: jqXHR.responseJSON ? jqXHR.responseJSON.error.msg : jqXHR.statusText});
    this.onClickBtnMain('Error');
  }

  // Create a vault
  doCreate = () => this.doCreateOrEdit('Create');

  // Save edited vault
  doEdit = () => this.doCreateOrEdit('Edit');

  // Handles saving created or edited vault
  doCreateOrEdit = (action) =>
  {
    const name = this.refs['inputVaultName' + action].value;
    if(!name)
    {
      throw(['Please enter a name ', this.refs['inputVaultName' + action]])
    }

    // Get vault params
    const refParams = this.refs['vaultTabs' + action];
    const params = refParams && refParams.getParams(action);
    const refMD = this.refs['metaData' + action];
    try
    {
      if(refMD && refMD.value)
      {
        params.metadata = JSON.stringify(JSON.parse(refMD.value));
      }
    }
    catch (e)
    {
      throw(['Invalid JSON in meta data', refMD]);
    }

    // Add the zone to the globaldata
    const zone = this.refs['inputVaultZone' + action].value;
    if(params && params.globaldata)
    {
      params.globaldata.zone = zone;
    }
    else
    {
      params.globaldata = {zone};
    }

    // Convert the nested dict to JSON string for AJAX
    params.globaldata = JSON.stringify(params.globaldata, null, 2);

    // Add the other HTTP params needed
    params.name = name;
    params.user = this.state.user;

    // Style for previewing
    const stylePre =
    {
      ...Styles.Bordered,
      fontSize: 'small',
      padding: LayoutDims.nMargin,
      backgroundColor: Colors.clrNimbixBlueGray,
      whiteSpace: 'pre-wrap'
    };

    const sTitle = (action === 'Create' ? 'Confirm Vault Creation: ' : 'Confirm Edits For Vault: ') + params.name
    const sURL = action === 'Create' ? '/portal/admin-user-vault-create' : '/portal/admin-user-vault-edit';

    GlobalUI.DialogConfirm.confirm
    (
      <div style={{fontSize: 'small', maxHeight: window.innerHeight * 0.75, overflowY: 'auto'}}>
        Global Data:
        <pre style={stylePre}>
          {params.globaldata}
        </pre>

        {
          params.metadata &&
          <div>
            Metadata:
            <pre style={stylePre}>
            {params.metadata}
            </pre>
          </div>
        }
      </div>,
      sTitle,
      ()=>this.doAction(sURL, params, (action === 'Create' ? 'Creating' : 'Saving') + ' Vault...'),
    );
  }

  // Rename action, check for duplicate and blank
  doRename = () =>
  {
    const sName = this.refs.inputVaultRename.value;
    if(!sName)
    {
      throw ['Please enter a vault name', this.refs.inputVaultRename];
    }

    if(sName in this.state.vaultInfo)
    {
      throw ['This vault name is already in use!', this.refs.inputVaultRename];
    }

    const params = {old: this.state.selected, new: sName, user: this.state.user};
    this.doAction('/portal/admin-user-vault-rename', params, 'Renaming Vault...');
    this.setState({selected:''});
  }

  //Delete action
  doDelete = () =>
  {
    const params = {name: this.state.selected, user: this.state.user};
    this.doAction('/portal/admin-user-vault-delete', params, 'Deleting Vault...');
    this.setState({selected:''});
  }

  doInfo = () =>
  {
    this.setState({page: 'Info'});
    SwitchedPane.switch('@UserVaults', 'Info');
  }

  doLink = () =>
  {
    const sDestUser = this.refs.inputLinkUser.value;
    if(!sDestUser)
    {
      throw ['Please select a user name', this.refs.inputLinkUser];
    }

    const params = {name: this.state.selected, user: this.state.user, destuser: sDestUser};
    this.doAction
    (
      '/portal/admin-user-vault-link',
      params,
      'Linking Vault...',
      () => this.onClickBtnMain('List')
    );
  }

  doDefault = () =>
  {
    const params = {user: this.state.user, key: 'defaultVault', value: this.state.selected};
    const DefaultVaultSetter = new DataFetcher('/portal/admin-user-set-meta', params);
    DefaultVaultSetter.whenDone
    (
      () =>
      {
        SwitchedPane.switch('@UserVaults', 'List');
        this.setState({page: 'List', defaultVault: this.state.selected});
      }
    );

    FetchBusy(DefaultVaultSetter, 'Setting default vault');
  }

  onPageCancel = () => this.onClickBtnMain('List');

  // Handles the OK button of every page
  onPageDone = (action) =>
  {
    try
    {
      const sFnName = 'do' + action;
      if(this[sFnName])
      {
        this[sFnName]();
      }
    }
    catch(err) // ['error', HTML element to focus]
    {
      if(err[0])
      {
        this.setState({error: err[0]});
      }

      if(err[1])
      {
        err[1].focus();
      }
    }
  }

  // List pane
  renderListPane()
  {
    // Make the array of action buttons from above data
    const arrBtns = Object.keys(UserVaults.Btns).map
    (
      (e) =>
      {
        const arrProps = UserVaults.Btns[e];

        // Color property
        const props = {...arrProps[1]};

        // Disabled if not selected and not Create or else if the test function returns true
        const disabled =
          (arrProps[0] && !this.state.selected) ||
          !!(arrProps[2] && arrProps[2](this.state.defaultVault, this.state.selected, this.state.vaultGlobal));

        return <div key={e}>
          <RaisedButton {...props} data-cy={'buttonVault' + e} disabled={disabled} label={e} onClick={()=>this.onClickBtnMain(e)}/>
          &nbsp;&nbsp;
        </div>
      }
    );

    const styleTD = {...Styles.TableCellBordered,  wordBreak: 'break-all'};
    const dctZoneDesc = {}
    for(const sVaultZone of Object.keys(Data.Zones))
    {
      const zone = Data.Zones[sVaultZone];
      dctZoneDesc[zone.id] = zone.id + ': ' + zone.desc;
    }

    return (
      <SwitchedPane active paneGroup='@UserVaults' paneName='List'>
        <div >
          <div style={Styles.InlineFlexRow}>
            {arrBtns}
          </div>

          <br/>
          <br/>

          <OptionTable headers={['Vault Name', 'Type', 'Zone']}>
            {
              Object.keys(this.state.vaultInfo).map
              (
                (e) =>
                {
                  const isErr = this.state.vaultMeta[e] == null;
                  const isDefault = e === this.state.defaultVault;
                  const isSelected = e === this.state.selected;
                  const styleCell =
                    {
                      ...styleTD,
                      ...(isSelected ? Styles.Selected : Styles.DeSelected),
                      ...(isDefault ? {fontWeight: 'bold'} : {}),
                      ...(isErr ? {color: Colors.clrNimbixDarkRed} : {}),
                      ...(isDefault && !isSelected ? {backgroundColor: Colors.clrNimbixBlueGray} : {}),
                    };

                  let zone = dctZoneDesc[this.state.vaultInfo[e].zone] || 'None';

                  return (
                    <tr key={e} onMouseDown={()=>this.setState({selected: e})}>
                      <td style={{...styleCell, paddingLeft:4}} title={isErr ? 'Vault meta data fetch failed!' : ''}>
                        {isDefault && '✔ '}
                        {e}
                      </td>
                      <td style={styleCell}>{this.state.vaultInfo[e].type}</td>
                      <td style={styleCell}>{zone}</td>
                    </tr>
                  );
                }
              )
            }

            {
              Object.keys(this.state.vaultInfo).length === 0 &&
              <tr style={{height: 150}}>
                <td style={styleTD} colSpan='2'> </td>
              </tr>
            }
          </OptionTable>

        </div>
      </SwitchedPane>

    );
  }

  // Vault Create pane
  renderCreatePane()
  {
    return (
      <SwitchedPane paneGroup='@UserVaults' paneName='Create'>

        <div style={Styles.FlexSpread}>
          <InputRow title='Vault Name:'>
            <input  data-cy='inputVaultNameCreate' ref='inputVaultNameCreate' style={Styles.ParamInputWide}/>
          </InputRow>
          &nbsp;&nbsp;
          <InputRow title='Zone:'>
            <select data-cy='inputVaultZoneCreate' ref='inputVaultZoneCreate' style={Styles.ParamInputWide}>
              {getZoneOpts(true)}
            </select>
          </InputRow>
        </div>


        <VaultTabs ref='vaultTabsCreate' onChange={()=>this.setState({error: ''})} />

        <Separator units={2}/>
        Additional Metadata:<br/>
        <textarea ref='metaDataCreate' style={{height: 100, width: '100%'}}>
        </textarea>
        <div className='errortext'>{this.state.error}&nbsp;</div>

        <OKCancelPane parent={this} label='Create' id='Create' extra={Btns.Blue}/>

      </SwitchedPane>
    );
  }

  // Vault Create pane
  renderEditPane = () =>
  {
    const sVaultName = this.state.selected;
    return sVaultName &&  (
      <SwitchedPane paneGroup='@UserVaults' paneName='Edit'>

        <div style={Styles.FlexSpread}>
          <InputRow title='Vault Name:'>
            <input data-cy='inputVaultNameEdit' ref='inputVaultNameEdit' value={sVaultName || ''}  readOnly disabled style={Styles.ParamInputWide}/>
          </InputRow>
          &nbsp;&nbsp;
          <InputRow title='Zone:'>
            <select data-cy='inputVaultZoneEdit' ref='inputVaultZoneEdit' style={Styles.ParamInputWide}>
              {getZoneOpts(true)}
            </select>
          </InputRow>
        </div>

        <VaultTabs ref='vaultTabsEdit'
                   type='Edit'
                   vaultMeta={this.state.vaultMeta[this.state.selected]}
                   vaultInfo={this.state.vaultInfo[this.state.selected]}
                   vaultGlobal={this.state.vaultGlobal[this.state.selected]}/>


        {
          this.state.vaultGlobal[this.state.selected].plugin !== 'empty' &&
          <div>
            <Separator units={2}/>
            Additional Metadata:<br/>
            <textarea ref='metaDataEdit' style={{height: 100, width: '100%'}}>
            </textarea>
            <div className='errortext'>{this.state.error}&nbsp;</div>
          </div>
        }

        <OKCancelPane parent={this} label='Save' id='Edit' extra={Btns.Blue}/>

      </SwitchedPane>
    );
  }


  static Btns = null;

  isVaultNonEditable = (_, sel, global) => !(global[sel].plugin in VaultNames) && sel !== 'ephemeral';

  render()
  {
    // Init the buttons array the first time round
    if(!UserVaults.Btns)
    {
      // the optional third parameter is a function that tells if the button is disabled
      UserVaults.Btns =
      {
        'Create':   [false, Btns.Green],
        'Edit':     [true, Btns.Blue, this.isVaultNonEditable],
        'Delete':   [true, Btns.DarkRed],
        'Rename':   [true],
        'Link':     [true],
        'Info':     [true],
        'Default':  [true, Btns.Blue, (def, sel)=>sel && sel === def],
      };
    }


    return (
      <div>

        {this.renderListPane()}

        {this.renderCreatePane()}

        {this.renderEditPane()}

        <SwitchedPane paneGroup='@UserVaults' paneName='Rename'>
          <InputRow title='Enter New Vault Name:'>
            <input ref='inputVaultRename' style={Styles.ParamInputWide}/>
          </InputRow>

          <div className='errortext' >{this.state.error}&nbsp;</div>

          <OKCancelPane parent={this} label='Rename' extra={Btns.Blue}/>
        </SwitchedPane>

        <SwitchedPane paneGroup='@UserVaults' paneName='Delete'>
          <br/>
          Are you sure you want to delete the selected vault <b>'{this.state.selected}'</b>?
          <br/>
          <OKCancelPane parent={this} label='Delete' extra={Btns.Blue}/>
        </SwitchedPane>

        <SwitchedPane paneGroup='@UserVaults' paneName='Error'>
          Error:
          <pre style={{whitespace: 'pre-wrap'}}>
            <div>{this.state.actionerr}</div>
          </pre>

          <OKPane this={this}/>
        </SwitchedPane>

        <SwitchedPane paneGroup='@UserVaults' paneName='Info'>
        {
          this.state.selected &&
          <div style={{...Styles.Bordered, maxHeight: 350, overflowY: 'auto'}}>
            <PreDisplay data={this.state.vaultInfo[this.state.selected]} title='Info:' />
            <PreDisplay data={this.state.vaultMeta[this.state.selected]} title='Meta data:' />
            <PreDisplay data={this.state.vaultGlobal[this.state.selected]} title='Global:' />
          </div>
        }
        <OKPane this={this}/>
        </SwitchedPane>

        <SwitchedPane paneGroup='@UserVaults' paneName='Link'>
          <InputRow title='User to link to:'>
            <select ref='inputLinkUser' style={Styles.ParamInputWide}>
              {this.arrUserOpts}
            </select>
          </InputRow>
          <div className='errortext' >{this.state.error}&nbsp;</div>
          <OKCancelPane parent={this} label='Link'/>
        </SwitchedPane>

      </div>
    );
  }
}


// Admin users UI
export default class AdminUsers extends Component
{
  constructor(props)
  {
    super(props);

    UserRow.parent = this;

    this.state = {filtered: [], edited: 0, page: 1, editedCount: 0, filterRoleSet: new Set(), sortCol: 0, sortAsc: true};
    this.pagesize = 100;
    this.pagecount = 1;
    this.fetcherUsers = null;

    // Initial fetch after resetting all the non UI state
    this.resetData()
    this.fetchData();
  }

  // Header text
  static arrColText = ['Login', 'Name', 'Email',	'Privileges',	'Payer',	'Enabled', 'Dev', 'Roles', 'Billing'];

  // Field names
  static arrColFields = ['user_login', 'user_nicename', 'user_email',	'user_privs',	'payer',	'enabled', 'developer', 'roles', 'billing_code']

  // Column types for sorting, default is string
  static dctColTypes = {enabled: 'bool', developer: 'bool', roles: 'number'}

  // Fields that can be searched on
  static arrFilters = ['user_login', 'user_nicename', 'user_email',	'user_privs',	'payer',	'', '', null, 'billing_code']

  // Fields preset in invites
  static arrHashFields = ['user_email',  'enabled',  'payer',  'billing_code',  'developer',  'user_privs',  'account_vars', 'vaultGlobalData', 'vaultMetaData'];

  static checkProps =
  {
    style: {marginLeft: LayoutDims.nMargin * 2},
    labelStyle: {color: Colors.clrNimbixDark, marginLeft: -8}
  };

  static strStyleTag = null;

  initStyleTag = () =>
  {
    // We use an embedded class style so we can swiftly change a row from selected to unselected
    AdminUsers.strStyleTag =
    `
    .user-tr td, .user-tr-selected td
    {         
      padding: 8px;   
      color: #003366;
      font-size: 13px; 
      border: 1px solid ${clrTrans(Colors.clrNimbixDark, 0.1)};
      word-break: break-word 
    }
    
    .user-tr-selected, .user-tr-selected td
    {
      background-color: ${Colors.clrNimbixDark};
      color: white;
    }
    
    .user-check
    {
      font-size: 20px !important;
      width: 20px;
      height: 20px;
    }
    
    .changed
    {
      border: 3px solid ${clrTrans('#FF0000', 0.2)} !important;
    }
  `;
  }

  // map each user name to its row index to help with updating
  updateUserMap = () =>
  {
    for(let i = 0; i < this.sortedUsers.length; ++i)
    {
      this.userMap[this.sortedUsers[i].user_login] = i;
    }
  }

  // Resets the local (non-UI) state
  resetData = () =>
  {
    this.payersOnly = false;

    // Map of user names to user row indices
    this.userMap = {};

    // Current field filter criteria
    this.filterVals = {};

    // Integer role flags bitmask (also duplicated in state)
    this.filterRoleSet = new Set();

    // Contains filtered rows for each stage - only payers, role filter, field filter, search box
    this.filteredRows = [[], [], [], []]

    // The complete user list (and a sorted copy)
    this.users = null;
    this.sortedUsers = null;

    // user data list entries
    this.dataList = [];
  }

  // Calls fetchData after resetting local and UI state
  refresh = () =>
  {
    // Clear search, filters and selection
    this.refs.searchBox.inputField.value = '';
    AdminUsers.arrFilters.forEach(e => e && (this.refs[e].value = ''));
    this.refs.roles.value = '';
    this.deselect();

    // reset other non-UI state
    this.resetData();

    // Reset UI state and refetch users
    this.setState({filtered: [], edited: 0, page: 1, editedCount: 0, filterRoleSet: new Set(), sortCol: 0, sortAsc: true}, this.fetchData);
  }

  // Fetches all the data and passes it on to AdminLimits
  fetchData = () =>
  {
    this.fetcherUsers = new DataFetcher('/portal/admin-user-list');
    this.fetcherUsers.whenDone
    (
      () =>
      {
        // Keep track of users who have team members
        this.teams = {};

        // Clean up the entries where payer == user_login
        for(const row of this.fetcherUsers.data)
        {
          // Blank out payer display if not a team member
          if(row.user_login === row.payer)
          {
            row.payer = '';
          }

          // Keep track of users who have team members
          if(row.payer)
          {
            this.teams[row.payer] = true;
          }
        }

        // Update data - everything is initially all users
        this.users = this.fetcherUsers.data;

        // Initial sorted array
        this.sortedUsers = [...this.users]

        // Create the username to index map
        this.updateUserMap();

        // Initially nothing is filtered
        this.setState({filtered: this.sortedUsers});
        this.filteredRows = [this.sortedUsers, this.sortedUsers, this.sortedUsers, this.sortedUsers];

        // Pass the entire list to Limits UI
        GlobalUI.AdminLimits.takeUsers(this.users);
      }
    );

    this.fetcherUsers.fetch();
  }

  // returns true if user matches search box
  filterSearch = (user) =>
  {
    return !this.search ||
           user.user_login.toUpperCase().indexOf(this.search) >= 0 ||
           user.user_nicename.toUpperCase().indexOf(this.search) >= 0 ||
           user.user_email.toUpperCase().indexOf(this.search) >= 0;
  }

  // returns true if user is within current field filter
  filterFields = (user) =>
  {
    // Filter based on field search values
    const arrFields = AdminUsers.arrFilters;
    for(const sField of arrFields)
    {
      if(sField)
      {
        // Get the edit box value
        const sVal = this.refs[sField].value;

        // If there is a value, check if case insensitive substring mismatch and bail
        if(sVal)
        {
          const rx = new RegExp(sVal, 'i');
          if(!rx.test(user[sField]))
          {
            return false;
          }
        }
      }
    }

    // Everything matched or no filters
    return true;
  }

  // returns true if the users role matches with any of the roles in the set
  filterRoles = (user) =>
    this.filterRoleSet.size === 0 || Array.from(this.filterRoleSet).some(e => (e & (user.user_roles|0)) === e);

  // returns true for payers
  filterPayers = (user) => !this.payersOnly || !user.payer

  // Called with 0, 1, 2 or 3 when the respective filter criteria changes
  doFilter = (stage, instant) =>
  {
    // Deselect row if any
    this.deselect();

    // If an existing timer is running, kill it
    if(this.timerID)
    {
      clearTimeout(this.timerID);
      this.timerID = null;
    }

    const doIt = () =>
    {
      // Redo each stage of filtering as needed
      const filters = [this.filterPayers, this.filterRoles, this.filterFields, this.filterSearch];
      let filtered = stage ? this.filteredRows[stage - 1] : this.sortedUsers;
      for(let i = stage; i < filters.length; ++i)
      {
        this.filteredRows[i] = filtered.filter(filters[i]);
        filtered = this.filteredRows[i];
      }

      this.setState({filtered, page: 1});
    }

    // Call filtering instantly or with a debounce timer
    if(instant)
    {
      doIt()
    }
    else
    {
      // Start a new timer
      this.timerID = setTimeout(doIt, 150);
    }
  }

  // Called by rows class to indicate editing
  rowEdited(edited)
  {
    this.btns.rowEdited(edited);

    // Keep a count of edited rows
    this.setState({editedCount : this.state.editedCount + (edited ? 1: -1)});
  }

  // Deselect any row that is selected (should only be called when filtering or paging)
  deselect = () =>
  {
    // If anything selected and exists, deselect it
    if(this.sUserSelected)
    {
      if(this.rowRefs[this.sUserSelected])
      {
        this.rowRefs[this.sUserSelected].deselect();
      }

      this.sUserSelected = null;
      this.btns.setState({selected: false});
    }
  }

  // When search string changed
  onSearch = (search) =>
  {
    this.search = search && search.toUpperCase();
    this.doFilter(3);
  }

  // Search box X button
  onSearchClear = () =>
  {
    this.refs.searchBox.inputField.value = '';
    this.onSearch(null);
  }

  // Field filter changes
  onFieldFilterChange = (evt) =>
  {
    this.doFilter(2)
  }

  // Payers only checkbox
  onCheckPayersOnly = (e, v) =>
  {
    this.payersOnly = v;
    this.doFilter(0, true);
  }

  // On click row toggle selection
  onClickRow = (user) =>
  {
    const sUserSelected = this.sUserSelected;

    // If anything selected and exists, deselect it
    this.deselect();

    // Select the row if it wasn't selected before
    if(user && user !== sUserSelected && this.rowRefs[user])
    {
      this.rowRefs[user].setState({selected: true});
      this.btns.setState({locked: this.rowRefs[user].orig.user_locked});
      this.sUserSelected = user;
      this.btns.setState({selected: true});
    }
  }

  // Apply changes after confirm
  onApply = () =>
  {
    const dctChanges = {};
    let sChanges = '';

    // Build a dict of changes, and a text summary
    for(const k of Object.keys(this.rowRefs))
    {
      const row = this.rowRefs[k];
      if(row && row.state.edited)
      {
        // data() returns a dict containing the fields edited on that user row
        const dctFields = row.data();
        dctChanges[k] = {...dctFields};

        // If payer was set to none/self, then clear tenant admin role bit (unless admin)
        // Flags to indicate if we removed that role and if it was assigned earlier
        let bTenantAdminRemoved = false, bWasTenantAdmin = false;
        if('payer' in dctFields && (!dctFields.payer || dctFields.payer === row.props.user.user_login))
        {
          // Get the new role and the previous role
          let role = row.state.user.user_roles | 0;
          let oldRole = row.props.user.user_roles | 0;

          // If the role has ROLE_TENANT_ADMIN and it's not == ROLE_ADMIN
          if(role !== ROLE_ADMIN && (role & ROLE_TENANT_ADMIN))
          {
            // Remove the role bits
            dctChanges[k].user_roles = row.state.user.user_roles & ~ROLE_TENANT_ADMIN;
            dctFields.user_roles = dctChanges[k].user_roles;

            // Set the flags
            bTenantAdminRemoved = true;
            bWasTenantAdmin = oldRole & ROLE_TENANT_ADMIN;
          }
        }

        if('user_roles' in dctFields)
        {
          dctFields.user_roles = makeRoleString(dctFields.user_roles);
        }

        sChanges +=
          `${k}\n` + Object.keys(dctFields).map((f)=>`  ${f} = ${dctFields[f]}`).join('\n') + '\n';

        // Tell the user if the tenant admin role was disallowed
        if(bTenantAdminRemoved)
        {
          sChanges += bWasTenantAdmin ? 'Team Admin role removed' : 'Team Admin role is not allowed for payers and will not be applied';
        }

        sChanges += '\n';
      }
    }


    const style =
    {
      ...Styles.Bordered,
      backgroundColor:Colors.clrNimbixGray,
      margin:0,
      maxHeight: 200,
      overflowY: 'auto',
      padding: LayoutDims.nMargin,
      marginTop: LayoutDims.nMargin,
    };

    // Show confirm dialog and apply
    GlobalUI.DialogConfirm.confirm
    (
      <div style={{margin:0}}>
        Do you wish to update the following user data?
        <pre
          style={style}>
          {sChanges}
        </pre>
      </div>,
      'Confirm User',
      () => this.doApply(dctChanges)
    );
  }

  // Apply changes
  doApply = (dctChanges) =>
  {
    // Send request with spinner
    const fetcher = new DataFetcher('/portal/admin-user-update', {data: JSON.stringify(dctChanges)});

    fetcher.whenDone
    (
      // On success servers sends the data back, update each row
      ()=>
      {
        for(const entry of fetcher.data)
        {
          // Look up the user rows and update it
          const idx = this.userMap[entry.user_login];
          this.sortedUsers[idx] = entry;

          const ref = this.rowRefs[entry.user_login];
          if(ref)
          {
            ref.revert(entry);
          }
        }

        // Refresh the view
        this.doFilter(0, true);
      }
    );

    fetcher.ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Error'));
    FetchBusy(fetcher, 'Saving...');
  }

  // this called from the Cancel button
  onReset = () =>
  {
    // Revert all edited rows unless specified
    for(const k of Object.keys(this.rowRefs))
    {
      const ref = this.rowRefs[k];
      if(ref && ref.state.edited)
      {
        ref.revert();
      }
    }

    // If anything selected and exists, deselect it
    this.deselect();

    // Revert the buttons states, also deselect
    this.btns.setState({selected:false, editedCount: 0});
  }

  // Lock or unlock the user
  doLock = (lock, killjobs, resetkey, reason) =>
  {
    // Send request with spinner
    const user = this.sUserSelected;
    const fetcher = new DataFetcher('/portal/admin-user-lock', {user, lock, killjobs, resetkey});
    FetchBusy(fetcher, lock ? 'Locking...' : 'Unlocking...');

    // Update UI when done
    fetcher.whenDone
    (
      () =>
      {
        let sMsg = lock ? 'Locked' : 'Unlocked';
        if(reason)
        {
          sMsg += ': ' + reason;
        }
        AuditLog('user', user, sMsg);
        this.rowRefs[user].lock(lock);
        this.btns.setState({locked: lock});
      }
    );
  }

  // Show option/confirm dialog on lock, no dialog on unlock
  onLock = () =>
  {
    // If anything selected
    const sUserSelected = this.sUserSelected;
    if(sUserSelected && this.rowRefs[sUserSelected])
    {
      // Get the lock state
      const lock = !this.rowRefs[sUserSelected].orig.user_locked;

      if(lock)
      {
        // Checkboxes for job terminate and reset API key
        let bTerminate = false, bReset = false;
        let refReason;

        const ctrls =
          <div>
            Are you sure you want to lock access for the user <b>'{sUserSelected}'</b>?
            <br/><br/>
            <Checkbox {...AdminUsers.checkProps} onCheck={(e, v)=>{bTerminate = v}} label='Kill all running jobs for this user'/>
            <Checkbox {...AdminUsers.checkProps} onCheck={(e, v)=>{bReset = v}} label='Reset the API Key for this user'/>

            <br/>
            <div style={{...Styles.Inline, width: '100%', marginRight: LayoutDims.nMargin * 2, marginBottom: LayoutDims.nMargin * 2}}>
              Reason:&nbsp;&nbsp;
              <input style={{...Styles.ParamInput, flex: 1}} ref={(ref) => refReason = ref}/>
            </div>
          </div>

        // Show the confirm dialog and lock the user if...
        GlobalUI.DialogConfirm.confirm
        (
          ctrls,
          'Confirm Lock',
          () => this.doLock(lock, bTerminate, bReset, refReason.value),
        );
      }
      else
      {
        this.doLock(lock);
      }
    }
  }

  onDelete = () =>
  {
    const user = this.sUserSelected;
    if(user && this.rowRefs[user])
    {
      const setDelData = (v) => this.deldata = v;
      const ctrls =
      <div>
        {
          this.teams[user] &&
          <p className='errortext'>
            Warning: This user account is the owner of a team - deleting it will remove all data owned by this user,
            including SAML/LDAP configuration, Projects, Limits, Container Identity configuration,
            as well as job history/output for this team owner.<br/>
            All team members will become independent users and their jobs' billing (including past jobs)
            will apply to their own accounts.
            <br/>
            <br/>
            This operation may take quite a while if the user has run many jobs.
            <br/>
          </p>
        }
        Are you sure you want to delete the user <b>'{user}'</b>?
        <br/><br/>
        <Checkbox key={Math.random()}
                  defaultChecked={this.deldata}
                  {...AdminUsers.checkProps}
                  onCheck={(_, v)=>setDelData(v)}
                  label='Run user deletion hook script on backend'/>
      </div>;

      GlobalUI.DialogConfirm.confirm
      (
        ctrls,
        'Confirm Delete',
        () =>
        {
          const fetcher = new DataFetcher('/portal/admin-user-del', {user, deldata: this.deldata});
          FetchBusy(fetcher, 'Deleting...');
          fetcher.whenDone
          (
            ()=>
            {
              // Delete the row and reset the button state
              this.rowRefs[user].del();
              this.btns.setState({selected: false});
              this.sUserSelected = null;
            }
          );
        }
      );
    }
  }

  onNotify = () =>
  {
    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      'User Notification',
      <UserNotify user={this.sUserSelected} motd={Data.MOTD}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  onVars = () =>
  {
    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
    'Account Variables',
    <UserVars user={this.sUserSelected}/>,
    false,
    LayoutDims.wContent
    );
  }

  onVaults = () =>
  {
    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      'Manage Vaults',
      <UserVaults users={this.users} user={this.sUserSelected}/>,
      false,
      LayoutDims.wContent
    );
  }

  onCredits = () =>
  {
    GlobalUI.Dialog.clear();
    GlobalUI.Dialog.show
    (
      'Manage Credits',
      <UserCredits user={this.sUserSelected}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  onInvite = () =>
  {
    // title, text, pre, width, onClose, top, noEscape
    GlobalUI.Dialog.show
    (
      'Invite User',
      <UserInvite onInvite={this.doInvite}/>,
      false,
      LayoutDims.wContent * 0.75
    );
  }

  doInvite = (refInviteDlg, bLinkOnly) =>
  {
    // fetch the data
    const dctRet = refInviteDlg.getData();

    // Check each field
    for(const sKey of Object.keys(dctRet))
    {
      // Get the validator for this field if any
      const fn = Validators[sKey];

      // If it fails show teh error and dont let this dialog close
      if(fn && !fn(dctRet[sKey]))
      {
        refInviteDlg.setErr(sKey, ValidationErrs[sKey]);
        return true;
      }
    }

    // get rid of spaces in the hashed fields
    for(const sKey of AdminUsers.arrHashFields)
    {
      let val = dctRet[sKey];
      if(val && val.indexOf(' ') >= 0)
      {
        dctRet[sKey] = val.replace(/ /g, '');
      }
    }

    // Tell server we only need the link, not an email
    if(bLinkOnly)
    {
      dctRet.linkonly = true
    }

    const fetcher = new DataFetcher('/portal/admin-user-invite', dctRet)
      .whenDone
      (
        (jqXHR)=>
        {
          if(bLinkOnly)
          {
            copyToClipboard(jqXHR.responseJSON.invite_link);
          }

          GlobalUI.Dialog.show('Information', bLinkOnly ? 'Invitation link copied to clipboard' : 'An invitation was successfully sent')
        }
      )
      .ifFail
      (
        (jqXHR)=>
        {
          if(jqXHR.responseJSON)
          {
            refInviteDlg.setErr('', 'Invite failed: ' + jqXHR.responseJSON.error.msg)
          }
          else
          {
            GlobalUI.DialogConfirm.showErr(jqXHR, 'Invite failed');
          }
        }
      );

    FetchBusy(fetcher, bLinkOnly ? 'Getting Invite Link...' : 'Sending Invite...');
    return true;
  }

  onLog = () =>
  {
    const params =
    {
      user: this.sUserSelected,
      descending: true,
    };

    const fetcher = new DataFetcher('/portal/admin-audit-query', {...params});
    FetchBusy(fetcher, 'Fetching log...');

    const style =
    {
      fontSize: 'large',
      maxHeight: window.innerHeight * 0.65,
      overflowY: 'scroll',
      padding: LayoutDims.nMargin,
    };

    fetcher.whenDone
    (
      ()=>
      {
        const arrEntries = fetcher.data.result;
        for(const entry of arrEntries)
        {
          entry.logtime = noWrap(dateToLocalTimeStr(entry.logtime))
        }

        GlobalUI.Dialog.clear();
        GlobalUI.Dialog.show
        (
          'Audit Log',
          <div style={style}>

            <ReportDisplay data={arrEntries}
                           colFields={['logtime', 'category', 'resource', 'message']}
                           colText={['Time', 'Category', 'Resource', 'Message']}
                           sortAsc={false}
                           sortCol={0}/>

          </div>,
          false,
          LayoutDims.wContent
        );
      }
    );
  }

  // Handle Enter and Escape on rows
  onKeyPress = (evt) =>
  {
    // revert data on esc
    if(evt.key === 'Escape')
    {
      if(this.sUserSelected && this.rowRefs[this.sUserSelected])
      {
        this.rowRefs[this.sUserSelected].revert();
        this.btns.setState({selected: false});
        this.sUserSelected = null;
      }
    }
    else if(evt.key === 'Enter') // deselect on enter
    {
      this.deselect();
    }
  }

  onNavPage  = (page) => {this.deselect(); this.setState({page})};
  onPrevPage = () =>     {this.deselect(); this.setState({page: this.state.page - 1})};
  onNextPage = () =>     {this.deselect(); this.setState({page: this.state.page + 1})};

  onFilterKeypress = e =>
  {
    // Clear field filter on Esc, and apply field filter instantly
    if(e.key === 'Escape')
    {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = '';
      this.doFilter(2, true);
    }
    return true;
  }

  // When role filter box is clicked
  onClickFilterRole = e =>
  {
    if(!this.state.editedCount)
    {
      GlobalUI.DialogConfirm.clear();
      GlobalUI.DialogConfirm.confirm
      (
        <UserRoleDlg
          onChange={roleSet => this.filterRoleSet = roleSet}
          role={this.filterRoleSet}
          simple={true}
          payer={null}
        />,
        'Choose Roles To Filter',
        () => this.setState({filterRoleSet: this.filterRoleSet}, ()=>this.doFilter(1, true)),
        null,
      );
    }
  }

  onSortCol = (sortCol) =>
  {
    // If same column, switch asc/desc
    let sortAsc;
    if(sortCol === this.state.sortCol)
    {
      sortAsc = !this.state.sortAsc;
    }
    else // sort asc by new col
    {
      sortAsc = true;
    }

    // Sort the data
    const field = AdminUsers.arrColFields[sortCol];

    // Reverse after sorting if needed
    switch(AdminUsers.dctColTypes[field])
    {
      case 'number':
      case 'bool':
        this.sortedUsers.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)
      break;

      default:
        this.sortedUsers.sort((a, b)=>StrICmp(a[field] || '', b[field] || ''));
    }

    if(!sortAsc)
    {
      this.sortedUsers.reverse();
    }

    // Remake the map of user login to row index
    this.updateUserMap();

    // Reapply the filters
    this.doFilter(0, true);

    // Set the sort indicator status
    this.setState({sortAsc, sortCol});
  }

  // Makes a table header with a control in it, uses a dummy form to avoid overzealous auto-suggest
  makeFilterHeader = (name, child) =>
  {
    return (
      <th key={name} style={{...Styles.EditableHeaderFilter,  border: `1.5px solid ${Colors.clrNimbixGray}`}}>
        <form autoComplete='off' >
          <button type="submit" disabled style={{display: 'none'}} />
          {child}
        </form>
      </th>
    );
  }

  makeRoleFilterDisplayString = (short) =>
  {
    const n = this.filterRoleSet.size;
    if(n === 0) return '';

    const arrRoles = Array.from(this.filterRoleSet);

    // If there is only one role show full string
    if(!short || n < 2)
    {
      return arrRoles.map(x=>RoleNames[x]).join(', ');
    }

    // else use abbreviated names
    return arrRoles.map(x=>RoleNamesShort[x]).join(', ');
  }

  static btnHandlers = ['onNotify', 'onVars', 'onVaults', 'onCredits', 'onInvite', 'onApply', 'onLock', 'onDelete', 'onReset', 'onLog'];

  render()
  {
    if(!AdminUsers.strStyleTag)
    {
      this.initStyleTag();
    }

    // Create the search header only once - unlike typical use (we dont need the modified indicator)
    const arrEditHeaders = AdminUsers.arrColFields.map
    (
      (e, i) => this.makeFilterHeader(
        e,
        AdminUsers.arrFilters[i] &&
        <input tabIndex={i + 1}
               ref={e}
               readOnly={AdminUsers.arrFilters[i] === '' || this.state.editedCount}
               onKeyUp={this.onFilterKeypress}
               style={{...Styles.EditableHeaderFilterInput, color: Colors.clrNimbixDarkRed}}
               onChange={this.onFieldFilterChange}
               autoComplete='off'
               title='Press Escape to clear'/>
      )
    );

    // Put a separate control for roles filtering
    let iRole = AdminUsers.arrColFields.indexOf('roles');
    arrEditHeaders[iRole] = this.makeFilterHeader
    (
      'roles',
      <input tabIndex={iRole + 1}
             ref='roles'
             style={{...Styles.EditableHeaderFilterInput, color: Colors.clrNimbixDark, cursor: 'pointer'}}
             autoComplete='off'
             readOnly
             value={this.makeRoleFilterDisplayString(true)}
             title={this.makeRoleFilterDisplayString()}
             onClick={this.onClickFilterRole}/>
    );

    // Get the last filtered data
    let arrFiltered = this.state.filtered;
    this.pagecount = ((arrFiltered.length / this.pagesize)|0) + (arrFiltered.length % this.pagesize ? 1 : 0);

    const start = (this.state.page-1) * this.pagesize
    arrFiltered = arrFiltered.slice(start, start + this.pagesize);

    // Create the rows, save their refs separately
    this.rowRefs = {};
    const arrRows = arrFiltered.map
    (
      u => <UserRow show={true} key={u.user_login} ref={e=>this.rowRefs[u.user_login] = e} user={u} onClick={this.onClickRow}/>
    );

    if(this.dataList.length === 0 && this.users)
    {
      this.dataList = this.users.map((dctUser, i) => <option key={i} value={dctUser.user_login}/>);
    }

    // Calculate available space and adjust so scroll bar is avoided
    let hAvail = window.innerHeight - (LayoutDims.hAppBar + 100);
    const isEditing = this.state.editedCount > 0;
    const isBusy = !this.users;

    const styleCtl = {...Styles.InlineFlexRow, margin: LayoutDims.nMargin/2};


    return (
      <div style={{...Styles.InlineFlexCol, width: '100%', alignItems: 'stretch', maxHeight: hAvail}} tabIndex='0' className='no-focus-rect' onKeyUp={this.onKeyPress} id='admin-users-pane'>

        <style dangerouslySetInnerHTML={{__html: AdminUsers.strStyleTag}} />

        <div>
          <div style={Styles.Inline}>

            <div tabIndex='0' style={{...styleCtl, flex: 3, marginRight: LayoutDims.nMargin*2}}>
              <SearchBox disabled={isEditing} ref='searchBox' entryType='AdminUser' width='100%' onClear={this.onSearchClear} onSearch={this.onSearch} />
            </div>

            <Checkbox ref='checkPayersOnly'
                      label='Show only payers'
                      disabled={isEditing || isBusy}
                      style={{width: '100%', flex: 2}}
                      onCheck={this.onCheckPayersOnly}
                      iconStyle={{marginRight: LayoutDims.nMargin}}/>


            <div style={styleCtl}>
              <Pager disable={isEditing || isBusy} page={this.state.page} pagecount={this.pagecount}
                     {...fromThis(this, ['onNavPage', 'onPrevPage', 'onNextPage'])}
                     paneGroup={'@AdminUserList'}/>


              &nbsp;&nbsp;
              <FloatingActionButton disabled={isEditing || isBusy}
                                    disabledColor={Colors.clrNimbixDarkGray}
                                    backgroundColor={Colors.clrNimbixDarkGreen}
                                    mini
                                    onClick={this.refresh}>
                {Icon('refresh', {fontSize: 16, color: 'white'})}
              </FloatingActionButton>
            </div>

          </div>
        </div>

        <Separator units={2}/>

        <UserBtns ref={(btns)=> this.btns = btns} {...fromThis(this, AdminUsers.btnHandlers)} />

        <ShowOnly if={this.users} style={{flex: 1, flexShrink: 1, overflowY: this.users? 'scroll': 'hidden', overflowX: 'hidden'}}
                  otherwise={<Spinner style={{marginTop: '10%'}} size={64} textColor={Colors.clrNimbixDark} status='Loading...'/>}>
          <div style={Styles.Bordered}>
            <table data-cy='tableAdminUsers' style={{...Styles.Table, tableLayout: 'fixed', width: '100%' }}>
              <ColGroup cols={[12, 12, 12, 8, 10, 6, 5, 10, 6]}/>
              <thead>
                <tr>{arrEditHeaders}</tr>
                <tr>{TableHeaderCells(AdminUsers.arrColText, this.state.sortCol, this.state.sortAsc, this.onSortCol, {fontSize: 12})}</tr>
              </thead>

              <tbody>
                {arrRows}
              </tbody>

            </table>

            {
              arrFiltered.length > 0
              ?
                null
              :
                <div style={{...Styles.Inline, height: 100, justifyContent: 'center', color: Colors.clrNimbixMed}}>
                  No users matching search criteria
                </div>
            }

          </div>
        </ShowOnly>

        <datalist style={{maxHeight: 200}} id='user-data-list'>
          {this.dataList}
        </datalist>

      </div>

    );
  }
}

