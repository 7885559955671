// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI Controls
import RaisedButton from 'material-ui/RaisedButton';

import {CardActions} from 'material-ui/Card';

// Our UI data, styles and colors
import {Btns} from './UIConst.js';

// App commands button row as Card Actions
export const AppCommandButtons = (props) =>
{
  return (
    <CardActions style={{paddingTop: 4, padding: 0}}>
    {
      Object.keys(props.data.commands).map
      (
        (sCmdName) =>
        {
          const propBtn = props.invalid.indexOf(sCmdName) >= 0
          ?
            {
              ...Btns.DarkRed,
              title: 'This command requires a machine that is not available',
              'data-cy': 'cmd-' + sCmdName + '-invalid'
            }
          :
            {
              ...Btns.Blue,
              onClick: props.onClick.bind(props.that, sCmdName),
              title: props.data.commands[sCmdName].description,
              'data-cy': 'cmd-' + sCmdName + '-valid'
            };

          return <RaisedButton className={'cmd-' + sCmdName} {...propBtn} style={{margin: 4}}
                          key={props.data.name + '-' + sCmdName}
                          labelStyle={{textTransform: 'capitalize'}}
                          label={props.data.commands[sCmdName].name}/>;
        }
      )
    }
    </CardActions>
  );
};

AppCommandButtons.propTypes =
{
  data: PropTypes.object.isRequired,
  that: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  invalid: PropTypes.arrayOf(PropTypes.string).isRequired
};
