// Core
import React, {Component} from 'react';

// Our custom components
import {LayoutDims, Styles, Colors} from './UIConst'
import {HLine, Separator} from '../Components/Components';
import {asGB} from './Utils';


// Component for the vertical bar for the bar graph
function Bar(props)
{
  return (
    <div title={props.title} style={
      {
        width: props.width,
        ...Styles.Bordered,
        marginTop: LayoutDims.nMargin,
        marginRight: LayoutDims.nMargin,
        position: 'relative'
      }
    }>
      <div
        style={
        {
          position: 'absolute',
          bottom: 0,
          width: '100%',
          transition: 'height 0.3s ease-out',
          height: props.val + '%' ,
          backgroundColor: props.color
        }
      }>
        &nbsp;
      </div>

    </div>
  );
}


export class JobMetrics extends Component
{
  static styleGraph = {};

  constructor(props)
  {
    super(props);
    this.state =
    {
      metrics: props.metrics
    };

    JobMetrics.styleGraph =
    {
      minHeight: 150,
      overflowX: 'auto',
      overflowY: 'hidden',
      ...Styles.InlineFlexRow,
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    };
  }

  render()
  {

    //pass our "this" back to the parent, so it can call setState() as needed
    this.props.giveThis(this);

    // Calculate bar width as available-width / nodes
    const iTotalWidth = this.props.width - LayoutDims.nMargin * 8;
    const nNodes = this.state.metrics.itemized.cpu_used.length;

    // Reduce the bar width by slightly more than the margin
    let iBarWidth = (iTotalWidth / nNodes) | 0;
    iBarWidth -= LayoutDims.nMargin * 1.25;

    // Set minimum width to 16px
    if(iBarWidth < 16) iBarWidth = 16;

    const sMemUsed = asGB(this.state.metrics.summary.memory_used);
    const sMemTotal = asGB(this.state.metrics.summary.memory_total, true);
    const sMemSummary = sMemUsed + ' of ' + sMemTotal + 'GB';

    return (
      <div style={{margin: LayoutDims.nMargin}}>

        <b>CPU utilization (per node)</b>
        <HLine margin={0}/>

        <div style={{overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%'}}>
          <div style={JobMetrics.styleGraph}>
            {
              this.state.metrics.itemized.cpu_used.map
              (
                (n, i) =>
                  <Bar val={n}
                       key={i}
                       width={iBarWidth}
                       color={Colors.clrNimbixDark}
                       title={`Node ${i}\n${n}% CPU usage`}
                  />
              )
            }
          </div>

          <Separator/>

          <b>Memory utilization (per node)</b>
          <HLine margin={0}/>

          <div style={JobMetrics.styleGraph}>
            {
              this.state.metrics.itemized.memory_used.map
              (
                (n, i) =>
                {
                  const nTotal = this.state.metrics.itemized.memory_total[i];
                  const pct = (n * 100 / nTotal) | 0;
                  return (
                    <Bar key={i}
                         width={iBarWidth}
                         val={pct}
                         color={Colors.clrNimbixDarkGreen}
                         title={`Node ${i}\n${pct}% memory usage (${asGB(n)} of ${asGB(nTotal, true)} GB)`}
                    />
                  );
                }
              )
            }
          </div>

        </div>

        <Separator units={2}/>

        <b>Summary</b>
        <HLine margin={0}/>

        <div style={{fontSize: LayoutDims.FontSize}}>
          <div>
            <p>Avg. CPU usage: <b>{this.state.metrics.summary.cpu_used}%</b></p>
            <p>Memory usage: <b>{sMemSummary}</b></p>
          </div>
        </div>

      </div>
    );
  }
}

