import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {red900} from 'material-ui/styles/colors';

import {LayoutDims, Styles, Colors, JobStatusStyles} from './UIConst.js';
import {HLine, Icon, Separator, CloneAppBtn, AppIcon, dateToLocalTimeStr} from './Components';

import {AppsData, AppNAE} from '../Models/AppsData';
import {GetAppImgSrc, GetPortalAuthURL, StateFetcher, DataFetcher} from '../Models/Model';
import {Spinner} from '../Global';

export class JobHistoryEntry extends Component
{
  static propTypes =
  {
    job: PropTypes.object,
  };

  static TailLines = 500;

  static styleTD = null;
  static styleInfo = null;
  static styleOutput = null;

  constructor(props)
  {
    super(props);
    this.state =
    {
      job: props.job,
      appImgSrc: GetAppImgSrc(props.job.job_application),
      showDetails: false,
      tailText: null,
      tailTextError: false,
      killing: false,
      zombie: false,
      showKill: props.showKill
    };

    this.firstTime = true;
  }

  getStatusText = (status) =>
  {
    // Convert "SUBMITTED" to "queued" and chop all but the first word off
    return status === 'SUBMITTED' ? 'queued...' : status.split(' ')[0].toLowerCase();
  }

  toggleDetails = () =>
  {
    this.setState({showDetails: !this.state.showDetails});
    if(!this.state.tailText)
    {
      new StateFetcher
      (
        '/portal/output-list',
        {lines: JobHistoryEntry.TailLines, jobname: this.props.job.job_name},
        'result'
      )
      .fetchUpdate
      (
        this,
        'tailText',
        null,
        null,
        () => this.setState({tailTextError : true})
      );
    }
  }

  jobKill = () =>
  {
    const url = '/portal/job-terminate';
    const jobKiller = new DataFetcher(url, {jobname: this.props.job.job_name});
    jobKiller.ifFail
    (
      () => this.setState({showKill: false, killing: false, zombie: true})
    )
    .whenDone
    (
      () => this.setState({showKill: false, killing: false})
    )
    .fetch();
  }

  // When icon is loaded, adjust its size
  onLoadIcon(elemImg)
  {
    // Get width and height
    let w = elemImg.width, h = elemImg.height;

    // Scale image so that height is styleIconBox.height
    const availH = JobHistoryEntry.styleIconBox.height - 4;
    const availW = JobHistoryEntry.styleIconBox.width - 4;

    let scale = availH / h;
    let newW = w * scale;
    let newH = availH;

    // If scaling leaves the width > styleIconBox.width
    // Try scaling it down further (maximum of 3)
    // Center it vertically
    if(newW > availW)
    {
      let scale = Math.min(w / availW, 3);
      newW /= scale;
      newH /= scale;
      elemImg.style.top = `${availH - newH - LayoutDims.nMargin / 2}px`;
      elemImg.style.position = 'relative';
    }

    elemImg.width = newW | 1;
    elemImg.height = newH | 1;
  }

  render()
  {
    // Init static styles
    if(!JobHistoryEntry.styleTD)
    {
      JobHistoryEntry.styleTD =
      {
        ...Styles.TableCell,
        verticalAlign: 'bottom',
        paddingBottom: LayoutDims.nMargin/2
      };

      JobHistoryEntry.styleInfo =
      {
        ...JobHistoryEntry.styleTD,
        userSelect: 'none',
        cursor:'pointer',
        paddingBottom: LayoutDims.nMargin
      };

      JobHistoryEntry.styleOutput =
      {
        ...Styles.Tail(25),
        width: (LayoutDims.Scroll + LayoutDims.Tail80Column)|0,
        overflow: 'auto',
      };

      JobHistoryEntry.styleIconBox =
      {
        // Maximum width ~128
        width: LayoutDims.nIconSize * 2,
        maxWidth: LayoutDims.nIconSize * 2,
        height: LayoutDims.nIconSize * 0.75,
        minHeight: LayoutDims.nIconSize * 0.75,

        overflow: 'hidden'
      };
    }

    // If the app is NAE then use a default NAE App data
    const sAppName = this.state.job.job_application;
    const isNAE = sAppName === 'NAE';
    let dctApp = isNAE ? AppNAE : AppsData[sAppName];

    if(!dctApp)
    {
      dctApp = {data: {name: sAppName}}
    }

    const job = this.props.job;
    let sStatus = (job && job.job_status && job.job_status.toLowerCase()) || 'Unknown';

    if(this.state.killing)
    {
      sStatus = 'Aborting...'
    }
    else if(this.state.zombie)
    {
      sStatus = 'Abort failed...'
    }

    // Split the output into lines and add a dummy line to maintain correct width
    const arrLines = this.state.tailText || [];
    arrLines.push((new Array(80)).join("\u00A0"));

    if(this.state.tailText && this.firstTime)
    {
      window.setTimeout
      (
        () => this.refs.outputView && (this.refs.outputView.scrollTop = this.refs.outputView.scrollHeight),
        200
      );

      this.firstTime = false;
    }


    const styleJobNum =
    {
      ...JobHistoryEntry.styleTD,
      paddingLeft: LayoutDims.nMargin
    };

    const styleJobNumTitle =
    {
      ...JobHistoryEntry.styleTD,
      paddingRight: 0
    };

    return (
      <div style={{margin: LayoutDims.nMargin}} title={job.job_substatus_text || ''}>
        <Separator/>

        <div style={{padding: LayoutDims.nMargin, paddingTop:0}}>
          <table style={{...Styles.Table, tableLayout :'fixed'}} >
            <colgroup>
              <col style={{width: '20%'}}/>
              <col style={{width: '15%'}}/>
              <col style={{width: '30%'}}/>
              <col style={{width: '25%'}}/>

            </colgroup>

            <tbody>

              <tr data-cy='jobHistoryEntry' data-jobnum={job.job_number} data-job-status={sStatus}>
                <td style={{...JobHistoryEntry.styleTD, paddingBottom: 0}}>
                  <div style={{...Styles.FlexSpread, alignItems: 'flex-end', flexFlow: 'row nowrap'}}>
                    <div style={JobHistoryEntry.styleIconBox}>
                      <AppIcon app={sAppName} onLoad={this.onLoadIcon}/>
                    </div>
                    <div style={styleJobNumTitle}><b>Job&nbsp;No:</b></div>
                  </div>
                </td>

                <td style={styleJobNum}>
                  {job.job_number || 'Unknown'}
                </td>

                <td style={{...JobHistoryEntry.styleTD}}>
                  {
                    dctApp.data.name
                    ?
                      (dctApp.data.name + ' : ' + job.job_command)
                    :
                      'Unknown'
                  }
                </td>

                <td style={{...JobHistoryEntry.styleTD, ...JobStatusStyles[sStatus], textTransform:'capitalize'}}>
                  <div style={Styles.Inline}>
                  {
                    (sStatus === 'shutting down... ') && this.state.showKill &&
                    <div onClick={this.jobKill} title='Abort'>
                      {Icon('clear', {fontSize: 24, color: red900, marginTop:4, cursor: 'pointer'})}
                    </div>
                  }

                  {sStatus}

                  {
                    sStatus === 'shutting down... ' &&
                      <Spinner style={{width:16, marginRight: LayoutDims.nMargin*2}} size={16} status=''/>
                  }

                  </div>
                </td>

                <td style={JobHistoryEntry.styleTD}>
                  <CloneAppBtn size={24} jobnum={job.job_number}/>
                </td>


                <td style={JobHistoryEntry.styleTD}>
                  <div data-cy='divJobEntryDetails' onClick={this.toggleDetails} style={{...JobHistoryEntry.styleInfo}}>
                    { this.state.showDetails ? <div>&#x25B2;</div> : <div>&#x25BC;</div>}
                  </div>
                </td>

              </tr>

              {
                job.job_label &&
                <tr key='joblabel'>
                  <td style={{...Styles.TableCell, textAlign: 'right'}}><b>Job Label:</b></td>
                  <td colSpan='4' style={{...Styles.TableCell, wordBreak: 'break-all'}}>
                    {job.job_label}
                  </td>
                </tr>
              }

              {
                job.job_project &&
                <tr key='jobproject'>
                  <td style={{...Styles.TableCell, textAlign: 'right'}}><b>Job Project:</b></td>
                  <td style={Styles.TableCell}>
                    {job.job_project}
                  </td>
                </tr>
              }

              {
                this.state.showDetails &&
                [
                  job.job_substatus_text
                  ?
                    <tr key='jobsubstatus'>
                      <td style={{...Styles.TableCell, textAlign: 'right'}}><b>Substatus:</b></td>
                      <td colSpan='2' style={{...Styles.TableCell, color: Colors.clrNimbixErr}}>
                        {job.job_substatus_text}
                      </td>
                    </tr>
                  :
                    null,

                  <tr key='jobstarttime'>
                    <td style={{...Styles.TableCell, textAlign: 'right'}}><b>Start time:</b></td>
                    <td colSpan='2' style={Styles.TableCell}>
                      {dateToLocalTimeStr(job.job_start_time)}
                    </td>
                  </tr>,

                  <tr key='jobstoptime'>
                    <td style={{...Styles.TableCell, textAlign: 'right'}}><b>Stop time:</b></td>
                    <td colSpan='2' style={Styles.TableCell}>
                      {dateToLocalTimeStr(job.job_end_time)}
                    </td>
                  </tr>,

                  <tr key='joboutput'>
                    <td style={{...Styles.TableCell, verticalAlign: 'top', textAlign: 'right'}}>
                      <b>Output:</b>
                    </td>
                    <td colSpan='3'>
                    {
                      !this.state.tailTextError &&
                      <div style={{...Styles.Inline, alignItems: 'top'}}>
                        <div ref='outputView' style={JobHistoryEntry.styleOutput}>
                          {
                            arrLines.length === 501 &&
                            <pre style={{...Styles.TailLine, color: '#FFFF00'}}>
                            -- Only a maximum of 500 lines shown --
                            </pre>
                          }

                          {
                            // Key is job.job_number + ':' + i because
                            // A given jobs certain row of output is forever unchanging
                            arrLines.map((v, i)=><pre style={Styles.TailLine} key={job.job_number + ':' + i}>{v}</pre>)
                          }

                          {
                            !this.state.tailText &&
                            <Spinner style={Styles.Full} size={64} status='Fetching...'/>
                          }

                        </div>

                        <div style={{zIndex: 1, margin: 16, marginLeft: -64}}>
                          <a title="Download Complete Output"
                             href={GetPortalAuthURL('/portal/job-download-output?jobname=' + job.job_name)}>
                            {Icon('system_update_alt', {color: '#FFFF00'})}
                          </a>
                        </div>

                        <Separator/>
                      </div>
                    }

                    {
                      this.state.tailTextError &&
                      <div className='errortext' style={{paddingLeft: LayoutDims.nMargin}}>
                        Error fetching data...
                      </div>
                    }

                    </td>
                  </tr>
                ]
              }

            </tbody>
          </table>

          <Separator/>
          <HLine margin={0}/>
          <Separator/>


        </div>

      </div>
    );
  }
}

