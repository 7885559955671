import React from 'react';

import {ColGroup} from './Components';
import {dateToLocalTimeStr} from './Utils';
import {Data} from '../Models/Model';
import {AdminFilteredList, makeDataRows} from './AdminFilteredList';


// Component that shows the audit logs for team admins and sys admins
// Shares most of the code with AdminJobs via AdminFilteredList
// Team admins will see only their teams logs
export default class AdminLogs extends AdminFilteredList {

  // Set up the UI for Admins or Team admins based on who we are
  componentWillMount()
  {
    if(this.props.logrole === 'team')
    {
      this.initTeamAdmin()
    }
    else
    {
      this.initAdmin()
    }

    // Common stuff

    // Params
    this.urlDataParams = {descending: true};

    // Under what key is the count of rows returned
    this.keyCount = 'limit';

    // Option list for audit log categories fetched at start
    this.dctOptions.category = ['All Categories']
    this.dctOptions.category.push(...Data.Audit.Categories.sort());

    // Sort col and direction
    this.sortCol = 0;
    this.sortDesc = true;

    // Portal will add the filter for the "payer" field for team admins
    this.refreshData()
  }

  // Changes filter string
  translateFilter()
  {
    // "all" becomes empty filter
    if(this.filter['category'] === this.dctOptions['category'][0])
    {
      delete this.filter['category'];
    }
  }

  // Set up UI for sysadmins
  initAdmin()
  {
    // Override the columns, filters and titles
    this.arrColFields = ['logtime', 'user', 'payer', 'category', 'resource', 'message'];
    this.arrFilters = ['', 'user', 'payer', 'category', 'resource', 'message'];
    this.arrColText = ['Time', 'User', 'Payer', 'Category', 'Resource', 'Message'];

    // URL used to fetch the count
    this.urlCount = '/portal/admin-audit-count';

    // URL used to fetch the data
    this.urlData = '/portal/admin-audit-query';

    // Prefix used for the refs of filter edit boxes
    this.filterName = 'auditadminfilter';

    // Column dimensions
    this.colGroup = <ColGroup cols={[15, 20, 10, 10, 15, 30]}/>

  }

  // Setup UI for team admins
  initTeamAdmin()
  {
    // Override the columns, filters and titles
    this.arrColFields = ['logtime', 'user', 'category', 'resource', 'message'];
    this.arrFilters = ['', 'user', 'category', 'resource', ''];
    this.arrColText = ['Time', 'User', 'Category', 'Resource', 'Message'];

    // URL used to fetch the count
    this.urlCount = '/portal/team-audit-count';

    // URL used to fetch the data
    this.urlData = '/portal/team-audit-query';

    // Prefix used for the refs of filter edit boxes
    this.filterName = 'auditteamfilter';


    // Column dimensions
    this.colGroup = <ColGroup cols={[15, 25, 10, 20, 30]}/>
  }

  // Overridden methods

  // Called to get the count from the result of the request that fetches the count
  getCount(fetcher)
  {
    return fetcher.data.result;
  }

  // Called to construct the data row elements given the filtered data
  getDataRowElems(arrFilterData)
  {
    return makeDataRows
    (
      null,
      arrFilterData,
      this.arrColFields,
      {},
      'logtime'
    );
  }

  // Called to get the column group component for the view
  getColGroup()
  {
    return this.colGroup;
  }

  // Called to get the data rows from the fetcher
  getRows(fetcher)
  {
    const arrIDs = Object.keys(fetcher.data.result);
    return arrIDs.map
    (
      (id) =>
      {
        const data = fetcher.data.result[id];
        data.logtime = dateToLocalTimeStr(data.logtime);
        return data;
      }
    );
  }

}
