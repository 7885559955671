import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import {Tabs, Tab} from 'material-ui/Tabs';

import {Separator, ColGroup, Icon} from './Components';
import {Styles, LayoutDims, Btns, PaletteMain} from './UIConst';
import {Colors} from './UIConst';
import {Spinner, FetchBusy, GlobalUI, userImpersonate} from '../Global';
import {DataFetcher, StateFetcher, Data, GetPortalAuthURL, AuditLog, JobSuspSubstatuses, JobSubstatus} from '../Models/Model';
import {dateToLocalTimeStr, getEscapedDataURI} from './Utils'
import {AdminFilteredList, makeDataRows} from './AdminFilteredList';


const SubStatusColors = {
  [JobSubstatus.SUBSTATUS_LICENSE_FEATURES]: Colors.clrNimbixOrangeTrans,
  [JobSubstatus.SUBSTATUS_SUSPENDED_USER]: Colors.clrNimbixBlueTrans,
  [JobSubstatus.SUBSTATUS_SUSPENDED_TEMP_LM]: Colors.clrNimbixRedTrans,
  [JobSubstatus.SUBSTATUS_LIMITS]: Colors.clrNimbixYellowTrans,
}

const PreText = (props) =>
{
  const n = LayoutDims.nMargin;
  return (
    <div style={{...Styles.Bordered, minHeight: '100%', backgroundColor: Colors.clrNimbixGray}}>
      {
        props.download &&
        <div style={{position: 'sticky', top: n, padding: n, width: n*4, left: `calc(100% - ${n*4}px)`}}
             title='download' onClick={()=>props.onClick(props)}>
          {Icon('system_update_alt', {color: Colors.clrNimbixDark, fontSize: n*4, cursor: 'pointer'})}
        </div>
      }
      <pre style={{overflowX: 'auto', overflowY: 'hidden', fontSize: 14,
        color: PaletteMain.palette.textColor, margin: 0, marginTop: props.download ? -n*6 : 0, padding: n}}>
        {props.data}
      </pre>
    </div>
  );
}


class JobEvents extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {events: null, out: null, loading: true, tab: 'events', jobname: props.jobname, jobnum: props.jobnum}
  }

  // On mount refresh both
  componentDidMount()
  {
    this.onRefresh(null, 'events');
    this.onRefresh(null, 'output');
  }

  // If props change refresh
  componentWillReceiveProps(nextProps)
  {
    this.setState({jobnum: nextProps.jobnum, jobname: nextProps.jobname}, ()=>this.componentDidMount());
  }

  doScrollToBottom = () =>
  {
    if(this.refs.logView)
    {
      setTimeout(()=>{this.refs.logView.scrollTop = this.refs.logView.scrollHeight;}, 250)
    }
  }

  // This method is called both as an event handler and directly, so ignore the first param
  onRefresh = (_, tab) =>
  {
    // Get the tab and reset the error state
    if(!tab) tab = this.state.tab;
    const errField = `${tab}Error`;
    this.setState({[errField]: false, [tab]: null});

    // Fail handler set the error field false
    const onFail = ()=>this.setState({[errField]: true});

    // Create a fetcher and update
    const params = {jobname: this.state.jobname}
    const fetcher = tab === 'events'
    ?
      new StateFetcher('/portal/job-events', params)
    :
      new StateFetcher('/portal/runtime-tail', params, 'result')

    fetcher.fetchUpdate
    (
      this,
      tab,
      null,
      this.doScrollToBottom,
      onFail
    );
  }

  onDownload = () =>
  {
    this.refs.downloadLink.download = `${this.props.jobnum}-${this.state.tab}.txt`;
    this.refs.downloadLink.href = getEscapedDataURI(this.state[this.state.tab]);
    this.refs.downloadLink.click();
  }

  onChangeTab = (tab) =>
  {
    const oldTab = this.state.tab;
    this.setState({tab})
    if(oldTab !== tab)
    {
      this.doScrollToBottom();
    }
  }

  render()
  {
    const tab = this.state.tab;
    const errField = `${tab}Error`;
    const elemErr = this.state[errField] && <div style={{height:'100%', margin: 'auto'}}>Error fetching data</div>

    // If no events exist, show a message
    let sLog = this.state[tab];
    if(tab === 'events' && sLog === '""')
    {
      sLog = 'No events';
    }

    const elemContent =
      !this.state[errField] && sLog &&
      <pre style={{padding: LayoutDims.nMargin, margin:0,  fontSize: 16}}>
        {sLog}
      </pre>

    return(
      <div>

        <div style={{...Styles.Bordered, ...Styles.InlineFlexCol,
          alignItems: 'stretch', width:'100%', maxHeight: 400, minHeight: 400}}>
          <Tabs  ref='tabs' value={this.state.tab} onChange={this.onChangeTab}>
            <Tab label='Events' value='events'/>
            <Tab label='Output' value='output' />
          </Tabs>

          <div ref='logView' style={{flex:1, display: 'flex', overflow: 'auto', backgroundColor: Colors.clrNimbixGray}}>
          {
            elemErr ||
            elemContent ||
            <Spinner size={64} style={{...Styles.Full, marginTop: '10%'}} textColor={Colors.clrNimbixDark} status='Loading...'/>
          }
          </div>
        </div>

        <Separator units={2}/>
        <div style={Styles.FlexSpread}>
          <a ref='downloadLink'/>
          <div>
            <RaisedButton {...Btns.Blue} disabled={!elemContent} onClick={this.onDownload} label='Download'/>
            &nbsp;&nbsp;
            <RaisedButton {...Btns.Green} onClick={this.onRefresh} label='Refresh'/>
          </div>
        </div>
      </div>
    );
  }
}

// Job detail view
class JobDetails extends Component
{

  static FieldNames = {
    job_name:              'Name',
    job_sched_id:          'Cluster ID',
    job_sched_job_id:      'Sched job id',
    job_owner_username:    'Owner username',
    job_application:       'Application',
    job_status:            'Status',
    job_submit_time:       'Submit time',
    job_label:             'Label',
    job_start_time:        'Start time',
    job_end_time:          'End time',
    job_exitcode:          'Exit code',
    job_walltime:          'Wall time',
    job_dashboard_visible: 'Dashboard visible',
    job_after:             'After',
    job_price:             'Price',
    job_nodes:             'Nodes',
    job_api_submission:    'Submission',
    job_substatus_text:    'Substatus text',
  };

  constructor(props)
  {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  componentWillReceiveProps(nextProps)
  {
    this.setState(this.getStateFromProps(nextProps))
  }

  getStateFromProps = (props) =>
  {
    const data = this.makeData(props);
    return {
      data,
      blacklisted: props.data.blacklisted,
      jobnum: props.jobnum,
      submission: props.data.submission,
      jobsub: props.data.jobsub,
      audit: props.audit
    }
  }

  // makes the {fields {name: value, ...}} nested dict to a plain dict
  makeData = (props) =>
  {
    let data = {};
    for(const field of props.data.fields)
    {
      data[field.name] = field.value;
    }

    return data;
  }

  download = (stream) =>
  {
    const url = `/portal/admin-output-list?stream=${stream}&jobname=${this.state.data['job_name']}`;
    window.open(GetPortalAuthURL(url, '_blank'));
  }

  terminateJob = () =>
  {
    const data =
    {
      jobname: this.state.data['job_name'],
      job_owner_username: this.state.data['job_owner_username'],
    };

    GlobalUI.DialogConfirm.confirm
    (
      <div>
        Are you sure you want to terminate this job?<br/><br/>
      </div>,
      'Confirm Job Termination',
      () =>
      {
        const fetcher = new DataFetcher('/portal/job-terminate', data)
          .ifFail((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Error'))
          .whenDone
          (
            ()=>
            {
              AuditLog('job', this.state.jobnum, 'Terminated by Admin')
              this.props.onTerminate();
              this.setState({...this.state.data, 'job_status': 'TERMINATED'});
              AuditLog('job', this.state.jobnum, 'Terminated by Admin on behalf of ' + data.job_owner_username);
            }
          )


        FetchBusy(fetcher, 'Terminating job...');
      },
      null,
      'Yes',
      'No'
    );
  }

  blackListJobLabel = () =>
  {
    const data =
    {
      job_label: this.state.data['job_label'],
      user: this.state.data['job_owner_username'],
      blacklist: !this.state.blacklisted
    };

    const sMsg = this.state.blacklisted ?
      'Are you sure you want to remove the blacklist for this job label and user?'
      :
      'Are you sure you want to blacklist this job label for this user?';

    GlobalUI.DialogConfirm.confirm
    (
      <div>
        {sMsg}
        <br/>
        <br/>
      </div>,
      'Confirm',
      () =>
      {
        const fetcher = new DataFetcher('/portal/admin-job-blacklist', data)
          .ifFail ((jqXHR)=>GlobalUI.DialogConfirm.showErr(jqXHR, 'Error'))
          .whenDone(()=>{this.setState({blacklisted: data.blacklist})})
        FetchBusy(fetcher, 'Updating...');
      },
      null,
      'Yes',
      'No'
    );
  }

  downloadStdOut = () => this.download('out');
  downloadStdErr = () => this.download('err');


  doDownloadText = (props) =>
  {
    const fileContent = props.data;
    const link = this.refs.downloadLink;
    const blob = new Blob([fileContent ], { type: 'text/plain' });
    link.download = props.fileName;
    link.href = window.URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  }

  render()
  {
    const isRunning = ['SUBMITTED', 'PROCESSING STARTING'].indexOf(this.state.data['job_status']) >= 0;
    const sLabel = this.state.data.job_label;

    const elemDetails = Object.keys(this.state.data).map
    (
      (e) =>
      {
        let val = this.state.data[e];

        // Fields ending with _time are shown using local time format
        if(e.endsWith('_time'))
        {
          val = dateToLocalTimeStr(val);
        }
        // Nodes are shown with space delimit
        else if(e === 'job_nodes')
        {
          val = val.split(',').map((e, i)=><div key={i}>{e}</div>);
        }
        else if(e === 'job_sched_id')
        {
          // Treat None as 0
          if(val == null) val = 0;

          // Get the cluster, 0 is default
          const cluster = Data.Clusters[val]

          // Get desc if it exists
          const desc = cluster ? cluster.desc : '<Unknown>';

          // Format the string
          val = val + ' - ' + desc;
        }
        
        return (
          <tr key={e}>
            <td style={Styles.AdminTD}>{JobDetails.FieldNames[e]}</td>
            <td style={Styles.AdminTD}>{val}</td>
          </tr>
        );
      }
    );

    const elemLog = this.state.audit
      ?
        [
          <tr key='1'>
            <td colSpan='2' style={Styles.AdminTD}>Log</td>
          </tr>,
          <tr key='2'>
            <td colSpan='2' style={{...Styles.AdminTD, padding: 0}}>
              <PreText data={this.state.audit}/>
            </td>
          </tr>
        ]
      :
        null;

    return (
      <div>
        <div style={{maxHeight: 400, minHeight: 400, overflowY: 'auto', ...Styles.Bordered}}>
          <a ref='downloadLink'/>

          <table style={{...Styles.Table, tableLayout: 'fixed', width: '100%'}}>
            <colgroup>
              <col style={{width: '30%'}}/>
            </colgroup>

            <thead>
              <tr>
                <th style={{...Styles.TableHeader, fontSize: 12}}>Key</th>
                <th style={{...Styles.TableHeader, fontSize: 12}}>Value</th>
              </tr>
            </thead>

            <tbody>
              {elemDetails}
              {elemLog}

              <tr>
                <td colSpan='2' style={Styles.AdminTD}>API submission </td>
              </tr>

              <tr>
                <td colSpan='2' style={{...Styles.AdminTD, padding: 0}}>
                  <PreText download
                           onClick={this.doDownloadText}
                           data={this.state.submission}
                           fileName={this.state.jobnum + '-api_submission.txt'}/>
                </td>
              </tr>

              {
                this.state.jobsub !== '' &&
                [
                  <tr key='0'>
                    <td colSpan='2' style={Styles.AdminTD}>Submission data</td>
                  </tr>,
                  <tr key='1'>
                    <td colSpan='2' style={{...Styles.AdminTD, padding: 0}}>
                      <PreText download
                               onClick={this.doDownloadText}
                               data={this.state.jobsub}
                               fileName={this.state.jobnum +  '-sched_submission.txt'}/>
                        {}
                    </td>
                  </tr>
                ]
              }
            </tbody>
          </table>
        </div>

        <Separator units={2}/>

        <div style={Styles.FlexSpread}>
        {
          isRunning?
            <div> </div>
          :
            <div style={Styles.InlineFlexRow}>
              <RaisedButton {...Btns.Green} onClick={this.downloadStdOut} label='Download stdout'/>
              &nbsp;&nbsp;
              <RaisedButton {...Btns.Blue} onClick={this.downloadStdErr} label='Download stderr'/>
            </div>
        }

        {
          isRunning &&
            <RaisedButton {...Btns.DarkRed} onClick={this.terminateJob} label='Terminate'/>
        }

        {
          // Show black list button only if there is a label
          sLabel &&
          (
            !this.state.blacklisted
            ?
              <RaisedButton {...Btns.Gray} onClick={this.blackListJobLabel} label='Blacklist'/>
            :
              <RaisedButton onClick={this.blackListJobLabel} label='Remove Blacklist'/>
          )
        }

        </div>
      </div>
    );
  }
}

export default class AdminJobs extends AdminFilteredList
{
  static JobStatusActive = 'SUBMITTED,PROCESSING STARTING';

  constructor(props)
  {
    super(props);

    // Override the columns, filters and titles
    this.arrColFields = ['job_number', 'job_name', 'machine',     'job_owner_username', 'job_status'];
    this.arrFilters   = ['jobnum',     'jobname',  'job_mc_name', 'user',               'filter'];
    this.arrColText   = ['Job No.',    'Job Name', 'Machine',     'Owner',              'Status'];

    this.arrColTypes['job_number'] = 'number';

    this.dctOptions['job_status'] =
      ['* All Jobs', '* Active Jobs', '* Suspended Jobs',
       'COMPLETED', 'COMPLETED WITH ERROR', 'SUBMITTED', 'PROCESSING STARTING',
       'CANCELED', 'EXEMPT', 'SEQUENTIALLY QUEUED', 'TERMINATED']

    // These constants should match the above items indices
    this.dctOptionKeys = {
      ALL: 0,
      ACTIVE: 1,
      SUSPENDED: 2,
    }

    // Prefix used for the refs of filter edit boxes
    this.filterName = 'jobfilter';

    // URL used to fetch the count
    this.urlCount = null; // No longer needed

    // Params to be passed to above URL
    this.urlCountParams = null;

    // URL used to fetch the data
    this.urlData = '/portal/admin-jobs-list';


    // Under what key is the count of rows returned
    this.keyCount = 'count';

    // Sort col and direction
    this.sortCol = 0;
    this.sortDesc = true;

    // Extra action button
    this.fnExtraButton =
      () => <RaisedButton disabled={this.state.busy || !this.state.isActive || this.arrFilterData.length === 0}
                          {...Btns.DarkRed}
                          label='Terminate All'
                          data-cy='btnTerminateAll'
                          onClick={this.onTerminateAll}/>
  }

  componentWillMount()
  {
    this.refreshData()
  }

  // One extra column for action buttons
  fnExtraCol = (row) =>
  {
    return (
      row.job_status === 'PROCESSING STARTING' || row.job_status === 'SUBMITTED'
      ?
        <div style={{...Styles.Inline, cursor: 'pointer', justifyContent: 'space-around'}}>

          <div title='Terminate' onClick={this.onTerminate} data-cy={`job-${row.job_number}:${row.job_substatus}`}>
            {Icon('cancel', {color: Colors.clrNimbixDarkRed, fontSize: 14})}
          </div>

        </div>
      :
        null
    );
  }


  // Translates job status filter string for the special entries that start with *
  translateFilter()
  {
    // Get the index of the filters names and the key
    const filter = this.filter['filter'];
    const idxOpt = this.dctOptions['job_status'].indexOf(filter);

    // These three types need the filter to be translated
    switch(idxOpt)
    {
      case this.dctOptionKeys.ALL:
        delete this.filter['filter'];
        delete this.filter['substatus'];
      break;

      case this.dctOptionKeys.ACTIVE:
        this.filter['filter'] = AdminJobs.JobStatusActive;
        delete this.filter['substatus'];
      break;

      case this.dctOptionKeys.SUSPENDED:
        // For safety we wont assume that only active can be in suspended state
        delete this.filter['filter'];
        this.filter['substatus'] = JobSuspSubstatuses.join(',');
      break;

      default:
        // filter string needs no change
    }

    // Allow terminate all for any of submitted and processing
    const isActive = AdminJobs.JobStatusActive.indexOf(this.filter.filter) >= 0;
    this.setState({isActive});
  }

  // terminates jobs (if its only 1 job, singleJobnum has its number)
  doTerminate = (arrJobs, singleJobnum) =>
  {
    // 0 Limit means all visible on page
    const nJobs = arrJobs.length;
    const nLimit = Data.User.AdminJobTerminateLimit || nJobs;

    if(nJobs > nLimit)
    {
      GlobalUI.Dialog.show('Error', `Only a maximum of ${nLimit} jobs can be terminated`, false, LayoutDims.wContent * 0.5);
      return;
    }

    GlobalUI.Dialog.confirm
    (
      <div>
        Are you sure you want to terminate {singleJobnum ? 'job number: ' + singleJobnum : ' these ' + nJobs + ' jobs'}?
      </div>,
      'Confirm Job Termination',
      () =>
      {
        const params = {jobs: JSON.stringify(arrJobs)};
        const fetcher = new DataFetcher('/portal/admin-job-batch-terminate', params);
        fetcher.ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Failed to terminate jobs'));
        fetcher.whenDone
        (
          ()=>
          {
            if(fetcher.data.length)
            {
              GlobalUI.Dialog.show
              (
                'Error',
                <div>
                  The following jobs could not be terminated:
                  <div style={{
                    ...Styles.Bordered,
                    marginTop: LayoutDims.nMargin/2,
                    padding: LayoutDims.nMargin/2,
                    backgroundColor: Colors.clrNimbixGray,
                    fontFamily: 'monospace',
                    fontSize: 'large',
                    minHeight: 120,
                    whiteSpace: 'pre-wrap'}}>
                    {fetcher.data.map(e => e + '\n')}
                  </div>
                </div>,
                false,
                LayoutDims.wContent * 0.75
              );
            }

            this.refresh(1, false);
          }
        );
        FetchBusy(fetcher, 'Terminating...');
      },
      null,
      'Yes',
      'No',
      LayoutDims.wContent * 0.6
    );
  }

  // Terminate all visible jobs
  onTerminateAll = () =>
  {
    const arrJobs = this.arrFilterData.map(e => {return {name: e.job_name, user: e.job_owner_username}});
    this.doTerminate(arrJobs)
  }

  // Terminate one job
  onTerminate = (evt) =>
  {
    const elem = evt.target;
    const tr = elem.parentElement.parentElement.parentElement.parentElement;
    const num = tr.children[1].textContent;
    const name = tr.children[2].textContent;
    const user = tr.children[4].textContent;
    this.doTerminate([{name, user}], num);
  }

  onClickStatus = (evt) =>
  {
    const target = evt.target;
    let status = target.textContent;
    let refReason;
    const jobnum = evt.target.parentElement.getAttribute('name') | 0;

    const onCloseDlg = ()=>
    {
      GlobalUI.Dialog.onClose();
      GlobalUI.Dialog.clear();
    }

    const onChangeRadio = (s) => { status = s.target.value; }

    const onSetJobStatus = () =>
    {
      // Set reason only if not empty
      const params = {jobnum, status};
      const reason = refReason.value

      const fetcher = new DataFetcher('/portal/admin-job-set-status', params);
      fetcher.ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Failed to set job status'));
      fetcher.whenDone
      (
        () =>
        {
          let sMsg = 'Admin changed status to ' + status;
          if(reason)
          {
            sMsg += ': ' + reason;
          }
          AuditLog('job', jobnum, sMsg);

          target.textContent = status;
          onCloseDlg();
        }
      );

      FetchBusy(fetcher, 'Setting job status...');
    }

    GlobalUI.Dialog.show
    (
      'Set Job Status',
      <div>

        <div style={{...Styles.Inline, width: '100%', marginRight: LayoutDims.nMargin * 2, marginBottom: LayoutDims.nMargin * 2}}>
          Reason:&nbsp;&nbsp;
          <input style={{...Styles.ParamInput, flex: 1}}
                 ref={(ref) => refReason = ref}
          />
        </div>

        <div>
          <RadioButtonGroup name='job_status' defaultSelected={status} onChange={onChangeRadio}>
          {
            ['SUBMITTED', 'PROCESSING STARTING', 'COMPLETED', 'COMPLETED WITH ERROR',
              'TERMINATED', 'CANCELED', 'EXEMPT', 'SEQUENTIALLY QUEUED']
            .map
            (
              (s) =>
                <RadioButton key={s} value={s} label={s} style={Styles.Radio}/>
            )
          }
          </RadioButtonGroup>
        </div>

        <Separator/>

        <div style={Styles.FlexSpread}>
          <RaisedButton label='Dismiss' onClick={onCloseDlg} />
          <RaisedButton {...Btns.Green} onClick={onSetJobStatus} label='Apply' />
        </div>
      </div>,
      false,
      LayoutDims.wContent * 0.75,
      GlobalUI.Dialog.clear
    );
  }

  onClickJobNum = (evt) =>
  {
    const jobnum = evt.target.textContent | 0;

    // We need to fetch job details as well as the audit log, do it in parallel
    const fetcherJD = new DataFetcher('/portal/admin-job-details', {jobnum});
    fetcherJD.ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Failed to get job info'));

    const paramsAudit =
    {
      category: 'job',
      resource: jobnum,
      descending: true,
      textonly: true,
      payer: '',
      reltime: false,
    };

    const fetcherAudit = new DataFetcher('/portal/admin-audit-query', {...paramsAudit});

    // Terminate button handler
    const onTerminate = () =>
    {
      const jobs = [...this.state.rows];
      for(const job of jobs)
      {
        if((job.job_number|0) === jobnum)
        {
          job.job_status = 'TERMINATED';
          break;
        }
      }
      this.setState({jobs});
    }


    // Wait until both requests complete before showing the log
    let dataAudit = null, dataJobDetails = null;
    const onFetched = () =>
    {
      // Have to check against null because dataAudit may be an empty string
      if(dataAudit !== null && dataJobDetails !== null)
      {
        GlobalUI.Dialog.show
        (
          'Job data - ' + jobnum,
          <JobDetails onTerminate={onTerminate}
                      data={dataJobDetails}
                      audit={dataAudit}
                      jobnum={jobnum} />
        );
      }
    }

    // If audit fetching fails, pretend we succeeded anyway
    fetcherAudit.ifFail(() => dataAudit = '');

    // Launch both requests
    fetcherAudit.whenDone
    (
      (jqXHR) =>
      {
        dataAudit = jqXHR.responseJSON.result;
        onFetched()
      }
    ).fetch();

    fetcherJD.whenDone
    (
      (jqXHR)=>
      {
        dataJobDetails = jqXHR.responseJSON;
        onFetched();
      }
    );

    FetchBusy(fetcherJD, 'Fetching job details...');
  }

  onClickJobName = (evt) =>
  {
    const jobname = evt.target.textContent;
    const jobnum = evt.target.previousSibling.textContent
    GlobalUI.Dialog.show('Job logs - ' + jobnum, <JobEvents jobnum={jobnum} jobname={jobname} />);
  }

  onClickUser = (evt) =>
  {
    const user = evt.target.textContent;
    userImpersonate(user);
  }

  // Overridden methods

  // Called to get the count from the result of the request that fetches the count
  getCount(fetcher)
  {
    const hasFilter = this.hasFilter();
    return hasFilter ? fetcher.data.filtered : fetcher.data.count;
  }

  // Called to construct the data row elements given the filtered data
  getDataRowElems(arrFilterData)
  {
    const dctStyles =
    {
      job_status: {style: Styles.AdminTDClickable, onClick: this.onClickStatus},
      job_number: {style: Styles.AdminTDClickable, onClick: this.onClickJobNum},
      job_owner_username: {style: Styles.AdminTDClickable, onClick: this.onClickUser}
    }

    // job name is clickable iff in active view
    if(this.filter.filter === AdminJobs.JobStatusActive)
    {
      dctStyles['job_name'] = {style: Styles.AdminTDClickable, onClick: this.onClickJobName}
    }

    return makeDataRows
    (
      this.fnExtraCol,
      arrFilterData,
      this.arrColFields,
      dctStyles,
      'job_number',
      null,
      null,
      (job) => ({background: SubStatusColors[job['job_substatus']|0]})
    );
  }

  // Called to get the column group component for the view
  getColGroup()
  {
    return <ColGroup cols={[0, 10, 32, 15, 20, 20]} />;
  }

  // Called to get the data rows from the fetcher
  getRows(fetcher)
  {
    // We get an array of job objects from job-page-list
    const jobs = fetcher.data.jobs.map
    (
      e => (
      {
        job_number: String(e.job_number),
        job_name: e.job_name,
        job_mc_name: e.job_mc_name + ' [' + e.job_mc_scale + ']',
        job_owner_username: e.job_owner_username,
        job_status: e.job_status,
        job_substatus: String(e.job_substatus|0)}
      )
    )

    // Set the count if non zero
    if(fetcher.data.count)
    {
      this.total = fetcher.data.count;
      this.setState({pagecount: Math.ceil(fetcher.data.count / this.pagesize)});
    }

    return jobs;
  }

  // Check if
  beforeRefresh(page)
  {
    if(this.state.rows && this.state.rows.length)
    {
      // Add hint of whether were navigating fwd or back
      const jump = page - this.state.page;

      // Our rows are sorted arbitrarily, API expects a hint for the
      // highest and lowest job numbers on the page
      const arrJobNums = this.state.rows.map(e => e.job_number | 0);
      const highest = Math.max(...arrJobNums);
      const lowest = Math.min(...arrJobNums);

      // Add known count to the params if not going to first page
      const total = page === 1 ? 0: this.total;

      this.urlDataParams = {lowest, highest, jump, total};
    }
  }

}
