import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';

import {Styles, Btns, Colors, LayoutDims} from './UIConst';
import {FetchBusy, GlobalUI, Spinner, getXHRErr} from '../Global';
import {DataFetcher, Data, AuditLog} from '../Models/Model';
import {Separator, HLine, InputRow, LoginInputFieldRow, LoginInputPane,
  LoginSubmitPane, LoginErrField} from './Components';

import {cleanUserWhiteList, getHost} from './Utils';
import {checkTag} from './SettingsAuthCommon';


// Login test dialog
class LDAPTestPane extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {err: ' '}
  }

  onSubmit = () =>
  {
    const params =
    {
      user: this.refs.loginField.value,
      pass: this.refs.passwordField.value,
      tag: this.props.tag
    }

    // Test request
    const fetcher = new DataFetcher('/portal/ldap-test-login', params);
    fetcher.whenDone
    (
      // Show a confirmation dialog if succeeded
      ()=>
        GlobalUI.Dialog.show
        (
          'Information',
          <div>
            <p>LDAP Authentication completed successfully</p>
            <b style={{color: Colors.clrNimbixDark}}>{fetcher.data.dn}</b>
          </div>,
          false,
          LayoutDims.wContent * 0.8
        )
    );

    // Set the error message if failed
    fetcher.ifFail((jqXHR) => this.setState(getXHRErr(jqXHR)));

    // Before sending the request, clear the error message
    this.setState({err: ''});
    FetchBusy(fetcher, 'Testing Authentication...');
  }

  // Submit the active pane if enter is pressed
  onKeyPress = (evt) =>
  {
    if(evt.key === 'Enter')
    {
      this.onSubmit();
    }
  }

  render()
  {
    return (
      <div>
        <LoginInputPane ref='formPane'>
          <LoginInputFieldRow label='User name'>
            <input data-cy='inputLDAPTestUser' name='user' ref='loginField' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

          <LoginInputFieldRow label='Password'>
            <input data-cy='inputLDAPTestPassword' name='password' ref='passwordField' type='password' style={Styles.ParamInput}/>
          </LoginInputFieldRow>

        </LoginInputPane>

        <Separator units={0.5}/>

        <LoginErrField err={this.state.err}/>
        <LoginSubmitPane onClick={this.onSubmit} label='Test LDAP Login'/>
      </div>
    );
  }
}

export class SettingsLDAP extends Component
{
  static paramNames = ['tag', 'address', 'port', 'base_dn', 'bind_user', 'bind_pass', 'use_ssl', 'require_cert', 'primary_dn_attr'];
  static paramTypes = {use_ssl : 'boolean', require_cert: 'boolean'};

  constructor(props)
  {
    super(props);
    this.state =
    {
      tag: null, // Null indicates data being fetched
      changed: false,
    };

    this.sHost = getHost();

    this.dctLDAPParams = {payer: Data.User.Profile.payer || Data.User.Profile.user_login};

    // Setup a fetcher to get the data
    this.fetcher = new DataFetcher('/portal/ldap-get', this.dctLDAPParams);
    this.fetcher.whenDone(()=>this.onUpdate(this.fetcher.data));

    // If it fails set the values blank (tag != null means spinner vanishes)
    this.fetcher.ifFail(()=>this.onUpdate({address: '', tag: '', base_dn: '', bind_user: '', bind_pass: '', primary_dn_attr: ''}));
  }

  // Fetch data
  refresh = () =>
  {
    // set tag to null causes the spinner to show
    this.setState({tag: null});
    this.fetcher.fetch();
  }

  getLoginURL = () => `${this.sHost}/portal/ldap-login/${this.state.tag}`

  // When data arrives, put it in the input fields
  onUpdate = (data) =>
  {
    for(const k of SettingsLDAP.paramNames)
    {
      // Handle checkboxes specially
      if(SettingsLDAP.paramTypes[k] === 'boolean')
      {
        this.setState({[k]: data[k]})
      }
      else
      {
        this.refs[`ldap_${k}`].value = data[k];
      }
    }

    try
    {
      if(data.allowed && data.allowed.result)
      {
        this.refs.ldap_allowed.value = data.allowed.result.split(',');
      }
    }
    catch(e)
    {
      console.log('Invalid list of allowed users');
    }

    this.setState({tag: data.tag});
  }

  onDel = () =>
  {
    const fetcher = new DataFetcher('/portal/ldap-delete', this.dctLDAPParams)
      .ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Delete Failed'))
      .whenDone
      (
        () =>
        {
          AuditLog('settings', 'ldap', 'Deleted tag: ' + this.state.tag);

          // clear the input fields
          for(const k of SettingsLDAP.paramNames)
          {
            if(SettingsLDAP.paramTypes[k] === 'boolean')
            {
              this.setState({[k]: false})
            }
            else
            {
              this.refs[`ldap_${k}`].value = '';
            }
          }
          this.setState({tag: '', changed: false});
        }
      );

      FetchBusy(fetcher, 'Deleting...');
    }

  // Create, and edit use the same API ldapUpdate
  onSave = () =>
  {
    const data = {...this.dctLDAPParams};

    // Get data from UI
    for(const k of SettingsLDAP.paramNames)
    {
      // Handle checkboxes specially
      if(SettingsLDAP.paramTypes[k] === 'boolean')
      {
        data[k] = this.refs[`ldap_${k}`].isChecked();
      }
      else
      {
        data[k] = this.refs[`ldap_${k}`].value;
      }
    }

    // Even though DAL does it, clear password field if user field is cleared
    if(!data.bind_user) this.refs['ldap_bind_pass'].value = '';

    // Get cleaned up csv list of white-listed users, put it back in the UI
    data.allowed = cleanUserWhiteList(this.refs.ldap_allowed.value);
    this.refs.ldap_allowed.value = data.allowed;

    // Test tag with regex unless its a delete
    // checkTag shows an error dialog and returns false if invalid
    if(checkTag(data.tag))
    {
      const fetcher = new DataFetcher('/portal/ldap-update', data)
        .ifFail((jqXHR) => GlobalUI.DialogConfirm.showErr(jqXHR, 'Save Failed'))
        .whenDone
        (
          () =>
          {
            AuditLog('settings', 'ldap', 'Added tag: ' + data.tag);
            this.setState({tag: data.tag, changed: false});
          }
        );

      FetchBusy(fetcher, 'Saving...');
    }
  }

  onChange = () => this.setState({changed: true});

  onTest = () =>
  {
    GlobalUI.Dialog.show
    (
      'Test LDAP',
      <LDAPTestPane tag={this.state.tag} />,
      false,
      LayoutDims.wContent * 0.6
    );
  }

  onClickLogin = () =>
  {
    GlobalUI.Dialog.confirm
    (
      <div>You will be logged out of your current session - Do you wish to continue?</div>,
      'Confirm',
      ()=>window.open(this.getLoginURL(), '_blank')
    );
  }

  componentDidMount()
  {
    this.refresh();
  }

  render()
  {
    const hasTag = this.state.tag !== null;
    const sLoginURL = this.getLoginURL();

    return (
      <div style={{width: '100%'}}>
        {
          <div className={!hasTag ? 'show' : 'hide'}>
            <Separator units={12}/>
            <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}/>
          </div>
        }

        <div className={hasTag ? 'show' : 'hide'}>
          <InputRow width='60%' title='Tag'>
            <input data-cy='inputLDAPTag'
                   ref='ldap_tag'
                   placeholder='Enter a string that will be used to build the AD login URL for Jarvice MC'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>


          <InputRow width='90%' title='Directory Server Address'>

            <input data-cy='inputLDAPAddress'
                   ref='ldap_address'
                   type='uri'
                   spellCheck='false'
                   autoComplete='off'
                   role='presentation'
                   placeholder='Enter the LDAP server address e.g. ldap.acme.com'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>


          <InputRow width='10%' title='Port - A value of 0 or blank will use the defaults 389 and 636 for ldap:// and ldaps:// respectively'>
            <input data-cy='inputLDAPPort'
                   ref='ldap_port'
                   type='number'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>

          <InputRow width='30%'>
            <Checkbox data-cy='checkLDAPSSL'
                      ref='ldap_use_ssl'
                      checked={this.state.use_ssl}
                      onCheck={()=>this.setState({changed: true, use_ssl: !this.state.use_ssl})}
                      label='Use SSL (ldaps://)' />
          </InputRow>

          <InputRow width='30%'>
            <Checkbox data-cy='checkLDARequireCert'
                      ref='ldap_require_cert'
                      checked={this.state.require_cert}
                      onCheck={()=>this.setState({changed: true, require_cert: !this.state.require_cert})}
                      label='Require Valid Certificate' />
          </InputRow>

          <Separator units={4}/>

          <InputRow width='90%' title='Base DN'>
            <input data-cy='inputLDAPBaseDN'
                   ref='ldap_base_dn'
                   spellCheck='false'
                   onChange={this.onChange}
                   style={Styles.ParamInputWide}/>
          </InputRow>

          <div>
            <InputRow width='50%' title='Bind User (full UPN of service account)'>
              <input data-cy='inputLDAPBindUser'
                     ref='ldap_bind_user'
                     spellCheck='false'
                     role='presentation'
                     onChange={this.onChange}
                     style={Styles.ParamInputWide}/>
            </InputRow>

            <InputRow width='20%' title='Bind Password'>
              <input data-cy='inputLDAPBindPassword'
                     ref='ldap_bind_pass'
                     type='password'
                     role='presentation'
                     onChange={this.onChange}
                     style={Styles.ParamInputWide}/>
            </InputRow>

            <InputRow width='50%' title='Primary DN attribute'>
              <input data-cy='inputLDAPPrimaryDNAttr'
                     ref='ldap_primary_dn_attr'
                     role='presentation'
                     placeholder='Leave blank for default value "CN"'
                     onChange={this.onChange}
                     style={Styles.ParamInputWide}/>
            </InputRow>


          </div>

          <InputRow width='90%' title='List of authorized users (comma or newline separated, must match AD user IDs exactly); leave completely blank to authorize any authenticated user'>
            <textarea data-cy='inputLDAPAllowedUsers' style={{...Styles.Full, height: 100}} onChange={this.onChange} ref='ldap_allowed'/>
          </InputRow>

          <Separator/>

          <div style={Styles.FlexSpread}>

            <div style={Styles.InlineFlexRow}>
              {
                hasTag &&
                <div>

                  <RaisedButton data-cy='btnLDAPConfigDelete' disabled={this.state.tag === ''} {...Btns.DarkRed} name='delete' label='Delete' onClick={this.onDel}/>
                  &nbsp;&nbsp;

                  {
                    <RaisedButton data-cy='btnTestLDAPConfig'
                                  {...Btns.Green}
                                  disabled={this.state.tag === '' || this.state.changed}
                                  title='Test AD Login with these settings'
                                  label='Test'
                                  onClick={this.onTest}/>
                  }

                </div>
              }
            </div>

            <RaisedButton disabled={!this.state.changed} {...Btns.Blue} label='Save' onClick={this.onSave}/>

          </div>


          <Separator units={3}/>
          <HLine margin={0}/>
          <Separator units={3}/>

          {
            // Show these urls only if data is fetched and also not blank
            hasTag && this.state.tag !== '' &&
            <div style={Styles.InlineFlexRow}>
              Auth URL: &nbsp;
              <div
                data-link={sLoginURL}
                data-cy='linkLDAPLoginURL'
                onClick={this.onClickLogin}
                style={{cursor:'pointer', color: Colors.clrNimbixMed}}>
              {sLoginURL}
              </div> <br/>
            </div>
          }

        </div>
      </div>
    );
  }
}
