// Core
import React, {Component} from 'react';

// Material UI Controls
import Divider from 'material-ui/Divider';
import {List, ListItem} from 'material-ui/List';

import {Styles} from './UIConst';
import {MUI, SwitchedPaneDrawerItems} from './Components';
import UserStatsPane from './UserStatsPane'
import {GlobalUI} from '../Global'

export default class JobDrawer extends Component
{
  render()
  {
    return MUI(
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', overflowY: 'auto', overflowX: 'hidden'}}>

        <List style={{paddingTop: 1}}>
          <ListItem disabled style={Styles.MenuTitle}>Jobs</ListItem>
          <Divider/>
          <SwitchedPaneDrawerItems contentPane={GlobalUI.Dashboard} paneGroup='DashboardContent'/>
        </List>

        <UserStatsPane/>

      </div>
    );
  }
}
