import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {List, ListItem} from 'material-ui/List';
import {Styles} from './UIConst';
import {MUI, SwitchedPaneDrawerItems} from './Components';

export default class GenericDrawer extends PureComponent
{
  static propTypes =
  {
    title: PropTypes.string.isRequired,
    paneGroup: PropTypes.string.isRequired,
    contentPane: PropTypes.object.isRequired,
  };

  render()
  {
    const style =
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      overflowY: 'auto'
    }

    return MUI(
      <div style={style}>
        <List style={{paddingTop: 1, flex: 1}}>
          {
            this.props.title &&
            <ListItem disabled style={Styles.MenuTitle}>
              {this.props.title}
            </ListItem>
          }

          <SwitchedPaneDrawerItems contentPane={this.props.contentPane}
                                   paneGroup={this.props.paneGroup}/>
        </List>
      </div>
    );
  }
}
