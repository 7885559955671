import {darkBlack, grey300, white} from 'material-ui/styles/colors';
import {Elem} from '../Global';

const Logo = '../static/images/logo.png';
const FavIcon = '../static/images/favicon.png';
const DefaultIcon = '../static/images/default.png';


export const LayoutDims =
{
  hAppBar: 64,
  nMargin: 8,
  nIconSize: 64,
  DefScreenShotWidth: 960,
  FontSize: 16,
  TailFontSize: 12.5,
  wContent: null,
};

// COLORS

// Three shades of blue
export const Colors =
{
  clrNimbixLite: "#3a87ad",
  clrNimbixMed: "#0c8db6",
  clrNimbixDark: "#003366",
  clrNimbixDarker: "#002050",
  clrNimbixDarkGreen: "#006633",
  clrNimbixErr: "#EE0000",
  clrNimbixDarkTrans: "rgba(0, 51, 102, 0.5)",
  clrNimbixGray: "#EEEEEE",
  clrNimbixDarkGray: "#888888",
  clrNimbixBlueGray: "#ddddee",
  clrNimbixDarkRed: "#802020",
  clrNimbixSearch: "#FFFF00",
  clrLine: clrTrans("#003366", 0.2),

  // Row highlights
  clrNimbixYellowTrans: "rgba(255, 255, 120)",
  clrNimbixOrangeTrans: "rgb(255, 200, 120)",
  clrNimbixRedTrans:    "rgb(255, 200, 200)",
  clrNimbixBlueTrans:   "rgba(0, 200, 255, 0.1)",
};

export const Images =
{
  Logo: Logo,
  DefaultIcon: DefaultIcon,
  FavIcon: FavIcon
};

export const JobStatusStyles = {}

export function clrTrans(clr, alpha)
{
  const bigint = parseInt(clr.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// See http://www.material-ui.com/#/customization/colors
export const PaletteMain =
{
  // Using primary colors for unknown elements so we can see what to change
  // Other nimbix palette colors are #003366 and #0c8db6
  palette:
  {

  },

  appBar:
  {
    height: LayoutDims.hAppBar,
  },

  toggle:
  {
    thumbOffColor: '#DDDDDD',
  },
};


// Predefined style classes (dynamic styles which depend on LayoutDims are added later in InitStyles() )
export const Styles =
{
  // Basic horizontal flex layout
  Inline:
  {
    display: 'inline-flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },

  // Nav drawer needs to align at the right edge of the appbar, but it doesn't extend till the bottom
  DrawerRight:
  {
    top: 'inherit',
    height: 'inherit',
  },

  // Area under app bar
  ClientArea:
  {
    position: 'absolute',
    display: 'inline-flex',
    width: '100%',
    height: `calc(100% - ${LayoutDims.hAppBar}px)`,
  },

  Table:
  {
    borderCollapse: 'collapse',
    textAlign: 'left',
    width: '100%',
  },
};


Styles.Tail = (lines) =>
{
  return {
    ...Styles.TailView, padding: 1,
    maxHeight: lines  * Styles.TailView.fontSize,
    height: lines * Styles.TailView.fontSize,
    float: 'left'
  };
}


// For colored raised buttons - e.g. <RaisedButton {...Btns.Blue} label='...'/>
export const Btns = {}


// Dimensions based on which the UI element positions and size are calculated
export const InitLayoutDims = (width, dims) =>
{
  // The default drawer widths are setup for a HD screen
  // If the width is more, add the excess amount to the drawers tro reach at most 352
  // Dont add unless were getting more than 24px each
  const extraWidth = width - 1500;
  const extraDrawer = extraWidth > 48 ? extraWidth / 2 : 0;

  dims.AppEntryWidth = 192;
  dims.AppEntryHeight = dims.AppEntryWidth * 0.618;
  dims.AppEntryRow =  (dims.AppEntryWidth * 0.618) + (8 * 2.5);  // how much space an appview row takes

  dims.wDrawerRight = dims.AppEntryWidth + dims.nMargin * 6;
  dims.wDrawerLeft = dims.AppEntryWidth + dims.nMargin * 6;

  dims.wDrawerLeft += extraDrawer;
  dims.wDrawerRight += extraDrawer;

  if(dims.wDrawerLeft > 320)  dims.wDrawerLeft = 320;
  if(dims.wDrawerRight > 320)  dims.wDrawerRight = 320;
  dims.FontSize = extraDrawer > 0 ? 18 : 15;

  // The app bar title should extend the drawer width
  // the Breadcrumb button is as wide as the appbar is high, so adjust that much
  dims.wAppBarTitle = dims.wDrawerLeft - dims.hAppBar;

  // Set the font size for that title such that 'PushToCompute™' fits
  dims.TitleFontSize = 20;

  dims.Tail80Column = Elem('measure').offsetWidth;
  dims.Scroll = Elem('measurescroll').offsetWidth - Elem('measurescroll').clientWidth;

  if(width >= 1200)
  {
    dims.wContent = width - (dims.wDrawerLeft + dims.wDrawerRight + dims.nMargin * 4);
  }
  else
  {
    dims.wContent = width - dims.nMargin * 4;
  }

  if(width < 800)
  {
    dims.Tail80Column *= 7.75/12.5;
    dims.TailFontSize = 8;
  }
  else if(width < 1280)
  {
    dims.Tail80Column *= 9.75/12.5;
    dims.TailFontSize = 10;
  }

  if(dims.wContent > 1200)
  {
    dims.wContent = 1200;
  }


  // Adjust for zoom rule in CSS
  if(width <= 640)
  {
    dims.wContent /= 0.8;
  }

  // Whether to open the drawers
  dims.bLeftOpen = width >= 1200;
  dims.bRightOpen = width >= 1200;
}

export const InitStyles = () =>
{
  Styles.Drawer =
  {
    top: 'inherit',
    left: 'inherit',
    height: `calc(100% - ${LayoutDims.hAppBar}px)`, // We need to keep the drawer not obscure the appbar
    maxHeight: `calc(100% - ${LayoutDims.hAppBar}px)`, // We need to keep the drawer not obscure the appbar
    backgroundColor: Colors.clrLight,
    zIndex: 1990,
  };

  Styles.Left =
  {
    position: 'absolute',
      left: `${LayoutDims.nMargin}px`,
    width: `${LayoutDims.wDrawerLeft}px)`,
  };

  Styles.Right =
  {
    position: 'absolute',
      width: `${LayoutDims.wDrawerRight}px`,
    left: `calc(100% - ${LayoutDims.wDrawerRight}px)`,
  };

  Styles.JobEntry =
  {
    width: LayoutDims.Tail80Column + LayoutDims.nMargin * 2.5,
    maxWidth: LayoutDims.Tail80Column + LayoutDims.nMargin * 2.5,
    margin: LayoutDims.nMargin * 2,
    padding: LayoutDims.nMargin,
    minHeight: 120,
    flexGrow: 0,
    color: Colors.clrNimbixDark,
  };

  Styles.Content =
  {
    width: LayoutDims.wContent,
    overflowY: 'auto',
    height: '100%'//`calc(100% - ${LayoutDims.hAppBar}px)`
  };

  Styles.TailFrame =
  {
    overflowX: 'auto',
    padding: 2,
    backgroundColor: 'black',
    width: LayoutDims.Tail80Column,
  };

  Styles.TailView =
  {
    backgroundColor: 'black',
    fontSize: LayoutDims.TailFontSize,
    letterSpacing: 0,
    color: 'greenYellow',
    overflow: 'hidden',
    margin: 0,
    padding: 0
  };

  Styles.TailLine =
  {
    fontFamily: 'Courier New',
    margin: 0,
    lineHeight: `${Styles.TailView.fontSize}px`,
  };

  Styles.AdminAppIcon =
  {
      width: LayoutDims.nIconSize * 0.75,
      height: LayoutDims.nIconSize * 0.75,
      marginRight:  LayoutDims.nMargin * 2,
      border: `1px solid ${clrTrans(Colors.clrNimbixDark, 0.1)}`,
  }
}


export const InitColors = (dctColors) =>
{
  // If specified, set the colors, else defaults are OK
  if(dctColors)
  {
    Colors.clrNimbixLite = dctColors.clrNimbixLite;
    Colors.clrNimbixMed = dctColors.clrNimbixMed;
    Colors.clrNimbixDark = dctColors.clrNimbixDark;
    Colors.clrNimbixDarker = dctColors.clrNimbixDarker;
    Colors.clrNimbixDarkGreen = dctColors.clrNimbixDarkGreen;
    Colors.clrNimbixErr = dctColors.clrNimbixErr;
    Colors.clrNimbixDarkTrans = dctColors.clrNimbixDarkTrans;
    Colors.clrNimbixGray = dctColors.clrNimbixGray;
    Colors.clrNimbixDarkGray = dctColors.clrNimbixDarkGray;
    Colors.clrNimbixBlueGray = dctColors.clrNimbixBlueGray;
    Colors.clrNimbixDarkRed = dctColors.clrNimbixDarkRed;
    Colors.clrNimbixSearch = dctColors.clrNimbixSearch;
    Colors.clrLine = clrTrans(dctColors.clrNimbixDark, 0.2);
  }

  PaletteMain.palette.primary1Color = Colors.clrNimbixDark;
  PaletteMain.palette.primary2Color = Colors.clrNimbixDark;
  PaletteMain.palette.primary3Color = '#CCCCCC';
  PaletteMain.palette.accent1Color = Colors.clrNimbixDark;
  PaletteMain.palette.accent2Color = Colors.clrNimbixLite;
  PaletteMain.palette.accent3Color = Colors.clrNimbixMed;
  PaletteMain.palette.textColor = darkBlack;
  PaletteMain.palette.alternateTextColor = white;
  PaletteMain.palette.canvasColor = 'rgba(0;0;0;0)';
  PaletteMain.palette.borderColor = grey300;
  PaletteMain.palette.disabledColor = '#DDDDDD';
  PaletteMain.palette.pickerHeaderColor = Colors.clrNimbixMed;
  PaletteMain.palette.clockCircleColor = Colors.clrNimbixMed;
  PaletteMain.palette.shadowColor = Colors.clrNimbixDark;
  Colors.clrLight = PaletteMain.palette.alternateTextColor;


  Styles.TableCell =
  {
    padding: 8,
    color: Colors.clrNimbixDark,
    fontSize: 16,
    //borderTop: `1px solid ${clrTrans(Colors.clrNimbixDark, 0.1)}`,
  };

  Styles.Input=
  {
    minWidth: 64,
    color: Colors.clrNimbixDark,
    outline: 'none',
    border: 'none',
    fontSize: 'larger',
    flex: 1,
    backgroundColor: 'inherit',
  };

  Styles.Bordered =
  {
    border: `1px solid ${clrTrans(Colors.clrNimbixDark, 0.3)}`,
  };

  Styles.ThickBordered=
  {
    border: `2px solid ${clrTrans(Colors.clrNimbixDark, 0.5)}`,
  };

  // Inline with content spaced out maximally
  Styles.FlexSpread = {...Styles.Inline, ...{justifyContent: 'space-between'}};

  Styles.TableCellBordered = {...Styles.TableCell, ...Styles.Bordered};

  Styles.TableHeader = {...Styles.TableCellBordered, backgroundColor: '#EEEEEE'};

  Styles.TableParams = {...Styles.Table,};

  Styles.TableHeaderTab =
  {
    fontSize: 14,
    padding: 8,
    margin: 8,
    borderBottom: `1px solid ${clrTrans(Colors.clrNimbixDark, 0.1)}`,
  };

// Style for input and range controls on task builder options
  Styles.ParamInput =
  {
    ...Styles.Input,
    ...Styles.Bordered,
    width: '50%',
    height: 24,
    padding: '2px 8px',
    fontSize: 16,
    backgroundColor: Colors.clrLight,
    margin: 0,
    boxSizing: 'content-box',
  };

  Styles.ParamInputThin =
  {
    ...Styles.Input,
    ...Styles.Bordered,
  };

  Styles.ParamSlider= {width: '80%' , marginBottom: LayoutDims.nMargin * 2}

  Styles.ParamInputWide = {...Styles.ParamInput, width: '90%'};

  Styles.DrawerNav =
  {
    ...Styles.Inline,
    height: '100%',
    maxHeight: '100%',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
  };

  Styles.MenuTitle =
  {
    color: Colors.clrLight,
    backgroundColor: Colors.clrNimbixDark,
    fontSize: 'large',
  };

  Styles.FullScreenFlexCenter =
  {
    position:'fixed',
    top:0,
    left:0,
    display: 'flex',
    width: '100%',
    height:'100%',
    alignItems:'center',
    justifyContent: 'center',
    zIndex: 1999
  }

  Styles.Radio = {marginBottom: 16};
  Styles.Title = {fontSize: 24, marginTop:48, marginBottom:0, color:Colors.clrNimbixDark};

  Styles.Full = {height: '100%', width: '100%'};

// Styles for selected and deselected rows
  Styles.TDSel = {...Styles.TableCellBordered,  color: Colors.clrLight};
  Styles.TDDesel = Styles.TableCellBordered;
  Styles.TRSel = {backgroundColor: Colors.clrNimbixMed};
  Styles.TRDesel = {};

  Styles.Selected = {backgroundColor:Colors.clrNimbixDark, color:Colors.clrLight};
  Styles.DeSelected = {backgroundColor:Colors.clrLight, color:Colors.clrNimbixDark};
  Styles.Blue = {color:Colors.clrNimbixDark};
  Styles.GrayBG = {backgroundColor:Colors.clrNimbixDarkGray};

  Styles.InlineFlexRow =
  {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  Styles.InlineFlexCol =
  {
    display: 'inline-flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  Styles.ModalPaper =
  {
    width:'100%',
    minHeight:180,
    height:'auto',
    background: Colors.clrLight,
    padding: LayoutDims.nMargin * 2,
    boxShadow: 'rgb(0, 0, 0) 8px 8px 12px 0px'
  }

  Styles.ListIcon = {marginRight: -LayoutDims.nMargin * 2, top: LayoutDims.nMargin * 1.5};

  Styles.AppFilterText = {fontSize: LayoutDims.FontSize ,marginLeft: -16};

  Styles.TableCellKey = Styles.TableCell;

  Styles.SmallInput =
  {
    border: 'none',
    outline: 'none',
    padding: 2,
    fontSize: 14,
    height: 24,
    backgroundColor: Colors.clrLight,
    margin: 0,
    width: '90%',
  };

  Styles.FullCenter =
  {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }

  Styles.PopupStyle =
  {
    backgroundColor: Colors.clrLight,
    color: Colors.clrNimbixDark,
    overflowY: 'auto',
  };

  Styles.AdminTD = {...Styles.TableCellBordered, fontSize: 12};
  Styles.AdminTDClickable = {...Styles.AdminTD, color: Colors.clrNimbixLite, cursor: 'pointer', wordWrap: 'anywhere'};

  Styles.Link = {color: Colors.clrNimbixLite, cursor: 'pointer', textDecoration: 'underline'};

  Styles.EditableHeaderFilterInput =
  {
    width: '100%',
    height: LayoutDims.nMargin * 3.5,
    outline: 0,
    padding: 0,
    border: 'hidden',
    backgroundColor:Colors.clrLight
  };

  Styles.EditableHeaderFilter =
  {
    padding: 2,
  }

  Styles.TableBtn =
  {
    width: '100%',
    fontsSize: LayoutDims.FontSize * 1.25,
    padding: 0,
    textAlign: 'center'
  };

  Styles.Highlight =
  {
    backgroundColor : Colors.clrNimbixSearch,
    color: Colors.clrNimbixDark

  }

  Btns.Blue = {backgroundColor:Colors.clrNimbixDark, labelColor: Colors.clrLight};
  Btns.Green = {backgroundColor:Colors.clrNimbixDarkGreen, labelColor: Colors.clrLight};
  Btns.Gray = {backgroundColor:Colors.clrNimbixDarkGray, labelColor: Colors.clrLight}
  Btns.DarkRed = {backgroundColor: Colors.clrNimbixDarkRed, labelColor: Colors.clrLight}

  JobStatusStyles['processing'] = {color: '#007700'};
  JobStatusStyles['completed'] = {color: Colors.clrNimbixDarkGreen};
  JobStatusStyles['completed with error'] = {color: Colors.clrNimbixErr};
  JobStatusStyles['terminated'] = {color: '#000000'};
  JobStatusStyles['canceled'] = {color: Colors.clrNimbixErr, textDecoration: 'line-through'};
  JobStatusStyles['queued'] = {color: '#EE6600' };
}

// Sets the branding images if specified
export const InitImages = (sImgDefault, sImgFavicon, sImgLogo) =>
{
  if(sImgDefault) Images.DefaultIcon = sImgDefault;
  if(sImgFavicon) Images.FavIcon = sImgFavicon;
  if(sImgLogo) Images.Logo = sImgLogo;
}

