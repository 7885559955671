// Core
import React, {Component} from 'react';

// Material UI Controls
import Divider from 'material-ui/Divider';

import {List, ListItem} from 'material-ui/List';
import {Tabs, Tab} from 'material-ui/Tabs';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';

import {FetchBusy, GlobalUI} from '../Global'

// Our custom components
import {MUI, Separator, FileChooser} from './Components';
import {Styles, Btns, LayoutDims} from './UIConst';
import {DataFetcher, Data, DockerInfoFetcher} from '../Models/Model';

export default class DockerLoginPane extends Component
{
  constructor(props)
  {
    super(props);
    this.state = this.getStateDict(Data.Docker.LoginInfo);
  }

  // Creates the state dictionary given the data dict
  getStateDict = (data) =>
  {
    return {
      useAccessToken: false,
      loginInfo: data,
      error: data ? '' : 'Logged out'
    }
  }

  componentDidMount()
  {
    // Subscribe to updates
    DockerInfoFetcher
      .whenDone(()=>this.setState(this.getStateDict(Data.Docker.LoginInfo)))
      .ifFail(()=>this.setState(this.getStateDict(null)))
  }

  dockerLogin = () =>
  {
    let login, password, server, v1;

    // If its docker use the first panes values
    if(this.refs.tabsAuth.state.selectedIndex === 0)
    {
      login = this.refs.dockerUser.value;
      password = this.refs.dockerPass.value;
      server = this.refs.dockerServer.value;
      v1 = !this.state.useAccessToken;
    }
    else // else use the JSON key stuff
    {
      server = this.refs.gcrServer.value;
      login = this.refs.gcrUser.value;
      password = this.jsonKey;
    }

    const fetcher = new DataFetcher('/portal/docker-login', {server, login, password, v1})
        .whenDone(()=>DockerInfoFetcher.fetch())
        .ifFail(()=>{this.setState({error: 'Login failed'});});
    FetchBusy(fetcher, 'Logging in to Docker Registry');
  }

  dockerLogout = () =>
  {
    const fetcher = new DataFetcher('/portal/docker-logout').whenDone(()=>DockerInfoFetcher.fetch());
    FetchBusy(fetcher, 'Logging out of Docker Registry');
    this.jsonKey = null;
  }

  // Called when the ... button is clicked
  onBrowse = () =>
  {
    if(window.Cypress)
    {
      this.onReadJSONKey();
    }
    else
    {
      this.refs.fileChooser.open();
    }
  }

  // Called when file is chosen (returning false cancels reading)
  onValidateJSONKeyFile = (file) =>
  {
    if(file.size > 65536)
    {
      GlobalUI.Dialog.show
      (
        'Error loading JSON Key',
        <div>
          Key file is too large!
          <br/>
          The maximum allowed size is 64 KB
        </div>,
        false,
        LayoutDims.wContent * 0.75
      );
      return false;
    }
    return true;
  }

  // Called when file is read by file chooser
  onReadJSONKey = (sData) =>
  {
    try
    {
      JSON.parse(sData);
      this.refs.gcrJSONFile.value = this.refs.fileChooser.refs.inputFile.files[0].name;
      this.jsonKey = sData;
    }
    catch(e)
    {
      GlobalUI.Dialog.show
      (
        'Error loading JSON Key',
        <div>
          Key file could not be loaded!
          <br/>
          File is not a valid JSON document
        </div>,
        false,
        LayoutDims.wContent * 0.75
      );
    }
  }

  onCheckAccessToken = (e, useAccessToken) => this.setState({useAccessToken});

  render()
  {
    const styleField =
    {
      ...Styles.Inline,
      marginBottom: 10
    }

    const styleLabel =
    {
      minWidth: 80,
      textAlign:'right',
      marginRight: 8
    };

    const loginElem = !this.state.loginInfo
      ?
      <ListItem disabled key='Login' innerDivStyle={{padding: LayoutDims.nMargin}}>

        <Tabs data-cy='tabRegistryUsePasswd' ref='tabsAuth'>
          <Tab label='Use Password'>
            <div style={{...Styles.Bordered, padding: LayoutDims.nMargin*2}}>
              <div style={styleField}>
                <div style={styleLabel}>Server:</div>
                <input defaultValue='https://index.docker.io' style={Styles.ParamInput} ref='dockerServer' />
              </div>

              <div style={styleField}>
                <div style={styleLabel}>Username:</div>
                <input data-cy='inputDockerUser' style={Styles.ParamInput} ref='dockerUser' type='input'/>
              </div>

              <div style={styleField}>
                <div style={styleLabel}>Password:</div>
                <input data-cy='inputDockerPass' style={Styles.ParamInput} ref='dockerPass' type='password'/>
              </div>

              <div style={styleField} ref='checkAccessToken' id='checkAccessToken'>
                <Checkbox label='Use access token instead of password'
                          onCheck={this.onCheckAccessToken}
                          value={this.state.useAccessToken}/>
              </div>

            </div>
          </Tab>

          <Tab data-cy='tabRegistryUseJSONKey' label='Use JSON Key'>
            <div style={{...Styles.Bordered, padding: LayoutDims.nMargin*2}}>
              <div style={styleField}>
                <div style={styleLabel}>Server:</div>
                <input defaultValue='https://us-docker.pkg.dev' style={Styles.ParamInput}
                       ref='gcrServer' />
              </div>

              <div style={styleField}>
                <div style={styleLabel}>Username:</div>
                <input defaultValue='_json_key' style={Styles.ParamInput}
                       ref='gcrUser' type='input'/>
              </div>

              <div style={styleField}>
                <div style={styleLabel}>Key:</div>
                <input readOnly='readOnly' placeholder='Select file (*.json)'
                       style={Styles.ParamInput} ref='gcrJSONFile' type='text'/>

                <button data-cy='buttonBrowseDockerJSON' style={{width: 28, height: 30}} onClick={this.onBrowse} title='Browse'>
                  ...
                </button>

                <FileChooser cyTag={'fileJsonCreds'} ref='fileChooser' asURL={false} onRead={this.onReadJSONKey}
                             filter='.json' validate={this.onValidateJSONKeyFile} />
              </div>
            </div>
          </Tab>
        </Tabs>

        <Separator units={1}/>
        <div style={{...Styles.FlexSpread, marginLeft: LayoutDims.nMargin}}>
          <div className='errortext'>
            {this.state.error}
          </div>
          <RaisedButton data-cy='buttonDockerLogin'
                        {...Btns.Blue}
                        style={{marginRight: LayoutDims.nMargin}}
                        onClick={this.dockerLogin}
                        label='Login'/>
        </div>
        <Separator units={1}/>

      </ListItem>
      :
      <ListItem disabled key='LoginInfo'>

        <div style={styleField}>
          <div>{`${this.state.loginInfo.username}@${this.state.loginInfo.server}`}</div>
        </div>

        <Separator units={0.5}/>
        <RaisedButton {...Btns.Blue} onClick={this.dockerLogout} style={{float: 'right'}} label='Logout'/>
        <Separator units={6}/>

      </ListItem>;

    return MUI(
      <List style={{paddingTop:0}}>
        <ListItem key='LoginTitle' disabled style={Styles.MenuTitle}>
          Docker Registry
        </ListItem>

        {loginElem}

        <Divider/>

        <Separator units={0.5}/>
      </List>
    );
  }
}
