// Core
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Our UI data, styles and colors
import {Styles} from './UIConst.js';

export class TaskBuilderParamRow extends Component
{
  static propTypes =
  {
    name: PropTypes.string.isRequired,
    description: PropTypes.any,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    elem: PropTypes.element,
  };

  render()
  {
    const elemStyle = {...Styles.TableCell, padding: this.props.padding, paddingBottom: 4};
    return (
        <tr style={{height: 80}}>
          <td style={{...Styles.TableCell, padding: '0px 0px 8px 0px', fontSize: 16,
            textAlign: 'right'}}>
            {this.props.name}
          </td>
          <td style={elemStyle}>
            {this.props.elem}
            <div style={{fontSize: 12, margin: 2}}>{this.props.description || '\xa0'}</div>
          </td>
        </tr>
    );
  }
}

