import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {SectionViewHeader} from './Components';
import {JobHistoryEntry} from './JobHistoryEntry';
import {IntCmp} from './Utils';

// Shows old jobs
export class JobHistoryView extends Component
{
  static propTypes =
  {
    headerText: PropTypes.string.isRequired,
    showKill: PropTypes.bool
  };

  constructor(props)
  {
    super(props);
    this.state =
    {
      headerText: props.headerText,
      jobs: {}
    };
  }

  // Handle props changes
  componentWillReceiveProps(nextProps)
  {
    if(nextProps.jobs)
    {
      this.setState({jobs: nextProps.jobs});
    }
  }

  render()
  {
    const arrJobEntries = Object.keys(this.state.jobs).sort(IntCmp).reverse().map
    (
      (sJobID) => <JobHistoryEntry showKill={this.props.showKill}
                                   ref={'JobEntry' + sJobID} key={sJobID}
                                   job={this.state.jobs[sJobID]}/>
    );

    // allowEmpty means render the header even if no entries
    if(!this.props.allowEmpty && !arrJobEntries.length)
    {
      return <div></div>;
    }

    return (
      <div>
        <SectionViewHeader headerText={this.state.headerText}/>
        <div>
          {arrJobEntries}
        </div>
      </div>
    );
  }
}
