// Core
import React, {Component} from 'react';


import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';

import {LayoutDims, Colors, Styles, PaletteMain, Btns} from './UIConst';
import {HLine, Separator, ShowOnly} from './Components';
import {Spinner, GlobalUI, FetchBusy} from '../Global';
import {DataFetcher, Data, AuditLog} from '../Models/Model';


export class SettingsIdentity extends Component
{
  static Modes = {DEFAULT: 0, AUTO: 1, EXPLICIT: 2}

  constructor(props)
  {
    super(props);

    this.state =
    {
      fetched: false,
      fetchError: null,
      changed: false,

      // mode alone is a transient UI state
      mode: 0
    }

    // This will contain the last data from the server (to allow cancel)
    this.data = null;
  }

  // Puts the data in the uncontrolled UI controls
  setUIState = (data) =>
  {
    this.refs.user_override.setChecked(data.user_override);
    this.refs.app_override.setChecked(data.app_override);
    this.refs.user.value = data.user;
    this.refs.group.value = data.group;

    this.setState({...data, fetched: true, fetchError: null, changed: false});
  }

  // On mount, fire a fetch
  componentDidMount()
  {
    this.fetcher = new DataFetcher('/portal/ident-get', {username: Data.User.Profile.user_login});
    this.fetcher.whenDone
    (
      ()=>
      {
        // Put the data in the UI
        this.setUIState(this.fetcher.data);

        // also save it in data to allow cancel
        this.data = {...this.fetcher.data};
      }
    );

    // On fail either show the HTML or text error
    this.fetcher.ifFail
    (
      (jqXHR)=>
      {
        const fetchError =
          <div dangerouslySetInnerHTML={{__html: jqXHR.responseJSON ?
            jqXHR.responseJSON.error.msg : jqXHR.responseText}}/>

        this.setState({fetched: false, fetchError})
      }
    );

    this.fetcher.fetch();
  }

  // Set fetching state, and refetch data
  refetch = () =>
  {
    this.setState({fetched: false, fetchError: null});
    this.fetcher.fetch();
  }

  // When anything changes keep track
  onChange = () => this.setState({changed: true});

  // When mode radio button changes
  onChangeMode = (e) =>
  {
    // Convert string to int
    const mode = e.target.value|0;
    this.setState({mode, changed: true});

    // If we switch away from EXPLICIT, clear the user/group
    if(mode !== SettingsIdentity.Modes.EXPLICIT)
    {
      this.refs['user'].value = '';
      this.refs['group'].value = '';
    }
  }

  // Cancel changes - re-init the UI with the last server data
  onCancel = () => this.setUIState(this.data);

  // Save settings
  onSave = () =>
  {
    // Get the data from UI
    const data =
    {
      username: Data.User.Profile.user_login,
      user_override: this.refs.user_override.isChecked(),
      app_override: this.refs.app_override.isChecked(),
      mode: this.state.mode,
      user: this.refs.user.value,
      group: this.refs.group.value,
      impersonation: Data.User.Identity.impersonation
    }

    const fetcher = new DataFetcher('/portal/ident-update', data);
    fetcher.whenDone
    (
      // On success update both state and this.data as if we just fetched
      // No need to set values on the UI controls
      // Also update the Data.User.Identity
      ()=>
      {
        // TODO log any more details?
        AuditLog('settings', 'identity', 'Changed');
        this.setState({...data, fetched: true, fetchError: null, changed: false});
        this.data = data;
        Data.User.Identity = {...data};
      }
    );

    // On failure, show error dialog
    fetcher.ifFail((jqXHR)=>GlobalUI.Dialog.showErr(jqXHR, 'Failed to save'));

    FetchBusy(fetcher, 'Saving...');
  }

  render()
  {
    const bIsExplicit = this.state.mode === SettingsIdentity.Modes.EXPLICIT;

    const styleInputPane =
    {
      ...Styles.Inline,
      alignItems: 'baseline',
      width: '100%',
      height: LayoutDims.nMargin * 4,
      color: bIsExplicit ? PaletteMain.palette.textColor : Colors.clrNimbixGray,
      marginBottom: LayoutDims.nMargin,
    }

    const styleInput =
    {
      ...Styles.ParamInput,
      flex: 'none',
      width: '60%',
    };

    const styleLabel =
    {
      width: '18%',
      fontSize: LayoutDims.FontSize * 0.8,
      marginRight: LayoutDims.nMargin / 2,
      textAlign: 'right'
    };

    return (
      <div>
        <ShowOnly if={this.state.fetched}>

          <div style={{fontSize: LayoutDims.FontSize * 0.8}}>
            <p>These advanced settings control the default Linux user identity of
            applications running in jobs for this account.
            If there are users on the team, the settings extend to those users as well.
            In most cases, <b>System Default</b> is the appropriate setting.
            </p>


            <p>If you are using an network-based application license server that needs
            to account for users individually, select <b>Automatic</b>; license
            checkouts will appear with the identity of the user logged into JARVICE
            instead of the generic <code>nimbix</code> one.
            </p>

            <p>Use <b>Explicit</b> to specify the exact identity that all jobs will assume at
            the application level, but note that this may not be compatible with all workflows.</p>


            <p>Note that with <b>Automatic</b> or <b>Explicit</b>, using SSH to connect to a job
            will require you to specify that user rather than the default <code>nimbix</code> user.
            </p>

            If you are not sure what to specify, select <b>System Default</b>.

          </div>

          <br/>
          <HLine margin={0}/>
          <br/>

          <RadioButtonGroup onChange={this.onChangeMode}
                            ref='radioMode'
                            name='jobStatsView'
                            valueSelected={this.state.mode}>

            <RadioButton value={0} label='System Default' style={Styles.Radio} />
            <RadioButton value={1} label='Automatic (derived from logged in user)' style={Styles.Radio} />
            <RadioButton value={2} label='Explicit' style={Styles.Radio} />

          </RadioButtonGroup>

          <div style={styleInputPane}>
            <div style={styleLabel}>
              Linux Username:
            </div>
            <input ref='user' onChange={this.onChange} disabled={!bIsExplicit} style={styleInput}/>
          </div>


          <div style={styleInputPane}>
            <div style={styleLabel}>
              Linux Group:
            </div>
            <input ref='group' onChange={this.onChange} disabled={!bIsExplicit} style={styleInput}/>
          </div>

          <br/>
          <br/>
          <HLine margin={0}/>
          <br/>

          <Checkbox ref='user_override'
                    onCheck={this.onChange}
                    label='Allow Users to Override Job Identity Settings via API'/>

          <Separator units={1}/>

          <Checkbox ref='app_override'
                    onCheck={this.onChange}
                    label='Allow Applications to Override Job Identity Settings via AppDef'/>

          <br/>
          <HLine margin={0}/>
          <br/>

          <Separator units={1}/>

          <div style={Styles.FlexSpread}>
            <RaisedButton {...Btns.DarkRed}
                          label='Cancel'
                          disabled={!this.state.changed}
                          onClick={this.onCancel}/>

            <RaisedButton {...Btns.Green}
                          label='Save'
                          disabled={!this.state.changed}
                          onClick={this.onSave}/>
          </div>

        </ShowOnly>

        <ShowOnly if={!this.state.fetched}>
          <Separator units={8}/>
          {
            this.state.fetchError != null
            ?
              <div>
                <div style={{width:'100%', textAlign:'center', color: Colors.clrNimbixErr}}>
                  {this.state.fetchError}
                </div>
                <br/>
                <br/>
                <RaisedButton {...Btns.Green} label='Reload' onClick={this.refetch}/>
              </div>
            :
              <Spinner size={64} textColor={Colors.clrNimbixDark} status='Loading...' style={Styles.Full}/>
          }
        </ShowOnly>

      </div>
    );
  }
}
